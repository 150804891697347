import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PageLoading from '../../../../components/_atom/loading/PageLoading';
import ManageTokenBeforeDeploy from './ManageTokenBeforeDeploy';
import { BooleanParser } from '../utils/tokenUtils';
import ManageTokenAfterDeploy from './ManageTokenAfterDeploy';
import StatusDetail, { DEFAULT_LABEL_STYLE } from '../../../../components/_atom/label/StatusDetail';
import { addComma, errorAlert } from '../../../../../utils/Utils';
import ImageUpload from '../../../../components/_atom/file/ImageUpload';
import RadioButtons from '../../../../../legacy-common/molcules/RadioButtons/RadioButtons';
import { BURNABLE, MINTABLE, TOKEN_STATUS } from '../constant/tokenConstant';
import { selected_project_id } from 'reducer/projectReducer';
import { getTokenDetailAction } from 'action/tokenAction';
import PageArticle from 'view/components/_template/page/PageArticle';
import PageTitle from 'view/components/_molecules/page/PageTitle';
import CopyLabelWithIcon from 'view/components/_atom/label/CopyLabelWithIcon';
import useLoading from 'hooks/useLoading';
import { isDisplayTransactionStatus, parseServiceStatus } from '../../../../../utils/status.utils';
import TransactionStatus from '../../../../components/_atom/transactionStatus/TransactionStatus';
import { ManageFieldContainer, ManageFieldLabel, RowManageField } from 'view/components/_atom/section/ManageField';
import tokenIcon from '../../../../assets/images/service/Icon_SNB_token.png';

const ManageToken = () => {
  const { id: currencyId } = useParams();
  const projectId = useSelector(selected_project_id);

  //========= Get Token Detail =========//
  const [tokenDetail, setTokenDetail] = useState({});
  const [tokenImage, setTokenImage] = useState(null);
  const [mintable, setMintable] = useState(null);
  const [burnable, setBurnable] = useState(null);
  // const [lockable, setLockable] = useState(1)
  const [tokenStatus, setTokenStatus] = useState(null);

  const getTokenDetail_ = async () => {
    const { result, error } = await getTokenDetailAction(parseInt(currencyId));
    if (result) {
      const { mainStatus, subStatus } = result;
      const targetStatus = parseServiceStatus(TOKEN_STATUS, mainStatus, subStatus);
      setTokenDetail(result);
      setTokenImage(result.image);
      setMintable(BooleanParser(result.currencyDetail.mintable));
      setBurnable(BooleanParser(result.currencyDetail.burnable));
      setTokenStatus(targetStatus);
      // setLockable(BooleanParser(result.currencyDetail.lockable))
    }
    if (error) {
      errorAlert(error.data.message);
    }
  };

  //========= Distinguish Status =========//
  const statusParser = useMemo(() => {
    switch (tokenDetail?.mainStatus) {
      default:
        return tokenDetail?.mainStatus;
    }
  }, [tokenDetail]);
  const statusData = useMemo(() => {
    switch (tokenDetail?.mainStatus) {
      default:
        return {
          text: DEFAULT_LABEL_STYLE.find((el) => el.status === statusParser)?.text,
          description: DEFAULT_LABEL_STYLE.find((el) => el.status === statusParser)?.description,
        };
    }
  }, [tokenDetail, statusParser]);

  const { loading } = useLoading({
    dependency: [projectId],
    synchronousFunction: async () => {
      await getTokenDetail_();
    },
  });

  if (loading) {
    return <PageLoading />;
  }
  return (
    <PageArticle>
      <PageTitle title={'Token Detail'} size={810} />
      <div className="page-layout-810">
        <StatusDetail status={statusParser} data={statusData} />
        {isDisplayTransactionStatus(TOKEN_STATUS[tokenStatus]) && (
          <TransactionStatus
            {...(tokenDetail.transactionHash && {
              transactionHash: tokenDetail.transactionHash,
            })}
            statusDetail={TOKEN_STATUS[tokenStatus].statusDetail}
            microChainId={tokenDetail.originMicroChainId}
          />
        )}
        <ManageFieldContainer icon={tokenIcon} title="Basic Info">
          <RowManageField>
            <ManageFieldLabel label="Network / Microchain" />
            <span>{`${tokenDetail?.networkLabel} / ${tokenDetail?.microChainLabel}`}</span>
          </RowManageField>
        </ManageFieldContainer>
        <ManageFieldContainer icon={tokenIcon} title="Details">
          <RowManageField>
            <ManageFieldLabel label="ID" />
            <span>{tokenDetail?.currencyId ?? '-'}</span>
          </RowManageField>
          <RowManageField>
            <ManageFieldLabel label="Token name" />
            <span>{tokenDetail?.name ?? '-'}</span>
          </RowManageField>
          <RowManageField>
            <ManageFieldLabel label="Token symbol" />
            <span>{tokenDetail?.symbol ?? '-'}</span>
          </RowManageField>
          <RowManageField>
            <ManageFieldLabel label="Token image" />
            <ImageUpload image={tokenImage} setImage={setTokenImage} style={{ alignItems: 'start' }} previewStyle={{ borderRadius: '50%' }} disabled={true} />
          </RowManageField>
          <RowManageField>
            <ManageFieldLabel label="Token supply" />
            <span>{addComma(tokenDetail?.currencyDetail?.initialSupply) ?? '-'}</span>
          </RowManageField>
          <RowManageField>
            <ManageFieldLabel label="Mintable" />
            <RadioButtons options={MINTABLE} selectedValue={mintable} onChange={setMintable} readOnly={true} />
          </RowManageField>
          <RowManageField>
            <ManageFieldLabel label="Burnable" />
            <RadioButtons options={BURNABLE} selectedValue={burnable} onChange={setBurnable} readOnly={true} />
          </RowManageField>
          <RowManageField>
            <ManageFieldLabel label="Contract address" />
            {Boolean(tokenDetail?.originMicroChainCurrency?.contractAddress) ? (
              <CopyLabelWithIcon text={tokenDetail?.originMicroChainCurrency?.contractAddress} />
            ) : (
              '-'
            )}
          </RowManageField>
        </ManageFieldContainer>
        {tokenDetail?.mainStatus === 1 || tokenDetail?.mainStatus === 3 ? (
          <ManageTokenBeforeDeploy
            tokenImage={tokenImage}
            setTokenImage={setTokenImage}
            mintable={mintable}
            setMintable={setMintable}
            burnable={burnable}
            // setLockable={setLockable}
            // lockable={lockable}
            setBurnable={setBurnable}
            tokenDetail={tokenDetail}
            setTokenDetail={setTokenDetail}
            getTokenDetail_={getTokenDetail_}
          />
        ) : null}
        {tokenDetail?.mainStatus === 5 ? <ManageTokenAfterDeploy tokenDetail={tokenDetail} getTokenDetail_={getTokenDetail_} /> : null}
      </div>
    </PageArticle>
  );
};

export default ManageToken;
