import { useMemo, useState } from "react";
import "./ManageAirdrop.scss";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import StatusDetail, { DEFAULT_LABEL_STYLE } from "../../../../components/_atom/label/StatusDetail";
import PageLoading from "../../../../components/_atom/loading/PageLoading";
import { connectInfo } from "../../../../../store/redux/EQconnect/EQConnectSlice";
import toast from "react-hot-toast";
import AirdropDistributionSendFeeModal from "../component/modal/AirdropDistributionSendFeeModal";
import AirdropDepositModal from "../component/modal/AirdropDepositModal";
import ButtonTemplate from "../component/template/ButtonTemplate";
import ManageAirdropProjectManagementContainer from "../component/organism/ManageAirdropProjectManagementContainer";
import {
  AIRDROP_OPERATION_PURPOSE_BOUNTY,
  AIRDROP_PARTICIPANT_COUNT_LIST,
  AIRDROP_PARTICIPANT_LIMIT_PERIOD_LIST,
  AIRDROP_PROJECT_STATUS,
} from "../constants/airdropConstants";
import ManageAirdropBountyTemplate from "../component/template/ManageAirdropBountyTemplate";
import { addComma, errorAlert } from "../../../../../utils/Utils";
import IconDeleteRed from "../../../../assets/images/atom/icon_delete_red.png";
import AirdropContractDeployModal from "../component/modal/AirdropContractDeployModal";
import EQConnectWalletButton from "../../../../../modules/EQConnect/EQConnectWalletButton";
import FixedAirdropConditionEventList from "../component/molecule/FixedAirdropConditionEventList";
import { selected_project_id } from "reducer/projectReducer";
import {
  airdropPublishAction,
  checkDepositAction,
  checkDistributionFeeAction,
  deleteAirdropAction,
  getAirdropDetailAction,
  submitAirdropAction,
} from "action/airdropAction";
import PageArticle from "view/components/_template/page/PageArticle";
import PageTitle from "view/components/_molecules/page/PageTitle";
import CopyLabelWithIcon from "view/components/_atom/label/CopyLabelWithIcon";
import ButtonContainer from "view/components/_atom/container/ButtonContainer";
import BasicButton from "view/components/_atom/button/BasicButton";
import { ButtonSize, ButtonTheme } from "view/components/_atom/button/StyledButton";
import useLoading from "hooks/useLoading";
import { isDisplayTransactionStatus, parseServiceStatus } from "../../../../../utils/status.utils";
import TransactionStatus from "../../../../components/_atom/transactionStatus/TransactionStatus";
import { ManageFieldContainer, ManageFieldLabel, ManageFieldWrapper, RowManageField } from "view/components/_atom/section/ManageField";
import airdropIcon from "../../../../assets/images/service/Icon_SNB_airdrop.png";
import DivideLine from "view/components/_atom/line/DivideLine";

const ManageAirdrop = () => {
  const navigate = useNavigate();
  const projectId = useSelector(selected_project_id);
  const params = useParams();
  const { address: connectedAddress } = useSelector(connectInfo);
  const airdropId = useMemo(() => {
    return params.id;
  }, [params]);

  const [airdropDetail, setAirdropDetail] = useState();
  const [depositModalOpen, setDepositModalOpen] = useState(false);
  const [distributionSendFeeModalOpen, setDistributionSendFeeModalOpen] = useState(false);
  const [contractDeployModalOpen, setContractDeployModalOpen] = useState(false);
  const [airdropStatus, setAirdropStatus] = useState(null);

  const getAirdropDetail_ = async () => {
    const { result, error } = await getAirdropDetailAction(airdropId);
    if (result) {
      const { mainStatus, subStatus } = result;
      const targetStatus = parseServiceStatus(AIRDROP_PROJECT_STATUS, mainStatus, subStatus);
      setAirdropStatus(targetStatus);
      setAirdropDetail(result);
    }
    if (error) {
      errorAlert(error.data.message);
    }
  };

  const deleteAirdrop_ = async () => {
    if (window.confirm("Are you sure you want to delete it? You cannot recover deleted data")) {
      const { result, error } = await deleteAirdropAction(airdropId);
      if (result !== undefined) {
        toast.success("processed successfully");
        navigate("/application/airdrop/manage");
      }
      if (error) {
        errorAlert(error.data.message);
      }
    }
  };

  const submitAirdrop_ = async () => {
    const { result, error } = await submitAirdropAction(airdropId);
    if (result) {
      await getAirdropDetail_();
      setContractDeployModalOpen(true);
    }
    if (error) {
      errorAlert(error.data.message);
    }
  };

  const checkDeposit_ = async () => {
    const { result, error } = await checkDepositAction(airdropId);
    if (result) {
      if (result.status) {
        await getAirdropDetail_();
      }
      return result.status;
    }
    if (error) {
      errorAlert(error.data.message);
    }
  };

  const checkDistributionFee_ = async () => {
    const { result, error } = await checkDistributionFeeAction(airdropId);
    if (result) {
      if (result.status) {
        await getAirdropDetail_();
      }
      return result.status;
    }
    if (error) {
      errorAlert(error.data.message);
    }
  };

  const onClickDeployButton = () => {
    setContractDeployModalOpen(true);
  };

  const onClickDepositButton = async () => {
    setDepositModalOpen(true);
    // const res = await checkDeposit_();
    // if (!res) {
    //   setDepositModalOpen(true);
    // }
  };

  const onClickSendFeeButton = async () => {
    setDistributionSendFeeModalOpen(true);

    // const res = await checkDistributionFee_();
    // if (!res) {
    //   setDistributionSendFeeModalOpen(true);
    // }
  };

  const statusParser = useMemo(() => {
    switch (airdropDetail?.mainStatus) {
      default:
        return airdropDetail?.mainStatus;
    }
  }, [airdropDetail]);

  const statusData = useMemo(() => {
    switch (airdropDetail?.mainStatus) {
      case 0:
        return {
          text: DEFAULT_LABEL_STYLE.find((el) => el.status === statusParser).text,
          description: airdropDetail?.reject_reason?.reject_reason,
        };
      default:
        return {
          text: DEFAULT_LABEL_STYLE.find((el) => el.status === statusParser)?.text,
          description: DEFAULT_LABEL_STYLE.find((el) => el.status === statusParser)?.description,
        };
    }
  }, [statusParser]);

  const airdropPublish_ = async () => {
    const { result, error } = await airdropPublishAction(airdropId);
    if (result) {
      toast.success("processed successfully");
    }
    if (error) {
      errorAlert(error.data.message);
    }
  };

  const onClickPublishButton = async () => {
    await airdropPublish_();
    await getAirdropDetail_();
  };

  const { loading } = useLoading({
    dependency: [projectId],
    synchronousFunction: async () => {
      await getAirdropDetail_();
    },
  });

  const purposeRenderer = (data) => [
    {
      renderers: [
        {
          index: "Operation purpose",
          value: data?.operationPurposeLabel || "-",
        },
      ],
    },
  ];
  const basicInfoRenderer = (data) => [
    {
      renderers: [
        {
          value: (
            <div className="section-wrapper-content-data-information">
              <div className="section-wrapper-content-data-information-image">
                <img className="section-wrapper-content-data-information-image-logo" src={data?.projectImage} alt="" />
              </div>
              <div className="section-wrapper-content-data-information-title">{data?.projectName}</div>
            </div>
          ),
        },
      ],
    },
    {
      renderers: [
        {
          index: "Airdrop project ID",
          value: data?.airdropId,
        },
        {
          index: "Project links",
          value: (
            <div className="manage-airdrop-project-link-container">
              {Array.isArray(data?.marketingChannels) && data?.marketingChannels.length === 0 && "-"}
              {data?.marketingChannels.map((el, index) => {
                return (
                  <div className="link-button" key={`marketing-channel-${index}`} onClick={() => window.open(el)}>
                    {el}
                  </div>
                );
              })}
            </div>
          ),
        },
        {
          index: "Contract address",
          value: <CopyLabelWithIcon text={data.contractAddress} />,
        },
      ],
    },
  ];
  const budgetRenderer = (data) => [
    {
      renderers: [
        {
          index: "Network / MicroChain",
          value: `${data?.networkLabel} / ${data?.microchainLabel}` || "-",
        },
        {
          index: "Project currency",
          value: `${data?.currency.label} (${data?.currency.symbol})` || "-",
        },
        {
          index: "Budget",
          value: addComma(data?.depositAmount) || "-",
        },
      ],
    },
  ];
  const operatingWayRenderer = (data) => [
    {
      label: "Reward",
      renderers: [
        {
          index: "Type",
          value: data?.distributionAmount.label || "-",
        },
        {
          index: "Amount",
          value: (
            <>
              {data?.distributionAmount.distributionAmounts.map((el, index) => {
                return <span key={`distribution-amount-${index}`}>{addComma(el.amount)}</span>;
              })}
            </>
          ),
        },
      ],
    },
    {
      label: "Payment processing system",
      renderers: [
        {
          index: "System",
          value: data?.distributionType.label || "-",
        },
      ],
    },
    {
      label: "Project operation period",
      renderers: [
        {
          index: "Type",
          value: data?.participationPeriod.label || "-",
        },
      ],
    },
    data?.participationConditionTypes[0].participationConditionTypeId === 1 && {
      label: "Conditions of participation",
      renderers: [
        {
          index: "Type",
          value: (
            <div className="section-wrapper-content-data-row">
              <div className="section-wrapper-content-data-row-value">
                <div style={{ marginBottom: "20px" }}>{`Limit the number of participations within the time period`}</div>
                <p>
                  {`- Project participants can participate up to `}
                  <span className="highlight-text">
                    {AIRDROP_PARTICIPANT_COUNT_LIST.find((el) => el.value === data?.participationConditionTypes[0].limit.count).label}
                  </span>{" "}
                  <span className="highlight-text">
                    {AIRDROP_PARTICIPANT_LIMIT_PERIOD_LIST.find((el) => el.value === data?.participationConditionTypes[0].limit.period).label}
                  </span>
                </p>
              </div>
            </div>
          ),
        },
        {
          value: (
            <>
              {data?.participationConditionTypes[0].participationConditionTypeId === 2 && (
                <FixedAirdropConditionEventList participantEventsData={data?.participationConditionTypes[0].events} disabled={true} />
              )}
            </>
          ),
        },
      ],
    },
    data?.participationConditionTypes[0].participationConditionTypeId === 2 && {
      label: "Conditions of participation",
      renderers:
        data?.participationConditionTypes[0].events.length !== 0
          ? [
              {
                index: "Type",
                value: (
                  <div className="section-wrapper-content-data-row">
                    <div className="section-wrapper-content-data-row-value">{data?.participationConditionTypes[0].label}</div>
                  </div>
                ),
              },
              {
                value: <FixedAirdropConditionEventList participantEventsData={data?.participationConditionTypes[0].events} disabled={true} />,
              },
            ]
          : [
              {
                index: "Type",
                value: "No Events",
              },
            ],
    },
  ];

  if (loading) {
    return <PageLoading />;
  }
  return (
    <>
      {depositModalOpen && (
        <AirdropDepositModal
          isOpen={depositModalOpen}
          closeModal={() => setDepositModalOpen(false)}
          data={airdropDetail}
          airdropId={airdropId}
          callback={getAirdropDetail_}
        />
      )}
      {distributionSendFeeModalOpen && (
        <AirdropDistributionSendFeeModal
          isOpen={distributionSendFeeModalOpen}
          closeModal={() => setDistributionSendFeeModalOpen(false)}
          data={airdropDetail}
          airdropId={airdropId}
          callback={getAirdropDetail_}
        />
      )}
      {contractDeployModalOpen && (
        <AirdropContractDeployModal
          isOpen={contractDeployModalOpen}
          closeModal={() => setContractDeployModalOpen(false)}
          data={airdropDetail}
          airdropId={airdropId}
          callback={getAirdropDetail_}
        />
      )}
      <PageArticle>
        <PageTitle title={"Airdrop Project Detail"} size={810} />
        <div className="page-layout-810">
          <StatusDetail status={statusParser} data={statusData} />
          {isDisplayTransactionStatus(AIRDROP_PROJECT_STATUS[airdropStatus]) && (
            <TransactionStatus
              {...(airdropDetail?.transactionHash && { transactionHash: airdropDetail.transactionHash })}
              statusDetail={AIRDROP_PROJECT_STATUS[airdropStatus].statusDetail}
              microChainId={airdropDetail?.microchainId}
            />
          )}

          <ManageFieldContainer icon={airdropIcon} title="Project Purpose">
            <RowManageField>
              <ManageFieldLabel label="Operation purpose" />
              <span>{`${airdropDetail?.operationPurposeLabel ?? "-"}`}</span>
            </RowManageField>
          </ManageFieldContainer>
          <ManageFieldContainer icon={airdropIcon} title="Basic Info">
            <div className="section-wrapper-content-data-information">
              <div className="section-wrapper-content-data-information-image">
                <img className="section-wrapper-content-data-information-image-logo" src={airdropDetail?.projectImage} alt="" />
              </div>
              <div className="section-wrapper-content-data-information-title">{airdropDetail?.projectName ?? "-"}</div>
            </div>
            <DivideLine style={{ margin: "20px 0" }} />
            <RowManageField>
              <ManageFieldLabel label="Airdrop project ID" />
              <span>{airdropId ?? "-"}</span>
            </RowManageField>
            <RowManageField>
              <ManageFieldLabel label="Project links" />
              <div className="manage-airdrop-project-link-container">
                {Array.isArray(airdropDetail?.marketingChannels) && airdropDetail?.marketingChannels.length === 0 && "-"}
                {airdropDetail?.marketingChannels.map((el, index) => {
                  return (
                    <div className="link-button" key={`marketing-channel-${index}`} onClick={() => window.open(el)}>
                      {el}
                    </div>
                  );
                })}
              </div>
            </RowManageField>
            <RowManageField>
              <ManageFieldLabel label="Contract address" />
              {airdropDetail?.contractAddress ? <CopyLabelWithIcon text={airdropDetail.contractAddress} /> : "-"}
            </RowManageField>
          </ManageFieldContainer>
          <ManageFieldContainer icon={airdropIcon} title="Budget">
            <RowManageField>
              <ManageFieldLabel label="Network / Microchain" />
              <span>{`${airdropDetail?.networkLabel} / ${airdropDetail?.microchainLabel}`}</span>
            </RowManageField>
            <RowManageField>
              <ManageFieldLabel label="Project currency" />
              <span>{`${airdropDetail?.currency?.label ?? ""} (${airdropDetail?.currency?.symbol ?? ""})`}</span>
            </RowManageField>
            <RowManageField>
              <ManageFieldLabel label="Budget" />
              <span>{`${airdropDetail?.depositAmount ? addComma(airdropDetail.depositAmount) : "-"}`}</span>
            </RowManageField>
          </ManageFieldContainer>
          <ManageFieldContainer icon={airdropIcon} title="Operating Way">
            <ManageFieldWrapper title="Reward">
              <RowManageField>
                <ManageFieldLabel label="Type" />
                <span>{`${airdropDetail?.distributionAmount?.label ?? "-"}`}</span>
              </RowManageField>
              <RowManageField>
                <ManageFieldLabel label="Amount" />
                <>
                  {airdropDetail?.distributionAmount?.distributionAmounts?.map((el, index) => {
                    return <span key={`distribution-amount-${index}`}>{addComma(el.amount)}</span>;
                  })}
                </>
              </RowManageField>
            </ManageFieldWrapper>
            <DivideLine style={{ margin: "20px 0" }} />
            <ManageFieldWrapper title="Payment processing system">
              <RowManageField>
                <ManageFieldLabel label="System" />
                <span>{`${airdropDetail?.distributionType?.label ?? "-"}`}</span>
              </RowManageField>
            </ManageFieldWrapper>
            <DivideLine style={{ margin: "20px 0" }} />
            <ManageFieldWrapper title="Project operation period">
              <RowManageField>
                <ManageFieldLabel label="Type" />
                <span>{`${airdropDetail?.participationPeriod?.label ?? "-"}`}</span>
              </RowManageField>
            </ManageFieldWrapper>
            <DivideLine style={{ margin: "20px 0" }} />
            <ManageFieldWrapper title="Conditions of participation">
              {airdropDetail?.participationConditionTypes[0]?.participationConditionTypeId === 1 && (
                <RowManageField>
                  <ManageFieldLabel label="Type" />
                  <FaucetTypeParticipationCondition data={airdropDetail?.participationConditionTypes[0]} />
                </RowManageField>
              )}
              {airdropDetail?.participationConditionTypes[0]?.participationConditionTypeId === 2 && (
                <RowManageField>
                  <ManageFieldLabel label="Type" />
                  <div className="bounty-type-participation-condition-container">
                    <div className="bounty-type-participation-condition-label">{`Participate in the events`}</div>
                    <FixedAirdropConditionEventList participantEventsData={airdropDetail?.participationConditionTypes[0]?.events} disabled={true} />
                  </div>
                </RowManageField>
              )}
            </ManageFieldWrapper>
          </ManageFieldContainer>
          {/* <Section title={"Project Purpose"} view={purposeRenderer} data={airdropDetail} /> */}
          {/* <Section title={"Basic Info"} view={basicInfoRenderer} data={airdropDetail} /> */}
          {/* <Section title={"Budget"} view={budgetRenderer} data={airdropDetail} /> */}
          {/* <Section title={"Operating Way"} view={operatingWayRenderer} data={airdropDetail} /> */}
          {/* mainStatus 5 일 때 노출 */}
          {airdropDetail?.mainStatus === 5 && (
            <>
              <ManageAirdropProjectManagementContainer airdropId={airdropId} airdropDetail={airdropDetail} />
              {airdropDetail?.operationPurpose === AIRDROP_OPERATION_PURPOSE_BOUNTY && (
                <ManageAirdropBountyTemplate airdropId={airdropId} airdropDetail={airdropDetail} getAirdropDetail_={getAirdropDetail_} />
              )}
            </>
          )}
          {/* =================== button =================== */}
          {/* MainStatus 1 상태에서의 버튼들 묶음 */}
          {airdropDetail?.mainStatus === 1 && (
            <div className="flex justify-content-flex-end">
              <ButtonContainer>
                <BasicButton size={ButtonSize.ICON} theme={ButtonTheme.MINOR} onClick={() => deleteAirdrop_()}>
                  <img src={IconDeleteRed} style={{ width: 18, objectFit: "contain" }} alt={""} />
                </BasicButton>
                <BasicButton size={ButtonSize.LARGE} theme={ButtonTheme.SECONDARY} onClick={() => navigate(`/application/airdrop/manage/${airdropId}/edit`)}>
                  Edit
                </BasicButton>
                <EQConnectWalletButton />
                <BasicButton size={ButtonSize.LARGE} disabled={!connectedAddress} onClick={() => submitAirdrop_()}>
                  Deploy
                </BasicButton>
              </ButtonContainer>
            </div>
          )}
          {/* MainStatus 3 상태에서의 버튼들 묶음 */}
          {airdropDetail?.mainStatus === 3 && (
            <ButtonTemplate
              subStatus={airdropDetail.subStatus}
              onClickDepositButton={onClickDepositButton}
              onClickSendFeeButton={onClickSendFeeButton}
              onClickDeployButton={onClickDeployButton}
              getAirdropDetail_={getAirdropDetail_}
              onClickPublishButton={onClickPublishButton}
            />
          )}
        </div>
      </PageArticle>
    </>
  );
};

export default ManageAirdrop;

const FaucetTypeParticipationCondition = (props) => {
  const { data } = props || {};

  return (
    <div className="faucet-type-participation-condition-container">
      <div className="faucet-type-participation-condition-label">{`Limit the number of participations within the time period`}</div>
      <p>
        {`- Project participants can participate up to `}
        <strong>{AIRDROP_PARTICIPANT_COUNT_LIST.find((el) => el.value === data?.limit?.count)?.label}</strong>{" "}
        <strong>{AIRDROP_PARTICIPANT_LIMIT_PERIOD_LIST.find((el) => el.value === data?.limit?.period)?.label}</strong>
      </p>
    </div>
  );
};
