import React, { useEffect, useMemo, useState } from "react";
import "./NetworkPreAllocationField.scss";
import BasicButton from "view/components/_atom/button/BasicButton";
import { ButtonSize } from "view/components/_atom/button/StyledButton";
import ButtonContainer from "view/components/_atom/container/ButtonContainer";
import { ColumnField, FieldContainer, FieldLabel, RowField } from "view/components/_atom/section/Field";
import { PRE_ALLOC_DEFAULT_DATA, NETWORK_CHART_COLOR, NETWORK_CHART_INSUFFICIENT_COLOR, NETWORK_CHART_OVERFLOW_COLOR } from "../../constants/networkConstants";
import { Cell, Pie, PieChart } from "recharts";
import BasicInput from "view/components/_atom/input/BasicInput";
import { FIRST_DECIMAL_REG_EXP, FLOAT_REG_EXP } from "constants/commonConstants";
import { convertPercentToValue, plusCalculate } from "../../util/networkUtils";
import allocationIcon from "../../../../../assets/images/Icon_allocation.png";
import keyIcon from "../../../../../assets/images/Icon_key.png";
import deleteIcon from "../../../../../assets/images/icon-common-delete.png";
import CreateNewKeyModal from "view/components/_modal/CreateNewKeyModal";
import { removeComma } from "utils/Utils";
import { allottedState, allottedStateLabel, calculatePreAllocBalanceByDistributionPercent } from "../../constants/network.utils";
import { NETWORK_ANNOUNCEMENT_ALLOCATED_AMOUNT, NETWORK_ANNOUNCEMENT_INITIAL_SUPPLY } from "../../constants/network.data";

const NetworkBesuPreAllocationField = (props) => {
  const { networkData, setNetworkData, initialSupply } = props;

  const [openCreateKeyModal, setOpenCreateKeyModal] = useState<Boolean>(false);

  const colGroup = useMemo(() => [31, 31, 31, 7].map((el) => ({ style: { width: `${el}%` } })), []);

  const setPreAlloc = (preAlloc: Array<any>) => {
    setNetworkData((prevState) => ({
      ...prevState,
      preAlloc,
    }));
  };

  const addAllocationRowButtonHandler = () => {
    setNetworkData((prevState) => ({
      ...prevState,
      preAlloc: prevState.preAlloc.concat(PRE_ALLOC_DEFAULT_DATA),
    }));
  };

  const preAllocationList = useMemo(() => {
    if (networkData.preAlloc.length === 0) {
      return [{ name: "insufficient", distributionPercent: Number((100).toFixed(1)) }];
    }

    const totalPercent = networkData.preAlloc.map((el) => Number(el.distributionPercent)).reduce((acc, cur) => acc + cur);
    if (totalPercent < 100) {
      return networkData.preAlloc
        .filter((el) => Boolean(el.distributionPercent))
        .map((el) => ({ name: el.name ? el.name : "-", distributionPercent: Number(el.distributionPercent) }))
        .concat({ name: "insufficient", distributionPercent: Number((100 - totalPercent).toFixed(1)) });
    }
    if (totalPercent > 100) {
      return [{ name: "overflow", distributionPercent: Number(totalPercent.toFixed(1)) }];
    }

    return networkData.preAlloc.map((el) => ({ distributionPercent: Number(el.distributionPercent), name: el.name }));
  }, [networkData.preAlloc]);

  const totalAllocatedPercent = useMemo(() => {
    if (networkData.preAlloc.length <= 0) {
      return 0;
    }
    const percents = networkData.preAlloc.map((el) => Number(el.distributionPercent));
    return percents.reduce((acc, cur) => plusCalculate(acc.toString(), cur.toString()));
  }, [networkData.preAlloc]);

  const totalAllocatedBalance = useMemo(() => {
    if (networkData.preAlloc.length <= 0) {
      return "0";
    }
    const validAmounts = networkData.preAlloc.filter((el) => el.mmcBalance !== null);
    if (validAmounts.length <= 0) {
      return "0";
    }
    const amounts = validAmounts.map((el) => Number(removeComma(el.mmcBalance))).concat(0);
    return amounts.reduce((acc, cur) => plusCalculate(acc.toString(), cur.toString()));
  }, [networkData.preAlloc]);

  const allocModifyWhenInitialSupplyChange = (initialSupply: string, preAlloc: Array<any>) => {
    const result = calculatePreAllocBalanceByDistributionPercent(initialSupply, preAlloc);
    setPreAlloc(result as Array<any>);
  };

  useEffect(() => {
    if (networkData.microChainSetting.initialSupply) {
      allocModifyWhenInitialSupplyChange(removeComma(networkData.microChainSetting.initialSupply), networkData.preAlloc);
    }
  }, [networkData.microChainSetting.initialSupply]);

  return (
    <>
      {openCreateKeyModal && <CreateNewKeyModal title={"Create a new key"} isOpen={openCreateKeyModal} closeModal={() => setOpenCreateKeyModal(false)} />}

      <FieldContainer
        icon={allocationIcon}
        title={"Pre-Allocation"}
        description={
          "You can set up wallet accounts where you would like to hold coins.\nAllocate coins to multiple wallet accounts before deploying the network."
        }
      >
        <div className="pre-allocation-button-wrapper">
          <ButtonContainer>
            <BasicButton size={ButtonSize.DYNAMIC} style={{ padding: "8px", width: "fit-content", fontSize: 14 }} onClick={() => setOpenCreateKeyModal(true)}>
              <span style={{ fontWeight: 500 }}>Create a new key</span>
              <img src={keyIcon} className="node-setting-distribute-icon" alt="" />
            </BasicButton>
            <BasicButton
              size={ButtonSize.DYNAMIC}
              style={{ padding: "11px 10px", width: "fit-content", height: 40, fontSize: 14 }}
              onClick={() => addAllocationRowButtonHandler()}
            >
              Add a new address to allocate
            </BasicButton>
          </ButtonContainer>
        </div>
        <ColumnField>
          <table className="pre-allocation-table">
            <colgroup style={{ padding: "0 40px" }}>
              {colGroup.map((col, index) => (
                <col key={`col-${index}`} {...col} />
              ))}
            </colgroup>
            <thead>
              <tr>
                <th>
                  <span>Name</span>
                </th>
                <th>
                  <span>Address</span>
                </th>
                <th>
                  <span>Distribution</span>
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {networkData.preAlloc?.length > 0 &&
                networkData.preAlloc.map((row, index: number) => (
                  <PreAllocationTableRow
                    key={`pre-allocation-table-row-${index}`}
                    row={row}
                    index={index}
                    networkData={networkData}
                    setNetworkData={setNetworkData}
                    allocatableAmount={initialSupply}
                  />
                ))}
              {networkData.preAlloc?.length === 0 && (
                <tr className="table-no-data">
                  <td colSpan={colGroup.length}>{"Please add wallet Pre-Allocation"}</td>
                </tr>
              )}
            </tbody>
          </table>
        </ColumnField>
        <ColumnField>
          <div className="pre-allocation-visualization-chart">
            <PieChart width={400} height={400}>
              <Pie
                data={preAllocationList}
                cx={"50%"}
                cy={"50%"}
                outerRadius={100}
                innerRadius={50}
                fill="#fff"
                dataKey="distributionPercent"
                startAngle={90}
                endAngle={-270}
                label={(value) => <AllocationPieChartLabel preAllocationList={preAllocationList} value={value} />}
                labelLine={false}
              >
                {preAllocationList?.map((entry, index: number) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={
                      entry.name === "insufficient"
                        ? NETWORK_CHART_INSUFFICIENT_COLOR
                        : entry.name === "overflow"
                        ? NETWORK_CHART_OVERFLOW_COLOR
                        : NETWORK_CHART_COLOR[index]
                    }
                  />
                ))}
              </Pie>
            </PieChart>
          </div>
        </ColumnField>
        <RowField>
          <FieldLabel label="Initial Supply" announcement={NETWORK_ANNOUNCEMENT_INITIAL_SUPPLY} />
          <div className="pre-allocation-field-initial-supply">
            <span className="pre-allocation-field-initial-supply-value">{networkData.microChainSetting.initialSupply}</span>
            <span className="pre-allocation-field-initial-supply-symbol">{networkData.currency.symbol}</span>
          </div>
        </RowField>
        <RowField>
          <FieldLabel label="Allocated Amount" announcement={NETWORK_ANNOUNCEMENT_ALLOCATED_AMOUNT} />
          <div className={`pre-allocation-field-allocated-value-wrapper ${allottedState(Number(totalAllocatedPercent))}`}>
            <div className="pre-allocation-field-allocated-percent">
              <span className="pre-allocation-field-allocated-percent-value">{totalAllocatedPercent}</span>
              <span className="pre-allocation-field-allocated-percent-unit">{`%`}</span>
            </div>
            <span className="pre-allocation-field-allocated-value">{`(${totalAllocatedBalance} ${networkData.currency.symbol})`}</span>
          </div>
        </RowField>
        <RowField>
          <div className={`pre-allocation-field-allocated-status ${allottedState(Number(totalAllocatedPercent))}`}>
            {allottedStateLabel(totalAllocatedPercent)}
          </div>
        </RowField>
      </FieldContainer>
    </>
  );
};

export default NetworkBesuPreAllocationField;

const PreAllocationTableRow = (props) => {
  const { row, index, networkData, setNetworkData, allocatableAmount } = props;

  const setPreAlloc = (preAlloc: Array<any>) => {
    setNetworkData((prevState) => ({
      ...prevState,
      preAlloc,
    }));
  };

  const setPreAllocName = (name: string) => {
    const preAlloc = networkData.preAlloc.map((el, i) => {
      if (index === i) {
        return {
          ...el,
          name,
        };
      } else {
        return el;
      }
    });
    setPreAlloc(preAlloc);
  };

  const setPreAllocAddress = (address: string) => {
    const preAlloc = networkData.preAlloc.map((el, i) => {
      if (index === i) {
        return {
          ...el,
          address,
        };
      } else {
        return el;
      }
    });
    setPreAlloc(preAlloc);
  };

  const setPreAllocPercent = (value) => {
    if (value === "") {
      const preAlloc = networkData.preAlloc.map((el, i) => {
        if (index === i) {
          return {
            ...el,
            distributionPercent: "",
            mmcBalance: "0",
          };
        } else {
          return el;
        }
      });
      setPreAlloc(preAlloc);
      return;
    }

    if (!FLOAT_REG_EXP.test(value)) {
      return;
    }

    if (!FIRST_DECIMAL_REG_EXP.test(value.toString())) {
      return;
    }

    if (Number(value) > 100) {
      const balance = convertPercentToValue(allocatableAmount.toString(), "100");

      const preAlloc = networkData.preAlloc.map((el, i) => {
        if (index === i) {
          return {
            ...el,
            distributionPercent: "100",
            mmcBalance: balance,
          };
        } else {
          return el;
        }
      });
      setPreAlloc(preAlloc);
      return;
    }

    const balance = convertPercentToValue(allocatableAmount.toString(), value.toString());
    const preAlloc = networkData.preAlloc.map((el, i) => {
      if (index === i) {
        return {
          ...el,
          distributionPercent: value,
          mmcBalance: balance,
        };
      } else {
        return el;
      }
    });
    setPreAlloc(preAlloc);
    return;
  };

  const deleteRow = (index: number) => {
    setNetworkData((prevState) => {
      const preAlloc = prevState.preAlloc.filter((_, i: number) => i !== index);
      return {
        ...prevState,
        preAlloc,
      };
    });
  };

  return (
    <tr>
      <td>
        <div className="flex align-items-center">
          <BasicInput value={row["name"] ?? ""} onChange={(e) => setPreAllocName(e.target.value)} placeholder="Ex) Development" />
        </div>
      </td>
      <td>
        <div className="flex align-items-center">
          <BasicInput value={row["address"] ?? ""} onChange={(e) => setPreAllocAddress(e.target.value)} placeholder="Ex)0x123..." />
        </div>
      </td>
      <td>
        <div className="flex align-items-center" style={{ gap: 5 }}>
          <BasicInput
            value={row["distributionPercent"] ?? ""}
            onChange={(e) => setPreAllocPercent(e.target.value)}
            style={{ width: 75, textAlign: "center" }}
            placeholder="Ex)10"
          />
          <span className="pre-allocation-table-distribution-percent">{`%`}</span>
          <div className="pre-allocation-table-distribution-amount">{`(${row["mmcBalance"] || 0} ${networkData.currency.symbol})`}</div>
        </div>
      </td>
      <td>
        <img src={deleteIcon} className="alloc-row-delete" alt="" onClick={() => deleteRow(index)} />
      </td>
    </tr>
  );
};

const AllocationPieChartLabel = (props) => {
  const { preAllocationList, value } = props;
  const { cx, cy, midAngle, innerRadius, outerRadius, index } = value || {};

  const RADIAN = Math.PI / 180;
  const radius = 25 + innerRadius + (outerRadius - innerRadius);
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  if (preAllocationList[0].name === "overflow") {
    return <></>;
  }

  return (
    <text
      x={x}
      y={y}
      dominantBaseline="central"
      textAnchor={x > cx ? "start" : "end"}
      className="pre-allocation-visualization-chart-label"
      style={{ fill: value.fill, opacity: 0.5 }}
    >
      <tspan x={x} y={y} className="pre-allocation-visualization-chart-label-name">
        {preAllocationList[index]?.name}
      </tspan>
      <tspan x={x} y={y + 16} className="pre-allocation-visualization-chart-label-percent">{`(${value.distributionPercent}%)`}</tspan>
    </text>
  );
};
