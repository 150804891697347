import { VC__is_empty_value, VC__is_less_than, VC__is_not_WEB3_address } from "modules/validation/validationCondition";

export function VALIDATION__PAYMENT_GATEWAY_name(name) {
  return [
    {
      condition: !VC__is_empty_value(name),
      message: "",
    },
  ];
}

export function VALIDATION__PAYMENT_GATEWAY_wallet_address(address) {
  return [
    {
      condition: !VC__is_empty_value(address),
      message: "",
    },
    {
      condition: !VC__is_not_WEB3_address(address),
      message: "Please enter a correct wallet address.",
    },
  ];
}

export function VALIDATION__PAYMENT_GATEWAY_block_confirmation_count(value, minValue) {
  return [
    {
      condition: !VC__is_empty_value(value),
      message: "",
    },
    {
      condition: !VC__is_less_than(value, 1),
      message: `Enter a value of 1 or more`,
    },
  ];
}

export function VALIDATION__PAYMENT_GATEWAY_deposit_redirect_uri(uri) {
  return [
    {
      condition: !VC__is_empty_value(uri),
      message: "",
    },
  ];
}

export function VALIDATION__PAYMENT_GATEWAY_withdrawal_redirect_uri(uri) {
  return [
    {
      condition: !VC__is_empty_value(uri),
      message: "",
    },
  ];
}
