import './Skeleton.scss'

const Skeleton = ({type = 'default', style}) => {

  const skeletonType = (type) => {
    switch (type) {
      case 'default':
        return {
          borderRadius: 8,
        }
      case 'circle':
        return {
          borderRadius: '50%'
        }
    }
  }
  return (
    <div className={'skeleton'} style={{...(style), ...(skeletonType(type))}}/>
  );
};

export default Skeleton;



