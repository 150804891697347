import React, { useState } from "react";
import PageArticle from "view/components/_template/page/PageArticle";
import PageTitle from "view/components/_molecules/page/PageTitle";
import useLoading from "hooks/useLoading";
import PageLoading from "view/components/_atom/loading/PageLoading";
import { useNavigate, useParams } from "react-router-dom";
import { getPaymentMethodDetailAction, updatePaymentMethodAction } from "action/paymentGatewayAction";
import { errorAlert } from "utils/Utils";
import CommentInput from "view/components/_atom/input/CommentInput";
import { paymentTypeLabelParser, selectPaymentTypeById } from "reducer/presetReducer";
import { useSelector } from "react-redux";
import BasicButton from "view/components/_atom/button/BasicButton";
import { ButtonSize } from "view/components/_atom/button/StyledButton";
import { ButtonTheme } from "view/components/_atom/button/StyledButton";
import ButtonContainer from "view/components/_atom/container/ButtonContainer";
import toast from "react-hot-toast";
import useValidateCondition from "hooks/useValidateCondition";
import {
  VALIDATION__PAYMENT_GATEWAY_deposit_redirect_uri,
  VALIDATION__PAYMENT_GATEWAY_wallet_address,
  VALIDATION__PAYMENT_GATEWAY_withdrawal_redirect_uri,
} from "../validation/paymentGatewayValidation";
import useIsAble from "hooks/useIsAble";
import NotFound from "view/components/_page/notFound/NotFound";
import useEditable from "hooks/useEditable.ts";
import { ManageFieldContainer, ManageFieldLabel, RowManageField } from "view/components/_atom/section/ManageField";
import pgIcon from "../../../../assets/images/service/Icon_SNB_payment_gateway.png";

const EditPaymentGateway = () => {
  const navigate = useNavigate();
  const { id: paymentGatewayId } = useParams();

  const [paymentGatewayDetail, setPaymentGatewayDetail] = useState();
  const selectedPaymentType = useSelector((state) => selectPaymentTypeById(state, paymentGatewayDetail?.paymentTypeId));

  const getPaymentGatewayDetail_ = async () => {
    const { error, result } = await getPaymentMethodDetailAction(paymentGatewayId);
    if (result) {
      setPaymentGatewayDetail(result);
    }
    if (error) {
      errorAlert(error.data.message);
      if (error.data.status === "401") {
        navigate("/developer/payment-gateway/manage");
      }
    }
  };

  const setAdminAddress = (address) => {
    setPaymentGatewayDetail((prevState) => {
      return {
        ...prevState,
        adminAddress: address,
      };
    });
  };

  const setDepositRedirectURI = (uri) => {
    setPaymentGatewayDetail((prevState) => {
      return {
        ...prevState,
        depositCallbackURI: uri,
      };
    });
  };

  const setWithdrawalRedirectURI = (uri) => {
    setPaymentGatewayDetail((prevState) => {
      return {
        ...prevState,
        withdrawalCallbackURI: uri,
      };
    });
  };

  const updatePaymentGateway_ = async () => {
    const { result, error } = await updatePaymentMethodAction(
      paymentGatewayDetail.paymentMethodId,
      paymentGatewayDetail.adminAddress,
      paymentGatewayDetail.depositCallbackURI,
      paymentGatewayDetail.withdrawalCallbackURI
    );

    if (result) {
      toast.success("Edit successfully");
      navigate(`/developer/payment-gateway/manage/${result.paymentMethodId}`);
    }

    if (error) {
      errorAlert(error.data.message);
    }
  };

  const walletAddressValidation = useValidateCondition(
    [paymentGatewayDetail?.adminAddress],
    VALIDATION__PAYMENT_GATEWAY_wallet_address(paymentGatewayDetail?.adminAddress)
  );
  const depositCallbackURIValidation = useValidateCondition(
    [paymentGatewayDetail?.depositCallbackURI],
    VALIDATION__PAYMENT_GATEWAY_deposit_redirect_uri(paymentGatewayDetail?.depositCallbackURI)
  );
  const withdrawalCallbackURIValidation = useValidateCondition(
    [paymentGatewayDetail?.withdrawalCallbackURI],
    VALIDATION__PAYMENT_GATEWAY_withdrawal_redirect_uri(paymentGatewayDetail?.withdrawalCallbackURI)
  );

  const isAble = useIsAble([walletAddressValidation.isValid, depositCallbackURIValidation.isValid, withdrawalCallbackURIValidation.isValid]);

  const { loading } = useLoading({
    dependency: [paymentGatewayId],
    synchronousFunction: async () => {
      await getPaymentGatewayDetail_();
    },
  });

  const { editable } = useEditable({ dependency: [!loading], currentStatus: paymentGatewayDetail?.mainStatus, unEditableStatus: [2, 3, 4] });

  if (loading) {
    return <PageLoading />;
  }

  if (!editable) {
    return <NotFound />;
  }

  return (
    <PageArticle>
      <PageTitle size={810} title={"Edit Payment Gateway"} />
      <div className="page-layout-810">
        {selectedPaymentType?.methodType === "account" && (
          <DetailInfoForVA
            paymentGatewayDetail={paymentGatewayDetail}
            selectedPaymentType={selectedPaymentType}
            setAdminAddress={setAdminAddress}
            setDepositRedirectURI={setDepositRedirectURI}
            setWithdrawalRedirectURI={setWithdrawalRedirectURI}
          />
        )}
        {selectedPaymentType?.methodType === "contract" && (
          <DetailInfoForSC
            paymentGatewayDetail={paymentGatewayDetail}
            selectedPaymentType={selectedPaymentType}
            setDepositRedirectURI={setDepositRedirectURI}
            setWithdrawalRedirectURI={setWithdrawalRedirectURI}
          />
        )}
        <div className="flex justify-content-flex-end">
          <ButtonContainer>
            <BasicButton size={ButtonSize.LARGE} theme={ButtonTheme.SECONDARY} onClick={() => navigate(-1)}>
              Back
            </BasicButton>
            <BasicButton size={ButtonSize.LARGE} disabled={!isAble} onClick={() => updatePaymentGateway_()}>
              Edit
            </BasicButton>
          </ButtonContainer>
        </div>
      </div>
    </PageArticle>
  );
};

export default EditPaymentGateway;

const DetailInfoForVA = (props) => {
  const { paymentGatewayDetail, selectedPaymentType, setAdminAddress, setDepositRedirectURI, setWithdrawalRedirectURI } = props;

  const walletAddressValidation = useValidateCondition(
    [paymentGatewayDetail?.adminAddress],
    VALIDATION__PAYMENT_GATEWAY_wallet_address(paymentGatewayDetail?.adminAddress)
  );

  return (
    <>
      <ManageFieldContainer icon={pgIcon} title="Basic Info">
        <RowManageField>
          <ManageFieldLabel label="UUID (payment_method_id)" />
          <span>{paymentGatewayDetail?.paymentMethodId ?? "-"}</span>
        </RowManageField>
        <RowManageField>
          <ManageFieldLabel label="Name" />
          <span>{paymentGatewayDetail?.name ?? "-"}</span>
        </RowManageField>
        <RowManageField>
          <ManageFieldLabel label="Network" />
          <span>{paymentGatewayDetail?.networkLabel ?? "-"}</span>
        </RowManageField>
        <RowManageField>
          <ManageFieldLabel label="Currency" />
          <span>{paymentGatewayDetail?.currencyDetail?.currency?.symbol ?? "-"}</span>
        </RowManageField>
        <RowManageField>
          <ManageFieldLabel label="Type" />
          <span>{paymentTypeLabelParser(selectedPaymentType?.name) ?? "-"}</span>
        </RowManageField>
        <RowManageField>
          <ManageFieldLabel label="Wallet address" />
          <CommentInput {...walletAddressValidation} value={paymentGatewayDetail?.adminAddress} onChange={(e) => setAdminAddress(e.target.value)} />
        </RowManageField>
        <RowManageField>
          <ManageFieldLabel label="Deposit redirect URI" />
          <CommentInput value={paymentGatewayDetail?.depositCallbackURI} onChange={(e) => setDepositRedirectURI(e.target.value)} />
        </RowManageField>
        <RowManageField>
          <ManageFieldLabel label="Withdrawal redirect URI" />
          <CommentInput value={paymentGatewayDetail?.withdrawalCallbackURI} onChange={(e) => setWithdrawalRedirectURI(e.target.value)} />
        </RowManageField>
      </ManageFieldContainer>
    </>
  );
};

const DetailInfoForSC = (props) => {
  const { paymentGatewayDetail, selectedPaymentType, setDepositRedirectURI, setWithdrawalRedirectURI } = props;

  return (
    <>
      <ManageFieldContainer icon={pgIcon} title="Basic Info">
        <RowManageField>
          <ManageFieldLabel label="UUID (payment_method_id)" />
          <span>{paymentGatewayDetail?.paymentMethodId ?? "-"}</span>
        </RowManageField>
        <RowManageField>
          <ManageFieldLabel label="Name" />
          <span>{paymentGatewayDetail?.name ?? "-"}</span>
        </RowManageField>
        <RowManageField>
          <ManageFieldLabel label="Network" />
          <span>{paymentGatewayDetail?.networkLabel ?? "-"}</span>
        </RowManageField>
        <RowManageField>
          <ManageFieldLabel label="Currency" />
          <span>{paymentGatewayDetail?.currencyDetail?.currency?.symbol ?? "-"}</span>
        </RowManageField>
        <RowManageField>
          <ManageFieldLabel label="Type" />
          <span>{paymentTypeLabelParser(selectedPaymentType?.name) ?? "-"}</span>
        </RowManageField>
        <RowManageField>
          <ManageFieldLabel label="Wallet address" />
          <span>{paymentGatewayDetail?.adminAddress ?? "-"}</span>
        </RowManageField>
        <RowManageField>
          <ManageFieldLabel label="Deposit redirect URI" />
          <CommentInput value={paymentGatewayDetail?.depositCallbackURI} onChange={(e) => setDepositRedirectURI(e.target.value)} />
        </RowManageField>
        <RowManageField>
          <ManageFieldLabel label="Withdrawal redirect URI" />
          <CommentInput value={paymentGatewayDetail?.withdrawalCallbackURI} onChange={(e) => setWithdrawalRedirectURI(e.target.value)} />
        </RowManageField>
      </ManageFieldContainer>
    </>
  );
};
