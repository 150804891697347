import { useEffect, useMemo, useState } from "react";
import "./ManageNode.scss";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import StatusDetail, { DEFAULT_LABEL_STYLE } from "../../../../components/_atom/label/StatusDetail";
import { errorAlert } from "../../../../../utils/Utils";
import IconDeleteRed from "../../../../assets/images/atom/icon_delete_red.png";
import { deployNodeAction, getNodeDetailAction, submitNodeAction } from "action/nodeAction";
import PageTitle from "view/components/_molecules/page/PageTitle";
import PageArticle from "view/components/_template/page/PageArticle";
import PageLoading from "view/components/_atom/loading/PageLoading";
import useGetSelectedProject from "../../../../../hooks/useGetSelectedProject";
import useLoading from "hooks/useLoading";
import BasicButton from "view/components/_atom/button/BasicButton";
import { ButtonSize, ButtonTheme } from "view/components/_atom/button/StyledButton";
import ButtonContainer from "view/components/_atom/container/ButtonContainer";
import Warning from "view/components/_atom/box/Warning";
import { ColumnManageField, ManageFieldContainer, ManageFieldLabel, RowManageField } from "view/components/_atom/section/ManageField";
import { getNetworkBlockchainEngineAction } from "action/networkAction";
import BlockchainEngineInfo from "../../network/component/atom/BlockchainEngineInfo";
import NodeDeleteModal from "../component/atom/NodeDeleteModal";

const ManageNode = () => {
  const navigate = useNavigate();

  const { id: nodeId } = useParams();
  const { isOwner } = useGetSelectedProject();

  const [nodeDetail, setNodeDetail] = useState();

  const nodeIp = useMemo(() => {
    if (!nodeDetail?.ip) {
      return undefined;
    }
    return `${nodeDetail?.ip.split(":")[1].replaceAll("//", "")}`;
  }, [nodeDetail]);

  const statusParser = useMemo(() => {
    switch (nodeDetail?.mainStatus) {
      default:
        return nodeDetail?.mainStatus;
    }
  }, [nodeDetail]);

  const statusData = useMemo(() => {
    switch (nodeDetail?.mainStatus) {
      case 0:
        return {
          text: DEFAULT_LABEL_STYLE.find((el) => el.status === statusParser).text,
          description: nodeDetail?.rejectReason,
        };
      default:
        return {
          text: DEFAULT_LABEL_STYLE.find((el) => el.status === statusParser)?.text,
          description: DEFAULT_LABEL_STYLE.find((el) => el.status === statusParser)?.description,
        };
    }
  }, [nodeDetail, statusParser]);

  const getNodeDetail_ = async () => {
    const { result, error } = await getNodeDetailAction(nodeId);

    if (result) {
      setNodeDetail(result);
    }
    if (error) {
      if (error.data.status === "404") {
        navigate("/network/node/manage");
        return;
      }
      errorAlert(error.data.message);
    }
  };

  const submitNode_ = async () => {
    const { result, error } = await submitNodeAction(nodeId);

    if (result) {
      toast.success("processed successfully");
      setNodeDetail(result);
    }
    if (error) {
      errorAlert(error.data.message);
    }
  };

  const deployNode_ = async () => {
    const { result, error } = await deployNodeAction(nodeId);

    if (result) {
      toast.success("Successfully deployed");
      navigate("/network/node/manage");
    }
    if (error) {
      errorAlert(error.data.message);
    }
  };

  const [nodeDeleteModalOpen, setNodeDeleteModalOpen] = useState(false);

  const deleteButtonHandler = async () => {
    setNodeDeleteModalOpen(true);
  };

  const onClickManageConsole = () => {
    window.open(`http://${nodeIp}:4011?self=true`);
  };

  const [networkData, setNetworkData] = useState({});
  const [engineInfo, setEngineInfo] = useState({});
  const [algorithm, setAlgorithm] = useState({});
  const getNodeBlockchainEngine_ = async (nodeDetail) => {
    const { result, error } = await getNetworkBlockchainEngineAction(nodeDetail.microChainId);
    if (result) {
      setNetworkData(result.networkDetail);
      setEngineInfo(result.chainClient);
      setAlgorithm(result.algorithm);
    }
    if (error) {
      // NOTE 현재 기존 네트워크에 protocolId, chainClientVersionId가 null로 되어있어서 에러가 발생함
      // 추후 서버팀에서 수정 후 에러 처리 필요
      // errorAlert(error.data.message);
    }
  };

  useEffect(() => {
    if (nodeDetail) {
      getNodeBlockchainEngine_(nodeDetail);
    }
  }, [nodeDetail]);

  const { loading } = useLoading({
    dependency: [nodeId],
    synchronousFunction: async () => {
      await getNodeDetail_();
    },
  });

  if (loading) {
    return <PageLoading />;
  }
  return (
    <PageArticle>
      {nodeDeleteModalOpen && (
        <NodeDeleteModal isOpen={nodeDeleteModalOpen} setIsOpen={setNodeDeleteModalOpen} nodeId={nodeId} mainStatus={nodeDetail.mainStatus} />
      )}
      <PageTitle size={810} title={`Node Detail`} />
      <div className="page-layout-810">
        {nodeDetail?.mainStatus !== null && <StatusDetail status={statusParser} data={statusData} />}
        <ManageFieldContainer title="Node Info">
          <RowManageField>
            <ManageFieldLabel label="ID" />
            <span>{nodeDetail?.id ?? "-"}</span>
          </RowManageField>
          <RowManageField>
            <ManageFieldLabel label="Network" />
            <span>{nodeDetail?.networkLabel ?? "-"}</span>
          </RowManageField>
          <ColumnManageField>
            <ManageFieldLabel label="Protocol & Blockchain Client" />
            <BlockchainEngineInfo engineInfo={engineInfo} networkData={networkData} />
          </ColumnManageField>
          <RowManageField>
            <ManageFieldLabel label="Software" />
            <span>{nodeDetail?.version ?? "-"}</span>
          </RowManageField>
          <RowManageField>
            <ManageFieldLabel label="Consensus Algorithm" />
            <span>{algorithm?.name ?? "-"}</span>
          </RowManageField>
          <RowManageField>
            <ManageFieldLabel label="Keystore" />
            <span>{nodeDetail?.address ?? "-"}</span>
          </RowManageField>
        </ManageFieldContainer>
        {nodeDetail?.mainStatus === 5 && (
          <Warning title={"Caution"} description={"The VPN key file can be downloaded when the project owner enters the ‘Network’ tab of the dashboard."} />
        )}

        {nodeDetail?.mainStatus === 0 && (
          <div className="manage-node-button-container">
            <ButtonContainer>
              <BasicButton size={ButtonSize.LARGE} onClick={() => submitNode_()}>
                Retry
              </BasicButton>
            </ButtonContainer>
          </div>
        )}

        {nodeDetail?.mainStatus === 1 && (
          <div className="manage-node-button-container">
            <ButtonContainer>
              {isOwner && (
                <BasicButton size={ButtonSize.ICON} theme={ButtonTheme.MINOR} onClick={() => deleteButtonHandler()}>
                  <img src={IconDeleteRed} style={{ width: 18, objectFit: "contain" }} alt={"delete"} />
                </BasicButton>
              )}
              <BasicButton size={ButtonSize.LARGE} onClick={() => submitNode_()}>
                Submit
              </BasicButton>
            </ButtonContainer>
          </div>
        )}

        {nodeDetail?.mainStatus === 3 && (
          <div className="manage-node-button-container">
            <ButtonContainer>
              {isOwner && (
                <BasicButton size={ButtonSize.ICON} theme={ButtonTheme.MINOR} onClick={() => deleteButtonHandler()}>
                  <img src={IconDeleteRed} style={{ width: 18, objectFit: "contain" }} alt={"delete"} />
                </BasicButton>
              )}
              <BasicButton size={ButtonSize.LARGE} onClick={() => deployNode_()}>
                Deploy
              </BasicButton>
            </ButtonContainer>
          </div>
        )}
        {nodeDetail?.mainStatus === 5 && (
          <div className="manage-node-button-container">
            <ButtonContainer>
              {isOwner && (
                <BasicButton size={ButtonSize.ICON} theme={ButtonTheme.MINOR} onClick={() => deleteButtonHandler()}>
                  <img src={IconDeleteRed} style={{ width: 18, objectFit: "contain" }} alt={"delete"} />
                </BasicButton>
              )}
              <BasicButton size={ButtonSize.LARGE} onClick={() => onClickManageConsole()} disabled={!nodeDetail?.mainStatus === 5}>
                Manage Console
              </BasicButton>
            </ButtonContainer>
          </div>
        )}
      </div>
    </PageArticle>
  );
};

export default ManageNode;
