import { useEffect, useState } from "react";
import "./CardPreviewer.scss";
import downIcon from "../../../../view/assets/images/icon-card-previewer-down.png";
import upIcon from "../../../../view/assets/images/icon-card-previewer-up.png";
import copyIcon from "../../../../view/assets/images/icon-card-previewer-copy.png";
import defaultBackground from "../../../../view/assets/images/bg-card-previewer-default.png";

const CardPreviewer = (props) => {
  const { cardImage, cardColor, networkLabel, symbol = "", style } = props || {};

  const [previewImage, setPreviewImage] = useState(null);

  const readImage = () => {
    if (!cardImage) {
      return;
    }
    if (typeof cardImage === "string") {
      setPreviewImage(cardImage);
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(cardImage);
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
    }
  };

  useEffect(() => {
    readImage();
  }, [cardImage]);

  return (
    <div className="card-previewer" style={{ color: cardColor, ...(style && style) }}>
      <img className="card-previewer-image" src={previewImage ? previewImage : defaultBackground} alt="" />
      <div className="card-previewer-content">
        <div className="card-previewer-header">
          <div className="card-previewer-network-info">
            <span className="card-previewer-network-label">{networkLabel}</span>
          </div>
          <div className="card-previewer-address-wrapper">
            <span className="card-previewer-address">{`0x2AC3...Y672`}</span>
            <div className="card-previewer-copy">
              <img className="card-previewer-copy-src" src={copyIcon} alt="" />
            </div>
          </div>
        </div>

        <div className="card-previewer-middle">
          <span className="card-previewer-amount">{`100,000 ${symbol}`}</span>
        </div>
        <div className="card-previewer-footer">
          <div className="card-previewer-token-count">{`3 Tokens`}</div>
          <div className="card-previewer-button-wrapper">
            <img className="card-previewer-button" src={downIcon} alt="" />
            <img className="card-previewer-button" src={upIcon} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardPreviewer;
