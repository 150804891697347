import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import PageLoading from "../../../../components/_atom/loading/PageLoading";
import { errorAlert } from "../../../../../utils/Utils";
import Section from "legacy-common/atom/section/Section";
import { getPaymentMethodListAction } from "../../../../../action/paymentGatewayAction";
import Table from "../../../../../legacy-common/atom/table/Table";
import { useSelector } from "react-redux";
import { paymentTypeLabelParser, selectAllPaymentType } from "../../../../../reducer/presetReducer";
import PageArticle from "../../../../components/_template/page/PageArticle";
import PageTitle from "../../../../components/_molecules/page/PageTitle";
import BasicButton from "view/components/_atom/button/BasicButton";
import { ButtonSize, ButtonTheme } from "view/components/_atom/button/StyledButton";
import useLoading from "hooks/useLoading";
import useGetSelectedProject from "hooks/useGetSelectedProject";

function PaymentGatewayList(props) {
  const navigate = useNavigate();
  const paymentTypePreset = useSelector(selectAllPaymentType);
  const { selectedProjectID } = useGetSelectedProject();

  const [paymentGatewayList, setPaymentGatewayList] = useState([]);

  const getPaymentGatewayList_ = async () => {
    const { error, result } = await getPaymentMethodListAction();
    if (result) {
      setPaymentGatewayList(result);
    }
    if (error) {
      errorAlert(error.data.message);
    }
  };

  const headers = useMemo(
    () => [
      { title: "Payment gateway name", accessor: "name" },
      { title: "Network", accessor: "microChainLabel" },
      { title: "Currency", accessor: "currencyLabel" },
      { title: "Type", accessor: "paymentType" },
    ],
    []
  );

  const renderer = useCallback(
    (data) => ({
      name: data.name,
      microChainLabel: data.microChainLabel,
      currencyLabel: data.currencyDetail.currency.symbol,
      paymentType: paymentTypeLabelParser(paymentTypePreset.find((type) => type.paymentTypeId === data.paymentTypeId)?.name),
    }),
    []
  );

  const onClickList = (el) => {
    navigate(`${el.paymentMethodId}`);
  };

  const paymentGatewayListRenderer = () => [
    {
      renderers: [
        {
          value: <Table headers={headers} data={paymentGatewayList} renderer={renderer} onClick={onClickList} noDataPlaceholder={"No payment gateway"} />,
        },
      ],
    },
  ];

  const { loading } = useLoading({
    dependency: [selectedProjectID],
    synchronousFunction: async () => {
      await getPaymentGatewayList_();
    },
  });

  if (loading) {
    return <PageLoading />;
  }

  return (
    <PageArticle>
      <PageTitle title={"Payment Gateway List"} size={1240} />
      <div className="page-layout-1240">
        <BasicButton theme={ButtonTheme.CREATE} size={ButtonSize.EXTRALARGE} onClick={() => navigate("/developer/payment-gateway/create")}>
          Create Payment Gateway
        </BasicButton>
        <div style={{ height: 30 }} />
        <Section title={"Payment Gateway"} view={paymentGatewayListRenderer} />
      </div>
    </PageArticle>
  );
}

export default PaymentGatewayList;
