import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "../../../../components/_atom/select/Select";
import FileDragAndDrop from "../../../../components/_atom/file/FileDragAndDrop";
import RadioButtons from "../../../../../legacy-common/molcules/RadioButtons/RadioButtons";
import { errorAlert, onChangeInputHandler } from "../../../../../utils/Utils";
import { CONTRACT_DISCLOSURE, CONTRACT_ERC, CONTRACT_TYPE } from "../constants/contract.data";
import toast from "react-hot-toast";
import useValidateCondition from "../../../../../hooks/useValidateCondition";
import { VC__is_empty_value, VC__is_not_version, VC__is_text_exceed_limit } from "../../../../../modules/validation/validationCondition";
import useIsAble from "../../../../../hooks/useIsAble";
import BasicButton from "../../../../components/_atom/button/BasicButton";
import CommentInput from "../../../../components/_atom/input/CommentInput";
import BasicTextarea from "../../../../components/_atom/input/BasicTextarea";
import { createContractAction } from "../../../../../action/contractLibraryAction";
import PageArticle from "../../../../components/_template/page/PageArticle";
import PageTitle from "../../../../components/_molecules/page/PageTitle";
import { ButtonSize } from "view/components/_atom/button/StyledButton";
import { addDescriptionRecursive, readFile } from "../utils/contractUtils";
import { generateABiFile } from "../constants/contract.utils";
import { FieldContainer, FieldLabel, RowField } from "view/components/_atom/section/Field";
import contractIcon from "../../../../assets/images/service/Icon_SNB_contract_library.png";

const CreatePrimaryContractVersion = () => {
  const navigate = useNavigate();

  const [contractInfo, setContractInfo] = useState({
    title: "",
    description: "",
  });
  const [versionInfo, setVersionInfo] = useState({
    version: "",
    versionDescription: "",
  });
  const [contractErcType, setContractErcType] = useState(CONTRACT_ERC[0].value);
  const [contractType, setContractType] = useState(CONTRACT_TYPE[0].value);
  const [contractDisclosure, setContractDisclosure] = useState(CONTRACT_DISCLOSURE[0].value);
  const [solidityFile, setSolidityFile] = useState(null);
  const [byteFile, setByteFile] = useState(null);
  const [abiFile, setAbiFile] = useState(null);

  const titleValidation = useValidateCondition(
    [contractInfo.title],
    [
      {
        condition: !VC__is_empty_value(contractInfo.title),
        message: "",
      },
      {
        condition: !VC__is_text_exceed_limit(contractInfo.title, 50),
        message: "Please enter under 50 characters",
      },
    ]
  );

  const descriptionValidation = useValidateCondition(
    [contractInfo.description],
    [
      {
        condition: !VC__is_text_exceed_limit(contractInfo.description, 1000),
        message: "Please enter under 1,000 characters",
      },
    ]
  );

  const versionValidation = useValidateCondition(
    [versionInfo.version],
    [
      {
        condition: !VC__is_empty_value(versionInfo.version),
        message: "",
      },
      {
        condition: !VC__is_not_version(versionInfo.version),
        message: "Version must be include number & .",
      },
    ]
  );

  const versionDescriptionValidation = useValidateCondition(
    [versionInfo.versionDescription],
    [
      {
        condition: !VC__is_text_exceed_limit(versionInfo.versionDescription, 1000),
        message: "Please enter under 1,000 characters",
      },
    ]
  );

  const isAble = useIsAble([
    titleValidation.isValid,
    descriptionValidation.isValid,
    versionValidation.isValid,
    versionDescriptionValidation.isValid,
    solidityFile,
    byteFile,
    abiFile,
  ]);

  const onClickCreateContractButton = async () => {
    let formData = new FormData();
    formData.append("title", contractInfo.title);
    formData.append("description", contractInfo.description);
    formData.append("type", contractType);
    formData.append("version", versionInfo.version);
    formData.append("versionDescription", versionInfo.versionDescription);
    formData.append("isPrivate", contractDisclosure);
    Boolean(contractErcType) && formData.append("ercType", contractErcType);
    formData.append("solidityCode", solidityFile);
    formData.append("abiCode", abiFile);
    formData.append("byteCode", byteFile);

    const ABICode = await readFile(abiFile);

    const ABICodeForView = addDescriptionRecursive(ABICode, "inputs");

    const ABIViewFile = generateABiFile(ABICodeForView, abiFile.name.split(".")[0]);

    formData.append("customAbiCode", ABIViewFile);

    const { error, result } = await createContractAction(4, formData);
    if (result) {
      const { contractId, versionId } = result;
      toast.success("Successfully create contract");
      navigate(`/developer/contract/storage/${contractId}/version/${versionId}`);
    }
    if (error) {
      errorAlert(error.data.message);
    }
  };

  return (
    <PageArticle>
      <PageTitle title={"Create Contract"} size={810} />
      <div className="page-layout-810">
        {/* Basic Info Description :: Set the basic information for the smart contract you want to create. */}
        <FieldContainer icon={contractIcon} title="Basic Info">
          <RowField>
            <FieldLabel label="Title" />
            <CommentInput
              name={"title"}
              {...titleValidation}
              value={contractInfo.title}
              onChange={(e) => onChangeInputHandler(setContractInfo, "title", e.target.value)}
              placeholder={"Enter the name of Contract. (Ex. EQ Token Contract)"}
            />
          </RowField>
          <RowField>
            <FieldLabel label="Description" essential={false} />
            <CommentInput
              {...descriptionValidation}
              name={"description"}
              value={contractInfo.description}
              onChange={(e) => onChangeInputHandler(setContractInfo, "description", e.target.value)}
              placeholder={"Enter the description of Contract. (Ex. Contract)"}
            />
          </RowField>
          <RowField>
            <FieldLabel label="ERC" essential={false} />
            <Select placeholder={"Not selected"} options={CONTRACT_ERC} selectedValue={contractErcType} setSelectedValue={setContractErcType} />
          </RowField>
          <RowField>
            <FieldLabel label="Type" />
            <RadioButtons options={CONTRACT_TYPE} selectedValue={contractType} onChange={setContractType} />
          </RowField>
          <RowField>
            <FieldLabel label="Public / Private" />
            <RadioButtons options={CONTRACT_DISCLOSURE} selectedValue={contractDisclosure} onChange={setContractDisclosure} />
          </RowField>
          <RowField>
            <FieldLabel label="Contract version" />
            <CommentInput
              {...versionValidation}
              name={"version"}
              value={versionInfo.version}
              onChange={(e) => onChangeInputHandler(setVersionInfo, "version", e.target.value)}
              placeholder={"Enter the version of Contract"}
            />
          </RowField>
          <RowField>
            <FieldLabel label="Version description" essential={false} />
            <BasicTextarea
              name={"versionDescription"}
              value={versionInfo.versionDescription}
              onChange={(e) => onChangeInputHandler(setVersionInfo, "versionDescription", e.target.value)}
              placeholder={"Enter the description of this version"}
              rows={5}
            />
          </RowField>
        </FieldContainer>
        {/* Code Info Description :: Upload the code file of the smart contract you want to create. */}
        <FieldContainer icon={contractIcon} title="Code Info">
          <RowField>
            <FieldLabel label="Solidity Code" />
            <FileDragAndDrop
              file={solidityFile}
              setFile={setSolidityFile}
              allowExtension={[".sol", ".zip"]}
              placeholder={"Drag file or click to upload Solidity code"}
            />
          </RowField>
          <RowField>
            <FieldLabel label="Byte Code" />
            <FileDragAndDrop file={byteFile} setFile={setByteFile} allowExtension={[".txt"]} placeholder={"Drag file or click to upload Byte code"} />
          </RowField>
          <RowField>
            <FieldLabel label="Abi Code" />
            <FileDragAndDrop file={abiFile} setFile={setAbiFile} allowExtension={[".txt", ".json"]} placeholder={"Drag file or click to upload Abi code"} />
          </RowField>
        </FieldContainer>
        <div className="flex justify-content-flex-end">
          <BasicButton size={ButtonSize.LARGE} disabled={!isAble} onClick={() => onClickCreateContractButton()}>
            Create
          </BasicButton>
        </div>
      </div>
    </PageArticle>
  );
};
export default CreatePrimaryContractVersion;
