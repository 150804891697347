import { useState } from "react";
import toast from "react-hot-toast";
import IconCopyDefault from "../../../assets/images/icon_copy_default.png";
import IconCopyHover from "../../../assets/images/icon_copy_hover.png";
import "./CopyLabelWithIcon.scss";

type CopyLabelWithIconProps = {
  text: string;
  isDashboard?: boolean;
  maxWidth?: number;
};

const CopyLabelWithIcon = ({ text, isDashboard = false, maxWidth }: CopyLabelWithIconProps) => {
  const [isIconHover, setIsIconHover] = useState(false);
  const onClickCopyAddress = (text: string) => {
    window.navigator.clipboard.writeText(text).then(() => toast.success("Copied!"));
  };
  return (
    <div className="copy-label-with-icon">
      {text ? (
        <>
          <span className={isDashboard ? "dashboard-copy-label" : ""} data-legend-key={text}>
            {text}
          </span>
          <div className="copy-icon-wrapper" onMouseOver={() => setIsIconHover(true)} onMouseLeave={() => setIsIconHover(false)}>
            <img className="copy-icon" src={isIconHover ? IconCopyHover : IconCopyDefault} alt="copy" onClick={() => onClickCopyAddress(text)} />
          </div>
        </>
      ) : (
        <span>-</span>
      )}
    </div>
  );
};

export default CopyLabelWithIcon;
