import React from "react";
import "./PageHeader.scss";

const PageHeader = (props) => {
  const { title } = props;
  return (
    <div className="page-header">
      <div className="page-header-title">{title}</div>
    </div>
  );
};

export default PageHeader;
