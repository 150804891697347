import { useEffect, useMemo, useState } from "react";
import "./CreateNFTProject.scss";
import Select from "../../../../components/_atom/select/Select";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import {
  VALIDATION__NFT_project_description,
  VALIDATION__NFT_project_image,
  VALIDATION__NFT_project_links,
  VALIDATION__NFT_project_name,
  VALIDATION__NFT_project_symbol,
} from "../validation/NFTValidation";
import { errorAlert } from "../../../../../utils/Utils";
import ImageUpload from "view/components/_atom/file/ImageUpload";
import MultipleInput from "view/components/_molecules/input/MultipleInput";
import RadioButtons from "legacy-common/molcules/RadioButtons/RadioButtons";
import { MINTING } from "../constant/nftConstant";
import { createNFTProjectAction, getInitialDataForCreateNFTProjectAction, uploadProjectImageToS3Action } from "action/nftAction";
import PageArticle from "view/components/_template/page/PageArticle";
import PageTitle from "view/components/_molecules/page/PageTitle";
import PageLoading from "view/components/_atom/loading/PageLoading";
import BasicButton from "view/components/_atom/button/BasicButton";
import { ButtonSize } from "view/components/_atom/button/StyledButton";
import useLoading from "hooks/useLoading";
import { distinguishValidation } from "modules/validation/validationCondition";
import CommentInput from "view/components/_atom/input/CommentInput";
import useValidateCondition from "hooks/useValidateCondition";
import useIsAble from "hooks/useIsAble";
import useGetSelectedProject from "hooks/useGetSelectedProject";
import nftIcon from "../../../../assets/images/service/Icon_SNB_non_fungible_token.png";
import { FieldContainer, FieldLabel, RowField } from "view/components/_atom/section/Field";
import BasicTextarea from "view/components/_atom/input/BasicTextarea";

const CreateNFTProject = () => {
  const navigate = useNavigate();
  const { selectedProjectID } = useGetSelectedProject();

  //========= Get initial data NFT Project =========//
  const [allNetworkList, setAllNetworkList] = useState([]);
  const [allMicroChainList, setAllMicroChainList] = useState([]);

  const getInitialDataForCreateNFT_ = async () => {
    const { result, error } = await getInitialDataForCreateNFTProjectAction();
    if (result) {
      const { networks, microChains } = result;
      setAllMicroChainList(microChains);
      setAllNetworkList(networks);
    }
    if (error) {
      errorAlert(error.data.message);
    }
  };

  //========= Select NFT Project Network/Microchain =========//
  const [availableMicroChainList, setAvailableMicroChainList] = useState([]);
  const [selectedNetwork, setSelectedNetwork] = useState(null);
  const [selectedMicroChain, setSelectedMicroChain] = useState(null);
  useEffect(() => {
    if (selectedNetwork) {
      const availableMicroChains = allMicroChainList.filter((el) => el.networkId === selectedNetwork);
      if (availableMicroChains.length !== 0) {
        setSelectedMicroChain(availableMicroChains.find((chain) => Boolean(chain.isMain)).id ?? null);
      } else {
        setSelectedMicroChain(null);
      }
      setAvailableMicroChainList(availableMicroChains);
    }
  }, [selectedNetwork]);

  //========= Input NFT Project Data =========//
  const [projectName, setProjectName] = useState("");
  const [tokenSymbol, setTokenSymbol] = useState("");
  const [projectDescription, setProjectDescription] = useState("");
  const [projectImage, setProjectImage] = useState(null);
  const [projectLinks, setProjectLinks] = useState([]);
  const [mintTiming, setMintTiming] = useState(1);

  //========= Create NFT Project =========//

  const nameValidation = useValidateCondition([projectName], VALIDATION__NFT_project_name(projectName));
  const symbolValidation = useValidateCondition([tokenSymbol], VALIDATION__NFT_project_symbol(tokenSymbol));
  const descriptionValidation = useValidateCondition([projectDescription], VALIDATION__NFT_project_description(projectDescription));
  const imageValidation = useValidateCondition([projectImage], VALIDATION__NFT_project_image(projectImage));
  const linksValidation = useValidateCondition([projectLinks], VALIDATION__NFT_project_links(projectLinks));

  const isAble = useIsAble([
    Boolean(selectedNetwork),
    Boolean(selectedMicroChain),
    nameValidation.isValid,
    symbolValidation.isValid,
    descriptionValidation.isValid,
    imageValidation.isValid,
  ]);

  const NFTProjectValidationResult = useMemo(() => {
    return distinguishValidation([nameValidation, symbolValidation, descriptionValidation, imageValidation, linksValidation]);
  }, [nameValidation, symbolValidation, descriptionValidation, imageValidation, linksValidation]);

  const uploadProjectImageToS3_ = async () => {
    let formData = new FormData();
    formData.append("image", projectImage);
    const { result, error } = await uploadProjectImageToS3Action(formData);
    if (result) {
      return result;
    }
    if (error) {
      errorAlert(error.data.message);
    }
  };

  const createNFTProject_ = async () => {
    if (!NFTProjectValidationResult.isValid) {
      toast.error(NFTProjectValidationResult.message);
      return;
    }

    const imageUrl = await uploadProjectImageToS3_();
    const { result, error } = await createNFTProjectAction(
      selectedMicroChain,
      tokenSymbol,
      projectName,
      projectDescription,
      imageUrl,
      false,
      false,
      projectLinks
    );
    if (result) {
      toast.success("Create successfully");
      navigate(`/application/nft/manage/${result.erc721_temp_id}`);
    }
    if (error) {
      errorAlert(error.data.message);
    }
  };

  const { loading } = useLoading({
    dependency: [selectedProjectID],
    synchronousFunction: async () => {
      await getInitialDataForCreateNFT_();
    },
  });

  if (loading) {
    return <PageLoading />;
  }
  return (
    <PageArticle>
      <PageTitle
        title={"Create NFT Project"}
        description={"Create your own NFT project.\n You can mint the NFT you want through the NFT project and manage it in the EQ Hub wallet."}
        size={810}
      />
      <div className="page-layout-810">
        {/* Basic Info Description :: Set the basic information for the NFT project you want to create. */}
        <FieldContainer icon={nftIcon} title="Basic Info">
          <RowField>
            <FieldLabel label="Network / Microchain" />
            <div className="create-nft-project-chain-selector-container">
              <Select
                labelKey={"label"}
                valueKey={"id"}
                options={allNetworkList}
                selectedValue={selectedNetwork}
                setSelectedValue={setSelectedNetwork}
                canSelect={allNetworkList.length !== 0}
                placeholder={"Select Network"}
              />
              <Select
                labelKey={"label"}
                valueKey={"id"}
                options={availableMicroChainList}
                selectedValue={selectedMicroChain}
                setSelectedValue={setSelectedMicroChain}
                canSelect={selectedNetwork}
                placeholder={"Select MicroChain"}
              />
            </div>
          </RowField>
          <RowField>
            <FieldLabel label="Project name" />
            <CommentInput {...nameValidation} value={projectName} onChange={(e) => setProjectName(e.target.value)} placeholder={"Enter NFT Project name"} />
          </RowField>
        </FieldContainer>

        {/* Details Dsecription :: Enter details for the NFT project you want to create. */}
        <FieldContainer icon={nftIcon} title="Details">
          <RowField>
            <FieldLabel label="Project symbol" />
            <CommentInput
              {...symbolValidation}
              value={tokenSymbol}
              onChange={(e) => setTokenSymbol(e.target.value.toUpperCase())}
              placeholder={"Enter NFT Project symbol"}
            />
          </RowField>
          <RowField>
            <FieldLabel label="Project description" />
            <BasicTextarea
              value={projectDescription}
              onChange={(e) => setProjectDescription(e.target.value)}
              placeholder={"Enter the description of NFT project"}
              rows={5}
            />
          </RowField>
          <RowField>
            <FieldLabel label="Project image" />
            <ImageUpload
              style={{ alignItems: "flex-start" }}
              image={projectImage}
              setImage={setProjectImage}
              comment={"Please upload an image over 130x130 size."}
              previewStyle={{ borderRadius: 8 }}
              buttonText={"Upload"}
            />
          </RowField>
          <RowField>
            <FieldLabel label="Project links" essential={false} />
            <MultipleInput placeholder={"https://www.nftproject.com/"} inputArray={projectLinks} setInputArray={setProjectLinks} />
          </RowField>
          <RowField>
            <FieldLabel label="The time of minting" />
            <RadioButtons options={MINTING} selectedValue={mintTiming} onChange={setMintTiming} />
          </RowField>
        </FieldContainer>

        <div className="create-nft-project-button-container">
          <BasicButton size={ButtonSize.LARGE} disabled={!isAble} onClick={() => createNFTProject_()}>
            Create
          </BasicButton>
        </div>
      </div>
    </PageArticle>
  );
};

export default CreateNFTProject;
