import { parseAction } from "./util/parseAction";
import {
  confirmNetworkParser,
  createNetworkParser,
  deleteNetworkParser,
  getAlgorithmChainClientVersionListParser,
  getAlgorithmListParser,
  getChainClientVersionListParser,
  getChainClientVersionParser,
  getNetworkListParser,
  getMicroChainSettingParser,
  getNetworkAllocParser,
  getNetworkDetailParser,
  getNetworkPlanListParser,
  getNetworkPlanParser,
  getNetworkRejectReasonParser,
  getProtocolChainClientListParser,
  getProtocolListParser,
  rejectNetworkParser,
  releaseNetworkParser,
  submitNetworkParser,
  updateNetworkParser,
} from "../parser/networkParser";
import { getCoinDetailParser, getCurrenciesParser, uploadTokenImageToS3Parser } from "../parser/currencyParser";
import { CURRENCY_MAIN_STATUS } from "../view/service/network/network/constants/networkConstants";
import { WEB3 } from "../modules/web3/Web3";
import { addComma, removeComma } from "../utils/Utils";
import { mulCalculate, plusCalculate } from "view/service/network/network/util/networkUtils";
import { divCalculate } from "../view/service/network/network/util/networkUtils";
import { networkTypeConverter } from "view/service/network/network/constants/network.utils";

export const getNetworkPlanListAction = (options?: { isPrivate: string }) =>
  parseAction(async () => {
    return await getNetworkPlanListParser(options);
  });

export const getBlockchainEngineListAction = () =>
  parseAction(async () => {
    const protocolList = await getProtocolListParser();
    const protocolIds = protocolList.map((el) => el.protocolId);
    const protocolChainClientList = await Promise.all(
      protocolIds.map(async (protocolId) => {
        return await getProtocolChainClientListParser(protocolId);
      })
    );

    return protocolChainClientList.flat();
  });

export const getAlgorithmChainClientVersionListAction = (chainClientId: number) =>
  parseAction(async () => {
    const versionList = await getChainClientVersionListParser(chainClientId);
    const { chainClientVersionId } = versionList[0] || {};
    const algorithmList = await getAlgorithmChainClientVersionListParser(chainClientVersionId);

    return {
      versionList,
      algorithmList,
    };
  });

export const getNetworkListAction = (options?: { isExternal?: boolean; projectId?: number; mainStatus?: number[] }) =>
  parseAction(async () => {
    const currencyType = [0];
    const networkList = await getNetworkListParser(options);

    let response: Array<any> = [];

    if (networkList.length > 0) {
      const originMicroChainIds = networkList.map((el: any) => el.mainMicroChainId);
      const currencyList = await getCurrenciesParser({
        originMicroChainIds,
        type: currencyType,
        mainStatus: CURRENCY_MAIN_STATUS,
      });

      response = networkList.map((network: any) => {
        const currency = currencyList.find((currency: any) => currency.originMicroChainId === network.mainMicroChainId);

        return { ...network, currency };
      });
    }

    return response;
  });

export const getNetworkDetailForEditAction = (networkId: number) =>
  parseAction(async () => {
    const [networkDetail, allocList, microChainSettingDetail] = await Promise.all([
      getNetworkDetailParser(networkId),
      getNetworkAllocParser(networkId),
      getMicroChainSettingParser(networkId),
    ]);

    const currencyDetail = await getCoinDetailParser(networkDetail.microChain.id);

    let rejectReason: string | undefined;
    if (networkDetail.mainStatus === 0) {
      const rejectReasonDetail = await getNetworkRejectReasonParser(networkId);
      rejectReason = rejectReasonDetail[0];
    }
    const parseNodeAllocList = allocList
      .filter((el) => el.isValidator === true)
      .map((alloc: any) => ({
        ...alloc,
        keyStoreFile: JSON.stringify(alloc.keyStore),
        ...(alloc.mmcBalance && { mmcBalance: addComma(WEB3.fromWei(alloc.mmcBalance)) }),
        ...(alloc.mmcStaking && { mmcStaking: addComma(WEB3.fromWei(alloc.mmcStaking)) }),
        mmcBalancePercent: ((Number(WEB3.fromWei(alloc.mmcBalance)) / Number(WEB3.fromWei(microChainSettingDetail.initialSupply))) * 100).toFixed(1),
      }));

    const parsePreAllocList = allocList
      .filter((el) => el.isValidator === false)
      .map((alloc: any) => ({
        ...alloc,
        mmcBalance: addComma(WEB3.fromWei(alloc.mmcBalance)),
        distributionPercent: ((Number(WEB3.fromWei(alloc.mmcBalance)) / Number(WEB3.fromWei(microChainSettingDetail.initialSupply))) * 100).toFixed(1),
      }));

    const response = {
      ...(rejectReason && { rejectReason }),
      nodeAlloc: parseNodeAllocList,
      preAlloc: parsePreAllocList,
      microChainSetting: {
        ...microChainSettingDetail,
        initialSupply: addComma(WEB3.fromWei(microChainSettingDetail.initialSupply)),
        isContractUploadAvailability: Boolean(microChainSettingDetail.isContractUploadAvailability),
        isNodeParticipationAvailability: Boolean(microChainSettingDetail.isNodeParticipationAvailability),
        isTestnet: Boolean(microChainSettingDetail.isTestnet),
        ...(microChainSettingDetail.fee && { fee: WEB3.fromWei(microChainSettingDetail.fee) }),
        ...(microChainSettingDetail.inflationRate && { inflationRate: mulCalculate(microChainSettingDetail.inflationRate, "100") }),
        ...(microChainSettingDetail.disInflationRate && { disInflationRate: mulCalculate(microChainSettingDetail.disInflationRate, "100") }),
      },
      network: networkDetail.network,
      microChain: networkDetail.microChain,
      currency: currencyDetail,
      mainStatus: networkDetail.mainStatus,
      subStatus: networkDetail.subStatus,
      createdAt: networkDetail.createdAt,
      updatedAt: networkDetail.updatedAt,
    };

    return response;
  });

export const getNetworkDetailAction = (networkId: number) =>
  parseAction(async () => {
    const networkDetail = await getNetworkDetailParser(networkId);
    const networkPlan = await getNetworkPlanParser(networkDetail.network.networkPlanId);

    let rejectReason: string | undefined;
    if (networkDetail.mainStatus === 0) {
      const rejectReasonDetail = await getNetworkRejectReasonParser(networkId);
      rejectReason = rejectReasonDetail[0];
    }

    const { microChain } = networkDetail;
    const { protocolId, chainClientVersionId, algorithmId } = microChain;

    let networkType: number | undefined;
    if (Boolean(protocolId) && Boolean(chainClientVersionId) && Boolean(algorithmId)) {
      networkType = networkTypeConverter(protocolId, chainClientVersionId, algorithmId);
    }

    const response = {
      ...(rejectReason && { rejectReason }),
      ...(networkType && { networkType }),
      network: networkDetail.network,
      microChain: networkDetail.microChain,
      mainStatus: networkDetail.mainStatus,
      subStatus: networkDetail.subStatus,
      createdAt: networkDetail.createdAt,
      updatedAt: networkDetail.updatedAt,
      networkPlan,
    };

    return response;
  });

export const getNetworkBasicInfoAction = (networkId: number) =>
  parseAction(async () => {
    const [networkDetail, microChainSetting] = await Promise.all([getNetworkDetailParser(networkId), getMicroChainSettingParser(networkId)]);

    const { microChain } = networkDetail;
    const currency = await getCoinDetailParser(microChain.id);

    return {
      ...networkDetail,
      microChainSetting: {
        ...microChainSetting,
        isTestnet: Boolean(microChainSetting.isTestnet),
        initialSupply: WEB3.fromWei(microChainSetting.initialSupply),
        isContractUploadAvailability: Boolean(microChainSetting.isContractUploadAvailability),
        isNodeParticipationAvailability: Boolean(microChainSetting.isNodeParticipationAvailability),
      },
      currency,
    };
  });

export const getNetworkBlockchainEngineAction = (networkId: number) =>
  parseAction(async () => {
    const networkDetail = await getNetworkDetailParser(networkId);
    const { microChain } = networkDetail;

    const protocolChainClientList = await getProtocolChainClientListParser(microChain.protocolId);
    const algorithmList = await getAlgorithmListParser();

    const chainClient = protocolChainClientList.find((el) => el.chainClientId === microChain.chainClientId);
    const chainClientVersion = await getChainClientVersionParser(microChain.chainClientVersionId);
    const algorithm = algorithmList.find((el) => el.algorithmId === microChain.algorithmId);

    return { networkDetail, chainClient, chainClientVersion, algorithm };
  });

export const getNodeFromEquilibriumNetworkAction = (networkId: number) =>
  parseAction(async () => {
    const [networkDetail, alloc] = await Promise.all([getNetworkDetailParser(networkId), getNetworkAllocParser(networkId)]);
    const networkPlan = await getNetworkPlanParser(networkDetail.network.networkPlanId);
    const currency = await getCoinDetailParser(networkDetail.microChain.id);

    const nodeAlloc = alloc
      .filter((el) => el.isValidator === true)
      .map((item) => ({
        ...item,
        mmcBalance: WEB3.fromWei(item.mmcBalance),
        mmcStaking: WEB3.fromWei(item.mmcStaking),
        mmcBalancePercent: ((Number(WEB3.fromWei(item.mmcBalance)) / Number(WEB3.fromWei(networkDetail.microChainSetting.initialSupply))) * 100).toFixed(1),
      }));

    const balanceList = nodeAlloc.map((el) => el.mmcBalance).concat(["0"]);
    let totalBalance = "";

    if (balanceList.length > 0) {
      totalBalance = balanceList.reduce((acc, cur) => plusCalculate(acc, cur));
    }

    return {
      currency,
      networkPlan,
      nodeAlloc,
      totalBalance,
      microChainSetting: {
        inflationRate: mulCalculate(networkDetail.microChainSetting.inflationRate, "100"),
        disInflationRate: mulCalculate(networkDetail.microChainSetting.disInflationRate, "100"),
        fee: WEB3.fromWei(networkDetail.microChainSetting.fee),
      },
    };
  });

export const getNodeFromBesuNetworkAction = (networkId: number) =>
  parseAction(async () => {
    const [networkDetail, alloc] = await Promise.all([getNetworkDetailParser(networkId), getNetworkAllocParser(networkId)]);
    const networkPlan = await getNetworkPlanParser(networkDetail.network.networkPlanId);
    const currency = await getCoinDetailParser(networkDetail.microChain.id);

    const nodeAlloc = alloc.filter((el) => el.isValidator === true);

    return {
      currency,
      networkPlan,
      nodeAlloc,
    };
  });

export const getAdditionalFromEquilibriumNetworkAction = (networkId: number) =>
  parseAction(async () => {
    const [networkDetail, alloc] = await Promise.all([getNetworkDetailParser(networkId), getNetworkAllocParser(networkId)]);
    const currency = await getCoinDetailParser(networkDetail.microChain.id);

    const nodeAlloc = alloc
      .filter((el) => el.isValidator === true)
      .map((item) => ({
        mmcBalance: WEB3.fromWei(item.mmcBalance),
        distributionPercent: ((Number(WEB3.fromWei(item.mmcBalance)) / Number(WEB3.fromWei(networkDetail.microChainSetting.initialSupply))) * 100).toFixed(1),
      }));

    const nodeAllocBalances = nodeAlloc.map((el) => el.mmcBalance);
    const nodeAllocPercents = nodeAlloc.map((el) => el.distributionPercent);

    const totalNodeAllocPercent = nodeAllocPercents.reduce((acc, cur) => plusCalculate(acc, cur));
    const totalNodeAllocBalance = nodeAllocBalances.reduce((acc, cur) => plusCalculate(acc, cur));

    const stakingAlloc = [
      {
        name: "Staking Amount",
        address: null,
        mmcBalance: removeComma(totalNodeAllocBalance),
        distributionPercent: Number(totalNodeAllocPercent),
      },
    ];

    const preAlloc = alloc
      .filter((el) => el.isValidator === false)
      .map((item) => ({
        name: item.name,
        address: item.address,
        mmcBalance: WEB3.fromWei(item.mmcBalance),
        distributionPercent: Number(
          ((Number(WEB3.fromWei(item.mmcBalance)) / Number(WEB3.fromWei(networkDetail.microChainSetting.initialSupply))) * 100).toFixed(1)
        ),
      }));

    return {
      currency,
      alloc: stakingAlloc.concat(preAlloc),
    };
  });

export const getAdditionalFromBesuNetworkAction = (networkId: number) =>
  parseAction(async () => {
    const [networkDetail, alloc] = await Promise.all([getNetworkDetailParser(networkId), getNetworkAllocParser(networkId)]);
    const currency = await getCoinDetailParser(networkDetail.microChain.id);

    const preAlloc = alloc
      .filter((el) => el.isValidator === false)
      .map((item) => ({
        name: item.name,
        address: item.address,
        mmcBalance: WEB3.fromWei(item.mmcBalance),
        distributionPercent: Number(
          ((Number(WEB3.fromWei(item.mmcBalance)) / Number(WEB3.fromWei(networkDetail.microChainSetting.initialSupply))) * 100).toFixed(1)
        ),
      }));

    return {
      currency,
      preAlloc,
    };
  });

export const createEquilibriumNetworkAction = (network, microChain, microChainSetting, nodeAlloc, preAlloc, currency) =>
  parseAction(async () => {
    let s3URL: string;
    if (typeof currency.image !== "string") {
      let formData = new FormData();
      formData.append("", currency.image);
      s3URL = await uploadTokenImageToS3Parser(formData, 1);
    } else {
      s3URL = currency.image;
    }

    const parseMicroChainSetting = {
      ...microChainSetting,
      fee: WEB3.toWei(microChainSetting.fee),
      initialSupply: WEB3.toWei(removeComma(microChainSetting.initialSupply)),
      inflationRate: Number(divCalculate(microChainSetting.inflationRate, "100")),
      disInflationRate: Number(divCalculate(microChainSetting.disInflationRate, "100")),
    };

    const parseCurrency = {
      ...currency,
      image: s3URL,
    };

    const parseNodeAlloc = nodeAlloc.map((el) => {
      const balance = WEB3.toWei(removeComma(el.mmcBalance));
      return {
        address: el.address,
        encryptedKey: el.encryptedKey,
        isValidator: el.isValidator,
        keyStore: el.keyStore,
        name: el.name,
        mmcBalance: balance,
        mmcStaking: balance,
      };
    });

    const parsePreAlloc = preAlloc.map((el) => {
      const balance = WEB3.toWei(removeComma(el.mmcBalance));
      return {
        address: el.address,
        name: el.name,
        mmcBalance: balance,
      };
    });

    const alloc = [].concat(parseNodeAlloc).concat(parsePreAlloc);
    return await createNetworkParser(network, microChain, parseMicroChainSetting, alloc, parseCurrency);
  });

export const updateEquilibriumNetworkAction = (network, microChain, microChainSetting, nodeAlloc, preAlloc, currency) =>
  parseAction(async () => {
    let s3URL: string;
    if (typeof currency.image !== "string") {
      let formData = new FormData();
      formData.append("", currency.image);
      s3URL = await uploadTokenImageToS3Parser(formData, 1);
    } else {
      s3URL = currency.image;
    }

    const parseNetwork = {
      label: network.label,
      networkPlanId: network.networkPlanId,
    };

    const parseMicroChain = {
      protocolId: microChain.protocolId,
      chainClientId: microChain.chainClientId,
      chainClientVersionId: microChain.chainClientVersionId,
      algorithmId: microChain.algorithmId,
      label: microChain.label,
    };

    const parseMicroChainSetting = {
      isTestnet: microChainSetting.isTestnet,
      isContractUploadAvailability: microChainSetting.isContractUploadAvailability,
      isNodeParticipationAvailability: microChainSetting.isNodeParticipationAvailability,
      fee: WEB3.toWei(microChainSetting.fee),
      initialSupply: WEB3.toWei(removeComma(microChainSetting.initialSupply)),
      inflationRate: Number(divCalculate(microChainSetting.inflationRate, "100")),
      disInflationRate: Number(divCalculate(microChainSetting.disInflationRate, "100")),
    };

    const parseCurrency = {
      name: currency.name,
      symbol: currency.symbol,
      image: s3URL,
    };

    const parseNodeAlloc = nodeAlloc.map((el) => {
      const balance = WEB3.toWei(removeComma(el.mmcBalance));
      return {
        address: el.address,
        encryptedKey: el.encryptedKey,
        isValidator: el.isValidator,
        keyStore: el.keyStore,
        name: el.name,
        mmcBalance: balance,
        mmcStaking: balance,
      };
    });

    const parsePreAlloc = preAlloc.map((el) => {
      const balance = WEB3.toWei(removeComma(el.mmcBalance));
      return {
        address: el.address,
        name: el.name,
        mmcBalance: balance,
      };
    });

    const alloc = [].concat(parseNodeAlloc).concat(parsePreAlloc);
    return await updateNetworkParser(network.id, parseNetwork, parseMicroChain, parseMicroChainSetting, alloc, parseCurrency);
  });

export const createBesuNetworkAction = (network, microChain, microChainSetting, nodeAlloc, preAlloc, currency) =>
  parseAction(async () => {
    let s3URL: string;
    if (typeof currency.image !== "string") {
      let formData = new FormData();
      formData.append("", currency.image);
      s3URL = await uploadTokenImageToS3Parser(formData, 1);
    } else {
      s3URL = currency.image;
    }

    const parseMicroChainSetting = {
      initialSupply: WEB3.toWei(removeComma(microChainSetting.initialSupply)),
      isTestnet: microChainSetting.isTestnet,
      isContractUploadAvailability: microChainSetting.isContractUploadAvailability,
      isNodeParticipationAvailability: microChainSetting.isNodeParticipationAvailability,
    };

    const parseCurrency = {
      ...currency,
      image: s3URL,
    };

    const parseNodeAlloc = nodeAlloc.map((el) => {
      return {
        address: el.address,
        name: el.name,
        keyStore: el.keyStore,
        encryptedKey: el.encryptedKey,
        isValidator: el.isValidator,
      };
    });

    const parsePreAlloc = preAlloc.map((el) => {
      const balance = WEB3.toWei(removeComma(el.mmcBalance));
      return {
        address: el.address,
        name: el.name,
        mmcBalance: balance,
      };
    });

    const alloc = [].concat(parseNodeAlloc).concat(parsePreAlloc);
    return await createNetworkParser(network, microChain, parseMicroChainSetting, alloc, parseCurrency);
  });

export const updateBesuNetworkAction = (network, microChain, microChainSetting, nodeAlloc, preAlloc, currency) =>
  parseAction(async () => {
    let s3URL: string;
    if (typeof currency.image !== "string") {
      let formData = new FormData();
      formData.append("", currency.image);
      s3URL = await uploadTokenImageToS3Parser(formData, 1);
    } else {
      s3URL = currency.image;
    }

    const parseNetwork = {
      label: network.label,
      networkPlanId: network.networkPlanId,
    };

    const parseMicroChain = {
      protocolId: microChain.protocolId,
      chainClientId: microChain.chainClientId,
      chainClientVersionId: microChain.chainClientVersionId,
      algorithmId: microChain.algorithmId,
      label: microChain.label,
    };

    const parseMicroChainSetting = {
      initialSupply: WEB3.toWei(removeComma(microChainSetting.initialSupply)),
      isTestnet: microChainSetting.isTestnet,
      isContractUploadAvailability: microChainSetting.isContractUploadAvailability,
      isNodeParticipationAvailability: microChainSetting.isNodeParticipationAvailability,
    };

    const parseCurrency = {
      name: currency.name,
      symbol: currency.symbol,
      image: s3URL,
    };

    const parseNodeAlloc = nodeAlloc.map((el) => {
      return {
        address: el.address,
        name: el.name,
        keyStore: el.keyStore,
        encryptedKey: el.encryptedKey,
        isValidator: el.isValidator,
      };
    });

    const parsePreAlloc = preAlloc.map((el) => {
      const balance = WEB3.toWei(removeComma(el.mmcBalance));
      return {
        address: el.address,
        name: el.name,
        mmcBalance: balance,
      };
    });

    const alloc = [].concat(parseNodeAlloc).concat(parsePreAlloc);
    return await updateNetworkParser(network.id, parseNetwork, parseMicroChain, parseMicroChainSetting, alloc, parseCurrency);
  });

export const submitNetworkAction = (networkId: number) =>
  parseAction(async () => {
    const submitResult = await submitNetworkParser(networkId);

    const networkDetail = await getNetworkDetailParser(networkId);
    const networkPlan = await getNetworkPlanParser(networkDetail.network.networkPlanId);

    const { microChain } = networkDetail;
    const { protocolId, chainClientVersionId, algorithmId } = microChain;

    let networkType: number | undefined;
    if (Boolean(protocolId) && Boolean(chainClientVersionId) && Boolean(algorithmId)) {
      networkType = networkTypeConverter(protocolId, chainClientVersionId, algorithmId);
    }

    const response = {
      ...(networkType && { networkType }),
      network: networkDetail.network,
      microChain: networkDetail.microChain,
      mainStatus: networkDetail.mainStatus,
      subStatus: networkDetail.subStatus,
      createdAt: networkDetail.createdAt,
      updatedAt: networkDetail.updatedAt,
      networkPlan,
    };

    return response;
  });

export const releaseNetworkAction = (networkId: number) =>
  parseAction(async () => {
    return await releaseNetworkParser(networkId);
  });

export const deleteNetworkAction = (networkId: number) =>
  parseAction(async () => {
    return await deleteNetworkParser(networkId);
  });

export const rejectNetworkAction = (networkId: number, rejectReason: string) =>
  parseAction(async () => {
    return await rejectNetworkParser(networkId, rejectReason);
  });

export const confirmNetworkAction = (networkId: number) =>
  parseAction(async () => {
    return await confirmNetworkParser(networkId);
  });
