import React, {useEffect, useMemo, useState} from "react";
import "./Select.scss";
import IconSelectArrow from "../../../../view/assets/images/atom/icon-select-arrow.png";
import {useParams} from "react-router-dom";

const SelectBox = (props) => {
    const {
      placeholder = "--",
      options,
      labelKey = "label",
      valueKey = "value",
      disabled,
      canSelect = true,
      description,
      style,
      selectedValue,
      setSelectedValue,
    } = props || {};

    const { versionId } = useParams();
    const [isOptionPanelOpen, setIsOptionPanelOpen] = useState(false);

    useEffect(() => {
      setSelectedValue(parseInt(versionId));
    }, [versionId]);

    useEffect(() => {
      function closeOptionPanel() {
        setIsOptionPanelOpen(false);
      }

      if (isOptionPanelOpen) window.addEventListener("click", closeOptionPanel);
      return () => {
        window.removeEventListener("click", closeOptionPanel);
      };
    }, [isOptionPanelOpen]);

    const canSelectStyle = useMemo(() => {
      return {
        color: "#b1b1c1",
        cursor: "default",
      };
    }, [canSelect]);

    return (
        <div className={"select"}>
            <button
                className="select-button"
                disabled={disabled}
                onClick={() => (canSelect ? setIsOptionPanelOpen((prev) => !prev) : undefined)}
                style={{...style, ...(!canSelect && {...canSelectStyle})}}
            >
                <div className="select-value">
                    {(Boolean(selectedValue) || selectedValue === 0) && options.find((el) => el[valueKey] === selectedValue)
                        ? options.find((el) => el[valueKey] === selectedValue)[labelKey]
                        : placeholder}
                </div>
                {!disabled && (
                    <img
                        className="select-arrow"
                        src={IconSelectArrow}
                        alt=""
                        style={{transform: isOptionPanelOpen && "rotate(180deg)"}}
                    />
                )}
            </button>
            {Boolean(description) && <p className="select-description">{description}</p>}
            {isOptionPanelOpen && Array.isArray(options) && canSelect && (
                <div className="select-options">
                    {options.map((el, index) => {
                        return (
                            <div
                                key={index}
                                className={`option-panel-label ${el[valueKey] === selectedValue ? "selected" : ""}`}
                                onClick={() => setSelectedValue(el[valueKey])}
                            >
                                {el[labelKey]}
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default SelectBox;
