import React, { useState } from "react";
import "./ProjectBilling.scss";
import billingInfoIcon from "../assets/icon_dashboard_billing_list_icon.png";
import billingHistoryIcon from "../assets/icon_dashboard_billing_history_icon.png";
import refreshIcon from "../assets/icon_dashboard_billing_refresh.png";
import flagIcon from "../assets/icon_dashboard_billing_flag.png";
import addIcon from "../assets/icon_dashboard_billing_add.png";
import cardIcon from "../assets/icon_dashboard_billing_card.png";
import { getProjectBillingInfoAction } from "action/projectAction";
import { errorAlert } from "utils/Utils";
import useGetSelectedProject from "hooks/useGetSelectedProject";
import useLoading from "hooks/useLoading";
import PageLoading from "view/components/_atom/loading/PageLoading";
import { numberConvertor } from "utils/convert.utils";
import {
  MonthlyHistory as MonthlyHistoryType,
  ProjectReceipt as ProjectReceiptType,
  PricingPlan as PricingPlanType,
} from "reducer/clientType/projectClientType";
import SectionContainer from "view/components/_atom/section/SectionContainer";
import eqCreditIcon from "../../../assets/images/Icon_eqcredit.png";
import BillingHistory from "../components/BillingHistory";
import { ESTIMATED_COST_CHART_COLOR, HISTORY_LIMIT } from "../constants/project.data";
import PageHeader from "view/components/_molecules/page/PageHeader";
import { useNavigate } from "react-router-dom";
import { calculateNextDesignatedDate } from "utils/time.utils";

type ProjectBillingRowProps = {
  label: string;
  value?: string;
  hasImage?: boolean;
};

const ProjectBillingRow = ({ label, value, hasImage = false }: ProjectBillingRowProps) => {
  return (
    <div className="project-billing-row">
      <p className="project-billing-row-label">{label}</p>
      <div className="project-billing-row-value">
        {hasImage ? <CreditImage /> : <div style={{ height: 30 }} />}
        {value ? value : "-"}
      </div>
    </div>
  );
};

const CreditImage = () => {
  return (
    <div className="credit-image-wrapper">
      <img className="credit-image" src={eqCreditIcon} alt="" />
    </div>
  );
};

const ProjectBilling = () => {
  const { selectedProject, selectedProjectID } = useGetSelectedProject();

  const [pricingPlan, setPricingPlan] = useState<PricingPlanType>();
  const [monthlyHistory, setMonthlyHistory] = useState<MonthlyHistoryType>();
  const [projectCredit, setProjectCredit] = useState<{ credit: string; projectId: number }>();
  const [projectReceipt, setProjectReceipt] = useState<ProjectReceiptType>();
  const [estimatedCost, setEstimatedCost] = useState();

  const [projectReceiptCount, setProjectReceiptCount] = useState<number>();

  const getProjectBillingInformation_ = async () => {
    const { result, error } = await getProjectBillingInfoAction(selectedProject.pricingPlanId, HISTORY_LIMIT);

    if (result) {
      const { pricingPlan, monthlyHistory, projectCredit, projectReceipt, estimatedCost } = result || {};
      const { rows, count } = projectReceipt;

      setPricingPlan(pricingPlan);
      setMonthlyHistory(monthlyHistory);
      setProjectCredit(projectCredit);
      setEstimatedCost(estimatedCost);
      setProjectReceipt(rows);
      setProjectReceiptCount(count);
    }

    if (error) {
      errorAlert(error.data.message);
    }
  };

  const { loading } = useLoading({
    dependency: [selectedProjectID],
    synchronousFunction: async () => {
      await getProjectBillingInformation_();
    },
  });

  if (loading) {
    return <PageLoading style={{ height: "calc(100vh - 60px - 112px - 141px - 116px - 100px)" }} />;
  }
  type PaymentInformationItemProps = {
    label: string;
    value?: string;
    labelImage?: string;
    labelImageClickEvent?: Function;
    valueImage?: string;
    creditIcon?: boolean;
    isFirst?: boolean;
  };
  const PaymentInformationItem = ({
    label,
    value,
    labelImage,
    labelImageClickEvent,
    valueImage,
    creditIcon = false,
    isFirst = false,
  }: PaymentInformationItemProps) => {
    return (
      <>
        {!isFirst && <div className="project-billing-payment-information-right-item-divide-line" />}
        <div className="project-billing-payment-information-right-item">
          <div>
            <div className="project-billing-payment-information-right-item-label">
              {label}
              {labelImage && (
                <img
                  className="project-billing-payment-information-right-item-label-image"
                  src={labelImage}
                  onClick={() => {
                    labelImageClickEvent && labelImageClickEvent();
                  }}
                  alt=""
                />
              )}
            </div>
            <div className="project-billing-payment-information-right-item-value">
              {valueImage && <img className="project-billing-payment-information-right-item-value-image" src={valueImage} alt="" />}
              {creditIcon && <CreditImage />}
              {value ? value : "-"}
            </div>
          </div>
        </div>
      </>
    );
  };
  const PaymentInformation = () => {
    const navigate = useNavigate();

    return (
      <div className="project-billing-payment-information">
        <div className="project-billing-payment-information-left">
          <p className="project-billing-payment-information-left-title">My Payment Information</p>
          <p className="project-billing-payment-information-left-description">{`Check the usage for this month, and the\nexpected cost.`}</p>
        </div>

        <div className="project-billing-payment-information-right">
          <PaymentInformationItem
            label="My Plan"
            value={pricingPlan?.name}
            labelImage={refreshIcon}
            labelImageClickEvent={() => navigate("/project/pricing")}
            isFirst={true}
          />
          <PaymentInformationItem label="Due Date" value={calculateNextDesignatedDate(Number(monthlyHistory?.paymentDay ?? 3))} valueImage={flagIcon} />
          <PaymentInformationItem label="Payment Method" value="" labelImage={refreshIcon} valueImage={cardIcon} />
          <PaymentInformationItem label="EQ Credit Balance" value={projectCredit?.credit} labelImage={addIcon} creditIcon={true} />
        </div>
      </div>
    );
  };

  const BillingUsageSummary = () => {
    return (
      <>
        <ProjectBillingRow label="API Requests" value={numberConvertor(Number(monthlyHistory?.apiUsageCredit))} hasImage={true} />
        <ProjectBillingRow label="Blockchain Infra" value={numberConvertor(Number(monthlyHistory?.infraUsageCredit))} hasImage={true} />
      </>
    );
  };

  const EQCreditDeduction = () => {
    return (
      <>
        <ProjectBillingRow label="Pricing Plan" value={numberConvertor(Number(pricingPlan?.defaultUsageCredit))} hasImage={true} />
        <ProjectBillingRow label="Bonus Credit" value={numberConvertor(Number(monthlyHistory?.bonusCredit))} hasImage={true} />
      </>
    );
  };

  const EstimatedCostProgressBar = (props) => {
    const { estimatedCost } = props;

    const sumAmounts = (array) => {
      let totalAmount = 0;
      for (const obj of array) {
        totalAmount += obj.amount;
      }
      return totalAmount;
    };
    const total = sumAmounts(estimatedCost);
    const newArray = estimatedCost.map((el) => {
      const percentage = (el.amount / total) * 100;
      return {
        ...el,
        percentage: `${percentage}%`,
      };
    });

    return (
      <div className="project-billing-estimated-cost-progress">
        <div className="project-billing-estimated-cost-progress-bar">
          {newArray.map((el) => {
            return (
              <div
                key={`billing-progress-bar-${el.name}`}
                className="project-billing-estimated-cost-progress-bar-item"
                style={{ width: el.percentage, backgroundColor: ESTIMATED_COST_CHART_COLOR[el.name] }}
              />
            );
          })}
        </div>
        <div className="project-billing-estimated-cost-progress-amount">
          {newArray.map((el) => {
            return (
              <div key={`billing-progress-label-${el.name}`} className="project-billing-estimated-cost-progress-amount-item" style={{ width: el.percentage }}>
                <div className="project-billing-estimated-cost-progress-amount-item-bar" />
                <div>$ {numberConvertor(Number(el.amount))}</div>
              </div>
            );
          })}
        </div>
        <div className="project-billing-estimated-cost-progress-name">
          {newArray.map((el) => {
            return (
              <div key={`billing-progress-legend-${el.name}`} className="project-billing-estimated-cost-progress-name-item">
                <div className="project-billing-estimated-cost-progress-name-item-label" style={{ backgroundColor: ESTIMATED_COST_CHART_COLOR[el.name] }} />
                <div>{el.name}</div>
              </div>
            );
          })}
        </div>
        <div className="project-billing-estimated-cost-progress-description">* Excess usage is subject to a 5% surcharge.</div>
      </div>
    );
  };
  const EstimatedCost = () => {
    return (
      <>
        <div className="project-billing-estimated-cost-progress">
          <EstimatedCostProgressBar estimatedCost={estimatedCost} />
        </div>
        <ProjectBillingRow label="Pricing Plan" value={`$ ${numberConvertor(Number(pricingPlan?.monthlyPrice))}`} />
        <ProjectBillingRow label="Blockchain Infra Usage" value={`$ ${numberConvertor(Number(monthlyHistory?.infraUsageCredit))}`} />
        <ProjectBillingRow label="Excess API Request Cost" value={`$ ${numberConvertor(Number(monthlyHistory?.extraChargeCredit))}`} />
        <div className="project-billing-estimated-cost-total-cost">
          <p className="project-billing-estimated-cost-total-cost-label">Total Estimated Cost</p>
          <p className="project-billing-estimated-cost-total-cost-value">
            <span style={{ fontSize: 16, marginRight: 5 }}>$</span>
            {numberConvertor(Number(monthlyHistory?.totalPaymentPrice))}
          </p>
        </div>
      </>
    );
  };

  return (
    <div id="ProjectBilling">
      <PageHeader title={"Billing"} />

      <div className="project-billing-container">
        <PaymentInformation />

        <div style={{ display: "flex", gap: 20, width: "100%" }}>
          <SectionContainer title="Usage Summary" icon={billingInfoIcon}>
            <BillingUsageSummary />
          </SectionContainer>

          <SectionContainer title="EQ Credit Deduction" icon={billingInfoIcon}>
            <EQCreditDeduction />
          </SectionContainer>
        </div>

        <SectionContainer title="Estimated Cost" icon={billingInfoIcon}>
          <EstimatedCost />
        </SectionContainer>

        <SectionContainer title="Billing History" icon={billingHistoryIcon}>
          <BillingHistory
            projectReceipt={projectReceipt}
            setProjectReceipt={setProjectReceipt}
            projectReceiptCount={projectReceiptCount}
            setProjectReceiptCount={setProjectReceiptCount}
          />
        </SectionContainer>
      </div>
    </div>
  );
};

export default ProjectBilling;
