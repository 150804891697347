import { makeQuery } from "../index/utils";
import { v2ServerByProjectAuth } from "../index/serverInstances";

//사용처 없음
//----------getWalletMicroChains
const getWalletList = async (request: getWalletListRequest): Promise<getWalletListResponse> =>
  await v2ServerByProjectAuth.get({
    path: "wallet-manage/micro-chains",
    query: makeQuery(request.query),
  });

type getWalletListRequest = {
  query: {
    proejctId?: number;
    creatorId?: number;
    mainStatus?: [];
    subStatus?: [];
  };
};

type getWalletListResponse = any;

//----------getWalletDefaultImages
const getWalletDefaultImages = async (): Promise<GetWalletDefaultImagesResponse> =>
  await v2ServerByProjectAuth.get({
    path: "wallet-manage/default-image",
  });
type GetWalletDefaultImagesResponse = any;

//----------uploadWalletImage
const uploadWalletImage = async (request: UploadWalletImageRequest): Promise<UploadWalletImageResponse> =>
  await v2ServerByProjectAuth.post({
    path: "wallet-manage/upload-image",
    query: makeQuery(request.query),
    data: request.data,
  });

type UploadWalletImageRequest = {
  query: {
    fileCount: number;
  };
  data: FormData;
};
type UploadWalletImageResponse = any;

//----------createWallet (coin / token 생성 (기존 currency))
const createWallet = async (request: CreateWalletRequest): Promise<CreateWalletResponse> =>
  await v2ServerByProjectAuth.post({
    path: "wallet-manage/micro-chains",
    data: request.data,
  });

type CreateWalletRequest = {
  data: {
    microChainId: number;
    backgroundImage?: string;
    fontColor?: string;
  };
};
type CreateWalletResponse = any;

// 사용처 없음
//----------getWalletDetail
const getWalletDetail = async (request: getWalletDetailRequest): Promise<getWalletDetailResponse> =>
  await v2ServerByProjectAuth.get({
    path: `wallet-manage/micro-chains/${request.params.microChainId}`,
  });

type getWalletDetailRequest = {
  params: {
    microChainId: number;
  };
};
type getWalletDetailResponse = any;

//----------submitWallet
const submitWallet = async (request: SubmitWalletRequest): Promise<SubmitWalletResponse> =>
  await v2ServerByProjectAuth.post({
    path: `wallet-manage/micro-chains/${request.params.microChainId}/submit`,
  });

type SubmitWalletRequest = {
  params: {
    microChainId: number;
  };
};
type SubmitWalletResponse = any;

//----------confirmWallet
const confirmWallet = async (request: ConfirmWalletRequest): Promise<ConfirmWalletResponse> =>
  await v2ServerByProjectAuth.post({
    path: `wallet-manage/micro-chains/${request.params.microChainId}/confirm-EQBR`,
  });

type ConfirmWalletRequest = {
  params: {
    microChainId: number;
  };
};
type ConfirmWalletResponse = any;

//----------rejectWallet
const rejectWallet = async (request: RejectWalletRequest): Promise<RejectWalletResponse> =>
  await v2ServerByProjectAuth.post({
    path: `wallet-manage/micro-chains/${request.params.microChainId}/reject`,
    data: request.data,
  });

type RejectWalletRequest = {
  params: {
    microChainId: number;
  };
  data: {
    rejectReason: string;
  };
};
type RejectWalletResponse = any;

//----------getWalletRejects
const getWalletRejectReason = async (request: GetWalletRejectReasonRequest): Promise<GetWalletRejectReasonResponse> =>
  await v2ServerByProjectAuth.get({
    path: `wallet-manage/micro-chains/${request.params.microChainId}/reject`,
  });

type GetWalletRejectReasonRequest = {
  params: {
    microChainId: number;
  };
};
type GetWalletRejectReasonResponse = any;

//----------releaseWallet
const releaseWallet = async (request: ReleaseWalletRequest): Promise<ReleaseWalletResponse> =>
  await v2ServerByProjectAuth.post({
    path: `wallet-manage/micro-chains/${request.params.microChainId}/release`,
  });

type ReleaseWalletRequest = {
  params: {
    microChainId: number;
  };
};
type ReleaseWalletResponse = any;

//----------deleteWallet
const deleteWallet = async (request: DeleteWalletRequest): Promise<DeleteWalletResponse> =>
  await v2ServerByProjectAuth.delete({
    path: `wallet-manage/micro-chains/${request.params.microChainId}`,
  });

type DeleteWalletRequest = {
  params: {
    microChainId: number;
  };
};
type DeleteWalletResponse = any;

//---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
const getAssets = async (): Promise<getAssetsResponse> =>
  await v2ServerByProjectAuth.get({
    path: "wallet-manage/assets",
  });

type getAssetsResponse = any;

//----------------------------------
const getMicroChainAssetList = async (request: getMicroChainAssetListRequest): Promise<getMicroChainAssetListResponse> =>
  await v2ServerByProjectAuth.get({
    path: "micro-chain-assets",
    query: makeQuery(request.query),
  });

type getMicroChainAssetListRequest = {
  query: {
    projectId?: number;
    creatorId?: number;
    mainStatus?: number[];
    microChainId?: number;
    assetId?: number;
  };
};

type getMicroChainAssetListResponse = any;

//----------------------------------

//----------createWallet (NFT 생성 (새로운 wallet manage))
const createMicroChainAssetWallet = async (request: createMicroChainAssetWalletRequest): Promise<createMicroChainAssetWalletResponse> =>
  await v2ServerByProjectAuth.post({
    path: "micro-chain-assets",
    data: request.data,
  });

type createMicroChainAssetWalletRequest = {
  data: {
    microChainId: number;
    backgroundImage?: string;
    fontColor?: string;
    assetId: number;
  };
};
type createMicroChainAssetWalletResponse = any;

//----------------------------------
const getMicroChainAssetDetail = async (request: getMicroChainAssetDetailRequest): Promise<getMicroChainAssetDetailResponse> =>
  await v2ServerByProjectAuth.get({
    path: `micro-chain-assets/${request.params.microChainAssetId}`,
  });

type getMicroChainAssetDetailRequest = {
  params: {
    microChainAssetId: number;
  };
};

type getMicroChainAssetDetailResponse = any;

//----------------------------------
const submitMicroChainAsset = async (request: submitMicroChainAssetRequest): Promise<submitMicroChainAssetResponse> =>
  await v2ServerByProjectAuth.post({
    path: `micro-chain-assets/${request.params.microChainAssetId}/submit`,
  });

type submitMicroChainAssetRequest = {
  params: {
    microChainAssetId: number;
  };
};

type submitMicroChainAssetResponse = any;

//----------------------------------
const confirmMicroChainAsset = async (request: confirmMicroChainAssetRequest): Promise<confirmMicroChainAssetResponse> =>
  await v2ServerByProjectAuth.post({
    path: `micro-chain-assets/${request.params.microChainAssetId}/confirm`,
  });

type confirmMicroChainAssetRequest = {
  params: {
    microChainAssetId: number;
  };
};

type confirmMicroChainAssetResponse = any;

//----------------------------------
const rejectMicroChainAsset = async (request: rejectMicroChainAssetRequest): Promise<rejectMicroChainAssetResponse> =>
  await v2ServerByProjectAuth.post({
    path: `micro-chain-assets/${request.params.microChainAssetId}/reject`,
    data: request.data,
  });

type rejectMicroChainAssetRequest = {
  params: {
    microChainAssetId: number;
  };
  data: {
    rejectReason: String;
  };
};

type rejectMicroChainAssetResponse = any;

//----------------------------------
const getMicroChainAssetRejectReason = async (request: getMicroChainAssetRejectReasonRequest): Promise<getMicroChainAssetRejectReasonResponse> =>
  await v2ServerByProjectAuth.get({
    path: `micro-chain-assets/${request.params.microChainAssetId}/reject`,
  });

type getMicroChainAssetRejectReasonRequest = {
  params: {
    microChainAssetId: number;
  };
};

type getMicroChainAssetRejectReasonResponse = any;

//----------------------------------
const releaseMicroChainAsset = async (request: releaseMicroChainAssetRequest): Promise<releaseMicroChainAssetResponse> =>
  await v2ServerByProjectAuth.post({
    path: `micro-chain-assets/${request.params.microChainAssetId}/release`,
  });

type releaseMicroChainAssetRequest = {
  params: {
    microChainAssetId: number;
  };
};

type releaseMicroChainAssetResponse = any;

//----------------------------------
const deleteMicroChainAsset = async (request: deleteMicroChainAssetRequest): Promise<deleteMicroChainAssetResponse> =>
  await v2ServerByProjectAuth.delete({
    path: `micro-chain-assets/${request.params.microChainAssetId}`,
  });

type deleteMicroChainAssetRequest = {
  params: {
    microChainAssetId: number;
  };
};

type deleteMicroChainAssetResponse = any;

//----------------------------------

export {
  getWalletList as getWalletListFromServer,
  getWalletDefaultImages as getWalletDefaultImagesFromServer,
  uploadWalletImage as uploadWalletImageFromServer,
  createWallet as createWalletFromServer,
  getWalletDetail as getWalletDetailFromServer,
  submitWallet as submitWalletFromServer,
  confirmWallet as confirmWalletFromServer,
  rejectWallet as rejectWalletFromServer,
  getWalletRejectReason as getWalletRejectReasonFromServer,
  releaseWallet as releaseWalletFromServer,
  deleteWallet as deleteWalletFromServer,
  //-------------------------------------------
  getAssets as getAssetsFromServer,
  getMicroChainAssetList as getMicroChainAssetListFromServer,
  createMicroChainAssetWallet as createMicroChainAssetWalletFromServer,
  getMicroChainAssetDetail as getMicroChainAssetDetailFromServer,
  submitMicroChainAsset as submitMicroChainAssetFromServer,
  confirmMicroChainAsset as confirmMicroChainAssetFromServer,
  rejectMicroChainAsset as rejectMicroChainAssetFromServer,
  getMicroChainAssetRejectReason as getMicroChainAssetRejectReasonFromServer,
  releaseMicroChainAsset as releaseMicroChainAssetFromServer,
  deleteMicroChainAsset as deleteMicroChainAssetFromServer,
};
