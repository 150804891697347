import { getMyProjectNetworkListFromServer } from "logic/services/Project/server";
import { actionController } from "../utils";
import { getProjectsParser } from "parser/projectParser";
import { useSelector } from "react-redux";
import { selected_project_id } from "reducer/projectReducer";
import { storeState } from "reducer/index";

export const projectController = {
  getMyProjectNetworkList: () => {
    return actionController(async () => {
      try {
        const networkList = await getMyProjectNetworkListFromServer({});
        const myProjectNetworkList = networkList.map((data) => ({
          projectId: data.project_id,
          microChainId: data.micro_chain_id,
          createdAt: data.created_at,
        }));
        return myProjectNetworkList;
      } catch (e) {
        throw new Error("MyProject_NetworkList_get_fail");
      }
    });
  },

  getMyProjectInfo: () => {
    return actionController(async () => {
      try {
        const selectedProjectID = selected_project_id(storeState());
        const projectIds = [selectedProjectID];
        const projectInfo = await getProjectsParser({ projectIds });
        return projectInfo;
      } catch (e) {
        throw new Error("selected_Project_Info_get_fail");
      }
    });
  },
};
