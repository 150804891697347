import React, { ReactNode } from "react";
import "./ButtonContainer.scss";

type ButtonContainerProps = {
  children: ReactNode;
};

const ButtonContainer = ({ children }: ButtonContainerProps) => {
  return <div className="basic-button-container">{children}</div>;
};

export default ButtonContainer;
