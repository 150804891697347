import { parseAction } from "./util/parseAction";
import { WEB3 } from "../modules/web3/Web3";
import {
  callParser,
  getAbiCodeByContractAddressParser,
  getAbiCodeByContractIdParser,
  getByteCodeByContractIdParser,
  estimateGasParser,
  getLoadBalancerParser,
  getTransactionReceiptParser,
  getTransactionResultParser,
  makeTransactionParser,
} from "../parser/requestParser";
import { CalculateGasFeePayload, MakeTransactionPayload, TransactionQuery } from "../reducer/clientType/requestClientType";
import { encodeABI, encodeABIWithByteCode } from "../modules/web3/Web3Utils";
import { getGasPriceParser, getMicroChainDetailParser } from "../parser/microChainParser";
import { AbiItem } from "web3-utils";
import { project_access } from "reducer/projectReducer";
import { storeState } from "reducer/index";

export const makeTransactionAction = ({
  address,
  microChainId,
  contractAddress = undefined,
  parameters,
  to,
  value,
  functionName,
  transactionPath,
  noncePath,
  gasPath,
  gasPricePath,
  token,
  additionalQuery,
}: MakeTransactionPayload) =>
  parseAction(async () => {
    let data: string | null;

    if (!!contractAddress) {
      const abiCode = await getAbiCodeByContractAddressParser(microChainId, contractAddress);
      data = encodeABI(abiCode, functionName, parameters);
    } else {
      data = null;
    }

    const baseURL: string = await getLoadBalancerParser(microChainId);
    const projectAccessToken = project_access(storeState());
    const transaction = makeTransactionParser({
      address,
      microChainId,
      to,
      value,
      functionName,
      baseURL,
      transactionPath,
      noncePath,
      gasPath,
      gasPricePath,
      token: projectAccessToken,
      additionalQuery,
    });

    return {
      ...transaction,
      data,
    };
  });

export const makeTransactionForUploadContractAction = ({
  address,
  contractId,
  microChainId,
  parameters,
  to,
  value,
  transactionPath,
  noncePath,
  gasPath,
  gasPricePath,
  token,
  additionalQuery,
}: MakeTransactionPayload) =>
  parseAction(async () => {
    const baseURL: string = await getLoadBalancerParser(microChainId);
    const abiCode = await getAbiCodeByContractIdParser(contractId);
    const byteCode: string = await getByteCodeByContractIdParser(contractId);
    const projectAccessToken = project_access(storeState());
    const transaction = makeTransactionParser({
      address,
      microChainId,
      to,
      value,
      functionName: "registerContract",
      baseURL,
      transactionPath,
      noncePath,
      gasPath,
      gasPricePath,
      token: projectAccessToken,
      additionalQuery,
    });

    const data: string = encodeABIWithByteCode(abiCode, byteCode, parameters);
    return {
      ...transaction,
      data,
    };
  });

export const callAction = (microChainId: number, contractAddress: string, functionName: string, parameters: Array<string | number | Array<string | number>>) =>
  parseAction(async () => {
    const abiCode = await getAbiCodeByContractAddressParser(microChainId, contractAddress);
    const data: string = encodeABI(abiCode, functionName, parameters);
    const queryObject: TransactionQuery = {
      to: contractAddress,
      data,
    };
    return await callParser(microChainId, queryObject);
  });

export const getTransactionResultAction = (networkId: number, microChainId: number, transactionHash: string) =>
  parseAction(async () => {
    return await getTransactionResultParser(networkId, microChainId, transactionHash);
  });

export const calculateGasFeeAction = ({
  microChainId,
  to,
  value,
  from,
  parameters,
  functionName,
  contractIdentifier,
  isContractDeployTx = false,
}: CalculateGasFeePayload) =>
  parseAction(async () => {
    const { microChainSetting } = await getMicroChainDetailParser(microChainId);
    const { fee } = microChainSetting;
    let calculatedFee;
    if (Boolean(fee)) {
      // 고정 수수료 네트워크 일 경우 수수료 계산
      calculatedFee = WEB3.fromWei(fee);
    } else {
      // 변동 수수료 네트워크 일 경우 수수료 계산
      let data: string | null = null;
      let abiCode: AbiItem | AbiItem[] | null = null;
      let byteCode: string | null = null;

      if (contractIdentifier && typeof contractIdentifier === "number") {
        // contract id로 ABI code를 조회해야 하는 경우 => 높은 확률로 Contract Deploy를 하기 위함
        abiCode = await getAbiCodeByContractIdParser(contractIdentifier);
        byteCode = await getByteCodeByContractIdParser(contractIdentifier);
      }
      if (contractIdentifier && typeof contractIdentifier === "string") {
        // contract address로 ABI code를 조회해야 하는 경우 => 높은 확률로 일반적인 contract의 method로 발생하는 transaction
        abiCode = await getAbiCodeByContractAddressParser(microChainId, contractIdentifier);
      }

      if (isContractDeployTx && abiCode && byteCode && parameters) {
        data = encodeABIWithByteCode(abiCode, byteCode, parameters);
      }
      if (!isContractDeployTx && abiCode && parameters && functionName) {
        data = encodeABI(abiCode, functionName, parameters);
      }

      const gasPrice = await getGasPriceParser(microChainId);
      const gas = await estimateGasParser(microChainId, {
        ...(to && { to }),
        ...(value && { value }),
        ...(from && { from }),
        ...(data && { data }),
      });
      const upperGas = (Number(gas) * 1.2).toFixed();
      calculatedFee = WEB3.fromWei(WEB3.mul(upperGas, gasPrice));
    }
    return calculatedFee;
  });

// ----------------------------------------------------------------------------------

// TODO :: 삭제 예정 (requestTransactionButton 사용)
export const getTransactionReceiptAction = (baseURL: string, transactionHash: string) =>
  parseAction(async () => {
    return await getTransactionReceiptParser(baseURL, transactionHash);
  });
