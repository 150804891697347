import React from "react";
import css from "./TabSection.scss";
import { useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { SliderContainer } from "view/components/_atom/container/SliderContainer";
import { bindStyle } from "view/styles/classNames";
import multiMenuArrowIcon from "../../../assets/images/Icon_multi_menu_arrow.png";

const MENU_HEIGHT = 42;

const TabSection = (props) => {
  const { tab, opendSubMenuList, setOpendSubMenuList, clickedMenu, setClickedMenu } = props;
  const { name, menu } = tab;

  const navigate = useNavigate();

  const menuClickHandler = (menu, e) => {
    setClickedMenu(menu.index);
    // 외부 페이지로 이동할 경우
    if (Boolean(menu.url)) {
      window.open(menu.url);
      return;
    }

    // 비공개일 경우
    if (!Boolean(menu.route)) {
      toast("Coming Soon !", { icon: "✋🏻" });
      return;
    }

    setOpendSubMenuList([]);

    if (e.metaKey) {
      window.open(`${window.location.origin}${menu.route}`);
      return;
    }

    navigate(menu.route);
  };

  const multiMenuClickHandler = (menu) => {
    if (menu.index === clickedMenu) {
      setClickedMenu(undefined);
      setOpendSubMenuList([]);
    } else {
      setClickedMenu(menu.index);
      setOpendSubMenuList(menu.subMenu);
    }
  };

  return (
    <div className="tab-section">
      {Boolean(name) && <div className="tab-section-name">{name}</div>}
      {menu.map((menu) => {
        return !Boolean(menu.subMenu) ? (
          <SingleMenu menu={menu} menuClickHandler={menuClickHandler} key={`single-menu-${menu.name}`} />
        ) : (
          <MultiMenu
            menu={menu}
            multiMenuClickHandler={multiMenuClickHandler}
            key={`multi-menu-${menu.name}`}
            opendSubMenuList={opendSubMenuList}
            clickedMenu={clickedMenu}
          />
        );
      })}
    </div>
  );
};

const SingleMenu = (props) => {
  const { menu, menuClickHandler } = props;

  const location = useLocation();
  const singleMenuCn = bindStyle(css);

  return (
    <div className="tab-section-menu-container" onClick={(e) => menuClickHandler(menu, e)}>
      <div className={singleMenuCn("tab-section-menu", { selected: location.pathname === `${menu.route}` })}>
        <div className="tab-section-menu-wrapper">
          <div className="tab-section-menu-icon">
            <img src={menu.icon} className="tab-section-menu-icon-src" alt="" />
          </div>
          <span className="tab-section-menu-name">{menu.name}</span>
        </div>
      </div>
    </div>
  );
};

const MultiMenu = (props) => {
  const { menu, multiMenuClickHandler, opendSubMenuList, clickedMenu } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const isSelected = location.pathname.includes(menu.route);
  const multiMenuCn = bindStyle(css);

  const subMenuClickHandler = (subMenu, e) => {
    // 비공개일 경우
    if (!Boolean(subMenu.route)) {
      toast("Coming Soon !", { icon: "✋🏻" });
      return;
    }

    if (e.metaKey) {
      window.open(`${window.location.origin}${subMenu.route}`);
      return;
    }

    navigate(subMenu.route);
  };

  return (
    <div className="tab-section-menu-container">
      <div className={multiMenuCn("tab-section-menu", { selected: isSelected })} onClick={() => multiMenuClickHandler(menu)}>
        <div className="tab-section-menu-wrapper">
          <div className="tab-section-menu-icon">
            <img src={menu.icon} className="tab-section-menu-icon-src" alt="" />
          </div>
          <span className="tab-section-menu-name">{menu.name}</span>
        </div>
        <div className={multiMenuCn("tab-section-menu-multi-arrow", { active: clickedMenu === menu.index })}>
          <img src={multiMenuArrowIcon} alt="" className="tab-section-menu-multi-arrow-src" />
        </div>
      </div>
      <SliderContainer currentHeight={opendSubMenuList.length * MENU_HEIGHT} isOpen={Boolean(opendSubMenuList.length > 0)}>
        {menu.subMenu.map((subMenu, index) => (
          <div className="tab-section-sub-menu" key={`sub-menu-${index}`} onClick={(e) => subMenuClickHandler(subMenu, e)}>
            <div className={multiMenuCn("tab-section-sub-menu-item", { selected: location.pathname.includes(subMenu.route) })}>{`- ${subMenu.name}`}</div>
          </div>
        ))}
      </SliderContainer>
    </div>
  );
};

export default TabSection;
