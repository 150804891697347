import { ServiceStatus, statusType } from "../../../../../utils/status.utils";

export const AIRDROP_DATA_FORMAT = {
  networkId: null,
  microchainId: null,
  projectName: null,
  depositAmount: null,
  projectImage: null,
  projectDescription: null,
  microchainCurrencyId: null,
  marketingChannels: [],
  operationPurpose: null,
  participationPeriodTypeId: null,
  participationStartDate: null,
  participationEndDate: null,
  distributionTypeId: null,
  distributionAmountTypeId: null,
  participationConditionTypeId: [],
};

export const AIRDROP_STEP_LIST = [
  {
    step: 0,
    label: "Project Purpose",
    description: "Set the purpose of the airdrop project. When you set the purpose,\nthe information about operating way is automatically set accordingly.",
  },
  {
    step: 1,
    label: "Basic Info",
    description: "Enter basic information about the project.",
  },
  {
    step: 2,
    label: "Budget",
    description: "Set the environment and budget for the project.",
  },
  {
    step: 3,
    label: "Operating Way",
    description: `Set how to operate the project, including the amount of reward, payment processing system, period of project,\nand conditions of participation.`,
  },
];

export const AIRDROP_OPERATION_PURPOSE_LIST = [
  {
    id: 0,
    operationPurpose: 1,
    label: "Faucet",
    description: "It is a project that is automatically paid\nwhen a user participates in the project.",
    disabled: false,
    property: [
      { prop: "Distribution type", value: "Automatic payment" },
      { prop: "amount per winner", value: "Fixed reward" },
      { prop: "operation period", value: "No fixed time limit" },
    ],
    distributionAmountTypeId: 1,
    distributionTypeId: 2,
    participationPeriodTypeId: 2,
    participationConditionTypeId: 1,
  },
  {
    id: 1,
    operationPurpose: 2,
    label: "Bounty",
    description: "A project that sets the conditions for participating in the project and selects and pays winners who meet the conditions.",
    disabled: true,
    // disabled: false,
    property: [
      { prop: "Distribution type", value: "Manual payment" },
      { prop: "amount per winner", value: "Fixed reward" },
      { prop: "operation period", value: "Fixed period" },
    ],
    distributionAmountTypeId: 1,
    distributionTypeId: 1,
    participationPeriodTypeId: 1,
    participationConditionTypeId: 2,
  },
];

export const AIRDROP_PARTICIPANT_LIMIT_PERIOD_LIST = [
  { id: 1, value: 1, label: "per day" },
  { id: 2, value: 3, label: "in 3 days" },
  { id: 3, value: 5, label: "in 5 days" },
  { id: 4, value: 7, label: "in 7 days" },
  { id: 5, value: 14, label: "in 14 days" },
];

export const AIRDROP_PARTICIPANT_COUNT_LIST = [
  { id: 1, value: 1, label: "1 time" },
  { id: 2, value: 3, label: "3 times" },
  { id: 3, value: 5, label: "5 times" },
  { id: 4, value: 7, label: "7 times" },
  { id: 5, value: 10, label: "10 times" },
];

export const AIRDROP_DISTRIBUTION_AMOUNT_FORMAT = { amount: null, probability: null };

export const AIRDROP_PARTICIPANT_LIMIT_FORMAT = { period: null, count: null };

export const AIRDROP_PARTICIPANT_EVENT_FORMAT = { title: null, placeholder: null };

export const AIRDROP_OPERATION_PURPOSE_FAUCET = 1;

export const AIRDROP_OPERATION_PURPOSE_BOUNTY = 2;

export const AIRDROP_PROJECT_STATUS: Record<string, ServiceStatus> = {
  AIRDROP_PROJECT_CREATED: {
    mainStatus: 1,
    subStatus: 0,
  },
  AIRDROP_PROJECT_PREPARED: {
    mainStatus: 3,
    subStatus: 0,
  },
  AIRDROP_PROJECT_DEPLOY_PROCESSING: {
    mainStatus: 3,
    subStatus: 1,
    statusDetail: {
      type: statusType.WAIT,
      title: "Smart contract is being deployed.",
      showTransactionHash: true,
    },
  },
  AIRDROP_PROJECT_DEPLOY_FAILED_REORGANIZE: {
    mainStatus: 3,
    subStatus: 2,
    statusDetail: {
      type: statusType.REJECT,
      title: "The deployment of the smart contract was not completed successfully. Please try again.",
      description: {
        text: "Reason: Reorganization of blockchain network",
        color: "#FF1717",
      },
      showTransactionHash: false,
    },
  },
  AIRDROP_PROJECT_DEPOSIT_READY: {
    mainStatus: 3,
    subStatus: 3,
  },
  AIRDROP_PROJECT_DEPOSIT_PROCESSING: {
    mainStatus: 3,
    subStatus: 4,
    statusDetail: {
      type: statusType.WAIT,

      title: "Deposit transaction is sent.",
      showTransactionHash: true,
    },
  },
  AIRDROP_PROJECT_DEPOSIT_FAILED_REORGANIZE: {
    mainStatus: 3,
    subStatus: 5,
    statusDetail: {
      type: statusType.REJECT,
      title: "The transaction to deposit the budget did not complete successfully. Please try again.",
      description: {
        text: "Reason: Reorganization of blockchain network",
        color: "#FF1717",
      },
      showTransactionHash: false,
    },
  },
  AIRDROP_PROJECT_SEND_FEE_READY: {
    mainStatus: 3,
    subStatus: 6,
  },
  AIRDROP_PROJECT_SEND_FEE_PROCESSING: {
    mainStatus: 3,
    subStatus: 7,
    statusDetail: {
      type: statusType.WAIT,
      title: "Sending Fee transaction is sent.",
      showTransactionHash: true,
    },
  },
  AIRDROP_PROJECT_SEND_FEE_FAILED_REORGANIZE: {
    mainStatus: 3,
    subStatus: 8,
    statusDetail: {
      type: statusType.REJECT,
      title: "The transaction to send the total tx fee did not complete successfully. Please try again.",
      description: {
        text: "Reason: Reorganization of blockchain network",
        color: "#FF1717",
      },
      showTransactionHash: false,
    },
  },
  AIRDROP_PROJECT_PUBLISH_READY: {
    mainStatus: 3,
    subStatus: 9,
  },
  AIRDROP_PROJECT_PUBLISHED_MANUAL: {
    mainStatus: 5,
    subStatus: 0,
  },
  AIRDROP_PROJECT_PUBLISHED_AUTOMATICAL: {
    mainStatus: 5,
    subStatus: 5,
  },
};
