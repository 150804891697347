import "./ContractCodeViewer.scss";
import { ManageFieldContainer, ManageFieldLabel, RowManageField } from "view/components/_atom/section/ManageField";
import contractIcon from "../../../../assets/images/service/Icon_SNB_contract_library.png";

const ContractCodeViewer = ({ codeDetail }) => {
  return (
    <>
      <ManageFieldContainer icon={contractIcon} title="Uploaded File">
        <RowManageField>
          <ManageFieldLabel label="Solidity Code" />
          <Download link={codeDetail.solidityCode} />
        </RowManageField>
        <RowManageField>
          <ManageFieldLabel label="Byte Code" />
          <Download link={codeDetail.byteCode} />
        </RowManageField>
        <RowManageField>
          <ManageFieldLabel label="ABI Code" />
          <Download link={codeDetail.abiCode} />
        </RowManageField>
      </ManageFieldContainer>
    </>
  );
};
export default ContractCodeViewer;

const Download = ({ link }) => {
  return (
    <div className={"contract-code-data"} onClick={() => window.open(link)}>
      Download File
    </div>
  );
};
