import { createSlice } from "@reduxjs/toolkit";

// Slice 영역 - 선언부
const transactionResultSlice = createSlice({
  name: "transactionResult",
  initialState: {
    chainLoadBalancer: "",
    rawTransactionResult: {
      tx_hash: "",
    },
    receiptResult: {
      data: null,
      status: null,
    },
  },
  reducers: {
    setLoadBalancer(state, action) {
      state.chainLoadBalancer = action.payload.loadBalancer;
    },
    setRawTransactionResult(state, action) {
      state.rawTransactionResult.tx_hash = action.payload.tx_hash;
    },
    setTransactionReceipt(state, action) {
      state.receiptResult.status = action.payload.status;
      state.receiptResult.data = action.payload.data;
    },
    removeTransactionResult(state) {
      state.rawTransactionResult.tx_hash = "";
      state.chainLoadBalancer = "";
      state.receiptResult.status = null;
      state.receiptResult.data = null;
    },
  },
});

// Action 영역
export const { setRawTransactionResult, removeTransactionResult, setTransactionReceipt, setLoadBalancer } = transactionResultSlice.actions;

// Store 데이터 영역

export const transaction_result = (RootState) => RootState.transactionResult;

// Reducer 영역
export default transactionResultSlice.reducer;
