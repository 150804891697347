import { useEffect, useMemo, useState } from "react";
import "./RegisterWallet.scss";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import Select from "../../../../components/_atom/select/Select";
import PageLoading from "../../../../components/_atom/loading/PageLoading";
import WalletCardSetting from "../component/organism/WalletCardSetting";
import { WALLET_PURPOSE_TYPE } from "../constants/walletConstants";
import { errorAlert } from "../../../../../utils/Utils";
import RadioButton from "../../../../../legacy-common/atom/button/RadioButton";
import { createWalletAction, getInitialDataFromRegisterWalletAction } from "../../../../../action/walletAction";
import { ALL_MAIN_STATUS } from "../../../../../constants/commonConstants";
import { selected_project_id } from "reducer/projectReducer";
import PageTitle from "view/components/_molecules/page/PageTitle";
import PageArticle from "view/components/_template/page/PageArticle";
import BasicButton from "view/components/_atom/button/BasicButton";
import useLoading from "hooks/useLoading";
import useIsAble from "hooks/useIsAble";
import { FieldContainer, FieldLabel, RowField } from "view/components/_atom/section/Field";
import walletIcon from "../../../../assets/images/service/Icon_SNB_wallet.png";

const DEFAULT_COLOR = "#3f4248";

function RegisterWallet() {
  const navigate = useNavigate();

  const projectId = useSelector(selected_project_id);

  const [initialData, setInitialData] = useState();

  const [allNetworkList, setAllNetworkList] = useState([]);
  const [allMicroChainList, setAllMicroChainList] = useState([]);
  const [availableMicroChainList, setAvailableMicroChainList] = useState([]);
  const [selectedNetwork, setSelectedNetwork] = useState(null);
  const [selectedMicroChain, setSelectedMicroChain] = useState(null);

  const [cardImage, setCardImage] = useState(null);
  const [cardFontColor, setCardFontColor] = useState(DEFAULT_COLOR);

  const [selectedAssetPurpose, setSelectedAssetPurpose] = useState();

  const shouldShowWalletEditor = useMemo(() => {
    if (selectedAssetPurpose !== WALLET_PURPOSE_TYPE[0].value) {
      // TODO :: 나중엔 selectedAssetPurpose !== assetList[0].value 로 비교
      return false;
    }
    return availableMicroChainList.find((el) => el.id === selectedMicroChain)?.isMain ?? false;
  }, [selectedMicroChain, availableMicroChainList, selectedAssetPurpose]);

  const setInitialDataForRegisterWallet = async () => {
    const defaultAssetType = WALLET_PURPOSE_TYPE[0].value;
    const { result, error } = await getInitialDataFromRegisterWalletAction({ projectId, mainStatus: ALL_MAIN_STATUS });

    if (result) {
      setInitialData(result);
      setSelectedAssetPurpose(defaultAssetType);
    }
    if (error) {
      errorAlert(error.data.message);
      return;
    }
  };

  const setAssetPurpose = async (value) => {
    setSelectedAssetPurpose(Number(value));
  };

  useEffect(() => {
    if (!Boolean(initialData)) {
      return;
    }
    setSelectedNetwork(null);
    setSelectedMicroChain(null);
    setAvailableMicroChainList([]);

    // 값 세팅
    const target = initialData?.find((el) => el.assetTypeId === selectedAssetPurpose);
    const { networkList, microChainList, defaultImageList } = target;
    const randomImageIndex = Math.floor(Math.random() * 100) % defaultImageList.length;
    setAllNetworkList(networkList);
    setAllMicroChainList(microChainList);
    setCardImage(defaultImageList[randomImageIndex].url);
    setCardFontColor(DEFAULT_COLOR);
  }, [selectedAssetPurpose]);

  useEffect(() => {
    if (selectedNetwork) {
      const availableMicroChainList = allMicroChainList.filter((el) => el.networkId === selectedNetwork);
      if (availableMicroChainList.length === 0) {
        setSelectedMicroChain(null);
      } else {
        setSelectedMicroChain(availableMicroChainList.find((chain) => Boolean(chain.isMain)).id ?? null);
      }
      setAvailableMicroChainList(availableMicroChainList);
    }
  }, [selectedNetwork]);

  const createWallet_ = async () => {
    const { result, error } = await createWalletAction(selectedMicroChain, selectedAssetPurpose, cardImage, cardFontColor);

    if (result) {
      toast.success("processed successfully");
      navigate(`/tool/wallet/manage/${result.microChainAssetId}`);
    }
    if (error) {
      errorAlert(error.data.message);
      return;
    }
  };

  const isAble = useIsAble([Boolean(selectedMicroChain)]);

  const { loading } = useLoading({
    dependency: [projectId],
    synchronousFunction: async () => {
      await setInitialDataForRegisterWallet();
    },
  });

  if (loading) {
    return <PageLoading />;
  }

  return (
    <PageArticle>
      <PageTitle title={"Register in Wallet"} size={810} />
      <div className="page-layout-810">
        <FieldContainer icon={walletIcon} title="Basic Info">
          <RowField>
            <FieldLabel label="Managing asset" />
            <div className="register-wallet-purpose-type-container">
              {WALLET_PURPOSE_TYPE.map((el) => {
                return (
                  <RadioButton
                    key={`wallet-purpose-${el.label}`}
                    id={el.id ? el.id : el.label}
                    onChange={(e) => setAssetPurpose(e.target.value)}
                    value={el.value}
                    isSelected={selectedAssetPurpose === el.value}
                    label={el.label}
                    labelStyle={{ padding: "0 0 0 25px" }}
                  />
                );
              })}
            </div>
          </RowField>
          <RowField>
            <FieldLabel label="Network / Microchain" />
            <div className="register-wallet-chain-selector-container">
              <Select
                labelKey={"label"}
                valueKey={"id"}
                options={allNetworkList}
                selectedValue={selectedNetwork}
                setSelectedValue={setSelectedNetwork}
                canSelect={allNetworkList.length !== 0}
                placeholder={"Select Network"}
              />
              <Select
                labelKey={"label"}
                valueKey={"id"}
                options={availableMicroChainList}
                selectedValue={selectedMicroChain}
                setSelectedValue={setSelectedMicroChain}
                canSelect={selectedNetwork && availableMicroChainList.length !== 0}
                placeholder={"Select Microchain"}
              />
            </div>
          </RowField>
        </FieldContainer>
        {shouldShowWalletEditor && (
          <FieldContainer icon={walletIcon} title="Wallet Card">
            <WalletCardSetting
              cardImage={cardImage}
              setCardImage={setCardImage}
              cardColor={cardFontColor}
              setCardFontColor={setCardFontColor}
              symbol={availableMicroChainList.find((el) => el.id === selectedMicroChain)?.symbol ?? ""}
              networkLabel={availableMicroChainList.find((el) => el.id === selectedMicroChain)?.network.label ?? ""}
            />
          </FieldContainer>
        )}
        <div className="register-wallet-button-container">
          <BasicButton onClick={() => createWallet_()} disabled={!isAble}>
            Register
          </BasicButton>
        </div>
      </div>
    </PageArticle>
  );
}

export default RegisterWallet;
