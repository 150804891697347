import { useCallback, useEffect, useMemo, useState } from "react";
import "./GetProxyContractToList.scss";
import { useNavigate, useParams } from "react-router-dom";
import PageLoading from "../../../../components/_atom/loading/PageLoading";
import toast from "react-hot-toast";
import GetContractCommonField from "../organism/GetContractCommonField";
import ParameterHandler from "../molcules/ParameterHandler";
import { isArrayParameter, removeEmptyElement } from "../utils/contractUtils";
import { errorAlert } from "../../../../../utils/Utils";
import {
  getCategoriesAction,
  getContractLibraryDetailAction,
  getDataForGetContractAction,
  getTagsAction,
} from "../../../../../action/contractLibraryAction";
import { getAbiCodeForInitializeAction, getProxyContractToListAction } from "../../../../../action/contractAction";
import PageArticle from "../../../../components/_template/page/PageArticle";
import PageTitle from "../../../../components/_molecules/page/PageTitle";
import BasicButton from "view/components/_atom/button/BasicButton";
import { ButtonSize } from "view/components/_atom/button/StyledButton";
import useIsAble from "hooks/useIsAble";
import { throttle } from "lodash";
import { FieldContainer, FieldLabel, RowField } from "view/components/_atom/section/Field";
import contractIcon from "../../../../assets/images/service/Icon_SNB_contract_library.png";
import BasicInput from "view/components/_atom/input/BasicInput";
import DivideLine from "view/components/_atom/line/DivideLine";
import ContractDetailInfo from "../organism/ContractDetailInfo";

const GetProxyContractToList = () => {
  const navigate = useNavigate();
  const { contractId, versionId } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [contractDetail, setContractDetail] = useState({});
  const [versionDetail, setVersionDetail] = useState([]);
  const [selectedVersion, setSelectedVersion] = useState(parseInt(versionId));

  const [allNetworks, setAllNetworks] = useState([]);
  const [allMicroChains, setAllMicroChains] = useState([]);
  const [availableMicroChains, setAvailableMicroChains] = useState([]);
  const [selectedNetwork, setSelectedNetwork] = useState(null);
  const [selectedMicroChain, setSelectedMicroChain] = useState(null);

  const [contractName, setContractName] = useState("");
  const [memo, setMemo] = useState("");

  const [abiCode, setAbiCode] = useState(null);
  const [implementationAddress, setImplementationAddress] = useState("");
  const [initializeInputs, setInitializeInputs] = useState(null);
  const [initializeValue, setInitializeValue] = useState(null);

  const [solutions, setSolutions] = useState([]);
  const [standards, setStandards] = useState([]);

  const getFilters = useCallback(async () => {
    const tags = await getTagsAction();
    const categories = await getCategoriesAction();
    if (tags.result && categories.result) {
      setStandards(categories.result);
      setSolutions(tags.result);
    }
  }, []);

  useEffect(() => {
    getFilters();
  }, []);

  const isAllDataFilled = useMemo(() => {
    if (initializeValue && initializeInputs) {
      return initializeValue.reduce((acc, cur, index) => {
        if (typeof initializeInputs[index].type === "string") {
          return Boolean(acc) && Boolean(cur);
        }
      }, true);
    } else {
      return false;
    }
  }, [initializeValue, initializeInputs]);

  const setInitialDataFromServer = async () => {
    const { result, error } = await getDataForGetContractAction(Number(contractId), Number(versionId));
    if (result) {
      const { networks, microChains, versionDetail } = result;
      if (versionDetail.status < 3) {
        toast.error("Invalid Status to access");
        setTimeout(() => {
          navigate(-1);
        }, 1500);
      } else {
        setAllNetworks(networks);
        setAllMicroChains(microChains);
        setIsLoading(false);
      }
    }
    if (error) {
      toast.error(error.data.message);
      navigate(-1);
    }
  };

  const setInitializeFunctionParameters = async () => {
    const { error, result } = await getAbiCodeForInitializeAction(implementationAddress, selectedMicroChain);
    if (result) {
      const initialize = result.find((method) => method.name === "initialize" && method.type === "function");
      setAbiCode(result);
      setInitializeInputs(initialize.inputs);
    }
    if (error) {
      errorAlert(error.data.message);
    }
  };

  const implementationAddressHandler = (e) => {
    if (e.target.value.length > 42) return;
    setImplementationAddress(e.target.value);
  };

  const getProxyContractToList = throttle(
    async () => {
      const initializeArguments = removeEmptyElement(initializeValue);
      if (contractName.trim().length <= 0) {
        toast.error("Please enter 'Contract name'");
        return;
      }
      if (initializeArguments.length !== 0) {
        let hasEmptyValue = false;
        initializeArguments
          .filter((arg) => typeof arg === "string")
          .map((stringArg) => (hasEmptyValue = !Boolean(stringArg.trim())));
        if (hasEmptyValue) {
          toast.error("Have empty value. Please check again");
        } else {
          const { error, result } = await getProxyContractToListAction(
            selectedMicroChain,
            contractName,
            memo,
            contractId,
            versionId,
            implementationAddress,
            initializeValue,
            abiCode
          );
          if (result) {
            toast.success("Successfully added to list!");
            navigate(`/developer/contract/list/${result.contractId}`);
          }
          if (error) {
            switch (error.type) {
              case "API":
                errorAlert(error.data.message);
                break;
              case "Module":
                errorAlert(`${error.data.argument}::${error.data.code}`);
                break;
              default:
                errorAlert(error.data.message);
            }
          }
        }
      }
    },
    4000,
    { leading: true, trailing: true }
  );

  // const CONTRACT_INITIALIZE_VIEW = () => [
  //   {
  //     renderers: [
  //       {
  //         index: "_logic",
  //         value: (
  //           <Input
  //             value={implementationAddress}
  //             maxLength={42}
  //             placeholder={"Enter the implementation contract address"}
  //             onChange={(e) => implementationAddressHandler(e)}
  //           />
  //         ),
  //       },
  //     ],
  //   },
  //   initializeValue &&
  //     initializeInputs && {
  //       label: "Data",
  //       renderers: initializeInputs.map((input, index) => {
  //         return {
  //           index: `- ${input.name} (${input.type})`,
  //           value: <ParameterHandler key={index} parameters={initializeValue} setParameters={setInitializeValue} input={input} index={index} />,
  //         };
  //       }),
  //     },
  // ];

  const getContractDetail = async () => {
    const { result, error } = await getContractLibraryDetailAction(Number(contractId), Number(versionId));
    if (result) {
      setContractDetail(result.contractDetail);
      setVersionDetail(result.versionDetail);
    }
    if (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    setInitialDataFromServer();
    getContractDetail();
  }, []);

  useEffect(() => {
    if (selectedNetwork) {
      const availableMicroChains = allMicroChains.filter((el) => el.networkId === selectedNetwork);
      if (availableMicroChains.length !== 0) {
        setSelectedMicroChain(availableMicroChains.find((chain) => Boolean(chain.isMain)).id ?? null);
      } else {
        setSelectedMicroChain(null);
      }
      setAvailableMicroChains(availableMicroChains);
      if (implementationAddress) {
        setImplementationAddress("");
      }
    }
  }, [selectedNetwork]);

  useEffect(() => {
    if (!initializeInputs) {
      if (implementationAddress.length === 42 && selectedMicroChain) {
        setInitializeFunctionParameters();
      }
    }
  }, [implementationAddress, initializeInputs, selectedMicroChain]);

  useEffect(() => {
    if (initializeInputs) {
      const newArr = initializeInputs.map((input) => {
        if (isArrayParameter(input.type)) return [];
        else return "";
      });
      setInitializeValue(newArr);
    }
  }, [initializeInputs]);

  useEffect(() => {
    if (initializeInputs && (implementationAddress.length !== 42 || !selectedMicroChain)) {
      setInitializeValue(null);
      setInitializeInputs(null);
    }
  }, [initializeInputs, implementationAddress, selectedMicroChain]);

  const isAble = useIsAble([
    Boolean(selectedNetwork),
    Boolean(selectedMicroChain),
    Boolean(contractName),
    Boolean(memo),
    isAllDataFilled,
  ]);

  if (isLoading) {
    return <PageLoading />;
  }

  return (
    <PageArticle>
      <PageTitle title={"Get Proxy Contract"} size={812} />
      <div className="page-layout-812 get-proxy">
        <ContractDetailInfo
          labelKey={"version"}
          valueKey={"contract_version_id"}
          options={contractDetail.contractVersions}
          selectedValue={selectedVersion}
          setSelectedValue={setSelectedVersion}
          contractDetail={contractDetail}
          versionDetail={versionDetail}
          select={false}
          button={false}
          solutions={solutions}
          standards={standards}
        />
        <GetContractCommonField
          allNetworks={allNetworks}
          availableMicroChains={availableMicroChains}
          selectedNetworkState={{ selectedNetwork, setSelectedNetwork }}
          selectedMicroChainState={{ selectedMicroChain, setSelectedMicroChain }}
          contractNameState={{ contractName, setContractName }}
          memoState={{ memo, setMemo }}
        />
        <div className="get-proxy-contract-notice-box">
          <div className="flex-column justify-content-center" style={{ textAlign: "center" }}>
            <div style={{ fontWeight: 500, lineHeight: "23px" }}>
              Enter the deployed implementation contract address.
            </div>
            <div style={{ fontWeight: 500, lineHeight: "23px" }}>
              {" "}
              When the 42 character input is completed, required data is automatically loaded.
            </div>
          </div>
        </div>
        {/* Implementation Info Description :: Enter information about the Implementation contract to be connected with the proxy contract. */}
        <FieldContainer icon={contractIcon} title="Implementation Info">
          <RowField>
            <FieldLabel label="_logic" />
            <BasicInput
              value={implementationAddress}
              maxLength={42}
              placeholder={"Enter the implementation contract address"}
              onChange={(e) => implementationAddressHandler(e)}
            />
          </RowField>

          {initializeValue && initializeInputs && (
            <>
              <DivideLine style={{ margin: "20px 0" }} />
              {initializeInputs?.map((input, index) => {
                return (
                  <RowField key={`parameter-handler-${index}`}>
                    <FieldLabel label={`- ${input.name} (${input.type})`} />
                    <ParameterHandler
                      parameters={initializeValue}
                      setParameters={setInitializeValue}
                      input={input}
                      index={index}
                    />
                  </RowField>
                );
              })}
            </>
          )}
        </FieldContainer>
        {/* <Section
          title={"Implementation Info"}
          description={"Enter information about the Implementation contract to be connected with the proxy contract."}
          view={CONTRACT_INITIALIZE_VIEW}
        /> */}
        <div className="flex justify-content-flex-end">
          <BasicButton size={ButtonSize.LARGE} onClick={() => getProxyContractToList()} disabled={!isAble}>
            Add
          </BasicButton>
        </div>
      </div>
    </PageArticle>
  );
};

export default GetProxyContractToList;
