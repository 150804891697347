import React from "react";
import "./RadioButton.scss";

const RadioButton = (props) => {
  const { onChange, id, isSelected, label, value, style, labelStyle, disabled, name, ...rest } = props;
  return (
    <div className={"radio-button"} style={style && style}>
      <input id={id} onChange={onChange} value={value} type="radio" checked={isSelected} disabled={disabled} name={name} {...rest} />
      <label htmlFor={id} style={labelStyle && labelStyle}>
        {label}
      </label>
    </div>
  );
};

export default RadioButton;
