import {ServiceStatus, statusType} from "../../../../../utils/status.utils";

export const MINTING = [
  // {
  //   id: 'with-contract-deployment',
  //   value: 0,
  //   label: 'With Contract Deployment'
  // },
  {
    id   : 'anytime',
    value: 1,
    label: 'Anytime(even after deployment)'
  },
]

export const NFT_MINT_STEP_LIST = [
  {
    step       : 0,
    label      : "Make NFT",
    description: "The first step in creating a network.\nPlease enter basic information about the network.",
  },
  {
    step       : 1,
    label      : "Check NFT",
    description:
      "In this step, enter information about the coin in the network.\nSet up data on coin issuance, transaction fee, rewards, etc. required for network operation.",
  },
];

export const NFT_PROJECT_STATUS: Record<string, ServiceStatus> = {
  //TODO: subStatus 1인거 0으로 바꿔야 할수도
  NFT_PROJECT_CREATED: {
    mainStatus: 1,
    subStatus : 1,
  },
  //TODO: subStatus 1인거 0으로 바꿔야 할수도
  NFT_PROJECT_PREPARED         : {
    mainStatus: 3,
    subStatus : 1
  },
  NFT_PROJECT_FAILED_REORGANIZE: {
    mainStatus  : 3,
    subStatus   : 2,
    statusDetail: {
      type               : statusType.REJECT,
      title              : 'The deployment of the smart contract was not completed successfully. Please try again.',
      description        : {
        text : 'Reason: Reorganization of blockchain network',
        color: '#FF1717'
      },
      showTransactionHash: false
    }
  },
  NFT_PROJECT_PROCESSING       : {
    mainStatus  : 4,
    subStatus   : 1,
    statusDetail: {
      type               : statusType.WAIT,
      title              : 'Smart contract is being deployed.',
      showTransactionHash: true
    }
  },
  //TODO: subStatus 1인거 0으로 바꿔야 할수도
  NFT_PROJECT_DEPLOYED: {
    mainStatus: 5,
    subStatus : 1
  },
}

export const NFT_STATUS: Record<string, ServiceStatus> = {
  NFT_MINT_SUCCESS: {
    mainStatus: 1,
    subStatus : 0,
  },
  NFT_MINT_PROCESS: {
    mainStatus  : 4,
    subStatus   : 0,
    statusDetail: {
      type               : statusType.CHECK,
      title              : 'Transaction is sent.',
      showTransactionHash: true
    }
  },
}
