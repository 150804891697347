import Modal, { Props } from 'react-modal';
import { customStyles } from '../../../styles/customStyles';
import './ModalTemplateB.scss';
import DivideLine from 'view/components/_atom/line/DivideLine';

export type ModalTemplateBProps = Props & {
  title: string;
  description?: string;
  showDivideLine?: boolean;
};

const ModalTemplateB = ({
  isOpen,
  onRequestClose,
  overlayClassName = 'modal-overlay-style',
  ariaHideApp = false,
  shouldCloseOnOverlayClick = true,
  title,
  description,
  children,
  showDivideLine = true,
}: ModalTemplateBProps) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      overlayClassName={overlayClassName}
      ariaHideApp={ariaHideApp}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
    >
      <div className="modal-template-B">
        <div className="modal-template-B-header">
          <div className="modal-template-B-header-title">{title}</div>
          {description && <span className="modal-template-B-header-description">{description}</span>}
        </div>
        {showDivideLine && <DivideLine style={{ marginBottom: 40 }} />}
        <div className="modal-template-B-content">{children}</div>
      </div>
    </Modal>
  );
};

export default ModalTemplateB;
