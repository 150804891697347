import { VC__is_empty_value } from "modules/validation/validationCondition";

export function VALIDATION__CONTRACT_name(name) {
  return [
    {
      condition: !VC__is_empty_value(name),
      message: "",
    },
  ];
}

export function VALIDATION__CONTRACT_memo(memo) {
  return [
    {
      condition: !VC__is_empty_value(memo),
      message: "",
    },
  ];
}
