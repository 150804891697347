import { useEffect, useMemo, useState } from "react";
import "./CreateToken.scss";
import { useNavigate } from "react-router-dom";
import Select from "../../../../components/_atom/select/Select";
import ImageUpload from "../../../../components/_atom/file/ImageUpload";
import RadioButtons from "../../../../../legacy-common/molcules/RadioButtons/RadioButtons";
import { BURNABLE, MINTABLE } from "../constant/tokenConstant";
import toast from "react-hot-toast";
import PageLoading from "../../../../components/_atom/loading/PageLoading";
import { WEB3 } from "../../../../../modules/web3/Web3";
import { errorAlert, removeComma } from "../../../../../utils/Utils";
import { stringNumInputHandler } from "../utils/tokenUtils";
import CurrencyImageUploadModal from "../../../../components/_modal/CurrencyImageUploadModal";
import {
  checkCurrencyNameAction,
  createTokenAction,
  getInitialDataForCreateTokenAction,
  uploadCurrencyImageToS3Action,
} from "../../../../../action/tokenAction";
import PageArticle from "../../../../components/_template/page/PageArticle";
import PageTitle from "view/components/_molecules/page/PageTitle";
import useLoading from "hooks/useLoading";
import BasicButton from "view/components/_atom/button/BasicButton";
import { ButtonSize } from "view/components/_atom/button/StyledButton";
import { distinguishValidation } from "modules/validation/validationCondition";
import { VALIDATION__TOKEN_image, VALIDATION__TOKEN_initial_supply, VALIDATION__TOKEN_name, VALIDATION__Token_symbol } from "../validation/tokenValidation";
import useValidateCondition from "hooks/useValidateCondition";
import useIsAble from "hooks/useIsAble";
import CommentInput from "view/components/_atom/input/CommentInput";
import useGetSelectedProject from "hooks/useGetSelectedProject";
import { FieldContainer, FieldLabel, RowField } from "view/components/_atom/section/Field";
import tokenIcon from "../../../../assets/images/service/Icon_SNB_token.png";

function CreateToken() {
  const navigate = useNavigate();
  const { selectedProjectID } = useGetSelectedProject();

  const [tokenImageModalOpen, setTokenImageModalOpen] = useState(false);
  //========= Get initial data Token =========//
  const [allNetworkList, setAllNetworkList] = useState([]);
  const [allMicroChainList, setAllMicroChainList] = useState([]);

  const getInitialDataForCreateToken_ = async () => {
    const { result, error } = await getInitialDataForCreateTokenAction();
    if (result) {
      const { networkList, microChainList } = result || {};
      setAllNetworkList(networkList);
      setAllMicroChainList(microChainList);
    }
    if (error) {
      errorAlert(error.data.message);
      return;
    }
  };

  //========= Select Token Network/Microchain =========//
  const [selectedNetwork, setSelectedNetwork] = useState(null);
  const [selectedMicroChain, setSelectedMicroChain] = useState(null);
  const [availableMicroChainList, setAvailableMicroChainList] = useState([]);

  useEffect(() => {
    if (selectedNetwork) {
      const availableMicroChains = allMicroChainList.filter((el) => el.networkId === selectedNetwork);
      if (availableMicroChains.length !== 0) {
        setSelectedMicroChain(availableMicroChains.find((chain) => Boolean(chain.isMain)).id ?? null);
      } else {
        setSelectedMicroChain(null);
      }
      setAvailableMicroChainList(availableMicroChains);
    }
  }, [selectedNetwork]);

  //========= Input Token Data =========//
  const [tokenName, setTokenName] = useState("");
  const [tokenSymbol, setTokenSymbol] = useState("");
  const [tokenImage, setTokenImage] = useState(null);
  const [initialSupply, setInitialSupply] = useState("");
  const [mintable, setMintable] = useState(null);
  const [burnable, setBurnable] = useState(null);
  // const [lockable, setLockable] = useState(null);

  const checkTokenName_ = async (tokenName) => {
    const { result, error } = await checkCurrencyNameAction(tokenName);
    if (result) {
      return result;
    }
    if (error) {
      errorAlert("Token name is already exist");
      return false;
    }
  };
  const uploadTokenImage_ = async () => {
    const formData = new FormData();
    if (tokenImage) {
      const fileCount = 1;
      formData.append("image", tokenImage);
      const { result, error } = await uploadCurrencyImageToS3Action(formData, fileCount);
      if (result) {
        return result;
      }
      if (error) {
        errorAlert("Something wrong. Please try again");
        return "";
      }
    } else {
      errorAlert("Please upload correct token image");
    }
  };

  const tokenNameValidation = useValidateCondition([tokenName], VALIDATION__TOKEN_name(tokenName));
  const tokenImageValidation = useValidateCondition([tokenImage], VALIDATION__TOKEN_image(tokenImage));
  const tokenSymbolValidation = useValidateCondition([tokenSymbol], VALIDATION__Token_symbol(tokenSymbol));
  const tokenInitialSupplyValidation = useValidateCondition([initialSupply], VALIDATION__TOKEN_initial_supply(initialSupply));

  const isAble = useIsAble([
    Boolean(selectedNetwork),
    Boolean(selectedMicroChain),
    tokenNameValidation.isValid,
    tokenImageValidation.isValid,
    tokenSymbolValidation.isValid,
    tokenInitialSupplyValidation.isValid,
    mintable !== null,
    burnable !== null,
  ]);

  const tokenValidationResult = useMemo(() => {
    return distinguishValidation([tokenNameValidation, tokenImageValidation, tokenSymbolValidation, tokenInitialSupplyValidation]);
  }, [tokenNameValidation, tokenImageValidation, tokenSymbolValidation, tokenInitialSupplyValidation]);

  const createToken_ = async () => {
    if (!tokenValidationResult.isValid) {
      toast.error(tokenValidationResult.message);
      return;
    }

    let isAbleName;
    let imageURL;
    isAbleName = await checkTokenName_(tokenName);
    if (isAbleName) {
      if (typeof tokenImage !== "string") {
        imageURL = await uploadTokenImage_();
      } else {
        imageURL = tokenImage;
      }
    }
    if (isAbleName && Boolean(imageURL)) {
      const { result, error } = await createTokenAction(
        tokenName,
        imageURL,
        tokenSymbol,
        selectedMicroChain,
        WEB3.toWei(removeComma(initialSupply)),
        null,
        false,
        Boolean(mintable),
        Boolean(burnable)
        // lockable: Boolean(lockable)
      );
      if (result) {
        toast.success("Create successfully");
        navigate(`/application/token/manage/${result.currencyId}`);
      }
      if (error) {
        errorAlert(error.data.message);
        return;
      }
    }
  };

  const { loading } = useLoading({
    dependency: [selectedProjectID],
    synchronousFunction: async () => {
      await getInitialDataForCreateToken_();
    },
  });

  if (loading) {
    return <PageLoading />;
  }
  return (
    <>
      {tokenImageModalOpen && (
        <CurrencyImageUploadModal
          title={"Select Token Image"}
          currencyImage={tokenImage}
          setCurrencyImage={setTokenImage}
          currencyImageModalOpen={tokenImageModalOpen}
          setCurrencyImageModalOpen={setTokenImageModalOpen}
        />
      )}
      <PageArticle>
        <PageTitle size={810} title={"Create Token"} />
        <div className="page-layout-810">
          {/* Basic Info Description :: Set the basic information for the token you want to create. */}
          <FieldContainer icon={tokenIcon} title="Basic Info">
            <RowField>
              <FieldLabel label="Network / Microchain" />
              <div className="create-token-chain-selector-container">
                <Select
                  labelKey={"label"}
                  valueKey={"id"}
                  options={allNetworkList}
                  selectedValue={selectedNetwork}
                  setSelectedValue={setSelectedNetwork}
                  canSelect={allNetworkList.length !== 0}
                  placeholder={"Select Network"}
                />
                <Select
                  labelKey={"label"}
                  valueKey={"id"}
                  options={availableMicroChainList}
                  selectedValue={selectedMicroChain}
                  setSelectedValue={setSelectedMicroChain}
                  canSelect={selectedNetwork}
                  placeholder={"Select MicroChain"}
                />
              </div>
            </RowField>
            <RowField>
              <FieldLabel label="Token name" />
              <CommentInput {...tokenNameValidation} value={tokenName} placeholder={"Enter Token name"} onChange={(e) => setTokenName(e.target.value)} />
            </RowField>
          </FieldContainer>
          {/* Details Description :: Enter details for the token you want to create. */}
          <FieldContainer icon={tokenIcon} title="Details">
            <RowField>
              <FieldLabel label="Token symbol" />
              <CommentInput
                {...tokenSymbolValidation}
                value={tokenSymbol}
                placeholder={"Enter token symbol"}
                onChange={(e) => setTokenSymbol(e.target.value.toUpperCase())}
              />
            </RowField>
            <RowField>
              <FieldLabel label="Token image" />
              <ImageUpload
                image={tokenImage}
                setImage={setTokenImage}
                style={{ alignItems: "start" }}
                previewStyle={{ borderRadius: "50%" }}
                buttonText={"Image select"}
                onClickUploader={() => setTokenImageModalOpen(true)}
              />
            </RowField>
            <RowField>
              <FieldLabel label="Token supply" />
              <CommentInput
                {...tokenInitialSupplyValidation}
                value={initialSupply}
                placeholder={"Enter the issue amount of token"}
                onChange={(e) => {
                  stringNumInputHandler(e.target.value, setInitialSupply);
                }}
                maxLength={55}
              />
            </RowField>
            <RowField>
              <FieldLabel label="Mintable" />
              <RadioButtons options={MINTABLE} selectedValue={mintable} onChange={setMintable} />
            </RowField>
            <RowField>
              <FieldLabel label="Burnable" />
              <RadioButtons options={BURNABLE} selectedValue={burnable} onChange={setBurnable} />
            </RowField>
            {/* <RowField>
              <FieldLabel label="Lockable" />
              <RadioButtons options={LOCKABLE} selectedValue={lockable} onChange={setLockable} />
            </RowField> */}
          </FieldContainer>
          <div className="create-token-button-container">
            <BasicButton size={ButtonSize.LARGE} disabled={!isAble} onClick={() => createToken_()}>
              Create
            </BasicButton>
          </div>
        </div>
      </PageArticle>
    </>
  );
}

export default CreateToken;
