import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import css from "./DashboardNetwork.scss";
import nodeIcon from "../../assets/images/service/Icon_SNB_node.png";
import chartIcon from "./assets/Icon_dashboard_chart.png";
import { ReactComponent as DownloadIcon } from "../../assets/images/icon_button_download.svg";
import { STATUS } from "view/components/_atom/status/constants/status.type";
import Status from "../../components/_atom/status/Status";
import { errorAlert } from "utils/Utils";
import { Area, AreaChart, ResponsiveContainer, Tooltip } from "recharts";
import BasicTable from "../../components/_atom/table/BasicTable";
import { CommonTimestamp } from "../../../utils/TimeUtils";
import PageLoading from "../../components/_atom/loading/PageLoading";
import useGetSelectedProject from "../../../hooks/useGetSelectedProject";
import { getBlocksForwardAction, getDailySummaryAction, getDashboardNetworkAction, getTransactionsAction } from "action/dashboardAction";
import { getNodeListAction } from "../../../action/nodeAction";
import { toast } from "react-hot-toast";
import { downloadNetworkVPNAction } from "../../../action/dashboardAction";
import { Block, DailySummary, Transaction } from "reducer/clientType/blockExplorerClientType";
import { getBlockExplorerDetailAction } from "action/blockExplorerAction";
import moment from "moment";
import EmptyDataTemplate from "view/service/dashboard/components/EmptyDataTemplate";
import useLoading from "../../../hooks/useLoading";
import ChartLoading from "view/components/_atom/loading/ChartLoading";
import SectionContainer from "view/components/_atom/section/SectionContainer";
import DivideLine from "view/components/_atom/line/DivideLine";
import { EMPTY_CHART_TEXT } from "./constants/dashboard.data";
import { addressSimplificationConvertor, numberConvertor } from "utils/convert.utils";
import { useNavigate } from "react-router-dom";
import { bindStyle } from "view/styles/classNames";
import arrowIcon from "../../assets/images/Icon_arrow_down.png";
import disabledArrowIcon from "../../assets/images/Icon_arrow_down_gray.png";
import NetworkCard from "../network/network/component/atom/\bNetworkCard";

const DashboardNetwork = () => {
  const { selectedProjectID } = useGetSelectedProject();
  const [selectedNetwork, setSelectedNetwork] = useState<any>();
  const [projectNetworkList, setProjectNetworkList] = useState<Array<any>>([]);

  const getDashboardNetwork_ = async () => {
    const { result, error } = await getDashboardNetworkAction();

    if (result) {
      if (result.length > 0) {
        setProjectNetworkList(result);
        setSelectedNetwork(result[0]);
      }
    }

    if (error) {
      errorAlert(error.data.message);
    }
  };

  const { loading } = useLoading({
    dependency: [selectedProjectID],
    synchronousFunction: async () => {
      await getDashboardNetwork_();
    },
  });

  if (loading) {
    return <PageLoading />;
  }

  return (
    <div id={"DashboardNetwork"}>
      <DashboardNetworkInfo projectNetworkList={projectNetworkList} selectedNetwork={selectedNetwork} setSelectedNetwork={setSelectedNetwork} />
      {projectNetworkList.length > 0 && (
        <>
          <DashboardNodeInfo
            projectId={selectedProjectID}
            selectedMicroChainId={selectedNetwork?.mainMicroChainId ?? -1}
            selectedNetworkLabel={selectedNetwork?.label ?? ""}
          />
          <DashboardBEInfo selectedMicroChainId={selectedNetwork?.mainMicroChainId ?? -1} />
        </>
      )}
    </div>
  );
};

// ---------------------------- DASHBOARD NETWORK INFO -----------------------------------------

type DashboardNetworkInfoProps = {
  projectNetworkList: any;
  selectedNetwork: any;
  setSelectedNetwork: React.Dispatch<React.SetStateAction<any>>;
};
const DashboardNetworkInfo = (props: DashboardNetworkInfoProps) => {
  const { projectNetworkList, selectedNetwork, setSelectedNetwork } = props;
  const cn = bindStyle(css);

  const navigate = useNavigate();
  const [isNetworkSelectorOpen, setIsNetworkSelectorOpen] = useState<boolean>(false);
  const isNoneProjectNetwork = projectNetworkList.length === 0;
  const onClickNetworkSelector = () => {
    if (isNoneProjectNetwork) {
      return;
    }
    setIsNetworkSelectorOpen(!isNetworkSelectorOpen);
  };
  const onClickNetworkSelectorItem = (network: any) => {
    setSelectedNetwork(network);
  };

  const dropMenuRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleOutsideClose = (e: { target: any }) => {
      // useRef current에 담긴 엘리먼트 바깥을 클릭 시 드롭메뉴 닫힘
      if (isNetworkSelectorOpen && !dropMenuRef.current?.contains(e.target)) setIsNetworkSelectorOpen(false);
    };
    document.addEventListener("click", handleOutsideClose);

    return () => document.removeEventListener("click", handleOutsideClose);
  }, [isNetworkSelectorOpen]);

  return (
    <div className="dashboard-network-list">
      <div className="dashboard-network-selector-wrapper">
        <div className="dashboard-network-selector-label">Network</div>
        <div
          ref={dropMenuRef}
          className={cn("dashboard-network-selector", {
            active: isNetworkSelectorOpen,
            disabled: isNoneProjectNetwork,
          })}
          onClick={onClickNetworkSelector}
        >
          <div className="dashboard-network-selector-area">
            <div className="dashboard-network-selector-area-selected-network">
              <span className={cn("dashboard-network-selector-area-selected-network-label", { disabled: isNoneProjectNetwork })}>
                {isNoneProjectNetwork ? "None" : selectedNetwork?.label}
              </span>
            </div>
            <div
              className={cn("dashboard-network-selector-area-arrow", {
                active: isNetworkSelectorOpen,
              })}
            >
              {isNoneProjectNetwork ? (
                <img src={disabledArrowIcon} className="dashboard-network-selector-area-arrow-icon disabled" alt="" />
              ) : (
                <img src={arrowIcon} className="dashboard-network-selector-area-arrow-icon" alt="" />
              )}
            </div>
          </div>
          {isNetworkSelectorOpen && (
            <div className="dashboard-network-selector-panel">
              <div className="dashboard-network-selector-panel-header" />
              <ul className="dashboard-network-selector-panel-network">
                {projectNetworkList.map((projectNetwork) => (
                  <li
                    key={`network-${projectNetwork.id}`}
                    className="dashboard-network-selector-panel-network-item"
                    onClick={() => onClickNetworkSelectorItem(projectNetwork)}
                  >
                    <div className="dashboard-network-selector-panel-network-item-label">
                      <div
                        className={cn("dashboard-network-selector-panel-network-item-label-dot", {
                          selected: projectNetwork?.id === selectedNetwork?.id,
                        })}
                      />
                      <div className="dashboard-network-selector-panel-network-item-label-name">{projectNetwork?.label}</div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
      <DivideLine style={{ margin: "20px 0" }} />
      {projectNetworkList?.length <= 0 && (
        <div className="no-project-network">
          <EmptyDataTemplate>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div className="no-project-network-text">No networks created or running in your project.</div>
              <div className="no-project-network-create-network" onClick={() => navigate("/network/mainnet/create")}>
                {`Create a new Network`}
              </div>
            </div>
          </EmptyDataTemplate>
        </div>
      )}
      {projectNetworkList?.length > 0 && (
        <NetworkCard
          networkInfo={selectedNetwork}
          equalizerCount={selectedNetwork?.mainMicroChainEqualizerCount ?? "-"}
          lightNodeCount={selectedNetwork.mainMicroChainLightNodeCount ?? "-"}
        />
      )}
    </div>
  );
};
// ---------------------------- DASHBOARD NODE INFO -----------------------------------------

type DashboardNodeInfoProps = {
  projectId: number;
  selectedMicroChainId: number;
  selectedNetworkLabel: string;
};
const DashboardNodeInfo = (props: DashboardNodeInfoProps) => {
  const { projectId, selectedMicroChainId, selectedNetworkLabel } = props;

  const [nodeList, setNodeList] = useState<Array<any>>([]);

  const getNodeList_ = async () => {
    const { result, error } = await getNodeListAction({
      projectId,
      microChainId: selectedMicroChainId,
    });

    if (result) {
      setNodeList(result);
    }

    if (error) {
    }
  };

  const downloadVPN_ = async () => {
    if (selectedMicroChainId === -1) {
      errorAlert("There isn't the selected Network.<br />Select the network first");
      return;
    }
    const { result, error } = await downloadNetworkVPNAction(selectedMicroChainId);

    if (result) {
      const url = await fetch(result);
      const blob = await url.blob();
      const element = document.createElement("a");
      element.download = `${selectedNetworkLabel}_VPN`;
      element.href = window.URL.createObjectURL(blob);
      element.target = "_blank";
      element.rel = "noreferrer";
      document.body.appendChild(element);
      toast("VPN Key file is downloaded");
      element.click();
      document.body.removeChild(element);
    }

    if (error) {
      errorAlert(error.data.message);
    }
  };

  const nodeListTableColGroup = [10, 26, 16, 19, 19, 14];

  const nodeListTableHeader = useMemo(
    () => [
      { title: "ID", accessor: "id" },
      { title: "Node Address", accessor: "address" },
      { title: "Status", accessor: "status" },
      { title: "Network", accessor: "networkLabel" },
      { title: "Type", accessor: "type" },
      { title: "ENMC", accessor: "enmc" },
    ],
    []
  );

  const nodeStatusRenderer = (status: number): { status: STATUS; text?: string; viewText?: boolean } => {
    switch (status) {
      case 5:
        return {
          status: STATUS.RUNNING,
          text: "Running",
          viewText: true,
        };
      default:
        return {
          status: STATUS.READY,
        };
    }
  };

  const goToENMC = (ip: string) => {
    const nodeIp = ip.split(":")[1].replaceAll("//", "");
    window.open(`http://${nodeIp}:4011?self=true`);
  };

  const nodeListTableRendrer = useCallback(
    (data: any) => ({
      id: data.id,
      address: data.address ? <strong>{data.address}</strong> : "-",
      status: <Status {...nodeStatusRenderer(data.mainStatus)} />,
      networkLabel: data.networkLabel,
      type: data.typeLabel,
      enmc: (
        <span className="link" onClick={() => goToENMC(data.ip)}>
          Go to ENMC
        </span>
      ),
    }),
    []
  );

  const { loading } = useLoading({
    dependency: [selectedMicroChainId],
    synchronousFunction: async () => {
      await getNodeList_();
    },
  });

  return (
    <SectionContainer title={"Node Info"} icon={nodeIcon} element={<DownloadVPNButton downloadVPN={downloadVPN_} />}>
      {loading && <ChartLoading />}
      {!loading && nodeList.length <= 0 && <EmptyDataTemplate>{"There are no nodes yet."}</EmptyDataTemplate>}
      {!loading && nodeList.length > 0 && (
        <div className="dashboard-node-info">
          <BasicTable colGroup={nodeListTableColGroup} headers={nodeListTableHeader} data={nodeList} renderer={nodeListTableRendrer} />
        </div>
      )}
    </SectionContainer>
  );
};

const DownloadVPNButton = ({ downloadVPN }) => {
  const { isOwner } = useGetSelectedProject();

  return (
    <div className="node-info-vpn-download" onClick={() => downloadVPN()}>
      {isOwner && (
        <>
          <DownloadIcon fill={"#5888ff"} />
          <span className="node-info-vpn-download-label">Download VPN Key</span>
        </>
      )}
    </div>
  );
};

// -------------------------------- DASHBOARD BE INFO --------------------------------------

type DashboardBEInfoProps = {
  selectedMicroChainId: number;
};
const DashboardBEInfo = (props: DashboardBEInfoProps) => {
  const { selectedMicroChainId } = props;
  const [isBlockExplorerExist, setIsBlockExplorerExist] = useState<boolean>(false);

  const getBlockExplorerInfo_ = async () => {
    const { result, error } = await getBlockExplorerDetailAction(selectedMicroChainId);
    if (result) {
      if (result.mainStatus !== 5) {
        setIsBlockExplorerExist(false);
        return;
      }
      setIsBlockExplorerExist(true);
    }
    if (error) {
      if (error.data.serverStatus === 25) {
        setIsBlockExplorerExist(false);
        return;
      }
    }
  };

  useEffect(() => {
    getBlockExplorerInfo_();
  }, [selectedMicroChainId]);

  if (!isBlockExplorerExist) {
    return <></>;
  }

  return (
    <div className="dashboard-be-info">
      <div className="dashboard-be-chart-wrapper">
        <SectionContainer title={"Daily TXs"} icon={chartIcon}>
          <DailyTxnsChart selectedMicroChainId={selectedMicroChainId} />
        </SectionContainer>
      </div>
      <div className="dashboard-be-table-wrapper">
        <LatestBlocksTable selectedMicroChainId={selectedMicroChainId} />
        <LatestTxTable selectedMicroChainId={selectedMicroChainId} />
      </div>
    </div>
  );
};

type DailyTxnsChartProps = {
  selectedMicroChainId: number;
};
const DailyTxnsChart = (props: DailyTxnsChartProps) => {
  const { selectedMicroChainId } = props;

  // const [selectedOptionId, setSelectedOptionId] = useState<FromTick>(FromTick.WEEK);

  const [dailySummaryList, setDailySummaryList] = useState<Array<DailySummary>>([]);

  const getDailySummary_ = async () => {
    const { result, error } = await getDailySummaryAction(selectedMicroChainId);
    if (result) {
      setDailySummaryList(result);
    }
  };

  const { loading } = useLoading({
    dependency: [selectedMicroChainId],
    synchronousFunction: async () => {
      await getDailySummary_();
    },
  });

  return (
    <div className="daily-txns">
      {loading ? (
        <ChartLoading />
      ) : (
        <>
          {dailySummaryList.length > 1 ? (
            <div className="dashboard-be-chart-box">
              <ResponsiveContainer width={"100%"} height={"100%"}>
                <AreaChart
                  data={dailySummaryList}
                  margin={{
                    left: 0,
                    right: 0,
                    top: 10,
                    bottom: 10,
                  }}
                >
                  <defs>
                    <linearGradient id="DailyTxChartgradientColor" x1="0" y1="0" x2="0" y2="1">
                      <stop offset={"20%"} stopColor={"#006eff"} stopOpacity={0.35} />
                      <stop offset={"100%"} stopColor={"#006eff"} stopOpacity={0} />
                    </linearGradient>
                  </defs>
                  <Tooltip wrapperStyle={{ outline: "none" }} content={<CustomDailyTxChartTooltip />} cursor={false} />
                  <Area
                    type="monotone"
                    dataKey="transactionCount"
                    activeDot={{
                      stroke: "#5888ff",
                      strokeWidth: 3,
                      strokeOpacity: 0.4,
                      fill: "#5888ff",
                      r: 4,
                    }}
                    strokeWidth={2}
                    stroke="#006eff"
                    fill="url(#DailyTxChartgradientColor)"
                    fillOpacity={"0.5"}
                  />
                </AreaChart>
              </ResponsiveContainer>
            </div>
          ) : (
            <EmptyDataTemplate>{EMPTY_CHART_TEXT}</EmptyDataTemplate>
          )}
        </>
      )}
    </div>
  );
};

type LatestBlocksTableProps = {
  selectedMicroChainId: number;
};
const LatestBlocksTable = (props: LatestBlocksTableProps) => {
  const { selectedMicroChainId } = props;

  const [latestBlockList, setLatestBlockList] = useState<Array<Block>>([]);

  const getBlockList_ = async () => {
    const { result, error } = await getBlocksForwardAction(selectedMicroChainId);
    if (result) {
      setLatestBlockList(result);
    }
  };

  const latestBlockTableHeader = useMemo(
    () => [
      { title: "Block", accessor: "height" },
      { title: "Hash", accessor: "hash" },
      { title: "Transaction", accessor: "transactionCount" },
      { title: "Timestamp", accessor: "timestamp" },
    ],
    []
  );

  const latestBlockTableRenderer = useCallback(
    (data: any) => ({
      height: <strong>{data.height}</strong>,
      hash: addressSimplificationConvertor(data.hash, 4),
      transactionCount: numberConvertor(data.transactionCount),
      timestamp: moment(data.timestamp).fromNow(),
    }),
    []
  );

  const { loading } = useLoading({
    dependency: [selectedMicroChainId],
    synchronousFunction: async () => {
      await getBlockList_();
    },
  });

  return (
    <SectionContainer title={"Latest Blocks"} icon={chartIcon}>
      <div className="latest-blocks">
        {loading ? (
          <ChartLoading />
        ) : (
          <>
            {latestBlockList.length > 0 ? (
              <BasicTable headers={latestBlockTableHeader} data={latestBlockList} renderer={latestBlockTableRenderer} />
            ) : (
              <EmptyDataTemplate>{EMPTY_CHART_TEXT}</EmptyDataTemplate>
            )}
          </>
        )}
      </div>
    </SectionContainer>
  );
};

type LatestTxTableProps = {
  selectedMicroChainId: number;
};
const LatestTxTable = (props: LatestTxTableProps) => {
  const { selectedMicroChainId } = props;

  const [latestTxList, setLatestTxList] = useState<Array<Transaction>>([]);

  const getTxList_ = async () => {
    const { result, error } = await getTransactionsAction(selectedMicroChainId);
    if (result) {
      setLatestTxList(result);
    }
  };

  const latestTxTableHeader = useMemo(
    () => [
      { title: "Hash", accessor: "hash" },
      { title: "From", accessor: "from" },
      { title: "To", accessor: "to" },
      { title: "Value", accessor: "value" },
      { title: "Timestamp", accessor: "timestamp" },
    ],
    []
  );

  const latestTxTableRenderer = useCallback(
    (data: any) => ({
      hash: <strong>{addressSimplificationConvertor(data.hash, 4)}</strong>,
      from: addressSimplificationConvertor(data.from, 4),
      to: data.to ? addressSimplificationConvertor(data.to, 4) : "-",
      value: numberConvertor(data.value),
      timestamp: moment(data.timestamp).fromNow(),
    }),
    []
  );
  const { loading } = useLoading({
    dependency: [selectedMicroChainId],
    synchronousFunction: async () => {
      await getTxList_();
    },
  });

  return (
    <SectionContainer title={"Latest Transactions"} icon={chartIcon}>
      <div className="latest-transactions">
        {loading ? (
          <ChartLoading />
        ) : (
          <>
            {latestTxList.length > 0 ? (
              <BasicTable headers={latestTxTableHeader} data={latestTxList} renderer={latestTxTableRenderer} />
            ) : (
              <EmptyDataTemplate>{EMPTY_CHART_TEXT}</EmptyDataTemplate>
            )}
          </>
        )}
      </div>
    </SectionContainer>
  );
};

const CustomDailyTxChartTooltip = (props: any) => {
  const { payload } = props;
  const { payload: data } = payload[0] || {};
  const { transactionCount, endTime } = data || {};

  return (
    <div className="dashboard-be-chart-custom-tooltip">
      <div className="dashboard-be-chart-custom-tooltip-label">
        <p className="dashboard-be-chart-custom-tooltip-label-date">{CommonTimestamp(endTime)}</p>
        <p className="dashboard-be-chart-custom-tooltip-label-value">{transactionCount}</p>
      </div>
    </div>
  );
};

export default DashboardNetwork;
