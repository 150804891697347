import { AuthCodeType, MyInfoFromServer, TotpKeyFromServer, UserFromServer, UserTokenInfoFromServer } from "../type/userType";
import { v1ServerByUserAuth } from "../index/serverInstances";
import { ProjectFromServer, ProjectInvitationFromServer, ProjectWithMemberInfoFromServer } from "../type/projectType";
import { makeQuery } from "server/index/utils";
import { AxiosRequestConfig } from "axios";

//refreshToken---------------------------------------------------------------------------------------
const refreshToken = async (): Promise<RefreshTokenResponse> =>
  await v1ServerByUserAuth.post({
    path: `developer-tokens/refresh`,
    config: { withCredentials: true },
  });

type RefreshTokenResponse = UserTokenInfoFromServer;

//createUser---------------------------------------------------------------------------------------
const createUser = async (request: CreateUserRequest): Promise<CreateUserResponse> =>
  await v1ServerByUserAuth.post({
    path: `developer-users`,
    data: request.data,
  });

type CreateUserRequest = {
  data: {
    firstName: string;
    lastName: string;
    birthday: string;
    email: string;
    password: string;
  };
};

type CreateUserResponse = UserTokenInfoFromServer;

//checkEmail---------------------------------------------------------------------------------------
const checkEmail = async (request: CheckEmailRequest): Promise<CheckEmailResponse> =>
  await v1ServerByUserAuth.post({
    path: `developer-users/check-email`,
    data: request.data,
  });

type CheckEmailRequest = {
  data: {
    email: string;
  };
};

type CheckEmailResponse = {
  status: boolean;
};

//requestResetPassword---------------------------------------------------------------------------------------
const findUserPassword = async (request: FindUserPasswordRequest): Promise<FindUserPasswordResponse> =>
  await v1ServerByUserAuth.post({
    path: `developer-users/find-password/send-code`,
    data: request.data,
    config: { withCredentials: true },
  });

type FindUserPasswordRequest = {
  data: {
    email: string;
  };
};

type FindUserPasswordResponse = {
  csrfToken: string;
};

//verifyResetPasswordAuthCode---------------------------------------------------------------------------------------
const verifyFindPasswordAuthCode = async (request: VerifyFindPasswordAuthCodeRequest): Promise<VerifyFindPasswordAuthCodeResponse> =>
  await v1ServerByUserAuth.post({
    path: `developer-users/find-password/verify-code`,
    data: request.data,
    config: { withCredentials: true },
  });

type VerifyFindPasswordAuthCodeRequest = {
  data: {
    authCode: string;
  };
};

type VerifyFindPasswordAuthCodeResponse = {
  csrfToken: string;
};

//resetPassword---------------------------------------------------------------------------------------
const changeUserPassword = async (request: ChangeUserPasswordRequest): Promise<ChangeUserPasswordResponse> =>
  await v1ServerByUserAuth.post({
    path: `developer-users/find-password/change-password`,
    data: request.data,
    config: { withCredentials: true },
  });

type ChangeUserPasswordRequest = {
  data: {
    authCode: string;
    newPassword: string;
  };
};

type ChangeUserPasswordResponse = {
  status: boolean;
};

//getMyUser---------------------------------------------------------------------------------------
const getMyUser = async (): Promise<GetMyUserResponse> =>
  await v1ServerByUserAuth.get({
    path: `developer-users/me`,
  });

type GetMyUserResponse = MyInfoFromServer;

//getUser---------------------------------------------------------------------------------------
const getUser = async (request: GetUserRequest): Promise<GetUserResponse> =>
  await v1ServerByUserAuth.get({
    path: `developer-users/${request.params.userId}`,
  });

type GetUserRequest = {
  params: {
    userId: number;
  };
};

type GetUserResponse = UserFromServer;

//getUsers---------------------------------------------------------------------------------------
const getUsersInformation = async (request: getUsersInformationRequest): Promise<getUsersInformationResponse> =>
  await v1ServerByUserAuth.get({
    path: `developer-users`,
    query: makeQuery(request.query),
  });

type getUsersInformationRequest = {
  query: {
    userIds: Array<number>;
  };
};

type getUsersInformationResponse = Array<UserFromServer>;

//updateUser---------------------------------------------------------------------------------------
const updateUser = async (request: UpdateUserRequest): Promise<UpdateUserResponse> =>
  await v1ServerByUserAuth.put({
    path: `developer-users`,
    data: request.data,
  });

type UpdateUserRequest = {
  data: {
    firstName: string;
    lastName: string;
  };
};

type UpdateUserResponse = UserFromServer;

//getBelongProjects----------------------------------------------------------------------------------
//User가 속한 => 내가 만들거나, 내가 속한 모든 프로젝트 정보 조회
const getBelongProjects = async (request: getBelongProjectsRequest): Promise<getBelongProjectsResponse> =>
  await v1ServerByUserAuth.get({
    path: `developer-users/projects`,
    ...(request.config && { config: request.config }),
  });
type getBelongProjectsRequest = {
  config?: AxiosRequestConfig;
};
type getBelongProjectsResponse = Array<ProjectWithMemberInfoFromServer>;

//geyMyProjects----------------------------------------------------------------------------------
//내가 만든 프로젝트 정보 조회
const geyMyProjects = async (): Promise<geyMyProjectsResponse> =>
  await v1ServerByUserAuth.get({
    path: `developer-users/my-projects`,
  });

type geyMyProjectsResponse = Array<ProjectFromServer>;

//getProjectInvitations----------------------------------------------------------------------------------
const getInvitedProjectList = async (request: getInvitedProjectListRequest): Promise<getInvitedProjectListResponse> =>
  await v1ServerByUserAuth.get({
    path: `developer-users/invitations`,
    ...(request.config && { config: request.config }),
  });
type getInvitedProjectListRequest = {
  config?: AxiosRequestConfig;
};
type getInvitedProjectListResponse = Array<ProjectInvitationFromServer>;

//acceptInvitation----------------------------------------------------------------------------------
const acceptInvitation = async (request: AcceptInvitationRequest): Promise<AcceptInvitationResponse> =>
  await v1ServerByUserAuth.post({
    path: `developer-users/accept-invitation`,
    data: request.data,
    ...(request.config && { config: request.config }),
  });
type AcceptInvitationRequest = {
  data: {
    invitationIds: Array<number>;
  };
  config?: AxiosRequestConfig;
};
type AcceptInvitationResponse = {
  status: boolean;
};
//updatePassword---------------------------------------------------------------------------------------
const updateUserPassword = async (request: UpdateUserPasswordRequest): Promise<UpdateUserPasswordResponse> =>
  await v1ServerByUserAuth.put({
    path: `developer-users/change-password`,
    data: request.data,
  });

type UpdateUserPasswordRequest = {
  data: {
    password: string;
    newPassword: string;
  };
};

type UpdateUserPasswordResponse = {
  status: boolean;
};

//sendAuthCodeEmail---------------------------------------------------------------------------------------
const sendAuthCodeByEmail = async (request: SendAuthCodeByEmailRequest): Promise<SendAuthCodeByEmailResponse> =>
  await v1ServerByUserAuth.post({
    path: `developer-users/${request.params.userId}/send-code`,
    data: request.data,
  });

type SendAuthCodeByEmailRequest = {
  params: {
    userId: number;
  };
  data: {
    email: string;
    type: AuthCodeType;
  };
};

type SendAuthCodeByEmailResponse = {
  status: boolean;
};

//verifyEmail---------------------------------------------------------------------------------------
const verifyAuthCode = async (request: VerifyAuthCodeRequest): Promise<VerifyAuthCodeResponse> =>
  await v1ServerByUserAuth.post({
    path: `developer-users/verify-code`,
    data: request.data,
  });

type VerifyAuthCodeRequest = {
  data: {
    authCode: string;
  };
};

//''
type VerifyAuthCodeResponse = {
  status: boolean;
};

//login---------------------------------------------------------------------------------------
const login = async (request: LoginRequest): Promise<LoginResponse> =>
  await v1ServerByUserAuth.post({
    path: `developer-users/login`,
    data: request.data,
    config: { withCredentials: true },
  });

type LoginRequest = {
  data: {
    email: string;
    password: string;
    totpCode?: string;
  };
};

type LoginResponse = MyInfoFromServer;

//logout---------------------------------------------------------------------------------------
const logout = async (): Promise<LogoutResponse> =>
  await v1ServerByUserAuth.post({
    path: `developer-users/logout`,
    config: { withCredentials: true },
  });

type LogoutResponse = {
  status: boolean;
};

//check password---------------------------------------------------------------------------------------
const checkPassword = async (request: CheckPasswordRequest): Promise<CheckPasswordResponse> =>
  await v1ServerByUserAuth.post({
    path: `developer-users/change-password/check`,
    data: request.data,
  });

type CheckPasswordRequest = {
  data: {
    newPassword: string;
  };
};

type CheckPasswordResponse = {
  status: boolean;
};

// change password recommended date---------------------------------------------------------------------------------------
const checkPasswordRecommendedDate = async (): Promise<checkPasswordRecommendedDateResponse> =>
  await v1ServerByUserAuth.post({
    path: `developer-users/change-password/recommended-date`,
  });

type checkPasswordRecommendedDateResponse = {
  status: boolean;
};

// createTotpKey ---------------------------------------------------------------------------------------
const createTotpKey = async (request: createTotpKeyRequest): Promise<createTotpKeyResponse> =>
  await v1ServerByUserAuth.post({
    path: `developer-users/${request.params.userId}/totp-key`,
  });

type createTotpKeyRequest = {
  params: {
    userId: number;
  };
};

type createTotpKeyResponse = TotpKeyFromServer;

// setTotpKey ---------------------------------------------------------------------------------------
const setTotpKey = async (request: setTotpKeyRequest): Promise<setTotpKeyResponse> =>
  await v1ServerByUserAuth.put({
    path: `developer-users/${request.params.userId}/totp-key`,
    data: request.data,
  });

type setTotpKeyRequest = {
  params: {
    userId: number;
  };
  data: {
    totpCode: string;
    totpKey: string;
  };
};

type setTotpKeyResponse = {
  totp_key: string;
  user: MyInfoFromServer;
};

// deleteTotpKey ---------------------------------------------------------------------------------------
const deleteTotpKey = async (request: deleteTotpKeyRequest): Promise<deleteTotpKeyResponse> =>
  await v1ServerByUserAuth.delete({
    path: `developer-users/${request.params.userId}/totp-key`,
  });

type deleteTotpKeyRequest = {
  params: {
    userId: number;
  };
};

type deleteTotpKeyResponse = MyInfoFromServer;

//====================================================================================================//
export {
  refreshToken as refreshTokenFromServer,
  createUser as createUserFromServer,
  checkEmail as checkEmailFromServer,
  findUserPassword as findUserPasswordFromServer,
  verifyFindPasswordAuthCode as verifyFindPasswordAuthCodeFromServer,
  changeUserPassword as changeUserPasswordFromServer,
  updateUserPassword as updateUserPasswordFromServer,
  getMyUser as getMyUserFromServer,
  getUser as getUserFromServer,
  getUsersInformation as getUsersInformationFromServer,
  updateUser as updateUserFromServer,
  geyMyProjects as geyMyProjectsFromServer,
  getBelongProjects as getBelongProjectsFromServer,
  getInvitedProjectList as getInvitedProjectListFromServer,
  acceptInvitation as acceptInvitationFromServer,
  sendAuthCodeByEmail as sendAuthCodeEmailFromServer,
  verifyAuthCode as verifyAuthCodeFromServer,
  login as loginFromServer,
  logout as logoutFromServer,
  checkPassword as checkPasswordFromServer,
  checkPasswordRecommendedDate as checkPasswordRecommendedDateFromServer,
  createTotpKey as createTotpKeyFromServer,
  setTotpKey as setTotpKeyFromServer,
  deleteTotpKey as deleteTotpKeyFromServer,
};
