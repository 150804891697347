import { ReactComponent as LoadingIcon } from "../../../../view/assets/images/atom/icon-page-loading.svg";
import "./PageLoading.scss";
import { CSSProperties } from "react";

type PageLoadingProps = {
  style?: CSSProperties;
};

export default function PageLoading({ style }: PageLoadingProps) {
  return (
    <div>
      <div className={"page-loading"} style={style && style}>
        <div className={"rotating"}>
          <LoadingIcon />
        </div>
      </div>
    </div>
  );
}
