import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CommonTimestamp } from "../../../../../utils/TimeUtils";
import Table from "../../../../../legacy-common/atom/table/Table";
import { errorAlert } from "../../../../../utils/Utils";
import { getFoundationNoticeListAction } from "../../../../../action/foundationAction";
import Section from "legacy-common/atom/section/Section";
import PageTitle from "view/components/_molecules/page/PageTitle";
import PageArticle from "view/components/_template/page/PageArticle";
import PageLoading from "view/components/_atom/loading/PageLoading";
import Paging from "view/components/_atom/paging/Paging";
import useLoading from "hooks/useLoading";
import BasicButton from "view/components/_atom/button/BasicButton";
import { ButtonSize, ButtonTheme } from "view/components/_atom/button/StyledButton";

const NOTICE_LIMIT = 10;

function FoundationNoticeList() {
  const navigate = useNavigate();
  const foundationId = useParams().id;

  const [currentPage, setCurrentPage] = useState(0);

  const [selectedFoundationNoticeList, setSelectedFoundationNoticeList] = useState([]);
  const [foundationNoticeCount, setFoundationNoticeCount] = useState(0);

  const headers = useMemo(
    () => [
      { title: "No", accessor: "id" },
      { title: "Title", accessor: "title" },
      { title: "Content", accessor: "content" },
      { title: "Date Created", accessor: "createdAt" },
    ],
    []
  );

  const colGroup = useMemo(() => [10, 30, 35, 25].map((el) => ({ style: { width: `${el}%` } })), []);

  const renderer = useCallback(
    (data) => ({
      id: data.id,
      title: data.title,
      contentUrl: data.content,
      createdAt: CommonTimestamp(data.createdAt),
    }),
    []
  );

  const getFoundationNoticeList_ = async () => {
    const { result, error } = await getFoundationNoticeListAction(foundationId, currentPage, NOTICE_LIMIT);

    if (result) {
      const { rows, count } = result || {};
      setSelectedFoundationNoticeList(rows);
      setFoundationNoticeCount(count);
    }
    if (error) {
      errorAlert(error.data.message);
    }
  };

  useEffect(() => {
    getFoundationNoticeList_();
  }, [currentPage]);

  const onClickList = (el) => {
    navigate(`${el.id}`);
  };

  const foundationNoticeListView = () => [
    {
      renderers: [
        {
          value: (
            <Table
              headers={headers}
              colGroup={colGroup}
              data={selectedFoundationNoticeList}
              renderer={renderer}
              onClick={onClickList}
              noDataPlaceholder={"Notice does not exist"}
            />
          ),
        },
      ],
    },
  ];

  const { loading } = useLoading({
    dependency: [foundationId],
    synchronousFunction: async () => {
      await getFoundationNoticeList_();
    },
  });

  if (loading) {
    return <PageLoading />;
  }

  return (
    <PageArticle>
      <PageTitle size={1240} title={"Notice List"} description={"You can write and manage announcements."} />
      <div className="page-layout-1240">
        <BasicButton
          theme={ButtonTheme.CREATE}
          size={ButtonSize.EXTRALARGE}
          onClick={() => navigate(`/network/foundation/manage/${foundationId}/notice/create`)}
        >
          Create a new Notice
        </BasicButton>
        <div style={{ height: 30 }} />
        <Section title={"Notice"} view={foundationNoticeListView} />

        <Paging totalCount={foundationNoticeCount} limit={NOTICE_LIMIT} currentPage={currentPage} setPage={setCurrentPage} />
      </div>
    </PageArticle>
  );
}

export default FoundationNoticeList;
