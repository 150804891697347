import { useState } from 'react';
import './PagingBar.scss';
import { addComma } from '../../../../utils/Utils';
import { ReactComponent as LeftArrow } from './assets/left-arrow.svg';
import { ReactComponent as LeftArrowWithBar } from './assets/left-arrow-bar.svg';
import { ReactComponent as RightArrow } from './assets/right-arrow.svg';
import { ReactComponent as RightArrowWithBar } from './assets/right-arrow-bar.svg';
import { useSearchParams } from 'react-router-dom';

type PagingBarProps = {
  maxPageNum: number;
  query?: string;
};

export function PagingBar({ maxPageNum, query = 'page' }: PagingBarProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const initializePageNum = () => {
    const queryPageNum = searchParams.get(query) ? Number(searchParams.get(query)) : 1;
    if (queryPageNum < 1 || isNaN(queryPageNum) || queryPageNum === undefined) {
      return 1;
    }
    if (queryPageNum > maxPageNum) {
      return maxPageNum;
    }
    return queryPageNum;
  };
  const [pageNum, setPageNum] = useState(initializePageNum);
  const windowWidth = window.innerWidth;
  const numberOfPages = windowWidth < 768 ? 5 : 10;

  const startNumberOfCurrentPage = Math.floor((pageNum - 1) / numberOfPages) * numberOfPages + 1;
  const endNumberOfCurrentPage = startNumberOfCurrentPage + numberOfPages - 1 > maxPageNum ? maxPageNum : startNumberOfCurrentPage + numberOfPages - 1;

  const isAbleBackBtn = pageNum > 1;
  const isAbleNextBtn = pageNum < maxPageNum;

  const setLocationQuery = (key: string, value: any) => {
    searchParams.set(key, value);
    setSearchParams(searchParams);
  };

  const clickBackBtn = () => {
    if (isAbleBackBtn) {
      const newPage = pageNum - 1;
      setPageNum(newPage);
      setLocationQuery(query, newPage);
    }
  };

  const clickNextBtn = () => {
    if (isAbleNextBtn) {
      const newPage = pageNum + 1;
      setPageNum(newPage);
      setLocationQuery(query, newPage);
    }
  };

  const clickFirstBtn = () => {
    if (isAbleBackBtn) {
      setPageNum(1);
      setLocationQuery(query, 1);
    }
  };

  const clickLastBtn = () => {
    if (isAbleNextBtn) {
      setPageNum(maxPageNum);
      setLocationQuery(query, maxPageNum);
    }
  };

  return (
    <div className="paging-bar-wrapper">
      {/*<img className={`paging-page btn ${isAbleBackBtn ? 'active' : ''}`} onClick={clickFirstBtn} src={LeftArrowWithBar} alt={'|<'} />*/}
      <LeftArrowWithBar className={`paging-page btn ${isAbleBackBtn ? 'active' : ''}`} onClick={clickFirstBtn} />
      {/*<img className={`paging-page btn ${isAbleBackBtn ? 'active' : ''}`} onClick={clickBackBtn} src={LeftArrow} alt={'<'} />*/}
      <LeftArrow className={`paging-page btn ${isAbleBackBtn ? 'active' : ''}`} onClick={clickBackBtn} />
      {/*{windowWidth < 768 ? (*/}
      {/*  <div className="paging-page">*/}
      {/*    {'Page '}*/}
      {/*    <span className="paging-page-num-text">{addComma(pageNum)}</span>*/}
      {/*    {' of '}*/}
      {/*    <span className="paging-page-num-text">{addComma(maxPageNum)}</span>*/}
      {/*  </div>*/}
      {/*) : (*/}
      <div className="paging-page">
        {Array.from({ length: endNumberOfCurrentPage - startNumberOfCurrentPage + 1 }).map((_, index) => {
          const currentPage = startNumberOfCurrentPage + index;
          return (
            <div
              key={index}
              className={`paging-page-num ${currentPage === pageNum ? 'active' : ''}`}
              onClick={() => {
                setPageNum(currentPage);
                setLocationQuery(query, currentPage);
              }}
            >
              <div className="paging-page-num-text">{addComma(currentPage)}</div>
            </div>
          );
        })}
      </div>
      {/*)}*/}
      {/*<img className={`paging-page btn ${isAbleNextBtn ? 'active' : ''}`} onClick={clickNextBtn} src={RightArrow} alt={'>'} />*/}
      <RightArrow className={`paging-page btn ${isAbleNextBtn ? 'active' : ''}`} onClick={clickNextBtn} />
      {/*<img className={`paging-page btn ${isAbleNextBtn ? 'active' : ''}`} onClick={clickLastBtn} src={RightArrowWithBar} alt={'>|'} />*/}
      <RightArrowWithBar className={`paging-page btn ${isAbleNextBtn ? 'active' : ''}`} onClick={clickLastBtn} />
    </div>
  );
}
