import React from "react";
import "./PasswordCondition.scss";

export type PasswordCondtionProps = {
  passwordConditionItems: string[];
};

const PasswordCondition = ({
  passwordConditionItems,
}: PasswordCondtionProps) => {
  return (
    <div className="password-condition">
      <div className="password-condition-title">Password condition</div>
      <ol className="password-condition-body">
        {passwordConditionItems.map((el, index) => {
          return (
            <li
              key={`password-condition-${index}`}
              className="password-condition-body-item"
            >
              {el}
            </li>
          );
        })}
      </ol>
    </div>
  );
};

export default PasswordCondition;
