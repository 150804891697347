import React from "react";
import PaymentGatewayTypeList from "./PaymentGatewayTypeList";

const PaymentGatewayTypeSetting = (props) => {
  const { setCurrentStep, setPaymentGatewayType } = props;

  const onClickTypeButton = (value) => {
    setPaymentGatewayType(value);
    setCurrentStep(2);
  };

  return (
    <article>
      <PaymentGatewayTypeList onClickTypeButton={onClickTypeButton} />
    </article>
  );
};

export default PaymentGatewayTypeSetting;
