import React, { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import defaultImage from './asset/img-default.png';
import './KitImageUpload.scss';

type KitImageUploadProps = {
  image: any;
  setImage: any;
  onClickUploader?: any;
  disabled?: boolean;
  style?: any;
  previewStyle?: any;
  buttonText?: string;
  previewLabel?: string;
  comment?: string;
};
const KitImageUpload = (props: KitImageUploadProps) => {
  const { image, setImage, onClickUploader = null, disabled = false, style, previewStyle, previewLabel = 'Upload/Select', comment = '' } = props || {};
  const imageRef = useRef<any>(null);
  const [previewImage, setPreviewImage] = useState<string | ArrayBuffer | null>();
  const uploadImage = () => {
    if (imageRef.current.files[0]) {
      const file = imageRef.current.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        if (file.size > 5 * 1024 * 1024) {
          toast.error('image file must be less than or equal to 5 MB');
        } else {
          setImage(file);
        }
      };
    }
  };

  useEffect(() => {
    if (image) {
      if (typeof image === 'string') {
        setPreviewImage(image);
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onloadend = () => {
          setPreviewImage(reader.result);
        };
      }
    } else {
      setPreviewImage(null);
    }
  }, [image]);

  return (
    <div className="kit-image-uploader" style={style && style}>
      <input
        name={'image-uploader'}
        type="file"
        accept="image/png, image/jpeg, image/gif"
        ref={imageRef}
        onChange={(e) => {
          uploadImage();
        }}
      />
      <div
        className="preview"
        style={{ ...previewStyle }}
        onClick={() => (disabled ? undefined : onClickUploader ? onClickUploader() : imageRef.current.click())}
      >
        {image ? (
          <img src={previewImage as string} className={'preview-image'} style={{ cursor: disabled ? 'default' : 'pointer' }} alt={'image'} />
        ) : (
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 5 }}>
            <img src={defaultImage} className={'preview-image'} style={{ width: 30, height: 30, objectFit: 'contain' }} alt={'image'} />
            {previewLabel}
          </div>
        )}
      </div>
      {!disabled && comment && <div className="upload-comment">{comment}</div>}
    </div>
  );
};

export default KitImageUpload;
