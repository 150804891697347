import { useEffect, useMemo, useState } from "react";
import MethodRequestTemplate from "../template/MethodRequestTemplate";
import { callAction } from "../../../../../action/requestAction";
import { errorAlert } from "../../../../../utils/Utils";

const RequestCall = ({ selectedMethod, selectedMicroChain, selectedContract }) => {
  const [callMethodInputs, setCallMethodInputs] = useState([]);
  const [callResult, setCallResult] = useState(null);

  const callResultParser = useMemo(() => {
    return callResult ? (
      Array.isArray(callResult["0"]) ? (
        <div>
          {callResult["0"].length !== 0 ? (
            <>
              {callResult["0"].map((el, index) => {
                return <div>{`[${index}]: ${typeof el === "boolean" ? JSON.stringify(el) : el}`}</div>;
              })}
            </>
          ) : (
            <div>The data corresponding to the request does not exist.</div>
          )}
        </div>
      ) : (
        <div>{typeof callResult["0"] === "boolean" ? JSON.stringify(callResult["0"]) : callResult["0"]}</div>
      )
    ) : (
      ""
    );
  }, [callResult]);

  const onRequestCall = async () => {
    const parameters = callMethodInputs.map((el) => {
      if (el.type.includes("[]")) {
        return el.value.split(",");
      } else {
        return el.value;
      }
    });
    const { error, result } = await callAction(selectedMicroChain.id, selectedContract.contractAddress, selectedMethod.name, parameters);
    if (result) {
      setCallResult(result);
    }
    if (error) {
      switch (error.type) {
        case "API":
          errorAlert(error.data.message);
          break;
        case "Module":
          errorAlert(`${error.data.argument}::${error.data.code}`);
          break;
        default:
          errorAlert("Error::unknown");
      }
    }
  };

  useEffect(() => {
    if (selectedMethod) {
      const params = selectedMethod.inputs.map((input) => {
        if (input.type === "bool") {
          return {
            type: input.type,
            value: null,
          };
        } else {
          return {
            type: input.type,
            value: "",
          };
        }
      });
      setCallMethodInputs(params);
    }
    return () => {
      setCallMethodInputs([]);
      setCallResult(null);
    };
  }, [selectedMethod]);

  return (
    <MethodRequestTemplate
      selectedMethod={selectedMethod}
      methodInputs={callMethodInputs}
      setMethodInputs={setCallMethodInputs}
      onRequest={() => onRequestCall()}
      buttonText={"View"}
      result={callResultParser}
    />
  );
};

export default RequestCall;
