import { useEffect, useMemo, useState } from "react";
import "./ContractMethodViewer.scss";
import IconArrow from "../../../../assets/images/atom/icon-select-arrow.png";
import Input from "../../../../../legacy-common/atom/input/Input";
import DivideLine from "view/components/_atom/line/DivideLine";
import { ManageFieldContainer } from "view/components/_atom/section/ManageField";
import contractIcon from "../../../../assets/images/service/Icon_SNB_contract_library.png";

const ContractMethodViewer = (props) => {
  const {
    label,
    methodState: { methods, setMethods },
    disabled,
    announcement,
  } = props || {};

  return (
    <>
      <ManageFieldContainer icon={contractIcon} title={label} announcement={announcement}>
        {methods.map((method, idx) => {
          return (
            <div className="contract-method-viewer-container" key={`contract-method-${idx}`}>
              <ContractMethodContainer
                currentMethod={method}
                methodsState={{ methods, setMethods }}
                methodId={idx}
                disabled={disabled}
              />
            </div>
          );
        })}
      </ManageFieldContainer>
    </>
  );
};

const ContractMethodContainer = (props) => {
  const {
    methodsState: { methods, setMethods },
    currentMethod,
    methodId,
    disabled,
  } = props;

  const [isMethodOpen, setIsMethodOpen] = useState(false);

  const [method, setMethod] = useState(currentMethod);

  useEffect(() => {
    const updatedMethods = methods.map((mth, index) => {
      return index === methodId ? method : mth;
    });
    setMethods(updatedMethods);
  }, [method]);

  useEffect(() => {
    setMethod(currentMethod);
  }, [methods]);

  useEffect(() => {
    if (!disabled) setIsMethodOpen(true);
  }, [disabled]);

  const customHeaderStyle = useMemo(() => {
    return {
      height: isMethodOpen ? "fit-content" : 60,
      marginBottom: isMethodOpen ? 8 : 0,
      paddingTop: isMethodOpen ? 22 : 0,
      transition: "height 1s linear",
    };
  }, [isMethodOpen]);

  return (
    <>
      <div
        className="contract-method-header"
        onClick={() => setIsMethodOpen((prev) => !prev)}
        style={customHeaderStyle}
      >
        <div className="contract-method-name">{method.name}</div>
        <img
          src={IconArrow}
          alt=""
          className="contract-method-arrow"
          style={{ transform: isMethodOpen && "rotate(180deg)" }}
        />
      </div>
      {isMethodOpen && (
        <div className="contract-method-detail-wrapper">
          {disabled ? (
            <div className="contract-method-detail-data" style={{ marginBottom: method.inputs.length === 0 ? 0 : 20 }}>
              {method.description === "" ? (
                <span style={{ color: "#B1B1C1" }}>Enter the description of method</span>
              ) : (
                method.description
              )}
            </div>
          ) : (
            <Input
              value={method.description ?? ""}
              onChange={(e) =>
                setMethod((prev) => {
                  return {
                    ...prev,
                    description: e.target.value,
                  };
                })
              }
              disabled={disabled}
              placeholder={"Enter the description of method"}
              style={{ marginBottom: method.inputs.length === 0 ? 0 : 20 }}
            />
          )}
          {method.inputs.length !== 0 && (
            <>
              <DivideLine width={1} />
              {method.inputs.map((input, index) => {
                return (
                  <div className="contract-method-input-container" key={index}>
                    <ContractMethodInput
                      methodState={{ method, setMethod }}
                      currentInput={input}
                      inputId={index}
                      disabled={disabled}
                    />
                  </div>
                );
              })}
            </>
          )}
        </div>
      )}
    </>
  );
};

const ContractMethodInput = (props) => {
  const {
    methodState: { method, setMethod },
    currentInput,
    inputId,
    disabled,
  } = props || {};

  const [methodInput, setMethodInput] = useState(currentInput);

  useEffect(() => {
    setMethodInput(currentInput);
  }, [method]);

  useEffect(() => {
    const updatedInputs = method.inputs.map((input, idx) => {
      return inputId === idx ? methodInput : input;
    });
    setMethod((prev) => {
      return {
        ...prev,
        inputs: updatedInputs,
      };
    });
  }, [methodInput]);

  return (
    <>
      <div className="contract-method-input-detail">{`${methodInput.name} (${methodInput.type})`}</div>
      {disabled ? (
        <div className="contract-input-method-detail-data">
          {methodInput.description === "" ? (
            <span style={{ color: "#B1B1C1" }}>Enter the description of input</span>
          ) : (
            methodInput.description
          )}
        </div>
      ) : (
        <Input
          name={"description"}
          value={methodInput.description ?? ""}
          onChange={(e) =>
            setMethodInput((prev) => {
              return {
                ...prev,
                description: e.target.value,
              };
            })
          }
          placeholder={"Enter the description of input"}
          disabled={disabled}
          style={{ fontSize: 16 }}
        />
      )}
    </>
  );
};

export default ContractMethodViewer;
