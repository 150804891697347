import { useMemo } from "react";
import "./ProgressState.scss";
import checkIcon from "../../../../view/assets/images/icon-common-check.png";

/**
 *
 * @param {number} step - start index = 0, default = 0
 * @param {ObjectArray} stepList - [{step: 0, label: 'Create'}, ...]
 * @returns UI
 */
const ProgressState = (props) => {
  const { step: currentStep = 1, stepList = [], size = 730, shadow = true } = props || {};

  const updateStep = useMemo(() => {
    return stepList.slice(1);
  }, [stepList]);

  return (
    <div className="progress-state-content-box" style={shadow ? { boxShadow: "0 1px 1px rgba(0, 0, 0, 0.05), 0 2px 5px rgba(61, 84, 128, 0.1)" } : {}}>
      <div className="progress-state" style={{ width: `${size}px` }}>
        <div className="progress-state-box">
          <div className="progress-line-wrapper">
            {updateStep.map((el, index) => {
              return (
                <div
                  className={`progress-line ${el.step <= currentStep ? "active" : ""}`}
                  key={`progress-step-${index}`}
                  style={{ width: `calc(${size}px/${stepList.length}` }}
                />
              );
            })}
          </div>
          <div className="progress-state-wrapper">
            {stepList.map((el, index) => {
              return (
                <div className="progress-state-helper" key={`progress-state-${index}`} style={{ width: `calc(${size}px/${stepList.length}` }}>
                  <div className="progress-state-container">
                    <div className={`progress-state-pointer ${el.step <= currentStep ? "active" : ""}`}>
                      {el.step < currentStep && <img src={checkIcon} alt="check" className="progress-state-pointer-check-icon" />}
                      {el.step === currentStep && <div className="progress-state-pointer-dot" />}
                    </div>
                    <div className={`progress-state-container-label ${el.step <= currentStep ? "active" : ""}`}>{el.label}</div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {stepList.find((el) => el.step === currentStep)?.description && (
          <div className="progress-state-description">
            <p>{stepList.find((el) => el.step === currentStep)?.description}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProgressState;
