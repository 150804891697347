import { ReactElement, useState } from 'react';
import './PageTitle.scss';

type PageTitleProps = {
  title: string;
  description?: string;
  size?: number;
  Button?: () => ReactElement;
};

function PageTitle({ title, description, size, Button }: PageTitleProps) {
  const descriptionList = description?.split('\n') || [];
  const isDescriptionOver5Lines = description ? descriptionList.length > 2 : false;

  const [isOpen, setIsOpen] = useState(false);
  const onClickMore = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="page-title-wrapper">
      <div className={'page-title'} style={{ minWidth: `${size}px` }}>
        <div className="page-title-header">{title}</div>
        {isDescriptionOver5Lines ? (
          <div className="page-description-wrapper">
            {isOpen ? (
              <>
                {descriptionList?.map((el, index) => {
                  return (
                    <div className={`page-description`} key={index}>
                      {el}
                    </div>
                  );
                })}
                <div className="description-button" onClick={() => onClickMore()}>
                  Close
                </div>
              </>
            ) : (
              <>
                {descriptionList?.slice(0, 2).map((el, index) => {
                  return (
                    <div className={`page-description`} key={index}>
                      {el}
                    </div>
                  );
                })}
                <div className="description-button" onClick={() => onClickMore()}>
                  ...More
                </div>
              </>
            )}
          </div>
        ) : (
          <div className="page-description-wrapper">
            {descriptionList?.map((el, index) => {
              return (
                <div className="page-description" key={index}>
                  {el}
                </div>
              );
            })}
          </div>
        )}
      </div>
      {Button && <Button />}
    </div>
  );
}

export default PageTitle;
