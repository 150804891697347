import {useEffect, useRef, useState} from 'react';

const useVisibleScrollView = () => {
  const [scrollVisible, setScrollVisible] = useState(false);
  const scrollViewRef = useRef<HTMLElement>(null)
  let scrollTimeout: NodeJS.Timeout | null = null;

  const handleScroll = (timeout) => {
    setScrollVisible(true)
    clearTimeout(timeout);
    scrollTimeout = setTimeout(() => {
      setScrollVisible(false)
      // Scroll event ended
    }, 200); // Adjust the timeout duration as needed
  };


  useEffect(() => {
    if (scrollViewRef.current) {
      scrollViewRef.current.addEventListener('scroll', () => handleScroll(scrollTimeout));
    }

    return () => {
      if (scrollTimeout) {
        clearTimeout(scrollTimeout);
      }

      if (scrollViewRef.current) {
        scrollViewRef.current.removeEventListener('scroll', () => handleScroll(scrollTimeout));
      }
    };
  }, []);

  return {ref: scrollViewRef, scrollVisible}

};

export default useVisibleScrollView;
