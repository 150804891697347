import { isNumber } from 'lodash';
import BigNumber from 'bignumber.js';
import Swal from 'sweetalert2';

BigNumber.config({
  FORMAT: {
    prefix: '',
    decimalSeparator: '.',
    groupSeparator: ',',
    groupSize: 3,
    secondaryGroupSize: 0,
    fractionGroupSeparator: ' ',
    fractionGroupSize: 0,
    suffix: '',
  },
});

const _ = require('lodash');

export const camelCase = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map((v) => camelCase(v));
  } else if (obj != null && obj.constructor === Object) {
    return Object.keys(obj).reduce((result, key) => {
      return {
        ...result,
        [_.camelCase(key)]: camelCase(obj[key]),
      };
    }, {});
  }
  return obj;
};
export const removeNull = (obj) => {
  if (Array.isArray(obj)) {
    // return obj.map(v => camelCase(v));
  } else if (obj != null && obj.constructor === Object) {
    return Object.keys(obj).reduce((result, key) => {
      if (obj[key] || obj[key] === 0) {
        return {
          ...result,
          [key]: obj[key],
        };
      } else {
        return {
          ...result,
        };
      }
    }, {});
  }
  return obj;
};
export const setDefaultValue = (object) => {
  if (isNumber(object.length)) return object;
  let newObj = {};
  Object.keys(object).forEach((key) => {
    switch (typeof object[key]) {
      case 'object':
        if (!object[key]) break;
        newObj[key] = setDefaultValue(object[key]);
        break;
      default:
        newObj[key] = object[key] ?? '';
    }
  });
  return newObj;
};

export const formatDigits = (num, max = 2, min = 0) => {
  if (Number.isNaN(num * 1)) return '-';
  if (typeof num === 'string' && Number.isNaN(num * 1)) return num;
  return Intl.NumberFormat('en-US', {
    minimumFractionDigits: min,
    maximumFractionDigits: max,
  }).format(num * 1);
};

export const reduceAddress = (text, digit = 6) => {
  return `${text.substring(0, digit)}...${text.slice(-digit)}`;
};

export const enterKeyPress = (e, callback) => {
  if (e.key === 'Enter') {
    callback();
  }
};

export const onChangeInputHandler = (setFunc, key, value) => {
  setFunc((prev) => {
    return {
      ...prev,
      [key]: value,
    };
  });
};

export const addComma = (value) => {
  if (typeof value === 'number') {
    return value ? BigNumber(value.toString().replaceAll(',', '')).toFormat() : '-';
  } else if (typeof value === 'string') {
    return value ? BigNumber(value.replaceAll(',', '')).toFormat() : '-';
  }
};

export const removeComma = (value) => {
  return value.replaceAll(',', '');
};

/**
 * 하단 networkParserNew 함수와 동일한 기능
 * action / parser로 대체 후 삭제 예정
 */
export const networkParser = (microChains) => {
  return microChains.reduce((acc, cur) => {
    if (!Boolean(acc.find((net) => net.id === cur.network_id))) {
      return acc.concat(cur.network);
    } else return acc;
  }, []);
};

/**
 * parm: array, key
 * return: array
 * array의 key값을 기준으로 중복 제거 후 반환
 */
export const getUniqueValue = (arr, key) => {
  return [...new Set(arr.map((item) => item[key]))];
};

export const networkParserNew = (microChains) => {
  return getUniqueValue(microChains, 'network');
};

export const scrollToTop = () => {
  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
};

export const sortByKey = (key, Arr, type) => {
  const sortArr = Arr.sort((prev, cur) => {
    if (prev[key] > cur[key]) return 1;
    if (prev[key] < cur[key]) return -1;
  });
  return type === 'upper' ? sortArr.reverse() : sortArr;
};

export const linkToField = (type, microChainId, value) => {
  switch (type) {
    case 'hash':
      window.open();
      break;
    case 'contract':
      window.open(`${process.env.REACT_APP_EQ_FIELD_URL}/${microChainId}/contract/${value}`);
      break;
  }
};

export const errorAlert = (message) => {
  Swal.fire({
    icon: 'error',
    title: 'Error',
    html: message,
    confirmButtonText: 'Close',
    confirmButtonColor: '#5888ff',
    heightAuto: false,
  });
};

export const successAlert = ({ title = 'Success', message, buttonText = 'Close' }) => {
  Swal.fire({
    icon: 'success',
    title: title,
    html: message,
    confirmButtonText: buttonText,
    confirmButtonColor: '#5888ff',
    heightAuto: false,
  });
};

// 사용처 없음
export const parseObject = (obj, filterList) => {
  const newObj = {};
  filterList.map((key) => {
    newObj[key] = obj[key];
  });
  return newObj;
};
