import { addComma, networkParserNew, removeComma } from "utils/Utils";
import { getExternalAndInnerMicroChainListParser, getMicroChainDetailParser } from "../parser/microChainParser";
import { parseAction } from "./util/parseAction";
import {
  airdropBountyDistributeParser,
  airdropBountyForceCloseParser,
  airdropPublishParser,
  airdropUploadImageParser,
  checkDepositParser,
  checkDistributionFeeParser,
  createAirdropParser,
  deleteAirdropParser,
  downloadAirdropBountyResultParser,
  downloadAirdropBountyTemplateParser,
  getAirdropDetailParser,
  getAirdropLimitParticipantDetailByAddressParser,
  getAirdropListParser,
  getAirdropParticipationsParser,
  getAssetParser,
  getDistributionAmountsByAirdropDistributionAmountTypeIdParser,
  getDistributionAmountTypesByAirdropIdParser,
  getDistributionAmountTypesParser,
  getDistributionCountParser,
  getDistributionTypesByAirdropIdParser,
  getDistributionTypesParser,
  getMarketingChannelsParser,
  getParticipationConditionTypesByAirdropIdParser,
  getParticipationConditionTypesParser,
  getParticipationEventsParser,
  getParticipationLimitParser,
  getParticipationPeriodTypesByAirdropIdParser,
  getParticipationPeriodTypesParser,
  getServerKeyAddressParser,
  reuploadAirdropBountyResultParser,
  submitAirdropParser,
  updateAirdropParser,
  uploadAirdropBountyResultParser,
} from "parser/airdropParser";
import { getCoinDetailParser, getMicroChainCurrencyDetailParser, getMicroChainCurrencyListParser } from "parser/currencyParser";
import { uniqBy } from "lodash";
import { AIRDROP_OPERATION_PURPOSE_LIST } from "view/service/application/airdrop/constants/airdropConstants";
import { WEB3 } from "modules/web3/Web3";
import { toLocalDateTime } from "utils/TimeUtils";
import { getMicroChainIdsOfProjectParser } from "parser/projectParser";
import { getListedContractDetailParser } from "../parser/contractParser";

export const getChainDataForAirdropAction = () =>
  parseAction(async () => {
    const microChainIdsOfProject = await getMicroChainIdsOfProjectParser();
    let microChainList = [];
    if (microChainIdsOfProject.length) {
      microChainList = await getExternalAndInnerMicroChainListParser({ microChainIds: microChainIdsOfProject, mainStatus: [5] });
    }
    // const microChainList = microChainIdsOfProject.length ? await getMicroChainListParser({ microChainIds: microChainIdsOfProject, mainStatus: [5] }) : [];
    const networkList = networkParserNew(microChainList);

    const result = { microChainList, networkList };
    return result;
  });

export const getAirdropPresetAction = () =>
  parseAction(async () => {
    const participationPeriodTypes = await getParticipationPeriodTypesParser();
    const distributionTypes = await getDistributionTypesParser();
    const distributionAmountTypes = await getDistributionAmountTypesParser();
    const participationConditionTypes = await getParticipationConditionTypesParser();

    const participationPeriodTypesResult = participationPeriodTypes.map((el) => {
      return {
        participationPeriodTypeId: el.participation_period_type_id,
        name: el.name,
      };
    });
    const distributionTypesResult = distributionTypes.map((el) => {
      return {
        distributionTypeId: el.distribution_type_id,
        name: el.name,
      };
    });
    const distributionAmountTypesResult = distributionAmountTypes.map((el) => {
      return {
        distributionAmountTypeId: el.distribution_amount_type_id,
        name: el.name,
      };
    });
    const participationConditionTypesResult = participationConditionTypes.map((el) => {
      return {
        participationConditionTypeId: el.participation_condition_type_id,
        name: el.name,
      };
    });

    const result = {
      participationPeriodTypes: participationPeriodTypesResult,
      distributionTypes: distributionTypesResult,
      distributionAmountTypes: distributionAmountTypesResult,
      participationConditionTypes: participationConditionTypesResult,
    };
    return result;
  });

export const getCurrenciesByMicroChainIdAction = (microChainId: number) =>
  parseAction(async () => {
    const result = await getMicroChainCurrencyListParser(microChainId);
    return result;
  });

export const getAirdropListAction = () =>
  parseAction(async () => {
    const getAirdropListResult = await getAirdropListParser({});
    const microchainIds = uniqBy(getAirdropListResult, "micro_chain_id").map((el: any) => el.micro_chain_id);
    const microChainList = await Promise.all(
      microchainIds.map(async (microChainId: number) => {
        const microChainDetail = await getMicroChainDetailParser(microChainId);
        return microChainDetail;
      })
    );
    const result = getAirdropListResult.map((el) => {
      const networkLabel = microChainList.find((item: any) => item.microChain.id === el.micro_chain_id)?.network.label;
      const microchainLabel = microChainList.find((item: any) => item.microChain.id === el.micro_chain_id)?.microChain.label;
      const operationPurposeLabel = AIRDROP_OPERATION_PURPOSE_LIST.find((purpose) => purpose.operationPurpose === el.operation_purpose)?.label;
      return {
        networkLabel,
        microchainLabel,
        contractAddress: el.contract_address,
        airdropId: el.airdrop_id,
        balance: el.balance,
        depositAmount: el.deposit_amount,
        mainStatus: el.main_status,
        subStatus: el.sub_status,
        networkId: el.network_id,
        microchainId: el.micro_chain_id,
        operationPurpose: el.operation_purpose,
        projectImage: el.project_image,
        projectName: el.project_name,
        operationPurposeLabel,
      };
    });
    return result.slice().reverse();
  });

export const getAirdropDetailAction = (airdropId: number) =>
  parseAction(async () => {
    const getAirdropDetailResult = await getAirdropDetailParser(airdropId);
    const getMarketingChannelsResult = await getMarketingChannelsParser(airdropId);
    const getAssetResult = await getAssetParser(airdropId);
    const getServerKeyAddressResult = await getServerKeyAddressParser(airdropId);
    const getParticipationPeriodTypesResult = await getParticipationPeriodTypesParser();
    const getParticipationPeriodTypesByAirdropIdResult = await getParticipationPeriodTypesByAirdropIdParser(airdropId);
    const getDistributionTypesResult = await getDistributionTypesParser();
    const getDistributionTypesByAirdropIdResult = await getDistributionTypesByAirdropIdParser(airdropId);
    const getDistributionAmountTypesResult = await getDistributionAmountTypesParser();
    const getDistributionAmountTypesByAirdropIdResult = await getDistributionAmountTypesByAirdropIdParser(airdropId);
    const getParticipationConditionTypesResult = await getParticipationConditionTypesParser();
    const getParticipationConditionTypesByAirdropIdResult = await getParticipationConditionTypesByAirdropIdParser(airdropId);

    const getMicroChainDetail = await getMicroChainDetailParser(getAirdropDetailResult.micro_chain_id);
    const getDistributionAmountsByAirdropDistributionAmountTypeIdResult = await getDistributionAmountsByAirdropDistributionAmountTypeIdParser(
      airdropId,
      getDistributionAmountTypesByAirdropIdResult.airdrop_distribution_amount_type_id
    );

    const { coin_id, contract_id, micro_chain_currency_id } = getAssetResult || {};

    let currency;
    if (coin_id) {
      // coin > wallet server
    }

    // 각 subStatus에서 필요한 txHash 맵핑
    let airdropTxHash: string | null = null;
    if (getAirdropDetailResult.contract_id && getAirdropDetailResult.main_status === 3 && getAirdropDetailResult.sub_status === 1) {
      const { transactionHash } = await getListedContractDetailParser(getAirdropDetailResult.contract_id);
      airdropTxHash = transactionHash;
    }

    //TODO: 서버팀에서 작성한 key와 맞춰야 함 - deposit_tranaction_hash
    if (getAirdropDetailResult.deposit_transaction_hash && getAirdropDetailResult.main_status === 3 && getAirdropDetailResult.sub_status === 4) {
      airdropTxHash = getAirdropDetailResult.deposit_transaction_hash;
    }

    //TODO: 서버팀에서 작성한 key와 맞춰야 함 - distribution_transaction_hash
    if (getAirdropDetailResult.distribute_transaction_hash && getAirdropDetailResult.main_status === 3 && getAirdropDetailResult.sub_status === 7) {
      airdropTxHash = getAirdropDetailResult.distribute_transaction_hash;
    }

    if (micro_chain_currency_id) {
      // sec 이후 삭제될 currency server
      const getCurrencyResult = await getMicroChainCurrencyDetailParser(micro_chain_currency_id);
      currency = {
        type: "currency",
        microChainCurrencyId: micro_chain_currency_id,
        label: getCurrencyResult.currency.name,
        symbol: getCurrencyResult.currency.symbol,
        contractAddress: getCurrencyResult.contractAddress,
      };
    }

    const serverKeyAddress = getServerKeyAddressResult.address;
    const participationPeriod = {
      participationStartDate: getParticipationPeriodTypesByAirdropIdResult.participation_start_date,
      participationEndDate: getParticipationPeriodTypesByAirdropIdResult.participation_end_date,
      participationPeriodTypeId: getParticipationPeriodTypesByAirdropIdResult.participation_period_type_id,
      label: getParticipationPeriodTypesResult.find(
        (el) => el.participation_period_type_id === getParticipationPeriodTypesByAirdropIdResult.participation_period_type_id
      ).name,
    };

    const distributionType = {
      distributionTypeId: getDistributionTypesByAirdropIdResult.distribution_type_id,
      label: getDistributionTypesResult.find((el) => el.distribution_type_id === getDistributionTypesByAirdropIdResult.distribution_type_id).name,
    };

    const distributionAmount = {
      distributionAmountTypeId: getDistributionAmountTypesByAirdropIdResult.distribution_amount_type_id,
      label: getDistributionAmountTypesResult.find(
        (el) => el.distribution_amount_type_id === getDistributionAmountTypesByAirdropIdResult.distribution_amount_type_id
      ).name,
      distributionAmounts: getDistributionAmountsByAirdropDistributionAmountTypeIdResult.map((el) => {
        return { amount: el.amount, probability: el.probability };
      }),
    };

    const participationConditionList = await Promise.all(
      getParticipationConditionTypesByAirdropIdResult.map(async (el) => {
        let result;
        if (el.participation_condition_type_id === 1) {
          const data = await getParticipationLimitParser(airdropId, el.airdrop_participation_condition_type_id);
          result = {
            airdropParticipationConditionTypeId: el.airdrop_participation_condition_type_id,
            participationConditionTypeId: el.participation_condition_type_id,
            limit: data,
          };
        }
        if (el.participation_condition_type_id === 2) {
          const data = await getParticipationEventsParser(airdropId, el.airdrop_participation_condition_type_id);
          result = {
            airdropParticipationConditionTypeId: el.airdrop_participation_condition_type_id,
            participationConditionTypeId: el.participation_condition_type_id,
            events: data,
          };
        }
        return result;
      })
    );

    const participationConditionTypes = getParticipationConditionTypesByAirdropIdResult.map((el) => {
      const conditionList = participationConditionList.find((item) => item.participationConditionTypeId === el.participation_condition_type_id);
      return {
        label: getParticipationConditionTypesResult.find((item) => item.participation_condition_type_id === el.participation_condition_type_id).name,
        ...conditionList,
      };
    });

    const marketingChannels = getMarketingChannelsResult.map((el) => {
      return el.url;
    });
    const result = {
      airdropId: getAirdropDetailResult.airdrop_id,
      contractAddress: getAirdropDetailResult.contract_address,
      contractId: getAirdropDetailResult.contract_id,
      depositAmount: getAirdropDetailResult.deposit_amount,
      mainStatus: getAirdropDetailResult.main_status,
      subStatus: getAirdropDetailResult.sub_status,
      operationPurpose: getAirdropDetailResult.operation_purpose,
      operationPurposeLabel: AIRDROP_OPERATION_PURPOSE_LIST.find((el) => el.operationPurpose === getAirdropDetailResult.operation_purpose)?.label,
      participateListFile: getAirdropDetailResult.participate_list_file,
      projectDescription: getAirdropDetailResult.project_description,
      projectImage: getAirdropDetailResult.project_image,
      projectName: getAirdropDetailResult.project_name,
      winnerListFile: getAirdropDetailResult.winner_list_file,
      networkId: getAirdropDetailResult.network_id,
      microchainId: getAirdropDetailResult.micro_chain_id,
      transactionHash: airdropTxHash,
      marketingChannels,
      participationPeriod,
      distributionType,
      distributionAmount,
      participationConditionTypes,
      estimatedDistributionAmount: getAirdropDetailResult.estimated_distribution_amount,
      networkLabel: getMicroChainDetail.network.label,
      microchainLabel: getMicroChainDetail.microChain.label,
      fee: WEB3.fromWei(getMicroChainDetail.microChainSetting.fee),
      currency,
      serverKeyAddress,
    };

    return result;
  });

export const createAirdropAction = (formData: FormData, airdropData, distributionAmountsData, participationLimitData, participationEventsData) =>
  parseAction(async () => {
    let uploadAirdropImageResult;
    if (typeof airdropData.projectImage !== "string") {
      uploadAirdropImageResult = await airdropUploadImageParser(1, formData);
    }
    const { project_image } = uploadAirdropImageResult || {};
    let projectImageResult;
    if (project_image) {
      projectImageResult = project_image;
    }
    if (!project_image) {
      projectImageResult = airdropData.projectImage;
    }

    // TODO :: 임시 수정 필요 (여러개 중복 선택 처리)
    let conditionTypeList;
    if (airdropData.participationConditionTypeId === 1) {
      conditionTypeList = {
        participationConditionTypeId: airdropData.participationConditionTypeId,
        participationLimit: participationLimitData,
      };
    }
    if (airdropData.participationConditionTypeId === 2) {
      conditionTypeList = {
        participationConditionTypeId: airdropData.participationConditionTypeId,
        participationEvents: participationEventsData,
      };
    }
    // const removeEmptyMarketingChannels = airdropData.marketingChannels.filter((el) => el !== "");

    const distributionAmountList = distributionAmountsData.map((el) => {
      return {
        ...(el.probability && { probability: el.probability }),
        amount: Number(removeComma(el.amount)),
      };
    });

    const data = {
      networkId: airdropData.networkId,
      microChainId: airdropData.microchainId,
      projectName: airdropData.projectName,
      projectDescription: airdropData.projectDescription,
      projectImage: projectImageResult,
      // marketingChannels: removeEmptyMarketingChannels,
      marketingChannels: airdropData.marketingChannels,
      operationPurpose: airdropData.operationPurpose,
      depositAmount: Number(removeComma(airdropData.depositAmount)),
      asset: {
        microChainCurrencyId: airdropData.microchainCurrencyId,
      },
      participationPeriodType: {
        participationPeriodTypeId: airdropData.participationPeriodTypeId,
        ...(airdropData.participationStartDate && { participationStartDate: airdropData.participationStartDate }),
        ...(airdropData.participationEndDate && { participationEndDate: airdropData.participationEndDate }),
      },
      distributionType: {
        distributionTypeId: airdropData.distributionTypeId,
      },
      distributionAmountType: {
        distributionAmountTypeId: airdropData.distributionAmountTypeId,
        distributionAmounts: distributionAmountList,
      },
      participationConditionTypes: [conditionTypeList],
    };

    const createAirdropResult = await createAirdropParser(data);
    return createAirdropResult;
  });

export const getAirdropDetailForUpdateAirdropAction = (airdropId: number) =>
  parseAction(async () => {
    const getParticipationPeriodTypesByAirdropIdResult = await getParticipationPeriodTypesByAirdropIdParser(airdropId);
    const getDistributionTypesByAirdropIdResult = await getDistributionTypesByAirdropIdParser(airdropId);
    const getDistributionAmountTypesByAirdropIdResult = await getDistributionAmountTypesByAirdropIdParser(airdropId);
    const getParticipationConditionTypesByAirdropIdResult = await getParticipationConditionTypesByAirdropIdParser(airdropId);
    const getAirdropDetailResult = await getAirdropDetailParser(airdropId);
    const getMarketingChannelsResult = await getMarketingChannelsParser(airdropId);
    const getAssetResult = await getAssetParser(airdropId);
    const { coin_id, contract_id, micro_chain_currency_id } = getAssetResult || {};
    const marketingChannels = getMarketingChannelsResult.map((el) => {
      return el.url;
    });

    const getMicrochainDetail = await getMicroChainDetailParser(getAirdropDetailResult.micro_chain_id);
    const distributionAmountList = await getDistributionAmountsByAirdropDistributionAmountTypeIdParser(
      airdropId,
      getDistributionAmountTypesByAirdropIdResult.airdrop_distribution_amount_type_id
    );

    let currencySymbol;
    if (coin_id) {
      // coin > wallet server
    }
    if (contract_id) {
      // token > contract server
    }
    if (micro_chain_currency_id) {
      // sec 이후 삭제될 currency server
      const getCurrencyDetailResult = await getMicroChainCurrencyDetailParser(micro_chain_currency_id);
      currencySymbol = getCurrencyDetailResult.currency.symbol;
    }

    const participationConditionList = await Promise.all(
      getParticipationConditionTypesByAirdropIdResult.map(async (el) => {
        let result;
        if (el.participation_condition_type_id === 1) {
          const data = await getParticipationLimitParser(airdropId, el.airdrop_participation_condition_type_id);
          result = {
            ...el,
            limit: data,
          };
        }
        if (el.participation_condition_type_id === 2) {
          const data = await getParticipationEventsParser(airdropId, el.airdrop_participation_condition_type_id);
          result = {
            ...el,
            events: data,
          };
        }
        return result;
      })
    );
    const distributionAmountData = distributionAmountList.map((el) => {
      return {
        amount: addComma(el.amount),
        probability: el.probability,
      };
    });

    const findParticipationConditionLimit = participationConditionList.find((el) => el.participation_condition_type_id === 1);
    const findParticipationConditionEvents = participationConditionList.find((el) => el.participation_condition_type_id === 2);

    let participationConditionLimitData;
    if (Boolean(findParticipationConditionLimit)) {
      const { limit } = findParticipationConditionLimit || {};
      participationConditionLimitData = { count: limit.count, period: limit.period };
    }
    let participationConditionEventsData;
    if (Boolean(findParticipationConditionEvents)) {
      const { events } = findParticipationConditionEvents || {};
      participationConditionEventsData = events.map((el) => {
        return { title: el.title, placeholder: el.placeholder };
      });
    }

    const result = {
      data: {
        mainStatus: getAirdropDetailResult.main_status,
        depositAmount: addComma(getAirdropDetailResult.deposit_amount),
        distributionAmountTypeId: getDistributionAmountTypesByAirdropIdResult.distribution_amount_type_id,
        distributionTypeId: getDistributionTypesByAirdropIdResult.distribution_type_id,
        marketingChannels,
        currencySymbol,
        microchainLabel: getMicrochainDetail.microChain.label,
        networkLabel: getMicrochainDetail.network.label,
        operationPurpose: getAirdropDetailResult.operation_purpose,
        // TODO :: 반복적으로 가능하도록 변경
        participationConditionTypeId: getParticipationConditionTypesByAirdropIdResult[0].participation_condition_type_id,
        participationEndDate: getParticipationPeriodTypesByAirdropIdResult.participation_end_date
          ? toLocalDateTime(getParticipationPeriodTypesByAirdropIdResult.participation_end_date)
          : null,
        participationPeriodTypeId: getParticipationPeriodTypesByAirdropIdResult.participation_period_type_id,
        participationStartDate: getParticipationPeriodTypesByAirdropIdResult.participation_start_date
          ? toLocalDateTime(getParticipationPeriodTypesByAirdropIdResult.participation_start_date)
          : null,
        projectDescription: getAirdropDetailResult.project_description,
        projectImage: getAirdropDetailResult.project_image,
        projectName: getAirdropDetailResult.project_name,
      },
      distributionAmountData: distributionAmountData,
      participationConditionLimitData: participationConditionLimitData ?? null,
      participationConditionEventsData: participationConditionEventsData ?? null,
    };
    return result;
  });

export const updateAirdropAction = (airdropId, formData, airdropData, distributionAmountsData, participationLimitData, participationEventsData) =>
  parseAction(async () => {
    let uploadAirdropImageResult;
    if (typeof airdropData.projectImage !== "string") {
      uploadAirdropImageResult = await airdropUploadImageParser(1, formData);
    }
    const { project_image } = uploadAirdropImageResult || {};
    let projectImageResult;
    if (project_image) {
      projectImageResult = project_image;
    }
    if (!project_image) {
      projectImageResult = airdropData.projectImage;
    }

    // TODO :: 임시 수정 필요 (여러개 중복 선택 처리)
    let conditionTypeList;
    if (airdropData.participationConditionTypeId === 1) {
      conditionTypeList = {
        participationConditionTypeId: airdropData.participationConditionTypeId,
        participationLimit: participationLimitData,
      };
    }
    if (airdropData.participationConditionTypeId === 2) {
      conditionTypeList = {
        participationConditionTypeId: airdropData.participationConditionTypeId,
        participationEvents: participationEventsData,
      };
    }

    const removeEmptyMarketingChannels = airdropData.marketingChannels.filter((el) => el !== "");

    const distributionAmountList = distributionAmountsData.map((el) => {
      return {
        ...(el.probability && { probability: el.probability }),
        amount: Number(removeComma(el.amount)),
      };
    });

    const data = {
      projectName: airdropData.projectName,
      projectDescription: airdropData.projectDescription,
      projectImage: projectImageResult,
      marketingChannels: removeEmptyMarketingChannels,
      operationPurpose: airdropData.operationPurpose,
      depositAmount: Number(removeComma(airdropData.depositAmount)),
      participationPeriodType: {
        participationPeriodTypeId: airdropData.participationPeriodTypeId,
        ...(airdropData.participationStartDate && { participationStartDate: airdropData.participationStartDate }),
        ...(airdropData.participationEndDate && { participationEndDate: airdropData.participationEndDate }),
      },
      distributionType: {
        distributionTypeId: airdropData.distributionTypeId,
      },
      distributionAmountType: {
        distributionAmountTypeId: airdropData.distributionAmountTypeId,
        distributionAmounts: distributionAmountList,
      },
      participationConditionTypes: [conditionTypeList],
    };

    const updateAirdropResult = await updateAirdropParser(airdropId, data);
    return updateAirdropResult;
  });
export const deleteAirdropAction = (airdropId) =>
  parseAction(async () => {
    const deleteAirdropResult = await deleteAirdropParser(airdropId);
    return deleteAirdropResult;
  });

export const submitAirdropAction = (airdropId) =>
  parseAction(async () => {
    const submitAirdropResult = await submitAirdropParser(airdropId);
    return submitAirdropResult;
  });

export const checkDepositAction = (airdropId) =>
  parseAction(async () => {
    const checkDepositResult = await checkDepositParser(airdropId);
    return checkDepositResult;
  });

export const getDistributionCountAction = (airdropId, microChainId) =>
  parseAction(async () => {
    const { virtual_address, maximum_distribution_count } = await getDistributionCountParser(airdropId);
    const currencyDetail = await getCoinDetailParser(microChainId);

    const result = {
      virtualAddress: virtual_address,
      maximumDistributionCount: maximum_distribution_count,
      // fee: WEB3.fromWei(getDistributionFeeResult.fee),
      coinSymbol: currencyDetail.symbol,
    };
    return result;
  });

export const checkDistributionFeeAction = (airdropId) =>
  parseAction(async () => {
    const checkDistributionFeeResult = await checkDistributionFeeParser(airdropId);
    return checkDistributionFeeResult;
  });

export const airdropPublishAction = (airdropId) =>
  parseAction(async () => {
    const airdropPublishResult = await airdropPublishParser(airdropId);
    return airdropPublishResult;
  });

export const getAirdropParticipationsAction = (airdropId, limit, offset, participationStartDate, participationEndDate) =>
  parseAction(async () => {
    const getAirdropParticipationsResult = await getAirdropParticipationsParser(airdropId, limit, offset, participationStartDate, participationEndDate);
    const { count, rows } = getAirdropParticipationsResult || {};

    const result = {
      rows: rows.map((el) => {
        return { participantId: el.participant_id, address: el.address, amount: el.amount, timestamp: el.created_at };
      }),
      count,
    };
    return result;
  });

export const getAirdropLimitParticipantDetailByAddressAction = (airdropId, address) =>
  parseAction(async () => {
    const getAirdropLimitParticipantDetailByAddressResult = await getAirdropLimitParticipantDetailByAddressParser(airdropId, address);

    const result = {
      totalParticipations: getAirdropLimitParticipantDetailByAddressResult.total_participations,
      participationsByPeriod: getAirdropLimitParticipantDetailByAddressResult.participations_by_period,
    };
    return result;
  });

// ------------------------------------ Bounty -------------------------------------------------
export const downloadAirdropBountyResultAction = (airdropId) =>
  parseAction(async () => {
    const downloadAirdropBountyResultResult = await downloadAirdropBountyResultParser(airdropId);
    const { participate_list_file } = downloadAirdropBountyResultResult || {};

    if (!participate_list_file) {
      const airdropForceClose = await airdropBountyForceCloseParser(airdropId);
      return;
    }

    const result = { participateListFile: participate_list_file };
    return result;
  });

export const downloadAirdropBountyTemplateAction = (airdropId) =>
  parseAction(async () => {
    const downloadAirdropBountyTemplateResult = await downloadAirdropBountyTemplateParser(airdropId);
    const result = { templateFile: downloadAirdropBountyTemplateResult.template_file };
    return result;
  });

export const uploadAirdropBountyResultAction = (airdropId, formData) =>
  parseAction(async () => {
    const uploadAirdropBountyResultResult = await uploadAirdropBountyResultParser(airdropId, 1, formData);
    return uploadAirdropBountyResultResult;
  });

export const reuploadAirdropBountyResultAction = (airdropId) =>
  parseAction(async () => {
    const reuploadAirdropBountyResultResult = await reuploadAirdropBountyResultParser(airdropId);
    return reuploadAirdropBountyResultResult;
  });

export const airdropBountyDistributeAction = (airdropId) =>
  parseAction(async () => {
    const airdropBountyDistributeResult = await airdropBountyDistributeParser(airdropId);
    return airdropBountyDistributeResult;
  });
