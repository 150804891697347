import React from "react";
import BasicButton from "view/components/_atom/button/BasicButton";
import { ButtonSize } from "view/components/_atom/button/StyledButton";
import ButtonContainer from "view/components/_atom/container/ButtonContainer";
import useValidateCondition from "hooks/useValidateCondition";
import {
  VALIDATION__NETWORK_coin_image,
  VALIDATION__NETWORK_coin_name,
  VALIDATION__NETWORK_coin_symbol,
  VALIDATION__NETWORK_initial_supply,
  VALIDATION__NETWORK_name,
} from "../../validation/networkValidation";
import useIsAble from "hooks/useIsAble";
import NetworkBasicInfoField from "../molecule/NetworkBasicInfoField";

type NetworkBasicInfoSettingProps = {
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  networkData: any;
  setNetworkData: React.Dispatch<React.SetStateAction<any>>;
};

const NetworkBasicInfoSetting = (props: NetworkBasicInfoSettingProps) => {
  const { setCurrentStep, networkData, setNetworkData } = props;

  const nextButtonHandler = () => {
    setCurrentStep((prevState) => prevState + 1);
  };

  const networkNameValidation = useValidateCondition([networkData.network.label], VALIDATION__NETWORK_name(networkData.network.label));

  const coinNameValidation = useValidateCondition([networkData.currency.name], VALIDATION__NETWORK_coin_name(networkData.currency.name));
  const coinSymbolValidation = useValidateCondition([networkData.currency.symbol], VALIDATION__NETWORK_coin_symbol(networkData.currency.symbol));
  const coinImageValidation = useValidateCondition([networkData.currency.image], VALIDATION__NETWORK_coin_image(networkData.currency.image));
  const initialSupplyValidation = useValidateCondition(
    [networkData.microChainSetting.initialSupply],
    VALIDATION__NETWORK_initial_supply(networkData.microChainSetting.initialSupply)
  );

  const isAble: boolean = useIsAble([
    networkNameValidation.isValid,
    coinNameValidation.isValid,
    coinSymbolValidation.isValid,
    coinImageValidation.isValid,
    initialSupplyValidation.isValid,
  ]);

  return (
    <div>
      <NetworkBasicInfoField
        networkData={networkData}
        setNetworkData={setNetworkData}
        networkNameValidation={networkNameValidation}
        coinNameValidation={coinNameValidation}
        coinSymbolValidation={coinSymbolValidation}
        coinImageValidation={coinImageValidation}
        initialSupplyValidation={initialSupplyValidation}
      />
      <div className="network-button-container">
        <ButtonContainer>
          <BasicButton size={ButtonSize.LARGE} disabled={!isAble} onClick={() => nextButtonHandler()}>
            Next
          </BasicButton>
        </ButtonContainer>
      </div>
    </div>
  );
};

export default NetworkBasicInfoSetting;
