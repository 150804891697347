export type UserFromServer = {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  email_verification: number;
  image: string;
  region: null;
  created_at: string;
  updated_at?: string;
};

export type UserTokenInfoFromServer = UserFromServer & {
  token: TokensFromServer;
  project_token?: TokensFromServer;
};

export type MyInfoFromServer = UserTokenInfoFromServer & {
  last_password_changed_at: string;
  change_password_recommended_date: string;
  is_mfa_set: number;
};

export type TokensFromServer = {
  access: TokenFromServer;
  refresh: {
    expires: string;
  };
};

type TokenFromServer = {
  token: string;
  expires: string;
};

export enum AuthCodeType {
  SIGN_UP = "signUp",
  FIND_PASSWORD = "findPassword",
  INVITE = "invite",
}

export type TotpKeyFromServer = {
  totp_key: string;
  qr: {
    provider: string;
    email: string;
    issuer: string;
  };
};
