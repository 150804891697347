import React from "react";
import "./RecommendedChangePasswordModal.scss";
import ModalTemplateB from "../_template/modal/ModalTemplateB";
import { checkPasswordRecommendedDateAction } from "action/userAction";
import ButtonContainer from "../_atom/container/ButtonContainer";
import BasicButton from "../_atom/button/BasicButton";
import { ButtonTheme } from "../_atom/button/StyledButton";
import { useNavigate } from "react-router-dom";

type RecommendedChangePasswordModalProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const RecommendedChangePasswordModal = (props: RecommendedChangePasswordModalProps) => {
  const { isOpen, setIsOpen } = props;

  const navigate = useNavigate();

  const changePasswordRecommendedDate_ = async () => {
    const { result, error } = await checkPasswordRecommendedDateAction();
    if (result) {
      setIsOpen(false);
    }
  };

  const goToProfile = () => {
    navigate("/user/profile");
    setIsOpen(false);
  };

  return (
    <ModalTemplateB isOpen={isOpen} onRequestClose={() => setIsOpen(false)} title={"Change your password"} shouldCloseOnOverlayClick={false}>
      <div className="recommended-change-password-modal">
        <span className="recommended-change-password-modal-description">
          {`Your current password has not been changed for 90 days.\nPlease change your current password.`}
        </span>
        <div className="recommended-change-password-modal-button-wrapper">
          <ButtonContainer>
            <BasicButton theme={ButtonTheme.SECONDARY} onClick={() => changePasswordRecommendedDate_()}>
              After 90 Days
            </BasicButton>
            <BasicButton theme={ButtonTheme.PRIMARY} onClick={() => goToProfile()}>
              Change
            </BasicButton>
          </ButtonContainer>
        </div>
      </div>
    </ModalTemplateB>
  );
};

export default RecommendedChangePasswordModal;
