import { useEffect, useState } from "react";
import "./RequestsHistoryTable.scss";
import { DashboardRequestHistoryType } from "../constants/dashboard.type";
import { getRequestHistoryAction } from "../../../../action/dashboardAction";
import { FromTick } from "server/type/projectType";
import { errorAlert } from "utils/Utils";
import { numberConvertor } from "utils/convert.utils";
import SortingTable from "./SortingTable";
import EmptyDataTemplate from "./EmptyDataTemplate";
import SectionContainer from "view/components/_atom/section/SectionContainer";
import chartIcon from "../assets/Icon_dashboard_chart.png";
import { EMPTY_CHART_TEXT } from "../constants/dashboard.data";

const RequestsHistoryTable = () => {
  const [requestHistory, setRequestHistory] = useState<DashboardRequestHistoryType[]>([]);

  const REQUEST_ACTIVITY_COLGROUP: number[] = [33, 19, 26, 22];

  const REQUEST_ACTIVITY_RENDERER = (data: DashboardRequestHistoryType) => ({
    method: <div className="sorting-table-data bold">{data.method}</div>,
    networkLabel: <div className="sorting-table-data gray">{data.networkLabel}</div>,
    count: numberConvertor(data.count),
    credit: numberConvertor(data.microCredit ?? 0),
  });

  const REQUEST_ACTIVITY_HEADER: { title: string; accessor: string; sortable: boolean }[] = [
    { title: "Method", accessor: "method", sortable: false },
    { title: "Network", accessor: "networkLabel", sortable: false },
    { title: "Requests Volume", accessor: "count", sortable: true },
    { title: "EQ Credit", accessor: "credit", sortable: true },
  ];

  const getRequestHistory_ = async () => {
    const { result, error } = await getRequestHistoryAction(FromTick.WEEK);
    if (result) {
      setRequestHistory(result);
    }
    if (error) {
    }
  };

  useEffect(() => {
    getRequestHistory_();
  }, []);

  return (
    <SectionContainer title="Requests History" icon={chartIcon}>
      <div className="requests-history-table-container">
        {requestHistory.length > 0 ? (
          <SortingTable headers={REQUEST_ACTIVITY_HEADER} colGroup={REQUEST_ACTIVITY_COLGROUP} data={requestHistory} renderer={REQUEST_ACTIVITY_RENDERER} />
        ) : (
          <EmptyDataTemplate>{EMPTY_CHART_TEXT}</EmptyDataTemplate>
        )}
      </div>
    </SectionContainer>
  );
};

export default RequestsHistoryTable;
