import {BrowserRouter} from "react-router-dom";
import "./view/styles/container.scss";
import "./view/styles/reset.scss";
import BigNumber from "bignumber.js";
import toast, {Toaster, useToasterStore} from "react-hot-toast";
import RootRouter from "./view/frame/RootRouter";
import {showServerLogs,} from "./server/index/serverInstances";
import {useEffect} from "react";
import EventEmitter from "events";
import {MethodManager, MethodWithAddQueue, Queue} from "./modules/logic/Queue.logic";
import {refreshUserAccessToken} from "./server/index/utils";
import {EQHub} from "./modules/logic/dist";

BigNumber.config({EXPONENTIAL_AT: 1e9});

showServerLogs();

// export const eqhub = new EQHub();

export const eqhubLogic = new EQHub();
const projectMethodResultEventManager = new EventEmitter();
const projectMethodManager = new MethodManager(refreshUserAccessToken)
export const projectMethodQueue = new Queue(projectMethodManager, projectMethodResultEventManager);
export const eqhub = new MethodWithAddQueue(eqhubLogic, projectMethodQueue, projectMethodResultEventManager);


function App() {
    const {toasts} = useToasterStore();
    const TOAST_LIMIT = 1;

    // Enforce Toast Limit
    useEffect(() => {
        toasts
            .filter((t) => t.visible)
            .filter((_, i) => i >= TOAST_LIMIT)
            .forEach((t) => toast.dismiss(t.id));
    }, [toasts]);
    return (
        <BrowserRouter>
            <RootRouter/>
            <Toaster toastOptions={{dismiss: false}}/>
        </BrowserRouter>
    );
}

export default App;
