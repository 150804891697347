import { createSlice } from '@reduxjs/toolkit';

// Slice 영역 - 선언부
const connectSlice = createSlice({
  name: 'connect',
  initialState:{
    address : '',
    type: '',
  },
  reducers: {
    setConnectedInfo(state, action) {
      state.address = action.payload.address;
      state.type = action.payload.type
    },
    removeConnectedInfo(state){
      state.address = '';
      state.type = '';
    }
  },
});

// Action 영역
export const { setConnectedInfo, removeConnectedInfo } = connectSlice.actions;

// Store 데이터 영역

export const connectInfo = state => state.connect

// Reducer 영역
export default connectSlice.reducer;
