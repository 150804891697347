import React from "react";
import RowFieldContainer from "view/components/_atom/container/RowFieldContainer";
import BasicInput from "../../../../components/_atom/input/BasicInput";
import BasicButton from "../../../../components/_atom/button/BasicButton";
import {ButtonSize, ButtonTheme} from "view/components/_atom/button/StyledButton";
import ButtonContainer from "../../../../components/_atom/container/ButtonContainer";
import {ProjectDataType} from "../page/CreateProject";

type CreateProjectStep3Props = {
  setStep: React.Dispatch<React.SetStateAction<number>>;
  projectData: ProjectDataType;
  setProjectData: React.Dispatch<React.SetStateAction<ProjectDataType>>;
  createProject: Function;
};

const CreateProjectStep3 = ({ setStep, projectData, setProjectData, createProject }: CreateProjectStep3Props) => {
  const setCardDetail = (value: string): void => {
    setProjectData((prevState) => {
      return { ...prevState, cardDetail: value };
    });
  };
  const setExpirationDate = (value: string): void => {
    setProjectData((prevState) => {
      return { ...prevState, expirationDate: value };
    });
  };
  const setCVC = (value: string): void => {
    setProjectData((prevState) => {
      return { ...prevState, cvc: value };
    });
  };

  return (
    <div className="create-project-step3">
      <RowFieldContainer label={"Card Details"}>
        <BasicInput placeholder="Enter card number" onChange={(e) => setCardDetail(e.target.value)} value={projectData.cardDetail} />
      </RowFieldContainer>
      <RowFieldContainer label={"Expiration date"}>
        <BasicInput placeholder="MM / YY" onChange={(e) => setExpirationDate(e.target.value)} value={projectData.expirationDate} />
      </RowFieldContainer>
      <RowFieldContainer label={"CVC"}>
        <BasicInput placeholder="CVC" onChange={(e) => setCVC(e.target.value)} value={projectData.cvc} />
      </RowFieldContainer>
      <div className="flex justify-content-flex-end">
        <ButtonContainer>
          <BasicButton theme={ButtonTheme.SECONDARY} size={ButtonSize.LARGE} onClick={() => setStep((prevStep) => prevStep - 1)}>
            Back
          </BasicButton>
          {projectData.pricingPlanId === 1 && (
            <BasicButton theme={ButtonTheme.SECONDARY} size={ButtonSize.LARGE} onClick={() => {}}>
              Skip
            </BasicButton>
          )}
          <BasicButton size={ButtonSize.LARGE} onClick={() => createProject()}>
            Create
          </BasicButton>
        </ButtonContainer>
      </div>
    </div>
  );
};

export default CreateProjectStep3;
