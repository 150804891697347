import {
  checkTokenNameParser,
  createTokenParser,
  deleteTokenParser,
  getDefaultTokenImageListParser,
  getTokenDetailParser,
  getTokenListParser,
  registerTokenToWalletParser,
  submitTokenParser,
  updateTokenParser,
  uploadTokenImageToS3Parser,
} from '../parser/currencyParser';
import { parseAction } from './util/parseAction';
import { getExternalAndInnerMicroChainListParser, getNetworkLabelAndMicroChainLabelParser } from '../parser/microChainParser';
import { GetTokenListOptions, TokenType } from 'reducer/clientType/tokenClientType';
import { GetMicroChainListOptionsType } from 'reducer/clientType/microChainClientType';
import { networkParserNew } from '../utils/Utils';
import { WEB3 } from 'modules/web3/Web3';
import { getMicroChainIdsOfProjectParser } from 'parser/projectParser';
import { getListedContractDetailParser } from '../parser/contractParser';

//============ Create Token ============//
export const createTokenAction = (
  name: string,
  image: string,
  symbol: string,
  originMicroChainId: number,
  initialSupply: string,
  officialSite: string,
  useWallet: boolean,
  mintable: boolean,
  burnable: boolean
) =>
  parseAction(async () => {
    const createTokenResult = await createTokenParser(name, image, symbol, originMicroChainId, initialSupply, officialSite, useWallet, mintable, burnable);
    const result = createTokenResult;
    return result;
  });

export const getDefaultTokenImageAction = () =>
  parseAction(async () => {
    const result = await getDefaultTokenImageListParser();
    return result;
  });

export const uploadCurrencyImageToS3Action = (formData: FormData, fileCount: number) =>
  parseAction(async () => {
    const result = await uploadTokenImageToS3Parser(formData, fileCount);
    return result;
  });

export const checkCurrencyNameAction = (name: string) =>
  parseAction(async () => {
    const result = await checkTokenNameParser(name);
    return result;
  });

export const getInitialDataForCreateTokenAction = () =>
  parseAction(async () => {
    const microChainIdsOfProject = await getMicroChainIdsOfProjectParser();
    let microChainList = [];
    if (microChainIdsOfProject.length) {
      microChainList = await getExternalAndInnerMicroChainListParser({
        microChainIds: microChainIdsOfProject,
        mainStatus: [5],
      });
    }
    const networkList = networkParserNew(microChainList);

    return {
      networkList,
      microChainList,
    };
  });

//============ Token List ============//

export const getTokenListAction = (tokenListOptions: GetTokenListOptions, microChainListOptions: GetMicroChainListOptionsType) =>
  parseAction(async () => {
    const tokenList = await getTokenListParser(tokenListOptions);

    const microChainList = await getExternalAndInnerMicroChainListParser({ mainStatus: microChainListOptions.mainStatus });

    const networkList = networkParserNew(microChainList);

    const result = tokenList
      .map((token: TokenType) => ({
        currencyId: token.currencyId,
        name: token.name,
        image: token.image,
        mainStatus: token.mainStatus,
        subStatus: token.subStatus,
        issued: WEB3.fromWei(token.currencyDetail.initialSupply),
        network: networkList.find((net) => net.id === token.originNetworkId).label,
        microChain: microChainList.find((chain) => chain.id === token.originMicroChainId).label,
        symbol: token.symbol,
      }))
      .slice()
      .reverse();
    return result;
  });

//============ Manage Token ============//
export const getTokenDetailAction = (currencyId: number) =>
  parseAction(async () => {
    const tokenDetail = await getTokenDetailParser(currencyId);
    const { networkLabel, microChainLabel } = await getNetworkLabelAndMicroChainLabelParser(tokenDetail.originMicroChainId);

    const originMicroChainCurrency = tokenDetail?.microChainCurrencies.find((el) => el.microChainId === tokenDetail.originMicroChainId);
    let tokenContractTxHash: string | null = null;
    if (tokenDetail.mainStatus > 3 && originMicroChainCurrency) {
      const { transactionHash } = await getListedContractDetailParser(originMicroChainCurrency.contractId);
      tokenContractTxHash = transactionHash;
    }
    const result = {
      ...tokenDetail,
      currencyDetail: {
        ...tokenDetail.currencyDetail,
        initialSupply: WEB3.fromWei(tokenDetail.currencyDetail.initialSupply),
      },
      ...(originMicroChainCurrency && { originMicroChainCurrency: originMicroChainCurrency }),
      transactionHash: tokenContractTxHash,
      networkLabel,
      microChainLabel,
    };
    return result;
  });

export const deleteTokenAction = (currencyId: number) =>
  parseAction(async () => {
    const result = await deleteTokenParser(currencyId);
    return result;
  });

export const updateTokenAction = (
  currencyId: number,
  name: string,
  image: string,
  symbol: string,
  initialSupply: string,
  mintable: boolean,
  burnable: boolean,
  officialSite: string,
  useWallet: boolean
) =>
  parseAction(async () => {
    const updatedToken = await updateTokenParser(currencyId, name, image, symbol, initialSupply, mintable, burnable, officialSite, useWallet);
    const result = {
      ...updatedToken,
      currencyDetail: {
        ...updatedToken.currencyDetail,
        initialSupply: WEB3.fromWei(updatedToken.currencyDetail.initialSupply),
      },
    };
    return result;
  });

export const submitTokenAction = (currencyId: number) =>
  parseAction(async () => {
    const result = await submitTokenParser(currencyId);
    const originMicroChainCurrency = result?.microChainCurrencies.find((el) => el.microChainId === result.originMicroChainId);
    const { networkLabel, microChainLabel } = await getNetworkLabelAndMicroChainLabelParser(result.originMicroChainId);

    return {
      ...result,
      currencyDetail: {
        ...result.currencyDetail,
        initialSupply: WEB3.fromWei(result.currencyDetail.initialSupply),
      },
      ...(originMicroChainCurrency && { originMicroChainCurrency: originMicroChainCurrency }),
      networkLabel,
      microChainLabel,
    };
  });

export const registerTokenToWalletAction = (currencyId: number) =>
  parseAction(async () => {
    const result = await registerTokenToWalletParser(currencyId);
    return result;
  });
