import React from "react";
import "./ButtonContainer.scss";

const ButtonContainer = ({ children, style, vertical = "flex-end" }) => {
  return (
    <div className="button-container" style={{ ...(style && style), justifyContent: vertical }}>
      {children}
    </div>
  );
};
export default ButtonContainer;
