import React, { useEffect, useRef } from "react";
import "./ColorPicker.scss";
import { ChromePicker } from "react-color";

/**
 * @param {string} type - {hex}, hsl, hsv, rgb
 * @param {string} color - 선택된 color
 * @param {function} setColor - color의 set함수
 * @param {function} setColorComplete - color 선택을 완료한 후 실행되는 함수
 * @param {boolean} isOpen - colorPicker 의 활성화 여부
 * @param {function} setIsOpen - colorPicker 활성화의 set함수
 */
const ColorPicker = (props) => {
  const { type = "hex", color: cardColor, setColor, setColorComplete, isOpen, setIsOpen } = props || {};

  const colorPickerRef = useRef(null);

  useEffect(() => {
    function checkClickOutside(e) {
      if (colorPickerRef.current && !colorPickerRef.current.contains(e.target)) {
        setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", checkClickOutside);
    return () => document.removeEventListener("mousedown", checkClickOutside);
  }, [colorPickerRef]);

  const setColor_ = (color) => {
    setColor(color[type]);
  };

  return (
    <div className="color-control-wrapper">
      <div className="color-box">
        <div className="color-paint" style={{ backgroundColor: cardColor }} onClick={() => setIsOpen((prevState) => !prevState)} />
      </div>
      {isOpen && (
        <div className="color-picker-wrapper" ref={colorPickerRef}>
          <ChromePicker
            color={cardColor}
            onChange={(color) => setColor_(color)}
            onChangeComplete={(color) => setColorComplete && setColorComplete(color)}
          />
        </div>
      )}
    </div>
  );
};

export default ColorPicker;
