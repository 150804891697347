import {parseAction} from "./util/parseAction";
import {getExternalAndInnerMicroChainListParser} from "../parser/microChainParser";
import _ from "lodash";
import {
  getContractByContractAddressParser,
  getMyListedContractParser
} from "../parser/contractParser";
import {sortByKey} from "../utils/Utils";
import {getMicroChainIdsOfProjectParser} from "../parser/projectParser";
import {getABICodeByFileURLParser, getContractVersionDetailParser} from "../parser/contractLibraryParser";
import {unionABI} from "../view/service/developer/contract/constants/contract.utils";

export const getChainsForChainRequestAction = () =>
  parseAction(async () => {
    let microChains: Array<any> = [];
    const microChainIdsOfProject = await getMicroChainIdsOfProjectParser();
    if(microChainIdsOfProject.length !== 0){
      microChains = await getExternalAndInnerMicroChainListParser({ microChainIds: microChainIdsOfProject, mainStatus: [5] });
    }
    const networks = _.uniqBy(
      microChains.map((chain) => chain.network),
      "id"
    );
    return {
      microChains,
      networks: networks,
    };
  });

export const getDeployedContractByChainIdAction = (microChainId: number) =>
  parseAction(async () => {
    const result = await getMyListedContractParser({
      microChainId: [microChainId],
      mainStatus: [5],
    });
    return sortByKey("createdAt", result, "upper").filter((el) => !!el.originVersion);
  });

export const getContractFunctionsWithAbiCodeAction = (microChainId: number, contractAddress: string) =>
  parseAction(async () => {
    const contract = await getContractByContractAddressParser(contractAddress, microChainId);
    const {originContractVersionId, originContractId} = contract;

    const contractVersionDetail = await getContractVersionDetailParser(originContractId, originContractVersionId)
    const {contractVersionCode} = contractVersionDetail
    const {constructor, functions, events} = await getABICodeByFileURLParser(contractVersionCode.customAbiCode);

    let abiArray = [functions, events];
    if(constructor){
      abiArray = [constructor, ...abiArray];
    }
    return {
      abiCode: unionABI(abiArray),
      functions,
    };
  });
