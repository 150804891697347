import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import ExtensionListener from "../../../modules/EQExtension/ExtensionListener";
import { useSelector } from "react-redux";
import Header from "../header/Header";
import "./MainLayout.scss";
import SNB from "../side_navigation/SNB";
import NotFound from "../../components/_page/notFound/NotFound";
import { ScrollContainer } from "../../components/_atom/container/ScrollContainer";
import useVisibleScrollView from "../../../hooks/useVisibleScrollView";
import Footer from "../footer/Footer";
import helpIcon from "../../assets/images/icon_help_button.png";
import { TABS_IN_USE } from "../side_navigation/tabs";
import moment from "moment";
import { setIsExpired, user_info, user_is_expired } from "reducer/userReducer";
import { storeDispatch } from "reducer/index";
import SignInExpiredModal from "view/components/_modal/SignInExpiredModal";
import RecommendedChangePasswordModal from "view/components/_modal/RecommendedChangePasswordModal";

const MainLayout = function () {
  const { ref, scrollVisible } = useVisibleScrollView();

  const signInExpired = useSelector(user_is_expired);
  const changePasswordRecommendedDate = useSelector(user_info).changePasswordRecommendedDate;

  const [isRecommendedChangePassword, setIsRecommendedChangePassword] = useState(false);

  const checkPasswordChangeRecommendedPeriod = () => {
    const recommendedDate = moment(changePasswordRecommendedDate).utc(0);
    const currentDate = moment().utc(0);
    setIsRecommendedChangePassword(currentDate.isAfter(recommendedDate));
  };

  useEffect(() => {
    checkPasswordChangeRecommendedPeriod();
  }, []);

  return (
    <>
      <ExtensionListener />
      <Header />
      <div id={"MainLayout"}>
        {signInExpired && <SignInExpiredModal isOpen={signInExpired} closeModal={() => storeDispatch(setIsExpired(false))} />}
        {isRecommendedChangePassword && <RecommendedChangePasswordModal isOpen={isRecommendedChangePassword} setIsOpen={setIsRecommendedChangePassword} />}
        <HelpButton />
        <SNB />
        {/* {isValidPath ? ( */}
        <ScrollContainer ref={ref} scrollVisible={scrollVisible}>
          <Outlet />
          <Footer />
        </ScrollContainer>
        {/* ) : ( */}
        {/* <NotFound /> */}
        {/* )} */}
      </div>
    </>
  );
};

const HelpButton = () => {
  return (
    <div className="help-button" onClick={() => window.open(process.env.REACT_APP_EQ_HUB_DOCS_URL)}>
      <img className="help-button-icon" src={helpIcon} alt={""} />
    </div>
  );
};

export default MainLayout;
