import { useMemo } from 'react';
import './StatusDetail.scss';
import rejectIcon from '../../../../view/assets/images/icon-status-label-rejected.png';
import createdIcon from '../../../../view/assets/images/icon-status-label-created.png';
import preparedIcon from '../../../../view/assets/images/icon-status-label-prepared.png';
import processingIcon from '../../../../view/assets/images/icon-status-label-processing.png';
import distributedIcon from '../../../../view/assets/images/icon-status-label-distributed.png';

export const DEFAULT_LABEL_STYLE = [
  {
    status: 0,
    style: {
      icon: rejectIcon,
      backgroundColor: '#FFF3F3',
      borderColor: '#EFC2C2',
      mainColor: '#CF7171',
      descriptionColor: '#A84747',
    },
    text: 'Rejected',
    description: 'Your approval request has been declined. Please check the reason for the rejection.',
  },
  {
    status: 1,
    style: {
      icon: createdIcon,
      backgroundColor: '#F2F5FE',
      borderColor: '#C8D5EE',
      mainColor: '#8997C9',
      descriptionColor: '#5E7196',
    },
    text: 'Created',
    description: 'The process of entering data has ended. \nNow, move on to the next step.',
  },
  {
    status: 2,
    style: {
      icon: processingIcon,
      backgroundColor: '#FFFCF8',
      borderColor: '#F1DEC0',
      mainColor: '#F4C983',
      descriptionColor: '#CC9D52',
    },
    text: 'Submitted',
    description: 'An approval request has been sent.\nPlease wait a moment for an approval / rejection response.',
  },
  {
    status: 3,
    style: {
      icon: preparedIcon,
      backgroundColor: '#EDF8FF',
      borderColor: '#C8D9EE',
      mainColor: '#7A95AE',
      descriptionColor: '#647E92',
    },
    text: 'Prepared',
    description: 'Preparation process for deployment.',
  },
  {
    status: 4,
    style: {
      icon: processingIcon,
      backgroundColor: '#FFFCF8',
      borderColor: '#F1DEC0',
      mainColor: '#F4C983',
      descriptionColor: '#CC9D52',
    },
    text: 'Requested',
    description: 'Deployment request sent.',
  },
  {
    status: 5,
    style: {
      icon: distributedIcon,
      backgroundColor: '#F4FFF8',
      borderColor: '#B0DCB9',
      mainColor: '#6BC97E',
      descriptionColor: '#3C9A4F',
    },
    text: 'Deployed',
    description: 'Deployment completed.',
  },
];

/**
 * props = mainStatus(int) #required, custom(object)
 *
 * custom = { status:(int), style:(int), text:(string) }
 *
 * style
 * - (0) Reject (red)
 * - (1) Create (gray)
 * - (2) Approval (yellow)
 * - (3) Prepared (green)
 * - (4) Processing (yellow)
 * - (5) Distributed (blue)
 */

const StatusDetail = (props) => {
  const { status, data, customStyle = null } = props || {};

  const labelStyle = useMemo(() => {
    if (customStyle?.find((custom) => custom.status === status)) {
      return DEFAULT_LABEL_STYLE.find((el) => el.status === customStyle.find((custom) => custom.status === status).customStatus);
    } else {
      return DEFAULT_LABEL_STYLE.find((el) => el.status === status);
    }
  }, [status, customStyle]);

  return (
    <div
      className="status-detail"
      style={{
        backgroundColor: labelStyle?.style?.backgroundColor,
        borderColor: labelStyle?.style?.borderColor,
      }}
    >
      <div className="status-detail-title">
        <img className="status-detail-title-icon" src={labelStyle?.style?.icon} alt="" />
        <div className="status-detail-title-text" style={{ color: labelStyle?.style?.mainColor }}>
          {data.text}
        </div>
      </div>
      <div className="status-detail-description" style={{ color: labelStyle?.style?.descriptionColor }}>
        {data.description ?? ''}
      </div>
    </div>
  );
};

export default StatusDetail;
