import React, {useState} from "react";
import moment from "moment";
import toast from "react-hot-toast";
import "./CreateNewKeyModal.scss";
import BasicButton from "../_atom/button/BasicButton";
import {ButtonSize} from "../_atom/button/StyledButton";
import BasicInput from "../_atom/input/BasicInput";
import useIsAble from "../../../hooks/useIsAble";
import {WEB3} from "../../../modules/web3/Web3";
import ModalTemplateA from "../../../legacy-common/template/modal/ModalTemplateA";

const CreateNewKeyModal = (props) => {
  const {title, isOpen, closeModal} = props || {};

  const [password, setPassword] = useState("");

  const onClickGenerateKeystore = () => {
    try {
      const newAccount = WEB3.createAccount();
      const {privateKey} = newAccount || {};
      const hexPrivateKey = privateKey.slice(2, privateKey.length);
      const keyStoreJson = WEB3.encryptKeystore(privateKey, password);
      const currentDate = moment().format();
      const element = document.createElement("a");
      const file = new Blob([`${JSON.stringify(keyStoreJson)}`], {
        type: "application/octet-stream",
      });
      // 클립보드에 생성된 키스토어 파일에 해당하는 address 복사
      const createdAddressInKeystoreJson = `0x${keyStoreJson.address}`;
      toast.success("The address of the created keystore file has been copied.");
      navigator.clipboard.writeText(createdAddressInKeystoreJson);
      element.href = URL.createObjectURL(file);
      element.download = `UTC--${currentDate}--${keyStoreJson.address}`;
      document.body.appendChild(element);
      element.click();
      closeModal();
      // setShowMnemonic(mnemonic.encode(hexPrivateKey));
    } catch (e) {
      window.alert(e.message);
    }
  };
  const isAble = useIsAble([password === ""]);

  return (
    <ModalTemplateA title={title} isOpen={isOpen} closeModal={() => closeModal()}>
      <section className="create-new-key-modal-content">
        <p className="create-new-key-modal-content-input-label">{`Your password for a new key`}</p>
        <BasicInput type={"password"} placeholder={"Enter password"} value={password}
                    onChange={(e) => setPassword(e.target.value)}/>
        <ul className="create-new-key-modal-content-description">
          <li className="create-new-key-modal-content-description-row">Do not forget your password to use this keystore
            file.
          </li>
          <li className="create-new-key-modal-content-description-row">Do not share and lose this keystore file.</li>
        </ul>
        <div className="create-new-key-modal-button-wrapper">
          <BasicButton size={ButtonSize.LARGE} onClick={() => onClickGenerateKeystore()} disabled={isAble}>
            Download keystore file
          </BasicButton>
        </div>
      </section>
    </ModalTemplateA>
  );
};

export default CreateNewKeyModal;
