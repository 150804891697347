import { useEffect, useMemo } from "react";
import "./ManageProject.scss";
import createProjectImage from "../assets/img_create_project.png";
import inviteProjectImage from "../assets/img_invite_project.png";
import BasicButton from "../../../../components/_atom/button/BasicButton";
import { useNavigate } from "react-router-dom";
import quitIcon from "../../../../assets/images/icon_selectProject_quit_right.png";
import { useSelector } from "react-redux";
import { project_id_list } from "reducer/projectReducer";
import { getProjectsByUserIdAction, logoutAction } from "action/userAction";
import { logout } from "server/index/utils";

type ManageProjectProps = {
  invitedListCount: number;
};

const ManageProject = (props: ManageProjectProps) => {
  const { invitedListCount } = props;
  const projectIdList = useSelector(project_id_list);

  const getProjectsByUserId_ = async () => {
    await getProjectsByUserIdAction();
  };

  const logout_ = async () => {
    await logout();
  };

  const navigate = useNavigate();

  const inviteNoticeRender = (): string => {
    if (invitedListCount === 0) {
      return "You haven't been invited to a project yet";
    }
    return "Go to see invited projects";
  };

  const quitData = useMemo(() => {
    if (projectIdList.length === 0) {
      return {
        onClick: () => logout_(),
        text: "Sign out",
      };
    }
    return {
      onClick: () => navigate("/"),
      text: "Go to EQ Hub",
    };
  }, [projectIdList]);

  useEffect(() => {
    getProjectsByUserId_();
  }, []);

  return (
    <div className="manage-project">
      <div className="manage-project-header">
        <div className="manage-project-header-title-wrapper">
          <p className="manage-project-header-title">New Project</p>
          <div className="manage-project-quit-wrapper" onClick={quitData.onClick}>
            <div className="manage-project-quit-text">{quitData.text}</div>
            <img src={quitIcon} alt="quit" className="manage-project-quit-icon" />
          </div>
        </div>
        <span className="manage-project-header-description">{`Now set up a project to use EQ Hub.\nYou can create your own project,\nor you can be invited to a project by another EQ Hub user.`}</span>
      </div>
      <div className="manage-project-option">
        <div className="manage-project-option-item">
          <div className="manage-project-option-item-image">
            <img src={createProjectImage} className="manage-project-option-item-image-src" alt={""} />
          </div>
          <span className="manage-project-option-item-label">Create your own project</span>
          <BasicButton onClick={() => navigate("create")}>Go to Create</BasicButton>
        </div>
        <div className="manage-project-option-item">
          <div className="manage-project-option-item-image">
            <img src={inviteProjectImage} className="manage-project-option-item-image-src" alt={""} />
          </div>
          <span className="manage-project-option-item-label">Join the invited projects</span>
          <BasicButton onClick={() => navigate("join")} disabled={invitedListCount === 0}>
            Go to Join
          </BasicButton>
          <span className="manage-project-option-item-description">{inviteNoticeRender()}</span>
        </div>
      </div>
    </div>
  );
};

export default ManageProject;
