import { addComma } from "../../../../../utils/Utils";
export const NUMBER_REG_EXP = /^[,\d]+$/;

export const BooleanParser = (value) => {
  switch (value) {
    case true:
      return 1;
    case false:
      return 0;
    default:
      return 0;
  }
};

export const stringNumInputHandler = (value, setValue) => {
  if (typeof value === "string" && value === "") {
    setValue("");
  }
  if (!NUMBER_REG_EXP.test(value)) {
    return;
  } else {
    setValue(addComma(value));
  }
};
