import React, {ReactNode} from 'react';
import './RowFieldContainer.scss';

type RowFieldContainerProps = {
  label: string | ReactNode;
  children: ReactNode;
  labelSize?: number;
}
const RowFieldContainer = ({label, children, labelSize = 270}: RowFieldContainerProps) => {
  return (
    <div className='row-field-container'>
      <div className='row-field-container-label' style={{width: labelSize}}>{label}</div>
      <div className="row-field-container-content">
        {children}
      </div>
    </div>
  );
};

export default RowFieldContainer;
