import { useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { NUMBER_REG_EXP } from "../../../../../constants/commonConstants";
import ProgressState from "../../../../components/_molecules/progressState/ProgressState";
import { addComma, errorAlert } from "../../../../../utils/Utils";
import {
  AIRDROP_DATA_FORMAT,
  AIRDROP_DISTRIBUTION_AMOUNT_FORMAT,
  AIRDROP_PARTICIPANT_EVENT_FORMAT,
  AIRDROP_PARTICIPANT_LIMIT_FORMAT,
  AIRDROP_STEP_LIST,
} from "../constants/airdropConstants";
import AirdropBasicInfoSetting from "../component/organism/AirdropBasicInfoSetting";
import AirdropBudgetSetting from "../component/organism/AirdropBudgetSetting";
import AirdropOperatingWaySetting from "../component/organism/AirdropOperatingWaySetting";
import AirdropProjectPurposeSetting from "../component/organism/AirdropProjectPurposeSetting";
import { selected_project_id } from "reducer/projectReducer";
import { createAirdropAction, getAirdropPresetAction, getChainDataForAirdropAction } from "action/airdropAction";
import PageArticle from "view/components/_template/page/PageArticle";
import PageTitle from "view/components/_molecules/page/PageTitle";
import PageLoading from "view/components/_atom/loading/PageLoading";
import DivideLine from "view/components/_atom/line/DivideLine";
import useLoading from "hooks/useLoading";

const CreateAirdrop = () => {
  const navigate = useNavigate();
  const projectId = useSelector(selected_project_id);

  const [currentStep, setCurrentStep] = useState(0);
  const [airdropData, setAirdropData] = useState(AIRDROP_DATA_FORMAT);

  const [participationPeriodTypeList, setparticipationPeriodTypeList] = useState([]);
  const [distributionTypeList, setDistributionTypeList] = useState([]);
  const [distributionAmountTypeList, setDistributionAmountTypeList] = useState([]);
  const [participantConditionTypeList, setParticipantConditionTypeList] = useState([]);

  const [allNetworkList, setAllNetworkList] = useState([]);
  const [allMicroChainList, setAllMicroChainList] = useState([]);
  const [availableMicroChainList, setAvailableMicroChainList] = useState([]);
  const [availableCurrencyList, setAvailableCurrencyList] = useState([]);

  const [selectedNetworkId, setSelectedNetworkId] = useState(null);
  const [selectedMicroChainId, setSelectedMicroChainId] = useState(null);
  const [selectedCurrencyId, setSelectedCurrencyId] = useState(null);

  const [marketingChannelList, setMarketingChannelList] = useState([]);

  const [distributionAmountsData, setDistributionAmountsData] = useState([AIRDROP_DISTRIBUTION_AMOUNT_FORMAT]);
  const [participationLimitData, setParticipationLimitData] = useState(AIRDROP_PARTICIPANT_LIMIT_FORMAT);
  const [participationEventsData, setParticipationEventsData] = useState([AIRDROP_PARTICIPANT_EVENT_FORMAT]);

  const setData = (value, key, type) => {
    let parseValue = value;

    // 값이 빈값이면 null로 세팅
    if (typeof value === "string" && value === "") {
      setAirdropData((prevState) => {
        return { ...prevState, [key]: null };
      });
    }

    if (type === "number") {
      if (!NUMBER_REG_EXP.test(value)) {
        return;
      }
      parseValue = addComma(value);
    }

    setAirdropData((prevState) => {
      return { ...prevState, [key]: parseValue };
    });
  };

  const getInitialData_ = async () => {
    const { result: chainData, error: chainError } = await getChainDataForAirdropAction();
    if (chainData) {
      const { networkList, microChainList } = chainData || {};
      setAllNetworkList(networkList);
      setAllMicroChainList(microChainList);
    }
    if (chainError) {
      errorAlert(chainError.data.message);
    }

    const { result: airdropPreset, error: airdropPresetError } = await getAirdropPresetAction();
    if (airdropPreset) {
      const { participationPeriodTypes, distributionTypes, distributionAmountTypes, participationConditionTypes } = airdropPreset || {};
      setparticipationPeriodTypeList(participationPeriodTypes);
      setDistributionTypeList(distributionTypes);
      setDistributionAmountTypeList(distributionAmountTypes);
      setParticipantConditionTypeList(participationConditionTypes);
    }
    if (airdropPresetError) {
      errorAlert(airdropPresetError.data.message);
    }
  };

  const onClickBackButton = () => {
    setCurrentStep((prevState) => prevState - 1);
  };

  const onClickTypeButton = (el) => {
    if (el.disabled) {
      return;
    }
    setData(el.distributionAmountTypeId, "distributionAmountTypeId");
    setData(el.participationPeriodTypeId, "participationPeriodTypeId");
    setData(el.distributionTypeId, "distributionTypeId");
    setData(el.participationConditionTypeId, "participationConditionTypeId");
    setData(el.operationPurpose, "operationPurpose");
    setDistributionAmountsData([AIRDROP_DISTRIBUTION_AMOUNT_FORMAT]);
  };

  const createAirdrop_ = async () => {
    let formData = new FormData();
    if (typeof airdropData.projectImage !== "string") {
      formData.append("projectImage", airdropData.projectImage);
    }
    const { result, error } = await createAirdropAction(formData, airdropData, distributionAmountsData, participationLimitData, participationEventsData);
    if (result) {
      toast.success("Create successfully");
      navigate(`/application/airdrop/manage/${result.airdrop_id}`);
    }
    if (error) {
      errorAlert(error.data.message);
    }
  };

  const { loading } = useLoading({
    dependency: [projectId],
    synchronousFunction: async () => {
      await getInitialData_();
    },
  });

  if (loading) {
    return <PageLoading />;
  }

  return (
    <PageArticle scrollDependency={currentStep}>
      <PageTitle
        title={"Create Airdrop Project"}
        description={"Create your own airdrop project. \nYou can distribute your currency in various ways."}
        size={810}
      />
      <div className="page-layout-810">
        <ProgressState step={currentStep} stepList={AIRDROP_STEP_LIST} size={730} />
        <DivideLine style={{ margin: "40px 0" }} />

        {currentStep === 0 && <AirdropProjectPurposeSetting airdropData={airdropData} setCurrentStep={setCurrentStep} onClickTypeButton={onClickTypeButton} />}
        {currentStep === 1 && (
          <AirdropBasicInfoSetting
            onClickBackButton={onClickBackButton}
            airdropData={airdropData}
            setAirdropData={setData}
            setCurrentStep={setCurrentStep}
            marketingChannelList={marketingChannelList}
            setMarketingChannelList={setMarketingChannelList}
          />
        )}
        {currentStep === 2 && (
          <AirdropBudgetSetting
            onClickBackButton={onClickBackButton}
            airdropData={airdropData}
            setAirdropData={setData}
            allNetworkList={allNetworkList}
            allMicroChainList={allMicroChainList}
            setCurrentStep={setCurrentStep}
            selectedNetworkId={selectedNetworkId}
            setSelectedNetworkId={setSelectedNetworkId}
            selectedMicroChainId={selectedMicroChainId}
            setSelectedMicroChainId={setSelectedMicroChainId}
            selectedCurrencyId={selectedCurrencyId}
            setSelectedCurrencyId={setSelectedCurrencyId}
            availableMicroChainList={availableMicroChainList}
            setAvailableMicroChainList={setAvailableMicroChainList}
            availableCurrencyList={availableCurrencyList}
            setAvailableCurrencyList={setAvailableCurrencyList}
          />
        )}
        {currentStep === 3 && (
          <AirdropOperatingWaySetting
            onClickBackButton={onClickBackButton}
            onClickCreateButton={createAirdrop_}
            airdropData={airdropData}
            setAirdropData={setData}
            participationPeriodTypeList={participationPeriodTypeList}
            distributionTypeList={distributionTypeList}
            distributionAmountTypeList={distributionAmountTypeList}
            participantConditionTypeList={participantConditionTypeList}
            distributionAmountsData={distributionAmountsData}
            setDistributionAmountsData={setDistributionAmountsData}
            participationLimitData={participationLimitData}
            setParticipationLimitData={setParticipationLimitData}
            participationEventsData={participationEventsData}
            setParticipationEventsData={setParticipationEventsData}
          />
        )}
      </div>
    </PageArticle>
  );
};

export default CreateAirdrop;
