import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { removeTransactionResult, setLoadBalancer, setTransactionReceipt, transaction_result } from "../../store/redux/transaction/transactionSlice";
import { requestTransactionToExtension } from "./utils/messageUtils";
import ButtonLoading from "../../view/components/_atom/loading/ButtonLoading";
import { getTransactionReceiptAction } from "../../action/requestAction";
import { errorAlert } from "../../utils/Utils";
import BasicButton from "view/components/_atom/button/BasicButton";
import { ButtonSize } from "view/components/_atom/button/StyledButton";

// TODO :: UpgradeProxyContract / Gateway 확인 후 삭제 예정
const RequestTransactionButton = (props) => {
  const { type = "transaction", payload, text, onRequest, onSuccess, disabled = false, ...rest } = props || {};

  const dispatch = useDispatch();
  const { chainLoadBalancer, rawTransactionResult } = useSelector(transaction_result);
  const [isPolling, setIsPolling] = useState(false);
  const [pollingResult, setPollingResult] = useState(null);

  const request = async () => {
    const { error, result } = await onRequest();
    if (error) {
      switch (error.type) {
        case "API":
          errorAlert(error.data.message);
          break;
        case "Module":
          errorAlert(`${error.data.argument}::${error.data.code}`);
          break;
        default:
          errorAlert(error.data.message);
      }
    } else {
      const { loadBalancer } = result;
      dispatch(setLoadBalancer({ loadBalancer }));
      requestTransactionToExtension({
        ...result,
      });
    }
  };

  const onClickHandler = async () => {
    if (isPolling) {
      toast.success("Transaction Processing...");
    } else {
      if (!pollingResult) {
        await request();
      } else {
        if (pollingResult.status) {
          toast.success("Transaction success");
        } else {
          toast.error("Fail to get Transaction receipt");
        }
      }
    }
  };

  const buttonRenderer = useMemo(() => {
    return isPolling ? <ButtonLoading /> : text;
  }, [isPolling, text]);

  useEffect(() => {
    if (!pollingResult && Boolean(rawTransactionResult.tx_hash) && chainLoadBalancer && !disabled) {
      setIsPolling(true);
    }
  }, [rawTransactionResult.tx_hash, chainLoadBalancer, pollingResult, disabled]);

  useEffect(() => {
    let transactionPolling;
    if (isPolling) {
      transactionPolling = setTimeout(async () => {
        const { error, result } = await getTransactionReceiptAction(chainLoadBalancer, rawTransactionResult.tx_hash);
        if (error) {
          toast.error(error.data.message);
          setPollingResult(null);
          setIsPolling(false);
        } else {
          const { status, ...data } = result;
          setPollingResult({
            status,
            data,
          });
          setIsPolling(false);
          dispatch(
            setTransactionReceipt({
              status,
              data,
            })
          );
        }
      }, 200);
    }
    return () => clearTimeout(transactionPolling);
  }, [isPolling, rawTransactionResult, disabled]);

  useEffect(() => {
    if (onSuccess) {
      if (!isPolling && pollingResult) {
        if (pollingResult.status) {
          onSuccess();
        } else {
          toast.error("Fail to get Transaction receipt");
        }
      }
    }
  }, [isPolling, pollingResult]);

  useEffect(() => {
    return () => {
      setPollingResult(null);
      setIsPolling(false);
      setPollingResult(null);
      dispatch(removeTransactionResult());
    };
  }, []);

  return (
    <BasicButton size={ButtonSize.LARGE} disabled={disabled} onClick={() => onClickHandler()} {...rest}>
      {buttonRenderer}
    </BasicButton>
  );
};

export default RequestTransactionButton;
