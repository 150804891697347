import noDataInChart from "../assets/img_empty_chart.png";
import "./EmptyDataTemplate.scss";

function EmptyDataTemplate(props) {
  const { children } = props;
  return (
    <div className="empty-data-template">
      <div className="empty-data-template-wrapper">
        <img src={noDataInChart} alt="No Data" className="empty-data-template-image" />
        <div className="empty-data-template-text">{children}</div>
      </div>
    </div>
  );
}

export default EmptyDataTemplate;
