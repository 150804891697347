import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import ManageProject from "./page/ManageProject";
import CreateProject from "./page/CreateProject";
import JoinProject from "./page/JoinProject";
import { getInvitedProjectListAction } from "../../../../action/projectAction";
import { errorAlert } from "../../../../utils/Utils";
import PageLoading from "../../../components/_atom/loading/PageLoading";
import useLoading from "hooks/useLoading";

const InitialProjectRouter = () => {
  const [invitedList, setInvitedList] = useState<Array<any>>([]);

  const getInvitedProjectList_ = async () => {
    const { result, error } = await getInvitedProjectListAction();

    if (result) {
      setInvitedList(result);
    }

    if (error) {
      errorAlert(error.data.message);
    }
  };

  const { loading } = useLoading({
    dependency: [true],
    synchronousFunction: async () => {
      await getInvitedProjectList_();
    },
  });

  if (loading) {
    return <PageLoading />;
  }
  return (
    <Routes>
      <Route index element={<ManageProject invitedListCount={invitedList.length} />} />
      <Route path={"/create"} element={<CreateProject />} />
      <Route path={"/join"} element={<JoinProject invitedList={invitedList} />} />
    </Routes>
  );
};

export default InitialProjectRouter;
