import { useEffect, useState } from "react";

export default function useLoading({ dependency, synchronousFunction }: { dependency: any[]; synchronousFunction: Function }) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      synchronousFunction().finally(() => {
        setLoading(false);
      });
    }, 500);
  }, [...dependency]);
  return { loading };
}
