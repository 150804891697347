import {
  changeUserPasswordFromServer,
  checkEmailFromServer,
  checkPasswordFromServer,
  checkPasswordRecommendedDateFromServer,
  createTotpKeyFromServer,
  createUserFromServer,
  deleteTotpKeyFromServer,
  findUserPasswordFromServer,
  getBelongProjectsFromServer,
  getMyUserFromServer,
  getUserFromServer,
  getUsersInformationFromServer,
  loginFromServer,
  logoutFromServer,
  refreshTokenFromServer,
  sendAuthCodeEmailFromServer,
  setTotpKeyFromServer,
  updateUserFromServer,
  updateUserPasswordFromServer,
  verifyAuthCodeFromServer,
  verifyFindPasswordAuthCodeFromServer,
} from "../server/API/userAPI";
import { AuthCodeType, UserFromServer } from "../server/type/userType";
import { ProjectWithMember } from "../reducer/clientType/projectClientType";
import { MyInfo, TOTP, User, UserWithToken } from "../reducer/clientType/userClientType";
import { makeQRCode } from "utils/convert.utils";

// export const refreshTokenParser = async (): Promise<MyInfo> => {
//   const result = await refreshTokenFromServer();

//   return {
//     id: result.id,
//     firstName: result.first_name,
//     lastName: result.last_name,
//     email: result.email,
//     emailVerification: result.email_verification,
//     image: result.image,
//     region: result.region,
//     createdAt: result.created_at,
//     updatedAt: result.updated_at,
//     token: result.token,
//     ...(result.project_token && { projectToken: result.project_token }),
//   };
// };

export const createUserParser = async (firstName: string, lastName: string, birthday: string, email: string, password: string): Promise<UserWithToken> => {
  const result = await createUserFromServer({
    data: {
      firstName,
      lastName,
      birthday,
      email,
      password,
    },
  });
  return {
    id: result.id,
    firstName: result.first_name,
    lastName: result.last_name,
    email: result.email,
    emailVerification: Boolean(result.email_verification),
    image: result.image,
    region: result.region,
    createdAt: result.created_at,
    updatedAt: result.updated_at,
    token: result.token,
  };
};

export const checkEmailParser = async (email: string): Promise<boolean> => {
  const { status } = await checkEmailFromServer({
    data: {
      email,
    },
  });
  return status;
};

export const findUserPasswordParser = async (email: string): Promise<string> => {
  const { csrfToken } = await findUserPasswordFromServer({
    data: {
      email,
    },
  });
  return csrfToken;
};

export const verifyFindPasswordAuthCodeParser = async (authCode: string): Promise<string> => {
  const { csrfToken } = await verifyFindPasswordAuthCodeFromServer({
    data: {
      authCode,
    },
  });
  return csrfToken;
};

export const changeUserPasswordParser = async (authCode: string, newPassword: string): Promise<boolean> => {
  const { status } = await changeUserPasswordFromServer({
    data: {
      authCode,
      newPassword,
    },
  });
  return status;
};

export const updateUserPasswordParser = async (password: string, newPassword: string): Promise<boolean> => {
  const { status } = await updateUserPasswordFromServer({
    data: {
      password,
      newPassword,
    },
  });
  return status;
};

export const getUserParser = async (userId: number): Promise<User> => {
  const result = await getUserFromServer({
    params: { userId },
  });
  return {
    id: result.id,
    firstName: result.first_name,
    lastName: result.last_name,
    email: result.email,
    emailVerification: Boolean(result.email_verification),
    image: result.image,
    region: result.region,
    createdAt: result.created_at,
    updatedAt: result.updated_at,
  };
};

export const getUsersInformationParser = async (userIds: Array<number>): Promise<User[]> => {
  const result = await getUsersInformationFromServer({
    query: { userIds },
  });

  const response = result.map((user: UserFromServer) => ({
    id: user.id,
    firstName: user.first_name,
    lastName: user.last_name,
    email: user.email,
    image: user.image,
    emailVerification: Boolean(user.email_verification),
    region: user.region,
    createdAt: user.created_at,
    updatedAt: user.updated_at,
  }));

  return response;
};

export const getMyUserParser = async (): Promise<MyInfo> => {
  const result = await getMyUserFromServer();
  return {
    id: result.id,
    firstName: result.first_name,
    lastName: result.last_name,
    email: result.email,
    emailVerification: Boolean(result.email_verification),
    image: result.image,
    region: result.region,
    createdAt: result.created_at,
    updatedAt: result.updated_at,
    token: result.token,
    projectToken: result.project_token,
    lastPasswordChangedAt: result.last_password_changed_at,
    changePasswordRecommendedDate: result.change_password_recommended_date,
    isMFASet: Boolean(result.is_mfa_set),
  };
};

export const updateUserParser = async (firstName: string, lastName: string): Promise<User> => {
  const result = await updateUserFromServer({
    data: {
      firstName,
      lastName,
    },
  });
  return {
    id: result.id,
    firstName: result.first_name,
    lastName: result.last_name,
    email: result.email,
    emailVerification: Boolean(result.email_verification),
    image: result.image,
    region: result.region,
    createdAt: result.created_at,
    updatedAt: result.updated_at,
  };
};

export const getBelongProjectsParser = async (accessToken?: string): Promise<ProjectWithMember[]> => {
  const result = await getBelongProjectsFromServer({
    ...(accessToken && {
      config: {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    }),
  });
  return result.map((project) => ({
    projectMemberId: project.project_member_id,
    projectId: project.project_id,
    userId: project.user_id,
    projectRoleId: project.project_role_id,
    createdAt: project.created_at,
    project: {
      id: project.project.id,
      creatorId: project.project.creator_id,
      name: project.project.name,
      description: project.project.description,
      image: project.project.image,
      pricingPlanId: project.project.pricing_plan_id,
      createdAt: project.project.created_at,
    },
  }));
};

export const sendAuthCodeEmailParser = async (userId: number, email: string, type: AuthCodeType): Promise<boolean> => {
  const { status } = await sendAuthCodeEmailFromServer({
    params: { userId },
    data: {
      email,
      type,
    },
  });
  return status;
};

export const verifyAuthCodeParser = async (authCode: string): Promise<boolean> => {
  const { status } = await verifyAuthCodeFromServer({
    data: {
      authCode,
    },
  });
  return status;
};

// export const loginParser = async (email: string, password: string, options: { totpCode?: string }): Promise<MyInfo> => {
//   const result = await loginFromServer({
//     data: {
//       email,
//       password,
//       ...(options?.totpCode && { totpCode: options.totpCode }),
//     },
//   });
//   return {
//     id: result.id,
//     firstName: result.first_name,
//     lastName: result.last_name,
//     email: result.email,
//     emailVerification: result.email_verification,
//     image: result.image,
//     region: result.region,
//     createdAt: result.created_at,
//     updatedAt: result.updated_at,
//     token: result.token,
//     projectToken: result.project_token,
//     lastPasswordChangedAt: result.last_password_changed_at,
//     changePasswordRecommendedDate: result.change_password_recommended_date,
//     totpKey: result.totp_key,
//   };
// };

// export const logoutParser = async (): Promise<boolean> => {
//   const { status } = await logoutFromServer();
//   return status;
// };

export const checkPasswordParser = async (newPassword: string): Promise<boolean> => {
  const result = await checkPasswordFromServer({
    data: {
      newPassword,
    },
  });

  return result.status;
};

export const checkPasswordRecommendedDateParser = async (): Promise<boolean> => {
  const result = await checkPasswordRecommendedDateFromServer();

  return result.status;
};

export const createTotpKeyParser = async (userId: number): Promise<TOTP> => {
  const result = await createTotpKeyFromServer({
    params: {
      userId,
    },
  });

  const { totp_key, qr } = result;

  const qrURL = await makeQRCode(`otpauth://totp/${qr.provider}:${qr.email}?secret=${totp_key}&issuer=${qr.issuer}`);

  const response = {
    totpKey: totp_key,
    qrURL,
  };

  return response;
};

export const setTotpKeyParser = async (userId: number, totpCode: string, totpKey: string): Promise<MyInfo> => {
  const result = await setTotpKeyFromServer({
    params: {
      userId,
    },
    data: {
      totpCode,
      totpKey,
    },
  });

  const { totp_key, user } = result;

  const resposne = {
    id: user.id,
    firstName: user.first_name,
    lastName: user.last_name,
    email: user.email,
    emailVerification: Boolean(user.email_verification),
    image: user.image,
    region: user.region,
    createdAt: user.created_at,
    updatedAt: user.updated_at,
    token: user.token,
    projectToken: user.project_token,
    lastPasswordChangedAt: user.last_password_changed_at,
    changePasswordRecommendedDate: user.change_password_recommended_date,
    isMFASet: Boolean(user.is_mfa_set),
  };

  return resposne;
};

export const deleteTotpKeyParser = async (userId: number): Promise<any> => {
  const result = await deleteTotpKeyFromServer({
    params: {
      userId,
    },
  });

  const resposne = {
    id: result.id,
    firstName: result.first_name,
    lastName: result.last_name,
    email: result.email,
    emailVerification: Boolean(result.email_verification),
    image: result.image,
    region: result.region,
    createdAt: result.created_at,
    updatedAt: result.updated_at,
    token: result.token,
    projectToken: result.project_token,
    lastPasswordChangedAt: result.last_password_changed_at,
    changePasswordRecommendedDate: result.change_password_recommended_date,
    isMFASet: Boolean(result.is_mfa_set),
  };

  return resposne;
};
