import React from 'react';
import './KitContainer.scss';

type KitContainerProps = {
  title: string;
  description?: string;
  children?: React.ReactNode;
};
const KitContainer = (props: KitContainerProps) => {
  const { title, description, children } = props;
  return (
    <div className="kit-container">
      <div>
        <div className="kit-container-title">{title}</div>
        {description && <div className="kit-container-description">{description}</div>}
      </div>
      {children}
    </div>
  );
};

export default KitContainer;
