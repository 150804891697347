import { makeQuery } from "../index/utils";
import { v2ServerByProjectAuth } from "../index/serverInstances";

//사용처 없음
// ----------------------------------
const getWallet20s = async (
  request: getWallet20sRequest
): Promise<getWallet20sResponse> =>
  await v2ServerByProjectAuth.get({
    path: "wallet-20/tokens",
    query: makeQuery(request.query),
  });

type getWallet20sRequest = {
  query: {
    limit?: number;
    lastId?: number;
    contractIds?: number[];
  };
};

type getWallet20sResponse = {
  contract_id: number;
  decimals: number;
  token_20_id: number;
  total_amount: string;
}[];
// ----------------------------------

export { getWallet20s as getWallet20sFromServer };
