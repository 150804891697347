import React from "react";
import BasicButton from "view/components/_atom/button/BasicButton";
import ButtonContainer from "view/components/_atom/container/ButtonContainer";
import { ButtonSize, ButtonTheme } from "view/components/_atom/button/StyledButton";
import NetworkEngineField from "../molecule/NetworkEngineField";
import useIsAble from "hooks/useIsAble";
import useValidateCondition from "hooks/useValidateCondition";
import { VALIDATION__NETWORK_algirothm } from "../../validation/networkValidation";

type NetworkBasicInfoSettingProps = {
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  networkData: any;
  setNetworkData: React.Dispatch<React.SetStateAction<any>>;
  blockchainEngineList: any;
  setNetworkType: React.Dispatch<React.SetStateAction<any>>;
};

const NetworkEngineSetting = (props: NetworkBasicInfoSettingProps) => {
  const { setCurrentStep, networkData, setNetworkData, blockchainEngineList, setNetworkType } = props;

  const backButtonHandler = () => {
    setCurrentStep((prevState) => prevState - 1);
  };

  const nextButtonHandler = () => {
    setCurrentStep((prevState) => prevState + 1);
  };

  const algorithmValidation = useValidateCondition([networkData.microChain.algorithmId], VALIDATION__NETWORK_algirothm(networkData.microChain.algorithmId));

  const isAble: boolean = useIsAble([algorithmValidation.isValid]);

  return (
    <div className="network-engine-setting">
      <NetworkEngineField
        networkData={networkData}
        setNetworkData={setNetworkData}
        setNetworkType={setNetworkType}
        blockchainEngineList={blockchainEngineList}
      />
      <div className="network-button-container">
        <ButtonContainer>
          <BasicButton size={ButtonSize.LARGE} theme={ButtonTheme.SECONDARY} onClick={() => backButtonHandler()}>
            Back
          </BasicButton>
          <BasicButton size={ButtonSize.LARGE} disabled={!isAble} onClick={() => nextButtonHandler()}>
            Next
          </BasicButton>
        </ButtonContainer>
      </div>
    </div>
  );
};

export default NetworkEngineSetting;
