import React, { useEffect, useState } from "react";
import ButtonContainer from "view/components/_atom/container/ButtonContainer";
import BasicButton from "view/components/_atom/button/BasicButton";
import { ButtonSize, ButtonTheme } from "view/components/_atom/button/StyledButton";
import useIsAble from "hooks/useIsAble";
import useValidateCondition from "hooks/useValidateCondition";
import {
  VALIDATION__PAYMENT_GATEWAY_deposit_redirect_uri,
  VALIDATION__PAYMENT_GATEWAY_name,
  VALIDATION__PAYMENT_GATEWAY_wallet_address,
  VALIDATION__PAYMENT_GATEWAY_withdrawal_redirect_uri,
} from "../validation/paymentGatewayValidation";
import { useNavigate } from "react-router-dom";
import { COIN_CA, VIRTUAL_ACCOUNT_PAYMENT_GATEWAY_DATA_FORMAT } from "../constants/paymentGateway.data";
import CommentInput from "view/components/_atom/input/CommentInput";
import Select from "view/components/_atom/select/Select";
import { useSelector } from "react-redux";
import { parseAllPaymentTypeForRadioSelect } from "reducer/presetReducer";
import RadioButton from "legacy-common/atom/button/RadioButton";
import {
  createPaymentMethodAction,
  getCurrenciesBySelectedMicroChainAction,
  getDataForCreateVirtualAccountPaymentMethodAction,
} from "action/paymentGatewayAction";
import toast from "react-hot-toast";
import { errorAlert } from "utils/Utils";
import useLoading from "hooks/useLoading";
import PageLoading from "view/components/_atom/loading/PageLoading";
import useGetSelectedProject from "hooks/useGetSelectedProject";
import { FieldContainer, FieldLabel, RowField } from "view/components/_atom/section/Field";
import pgIcon from "../../../../assets/images/service/Icon_SNB_payment_gateway.png";

const PaymentGatewaySettingForVA = (props) => {
  const { setCurrentStep, paymentGatewayType } = props;
  const { selectedProjectID } = useGetSelectedProject();

  const [microChains, setMicroChains] = useState([]);
  const [currencies, setCurrencies] = useState([]);

  const [paymentGatewayData, setPaymentGatewayData] = useState(VIRTUAL_ACCOUNT_PAYMENT_GATEWAY_DATA_FORMAT);
  const navigate = useNavigate();

  const setName = (name) => {
    setPaymentGatewayData((prevState) => {
      return {
        ...prevState,
        name,
      };
    });
  };

  const setType = (type) => {
    setPaymentGatewayData((prevState) => {
      return {
        ...prevState,
        paymentTypeIds: [type],
      };
    });
  };

  const setMicrochainId = (id) => {
    setPaymentGatewayData((prevState) => {
      return {
        ...prevState,
        microChainId: id,
      };
    });
  };

  const setMicrochainCurrencyId = (id) => {
    setPaymentGatewayData((prevState) => {
      return {
        ...prevState,
        microChainCurrencyId: id,
      };
    });
  };

  const setWalletAddress = (address) => {
    setPaymentGatewayData((prevState) => {
      return {
        ...prevState,
        walletAddress: address,
      };
    });
  };

  const setDepositRedirectURI = (uri) => {
    setPaymentGatewayData((prevState) => {
      return {
        ...prevState,
        depositCallbackURI: uri,
      };
    });
  };

  const setWithdrawalRedirectURI = (uri) => {
    setPaymentGatewayData((prevState) => {
      return {
        ...prevState,
        withdrawalCallbackURI: uri,
      };
    });
  };

  const getInitialDataForCreatePaymentGateway_ = async () => {
    const { error, result } = await getDataForCreateVirtualAccountPaymentMethodAction();
    if (result) {
      setMicroChains(result);
    }
    if (error) {
      errorAlert(error.data.message);
    }
  };

  const nameValidation = useValidateCondition([paymentGatewayData.name], VALIDATION__PAYMENT_GATEWAY_name(paymentGatewayData.name));
  const walletAddressValidation = useValidateCondition(
    [paymentGatewayData.walletAddress],
    VALIDATION__PAYMENT_GATEWAY_wallet_address(paymentGatewayData.walletAddress)
  );
  const depositCallbackURIValidation = useValidateCondition(
    [paymentGatewayData.depositCallbackURI],
    VALIDATION__PAYMENT_GATEWAY_deposit_redirect_uri(paymentGatewayData.depositCallbackURI)
  );
  const withdrawalCallbackURIValidation = useValidateCondition(
    [paymentGatewayData.withdrawalCallbackURI],
    VALIDATION__PAYMENT_GATEWAY_withdrawal_redirect_uri(paymentGatewayData.withdrawalCallbackURI)
  );

  const isAble = useIsAble([
    nameValidation.isValid,
    Boolean(paymentGatewayData.microChainId),
    Boolean(paymentGatewayData.microChainCurrencyId),
    paymentGatewayData.paymentTypeIds.length > 0,
    walletAddressValidation.isValid,
    depositCallbackURIValidation.isValid,
    withdrawalCallbackURIValidation.isValid,
  ]);

  const getCurrencyByMicroChainId = async () => {
    const { result, error } = await getCurrenciesBySelectedMicroChainAction(paymentGatewayData.microChainId);

    if (result) {
      setCurrencies(result.filter((currency) => currency.contractAddress === COIN_CA));
    }
    if (error) {
      errorAlert(error.data.message);
    }
  };

  useEffect(() => {
    if (paymentGatewayData.microChainId) {
      setMicrochainCurrencyId(null);
      getCurrencyByMicroChainId();
    } else {
      setMicrochainCurrencyId(null);
      setCurrencies([]);
    }
  }, [paymentGatewayData.microChainId]);

  const createPaymentGateway_ = async () => {
    const { result, error } = await createPaymentMethodAction(
      paymentGatewayData.name,
      paymentGatewayData.microChainId,
      paymentGatewayData.microChainCurrencyId,
      paymentGatewayData.paymentTypeIds,
      paymentGatewayData.walletAddress,
      paymentGatewayData.depositCallbackURI,
      paymentGatewayData.withdrawalCallbackURI
    );

    if (result) {
      toast.success("Create successfully");
      navigate(`/developer/payment-gateway/manage/${result.paymentMethodId}`);
    }
    if (error) {
      errorAlert(error.data.message);
    }
  };

  const { loading } = useLoading({
    dependency: [selectedProjectID],
    synchronousFunction: async () => {
      await getInitialDataForCreatePaymentGateway_();
    },
  });

  if (loading) {
    return <PageLoading />;
  }

  return (
    <>
      {/* Basic Info Description :: Set the basic information for the payment gateway you want to create. */}
      <FieldContainer icon={pgIcon} title="Basic Info">
        <RowField>
          <FieldLabel label="Name" />
          <CommentInput
            {...nameValidation}
            placeholder={"Enter payment gateway name"}
            value={paymentGatewayData.name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </RowField>
        <RowField>
          <FieldLabel label="Network" />
          <Select
            labelKey={"label"}
            valueKey={"id"}
            options={microChains}
            selectedValue={paymentGatewayData.microChainId}
            setSelectedValue={setMicrochainId}
            canSelect={microChains.length !== 0}
            placeholder={"Select Network"}
          />
        </RowField>
        <RowField>
          <FieldLabel label="Currency" />
          <Select
            labelKey={"symbol"}
            valueKey={"microChainCurrencyId"}
            options={currencies}
            selectedValue={paymentGatewayData.microChainCurrencyId}
            setSelectedValue={setMicrochainCurrencyId}
            canSelect={paymentGatewayData.microChainId}
            placeholder={"Select Currency"}
          />
        </RowField>
        <RowField>
          <FieldLabel label="Type" />
          <PaymentGatewayTypeSelector paymentGatewayType={paymentGatewayType} selectedPaymentTypeId={paymentGatewayData.paymentTypeIds[0]} setType={setType} />
        </RowField>
        <RowField>
          <FieldLabel label="Wallet address" />
          <CommentInput
            {...walletAddressValidation}
            value={paymentGatewayData.walletAddress}
            onChange={(e) => {
              setWalletAddress(e.target.value);
            }}
          />
        </RowField>
        <RowField>
          <FieldLabel label="Deposit redirect URI" />
          <CommentInput
            value={paymentGatewayData.depositCallbackURI}
            onChange={(e) => {
              setDepositRedirectURI(e.target.value);
            }}
          />
        </RowField>
        <RowField>
          <FieldLabel label="Withdrawal redirect URI" />
          <CommentInput
            value={paymentGatewayData.withdrawalCallbackURI}
            onChange={(e) => {
              setWithdrawalRedirectURI(e.target.value);
            }}
          />
        </RowField>
      </FieldContainer>
      <div className="flex justify-content-flex-end">
        <ButtonContainer>
          <BasicButton size={ButtonSize.LARGE} theme={ButtonTheme.SECONDARY} onClick={() => setCurrentStep((prev) => prev - 1)}>
            Back
          </BasicButton>
          <BasicButton size={ButtonSize.LARGE} disabled={!isAble} onClick={() => createPaymentGateway_()}>
            Create
          </BasicButton>
        </ButtonContainer>
      </div>
    </>
  );
};

export default PaymentGatewaySettingForVA;

const PaymentGatewayTypeSelector = (props) => {
  const { paymentGatewayType, selectedPaymentTypeId, setType } = props;
  const paymentType = useSelector(parseAllPaymentTypeForRadioSelect).filter((el) => el.methodType === paymentGatewayType);

  return (
    <div className="payment-gateway-type-selector" style={{ display: "flex", gap: 20 }}>
      {paymentType.map((type) => (
        <RadioButton
          key={`payment-gateway-type-${type.paymentTypeId}`}
          id={`payment-gateway-type-${type.paymentTypeId}`}
          label={type.name}
          value={type.paymentTypeId}
          name={"payment-gateway-type"}
          onChange={(e) => setType(Number(e.target.value))}
          checked={selectedPaymentTypeId === type.paymentTypeId}
        />
      ))}
    </div>
  );
};
