import { v2ServerByProjectAuth } from "server/index/serverInstances";
import { makeQuery } from "server/index/utils";
import { getCurrencyRequest, getCurrencyResponse } from "./index.type";

const getCurrency = async (
  request: getCurrencyRequest
): Promise<getCurrencyResponse> =>
  await v2ServerByProjectAuth.get({
    path: "/currency",
    query: makeQuery(request.query),
  });

export { getCurrency as getCurrencyFromServer };
