import IconTokenList from '../../../../assets/images/atom/icon_token_list.png'
import {ServiceStatus, statusType} from "../../../../../utils/status.utils";
import IconFailTransaction from "../../../../assets/images/Icon_fail_transaction.png";
import IconProcessingTransaction from "../../../../assets/images/Icon_processing_transaction.png";

export const MINTABLE = [
  {
    id: 'mintable-yes',
    value: 1,
    label: 'Yes'
  },
  {
    id: 'mintable-no',
    value: 0,
    label: 'No'
  },
]
export const BURNABLE = [
  {
    id: 'burnable-yes',
    value: 1,
    label: 'Yes'
  },
  {
    id: 'burnable-no',
    value: 0,
    label: 'No'
  },
]
export const LOCKABLE = [
  {
    id: 'lockable-yes',
    value: 1,
    label: 'Yes'
  },
  {
    id: 'lockable-no',
    value: 0,
    label: 'No'
  },
]

export const MANAGE_TOKEN_INDEX = {
  icon: IconTokenList,
  text: 'Issued'
}

export const TOKEN_STATUS: Record<string, ServiceStatus> = {
  TOKEN_CREATED          : {
    mainStatus: 1,
    subStatus : 0,
  },
  TOKEN_PREPARED: {
    mainStatus  : 3,
    subStatus   : 0
  },
  TOKEN_FAILED_REORGANIZE: {
    mainStatus  : 3,
    subStatus   : 1,
    statusDetail: {
      type               : statusType.REJECT,
      title              : 'The deployment of the smart contract was not completed successfully. Please try again.',
      description        : {
        text : 'Reason: Reorganization of blockchain network',
        color: '#FF1717'
      },
      showTransactionHash: false
    }
  },
  TOKEN_PROCESSING       : {
    mainStatus  : 4,
    subStatus   : 0,
    statusDetail: {
      type               : statusType.WAIT,
      title              : 'Smart contract is being deployed.',
      showTransactionHash: true
    }
  },
  TOKEN_DEPLOYED         : {
    mainStatus: 5,
    subStatus : 0
  },
}

