import React, { useEffect, useState } from "react";
import css from "./DashboardAPICardList.scss";
import IconCopyDefault from "../../../assets/images/icon_copy_default.png";
import IconCopyHover from "../../../assets/images/icon_copy_hover.png";
import { bindStyle } from "view/styles/classNames";

type Card = {
  label: string;
  InfoRenderer?: JSX.Element | undefined;
  icon?: string;
  value: string;
};

interface DashboardAPICardListProps {
  data: Array<Card>;
}

function DashboardAPICardList(props: DashboardAPICardListProps) {
  const { data } = props;

  const [hoverdCopyIconIndex, setHoverdCopyIconIndex] = useState(0);

  return (
    <div className="dashboard-api-card-list">
      {data.map((card, index) => {
        return (
          <DashboardAPICard
            key={`${card.label}`}
            card={card}
            index={index}
            hoverdCopyIconIndex={hoverdCopyIconIndex}
            setHoverdCopyIconIndex={setHoverdCopyIconIndex}
          />
        );
      })}
    </div>
  );
}

const DashboardAPICard = (props) => {
  const { card, index, hoverdCopyIconIndex, setHoverdCopyIconIndex } = props;

  const cn = bindStyle(css);

  const [clicked, setClicked] = useState<boolean>(false);

  const onClickCopy = (text: string) => {
    setClicked(true);
    window.navigator.clipboard.writeText(text);
  };

  useEffect(() => {
    if (clicked) {
      setTimeout(() => {
        setClicked(false);
      }, 1000);
    }
  }, [clicked]);

  return (
    <div className="dashboard-api-card-list-item">
      <div className="dashboard-api-card-list-item-label-wrapper">
        {card.icon && <img className="dashboard-api-card-list-item-icon" src={card.icon} alt="icon" />}
        <div className="dashboard-api-card-list-item-label">{card.label}</div>
        {card.InfoRenderer && <div className="dashboard-api-card-list-item-info">{card.InfoRenderer}</div>}
      </div>
      <div className="dashboard-api-copy-label-with-icon">
        {card.value ? (
          <>
            <span className="dashboard-api-copy-label" data-legend-key={card.value}>
              {card.value}
            </span>
            <div
              className={cn("dashboard-api-copy-icon-wrapper", { clicked })}
              onMouseOver={() => setHoverdCopyIconIndex(index + 1)}
              onMouseLeave={() => setHoverdCopyIconIndex(0)}
            >
              <img
                className="dashboard-api-copy-icon"
                src={hoverdCopyIconIndex === index + 1 ? IconCopyHover : IconCopyDefault}
                alt="copy"
                onClick={() => onClickCopy(card.value)}
              />
            </div>
          </>
        ) : (
          <span>-</span>
        )}
      </div>
    </div>
  );
};

export default DashboardAPICardList;
