import {parseAction} from "./util/parseAction";
import {AbiItem} from "web3-utils";
import {isProxyContract, removeEmptyElement} from "../view/service/developer/contract/utils/contractUtils";
import {encodeABI} from "../modules/web3/Web3Utils";
import {
  getABICodeByContractAddressParser,
  getContractByContractAddressParser, getContractConstructorByContractIdParser,
  getContractToListParser,
  getListedContractDetailParser,
  getMyListedContractParser,
} from "../parser/contractParser";
import {getExternalAndInnerMicroChainListParser} from "../parser/microChainParser";
import {
  getABICodeByFileURLParser,
  getContractVersionDetailParser,
  getContractVersionsParser
} from "../parser/contractLibraryParser";
import {LibraryContract, LibraryContractVersionDetail, ListedContract} from "../reducer/clientType/contractClientType";
import {getProjectsParser} from "../parser/projectParser";

//Proxy type의 컨트랙트를 Get 하기 위한 Action
export const getProxyContractToListAction = (
  microChainId: number,
  name: string,
  description: string,
  contractId: number,
  contractVersionId: number,
  implementationAddress: string,
  initializeValue: Array<any>,
  abiCode: AbiItem | AbiItem[]
) =>
  parseAction(async () => {
    //파라미터로 전달받은 initializeValue는 initialize 함수의 input parameter로 입력된 값을 의미합니다.
    //입력된 인자의 type이 string일 경우 trim 함수를 실행하고,
    //type이 array일 경우 비어있는 요소를 제거합니다.
    // ["", "   ", "1234"] => ["1234"]
    const initializeArguments = removeEmptyElement(initializeValue);
    //ABI 를 이용하여 인자를 encode 합니다.
    const data = encodeABI(abiCode, "initialize", initializeArguments);
    const implementationContract = await getContractByContractAddressParser(implementationAddress, microChainId);
    return await getContractToListParser(
      microChainId,
      name,
      description,
      contractId,
      contractVersionId,
      [implementationAddress, data],
      implementationContract.contractId
    );
  });

//Fixed, implementation type의 컨트랙트를 Get 하기 위한 Action
export const getFixedContractToListAction = (
  microChainId: number,
  name: string,
  description: string,
  contractId: number,
  contractVersionId: number,
  contractConstructorInputs: Array<any>
) =>
  parseAction(async () => {
    return await getContractToListParser(microChainId, name, description, contractId, contractVersionId, contractConstructorInputs);
  });

//Get 한 컨트랙트 목록을 조회하기 위한 Action
export const getListedContractsAction = (mainStatus: number[]) =>
  parseAction(async () => {
    const contracts = await getMyListedContractParser({mainStatus, limit: 500});
    const microChains = await getExternalAndInnerMicroChainListParser({mainStatus: [5]});
    //microChain 정보가 유효한 컨트랙트 객체를 필터링합니다.
    return contracts
      .filter((contract) => Boolean(microChains.find((microChain) => microChain.id === contract.microChainId)))
      .map((contract) => {
        return {
          ...contract,
          microChain: microChains.find((microChain) => microChain.id === contract.microChainId) ?? null,
        };
      });
  });

// Get 한 컨트랙트의 상세정보를 조회하기 위한 Action
export const getListedContractDetailAction = (contractId: number) =>
  parseAction(async () => {
    // 필요한 데이터를 모두 조회합니다.
    const [contract, microChains] = await Promise.all([
      getListedContractDetailParser(contractId),
      getExternalAndInnerMicroChainListParser({mainStatus: [5]}),
    ]);

    let implementationContract: ListedContract | null = null;
    // 만일 상세정보를 조회하려는 컨트랙트가 Proxy 컨트랙트 일 경우, implementation contract 정보도 함께 조회합니다.
    if(isProxyContract(contract) && contract.implementationContractId) {
      implementationContract = await getListedContractDetailParser(contract.implementationContractId);
    }

    // 컨트랙트 라이브러리 DB에 저장되어있는 컨트랙트 상세정보와 해당 버전 상세정보를 조회합니다.
    const [originContractDetail, originContractVersionDetail]: [LibraryContract, LibraryContractVersionDetail] = await Promise.all([
      getContractVersionsParser(contract.originContractId),
      getContractVersionDetailParser(contract.originContractId, contract.originContractVersionId),
    ]);

    const constructor = await getContractConstructorByContractIdParser(contractId)
    // const constructor = {
    //   "inputs"         : [
    //     {
    //       "internalType": "address payable",
    //       "name"        : "owner_",
    //       "type"        : "address",
    //       "value"       : "0x12345678987654321234567898765432123456789",
    //     },
    //     {
    //       "internalType": "address",
    //       "name"        : "token_",
    //       "type"        : "address",
    //       "value"       : "0x00000000000000000000000000000000000000001",
    //     }
    //   ],
    //   "stateMutability": "nonpayable",
    //   "type"           : "constructor"
    // }

    const {contractVersionCode} = originContractVersionDetail
    const customAbiCode = await getABICodeByFileURLParser(contractVersionCode.customAbiCode);

    // 네트워크 목록 정보를 중복 없이 파싱합니다.
    const networks = microChains.reduce((acc, cur) => {
      if(!Boolean(acc.find((net) => net.id === cur.networkId))) {
        return acc.concat(cur.network);
      } else return acc;
    }, []);

    const owner = await getProjectsParser({projectIds: [originContractDetail.projectId]});

    const selectedMicroChain = microChains.find((mc) => mc.id === contract.microChainId);

    // 상단에서 조회한 정보를 종합하여 return 합니다.
    return {
      contractDetail: {
        ...contract,
        owner          : owner[0].name,
        microChainLabel: selectedMicroChain.label ?? "",
        networkLabel   : networks.find((net) => net.id === selectedMicroChain.networkId).label ?? "-",
        ...(implementationContract && {implementationContractAddress: implementationContract.contractAddress}),
        originContractTitle             : originContractDetail.title,
        originContractDescription       : originContractDetail.description ?? "No contract description",
        originContractVersionDescription: originContractVersionDetail.versionDescription ?? "No version description",
      },
      //TODO: constructor 정보는 따로 조회
      contractConstructor: constructor,
      contractFunctions  : customAbiCode.functions,
      contractEvents     : customAbiCode.events,
    };
  });

// Proxy contract를 Get 하기 위해 implementation contract의 ABI code 정보를 조회하는 Action
export const getAbiCodeForInitializeAction = (contractAddress: string, microChainId: number) =>
  parseAction(async () => {
    return await getABICodeByContractAddressParser(contractAddress, microChainId);
  });

// Proxy contract를 업그레이드 하기 위해 상세정보를 조회하는 Action
export const getProxyContractDetailForUpgradeAction = (contractId: number) =>
  parseAction(async () => {
    const contract = await getListedContractDetailParser(contractId);
    let implementationContract: ListedContract | null = null;
    if(contract.implementationContractId) {
      implementationContract = await getListedContractDetailParser(contract.implementationContractId);
    }
    return {
      ...contract,
      ...(implementationContract && {implementationContractAddress: implementationContract.contractAddress}),
    };
  });

// Proxy contract를 업그레이드 하기 위해 연결한 implementation contract의 ABI code를 조회하는 Action
export const getAbiCodeForUpgradeContractAction = (contractAddress: string, microChainId: number) =>
  parseAction(async () => {
    const abiCode = (await getABICodeByContractAddressParser(contractAddress, microChainId)) as AbiItem[];
    return {
      abiCode,
      functions: abiCode.filter((method) => method.type === "function"),
    };
  });
