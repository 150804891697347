export const TEST_NETWORK_NODE_SPEC = 6;
export const TEST_NETWORK_NODE_COUNT = 5;
export const TEST_NETWORK_LIGHT_NODE_COUNT = 1;

export const DEFAULT_NETWORK_LIGHT_NODE_COUNT = 2;

export const ERROR_DUPLICATE_NETWORK_NAME_IN_CREATE = 1;
export const ERROR_DUPLICATE_COIN_NAME_IN_CREATE = 2;
export const ERROR_DUPLICATE_COIN_SYMBOL_IN_CREATE = 3;

export const ERROR_DUPLICATE_NETWORK_NAME_IN_EDIT = 4;
export const ERROR_DUPLICATE_COIN_NAME_IN_EDIT = 5;
export const ERROR_DUPLICATE_COIN_SYMBOL_IN_EDIT = 6;

// 네트워크 블록체인 엔진 세팅 값
export const NETWORK_ENGINE_TYPE_EQUILIBRIUM__1_1_6__PON = 111;
export const NETWORK_ENGINE_TYPE_ETHEREUM__1_1_6__CLIQUE = 222;

// Network 생성/수정 과정의 단계
export const NETWORK_STEP_LIST = [
  {
    step: 1,
    label: "Basic Info",
  },
  {
    step: 2,
    label: "Blockchain Engine",
  },
  {
    step: 3,
    label: "Node",
  },
  {
    step: 4,
    label: "Additionals",
  },
];

export const NETWORK_ANNOUNCEMENT_SYMBOL =
  "‘Coin symbol’ is a shorthand representation of a particular coin. It's similar to how stocks have ticker symbols in the stock market. Coin symbol is typically composed of capital letters and are used for brevity and clarity.";
export const NETWORK_ANNOUNCEMENT_INITIAL_SUPPLY = "The number of coins issued initially as this network is deployed.";
export const NETWORK_ANNOUNCEMENT_ENVIRONMENT =
  "This is a term that refers to the blockchain environment. In the case of 'Mainnet', it is a blockchain network environment corresponding to the production environment, and is an environment where actual services are operated. In the case of 'Testnet', it is a blockchain network environment corresponding to a staging or development environment and is operated for testing purposes.";
export const NETWORK_ANNOUNCEMENT_ACCESSIBILITY =
  "This is a term that refers to the accessibility of blockchain. In the case of 'Public', not only users of the EQ Hub project that is building this network can use it, but all users of the EQ Hub project can use it. In the case of 'Private', only users of the EQ Hub project that is building this network can use it.";
export const NETWORK_ANNOUNCEMENT_BLOCKCHAIN_CLIENT =
  "A protocol is a set of rules and standards that software follows to communicate with each other. In the context of blockchain, the protocol defines the rules of the network: how transactions are verified, how consensus is achieved, how data is stored, etc. A blockchain client is a software application that implements and runs the blockchain protocol, enabling users to participate in the blockchain network. There can be multiple clients for a single blockchain, as long as they all implement the same protocol.";
export const NETWORK_ANNOUNCEMENT_CONSENSUS_ALGORITHM =
  "A consensus algorithm in blockchain is a method used to achieve agreement on the validity of transactions among the network nodes. It ensures that all nodes have a consistent view of the ledger, even in the presence of malicious actors or failures.";
export const NETWORK_ANNOUNCEMENT_ESTIMATED_COST = "In the process of actual use, the amount charged may change depending on the period of use.";
export const NETWORK_ANNOUNCEMENT_GAS_FEE = `This is the fee paid by blockchain network users when they make a transaction, and is always a fixed value.\n(This data is set only in Equilibrium)`;
export const NETWORK_ANNOUNCEMENT_INFLATION_RATE = `The ‘Inflation rate' value determines the amount of coins to be paid as a reward to nodes participating in the consensus. For the total amount of coins issued, a value of the Inflation rate is distributed to nodes over a year.\n(This data is set only in Equilibrium)`;
export const NETWORK_ANNOUNCEMENT_DISINFLATION_RATE = `‘Disinflation rate' means the reduction rate of ‘Inflation rate’. On a yearly basis, the value of ‘Inflation rate' is reduced by the v alue of 'Disinflation rate’.\n(This data is set only in Equilibrium)`;
export const NETWORK_ANNOUNCEMENT_ALLOCATED_AMOUNT = "It means the ratio and total amount of coins allocated to wallet accounts and nodes so far.";
