import React, { useState } from "react";
import "./RemoveMemberModal.scss";
import BasicButton from "../../../components/_atom/button/BasicButton";
import ModalTemplateB from "view/components/_template/modal/ModalTemplateB";
import ButtonContainer from "../../../components/_atom/container/ButtonContainer";
import { ButtonSize, ButtonTheme } from "view/components/_atom/button/StyledButton";
import CommentInput from "../../../components/_atom/input/CommentInput";
import useIsAble from "../../../../hooks/useIsAble";
import useValidateCondition from "../../../../hooks/useValidateCondition";
import { removeMemberAction } from "../../../../action/projectAction";
import { errorAlert } from "utils/Utils";
import { toast } from "react-hot-toast";
import { User } from "reducer/clientType/userClientType";
import { ProjectMember } from "reducer/clientType/projectClientType";
import { VALIDATION__PROJECT_remove_member_email } from "view/service/dashboard/validation/projectValidation";

type RemoveMemberModalProps = {
  selectedMemberDetail: any;
  setSelectedMemberDetail: React.Dispatch<React.SetStateAction<any>>;
  setMemberList: React.Dispatch<React.SetStateAction<Array<ProjectMember & User>>>;
  removeMemberModalOpen: boolean;
  setRemoveMemberModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const RemoveMemberModal = (props: RemoveMemberModalProps) => {
  const { selectedMemberDetail, setSelectedMemberDetail, setMemberList, removeMemberModalOpen, setRemoveMemberModalOpen } = props;

  const [email, setEmail] = useState<string>("");

  const removeMember_ = async () => {
    const { result, error } = await removeMemberAction(selectedMemberDetail.projectMemberId);

    if (result) {
      setMemberList(result);
      setRemoveMemberModalOpen(false);
      setSelectedMemberDetail(undefined);
      toast.success("the member was deleted successfully");
    }

    if (error) {
      setSelectedMemberDetail(undefined);
      setRemoveMemberModalOpen(false);
      errorAlert(error.data.mesasge);
    }
  };

  const emailValidation = useValidateCondition([email, selectedMemberDetail.email], VALIDATION__PROJECT_remove_member_email(email, selectedMemberDetail.email));

  const isAble = useIsAble([emailValidation.isValid]);

  return (
    <ModalTemplateB
      isOpen={removeMemberModalOpen}
      onRequestClose={() => setRemoveMemberModalOpen(false)}
      title={"Remove member"}
      description={"Please enter the email address below of the member you want to remove."}
    >
      <div className="remove-member-modal">
        <div className="remove-member-modal-user">
          <div className="remove-member-modal-user-info">
            <div className="remove-member-modal-user-info-profile">
              <img src={selectedMemberDetail.image} className="remove-member-modal-user-info-profile-image" alt="user" />
            </div>
            <div className="remove-member-modal-user-info-name">{`${selectedMemberDetail.firstName} ${selectedMemberDetail.lastName}`}</div>
          </div>
          <div className="remove-member-modal-user-info">
            <div className="remove-member-modal-user-info-email">{selectedMemberDetail.email}</div>
          </div>
        </div>
        <div className="remove-member-modal-check">
          <div className="remove-member-modal-check-label">
            <div className="remove-member-modal-check-label-square" />
            <div className="remove-member-modal-check-label-text">Email</div>
          </div>
          <CommentInput {...emailValidation} value={email} onChange={(e) => setEmail(e.target.value)} placeholder={selectedMemberDetail.email} />
        </div>
        <div className="remove-member-modal-description">
          {`This member is immediately removed from the project\nand EQ Hub service is no longer available in the project.`}
        </div>
        <ButtonContainer>
          <BasicButton theme={ButtonTheme.SECONDARY} size={ButtonSize.LARGE} onClick={() => setRemoveMemberModalOpen(false)}>
            Close
          </BasicButton>
          <BasicButton disabled={!isAble} size={ButtonSize.LARGE} onClick={() => removeMember_()}>
            Remove
          </BasicButton>
        </ButtonContainer>
      </div>
    </ModalTemplateB>
  );
};

export default RemoveMemberModal;
