import React, { useEffect, useState } from "react";
import "./GenerateMFAModal.scss";
import ModalTemplateB from "view/components/_template/modal/ModalTemplateB";
import ProgressState from "view/components/_molecules/progressState/ProgressState";
import BasicButton from "view/components/_atom/button/BasicButton";
import { ButtonSize, ButtonTheme } from "view/components/_atom/button/StyledButton";
import ButtonContainer from "view/components/_atom/container/ButtonContainer";
import { GENERATE_MFA_STEP_LIST, GenerateMFAStep } from "../constants/user.data";
import BasicInput from "view/components/_atom/input/BasicInput";
import googleAuthenticatorQRForIos from "../assets/QR_google_authenticator_ios.png";
import googleAuthenticatorQRForAndroid from "../assets/QR_google_authenticator_android.png";
import copyIcon from "../../../assets/images/icon_copy_default.png";
import { createTotpKeyAction, saveTotpKeyAction } from "action/userAction";
import { useSelector } from "react-redux";
import { user_id } from "reducer/userReducer";
import toast from "react-hot-toast";
import { TOTP } from "reducer/clientType/userClientType";
import { errorAlert } from "utils/Utils";

type GenerateMFAModalProps = {
  isOpen: boolean;
  closeModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const GenerateMFAModal = (props: GenerateMFAModalProps) => {
  const { isOpen, closeModal } = props;

  const userID = useSelector(user_id);

  const [currentStep, setCurrentStep] = useState<GenerateMFAStep>(GenerateMFAStep.INSTALL);
  const [otpData, setOtpData] = useState<TOTP>({ totpKey: "", qrURL: "" });
  const [otpCode, setOtpCode] = useState<string>("");

  const onRequestClose = () => {
    closeModal(false);
  };

  const createTotpKey_ = async () => {
    const { result, error } = await createTotpKeyAction(userID);

    if (result) {
      setOtpData(result);
    }

    if (error) {
    }
  };

  useEffect(() => {
    createTotpKey_();
  }, []);

  return (
    <ModalTemplateB
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      title={"Set up Multi-Factor Authentication"}
      description="Setting up OTP-based Multi-Factor Authentication"
    >
      <div className="generate-mfa-modal">
        <ProgressState step={currentStep} stepList={GENERATE_MFA_STEP_LIST} size={547.5} shadow={false} />
        {currentStep === GenerateMFAStep.INSTALL && <InstallGoogleAuthenticator onRequestClose={onRequestClose} setCurrentStep={setCurrentStep} />}
        {currentStep === GenerateMFAStep.REGISTER && <RegisterDevice setCurrentStep={setCurrentStep} totpKey={otpData.totpKey} qrURL={otpData.qrURL} />}
        {currentStep === GenerateMFAStep.VERIFY && (
          <VerifyOTP setCurrentStep={setCurrentStep} otpKey={otpData.totpKey} otpCode={otpCode} setOtpCode={setOtpCode} closeModal={onRequestClose} />
        )}
      </div>
    </ModalTemplateB>
  );
};

export default GenerateMFAModal;

type InstallGoogleAuthenticatorProps = {
  onRequestClose: Function;
  setCurrentStep: React.Dispatch<React.SetStateAction<GenerateMFAStep>>;
};

const InstallGoogleAuthenticator = (props: InstallGoogleAuthenticatorProps) => {
  const { onRequestClose, setCurrentStep } = props;

  return (
    <>
      <div className="generate-mfa-modal-body">
        <div className="generate-mfa-modal-step">
          <p className="generate-mfa-modal-step-title">Step 1. Install OTP App</p>
          <p className="generate-mfa-modal-step-description">{`Install the Google OTP App on your device.\nScan the QR code below or search for ‘Google OTP’ to install.`}</p>
        </div>
        <div className="generate-mfa-modal-content">
          <div className="generate-mfa-modal-google-authenticator">
            <img src={googleAuthenticatorQRForAndroid} className="generate-mfa-modal-google-authenticator-qr" alt="" />
            <img src={googleAuthenticatorQRForIos} className="generate-mfa-modal-google-authenticator-qr" alt="" />
          </div>
        </div>
      </div>
      <ButtonContainer>
        <BasicButton size={ButtonSize.MIDDLE} theme={ButtonTheme.SECONDARY} onClick={() => onRequestClose()}>
          Close
        </BasicButton>
        <BasicButton size={ButtonSize.MIDDLE} onClick={() => setCurrentStep(GenerateMFAStep.REGISTER)}>
          Next
        </BasicButton>
      </ButtonContainer>
    </>
  );
};

type RegisterDeviceProps = {
  setCurrentStep: React.Dispatch<React.SetStateAction<GenerateMFAStep>>;
  totpKey: string;
  qrURL: string;
};

const RegisterDevice = (props: RegisterDeviceProps) => {
  const { setCurrentStep, totpKey, qrURL } = props;

  const copyOtpKey = () => {
    window.navigator.clipboard.writeText(totpKey).then(() => toast.success("Copied!"));
  };

  const splitTotpKey = (totpKey: string): string => {
    // 4글자씩 띄어쓰기
    return totpKey.toUpperCase().replace(/(.{4})/g, "$1 ");
  };

  return (
    <>
      <div className="generate-mfa-modal-body">
        <div className="generate-mfa-modal-step">
          <p className="generate-mfa-modal-step-title">Step 2. Register your device</p>
          <p className="generate-mfa-modal-step-description">{`Launch the Google OTP app, and scan the QR code below or enter the setup key\nto register your device and add the EQ Hub account.`}</p>
        </div>
        <div className="generate-mfa-modal-content">
          <div className="generate-mfa-modal-register">
            <div className="generate-mfa-modal-register-qr">
              <img className="generate-mfa-modal-register-qr-img" src={qrURL} alt="" />
            </div>
            <div className="generate-mfa-modal-register-key">
              <span className="generate-mfa-modal-register-key-value">{splitTotpKey(totpKey)}</span>
              <div className="generate-mfa-modal-register-key-copy" onClick={() => copyOtpKey()}>
                <img src={copyIcon} className="generate-mfa-modal-register-key-copy-icon" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ButtonContainer>
        <BasicButton size={ButtonSize.MIDDLE} theme={ButtonTheme.SECONDARY} onClick={() => setCurrentStep(GenerateMFAStep.INSTALL)}>
          Back
        </BasicButton>
        <BasicButton size={ButtonSize.MIDDLE} onClick={() => setCurrentStep(GenerateMFAStep.VERIFY)}>
          Next
        </BasicButton>
      </ButtonContainer>
    </>
  );
};

type VerifyOTPPRops = {
  setCurrentStep: React.Dispatch<React.SetStateAction<GenerateMFAStep>>;
  otpKey: string;
  otpCode: string;
  setOtpCode: React.Dispatch<React.SetStateAction<string>>;
  closeModal: Function;
};

const VerifyOTP = (props: VerifyOTPPRops) => {
  const { setCurrentStep, otpKey, otpCode, setOtpCode, closeModal } = props;

  const userID = useSelector(user_id);

  const saveTotpKey_ = async () => {
    const { result, error } = await saveTotpKeyAction(userID, otpCode, otpKey);

    if (result) {
      toast.success("Successfully registered MFA");
      closeModal();
    }

    if (error) {
      // 매직리터럴 변경
      if (error.data.serverStatus === 38) {
        errorAlert(error.data.message);
      }
    }
  };

  return (
    <>
      <div className="generate-mfa-modal-body">
        <div className="generate-mfa-modal-step">
          <p className="generate-mfa-modal-step-title">Step 3. Verify OTP number</p>
          <p className="generate-mfa-modal-step-description">{`Enter the 6-digit EQ Hub OTP authentication number displayed in the OTP app.`}</p>
        </div>
        <div className="generate-mfa-modal-content">
          <div className="generate-mfa-modal-otp">
            <span className="generate-mfa-modal-otp-label">OTP number</span>
            <div className="generate-mfa-modal-otp-input">
              <BasicInput value={otpCode} onChange={(e) => setOtpCode(e.target.value)} placeholder="Enter code" maxLength={6} autoFocus />
            </div>
          </div>
        </div>
        <div className="generate-mfa-modal-description">{`Once you complete the process, OTP authentication is required to sign in to EQ Hub thereafter.`}</div>
      </div>
      <ButtonContainer>
        <BasicButton size={ButtonSize.MIDDLE} theme={ButtonTheme.SECONDARY} onClick={() => setCurrentStep(GenerateMFAStep.REGISTER)}>
          Back
        </BasicButton>
        <BasicButton size={ButtonSize.MIDDLE} onClick={() => saveTotpKey_()}>
          Save
        </BasicButton>
      </ButtonContainer>
    </>
  );
};
