import { NUMBER_REG_EXP } from "constants/commonConstants";
import "./PagingButtonBar.scss";
import React, { useEffect } from "react";

function PagingButtonBar(props) {
  const { limit, page, setPage, maximumPage = 1, totalLength, getData } = props || {};
  const pageSetting = (value) => {
    if (typeof value === "string" && value === "") {
      setPage(value);
      return;
    }

    if (!NUMBER_REG_EXP.test(value)) {
      return;
    }
    if (Number(value) >= maximumPage) {
      setPage(maximumPage);
      return;
    }
    if (Number(value) < 1) {
      setPage(1);
      return;
    }
    setPage(value);
  };

  const movePage = (where) => {
    let result = page;
    switch (where) {
      case "first":
        result = 1;
        break;
      case "prev":
        if (Number(page) > 1) {
          result = Number(page) - 1;
        }
        break;
      case "next":
        if (Number(page) < Number(maximumPage)) {
          result = Number(page) + 1;
        }
        break;
      case "last":
        result = Number(maximumPage);
        break;
      default:
        break;
    }
    return result;
  };

  const currentStart = (page - 1) * limit + 1;
  const currentEnd = page === maximumPage ? totalLength : page * limit;

  const onClickPageMoveButton = async (where) => {
    const result = movePage(where);
    setPage(result);
  };

  useEffect(() => {
    getData(page);
  }, [page]);

  return (
    <div className="paging-button-bar">
      <div className="paging-balance">
        {currentStart} - {currentEnd} of {totalLength} items
      </div>
      <div className="paging-button-wrapper">
        <button className="paging-button prev" onClick={() => onClickPageMoveButton("prev")} disabled={page === 1}>
          Previous
        </button>
        <button className="paging-button next" onClick={() => onClickPageMoveButton("next")} disabled={page === maximumPage}>
          Next
        </button>
      </div>
    </div>
  );
}

export default PagingButtonBar;
