import { WalletSessionStorage } from "./Wallet/sessionStorage";
import { servers } from "./server";

export const services = {
  wallet: {
    sessionStorage: WalletSessionStorage,
  },
  // token: {
  //   server: servers.token,
  // },
  // tokenKits: {
  //   server: servers.tokenKits,
  // },
  // currency: {
  //   server: servers.currency,
  // },
};
