import React from "react";
import { Route, Routes } from "react-router-dom";
import EmailVerificationResult from "./email-verification/page/EmailVerificationResult";
import ResetPassword from "./reset-password/page/ResetPassword";

const VerificationRouter = () => {
  return (
    <Routes>
      <Route path="find-password/*" element={<ResetPassword />} />
      <Route path="email-verification/*" element={<EmailVerificationResult />} />
    </Routes>
  );
};
export default VerificationRouter;
