import { Project } from "../reducer/clientType/projectClientType";
import { useSelector } from "react-redux";
import { selected_project_id, selectProjectById } from "../reducer/projectReducer";
import { RootState } from "../reducer/index";
import { useMemo } from "react";
import { user_info } from "../reducer/userReducer";

const useGetSelectedProject = () => {
  const selectedProjectID = useSelector(selected_project_id);
  const userId = useSelector(user_info).id;
  const selectedProject = useSelector<RootState, Project>((state) => selectProjectById(state, selectedProjectID) as Project);
  const isOwner = useMemo(() => {
    if (selectedProject) {
      return selectedProject.creatorId === userId;
    }
    return false;
  }, [userId, selectedProject]);
  return { selectedProject, isOwner, selectedProjectID };
};

export default useGetSelectedProject;
