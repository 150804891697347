import { parseAction } from "./util/parseAction";
import { Project } from "../reducer/clientType/projectClientType";
import { setProjects, setProjectToken, setSelectedProjectId } from "../reducer/projectReducer";
import { storeDispatch } from "../reducer/index";
import { getBelongProjectsParser } from "../parser/userParser";
import { generateProjectTokenParser, getMyProjectByProjectTokenParser, getProjectRolesParser } from "../parser/projectParser";
import {
  setContractSolution,
  setContractStandard,
  setNodeSpec,
  setPaymentType,
  setProjectRole,
} from "../reducer/presetReducer";
import { getNodeSpecListParser } from "../parser/networkParser";
import _ from "lodash";
import { getPaymentTypePresetsParser } from "../parser/paymentMethodParser";
import { projectJWT } from "server/index/serverInstances";
import { eqhub } from "../App";
import { getCategoriesAction, getTagsAction } from "./contractLibraryAction";

export const initializeProjectAction = () =>
  parseAction(async () => {
    // projectToken 타입 변경
    let projectToken: any;
    let currentProject: Project;
    // const { projectToken: projectTokenByRefresh } = user_info(storeState());
    const projectTokenInstance = projectJWT.getJWT();
    const belongsProject = _.sortBy(await getBelongProjectsParser(), "projectId");
    if (belongsProject.length === 0) {
      return belongsProject;
    }
    const parsedProject: Project[] = belongsProject.map((el) => el.project);
    if (projectTokenInstance.access.token) {
      //redux에 project token이 있을 경우
      currentProject = await getMyProjectByProjectTokenParser(projectTokenInstance.access.token);
      projectToken = projectTokenInstance;
    } else {
      //redux에 project token이 없을 경우
      currentProject = parsedProject[0];
      projectToken = await generateProjectTokenParser(currentProject.id);
    }
    projectJWT.setAccess(projectToken.access);
    projectJWT.setRefresh(projectToken.refresh);
    storeDispatch(setSelectedProjectId(currentProject.id));
    storeDispatch(setProjectToken({ ...projectToken }));
    storeDispatch(setProjects(parsedProject));
    eqhub.setProjectAccessToken(projectToken.access.token);

    //Get Preset
    const [projectRole, nodeSpec, paymentType, categories, solutions] = await Promise.all([
      getProjectRolesParser(),
      getNodeSpecListParser(),
      getPaymentTypePresetsParser(),
      getCategoriesAction(),
      getTagsAction(),
    ]);

    const parsedPaymentType = paymentType.map((type) => ({
      ...type,
      name: _.capitalize(type.name),
    }));
    storeDispatch(setPaymentType(parsedPaymentType));
    storeDispatch(setProjectRole(projectRole));
    storeDispatch(setNodeSpec(nodeSpec));
    storeDispatch(setContractStandard(categories.result));
    storeDispatch(setContractSolution(solutions.result));

    return belongsProject;
  });

export const encryptPrivateKeyAction = (privateKey: string) =>
  parseAction(async () => {
    const str2ab = (str) => {
      const buf = new ArrayBuffer(str.length);
      const bufView = new Uint8Array(buf);
      for (let i = 0, strLen = str.length; i < strLen; i++) {
        bufView[i] = str.charCodeAt(i);
      }
      return buf;
    };

    const importPublicKey = async () => {
      const binaryDerString = window.atob(process.env.REACT_APP_ENCRYPTION_PUBLIC_KEY as string);
      const binaryDer = str2ab(binaryDerString);

      const publicKey = await window.crypto.subtle.importKey(
        "spki",
        binaryDer,
        {
          name: "RSA-OAEP",
          hash: "SHA-256",
        },
        true,
        ["encrypt"]
      );

      return publicKey;
    };

    const publicKey = await importPublicKey();

    const encodedPrivateKey = new TextEncoder().encode(privateKey);
    const encryptedPrivateKey = await window.crypto.subtle.encrypt(
      {
        name: "RSA-OAEP",
      },
      publicKey,
      encodedPrivateKey
    );
    const response = Buffer.from(encryptedPrivateKey).toString("base64");
    return response;
  });
