import { useNavigate } from "react-router-dom";
import { isProxyContract } from "../utils/contractUtils";
import "./ContractChainViewer.scss";
import CopyLabelWithIcon from "view/components/_atom/label/CopyLabelWithIcon";
import { ManageFieldContainer, ManageFieldLabel, RowManageField } from "view/components/_atom/section/ManageField";
import contractIcon from "../../../../assets/images/service/Icon_SNB_contract_library.png";

const ContractChainViewer = ({ contractDetail }) => {
  const navigate = useNavigate();

  return (
    <>
      <ManageFieldContainer icon={contractIcon} title="Network Info">
        <RowManageField>
          <ManageFieldLabel label="Network" />
          <span>{contractDetail.networkLabel ?? "-"}</span>
        </RowManageField>
        <RowManageField>
          <ManageFieldLabel label="Microchain" />
          <span>{contractDetail?.microChainLabel ?? "-"}</span>
        </RowManageField>
        <RowManageField>
          <ManageFieldLabel label="Contract address" />
          {contractDetail?.contractAddress ? <CopyLabelWithIcon text={contractDetail?.contractAddress} /> : <span>-</span>}
        </RowManageField>
        {isProxyContract(contractDetail) && (
          <RowManageField>
            <ManageFieldLabel label="Implementation contract" />
            <div className="flex">
              <CopyLabelWithIcon text={contractDetail?.implementationContractAddress} />
              {contractDetail?.mainStatus === 5 ? (
                <div className="upgrade-button" onClick={() => navigate(`/developer/contract/list/${contractDetail.contractId}/upgrade`)}>
                  Upgrade
                </div>
              ) : null}
            </div>
          </RowManageField>
        )}
      </ManageFieldContainer>
    </>
  );
};

export default ContractChainViewer;
