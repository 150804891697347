import React, { useState } from "react";
import "./ProjectPricing.scss";
import projectPlanIcon from "../assets/Icon_project_plans.png";
import useLoading from "hooks/useLoading";
import PageLoading from "view/components/_atom/loading/PageLoading";
import useGetSelectedProject from "hooks/useGetSelectedProject";
import { errorAlert } from "utils/Utils";
import { getPricingPlansAction } from "action/projectAction";
import PricingPlan from "../components/PricingPlan";
import SectionContainer from "view/components/_atom/section/SectionContainer";
import PageHeader from "view/components/_molecules/page/PageHeader";
import Warning from "view/components/_atom/box/Warning";

const ProjectPricing = () => {
  const { selectedProjectID } = useGetSelectedProject();

  const [planList, setPlanList] = useState<Array<any>>([]);

  const getPricingPlanList_ = async () => {
    const { result, error } = await getPricingPlansAction();

    if (result) {
      setPlanList(result);
    }

    if (error) {
      errorAlert(error.data.message);
    }
  };

  const { loading } = useLoading({
    dependency: [selectedProjectID],
    synchronousFunction: async () => {
      await getPricingPlanList_();
    },
  });

  if (loading) {
    return <PageLoading style={{ height: "calc(100vh - 60px - 112px - 141px - 116px - 100px)" }} />;
  }

  return (
    <div id="ProjectPricing">
      <PageHeader title={"Pricing"} />

      <div className="project-pricing-container">
        <div className="project-pricing-header">
          <p className="project-pricing-header-title">Choose The Plan That's Right For You</p>
          <p className="project-pricing-header-description">DApp development plans</p>
        </div>
        <SectionContainer icon={projectPlanIcon} title="DApp Development Plans">
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            {planList.map((plan) => {
              return <PricingPlan key={`plan-list-${plan.pricingPlanId}`} {...plan} />;
            })}
          </div>
        </SectionContainer>

        <Warning
          title={"Blockchain Network Infrastructure"}
          description={`The cost of operating the blockchain network infrastructure is charged as much as it is used, regardless of the DApp Development Plans above, the cost of operating the blockchain network infrastructure is charged as much as it is used.`}
        />
      </div>
    </div>
  );
};

export default ProjectPricing;
