// Network 서비스에서 사용되는 모든 sub status
export const SUB_STATUS_LIST = [0, 1, 2];
// Wallet 서버에서 배포된 currency에 대한 main status
export const CURRENCY_MAIN_STATUS = [-1, 5];
export const CURRENCY_TYPE_COIN = [0];

export const NETWORK_MICRO_CHAIN_SETTING_INITIAL_FORMAT = {
  initialSupply: null,
  isTestnet: false,
  isContractUploadAvailability: false,
  isNodeParticipationAvailability: false,
  fee: null,
  inflationRate: null,
  disInflationRate: null,
};

// Network 데이터의 기본 포맷과 초기값
export const NETWORK_DATA_FORMAT = {
  nodeAlloc: [],
  preAlloc: [],
  network: {
    label: null,
    networkPlanId: null,
  },
  microChain: {
    label: null,
    protocolId: null,
    chainClientId: null,
    chainClientVersionId: null,
    algorithmId: null,
  },
  currency: {
    name: null,
    image: null,
    symbol: null,
  },
  microChainSetting: NETWORK_MICRO_CHAIN_SETTING_INITIAL_FORMAT,
};

// Network 환경 종류
export const NETWORK_ENVIRONMENT_LIST = [
  {
    id: "type-mainnet",
    value: false,
    label: "Mainnet",
  },
  {
    id: "type-testnet",
    value: true,
    label: "Testnet",
  },
];

// Network 공개 여부 종류
export const NETWORK_ACCESS_STATUS = [
  {
    id: "access-public",
    value: true,
    label: "Public",
  },
  {
    id: "access-private",
    value: false,
    label: "Private",
  },
];

// Network Pre Alloc에 대한 데이터 초기값
export const PRE_ALLOC_DEFAULT_DATA = {
  name: "",
  distributionPercent: null,
  address: "",
  mmcBalance: null,
  mmcStaking: null,
  keyStore: null,
};

// Network allocation에 사용되는 차트의 색상
export const NETWORK_CHART_COLOR = [
  "#DE5E56",
  "#9F50B6",
  "#5DA4EF",
  "#7BB872",
  "#F0AE6B",
  "#F0D36C",
  "#8FD3CB",
  "#CF7BAD",
  "#8F76D4",
  "#4D6B89",
  "#E39E9C",
  "#C597D4",
  "#9CC9F5",
  "#AFD4AB",
  "#F9C590",
  "#F4E4AA",
  "#C7E1DE",
  "#D9A5C4",
  "#BDB3DA",
  "#99B2CB",
  "#D77976",
  "#AF6EC3",
  "#77B4F0",
  "#91C48A",
  "#F4B87B",
  "#F2DB88",
  "#A8D9D3",
  "#D38DB7",
  "#A28FD7",
  "#6D88A4",
  "#D3483E",
  "#8331A4",
  "#4188DE",
  "#5D9D52",
  "#ECA55D",
  "#EECC54",
  "#79CDC3",
  "#CB6BA4",
  "#7D5FD2",
  "#2C4C6C",
  "#B63731",
  "#612694",
  "#2F66BA",
  "#457B3B",
  "#E79848",
  "#ECC22F",
  "#59C5B8",
  "#C55497",
  "#633CCF",
  "#002245",
];

// Network allocation 차트에 사용되는 부족량에 대한 색상
export const NETWORK_CHART_INSUFFICIENT_COLOR = "#c9c9c9";

// Network allocation 차트에 사용되는 초과량에 대한 색상
export const NETWORK_CHART_OVERFLOW_COLOR = "#cf7171";
