import {
  disableBlockExplorerParser,
  getBlockExplorerDetailParser,
  getBlockExplorerListParser,
  regitserBlockExplorerParser,
  releaseBlockExplorerParser,
} from "../parser/blockExplorerParser";
import { getMicroChainListParser } from "../parser/microChainParser";
import { parseAction } from "./util/parseAction";
import _ from "lodash";
import { networkParserNew } from "../utils/Utils";

export const getInitialDataFromRegisterBEAction = (projectId: number) =>
  parseAction(async () => {
    const registeredStatusMicrochain = [5];
    const uncreatedStatusBlockExplorer = [1, 2, 3, 4, 5];
    const [microChainList, registeredBlockExplorerList] = await Promise.all([
      await getMicroChainListParser({ projectId, mainStatus: registeredStatusMicrochain }),
      await getBlockExplorerListParser({ projectId, mainStatus: uncreatedStatusBlockExplorer, isExternal: Number(false) }),
    ]);

    const registeredBlockExplorerMicroChainIds = _.uniq(registeredBlockExplorerList.map((el: any) => el.microChainId));
    const availableMicroChainList = microChainList.filter((el: any) => !registeredBlockExplorerMicroChainIds.includes(el.id));
    const networkList = networkParserNew(availableMicroChainList);

    return {
      microChainList: availableMicroChainList,
      networkList,
    };
  });

export const registerBlockExplorerAction = (microChainId: number) =>
  parseAction(async () => {
    return await regitserBlockExplorerParser(microChainId);
  });

export const getBlockExplorerListAction = (options?: { projectId?: number; mainStatus?: Array<number>; isExternal?: number }) =>
  parseAction(async () => {
    return await getBlockExplorerListParser(options);
  });

export const getBlockExplorerDetailAction = (microChainId: number, options?: { mainStatus?: number; isExternal?: number }) =>
  parseAction(async () => {
    return await getBlockExplorerDetailParser(microChainId, options);
  });

export const releaseBlockExplorerAction = (microChainId: number) =>
  parseAction(async () => {
    return await releaseBlockExplorerParser(microChainId);
  });

export const disableBlockExplorerAction = (microChainId: number) =>
  parseAction(async () => {
    return await disableBlockExplorerParser(microChainId);
  });
