import { useEffect, useState } from "react";
import PageLoading from "../../../../components/_atom/loading/PageLoading";
import { useNavigate, useParams } from "react-router-dom";
import { CONTRACT_DISCLOSURE, CONTRACT_ERC, CONTRACT_TYPE } from "../constants/contract.data";
import Input from "../../../../../legacy-common/atom/input/Input";
import { errorAlert, onChangeInputHandler } from "../../../../../utils/Utils";
import TextArea from "../../../../../legacy-common/atom/textarea/TextArea";
import Select from "../../../../components/_atom/select/Select";
import RadioButtons from "../../../../../legacy-common/molcules/RadioButtons/RadioButtons";
import FileDragAndDrop from "../../../../components/_atom/file/FileDragAndDrop";
import Section from "../../../../../legacy-common/atom/section/Section";
import useValidateCondition from "../../../../../hooks/useValidateCondition";
import { VC__is_empty_value, VC__is_not_version, VC__is_text_exceed_limit } from "../../../../../modules/validation/validationCondition";
import useIsAble from "../../../../../hooks/useIsAble";
import CommentInput from "../../../../components/_atom/input/CommentInput";
import BasicButton from "../../../../components/_atom/button/BasicButton";
import { createContractVersionAction, getContractDetailForCreateVersionAction } from "../../../../../action/contractLibraryAction";
import PageArticle from "../../../../components/_template/page/PageArticle";
import PageTitle from "../../../../components/_molecules/page/PageTitle";
import { ButtonSize } from "view/components/_atom/button/StyledButton";
import {addDescriptionRecursive, readFile} from "../utils/contractUtils";
import {generateABiFile} from "../constants/contract.utils";

const CreateAdditionalContractVersion = () => {
  const navigate = useNavigate();
  const { contractId } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [contractInfo, setContractInfo] = useState({
    title: "",
    description: "",
  });
  const [versionInfo, setVersionInfo] = useState({
    version: "",
    versionDescription: "",
  });
  const [contractErcType, setContractErcType] = useState(null);
  const [contractType, setContractType] = useState(null);
  const [contractDisclosure, setContractDisclosure] = useState(CONTRACT_DISCLOSURE[0].value);

  const [solidityFile, setSolidityFile] = useState(null);
  const [byteFile, setByteFile] = useState(null);
  const [abiFile, setAbiFile] = useState(null);

  const versionValidation = useValidateCondition(
    [versionInfo.version],
    [
      {
        condition: !VC__is_empty_value(versionInfo.version),
        message: "",
      },
      {
        condition: !VC__is_not_version(versionInfo.version),
        message: "Version must be include number & .",
      },
    ]
  );

  const versionDescriptionValidation = useValidateCondition(
    [versionInfo.versionDescription],
    [
      {
        condition: !VC__is_text_exceed_limit(versionInfo.versionDescription, 1000),
        message: "Please enter under 1,000 characters",
      },
    ]
  );

  const isAble = useIsAble([versionValidation.isValid, versionDescriptionValidation.isValid, solidityFile, byteFile, abiFile]);

  const onClickUpdateVersionButton = async () => {
    let formData = new FormData();
    formData.append("version", versionInfo.version);
    formData.append("versionDescription", versionInfo.versionDescription);
    formData.append("solidityCode", solidityFile);
    formData.append("byteCode", byteFile);
    formData.append("abiCode", abiFile);

    const ABICode = await readFile(abiFile);

    const ABICodeForView = addDescriptionRecursive(ABICode, 'inputs')

    const ABIViewFile = generateABiFile(ABICodeForView, abiFile.name.split('.')[0])

    formData.append("customAbiCode", ABIViewFile);

    const { result, error } = await createContractVersionAction(Number(contractId), 4, formData);
    if (result) {
      navigate("/developer/contract/storage");
    }
    if (error) errorAlert(error.data.message);
  };

  const setInitialDataFromServer = async () => {
    const { error, result } = await getContractDetailForCreateVersionAction(Number(contractId));
    if (error) {
      errorAlert(error.data.message);
    } else {
      setContractInfo({
        title: result.title,
        description: result.description,
      });
      setContractErcType(result.ercType);
      setContractType(result.type);
    }
  };

  const UPDATE_VERSION_CONTRACT_VIEW = () => [
    {
      renderers: [
        {
          index: "Title",
          value: (
            <Input
              name={"title"}
              value={contractInfo.title}
              onChange={(e) => onChangeInputHandler(setContractInfo, "title", e.target.value)}
              readOnly={true}
              placeholder={"Enter the name of Contract. (Ex. EQ Token Contract)"}
            />
          ),
        },
        {
          index: "Description",
          value: contractInfo.description,
        },
        {
          index: "ERC",
          value: (
            <Select placeholder={"Not selected"} options={CONTRACT_ERC} selectedValue={contractErcType} setSelectedValue={setContractErcType} disabled={true} />
          ),
        },
        {
          index: "Type",
          value: <RadioButtons options={CONTRACT_TYPE} readOnly={true} selectedValue={contractType} onChange={setContractType} />,
        },
        {
          index: "Public / Private",
          value: <RadioButtons options={CONTRACT_DISCLOSURE} selectedValue={contractDisclosure} onChange={setContractDisclosure} />,
        },
        {
          index: "Contract version",
          value: (
            <CommentInput
              {...versionValidation}
              name={"version"}
              value={versionInfo.version}
              onChange={(e) => onChangeInputHandler(setVersionInfo, "version", e.target.value)}
              placeholder={"Enter the version of Contract"}
            />
          ),
        },
        {
          index: "Version description",
          value: (
            <TextArea
              name={"versionDescription"}
              value={versionInfo.versionDescription}
              onChange={(e) => onChangeInputHandler(setVersionInfo, "versionDescription", e.target.value)}
              placeholder={"Enter the description of this version"}
              rows={5}
            />
          ),
        },
      ],
    },
  ];

  const UPDATE_VERSION_CODE_VIEW = () => [
    {
      renderers: [
        {
          index: "Solidity Code",
          value: (
            <FileDragAndDrop
              file={solidityFile}
              setFile={setSolidityFile}
              allowExtension={[".sol", ".zip"]}
              placeholder={"Drag file or click to upload Solidity code"}
            />
          ),
        },
        {
          index: "Byte Code",
          value: <FileDragAndDrop file={byteFile} setFile={setByteFile} allowExtension={[".txt"]} placeholder={"Drag file or click to upload Byte code"} />,
        },
        {
          index: "Abi Code",
          value: (
            <FileDragAndDrop file={abiFile} setFile={setAbiFile} allowExtension={[".txt", ".json"]} placeholder={"Drag file or click to upload Abi code"} />
          ),
        },
      ],
    },
  ];

  useEffect(() => {
    setInitialDataFromServer().finally(() => setIsLoading(false));
  }, []);

  if (isLoading) {
    return <PageLoading />;
  }

  return (
    <PageArticle>
      <PageTitle title={"Update Version"} size={810} />
      <div className="page-layout-810">
        <Section
          title={"Basic Info"}
          description={"Set the basic information for the smart contract you want to update."}
          view={UPDATE_VERSION_CONTRACT_VIEW}
        />
        <Section title={"Code Info"} description={"Upload the code file of the smart contract you want to update."} view={UPDATE_VERSION_CODE_VIEW} />
        <div className="flex justify-content-flex-end">
          <BasicButton size={ButtonSize.LARGE} disabled={!isAble} onClick={() => onClickUpdateVersionButton()}>
            Update
          </BasicButton>
        </div>
      </div>
    </PageArticle>
  );
};

export default CreateAdditionalContractVersion;
