import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { removeConnectedInfo, setConnectedInfo } from '../../store/redux/EQconnect/EQConnectSlice';
import BasicButton from 'view/components/_atom/button/BasicButton';
import { ButtonSize, ButtonTheme } from 'view/components/_atom/button/StyledButton';
import Modal from 'react-modal';
import { customStyles } from '../../view/styles/customStyles';
import './EQConnectWalletButtonWithMetamask.scss';
import metamaskLogo from '../../view/assets/images/img-metamask.png';
import eqWalletLogo from '../../view/assets/images/img-eq-wallet.png';
import { requestAddressToExtension, requestAddressToMetamask } from '../EQExtension/utils/messageUtils';
import { logic } from '../../logic';

export const enum WalletType {
  metaMask = 'metamask',
  eqHub = 'eqhub',
}

const WALLETS = [
  {
    type: WalletType.metaMask,
    img: metamaskLogo,
  },
  {
    type: WalletType.eqHub,
    img: eqWalletLogo,
  },
];
type EQConnectWalletButtonWithMetamaskProps = {
  size?: 'small' | 'regular';
  setWalletAddress?: (address: string) => void;
};
const EQConnectWalletButtonWithMetamask = (props: EQConnectWalletButtonWithMetamaskProps) => {
  const { size = 'regular', setWalletAddress } = props;
  const dispatch = useDispatch();
  const [isOpenSelectWalletModal, setIsOpenSelectWalletModal] = useState(false);
  const [address, setAddress] = useState('');
  const isConnected = useMemo(() => Boolean(address), [address]);

  const getAddress = async () => {
    const { result, error } = await logic.wallet.getConnectAddress();
    if (error) {
      return;
    }
    if (setWalletAddress) {
      setWalletAddress(result);
    }
    setAddress(result);
  };
  useEffect(() => {
    getAddress();
    window.addEventListener('message', getAddress);
    return () => {
      window.removeEventListener('message', getAddress);
    };
  }, []);
  useEffect(() => {
    window.addEventListener('disconnect', () => {
      disconnectWallet();
    });
    return () => {
      window.removeEventListener('disconnect', () => {
        disconnectWallet();
      });
    };
  }, []);

  // const getConnectedWalletType = async () => {
  //   const { result, error } = await logic.wallet.getConnectWallet();
  //   if (error) {
  //     setSelectedWallet(undefined);
  //     return;
  //   }
  //   if (result) {
  //     switch (result) {
  //       case 'metamask':
  //         setSelectedWallet(WalletType.metaMask);
  //         break;
  //       case 'eqhub':
  //         setSelectedWallet(WalletType.eqHub);
  //         break;
  //     }
  //   }
  // };
  // useEffect(() => {
  //   getConnectedWalletType();
  // }, [address]);

  // const onClickButton = async () => {
  //   if (!isConnected) {
  //     setIsOpenSelectWalletModal(true);
  //   } else {
  //     const disconnectEvent = new CustomEvent('disconnect');
  //     window.dispatchEvent(disconnectEvent);
  //     await disconnectWallet();
  //   }
  // };
  const onClickButton = async () => {
    if (!isConnected) {
      const result = await requestAddressToMetamask('metamask');
      if (result) {
        getAddress();
      }
    } else {
      const disconnectEvent = new CustomEvent('disconnect');
      window.dispatchEvent(disconnectEvent);
      await disconnectWallet();
    }
  };

  const disconnectWallet = async () => {
    const { result, error } = await logic.wallet.getConnectWallet();
    if (error) {
      return;
    }

    if (result === 'metamask') {
      await logic.wallet.disconnectWallet();
    }
    if (result === 'eqhub') {
      dispatch(removeConnectedInfo());
      sessionStorage.removeItem('address');
      sessionStorage.removeItem('type');
    }
    // setSelectedWallet(undefined);
    setWalletAddress && setWalletAddress('');
    setAddress('');
  };

  const connectButtonRenderer = useMemo(() => {
    if (Boolean(address)) {
      return (
        <div className="flex-column">
          <div>Disconnect</div>
          <div style={{ fontSize: 12, color: '#AFAFAF' }}>{`${address.slice(0, 6)}...${address.slice(-5)}`}</div>
        </div>
      );
    } else {
      if (size === 'small') {
        return <div>Connect Wallet</div>;
      }
      return <div>Connect</div>;
    }
  }, [address, size]);

  useEffect(() => {
    const sessionAddress = sessionStorage.getItem('address');
    const sessionType = sessionStorage.getItem('type');
    dispatch(
      setConnectedInfo({
        address: sessionAddress,
        type: sessionType,
      })
    );
  }, []);

  return (
    <>
      {/*<SelectWalletModal isOpen={isOpenSelectWalletModal} closeModal={() => setIsOpenSelectWalletModal(false)} getAddress={getAddress} />*/}
      <BasicButton
        size={size === 'small' ? ButtonSize.DYNAMIC : ButtonSize.LARGE}
        style={size === 'small' ? { height: 40 } : undefined}
        theme={address ? ButtonTheme.SECONDARY : ButtonTheme.PRIMARY}
        onClick={onClickButton}
      >
        {connectButtonRenderer}
      </BasicButton>
    </>
  );
};

export default EQConnectWalletButtonWithMetamask;

type SelectWalletModalProps = {
  isOpen: boolean;
  closeModal: () => void;
  getAddress: () => void;
};
const SelectWalletModal = (props: SelectWalletModalProps) => {
  const { isOpen, closeModal, getAddress } = props;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={customStyles}
      overlayClassName="modal-overlay-style"
      ariaHideApp={false}
      shouldCloseOnOverlayClick={true}
    >
      <div className="connect-wallet-modal">
        <div className="connect-wallet-modal-header">Connect Your Wallet</div>
        <div className="connect-wallet-modal-content">
          {WALLETS.map((wallet) => (
            <div
              key={wallet.type}
              className="connect-wallet-modal-content-button"
              onClick={async () => {
                let result: any;
                if (wallet.type === WalletType.eqHub) {
                  result = requestAddressToExtension();
                } else {
                  result = await requestAddressToMetamask(wallet.type);
                }
                if (result) {
                  getAddress();
                }
                closeModal();
              }}
            >
              <img src={wallet.img} alt={wallet.type} className="connect-wallet-modal-content-logo" />
            </div>
          ))}
        </div>
        <div className="connect-wallet-modal-footer">
          You don't have a wallet?
          <a
            className="connect-wallet-modal-footer-link"
            href="https://chromewebstore.google.com/detail/eq-hub-wallet/jjkhekbaikcnpnhflnnlnbonkkobkiim?hl=ko&utm_source=ext_sidebar"
            target="_blank"
            rel="noreferrer"
          >
            Get EQHub wallet
          </a>
        </div>
      </div>
    </Modal>
  );
};
