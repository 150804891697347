import React, { useMemo } from "react";
import "./NetworkNodeOptionField.scss";
import { setTempNodeList } from "../../constants/network.utils";
import { FieldContainer } from "view/components/_atom/section/Field";
import NodeIcon from "view/assets/images/service/Icon_SNB_node.png";
import NodePresetBox from "../atom/NodePresetBox";
import QuestionMark from "view/components/_atom/tooltip/QuestionMark";
import { numberConvertor } from "utils/convert.utils";
import consensusNodeWhiteIcon from "../../assets/Icon_consensus_node_white.png";
import fullNodeWhiteIcon from "../../assets/Icon_full_node_white.png";
import bonusCreditWhiteIcon from "../../assets/Icon_bonus_credit_white.png";
import eqcreditIcon from "../../../../../assets/images/Icon_eqcredit.png";
import { NETWORK_ANNOUNCEMENT_ESTIMATED_COST } from "../../constants/network.data";

const NODE_PRESET_SELECTOR_HEADER = [
  {
    id: 1,
    icon: consensusNodeWhiteIcon,
    label: "Consensus Node",
  },
  {
    id: 2,
    icon: fullNodeWhiteIcon,
    label: "Full Node",
  },
  {
    id: 3,
    icon: bonusCreditWhiteIcon,
    label: "Bonus Credit",
  },
];

const NetworkNodeOptionField = (props) => {
  const { networkPlanList, networkData, setNetworkData } = props;

  const onClickNodePreset = (el) => {
    const { equalizerCount, networkPlanId } = el || {};
    const tempNodeList = setTempNodeList(equalizerCount);
    setNetworkData((prevState) => ({
      ...prevState,
      network: {
        ...prevState.network,
        networkPlanId,
      },
      nodeAlloc: tempNodeList,
    }));
  };

  return (
    <>
      <FieldContainer
        icon={NodeIcon}
        title={"Node Option"}
        description={`Set the number and spec of nodes to participate in the network.\nYou can easily set it using the recommended value.`}
      >
        <NodePresetSelector networkPlanList={networkPlanList} selectedNetworkPlanId={networkData.network.networkPlanId} onClickNodePreset={onClickNodePreset} />
      </FieldContainer>
    </>
  );
};

export default NetworkNodeOptionField;

type NodePresetSelectorProps = {
  networkPlanList: Array<any>;
  selectedNetworkPlanId: number;
  onClickNodePreset: Function;
};

const NodePresetSelector = (props: NodePresetSelectorProps) => {
  const { networkPlanList, selectedNetworkPlanId, onClickNodePreset } = props;

  const estimatedCost = useMemo(() => {
    return networkPlanList.find((el) => el.networkPlanId === selectedNetworkPlanId)?.monthlyPrice ?? "0";
  }, [networkPlanList, selectedNetworkPlanId]);

  return (
    <div className="node-preset-selector">
      <div className="node-preset-selector-header">
        <div />
        <div className="node-preset-selector-header-area">
          {NODE_PRESET_SELECTOR_HEADER.map((el) => (
            <div className="node-preset-selector-header-area-item" key={`node-preset-header-${el.label}`}>
              <div className="node-preset-selector-header-area-item-icon">
                <img src={el.icon} className="node-preset-selector-header-area-item-icon-src" alt="" />
              </div>
              <span className="node-preset-selector-header-area-item-label">{el.label}</span>
            </div>
          ))}
        </div>
      </div>
      <div className="node-preset-selector-body">
        {networkPlanList.map((el) => (
          <div onClick={() => onClickNodePreset(el)} key={`node-preset-item-${el.name}`}>
            <NodePresetBox
              networkPlanId={el.networkPlanId}
              selectedNetworkPlanId={selectedNetworkPlanId}
              name={el.name}
              description={el.description}
              equalizerCount={el.equalizerCount}
              lightNodeCount={el.lightNodeCount}
              bonusCredit={el.bonusCredit}
              disabled={false}
            />
          </div>
        ))}
      </div>
      <div className="node-preset-selector-cost">
        <p className="node-preset-selector-cost-label">Estimated cost per month:</p>
        <div className="node-preset-selector-cost-info">
          <div className="node-preset-selector-cost-info-icon">
            <img src={eqcreditIcon} className="node-preset-selector-cost-info-icon-src" alt="" />
          </div>
          <span className="node-preset-selector-cost-info-value">{numberConvertor(Number(estimatedCost))}</span>
          <QuestionMark announcement={NETWORK_ANNOUNCEMENT_ESTIMATED_COST} />
        </div>
      </div>
    </div>
  );
};
