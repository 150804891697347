import {
  confirmNetworkFromServer,
  createNetworkFromServer,
  deleteNetworkFromServer,
  getAlgorithmChainClientVersionListFromServer,
  getAlgorithmListFromServer,
  getChainClientListFromServer,
  getChainClientVersionFromServer,
  getChainClientVersionListFromServer,
  getMicrochainSettingFromServer,
  getNetworkAllocFromServer,
  getNetworkDetailFromServer,
  getNetworkListFromServer,
  getNetworkNodeSpecListFromServer,
  getNetworkPlanFromServer,
  getNetworkPlanListFromServer,
  getNetworkRejectReasonFromServer,
  getNetworkVPNFromServer,
  getProtocolChainClientListFromServer,
  getProtocolListFromServer,
  rejectNetworkFromServer,
  releaseNetworkFromServer,
  submitNetworkFromServer,
  updateNetworkFromServer,
} from "../server/API/networkAPI";
import { NodeSpec } from "../reducer/clientType/nodeClientType";
import { NodeSpecFromServer } from "../server/type/nodeType";
import {
  Algorithm,
  AlgorithmChainClientVersion,
  ChainClient,
  ChainClientVersion,
  Network,
  Protocol,
  ProtocolChainClient,
} from "reducer/clientType/networkClientType";

export const getNodeSpecListParser = async (): Promise<Array<NodeSpec>> => {
  const result = await getNetworkNodeSpecListFromServer();

  const response: Array<NodeSpec> = result.map((el: NodeSpecFromServer) => ({
    isRecommended: el.is_recommended,
    memory: el.memory,
    monthlyPrice: el.monthly_price,
    name: el.name,
    specTypeId: el.spec_type_id,
    tpsCover: el.tps_cover,
    type: el.type,
    vCpu: el.v_cpu,
    value: el.value,
  }));

  return response;
};

export const getNetworkPlanListParser = async (options?: { isPrivate: string }): Promise<Array<any>> => {
  const result = await getNetworkPlanListFromServer({
    query: {
      ...(options?.isPrivate && { isPrivate: JSON.parse(options.isPrivate) }),
    },
  });

  const response = result.map((el) => ({
    networkPlanId: el.network_plan_id,
    name: el.name,
    image: el.image,
    description: el.description,
    lightNodeCount: el.light_node_count,
    equalizerCount: el.equalizer_count,
    monthlyPrice: el.monthly_price,
    bonusCredit: el.bonus_credit,
    specTypeId: el.spec_type_id,
    createdAt: el.created_at,
  }));

  return response;
};

export const getNetworkPlanParser = async (networkPlanId: number): Promise<any> => {
  const result = await getNetworkPlanFromServer({
    params: {
      networkPlanId,
    },
  });

  return {
    networkPlanId: result.network_plan_id,
    name: result.name,
    image: result.image,
    description: result.description,
    lightNodeCount: result.light_node_count,
    equalizerCount: result.equalizer_count,
    monthlyPrice: result.monthly_price,
    bonusCredit: result.bonus_credit,
    specTypeId: result.spec_type_id,
    createdAt: result.created_at,
  };
};

export const getProtocolListParser = async (): Promise<Array<Protocol>> => {
  const result = await getProtocolListFromServer();
  const response = result.map((el) => ({
    protocolId: el.protocol_id,
    name: el.name,
    image: el.image,
    description: el.description,
    createdAt: el.created_at,
  }));
  return response;
};

export const getProtocolChainClientListParser = async (protocolId: number): Promise<Array<ProtocolChainClient>> => {
  const result = await getProtocolChainClientListFromServer({
    params: {
      protocolId,
    },
  });

  const response = result.map((el) => ({
    protocolId: el.protocol_id,
    chainClientId: el.chain_client_id,
    createdAt: el.created_at,
    chainClient: {
      chainClientId: el.chain_client.chain_client_id,
      name: el.chain_client.name,
      description: el.chain_client.description,
      link: el.chain_client.link,
      image: el.chain_client.image,
      isPrivate: el.chain_client.is_private,
      createdAt: el.chain_client.created_at,
    },
  }));
  return response;
};

export const getChainClientListParser = async (): Promise<Array<ChainClient>> => {
  const result = await getChainClientListFromServer();

  return result.map((el) => ({
    chainClientId: el.chain_client_id,
    name: el.name,
    image: el.image,
    link: el.link,
    description: el.description,
    isPrivate: el.is_private,
    createdAt: el.created_at,
  }));
};

export const getChainClientVersionParser = async (chainClientVersionId: number): Promise<ChainClientVersion> => {
  const result = await getChainClientVersionFromServer({
    params: {
      chainClientVersionId,
    },
  });

  return {
    chainClientVersionId: result.chain_client_version_id,
    chainClientId: result.chain_client_id,
    version: result.version,
    description: result.description,
    createdAt: result.created_at,
  };
};

export const getChainClientVersionListParser = async (chainClientId: number): Promise<Array<ChainClientVersion>> => {
  const result = await getChainClientVersionListFromServer({
    query: {
      chainClientId,
    },
  });

  return result.map((el) => ({
    chainClientVersionId: el.chain_client_version_id,
    chainClientId: el.chain_client_id,
    version: el.version,
    description: el.description,
    createdAt: el.created_at,
  }));
};

export const getAlgorithmChainClientVersionListParser = async (chainClientVersionId: number): Promise<Array<AlgorithmChainClientVersion>> => {
  const result = await getAlgorithmChainClientVersionListFromServer({
    params: {
      chainClientVersionId,
    },
  });

  return result.map((el) => ({
    algorithmId: el.algorithm_id,
    chainClientVersionId: el.chain_client_version_id,
    createdAt: el.created_at,
    algorithm: {
      algorithmId: el.algorithm.algorithm_id,
      name: el.algorithm.name,
      image: el.algorithm.image,
      description: el.algorithm.description,
      minimumValidatorCount: el.algorithm.minimum_validator_count,
      createdAt: el.algorithm.created_at,
    },
  }));
};

export const getAlgorithmListParser = async (): Promise<Array<Algorithm>> => {
  const result = await getAlgorithmListFromServer();

  return result.map((el) => ({
    algorithmId: el.algorithm_id,
    name: el.name,
    image: el.image,
    description: el.description,
    minimumValidatorCount: el.minimum_validator_count,
    createdAt: el.created_at,
  }));
};

export const getInteralNetworkListParser = async (options?: {
  networkIds?: Array<number>;
  projectId?: number;
  mainStatus?: number[];
}): Promise<Array<Network>> => {
  const result = await getNetworkListFromServer({
    query: {
      isInternalUse: true,
      ...(options?.networkIds && { networkIds: options.networkIds }),
      ...(options?.projectId && { projectId: options.projectId }),
      ...(options?.mainStatus && { mainStatus: options.mainStatus }),
    },
  });

  return result
    .map((el: any) => ({
      createdAt: el.created_at,
      id: el.id,
      isPrivate: el.is_private,
      label: el.label,
      mainStatus: el.main_status,
      mainMicroChainId: el.main_micro_chain_id,
      microChainCount: el.micro_chain_count,
      mainMicroChainFee: el.main_micro_chain_fee,
      mainMicroChainEqualizerCount: el.main_micro_chain_equalizer_count,
      mainMicroChainLightNodeCount: el.main_micro_chain_light_node_count,
      isTestnet: Boolean(el.main_micro_chain_is_testnet),
      projectId: el.project_id,
      subStatus: el.sub_status,
      updatedAt: el.updated_at,
      isContractUploadAvailability: Boolean(el.is_contract_upload_availability),
      isNodeParticipationAvailability: Boolean(el.is_node_participation_availability),
    }))
    .slice()
    .reverse();
};

export const getNetworkListParser = async (options?: { networkIds?: Array<number>; projectId?: number; mainStatus?: number[] }): Promise<Array<Network>> => {
  const innerNetwork = await getNetworkListFromServer({
    query: {
      isInternalUse: true,
      ...(options?.networkIds && { networkIds: options.networkIds }),
      ...(options?.projectId && { projectId: options.projectId }),
      ...(options?.mainStatus && { mainStatus: options.mainStatus }),
    },
  });

  const externalNetwork = await getNetworkListFromServer({
    query: {
      isInternalUse: true,
      ...(options?.networkIds && { networkIds: options.networkIds }),
      ...(options?.projectId && { projectId: options.projectId }),
      ...(options?.mainStatus && { mainStatus: options.mainStatus }),
      external: true,
    },
  });

  const allNetworks: any = [...externalNetwork, ...innerNetwork];
  return allNetworks
    .map((el: any) => ({
      createdAt: el.created_at,
      id: el.id,
      isPrivate: el.is_private,
      label: el.label,
      mainStatus: el.main_status,
      mainMicroChainId: el.main_micro_chain_id,
      microChainCount: el.micro_chain_count,
      mainMicroChainFee: el.main_micro_chain_fee,
      mainMicroChainEqualizerCount: el.main_micro_chain_equalizer_count,
      mainMicroChainLightNodeCount: el.main_micro_chain_light_node_count,
      isTestnet: Boolean(el.main_micro_chain_is_testnet),
      projectId: el.project_id,
      subStatus: el.sub_status,
      updatedAt: el.updated_at,
      isContractUploadAvailability: Boolean(el.is_contract_upload_availability),
      isNodeParticipationAvailability: Boolean(el.is_node_participation_availability),
    }))
    .slice()
    .reverse();
};

export const getNetworkAllocParser = async (networkId: number): Promise<any> => {
  const result = await getNetworkAllocFromServer({
    params: {
      networkId,
    },
  });

  const response = result.map((el: any) => {
    return {
      id: el.id,
      microChainId: el.micro_chain_id,
      name: el.name,
      address: el.address,
      keyStore: el.key_store,
      mmcBalance: el.mmc_balance,
      mmcStaking: el.mmc_staking,
      isValidator: el.is_validator,
    };
  });

  return response;
};

export const getMicroChainSettingParser = async (networkId: number): Promise<any> => {
  const result = await getMicrochainSettingFromServer({
    params: {
      networkId,
    },
  });

  const response = {
    microChainId: result.micro_chain_id,
    fee: result.fee,
    gasPrice: result.gas_price,
    inflationRate: result.inflation_rate,
    disInflationRate: result.dis_inflation_rate,
    proposerIncentiveRate: result.proposer_incentive_rate,
    fullNodeCount: result.full_node_count,
    lightNodeCount: result.light_node_count,
    equalizerCount: result.equalizer_count,
    initialSupply: result.initial_supply,
    specTypeId: result.spec_type_id,
    isTestnet: result.is_testnet,
    isContractUploadAvailability: result.is_contract_upload_availability,
    isNodeParticipationAvailability: result.is_node_participation_availability,
  };

  return response;
};

export const getNetworkRejectReasonParser = async (networkId: number): Promise<any> => {
  const result = await getNetworkRejectReasonFromServer({
    params: {
      networkId,
    },
  });

  const response = result.map((el: any) => {
    return {
      id: el.id,
      networkId: el.network_id,
      rejectReason: el.reject_reason,
    };
  });

  return response;
};

export const getNetworkDetailParser = async (networkId: number, options?: { paranoid: string }): Promise<any> => {
  const result = await getNetworkDetailFromServer({
    params: {
      networkId,
    },
    query: {
      isInternalUse: true,
      ...(options?.paranoid && { paranoid: JSON.parse(options.paranoid) }),
    },
  });

  const { network_detail: data } = result || {};

  const response = {
    createdAt: data.created_at,
    mainStatus: data.main_status,
    subStatus: data.sub_status,
    updatedAt: data.updated_at,
    network: {
      externalNetworkId: data.network.external_network_id,
      id: data.network.id,
      label: data.network.label,
      creatorId: data.network.creator_id,
      networkPlanId: data.network.network_plan_id,
    },
    microChain: {
      externalMicroChainId: data.micro_chain.external_micro_chain_id,
      host: data.micro_chain.host,
      isMain: data.micro_chain.is_main,
      label: data.micro_chain.label,
      loadBalancer: data.micro_chain.load_balancer,
      creatorId: data.micro_chain.creator_id,
      id: data.micro_chain.id,
      networkPlanId: data.micro_chain.network_plan_id,
      protocolId: data.micro_chain.protocol_id,
      chainClientId: data.micro_chain.chain_client_id,
      chainClientVersionId: data.micro_chain.chain_client_version_id,
      algorithmId: data.micro_chain.algorithm_id,
    },
    microChainSetting: {
      disInflationRate: data.micro_chain_setting.dis_inflation_rate,
      equalizerCount: data.micro_chain_setting.equalizer_count,
      fee: data.micro_chain_setting.fee,
      inflationRate: data.micro_chain_setting.inflation_rate,
      initialSupply: data.micro_chain_setting.initial_supply,
      isTestnet: data.micro_chain_setting.is_testnet,
      lightNodeCount: data.micro_chain_setting.light_node_count,
      specTypeId: data.micro_chain_setting.spec_type_id,
    },
  };

  return response;
};

export const createNetworkParser = async (network: any, microChain: any, microChainSetting: any, alloc: any, currency: any) => {
  const result = await createNetworkFromServer({
    data: {
      network,
      microChain,
      microChainSetting,
      alloc,
      currency,
    },
  });

  const response = {
    networkId: result.network_id,
    microChainId: result.micro_chain_id,
  };

  return response;
};

export const updateNetworkParser = async (networkId: number, network: any, microChain: any, microChainSetting: any, alloc: any, currency: any) => {
  const result = await updateNetworkFromServer({
    params: {
      networkId,
    },
    data: {
      network,
      microChain,
      microChainSetting,
      alloc,
      currency,
    },
  });

  return result;
};

export const submitNetworkParser = async (networkId: number) => {
  return await submitNetworkFromServer({
    params: {
      networkId,
    },
  });
};

export const releaseNetworkParser = async (networkId: number) => {
  return await releaseNetworkFromServer({
    params: {
      networkId,
    },
  });
};

export const deleteNetworkParser = async (networkId: number): Promise<any> => {
  return await deleteNetworkFromServer({
    params: {
      networkId,
    },
  });
};

export const getNetworkVPNParser = async (networkId: number): Promise<string> => {
  const result = await getNetworkVPNFromServer({
    params: {
      networkId,
    },
  });

  return result.url;
};

export const rejectNetworkParser = async (networkId: number, rejectReason: string): Promise<any> => {
  return await rejectNetworkFromServer({
    params: {
      networkId,
    },
    data: {
      rejectReason,
    },
  });
};

export const confirmNetworkParser = async (networkId: number): Promise<any> => {
  return await confirmNetworkFromServer({
    params: {
      networkId,
    },
  });
};
