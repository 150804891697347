import moment from "moment";
import { useMemo } from "react";
import ManageAirdropDistributionContainer from "../organism/ManageAirdropDistributionContainer";

const ManageAirdropBountyTemplate = (props) => {
  const { airdropId, airdropDetail, getAirdropDetail_ } = props || {};

  const bountyStatus = useMemo(() => {
    return airdropDetail.subStatus;
  }, [airdropDetail]);

  const airdropEnd = useMemo(() => {
    const currentTime = moment();
    return currentTime.isAfter(airdropDetail.participationPeriod.participationEndDate);
  }, [airdropDetail]);

  return (
    <>
      {bountyStatus < 3 && airdropEnd && (
        <ManageAirdropDistributionContainer airdropId={airdropId} airdropDetail={airdropDetail} getAirdropDetail_={getAirdropDetail_} />
      )}
    </>
  );
};

export default ManageAirdropBountyTemplate;
