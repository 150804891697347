import {
  VC__is_empty_value,
  VC__is_equal_or_less_than,
  VC__is_more_than,
  VC__is_not_URL,
  VC__is_text_exceed_limit,
} from "modules/validation/validationCondition";
import { commaRemovalConvertor } from "utils/convert.utils";

const OPERATION_PURPOSE__type = (value) => {
  if (!Boolean(value)) {
    return { success: false, message: "Please select an airdrop purpose" };
  }
  return { success: true };
};

export const validationInProjectPurpose = {
  operationPurposeType: (value) => {
    return OPERATION_PURPOSE__type(value);
  },
};

export function VALIDATION__AIRDROP_purpose_type(type) {
  return [
    {
      condition: !VC__is_empty_value(type),
      message: "Please select an airdrop purpose",
    },
  ];
}
//------------------------------------------------------------------------------------

export function VALIDATION__AIRDROP_project_name(name) {
  return [
    {
      condition: !VC__is_empty_value(name),
      message: "",
    },
    {
      condition: !VC__is_text_exceed_limit(name, 20),
      message: "Airdrop project name must be up to 20 characters long",
    },
  ];
}

export function VALIDATION__AIRDROP_project_description(description) {
  return [
    {
      condition: !VC__is_empty_value(description),
      message: "",
    },
    {
      condition: !VC__is_text_exceed_limit(description, 2000),
      message: "Airdrop description must be up to 2,000 characters long",
    },
  ];
}

export function VALIDATION__AIRDROP_project_image(image) {
  return [
    {
      condition: !VC__is_empty_value(image),
      message: "Project image must be set",
    },
  ];
}

export function VALIDATION__AIRDROP_project_links(links) {
  const linkCount = links.length;
  const isExistValueLinkCount = links.filter((el) => Boolean(el)).length;

  return [
    {
      condition: linkCount === isExistValueLinkCount,
      message: "Please enter the 'Project link'",
    },
    {
      condition: Boolean(!links.map((el) => VC__is_not_URL(el)).find((el) => el)),
      message: "Please check Project URL. \nProject URL should include the phrase 'http,ftp,https'",
    },
  ];
}

//------------------------------------------------------------------------------------
export function VALIDATION__AIRDROP_network(network) {
  return [
    {
      condition: !VC__is_empty_value(network),
      message: "",
    },
  ];
}

export function VALIDATION__AIRDROP_microchain(microchain) {
  return [
    {
      condition: !VC__is_empty_value(microchain),
      message: "",
    },
  ];
}

export function VALIDATION__AIRDROP_currency(currency) {
  return [
    {
      condition: !VC__is_empty_value(currency),
      message: "",
    },
  ];
}

export function VALIDATION__AIRDROP_budget(budget) {
  return [
    {
      condition: !VC__is_empty_value(budget),
      message: "",
    },
    {
      condition: !VC__is_equal_or_less_than(commaRemovalConvertor(budget), 0),
      message: "Enter a value of 1 or more",
    },
  ];
}

//------------------------------------------------------------------------------------

export function VALIDATION__AIRDROP_reward_fixed_amount(rewardAmountList, depositAmount) {
  return [
    {
      condition: !VC__is_empty_value(rewardAmountList[0].amount),
      message: "",
    },
    {
      condition: !VC__is_equal_or_less_than(rewardAmountList[0].amount, 0),
      message: "Enter a value of 1 or more",
    },
    {
      condition: !VC__is_more_than(commaRemovalConvertor(rewardAmountList[0].amount), commaRemovalConvertor(depositAmount)),
      message: "Reward amount must be less than budget",
    },
  ];
}

export function VALIDATION__AIRDROP_fixed_period_of_time(startDate, endDate) {
  return [
    {
      condition: !VC__is_empty_value(startDate),
      message: "",
    },
    {
      condition: !VC__is_empty_value(endDate),
      message: "",
    },
    {
      condition: startDate < endDate,
      message: "Please fix end date. End date can't be earlier than start date",
    },
  ];
}

export function VALIDATION__AIRDROP_events_title(events) {
  return [
    {
      condition: events.reduce((acc, cur) => acc && Boolean(cur.title), true),
      message: "",
    },
  ];
}

export function VALIDATION__AIRDROP_events_result(events) {
  return [
    {
      condition: events.reduce((acc, cur) => acc && Boolean(cur.placeholder), true),
      message: "",
    },
  ];
}

export function VALIDATION__AIRDROP_limit_participation_count(count) {
  return [
    {
      condition: !VC__is_empty_value(count),
      message: "",
    },
  ];
}

export function VALIDATION__AIRDROP_limit_participation_time(time) {
  return [
    {
      condition: !VC__is_empty_value(time),
      message: "Please select a time",
    },
  ];
}

//------------------------------------------------------------------------------------
