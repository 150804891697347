import { v1ServerByProjectAuth } from "server/index/serverInstances";
import {
  getMyProjectNetworkListRequest,
  getMyProjectNetworkListResponse,
} from "./index.type";

const getMyProjectNetworkList = async (
  request: getMyProjectNetworkListRequest
): Promise<getMyProjectNetworkListResponse> =>
  await v1ServerByProjectAuth.get({
    path: `/projects/networks`,
  });

export { getMyProjectNetworkList as getMyProjectNetworkListFromServer };
