import {
  VC__is_empty_value,
  VC__is_not_URL,
  VC__is_not_address,
  VC__is_not_capital_letter,
  VC__is_text_exceed_limit,
} from "modules/validation/validationCondition";

export function VALIDATION__NFT_project_name(name) {
  return [
    {
      condition: !VC__is_empty_value(name),
      message: "",
    },
    {
      condition: !VC__is_text_exceed_limit(name, 20),
      message: "NFT project name must be up to 20 characters long",
    },
  ];
}

export function VALIDATION__NFT_project_symbol(symbol) {
  return [
    {
      condition: !VC__is_empty_value(symbol),
      message: "",
    },
    {
      condition: !VC__is_not_capital_letter(symbol),
      message: "Enter no more than 10 characters, including English capital letters and spaces",
    },
    {
      condition: !VC__is_text_exceed_limit(symbol, 10),
      message: "Enter no more than 10 characters, including English capital letters and spaces",
    },
  ];
}

export function VALIDATION__NFT_project_description(description) {
  return [
    {
      condition: !VC__is_empty_value(description),
      message: "Please enter 'Project description'",
    },
    {
      condition: !VC__is_text_exceed_limit(description, 2000),
      message: "NFT description must be up to 2,000 characters long",
    },
  ];
}

export function VALIDATION__NFT_project_image(image) {
  return [
    {
      condition: !VC__is_empty_value(image),
      message: "Project image must be set",
    },
  ];
}

export function VALIDATION__NFT_project_links(links) {
  const linkCount = links.length;
  const isExistValueLinkCount = links.filter((el) => Boolean(el)).length;

  return [
    {
      condition: linkCount === isExistValueLinkCount,
      message: "Please enter the 'Project link'",
    },
    {
      condition: Boolean(!links.map((el) => VC__is_not_URL(el)).find((el) => el)),
      message: "Please check Project URL. \nProject URL should include the phrase 'http,ftp,https'",
    },
  ];
}

//------------------------------------------------------------------------------------

export function VALIDATION__NFT_name(name) {
  return [
    {
      condition: !VC__is_empty_value(name),
      message: "",
    },
    {
      condition: !VC__is_text_exceed_limit(name, 20),
      message: "NFT name must be up to 20 characters long",
    },
  ];
}

export function VALIDATION__NFT_description(description) {
  return [
    {
      condition: !VC__is_empty_value(description),
      message: "Please enter 'Project description'",
    },
    {
      condition: !VC__is_text_exceed_limit(description, 2000),
      message: "NFT description must be up to 2,000 characters long",
    },
  ];
}

export function VALIDATION__NFT_recipient(address) {
  return [
    {
      condition: !VC__is_empty_value(address),
      message: "",
    },
    {
      condition: !VC__is_not_address(address),
      message: "Address must start with ‘0x’ and be 42 characters long",
    },
  ];
}
