import IconDeleteRed from "../../../../assets/images/atom/icon_delete_red.png";
import EQConnectWalletButton from "../../../../../modules/EQConnect/EQConnectWalletButton";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { connectInfo } from "../../../../../store/redux/EQconnect/EQConnectSlice";
import { errorAlert } from "../../../../../utils/Utils";
import { makeTransactionForUploadContractAction } from "../../../../../action/requestAction";
import toast from "react-hot-toast";
import { project_access } from "reducer/projectReducer";
import { create721ContractAction, deleteNFTProjectAction } from "action/nftAction";
import BasicButton from "view/components/_atom/button/BasicButton";
import { ButtonSize, ButtonTheme } from "view/components/_atom/button/StyledButton";
import ButtonContainer from "view/components/_atom/container/ButtonContainer";
import useTransaction from "../../../../../hooks/useTransaction";

const ManageNftProjectBeforeDeploy = ({ projectDetail, setProjectDetail, getNFTProjectDetail }) => {
  const navigate = useNavigate();
  const { id: nftProjectId } = useParams();

  //========= Delete NFT Project Detail =========//
  const deleteNFTProject_ = async () => {
    const { result, error } = await deleteNFTProjectAction(nftProjectId);
    if (result) {
      toast.success("processed successfully");
      navigate("/application/nft/manage");
    }
    if (error) {
      errorAlert(error.data.message);
    }
  };

  //========= Edit NFT Project Detail =========//
  const navigateToEdit = () => {
    navigate("edit");
  };

  //========= Deploy NFT Project Detail =========//
  const { address } = useSelector(connectInfo);
  const token = useSelector(project_access);

  const deploy721Contract = async (projectDetail) => {
    return makeTransactionForUploadContractAction({
      address: address,
      contractId: projectDetail.contractId,
      microChainId: projectDetail.microChainId,
      parameters: [projectDetail.projectName, projectDetail.symbol],
      to: null,
      value: null,
      transactionPath: `${process.env.REACT_APP_HUB_SERVER_URL}${process.env.REACT_APP_API_VERSION_V2}wallet-manage/erc721-temps/${projectDetail.projectId}/release-contract`,
      token,
      additionalQuery: {
        body: {
          contractId: projectDetail.contractId,
        },
      },
    });
  };

  const onClickDeploy = async () => {
    if (projectDetail.mainStatus === 1) {
      const { result, error } = await create721ContractAction(nftProjectId);
      if (result) {
        setProjectDetail(result);
        return await deploy721Contract(result);
      }
      if (error) {
        errorAlert(error.data.message);
      }
    } else {
      return await deploy721Contract(projectDetail);
    }
  };

  const { requestTransaction } = useTransaction(onClickDeploy, getNFTProjectDetail);

  //========= UI =========//

  return (
    <div className="flex justify-content-flex-end">
      <ButtonContainer>
        <BasicButton size={ButtonSize.ICON} theme={ButtonTheme.MINOR} onClick={() => deleteNFTProject_()}>
          <img src={IconDeleteRed} style={{ width: 18, objectFit: "contain" }} alt={""} />
        </BasicButton>
        {projectDetail.mainStatus === 1 && (
          <BasicButton size={ButtonSize.LARGE} theme={ButtonTheme.SECONDARY} onClick={() => navigateToEdit()}>
            Edit
          </BasicButton>
        )}
        <EQConnectWalletButton />
        <BasicButton size={ButtonSize.LARGE} disabled={!address} onClick={() => requestTransaction()}>
          Deploy
        </BasicButton>
        {/*<RequestTransactionButton className={"main"} text={"Deploy"} disabled={!address} onRequest={() => onClickDeploy()} onSuccess={() => onSuccess()} />*/}
      </ButtonContainer>
    </div>
  );
};

export default ManageNftProjectBeforeDeploy;
