import css from "./BasicInput.scss";
import { InputHTMLAttributes } from "react";
import { bindStyle } from "../../../styles/classNames";

export type BasicInputProps = InputHTMLAttributes<HTMLInputElement> & {
  elementSize?: "DEFAULT";
  style?: object;
};

const BasicInput = ({ elementSize = "DEFAULT", style, ...inputAttributeProps }: BasicInputProps) => {
  const cn = bindStyle(css);
  return <input className={cn("basic-input", elementSize.toLocaleLowerCase())} style={style && style} {...inputAttributeProps} />;
};

export default BasicInput;
