import './DashboardMethodSelectWrapper.scss';

const DashboardMethodSelectWrapper = (
  {
    title,
    count,
    description,
    methods,
    selectedMethod,
    setMethodState
  }) => {

  const isSelectedMethod = (selectedMethod, index) => {
    return methods[index] === selectedMethod
  }

  return (
    <div className='dashboard-method-select-wrapper'>
      <div className="dashboard-method-select-wrapper-header">
        <div className="dashboard-method-select-wrapper-header-title">
          {`${title} (${count})`}
        </div>
        <div className="dashboard-method-select-wrapper-header-description">
          {description}
        </div>
      </div>
      <div className="dashboard-method-select-wrapper-method-box">
        {methods.length !== 0 ?
          methods.map((mth, index) => {
            return (
              <div key={index}
                   className={`dashboard-method-select-wrapper-method-box-row ${isSelectedMethod(selectedMethod, index) ? 'selected' : ''}`}
                   onClick={() => !isSelectedMethod(selectedMethod, index) ? setMethodState(prev => ({
                     ...prev,
                     selectedMethod: mth
                   })) : undefined}>
                {mth.name}
              </div>
            )
          }) :
          <div style={{width: '100%', height: '100%'}} className={'flex justify-content-center align-items-center'}>
            <span style={{textAlign: 'center', fontWeight: 500}}>{`No ${title} in this contract`}</span>
          </div>
        }
      </div>
    </div>
  )
};

export default DashboardMethodSelectWrapper;
