import React from "react";
import "./AirdropProjectPurposeSetting.scss";
import { AIRDROP_OPERATION_PURPOSE_LIST } from "../../constants/airdropConstants";
import settingIcon from "../../../../../assets/images/icon-common-setting.png";
import AirdropOperatingWayPurposeList from "../molecule/AirdropOperatingWayPurposeList";
import { VALIDATION__AIRDROP_purpose_type } from "../../validation/airdropValidation";
import BasicButton from "view/components/_atom/button/BasicButton";
import { ButtonSize } from "view/components/_atom/button/StyledButton";
import useValidateCondition from "hooks/useValidateCondition";
import useIsAble from "hooks/useIsAble";
import { FieldContainer } from "view/components/_atom/section/Field";
import airdropIcon from "../../../../../assets/images/service/Icon_SNB_airdrop.png";

const AirdropProjectPurposeSetting = (props) => {
  const { airdropData, setCurrentStep, onClickTypeButton } = props || {};

  const purposeTypeValidation = useValidateCondition([airdropData.operationPurpose], VALIDATION__AIRDROP_purpose_type(airdropData.operationPurpose));

  const isAble = useIsAble([purposeTypeValidation.isValid]);

  const onClickNextButton = () => {
    setCurrentStep((prevState) => prevState + 1);
  };

  return (
    <>
      {/* Project Purpose Description :: "Set the purpose of the airdrop project. \nWhen you set the purpose, the information about operating way is automatically set accordingly." */}
      <FieldContainer icon={airdropIcon} title="Project Purpose">
        <div>
          <div className="airdrop-project-purpose-setting-header">
            <img src={settingIcon} className="airdrop-project-purpose-setting-header-icon" alt="" />
            <span className="airdrop-project-purpose-setting-header-title">Operation purpose</span>
          </div>
          <AirdropOperatingWayPurposeList
            recommendationList={AIRDROP_OPERATION_PURPOSE_LIST}
            type={airdropData.operationPurpose}
            onClickTypeButton={onClickTypeButton}
          />
        </div>
      </FieldContainer>
      <div className="flex justify-content-flex-end">
        <BasicButton size={ButtonSize.LARGE} disabled={!isAble} onClick={() => onClickNextButton()}>
          Next
        </BasicButton>
      </div>
    </>
  );
};

export default AirdropProjectPurposeSetting;
