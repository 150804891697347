import { useEffect, useMemo, useState } from "react";
import { CONTRACT_DISCLOSURE, CONTRACT_ERC, CONTRACT_TYPE, UPDATE_VERSION_VALIDATE_CONDITION } from "../constants/contract.data";
import useInputValidation from "../../../../../hooks/useInputValidation";
import { useSelector } from "react-redux";
import PageLoading from "../../../../components/_atom/loading/PageLoading";
import ContractConstructorViewer from "../organism/ContractConstructorViewer";
import ContractMethodViewer from "../organism/ContractMethodViewer";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import TextArea from "../../../../../legacy-common/atom/textarea/TextArea";
import { errorAlert, onChangeInputHandler } from "../../../../../utils/Utils";
import Select from "../../../../components/_atom/select/Select";
import RadioButtons from "../../../../../legacy-common/molcules/RadioButtons/RadioButtons";
import StatusDetail, { DEFAULT_LABEL_STYLE } from "../../../../components/_atom/label/StatusDetail";
import toast from "react-hot-toast";
import ContractCodeViewer from "../organism/ContractCodeViewer";
import ManageStoredContractButtonHandler from "../molcules/ManageStoredContractButtonHandler";
import { selected_project_id } from "reducer/projectReducer";
import {
  acceptContractVersionAction,
  deleteContractVersionAction,
  getContractVersionDetailAction,
  publishContractAction,
  rejectContractVersionAction,
  submitContractVersionAction,
  updateContractVersionAction,
  withdrawContractAction,
} from "../../../../../action/contractLibraryAction";
import { generateABiFile, unionABI } from "../constants/contract.utils";
import PageArticle from "../../../../components/_template/page/PageArticle";
import PageTitle from "../../../../components/_molecules/page/PageTitle";
import ButtonContainer from "view/components/_atom/container/ButtonContainer";
import { ManageFieldContainer, ManageFieldLabel, RowManageField } from "view/components/_atom/section/ManageField";
import contractIcon from "../../../../assets/images/service/Icon_SNB_contract_library.png";
import BasicInput from "view/components/_atom/input/BasicInput";

const ManageStoredContract = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { contractId, versionId } = useParams();
  const projectId = useSelector(selected_project_id);

  const [isLoading, setIsLoading] = useState(true);
  const [isEditMode, setIsEditMode] = useState(false);

  const [contractDetail, setContractDetail] = useState({});
  const [versionDetail, setVersionDetail] = useState({});
  const [selectedVersion, setSelectedVersion] = useState(parseInt(versionId));
  const [contractDisclosure, setContractDisclosure] = useState(0);

  const [contractConstructor, setContractConstructor] = useState({});
  const [contractFunctions, setContractFunctions] = useState([]);
  const [contractEvents, setContractEvents] = useState([]);

  const { inputValidationStatus, setInputValidateStatusInitial } = useInputValidation(UPDATE_VERSION_VALIDATE_CONDITION);

  const setVersionDataFromServer = async () => {
    const { error, result } = await getContractVersionDetailAction(Number(contractId), Number(versionId));
    if (error) {
      toast.error(error.data.message);
      navigate("/developer/contract/storage");
    } else {
      const { contractDetail, versionDetail } = result;
      setContractDetail(contractDetail);
      setVersionDetail(versionDetail);
      setContractDisclosure(versionDetail.isPrivate);
      setContractConstructor(versionDetail.contractConstructor);
      setContractFunctions(versionDetail.contractFunctions);
      setContractEvents(versionDetail.contractEvents);
      setIsLoading(false);
    }
  };

  const onClickSaveContract = async () => {
    const abi = unionABI([contractConstructor, contractFunctions, contractEvents]);
    const updatedCustomAbiFile = generateABiFile(abi, "updateAbi");
    const { result, error } = await updateContractVersionAction(
      Number(contractId),
      Number(versionId),
      contractDisclosure,
      contractDetail.description,
      versionDetail.versionDescription,
      updatedCustomAbiFile
    );
    if (error) {
      errorAlert(error.data.message);
    } else {
      setVersionDetail(result);
      setContractDisclosure(result.isPrivate);
      setContractConstructor(result.contractConstructor);
      setContractFunctions(result.contractFunctions);
      setContractEvents(result.contractEvents);
      setIsEditMode(false);
    }
  };

  const onClickSubmitContract = async () => {
    const { error } = await submitContractVersionAction(Number(contractId), Number(versionId));
    if (error) {
      errorAlert(error.data.message);
    } else {
      navigate("/developer/contract/storage");
    }
  };

  const onClickDeleteContract = async () => {
    const { error, result } = await deleteContractVersionAction(Number(contractId), Number(versionId));
    if (error) {
      errorAlert(error.data.message);
    } else {
      navigate("/developer/contract/storage");
    }
  };

  const onClickConfirmContract = async () => {
    const { error } = await acceptContractVersionAction(Number(contractId), Number(versionId));
    if (error) {
      errorAlert(error.data.message);
    } else {
      navigate(-1);
    }
  };

  const rejectContract = async (reason) => {
    const { error } = await rejectContractVersionAction(Number(contractId), Number(versionId), reason);
    if (error) {
      errorAlert(error.data.message);
    } else {
      navigate("/developer/contract/storage");
    }
  };

  const onClickPublishContract = async () => {
    const { error } = await publishContractAction(Number(contractId), Number(versionId));
    if (error) {
      errorAlert(error.data.message);
    } else {
      navigate("/developer/contract/storage");
    }
  };

  const onClickWithdrawContract = async () => {
    const { error } = await withdrawContractAction(Number(contractId), Number(versionId));
    if (error) {
      errorAlert(error.data.message);
    } else {
      navigate("/developer/contract/storage");
    }
  };

  const isAbleToUpgradeVersion = useMemo(() => {
    if (Object.keys(contractDetail).length !== 0) {
      return !Boolean(contractDetail.contractVersions.find((version) => version.status < 3));
    }
  }, [contractDetail]);

  const statusParser = useMemo(() => {
    switch (versionDetail?.status) {
      case 3:
        return 1;
      case 4:
        return 5;
      default:
        return versionDetail?.status;
    }
  }, [versionDetail]);

  const statusData = useMemo(() => {
    switch (versionDetail.status) {
      case 0:
        return {
          text: DEFAULT_LABEL_STYLE.find((el) => el.status === statusParser).text,
          description: versionDetail.contractVersionRejectDetail?.rejectReason,
        };
      case 3:
        return {
          text: "Unpublished",
          description: "The contract is currently private.",
        };
      case 4:
        return {
          text: "Published",
          description: "Contract has been update to contract library",
        };
      default:
        return {
          text: DEFAULT_LABEL_STYLE.find((el) => el.status === statusParser)?.text,
          description: DEFAULT_LABEL_STYLE.find((el) => el.status === statusParser)?.description,
        };
    }
  }, [versionDetail, statusParser]);

  useEffect(() => {
    setVersionDataFromServer();
  }, [location.pathname]);

  useEffect(() => {
    if (selectedVersion !== parseInt(versionId)) {
      navigate(`/developer/contract/storage/${contractId}/version/${selectedVersion}`);
    }
  }, [selectedVersion]);

  if (isLoading) {
    return <PageLoading />;
  }

  return (
    <PageArticle>
      <PageTitle title={"Contract Detail"} size={1080} />
      <div className="page-layout-1080">
        <StatusDetail status={statusParser} data={statusData} />
        <ManageFieldContainer icon={contractIcon} title="Contract Icon">
          <RowManageField>
            <ManageFieldLabel label="Contract title" />
            <span>{contractDetail?.title ?? "-"}</span>
          </RowManageField>
          <RowManageField>
            <ManageFieldLabel label="Contract ID" />
            <span>{contractDetail?.contractId ?? "-"}</span>
          </RowManageField>
          <RowManageField>
            <ManageFieldLabel label="Contract type" />
            <span>{CONTRACT_TYPE.find((el) => el.value === contractDetail?.type)?.label ?? "-"}</span>
          </RowManageField>
          <RowManageField>
            <ManageFieldLabel label="ERC" />
            <span>{CONTRACT_ERC.find((el) => el.value === contractDetail?.ercType).label ?? "-"}</span>
          </RowManageField>
          <RowManageField>
            <ManageFieldLabel label="Description" />
            {isEditMode ? (
              <BasicInput
                name={"description"}
                value={contractDetail?.description}
                onFocus={(e) => setInputValidateStatusInitial(e)}
                comment={inputValidationStatus["description"]?.comment}
                onChange={(e) => onChangeInputHandler(setContractDetail, "description", e.target.value)}
                placeholder={"Enter the description of Contract. (Ex. Contract)"}
              />
            ) : (
              <span>{contractDetail?.description}</span>
            )}
          </RowManageField>
        </ManageFieldContainer>
        <ManageFieldContainer icon={contractIcon} title="Version Info">
          <RowManageField>
            <ManageFieldLabel label="Contract version" />
            <Select
              labelKey={"version"}
              valueKey={"contractVersionId"}
              options={contractDetail.contractVersions}
              selectedValue={selectedVersion}
              setSelectedValue={setSelectedVersion}
              style={{ height: !isEditMode ? "fit-content" : "auto" }}
              disabled={isEditMode}
            />
          </RowManageField>
          <RowManageField>
            <ManageFieldLabel label="Public / Private" />
            {isEditMode ? (
              <RadioButtons options={CONTRACT_DISCLOSURE} readOnly={!isEditMode} selectedValue={contractDisclosure} onChange={setContractDisclosure} />
            ) : (
              CONTRACT_DISCLOSURE.find((el) => el.value === contractDisclosure)?.label
            )}
          </RowManageField>
          <RowManageField>
            <ManageFieldLabel label="Version description" />
            {isEditMode ? (
              <TextArea
                name={"versionDescription"}
                value={versionDetail.versionDescription}
                onFocus={(e) => setInputValidateStatusInitial(e)}
                comment={inputValidationStatus["versionDescription"]?.comment}
                onChange={(e) => onChangeInputHandler(setVersionDetail, "versionDescription", e.target.value)}
                readOnly={!isEditMode}
                placeholder={"Enter the description of version."}
                rows={5}
              />
            ) : (
              <span>{versionDetail?.versionDescription}</span>
            )}
          </RowManageField>
        </ManageFieldContainer>
        <ContractCodeViewer codeDetail={versionDetail.contractVersionCode} />
        {contractConstructor && contractConstructor.inputs.length !== 0 && (
          <ContractConstructorViewer constructorState={{ contractConstructor, setContractConstructor }} disabled={!isEditMode} />
        )}
        {contractFunctions.length !== 0 && (
          <ContractMethodViewer label={"Functions"} methodState={{ methods: contractFunctions, setMethods: setContractFunctions }} disabled={!isEditMode} />
        )}
        {contractEvents.length !== 0 && (
          <ContractMethodViewer label={"Events"} methodState={{ methods: contractEvents, setMethods: setContractEvents }} disabled={!isEditMode} />
        )}
        <div className="flex justify-content-flex-end">
          <ButtonContainer>
            <ManageStoredContractButtonHandler
              status={versionDetail.status}
              isPrivate={versionDetail.is_private}
              projectId={projectId}
              contractId={contractId}
              versionId={versionId}
              contractDetail={contractDetail}
              isAbleToUpgradeVersion={isAbleToUpgradeVersion}
              isEditModeStatus={{ isEditMode, setIsEditMode }}
              onClickSubmit={onClickSubmitContract}
              onClickSave={onClickSaveContract}
              onClickDelete={onClickDeleteContract}
              onClickConfirm={onClickConfirmContract}
              onClickPublish={onClickPublishContract}
              onClickWithdraw={onClickWithdrawContract}
            />
          </ButtonContainer>
        </div>
      </div>
    </PageArticle>
  );
};

export default ManageStoredContract;
