import { parseAction } from "./util/parseAction";
import {
  confirmNodeParser,
  createNodeParser,
  deleteNodeParser,
  deployNodeParser,
  getNodeDetailParser,
  getNodeListParser,
  getNodeRejectReasonParser,
  rejectNodeParser,
  submitNodeParser,
} from "../parser/nodeParser";
import _ from "lodash";
import { getMicroChainListParser, getNetworkLabelAndMicroChainLabelParser } from "../parser/microChainParser";
import { getChainClientVersionParser, getMicroChainSettingParser, getNodeSpecListParser } from "../parser/networkParser";
import { networkParserNew } from "utils/Utils";
import { getMicroChainIdsOfProjectParser } from "parser/projectParser";

export const initialDataFromCreateNodeAction = () =>
  parseAction(async () => {
    const registeredStatus = [5];
    let microChainList: Array<any> = [];
    const microChainIdsOfProject = await getMicroChainIdsOfProjectParser();
    if (microChainIdsOfProject.length !== 0) {
      microChainList = await getMicroChainListParser({ microChainIds: microChainIdsOfProject, mainStatus: registeredStatus });
    }

    const networkList = networkParserNew(microChainList);

    const response = {
      networkList,
      microChainList,
    };

    return response;
  });

export const getNetworkNodeSpecAction = (networkId: number) =>
  parseAction(async () => {
    return await getMicroChainSettingParser(networkId);
  });

export const getNodeListAction = (options?: { projectId?: number; mainStatus?: Array<number>; microChainId?: number }) =>
  parseAction(async () => {
    const nodeList = await getNodeListParser(options);
    const micrChainIds: Array<number> = _.uniq(nodeList.map((el: any) => el.microChainId));

    const chainLabelList = await Promise.all(
      micrChainIds.map(async (id: number) => {
        return await getNetworkLabelAndMicroChainLabelParser(id);
      })
    );

    const response = nodeList.map((el: any) => {
      const chainData = chainLabelList.find((chain: any) => chain.microChainId === el.microChainId);
      return { ...el, ...chainData };
    });

    return response;
  });

export const createNodeAction = (networkId: number, microChainId: number, specTypeId: number, nodes: []) =>
  parseAction(async () => {
    return await createNodeParser(networkId, microChainId, specTypeId, nodes);
  });

export const getNodeDetailAction = (nodeId: number) =>
  parseAction(async () => {
    const [nodeDetail, nodeSpecList] = await Promise.all([getNodeDetailParser(nodeId), getNodeSpecListParser()]);

    const { microChainId } = nodeDetail || {};

    const chainClientVersionDetail = await getChainClientVersionParser(nodeDetail.chainClientVersionId);
    const { version } = chainClientVersionDetail || {};
    const chainData = await getNetworkLabelAndMicroChainLabelParser(microChainId);

    const nodeSpec = nodeSpecList.find((node: any) => node.specTypeId === nodeDetail.specTypeId);
    const { name: nodeSpecLabel, monthlyPrice } = nodeSpec || {};

    let rejectReason: string = "";
    if (nodeDetail.mainStatus === 0) {
      const rejectResult = await getNodeRejectReasonParser(nodeId);
      rejectReason = rejectResult.rejectReason;
    }

    const response = { ...nodeDetail, nodeSpecLabel, monthlyPrice, ...chainData, version, ...(rejectReason && { rejectReason }) };

    return response;
  });

export const submitNodeAction = (nodeId: number) =>
  parseAction(async () => {
    const result = await submitNodeParser(nodeId);

    const [nodeDetail, nodeSpecList] = await Promise.all([getNodeDetailParser(nodeId), getNodeSpecListParser()]);

    const { microChainId } = nodeDetail || {};

    const chainData = await getNetworkLabelAndMicroChainLabelParser(microChainId);

    const nodeSpec = nodeSpecList.find((node: any) => node.specTypeId === nodeDetail.specTypeId);
    const { name: nodeSpecLabel, monthlyPrice } = nodeSpec || {};

    const response = { ...nodeDetail, nodeSpecLabel, monthlyPrice, ...chainData };

    return response;
  });

export const rejectNodeAction = (nodeId: number, rejectReason: string) =>
  parseAction(async () => {
    const result = await rejectNodeParser(nodeId, rejectReason);

    const response = {
      address: result.address,
      health: result.health,
      id: result.id,
      ip: result.ip,
      mainStatus: result.main_status,
      microChainId: result.micro_chain_id,
      ownerId: result.owner_id,
      requestId: result.request_id,
      subStatus: result.sub_status,
      type: result.type,
    };

    return response;
  });

export const confirmNodeAction = (projectId: number, nodeId: number) =>
  parseAction(async () => {
    const result = await confirmNodeParser(projectId, nodeId);

    const response = {
      address: result.address,
      health: result.health,
      id: result.id,
      ip: result.ip,
      mainStatus: result.main_status,
      microChainId: result.micro_chain_id,
      ownerId: result.owner_id,
      requestId: result.request_id,
      subStatus: result.sub_status,
      type: result.type,
    };

    return response;
  });

export const deployNodeAction = (nodeId: number) =>
  parseAction(async () => {
    const result = await deployNodeParser(nodeId);

    const response = {
      address: result.address,
      health: result.health,
      id: result.id,
      ip: result.ip,
      mainStatus: result.main_status,
      microChainId: result.micro_chain_id,
      ownerId: result.owner_id,
      requestId: result.request_id,
      subStatus: result.sub_status,
      type: result.type,
    };

    return response;
  });

export const deleteNodeAction = (nodeId: number) =>
  parseAction(async () => {
    const deleteResult = await deleteNodeParser(nodeId);

    return deleteResult;
  });
