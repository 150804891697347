import React from "react";
import checkIcon from "../assets/icon_dashboard_plans_check.png";
import css from "./PricingPlan.scss";
import { numberConvertor } from "utils/convert.utils";
import useGetSelectedProject from "hooks/useGetSelectedProject";
import { bindStyle } from "view/styles/classNames";

type PricingPlanProps = any;

const PricingPlan = (data: PricingPlanProps) => {
  const cn = bindStyle(css);
  const { selectedProject } = useGetSelectedProject();

  const buttonLabel = (planId: number): string => {
    if (planId === selectedProject.pricingPlanId) {
      return "Current Plan";
    }
    return "Select";
  };

  return (
    <div className="pricing-plan">
      <div className="pricing-plan-top">
        <div className="pricing-plan-top-description">{data.description}</div>
        <div>
          <p className="pricing-plan-top-name">{data.name}</p>
          <div className="pricing-plan-top-price">
            <div className="pricing-plan-top-price-image-wrapper">
              <img className="pricing-plan-top-price-image" src={data.image} alt="" />
            </div>
            <span className="pricing-plan-top-price-text">{numberConvertor(Number(data.defaultUsageCredit))}</span>
          </div>
          <p className="pricing-plan-top-price-sub-text">/ month</p>
        </div>
        {data.monthlyPrice > 0 ? (
          <p className="pricing-plan-top-dollar">$ {numberConvertor(Number(data.monthlyPrice))} / month</p>
        ) : (
          <p className="pricing-plan-top-dollar">Free</p>
        )}
        <div className={cn("pricing-plan-top-button", { selected: data.pricingPlanId === selectedProject.pricingPlanId })}>
          {buttonLabel(data.pricingPlanId)}
        </div>
      </div>
      <div className="pricing-plan-bottom">
        <p className="pricing-plan-bottom-title">{`${data.name} Plan feature`}</p>
        {data.features.map((el) => {
          return (
            <div className="pricing-plan-bottom-item" key={`plan-feature-${el.featureId}`}>
              <img className="pricing-plan-bottom-item-image" src={checkIcon} alt="" />
              {el.name}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PricingPlan;
