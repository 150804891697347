import {RefObject, useEffect} from 'react';

const useOuterClick = <T extends HTMLElement>(domRef: RefObject<T>, reference: Array<RefObject<HTMLElement>>, eventHandler: Function) => {

  const isIncludeReference = e => (acc, cur) => {
    return !!acc && !cur.current.contains(e.target)
  }

  const handler = (e) => {
    if(domRef.current){
      if(!domRef.current.contains(e.target) && reference.reduce(isIncludeReference(e),true)){
        eventHandler();
      }
    }
  }
  useEffect(() => {
    window.addEventListener('click', handler)
    return () => window.removeEventListener('click', handler);
  }, [])
};

export default useOuterClick;
