import {
  confirmNodeFromServer,
  createNodeFromServer,
  deleteNodeFromServer,
  deployNodeFromServer,
  getNodeDetailFromServer,
  getNodeListFromServer,
  getNodeRejectReasonFromServer,
  rejectNodeFromServer,
  submitNodeFromServer,
} from "../server/API/nodeAPI";

export const getNodeListParser = async (options?: { projectId?: number; mainStatus?: number[]; microChainId?: number }): Promise<any> => {
  const result = await getNodeListFromServer({
    query: {
      ...(options?.projectId && { projectId: options.projectId }),
      ...(options?.mainStatus && { mainStatus: options.mainStatus }),
      ...(options?.microChainId && { microChainId: options.microChainId }),
    },
  });

  const { nodes } = result || {};

  const response = nodes
    .map((el: any) => {
      let typeLabel: string = "";
      if (el.type === 1) {
        typeLabel = "Consensus Node";
      }
      if (el.type === 2) {
        typeLabel = "Full Node";
      }

      return {
        address: el.address,
        health: el.health,
        id: el.id,
        ip: el.ip,
        mainStatus: el.main_status,
        microChainId: el.micro_chain_id,
        projectId: el.project_id,
        requestId: el.request_id,
        subStatus: el.sub_status,
        type: el.type,
        typeLabel,
      };
    })
    .slice()
    .reverse();

  return response;
};

export const createNodeParser = async (networkId: number, microChainId: number, specTypeId: number, nodes: []): Promise<any> => {
  const result = await createNodeFromServer({
    data: {
      networkId,
      microChainId,
      specTypeId,
      nodes,
    },
  });

  const response = result.map((el: any) => ({
    addres: el.address,
    health: el.health,
    id: el.id,
    ip: el.ip,
    mainStatus: el.main_status,
    microChainId: el.micro_chain_id,
    ownerId: el.owner_id,
    requestId: el.request_id,
    subStatus: el.sub_status,
    type: el.type,
  }));

  return response;
};

export const getNodeDetailParser = async (nodeId: number): Promise<any> => {
  const result = await getNodeDetailFromServer({
    params: {
      nodeId,
    },
  });

  const response = {
    address: result.address,
    chainClientVersionId: result.chain_client_version_id,
    health: result.health,
    id: result.id,
    ip: result.ip,
    mainStatus: result.main_status,
    microChainId: result.micro_chain_id,
    specTypeId: result.node_setting.spec_type_id,
    ownerId: result.owner_id,
    requestId: result.request_id,
    subStatus: result.sub_status,
    type: result.type,
  };

  return response;
};

export const getNodeRejectReasonParser = async (nodeId: number): Promise<any> => {
  const result = await getNodeRejectReasonFromServer({
    params: {
      nodeId,
    },
  });

  const response = {
    rejectReason: result.reject_reason,
  };

  return response;
};

export const submitNodeParser = async (nodeId: number): Promise<any> => {
  const submitNodeResult = await submitNodeFromServer({
    params: {
      nodeId,
    },
  });

  return submitNodeResult;
};

export const confirmNodeParser = async (projectId: number, nodeId: number): Promise<any> => {
  const confirmNodeResult = await confirmNodeFromServer({
    params: {
      nodeId,
    },
    data: {
      projectId,
    },
  });

  return confirmNodeResult;
};

export const rejectNodeParser = async (nodeId: number, rejectReason: string): Promise<any> => {
  const rejectNodeResult = await rejectNodeFromServer({
    params: {
      nodeId,
    },
    data: {
      rejectReason,
    },
  });

  return rejectNodeResult;
};

export const deployNodeParser = async (nodeId: number): Promise<any> => {
  const deployNodeResult = await deployNodeFromServer({
    params: {
      nodeId,
    },
  });

  return deployNodeResult;
};

export const deleteNodeParser = async (nodeId: number): Promise<any> => {
  const deleteNodeResult = await deleteNodeFromServer({
    params: {
      nodeId,
    },
  });

  return deleteNodeResult;
};
