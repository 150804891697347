import styled, {css} from "styled-components";

type SliderWrapperProps = {
  isOpen: boolean;
  currentHeight: number;
}

//TODO: 재귀적으로 가능하게 리팩터링 가능할듯
export const SliderContainer = styled.div<SliderWrapperProps>`
  transition: height 0.3s ease, opacity 0.55s ease-in-out;
  overflow: hidden;
  opacity: 0;
  height: ${(props) => `${props.currentHeight}px`};
  ${({isOpen}) => isOpen && css`
    overflow: visible;
    opacity: 1;
  `}
`

