import React, { useState } from "react";
import css from "./ProjectSelector.scss";
import { useSelector } from "react-redux";
import { selectAllProjects } from "reducer/projectReducer";
import { bindStyle } from "view/styles/classNames";
import useGetSelectedProject from "hooks/useGetSelectedProject";
import { useNavigate } from "react-router-dom";
import { changeProjectAction } from "action/projectAction";
import { errorAlert } from "utils/Utils";
import arrowIcon from "../../../assets/images/Icon_arrow_down.png";

const ProjectSelector = () => {
  const cn = bindStyle(css);

  const navigate = useNavigate();
  const projectList = useSelector(selectAllProjects);
  const { selectedProject, selectedProjectID } = useGetSelectedProject();

  const [isProjectSelectorOpen, setIsProjectSelectorOpen] = useState<Boolean>(false);

  const changeProject_ = async (proejctId: number) => {
    if (selectedProjectID === proejctId) {
      return;
    }
    const { result, error } = await changeProjectAction(proejctId);

    if (result) {
      setIsProjectSelectorOpen(false);
      navigate("/");
    }

    if (error) {
      errorAlert(error.data.message);
    }
  };

  return (
    <div
      className={cn("project-selector", { active: isProjectSelectorOpen })}
      onMouseEnter={() => setIsProjectSelectorOpen(true)}
      onMouseLeave={() => setIsProjectSelectorOpen(false)}
    >
      <div className="project-selector-title-container">
        <div className="project-selector-title-area">
          <div className="project-selector-helper" />
          <div className="project-selector-title">Project</div>
        </div>
      </div>
      <div className="project-selector-area">
        <div className="project-selector-area-selected-project">
          <span className="project-selector-area-selected-project-label">{selectedProject.name ?? "-"}</span>
        </div>
        <div className={cn("project-selector-area-arrow", { active: isProjectSelectorOpen })}>
          <img src={arrowIcon} className="project-selector-area-arrow-icon" alt="" />
        </div>
      </div>

      {isProjectSelectorOpen && (
        <div className="project-selector-panel">
          <div className="project-selector-panel-header" />
          <ul className="project-selector-panel-project">
            {projectList.map((project) => (
              <li key={`project-${project.id}`} className="project-selector-panel-project-item" onClick={() => changeProject_(project.id)}>
                <div className="project-selector-panel-project-item-label">
                  <div className={cn("project-selector-panel-project-item-label-dot", { selected: project.id === selectedProjectID })} />
                  <div className="project-selector-panel-project-item-label-name">{project.name}</div>
                </div>
              </li>
            ))}
          </ul>
          <div className="project-selector-panel-new-project" onClick={() => navigate("/manage-project")}>{`+ New Project`}</div>
        </div>
      )}
    </div>
  );
};

export default ProjectSelector;
