import React from "react";
import { Route, Routes } from "react-router-dom";
import RegisterWallet from "./wallet/page/RegisterWallet";
import WalletList from "./wallet/page/WalletList";
import ManageWallet from "./wallet/page/ManageWallet";
import RegisterBlockExplorer from "./blockExplorer/page/RegisterBlockExplorer";
import BlockExplorerList from "./blockExplorer/page/BlockExplorerList";
import ManageBlockExplorer from "./blockExplorer/page/ManageBlockExplorer";

const ToolRouter = () => {
  return (
    <Routes>
      <Route path={"wallet/*"} element={<WalletRouter />} />
      <Route path={"block-explorer/*"} element={<BlockExplorerRouter />} />
    </Routes>
  );
};

export default ToolRouter;

const WalletRouter = () => {
  return (
    <Routes>
      <Route path="register" element={<RegisterWallet />} />
      <Route path="manage/*">
        <Route index element={<WalletList />} />
        <Route path=":id" element={<ManageWallet />} />
      </Route>
    </Routes>
  );
};

const BlockExplorerRouter = () => {
  return (
    <Routes>
      <Route path="register" element={<RegisterBlockExplorer />} />
      <Route path="manage/*">
        <Route index element={<BlockExplorerList />} />
        <Route path=":id" element={<ManageBlockExplorer />} />
      </Route>
    </Routes>
  );
};
