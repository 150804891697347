import { TokenTypeFromServer } from "../server/type/currencyType";
import {
  checkCurrencyNameFromServer,
  createCurrencyFromServer,
  deleteCurrencyFromServer,
  getCoinByMicroChainIdFromServer,
  getCurrencyDetailFromServer,
  getCurrencyListFromServer,
  getDefaultCurrencyImageListFromServer,
  getMicroChainCurrencyDetailFromServer,
  getMicroChainCurrencyListFromServer,
  getTokenListFromServer,
  registerCurrencyToWalletFromServer,
  submitCurrencyFromServer,
  updateCurrencyDetailFromServer,
  uploadCurrencyImageFromServer,
} from "../server/API/currencyAPI";
import { GetTokenListOptions, MicroChainCurrenciesType, MicroChainCurrencyType, TokenCurrencyType, TokenType } from "../reducer/clientType/tokenClientType";

export const createTokenParser = async (
  name: string,
  image: string,
  symbol: string,
  originMicroChainId: number,
  initialSupply: string,
  officialSite: string,
  useWallet: boolean,
  mintable: boolean,
  burnable: boolean
): Promise<TokenType> => {
  const createCurrencyResult: TokenTypeFromServer = await createCurrencyFromServer({
    data: {
      name,
      image,
      symbol,
      originMicroChainId,
      currencyDetail: { initialSupply, officialSite, useWallet, mintable, burnable },
    },
  });
  const result = {
    currencyId: createCurrencyResult.currency_id,
    name: createCurrencyResult.name,
    image: createCurrencyResult.image,
    unit: createCurrencyResult.unit,
    symbol: createCurrencyResult.symbol,
    originNetworkId: createCurrencyResult.origin_network_id,
    originMicroChainId: createCurrencyResult.origin_micro_chain_id,
    // originOwnerId: createCurrencyResult.origin_owner_id,
    type: createCurrencyResult.type,
    mainStatus: createCurrencyResult.main_status,
    subStatus: createCurrencyResult.sub_status,
    currencyDetail: {
      initialSupply: createCurrencyResult.currency_detail.initial_supply,
      officialSite: createCurrencyResult.currency_detail.official_site,
      useWallet: createCurrencyResult.currency_detail.use_wallet,
      mintable: createCurrencyResult.currency_detail.mintable,
      burnable: createCurrencyResult.currency_detail.burnable,
    },
    microChainCurrencies: createCurrencyResult.micro_chain_currencies.map((microChainCurrency) => {
      return {
        microChainCurrencyId: microChainCurrency.micro_chain_currency_id,
        microChainId: microChainCurrency.micro_chain_id,
        contractId: microChainCurrency.contract_id,
        contractAddress: microChainCurrency.contract_address,
        type: microChainCurrency.type,
      };
    }),
  };
  return result;
};

export const getDefaultTokenImageListParser = async () => {
  const defaultCurrencyImageList = await getDefaultCurrencyImageListFromServer();
  const result = defaultCurrencyImageList.map((image) => {
    return {
      currencyDefaultImageId: image.currency_default_image_id,
      url: image.url,
    };
  });
  return result;
};

export const uploadTokenImageToS3Parser = async (formData: FormData, fileCount: number): Promise<any> => {
  const result = await uploadCurrencyImageFromServer({
    data: formData,
    query: {
      fileCount,
    },
  });
  const currencyImageUrl: string = result.url;
  return currencyImageUrl;
};

export const checkTokenNameParser = async (name: string): Promise<any> => {
  const result = await checkCurrencyNameFromServer({
    data: {
      name,
    },
  });
  const currencyNameValidationResult = result.valid;
  return currencyNameValidationResult;
};

//============ Token List ============//
export const getTokenListParser = async (options: GetTokenListOptions): Promise<any> => {
  const currencyList = await getTokenListFromServer({
    query: options,
  });
  const result = currencyList.map((currency) => {
    return {
      currencyId: currency.currency_id,
      name: currency.name,
      image: currency.image,
      unit: currency.unit,
      symbol: currency.symbol,
      originNetworkId: currency.origin_network_id,
      originMicroChainId: currency.origin_micro_chain_id,
      //TODO:: 추후 키값 변경에 따라 함께 변경 필요
      originOwnerId: currency.origin_owner_id,
      type: currency.type,
      mainStatus: currency.main_status,
      subStatus: currency.sub_status,
      currencyDetail: {
        initialSupply: currency.currency_detail.initial_supply,
        officialSite: currency.currency_detail.official_site,
        useWallet: currency.currency_detail.use_wallet,
        mintable: currency.currency_detail.mintable,
        burnable: currency.currency_detail.burnable,
      },
    };
  });
  return result;
};

// Network에서 사용하는 parser로 getTokenListParser와 같은 역할을 하지만 return하는 데이터가 다르다.
export const getCurrenciesParser = async (options: {
  originMicroChainIds?: number[];
  type?: number[];
  mainStatus?: number[];
  //TODO:: 추후 키값 변경에 따라 함께 변경 필요
  originOwnerId?: number;
  originNetworkId?: number;
}): Promise<any> => {
  const result = await getCurrencyListFromServer({
    query: {
      ...(options.originMicroChainIds && { originMicroChainIds: options.originMicroChainIds }),
      ...(options.type && { type: options.type }),
      ...(options.mainStatus && { mainStatus: options.mainStatus }),
      //TODO:: 추후 키값 변경에 따라 함께 변경 필요
      ...(options.originOwnerId && { originOwnerId: options.originOwnerId }),
      ...(options.originNetworkId && { originNetworkId: options.originNetworkId }),
    },
  });

  const response = result.map((el: any) => ({
    initialSupply: el.currency_detail?.initial_supply,
    currencyId: el.currency_id,
    image: el.image,
    mainStatus: el.main_status,
    name: el.name,
    originMicroChainId: el.origin_micro_chain_id,
    originNetworkId: el.origin_network_id,
    //TODO:: 추후 키값 변경에 따라 함께 변경 필요
    originOwnerId: el.origin_owner_id,
    subStatus: el.sub_status,
    symbol: el.symbol,
    type: el.type,
  }));

  return response;
};

//============ Manage Token ============//
export const getTokenDetailParser = async (currencyId: number): Promise<TokenType> => {
  const tokenDetail = await getCurrencyDetailFromServer({ params: { currencyId } });
  const result = {
    currencyId: tokenDetail.currency_id,
    name: tokenDetail.name,
    image: tokenDetail.image,
    unit: tokenDetail.unit,
    symbol: tokenDetail.symbol,
    originNetworkId: tokenDetail.origin_network_id,
    originMicroChainId: tokenDetail.origin_micro_chain_id,
    //TODO:: 추후 키값 변경에 따라 함께 변경 필요
    originOwnerId: tokenDetail.origin_owner_id,
    type: tokenDetail.type,
    mainStatus: tokenDetail.main_status,
    subStatus: tokenDetail.sub_status,
    transactionHash: tokenDetail.transaction_hash ?? null,
    currencyDetail: {
      initialSupply: tokenDetail.currency_detail.initial_supply,
      officialSite: tokenDetail.currency_detail.official_site,
      useWallet: tokenDetail.currency_detail.use_wallet,
      mintable: tokenDetail.currency_detail.mintable,
      burnable: tokenDetail.currency_detail.burnable,
    },
    microChainCurrencies: tokenDetail.micro_chain_currencies.map((microChainCurrency) => {
      return {
        microChainCurrencyId: microChainCurrency.micro_chain_currency_id,
        microChainId: microChainCurrency.micro_chain_id,
        contractId: microChainCurrency.contract_id,
        contractAddress: microChainCurrency.contract_address,
        type: microChainCurrency.type,
      };
    }),
  };
  return result;
};

export const updateTokenParser = async (
  currencyId: number,
  name: string,
  image: string,
  symbol: string,
  initialSupply: string,
  mintable: boolean,
  burnable: boolean,
  officialSite: string,
  useWallet: boolean
): Promise<TokenType> => {
  const updatedToken = await updateCurrencyDetailFromServer({
    params: { currencyId },
    data: {
      name,
      image,
      symbol,
      currencyDetail: {
        initialSupply,
        mintable,
        burnable,
        officialSite,
        useWallet,
      },
    },
  });
  const result = {
    currencyId: updatedToken.currency_id,
    name: updatedToken.name,
    image: updatedToken.image,
    unit: updatedToken.unit,
    symbol: updatedToken.symbol,
    originNetworkId: updatedToken.origin_network_id,
    originMicroChainId: updatedToken.origin_micro_chain_id,
    //TODO:: 추후 키값 변경에 따라 함께 변경 필요
    originOwnerId: updatedToken.origin_owner_id,
    type: updatedToken.type,
    mainStatus: updatedToken.main_status,
    subStatus: updatedToken.sub_status,
    currencyDetail: {
      initialSupply: updatedToken.currency_detail.initial_supply,
      officialSite: updatedToken.currency_detail.official_site,
      useWallet: updatedToken.currency_detail.use_wallet,
      mintable: updatedToken.currency_detail.mintable,
      burnable: updatedToken.currency_detail.burnable,
    },
    microChainCurrencies: updatedToken.micro_chain_currencies.map((microChainCurrency) => {
      return {
        microChainCurrencyId: microChainCurrency.micro_chain_currency_id,
        microChainId: microChainCurrency.micro_chain_id,
        contractId: microChainCurrency.contract_id,
        contractAddress: microChainCurrency.contract_address,
        type: microChainCurrency.type,
      };
    }),
  };
  return result;
};

export const deleteTokenParser = async (currencyId: number): Promise<{ success: boolean }> => {
  const result = await deleteCurrencyFromServer({
    params: { currencyId },
  });
  return result;
};

export const submitTokenParser = async (currencyId: number): Promise<TokenType> => {
  const result = await submitCurrencyFromServer({
    params: { currencyId },
  });
  return {
    currencyId: result.currency_id,
    name: result.name,
    image: result.image,
    unit: result.unit,
    symbol: result.symbol,
    originNetworkId: result.origin_network_id,
    originMicroChainId: result.origin_micro_chain_id,
    //TODO:: 추후 키값 변경에 따라 함께 변경 필요
    originOwnerId: result.origin_owner_id,
    type: result.type,
    mainStatus: result.main_status,
    subStatus: result.sub_status,
    currencyDetail: {
      initialSupply: result.currency_detail.initial_supply,
      officialSite: result.currency_detail.official_site,
      useWallet: result.currency_detail.use_wallet,
      mintable: result.currency_detail.mintable,
      burnable: result.currency_detail.burnable,
    },
    microChainCurrencies: result.micro_chain_currencies.map((microChainCurrency) => {
      return {
        microChainId: microChainCurrency.micro_chain_id,
        microChainCurrencyId: microChainCurrency.micro_chain_currency_id,
        contractId: microChainCurrency.contract_id,
        contractAddress: microChainCurrency.contract_address,
        type: microChainCurrency.type,
      };
    }),
  };
};

//============ Coin (Token에서 사용 X) ============//
// 사용처 없음
export const getMicroChainCurrencyListParser = async (microChainId: number): Promise<MicroChainCurrenciesType[]> => {
  const result = await getMicroChainCurrencyListFromServer({
    query: {
      microChainId,
    },
  });
  return result.map((microChainCurrency) => {
    return {
      microChainCurrencyId: microChainCurrency.micro_chain_currency_id,
      microChainId: microChainCurrency.micro_chain_id,
      contractId: microChainCurrency.contract_id,
      contractAddress: microChainCurrency.contract_address,
      type: microChainCurrency.type,
      currencyId: microChainCurrency.currency_id,
      name: microChainCurrency.name,
      image: microChainCurrency.image,
      unit: microChainCurrency.unit,
      symbol: microChainCurrency.symbol,
      originNetworkId: microChainCurrency.origin_network_id,
      originMicroChainId: microChainCurrency.origin_micro_chain_id,
      //TODO:: 추후 키값 변경에 따라 함께 변경 필요
      originOwnerId: microChainCurrency.origin_owner_id,
    };
  });
};

//사용처 없음
//TODO:: type과 response 추후 확인 필요
export const getMicroChainCurrencyDetailParser = async (
  microChainCurrencyId: number
): Promise<
  MicroChainCurrencyType & {
    currency: TokenCurrencyType;
  }
> => {
  const result = await getMicroChainCurrencyDetailFromServer({
    params: {
      microChainCurrencyId,
    },
  });

  return {
    microChainCurrencyId: result.micro_chain_currency_id,
    microChainId: result.micro_chain_id,
    contractId: result.contract_id,
    contractAddress: result.contract_address,
    type: result.type,
    currency: {
      currencyId: result.currency.currency_id,
      name: result.currency.name,
      image: result.currency.image,
      unit: result.currency.unit,
      symbol: result.currency.symbol,
      originNetworkId: result.currency.origin_network_id,
      originMicroChainId: result.currency.origin_micro_chain_id,
      //TODO:: 추후 키값 변경에 따라 함께 변경 필요
      originOwnerId: result.currency.origin_owner_id,
      type: result.currency.type,
      mainStatus: result.currency.main_status,
      subStatus: result.currency.sub_status,
      currencyDetail: {
        initialSupply: result.currency.currency_detail.initial_supply,
        officialSite: result.currency.currency_detail.official_site,
        useWallet: result.currency.currency_detail.use_wallet,
        mintable: result.currency.currency_detail.mintable,
        burnable: result.currency.currency_detail.burnable,
      },
    },
  };
};

// Network, airdrop에서 사용하는 Coin parser
export const getCoinDetailParser = async (microChainId: number): Promise<any> => {
  const result = await getCoinByMicroChainIdFromServer({
    query: {
      microChainId,
    },
  });

  const response = {
    contractAddress: result.contract_address,
    contractId: result.contract_id,
    currencyId: result.currency_id,
    image: result.image,
    microChainCurrencyId: result.micro_chain_currency_id,
    microChainId: result.micro_chain_id,
    name: result.name,
    originMicroChainId: result.origin_micro_chain_id,
    originNetworkId: result.origin_network_id,
    //TODO:: 추후 키값 변경에 따라 함께 변경 필요
    originOwnerId: result.origin_owner_id,
    symbol: result.symbol,
    type: result.type,
    unit: result.unit,
  };

  return response;
};

export const registerTokenToWalletParser = async (currencyId: number): Promise<{ success: boolean }> => {
  const result = await registerCurrencyToWalletFromServer({
    params: { currencyId },
  });
  return result;
};
