import {useCallback} from "react";
import "./DeployTokenContractModalContent.scss";
import {useSelector} from "react-redux";
import {connectInfo} from "../../../../../store/redux/EQconnect/EQConnectSlice";
import {WEB3} from "../../../../../modules/web3/Web3";
import {errorAlert, removeComma} from "../../../../../utils/Utils";
import {makeTransactionForUploadContractAction} from "../../../../../action/requestAction";
import {project_access} from "reducer/projectReducer";
import ButtonContainer from "view/components/_atom/container/ButtonContainer";
import BasicButton from "view/components/_atom/button/BasicButton";
import {ButtonSize, ButtonTheme} from "view/components/_atom/button/StyledButton";
import useTransaction from "../../../../../hooks/useTransaction";
import {logic} from "../../../../../logic";
import {WalletType} from "../../../../../modules/EQConnect/EQConnectWalletButtonWithMetamask";

const DeployTokenContractModalContent = (props) => {
	const {tokenDetail, setDeployTokenContractModalOpen, callback, selectedWallet} = props;
	const {address} = useSelector(connectInfo);
	const token = useSelector(project_access);
	const closeModal = () => setDeployTokenContractModalOpen(false);

	const callback_ = () => {
		callback();
		closeModal();
	};
	//========= Make Transaction =========//
	const deployTokenContract = async () => {
		return await makeTransactionForUploadContractAction({
			address: address,
			contractId: tokenDetail.originMicroChainCurrency.contractId,
			microChainId: tokenDetail.originMicroChainId,
			parameters: [
				tokenDetail.name,
				tokenDetail.symbol,
				WEB3.toWei(removeComma(tokenDetail.currencyDetail.initialSupply)),
			],
			to: null,
			value: null,
			transactionPath: `${process.env.REACT_APP_HUB_SERVER_URL}${process.env.REACT_APP_API_VERSION_V2}currency/${tokenDetail.currencyId}/release-contract`,
			token,
			additionalQuery: {
				body: {
					contractId: tokenDetail.originMicroChainCurrency.contractId,
				},
			},
		});
	};

	//========= UI =========//
	const DEPLOY_TOKEN_INDEX = [
		{label: "Network", accessor: "networkLabel"},
		{label: "Microchain", accessor: "microChainLabel"},
	];
	const DEPLOY_TOKEN_RENDERER = useCallback(
		(data) => ({
			networkLabel: data.networkLabel,
			microChainLabel: data.microChainLabel,
		}),
		[]
	);

	const {requestTransaction: requestTxWithEQExtension} = useTransaction(deployTokenContract, callback_);
	const requestTxWithMetamask = async () => {
		const {currencyId, originMicroChainCurrency, name, symbol, currencyDetail: {initialSupply}} = tokenDetail || {};
		const makeDeployTX = async () => await logic.microChain.makeDeployTokenTransaction(currencyId, originMicroChainCurrency, name, symbol, initialSupply);
		const {result, error} = await logic.microChain.tokenDeployWithMetamask(makeDeployTX, callback_);
		console.log("result", result);
		if (error) {
			console.error(error);
			if (error.data === "tx_make_fail") {
				errorAlert("Making transaction failed. Please try again later");
			}
			if (error.data === "tx_send_fail") {
				errorAlert("Transaction send fail. Please try again later");
			}

		}
	}
	const requestTx = async () => {
		if (selectedWallet === WalletType.eqHub) {
			await requestTxWithEQExtension();
		} else {
			await requestTxWithMetamask();
		}
	}


	return (
		<section className="deploy-token-contract-modal-content">
			{DEPLOY_TOKEN_INDEX.map((el, index) => {
				return (
					<div key={index} className="deploy-token-contract-modal-content-row">
						<div className="deploy-token-contract-modal-content-index">{`${el.label}: `}</div>
						<div className="deploy-token-contract-modal-content-data">
							{DEPLOY_TOKEN_RENDERER(tokenDetail)[el.accessor]}
						</div>
					</div>
				);
			})}
			<div className="flex justify-content-center">
				<ButtonContainer>
					<BasicButton
						size={ButtonSize.LARGE}
						theme={ButtonTheme.SECONDARY}
						onClick={() => setDeployTokenContractModalOpen(false)}
					>
						Cancel
					</BasicButton>
					<BasicButton
						size={ButtonSize.LARGE}
						disabled={!address}
						onClick={requestTx}
					>
						Deploy
					</BasicButton>
				</ButtonContainer>
			</div>
		</section>
	);
};

export default DeployTokenContractModalContent;
