import { VC__is_empty_value, VC__is_not_email, VC__is_not_same_string } from "modules/validation/validationCondition";

export function VALIDATION__PROJECT_name(name) {
  return [
    {
      condition: !VC__is_empty_value(name),
      message: "",
    },
  ];
}

export function VALIDATION__PROJECT_invite_member_email(email) {
  return [
    {
      condition: !VC__is_empty_value(email),
      message: "",
    },
    {
      condition: !VC__is_not_email(email),
      message: "Please enter email format",
    },
  ];
}

export function VALIDATION__PROJECT_remove_member_email(email, emailToRemove) {
  return [
    {
      condition: !VC__is_empty_value(email),
      message: "",
    },
    {
      condition: !VC__is_not_email(email),
      message: "Please enter your email address according to the email format",
    },
    {
      condition: !VC__is_not_same_string(email, emailToRemove),
      message: "Please enter the correct email address",
    },
  ];
}
