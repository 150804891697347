import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import "./ManageNftProjectAfterDeploy.scss";
import NFTCard from "../molcules/NFTCard";
import { useNavigate } from "react-router-dom";
import { connectInfo } from "../../../../../store/redux/EQconnect/EQConnectSlice";
import EQConnectWalletButton from "../../../../../modules/EQConnect/EQConnectWalletButton";
import toast from "react-hot-toast";
import { errorAlert } from "../../../../../utils/Utils";
import { callAction } from "../../../../../action/requestAction";
import emptyNFTImage from "../../../../assets/images/image_NFT_empty.png";
import { getMintedNFTsByProjectIdAction } from "action/nftAction";
import BasicButton from "view/components/_atom/button/BasicButton";
import ButtonContainer from "view/components/_atom/container/ButtonContainer";
import { ButtonSize } from "view/components/_atom/button/StyledButton";
import nftIcon from "../../../../assets/images/service/Icon_SNB_non_fungible_token.png";
import { ManageFieldContainer, ManageFieldLabel, RowManageField } from "view/components/_atom/section/ManageField";
import DivideLine from "view/components/_atom/line/DivideLine";

const ManageNftProjectAfterDeploy = ({ projectDetail }) => {
  const navigate = useNavigate();
  const { address } = useSelector(connectInfo);

  //========= Check NFT Project Owner =========//
  const [nftProjectOwner, setNFTProjectOwner] = useState("");

  const getNFTProjectContractOwner = async () => {
    const { error, result } = await callAction(projectDetail.microChainId, projectDetail.contractAddress, "owner", []);
    if (result) {
      setNFTProjectOwner(result[0]);
    }
    if (error) {
      switch (error.type) {
        case "API":
          errorAlert(error.data.message);
          break;
        case "Module":
          errorAlert(`${error.data.argument}::${error.data.code}`);
          break;
        default:
          errorAlert("Error::unknown");
      }
    }
  };
  const isContractOwner = useMemo(() => {
    return address === nftProjectOwner;
  }, [address, nftProjectOwner]);

  useEffect(() => {
    if (projectDetail.contractAddress) {
      getNFTProjectContractOwner();
    }
  }, [projectDetail]);

  //========= Get NFT list & Check NFT list empty =========//
  const [NFTList, setNFTList] = useState([]);
  const getMintedNFTList = async () => {
    const { result, error } = await getMintedNFTsByProjectIdAction(projectDetail.projectId);
    if (result) {
      setNFTList(result);
    }
    if (error) {
      errorAlert(error.data.message);
    }
  };
  const isEmptyNFT = useMemo(() => {
    if (Array.isArray(NFTList) && NFTList.length === 0) {
      return true;
    }
    return false;
  }, [NFTList]);

  useEffect(() => {
    getMintedNFTList();
  }, []);

  return (
    <>
      <ManageFieldContainer
        icon={nftIcon}
        title="Minted NFTs"
        options={<MintedNFTButtons address={address} navigate={navigate} isContractOwner={isContractOwner} />}
      >
        <div className="minted-nft-box">
          {NFTList?.length > 0 && (
            <>
              <div className="minted-nft-isuued">
                <RowManageField>
                  <ManageFieldLabel label="# Issued" />
                  <p>
                    <span>{NFTList?.length ?? "-"}</span> <span>{NFTList?.length > 1 ? "Tokens" : "Token"}</span>
                  </p>
                </RowManageField>
              </div>
              <DivideLine style={{ margin: "20px 0" }} />
            </>
          )}
          {isEmptyNFT && (
            <div className="empty-nft-container">
              <div className="empty-nft-image">
                <img src={emptyNFTImage} alt={"empty-nft"} className="empty-nft-image-src" />
              </div>
              <div className="empty-nft-label">You do not have a registered NFT yet..</div>
            </div>
          )}
          {!isEmptyNFT && (
            <div className="minted-nft-container">
              {!isEmptyNFT &&
                NFTList.map((NFT, index) => {
                  return <NFTCard key={index} nftDetail={NFT} />;
                })}
            </div>
          )}
        </div>
      </ManageFieldContainer>
    </>
  );
};

export default ManageNftProjectAfterDeploy;

const MintedNFTButtons = (props) => {
  const { address, isContractOwner, navigate } = props;

  const navigateToMint = () => {
    if (isContractOwner) {
      navigate("mint");
    } else {
      toast.error("Only contract manager can mint NFT");
    }
  };

  return (
    <ButtonContainer>
      <EQConnectWalletButton />
      {address && (
        <BasicButton size={ButtonSize.LARGE} onClick={() => navigateToMint()}>
          Mint NFT
        </BasicButton>
      )}
    </ButtonContainer>
  );
};
