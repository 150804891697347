type UserErrorMessage = {
  clientId: number;
  serverStatusCode: number;
  message: string;
  path: string;
};

export const USER_ERROR_MESSAGE: Array<UserErrorMessage> = [
  {
    clientId: 1,
    serverStatusCode: 1,
    message: "This network name already exists",
    path: "network",
  },
  {
    clientId: 2,
    serverStatusCode: 2,
    message: "This coin name already exists",
    path: "network",
  },
  {
    clientId: 3,

    serverStatusCode: 3,
    message: "This coin symbol already exists",
    path: "network",
  },
  {
    clientId: 4,
    serverStatusCode: 4,
    message: "This network name already exists",
    path: "network",
  },
  {
    clientId: 5,
    serverStatusCode: 5,
    message: "This coin name already exists",
    path: "network",
  },
  {
    clientId: 6,
    serverStatusCode: 6,
    message: "This coin symbol already exists",
    path: "network",
  },
  {
    clientId: 7,
    serverStatusCode: 7,
    message: "This token name already exists",
    path: "token",
  },
  {
    clientId: 8,
    serverStatusCode: 8,
    message: "This token symbol already exists",
    path: "token",
  },
  {
    clientId: 9,
    serverStatusCode: 9,
    message: "This token name already exists",
    path: "token",
  },
  {
    clientId: 10,
    serverStatusCode: 10,
    message: "This token symbol already exists",
    path: "token",
  },
  {
    clientId: 11,
    serverStatusCode: 11,
    message: "This airdrop project name already exists",
    path: "airdrop",
  },
  {
    clientId: 12,
    serverStatusCode: 12,
    message: "This airdrop project name already exists",
    path: "airdrop",
  },
  {
    clientId: 13,
    serverStatusCode: 13,
    message: "This NFT project name already exists",
    path: "NFT",
  },
  {
    clientId: 14,
    serverStatusCode: 14,
    message: "This NFT project name already exists",
    path: "NFT",
  },
  {
    clientId: 15,
    serverStatusCode: 15,
    message: "This email address already exists",
    path: "user",
  },
  {
    clientId: 16,
    serverStatusCode: 16,
    message: "Please check the email you entered",
    path: "user",
  },
  {
    clientId: 17,
    serverStatusCode: 17,
    message: "Please check the current password",
    path: "user",
  },
  {
    clientId: 18,
    serverStatusCode: 18,
    message: "The password is the same as the current password. Please enter a new password",
    path: "user",
  },
  {
    // 프로젝트 이름 중복
    clientId: 19,
    serverStatusCode: 19,
    message: "Project name is already in use",
    path: "project",
  },
  {
    // 프로젝트 참여 실패 시
    // data : [{project_id, reason}]
    // case 1 : 참여 시도한 프로젝트가 존재하지 않음
    // case 2 : 참여 시도한 내역에 대해서 참여 정보가 존재하지 않음 (초대한 프로젝트 측에서 초대 취소)
    clientId: 20,
    serverStatusCode: 20,
    message: "Some invitation requests failed",
    path: "project",
  },
  {
    clientId: 21,
    serverStatusCode: 21,
    message: "Cannot register because it already exists",
    path: "wallet",
  },
  {
    clientId: 22,
    serverStatusCode: 22,
    message: "Cannot register because it already exists",
    path: "block-explorer",
  },
  {
    clientId: 23,
    serverStatusCode: 23,
    message: "This is a network where the wallet is not registered",
    path: "token",
  },
  {
    clientId: 24,
    serverStatusCode: 24,
    message: "The link in the email has expired",
    path: "user",
  },
  {
    clientId: 25,
    serverStatusCode: 25,
    message: "This page doesn't exist",
    path: "Network",
  },
  {
    clientId: 26,
    serverStatusCode: 26,
    message: "Browser is not match",
    path: "verify",
  },
  {
    clientId: 27,
    serverStatusCode: 27,
    message: "Invalid auth code",
    path: "verify",
  },
  {
    // 멤버 초대를 취소 시도하였으나, 해당 시점에 이미 초대받은 사용자가 초대를 승낙한 경우
    clientId: 28,
    serverStatusCode: 28,
    message: "User already added as a member",
    path: "project",
  },
  {
    // 입력한 이메일 주소의 사용자가 이미 프로젝트의 멤버인 경우
    clientId: 29,
    serverStatusCode: 29,
    message: "This user is already a member",
    path: "project",
  },
  {
    // 입력한 이메일 주소의 사용자가 이미 프로젝트에 존재하지 않는 멤버인 경우
    clientId: 30,
    serverStatusCode: 30,
    message: "User does not exist in this project",
    path: "project",
  },
  {
    // 멤버에서 제외된 유저가 해당 프로젝트의 리소스에 접근한 경우
    clientId: 31,
    serverStatusCode: 31,
    message: "You do not have permission to access this page.<br /> For details, please contact the manager of this project",
    path: "project",
  },
  {
    // 멤버에서 제외된 프로젝트의 토큰을 발급받으려 하는 경우 (예시 메세지)
    clientId: 32,
    serverStatusCode: 32,
    message: "This project is the unparticipated project.<br /> For details, please contact the manager of this project",
    path: "project",
  },
  {
    // 프로젝트를 생성하는 유저가 이미 Free Plan 프로젝트를 1개 이상 가지고 있을 경우 (Free Plan은 유저 1인당 1개까지 생성 가능)
    // 실결제가 붙기 전, 유저 1인당 하나의 프로젝트만 생성 가능하기 때문에 에러 메세지 수정됨. 이후 변경 필요
    clientId: 33,
    serverStatusCode: 33,
    // message: "You can only create one free plan project",
    message: "Only one project can be created",
    path: "project",
  },
  {
    // 서버에 요청을 보낼 때 담긴 Access 토큰이 유효하지 않을 때
    clientId: 34,
    serverStatusCode: 34,
    message: "Invalid Access Token",
    path: "auth",
  },
  {
    // Access 토큰이 만료되어 Refresh 요청을 보냈지만, 쿠키에 담긴 Refresh 토큰도 유효하지 않을 때
    clientId: 35,
    serverStatusCode: 35,
    message: "Invalid Refresh Token",
    path: "auth",
  },
  {
    // 비밀번호 변경시 최근 4번 째까지 사용한 비밀번호와 동일할 경우
    clientId: 36,
    serverStatusCode: 36,
    message: "Please do not reuse the 4 most recently used passwords",
    path: "auth",
  },
  {
    // 유저가 로그인을 시도할 때 MFA를 설정한 유저가 totpCode를 보내지 않았을 때
    clientId: 37,
    serverStatusCode: 37,
    message: "",
    path: "signIn",
  },
  {
    // MFA를 등록한 유저가 유효하지 않은 OTP를 입력했을 때
    clientId: 38,
    serverStatusCode: 38,
    message: "Please enter the correct OTP number",
    path: "MFA",
  },
];
