export enum STATUS {
  READY      = 'READY',
  CREATED    = 'CREATED',
  PREPARED   = 'PREPARED',
  COMPLETE   = 'COMPLETE',
  REJECTED   = 'REJECTED',
  PROCESSING = 'PROCESSING',
  RUNNING    = 'RUNNING'
}

export type StatusDataType = {
  status: STATUS
  icon: string;
  color: string;
  defaultText: string;
}
