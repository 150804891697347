import React from "react";
import "./NetworkNodeOperationField.scss";
import { FLOAT_REG_EXP, SECOND_DECIMAL_REG_EXP } from "constants/commonConstants";
import CommentInput from "view/components/_atom/input/CommentInput";
import { RowField, FieldContainer, FieldLabel } from "view/components/_atom/section/Field";
import NetworkIcon from "view/assets/images/service/Icon_SNB_network.png";
import useValidateCondition from "hooks/useValidateCondition";
import { VALIDATION__NETWORK_disinflation_rate, VALIDATION__NETWORK_fee, VALIDATION__NETWORK_inflation_rate } from "../../validation/networkValidation";
import { NETWORK_ANNOUNCEMENT_DISINFLATION_RATE, NETWORK_ANNOUNCEMENT_GAS_FEE, NETWORK_ANNOUNCEMENT_INFLATION_RATE } from "../../constants/network.data";

const NetworkNodeOperationField = (props) => {
  const { networkData, setNetworkData } = props;

  const setFee = (fee: string) => {
    if (fee === "") {
      setNetworkData((prevState) => ({
        ...prevState,
        microChainSetting: {
          ...prevState.microChainSetting,
          fee: null,
        },
      }));
      return;
    }

    if (!FLOAT_REG_EXP.test(fee)) {
      return;
    }

    setNetworkData((prevState) => ({
      ...prevState,
      microChainSetting: {
        ...prevState.microChainSetting,
        fee,
      },
    }));
  };

  const setInflationRate = (inflationRate: string) => {
    if (inflationRate === "") {
      setNetworkData((prevState) => ({
        ...prevState,
        microChainSetting: {
          ...prevState.microChainSetting,
          inflationRate: null,
        },
      }));
      return;
    }

    if (!FLOAT_REG_EXP.test(inflationRate)) {
      return;
    }

    if (!SECOND_DECIMAL_REG_EXP.test(inflationRate)) {
      return;
    }

    setNetworkData((prevState) => ({
      ...prevState,
      microChainSetting: {
        ...prevState.microChainSetting,
        inflationRate,
      },
    }));
  };

  const setDisinflationRate = (disInflationRate: string) => {
    if (disInflationRate === "") {
      setNetworkData((prevState) => ({
        ...prevState,
        microChainSetting: {
          ...prevState.microChainSetting,
          disInflationRate,
        },
      }));
      return;
    }

    if (!FLOAT_REG_EXP.test(disInflationRate)) {
      return;
    }

    if (!SECOND_DECIMAL_REG_EXP.test(disInflationRate)) {
      return;
    }

    if (Number(disInflationRate) > 100) {
      setNetworkData((prevState) => ({
        ...prevState,
        microChainSetting: {
          ...prevState.microChainSetting,
          disInflationRate: "100",
        },
      }));
      return;
    }

    setNetworkData((prevState) => ({
      ...prevState,
      microChainSetting: {
        ...prevState.microChainSetting,
        disInflationRate,
      },
    }));
  };

  const feeValidation = useValidateCondition(
    [networkData.microChainSetting.fee, networkData.microChainSetting.initialSupply],
    VALIDATION__NETWORK_fee(networkData.microChainSetting.fee, networkData.microChainSetting.initialSupply)
  );
  const inflationRateValidation = useValidateCondition(
    [networkData.microChainSetting.inflationRate],
    VALIDATION__NETWORK_inflation_rate(networkData.microChainSetting.inflationRate)
  );
  const disinflationRateValidation = useValidateCondition(
    [networkData.microChainSetting.disInflationRate],
    VALIDATION__NETWORK_disinflation_rate(networkData.microChainSetting.disInflationRate)
  );

  return (
    <>
      <FieldContainer icon={NetworkIcon} title={"Node Operation"}>
        <RowField>
          <FieldLabel label={"Gas Fee"} announcement={NETWORK_ANNOUNCEMENT_GAS_FEE} />
          <div className="node-operation-wrapper">
            <CommentInput
              {...feeValidation}
              value={networkData?.microChainSetting?.fee ?? ""}
              onChange={(e) => setFee(e.target.value)}
              style={{ width: 90, textAlign: "center" }}
              placeholder="EX) 0.1"
            />
            <span className="node-operation-unit">{networkData?.currency?.symbol}</span>
          </div>
        </RowField>
        <RowField>
          <FieldLabel label={"Inflation Rate"} announcement={NETWORK_ANNOUNCEMENT_INFLATION_RATE} />
          <div className="node-operation-wrapper">
            <CommentInput
              {...inflationRateValidation}
              value={networkData?.microChainSetting?.inflationRate ?? ""}
              onChange={(e) => setInflationRate(e.target.value)}
              style={{ width: 90, textAlign: "center" }}
              placeholder="EX) 20"
            />
            <span className="node-operation-unit">{`%`}</span>
          </div>
        </RowField>
        <RowField>
          <FieldLabel label={"Disinflation Rate"} announcement={NETWORK_ANNOUNCEMENT_DISINFLATION_RATE} />
          <div className="node-operation-wrapper">
            <CommentInput
              {...disinflationRateValidation}
              value={networkData?.microChainSetting?.disInflationRate ?? ""}
              onChange={(e) => setDisinflationRate(e.target.value)}
              style={{ width: 90, textAlign: "center" }}
              placeholder="EX) 20"
            />
            <span className="node-operation-unit">{`%`}</span>
          </div>
        </RowField>
      </FieldContainer>
    </>
  );
};

export default NetworkNodeOperationField;
