import { makeQuery } from "../index/utils";
import { v2ServerByProjectAuth } from "../index/serverInstances";
import { DailySummaryFromServer, BlockExplorerFromServer } from "server/type/blockExplorerType";

//----------getExplorerMicroChains
const getBlockExplorerList = async (request: getBlockExplorerListRequest): Promise<getBlockExplorerListResponse> =>
  await v2ServerByProjectAuth.get({
    path: "block-explorer/microChains",
    // path: "block-explorer/micro-chains",
    query: makeQuery(request.query),
  });

type getBlockExplorerListRequest = {
  query: {
    projectId?: number;
    mainStatus?: Array<number>;
    isExternal?: number;
  };
};

type getBlockExplorerListResponse = Array<BlockExplorerFromServer>;

//----------registerBlockExplorer
const registerBlockExplorer = async (request: registerBlockExplorerRequest): Promise<registerBlockExplorerResponse> =>
  await v2ServerByProjectAuth.post({
    path: "block-explorer/micro-chains-temp",
    // path: "block-explorer/micro-chains",
    data: request.data,
  });

type registerBlockExplorerRequest = {
  data: {
    microChainId: number;
  };
};
type registerBlockExplorerResponse = BlockExplorerFromServer;

//----------getBlockExplorerDetail
const getBlockExplorerDetail = async (request: getBlockExplorerDetailRequest): Promise<getBlockExplorerDetailResponse> =>
  await v2ServerByProjectAuth.get({
    path: `block-explorer/microChains/${request.params.microChainId}`,
    // path: `block-explorer/micro-chains/${request.params.microChainId}`,
    query: makeQuery(request.query),
  });

type getBlockExplorerDetailRequest = {
  params: {
    microChainId: number;
  };
  query: {
    mainStatus?: number;
    isExternal?: number;
  };
};
type getBlockExplorerDetailResponse = BlockExplorerFromServer;

//----------releaseBlockExplorer
const releaseBlockExplorer = async (request: ReleaseBlockExplorerRequest): Promise<ReleaseBlockExplorerResponse> =>
  await v2ServerByProjectAuth.post({
    path: `block-explorer/micro-chains-temp/${request.params.microChainId}/release`,
    // path: `block-explorer/micro-chains/${request.params.microChainId}/release`,
  });

type ReleaseBlockExplorerRequest = {
  params: {
    microChainId: number;
  };
};
type ReleaseBlockExplorerResponse = { success: true };

//----------disableBlockExplorer
const disableBlockExplorer = async (request: disableBlockExplorerRequest): Promise<disableBlockExplorerResponse> =>
  await v2ServerByProjectAuth.post({
    path: `block-explorer/micro-chains/${request.params.microChainId}/disable`,
  });

type disableBlockExplorerRequest = {
  params: {
    microChainId: number;
  };
};
type disableBlockExplorerResponse = { success: true };

// ----------------------------------------------------------------------------------------

//----------getBlocksForward
const getBlocksForward = async (request: getBlocksForwardRequest): Promise<getBlocksForwardResponse> =>
  await v2ServerByProjectAuth.get({
    path: `block-explorer/blocks/forward`,
    query: makeQuery(request.query),
  });

type getBlocksForwardRequest = {
  query: {
    microChainId: number;
    limit: number;
  };
};
type getBlocksForwardResponse = any;

//----------getTransactionsForward
const getTransactionsForward = async (request: getTransactionsForwardRequest): Promise<getTransactionsForwardResponse> =>
  await v2ServerByProjectAuth.get({
    path: `block-explorer/transactions/forward`,
    query: makeQuery(request.query),
  });

type getTransactionsForwardRequest = {
  query: {
    microChainId: number;
    limit: number;
  };
};
type getTransactionsForwardResponse = any;

//----------getDailySummary
const getDailySummary = async (request: getDailySummaryRequest): Promise<getDailySummaryResponse> =>
  await v2ServerByProjectAuth.get({
    path: `block-explorer/daily/summary`,
    query: makeQuery(request.query),
  });

type getDailySummaryRequest = {
  query: {
    microChainId: number;
    endTime: number;
  };
};
type getDailySummaryResponse = { summary: Array<DailySummaryFromServer> };

// ----------------------------------------------------------------------------------------
export {
  getBlockExplorerList as getBlockExplorerListFromServer,
  registerBlockExplorer as registerBlockExplorerFromServer,
  getBlockExplorerDetail as getBlockExplorerDetailFromServer,
  releaseBlockExplorer as releaseBlockExplorerFromServer,
  disableBlockExplorer as disableBlockExplorerFromServer,
  // --
  getBlocksForward as getBlocksForwardFromServer,
  getTransactionsForward as getTransactionsForwardFromServer,
  getDailySummary as getDailySummaryFromServer,
};
