import { ContractSolution } from "./../view/service/developer/contract/constants/contract.type";
import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ProjectRole } from "./clientType/projectClientType";
import { RootState, storeState } from "./index";
import { NodeSpec } from "./clientType/nodeClientType";
import { Preset } from "./clientType/presetClientType";
import { PaymentType } from "./clientType/paymentMethodClientType";
import { ContractStandard } from "view/service/developer/contract/constants/contract.type";

//--------- projectRoleAdapter ---------
const projectRoleAdapter = createEntityAdapter<ProjectRole>({
  selectId: (role) => role.projectRoleId,
});

export const {
  selectAll: selectAllProjectRoles,
  selectIds: selectProjectRoleIds,
  selectById: selectProjectRoleById,
} = projectRoleAdapter.getSelectors<RootState>((state) => state.preset.projectRole);

//--------- nodeSpecAdapter ---------
const nodeSpecAdapter = createEntityAdapter<NodeSpec>({
  selectId: (node) => node.specTypeId,
});

export const {
  selectAll: selectAllNodeSpecs,
  selectIds: selectNodeSpecIds,
  selectById: selectNodeSpecById,
} = nodeSpecAdapter.getSelectors<RootState>((state) => state.preset.nodeSpec);

//--------- PaymentTypeAdapter ---------
const paymentTypeAdapter = createEntityAdapter<PaymentType>({
  selectId: (paymentType) => paymentType.paymentTypeId,
});

export const {
  selectAll: selectAllPaymentType,
  selectIds: selectPaymentTypeIds,
  selectById: selectPaymentTypeById,
} = paymentTypeAdapter.getSelectors<RootState>((state) => state.preset.paymentType);

export const paymentTypeLabelParser = (name: string) => {
  if (name === "Single") {
    return "One-Time";
  }
  if (name === "Multiple") {
    return "Multi-Use";
  }
  if (name === "Contract") {
    return "Smart Contract";
  }
  return "";
};

export const parseAllPaymentTypeForRadioSelect = () => {
  const allPaymentType = selectAllPaymentType(storeState());
  return allPaymentType.map((type) => ({
    paymentTypeId: type.paymentTypeId,
    name: paymentTypeLabelParser(type.name),
    methodType: type.methodType,
    description: type.description,
  }));
};

//--------- ContractCategoryAdapter ---------
const contractStandardAdapter = createEntityAdapter<ContractStandard>({
  selectId: (contractStandard) => contractStandard.category_id,
});

export const {
  selectAll: selectAllStandard,
  selectIds: selectStandardIds,
  selectById: selectStandardById,
} = contractStandardAdapter.getSelectors<RootState>((state) => state.preset.contractCategory);
const contractSolutionAdapter = createEntityAdapter<ContractSolution>({
  selectId: (contractStandard) => contractStandard.tag_id,
});
export const {
  selectAll: selectAllSolution,
  selectIds: selectSolutionIds,
  selectById: selectSoutionById,
} = contractSolutionAdapter.getSelectors<RootState>((state) => state.preset.contractTag);

const initialState: Preset = {
  projectRole: projectRoleAdapter.getInitialState(),
  nodeSpec: nodeSpecAdapter.getInitialState(),
  paymentType: paymentTypeAdapter.getInitialState(),
  contractCategory: contractStandardAdapter.getInitialState(),
  contractTag: contractSolutionAdapter.getInitialState(),
};

const presetSlice = createSlice({
  name: "preset",
  initialState: initialState,
  reducers: {
    setProjectRole(state, action: PayloadAction<Array<ProjectRole>>) {
      projectRoleAdapter.setMany(state.projectRole, action.payload);
    },

    setNodeSpec(state, action: PayloadAction<Array<NodeSpec>>) {
      nodeSpecAdapter.setMany(state.nodeSpec, action.payload);
    },

    setPaymentType(state, action: PayloadAction<Array<PaymentType>>) {
      paymentTypeAdapter.setMany(state.paymentType, action.payload);
    },

    setContractStandard(state, action: PayloadAction<Array<ContractStandard>>) {
      contractStandardAdapter.setMany(state.contractCategory, action.payload);
    },
    setContractSolution(state, action: PayloadAction<Array<ContractSolution>>) {
      contractSolutionAdapter.setMany(state.contractTag, action.payload);
    },
  },
});

export const { setProjectRole, setNodeSpec, setPaymentType, setContractStandard, setContractSolution } =
  presetSlice.actions;

export default presetSlice.reducer;
