import { useCallback, useEffect, useMemo, useState } from "react";
import "./PaymentGatewayWithdrawal.scss";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import EQConnectWalletButton from "../../../../../modules/EQConnect/EQConnectWalletButton";
import { requestTransactionToExtension } from "../../../../../modules/EQExtension/utils/messageUtils";
import { connectInfo } from "../../../../../store/redux/EQconnect/EQConnectSlice";
import { makeQuery } from "../../../../../server/index/utils";
import {
  removeTransactionResult,
  transaction_result,
} from "../../../../../store/redux/transaction/transactionSlice";
import Table from "../../../../../legacy-common/atom/table/Table";
import BasicInput from "view/components/_atom/input/BasicInput";
import { addComma, errorAlert } from "../../../../../utils/Utils";
import { makeTransactionAction } from "../../../../../action/requestAction";
import PagingNumber from "../../../../components/_atom/paging/PagingNumber";
import { project_access } from "reducer/projectReducer";
import {
  getWithdrawalHistoryAction,
  retryWithdrawalAlertAction,
} from "../../../../../action/paymentGatewayAction";
import {
  COIN_CA,
  WITHDRAWAL_LIMIT,
  WITHDRAWAL_QUERY_DATA_FORMAT,
  WITHDRAWAL_STATUS,
} from "../constants/paymentGateway.data";
import ButtonContainer from "view/components/_atom/container/ButtonContainer";
import BasicButton from "view/components/_atom/button/BasicButton";
import {
  ButtonSize,
  ButtonTheme,
} from "view/components/_atom/button/StyledButton";
import {
  addressSimplificationConvertor,
  fromWeiDecimals,
} from "utils/convert.utils";
import RadioButton from "legacy-common/atom/button/RadioButton";
import sendIcon from "../../../../assets/images/Icon_send.png";
import retryIcon from "../../../../assets/images/Icon_retry.png";
import {
  ColumnManageField,
  ManageFieldContainer,
  ManageFieldLabel,
} from "view/components/_atom/section/ManageField";
import pgIcon from "../../../../assets/images/service/Icon_SNB_payment_gateway.png";

function PaymentGatewayWithdrawal(props) {
  const { paymentMethodDetail } = props;
  const dispatch = useDispatch();
  const { id: paymentMethodId } = useParams();
  const { address: connectedWalletAddress } = useSelector(connectInfo);
  const { rawTransactionResult } = useSelector(transaction_result);

  const [searchQuery, setSearchQuery] = useState(WITHDRAWAL_QUERY_DATA_FORMAT);

  const [totalWithdrawalCount, setTotalWithdrawalCount] = useState(null);
  const [withdrawalList, setWithdrawalList] = useState([]);
  const [isWithdrawalAction, setIsWithdrawalAction] = useState(false);
  const [currentPage, setCurrentPage] = useState("1");

  const setStartDate = (date) => {
    setSearchQuery((prevState) => {
      return {
        ...prevState,
        withdrawalStartDate: date,
      };
    });
  };

  const setEndDate = (date) => {
    setSearchQuery((prevState) => {
      return {
        ...prevState,
        withdrawalEndDate: date,
      };
    });
  };

  const setWithdrawalStatus = (status) => {
    setSearchQuery((prevState) => {
      return {
        ...prevState,
        withdrawalStatus: status,
      };
    });
  };

  const getWithdrawalHistory_ = async (page = 1) => {
    if (typeof currentPage === "string" && currentPage === "") {
      return;
    }

    const offset = (Number(page) - 1) * WITHDRAWAL_LIMIT;
    const { error, result } = await getWithdrawalHistoryAction(
      paymentMethodId,
      WITHDRAWAL_LIMIT,
      offset,
      { ...searchQuery }
    );
    if (result) {
      const { count, rows } = result;
      setTotalWithdrawalCount(count);
      setWithdrawalList(rows);
    }
    if (error) {
      errorAlert(error.data.message);
    }
  };

  const withdrawalResultHeaders = useMemo(
    () => [
      { title: "Time", accessor: "createdAt" },
      { title: "Account", accessor: "address" },
      { title: "Amount", accessor: "amount" },
      { title: "Withdrawal", accessor: "withdrawalTransactionStatus" },
      { title: "Callback", accessor: "withdrawalCallbackStatus" },
    ],
    []
  );

  const colGroup = useMemo(
    () => [20, 20, 20, 20, 20].map((el) => ({ style: { width: `${el}%` } })),
    []
  );

  const withdrawalResultRenderer = useCallback(
    (data) => ({
      createdAt: Boolean(data.createdAt) ? (
        <WithdrawalCreationDate date={data.createdAt} />
      ) : (
        "-"
      ),
      address: Boolean(data.address)
        ? addressSimplificationConvertor(data.address, 4)
        : "-",
      amount: Boolean(data.amount) ? (
        <ParseAmount
          amount={data.amount}
          decimals={paymentMethodDetail.wallet20?.decimals}
        />
      ) : (
        "-"
      ),
      withdrawalTransactionStatus: Boolean(data) ? (
        <WithdrawalTransactionStatus
          data={data}
          paymentMethodDetail={paymentMethodDetail}
        />
      ) : (
        "-"
      ),
      withdrawalCallbackStatus: Boolean(data) ? (
        <CallbackStatus
          data={data}
          setIsWithdrawalAction={setIsWithdrawalAction}
        />
      ) : (
        "-"
      ),
    }),
    [paymentMethodDetail, connectedWalletAddress]
  );

  const maximumPage = useMemo(() => {
    if (totalWithdrawalCount === 0) {
      return 1;
    }
    if (totalWithdrawalCount % WITHDRAWAL_LIMIT) {
      return parseInt(totalWithdrawalCount / WITHDRAWAL_LIMIT) + 1;
    } else {
      return parseInt(totalWithdrawalCount / WITHDRAWAL_LIMIT);
    }
  }, [totalWithdrawalCount]);

  const onClickSearch = async () => {
    setCurrentPage("1");
    getWithdrawalHistory_();
  };

  const onClickReset = async () => {
    setCurrentPage("1");
    setSearchQuery(WITHDRAWAL_QUERY_DATA_FORMAT);
  };

  useEffect(() => {
    if (isWithdrawalAction) {
      getWithdrawalHistory_(currentPage);
      setIsWithdrawalAction(false);
    }
  }, [isWithdrawalAction]);

  useEffect(() => {
    if (rawTransactionResult) {
      if (rawTransactionResult.tx_hash) {
        setIsWithdrawalAction(true);
        dispatch(removeTransactionResult());
      }
    }
  }, [rawTransactionResult]);

  return (
    <>
      {/* Filter Description :: (Optional) You can set conditions for the data shown in the 'Withdrawal info' area at the bottom. */}
      <ManageFieldContainer icon={pgIcon} title="Filter">
        <ColumnManageField>
          <ManageFieldLabel label="Date" />
          <div className="payment-gateway-date-picker-container">
            <BasicInput
              type="date"
              value={searchQuery.withdrawalStartDate}
              onChange={(e) => setStartDate(e.target.value)}
              max={moment(new Date()).format("YYYY-MM-DD")}
            />
            <span>~</span>
            <BasicInput
              type="date"
              value={searchQuery.withdrawalEndDate}
              onChange={(e) => setEndDate(e.target.value)}
              min={searchQuery.withdrawalStartDate}
              max={moment(new Date()).format("YYYY-MM-DD")}
            />
          </div>
        </ColumnManageField>
        <ColumnManageField>
          <ManageFieldLabel label="Withdrawal" />
          <div className="payment-gateway-status-option-container">
            {WITHDRAWAL_STATUS.map((option) => (
              <RadioButton
                key={`payment-gateway-withdrawal-option-${option.value}`}
                id={`withdrawal-option-${option.value}`}
                label={option.label}
                value={option.value}
                name={`payment-gateway-withdrawal`}
                onChange={(e) => setWithdrawalStatus(e.target.value)}
                checked={searchQuery.withdrawalStatus === option.value}
                labelStyle={{ padding: "0 0 0 25px" }}
              />
            ))}
          </div>
        </ColumnManageField>

        <div className="payment-gateway-withdrawal-filter-button-container">
          <ButtonContainer>
            <BasicButton
              theme={ButtonTheme.SECONDARY}
              size={ButtonSize.DYNAMIC}
              onClick={() => onClickReset()}
            >
              Reset
            </BasicButton>
            <BasicButton
              size={ButtonSize.DYNAMIC}
              onClick={() => onClickSearch()}
            >
              Search
            </BasicButton>
          </ButtonContainer>
        </div>
      </ManageFieldContainer>
      <div
        className={"flex justify-content-flex-end"}
        style={{ margin: "20px 0" }}
      >
        <EQConnectWalletButton />
      </div>
      {/* Withdrawal Info Description :: "Check out the details of coin that users want to withdraw through the Payment gateway.\nYou can also process approval for withdrawal requests." */}
      <ManageFieldContainer icon={pgIcon} title="Withdrawal Info">
        <div className={"flex-column"}>
          <Table
            headers={withdrawalResultHeaders}
            colGroup={colGroup}
            data={withdrawalList}
            renderer={withdrawalResultRenderer}
            style={{ cursor: "default" }}
          />
          <PagingNumber
            page={currentPage}
            setPage={setCurrentPage}
            maximumPage={maximumPage}
            getData={getWithdrawalHistory_}
          />
        </div>
      </ManageFieldContainer>
    </>
  );
}

export default PaymentGatewayWithdrawal;

// ------------------------------ COMPONENTS -------------------------------------

// -------------------------------------------------------------------------------
// -------------------------------------------------------------------------------
// DATE FORMAT COMPONENT
const WithdrawalCreationDate = ({ date }) => {
  return (
    <div className="payment-gateway-withdrawal-creation-date">
      <p className="payment-gateway-withdrawal-creation-date date">
        {moment(date).format("YY/MM/DD")}
      </p>
      <p className="payment-gateway-withdrawal-creation-date time">
        {moment(date).format("HH:mm:ss")}
      </p>
    </div>
  );
};

// -------------------------------------------------------------------------------
// -------------------------------------------------------------------------------
// WITHDRAWAL TX STATUS LABEL COMPONENT
const WithdrawalTransactionStatus = ({ data, paymentMethodDetail }) => {
  const { withdrawalTransaction } = data;
  const { status } = withdrawalTransaction;

  const { address: connectedWalletAddress } = useSelector(connectInfo);
  const token = useSelector(project_access);

  const withdrawalForCoin = async (query) => {
    const { result, error } = await makeTransactionAction({
      address: connectedWalletAddress,
      microChainId: paymentMethodDetail.microChainId,
      to: data.address,
      value: data.amount,
      functionName: "transfer",
      token: token,
      transactionPath: `${process.env.REACT_APP_HUB_SERVER_URL}${process.env.REACT_APP_API_VERSION}payment-gateway/request/transaction${query}`,
    });

    if (result) {
      requestTransactionToExtension({ ...result });
    }

    if (error) {
      switch (error.type) {
        case "API":
          errorAlert(error.data.message);
          break;
        case "Module":
          errorAlert(`${error.data.argument}::${error.data.code}`);
          break;
        default:
          errorAlert(error.data.message);
      }
    }
  };

  const withdrawalForToken = async (query) => {
    const { result, error } = await makeTransactionAction({
      address: connectedWalletAddress,
      microChainId: paymentMethodDetail.microChainId,
      to: paymentMethodDetail.currencyDetail.contractAddress,
      contractAddress: paymentMethodDetail.currencyDetail.contractAddress,
      parameters: [data.address, data.amount],
      value: "0x0",
      functionName: "transfer",
      token: token,
      transactionPath: `${process.env.REACT_APP_HUB_SERVER_URL}${process.env.REACT_APP_API_VERSION}payment-gateway/request/transaction${query}`,
    });
    if (result) {
      requestTransactionToExtension({ ...result });
    }
    if (error) {
      switch (error.type) {
        case "API":
          errorAlert(error.data.message);
          break;
        case "Module":
          errorAlert(`${error.data.argument}::${error.data.code}`);
          break;
        default:
          errorAlert(error.data.message);
      }
    }
  };

  const requestWithdrawal_ = async () => {
    if (!connectedWalletAddress) {
      toast.error("Please connect wallet");
      return;
    }

    const transactionQuery = makeQuery({
      microChainId: paymentMethodDetail.microChainId,
      withdrawalId: data.withdrawalId,
    });

    if (paymentMethodDetail.currencyDetail.contractAddress === COIN_CA) {
      withdrawalForCoin(transactionQuery);
    }

    if (paymentMethodDetail.currencyDetail.contractAddress !== COIN_CA) {
      withdrawalForToken(transactionQuery);
    }
  };

  return (
    <div className="payment-gateway-withdrawal-status">
      {status === 0 && (
        <div
          className="payment-gateway-withdrawal-status-label send"
          onClick={() => requestWithdrawal_()}
        >
          <span>Send</span>
          <img
            src={sendIcon}
            className="payment-gateway-withdrawal-status-label-icon"
            alt="send"
          />
        </div>
      )}
      {status === 1 && (
        <div className="payment-gateway-withdrawal-status-label processing">
          Processing
        </div>
      )}
      {status === 2 && (
        <div
          className="payment-gateway-withdrawal-status-label send"
          onClick={() => requestWithdrawal_()}
        >
          <span>Resend</span>
          <img
            src={retryIcon}
            className="payment-gateway-withdrawal-status-label-icon"
            alt="send"
          />
        </div>
      )}
      {status === 3 && (
        <div className="payment-gateway-withdrawal-status-label success">
          Success
        </div>
      )}
    </div>
  );
};

// -------------------------------------------------------------------------------
// -------------------------------------------------------------------------------
// CALLBACK STATUS LABEL COMPONENT
const CallbackStatus = ({ data, setIsWithdrawalAction }) => {
  const { withdrawalAlert } = data;
  const { status } = withdrawalAlert;

  const retryWithdrawalAlert_ = async () => {
    const { error, result } = await retryWithdrawalAlertAction(
      data.withdrawalId
    );
    if (result) {
      setIsWithdrawalAction(true);
    }
    if (error) {
      errorAlert(error.data.message);
    }
  };

  return (
    <div className="payment-gateway-withdrawal-status">
      {status === 0 && (
        <div className="payment-gateway-withdrawal-status-label">-</div>
      )}
      {status === 1 && (
        <div className="payment-gateway-withdrawal-status-label processing">
          Processing
        </div>
      )}
      {status === 2 && (
        <div
          className="payment-gateway-withdrawal-status-label send"
          onClick={() => retryWithdrawalAlert_()}
        >
          <span>Retry</span>
          <img
            src={retryIcon}
            className="payment-gateway-withdrawal-status-label-icon"
            alt="send"
          />
        </div>
      )}
      {status === 3 && (
        <div className="payment-gateway-withdrawal-status-label success">
          Success
        </div>
      )}
    </div>
  );
};

// -------------------------------------------------------------------------------
// -------------------------------------------------------------------------------
// ParseAmount COMPONENT
const ParseAmount = (props) => {
  const { amount, decimals } = props;

  return <span>{addComma(fromWeiDecimals(amount, decimals))}</span>;
};
