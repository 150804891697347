import { useState } from "react";
import "./CreatePaymentGateway.scss";
import PageArticle from "../../../../components/_template/page/PageArticle";
import PageTitle from "../../../../components/_molecules/page/PageTitle";
import PaymentGatewayTypeSetting from "../component/PaymentGatewayTypeSetting";
import PaymentGatewaySettingForSC from "../component/PaymentGatewaySettingForSC";
import PaymentGatewaySettingForVA from "../component/PaymentGatewaySettingForVA";

function CreatePaymentGateway() {
  const [paymentGatewayType, setPaymentGatewayType] = useState();
  const [currentStep, setCurrentStep] = useState(1);

  return (
    <PageArticle>
      <PageTitle size={810} title={"Create Payment Gateway"} />
      <div className="page-layout-810">
        {currentStep === 1 && <PaymentGatewayTypeSetting setCurrentStep={setCurrentStep} setPaymentGatewayType={setPaymentGatewayType} />}
        {currentStep === 2 && paymentGatewayType === "account" && (
          <PaymentGatewaySettingForVA setCurrentStep={setCurrentStep} paymentGatewayType={paymentGatewayType} />
        )}
        {currentStep === 2 && paymentGatewayType === "contract" && (
          <PaymentGatewaySettingForSC setCurrentStep={setCurrentStep} paymentGatewayType={paymentGatewayType} />
        )}
      </div>
    </PageArticle>
  );
}

export default CreatePaymentGateway;
