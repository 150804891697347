import "./Input.scss";

const Input = (props) => {
  const { type = "text", comment, onChange, placeholder, style, description, className = "", wrapperStyle, ...rest } = props || {};

  return (
    <div className={"input"} style={wrapperStyle && wrapperStyle}>
      <input
        type={type}
        placeholder={placeholder}
        className={`${className} atom-input`}
        onChange={onChange}
        style={{ ...style, borderColor: !Boolean(comment) ? "#E7E9EF" : "#DE1F1F" }}
        {...rest}
      />
      {Boolean(description) && <p className="input-description">{description}</p>}
      {Boolean(comment) && <div className="input-invalid-comment">{comment}</div>}
    </div>
  );
};

export default Input;
