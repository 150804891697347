import React, { useCallback } from "react";
import { useEffect, useRef, useState } from "react";
import Button from "../../../../legacy-common/atom/button/Button";
import toast from "react-hot-toast";
import "./FileUpload.scss";

const FileUpload = ({
  file,
  setFile,
  fileType,
  setFileType,
  allowExtension,
  disabled,
  previewStyle,
  comment = "",
  buttonText = "Upload",
  maxFileSize = 100 * 1024 ** 2,
  previewLabel = "preview",
}) => {
  const fileRef = useRef(null);
  const previewRef = useRef(null);
  const [fileURL, setFileURL] = useState("");

  const uploadFile = () => {
    if (fileRef.current.files[0]) {
      const file = fileRef.current.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        if (file.size > maxFileSize) {
          toast.error("File is over max size");
        } else {
          const fileType = file.type;
          const originFileType = fileType.split("/")[0];
          const extension = fileType.split("/")[1];
          if (allowExtension[originFileType].includes(extension)) {
            setFile(null);
            setFile(file);
            setFileType(originFileType);
          } else {
            toast.error("File type mismatch");
          }
        }
      };
    }
  };

  useEffect(() => {
    if (file) {
      if (typeof file === "string") {
        setFileURL(file);
      } else {
        setFileURL(URL.createObjectURL(file));
      }
    } else {
      setFileURL("");
    }
  }, [file]);
  //
  // const fileRenderer = (fileURL) => {
  //   switch (fileType) {
  //     case "image":
  //       return (
  //         <div className="preview" style={{ ...previewStyle }} onClick={() => (disabled ? undefined : fileRef.current.click())}>
  //           <img src={fileURL} className={"preview-image"} style={{ cursor: disabled ? "default" : "pointer" }} alt={"file"} />
  //         </div>
  //       );
  //     case "video":
  //       return (
  //         <div className="preview" style={{ ...previewStyle }}>
  //           <video controls className={"preview-video"}>
  //             <source src={fileURL} />
  //           </video>
  //         </div>
  //       );
  //     case "audio":
  //       return <audio controls className={"preview-audio"} src={fileURL} />;
  //   }
  // }

  return (
    <div className="file-uploader">
      <input
        name={"file-uploader"}
        type="file"
        accept={`${Object.keys(allowExtension)
          .map((el) => `${el}/*`)
          .join(",")}`}
        ref={fileRef}
        onChange={(e) => {
          uploadFile(e);
        }}
      />
      {file ? (
        <>
          {fileType === "image" && (
            <div className="preview" style={{ ...previewStyle }} onClick={() => (disabled ? undefined : fileRef.current.click())}>
              <img src={fileURL} className={"preview-image"} style={{ cursor: disabled ? "default" : "pointer" }} alt={"file"} />
            </div>
          )}
          {fileType === "video" && (
            <div className="preview" style={{ ...previewStyle }}>
              <video controls className={"preview-video"}>
                <source src={fileURL} />
              </video>
            </div>
          )}
          {fileType === "audio" && <audio controls className={"preview-audio"} src={fileURL} />}
        </>
      ) : (
        <div className="preview" style={{ ...previewStyle }} onClick={() => (disabled ? undefined : fileRef.current.click())}>
          {previewLabel}
        </div>
      )}
      {!disabled && <div className="upload-comment">{comment}</div>}
      {!disabled ? <Button className={"round main"} text={buttonText} onClick={() => fileRef.current.click()} /> : null}
    </div>
  );
};

export default FileUpload;
