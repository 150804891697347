import { getCurrencyFromServer } from "logic/services/currency/server";
import { actionController } from "../utils";

export const currencyController = {
  getCurrency: (
    currencyIds?: number[],
    originNetworkId?: number,
    originMicroChainIds?: number[],
    originOwnerId?: number,
    type?: number[],
    mainStatus?: number[],
    subStatus?: number[]
  ) => {
    return actionController(async () => {
      try {
        const currency = await getCurrencyFromServer({
          query: {
            ...(currencyIds && { currencyIds: currencyIds }),
            ...(originNetworkId && { originNetworkId: originNetworkId }),
            ...(originMicroChainIds && {
              originMicroChainIds: originMicroChainIds,
            }),
            ...(originOwnerId && { originOwnerId: originOwnerId }),
            ...(type && { type: type }),
            ...(mainStatus && { mainStatus: mainStatus }),
            ...(subStatus && { subStatus: subStatus }),
          },
        });
        const currencyData = {
          currencyId: currency.currency_id,
          name: currency.name,
          image: currency.image,
          unit: currency.unit,
          symbol: currency.symbol,
          originNetworkId: currency.origin_network_id,
          originMicroChainId: currency.origin_micro_chain_id,
          originOwnerId: currency.origin_owner_id,
          type: currency.type,
          mainStatus: currency.main_status,
          subStatus: currency.sub_status,
          currencyDetail: {
            initialSupply: currency.currency_detail.initial_supply,
            officialSite: currency.currency_detail.official_site,
            mintable: currency.currency_detail.mintable,
            burnable: currency.currency_detail.burnable,
          },
        };
        return currencyData;
      } catch (e) {
        return e;
      }
    });
  },
};
