import React, { ReactNode } from "react";
import "./SectionContainer.scss";
import QuestionMark from "../tooltip/QuestionMark";

type SectionContainerProps = {
  icon: string;
  title: string;
  children?: ReactNode;
  element?: any;
  announcement?: any;
};

const SectionContainer = (props: SectionContainerProps) => {
  const { icon, title, announcement, element, children } = props;

  return (
    <div className="section-container">
      <div className="section-container-header">
        <div className="section-container-header-info">
          <div className="section-container-header-info-icon">
            <img src={icon} className="section-container-header-info-icon-src" alt="" />
          </div>
          <div className="section-container-header-info-title">{title}</div>
          {announcement && <QuestionMark announcement={announcement} />}
        </div>
        {element && <div className="section-container-header-element">{element}</div>}
      </div>
      {children && <div className="section-container-body">{children && children}</div>}
    </div>
  );
};

export default SectionContainer;
