import {
  confirmMicroChainAssetFromServer,
  confirmWalletFromServer,
  createMicroChainAssetWalletFromServer,
  createWalletFromServer,
  deleteMicroChainAssetFromServer,
  deleteWalletFromServer,
  getAssetsFromServer,
  getMicroChainAssetDetailFromServer,
  getMicroChainAssetListFromServer,
  getMicroChainAssetRejectReasonFromServer,
  getWalletDefaultImagesFromServer,
  getWalletRejectReasonFromServer,
  rejectMicroChainAssetFromServer,
  rejectWalletFromServer,
  releaseMicroChainAssetFromServer,
  releaseWalletFromServer,
  submitMicroChainAssetFromServer,
  submitWalletFromServer,
  uploadWalletImageFromServer,
} from "../server/API/walletAPI";
import { getMicroChainCurrencyDetailFromServer } from "../server/API/currencyAPI";
import { WALLET_TYPE_LIST } from "../view/service/tool/wallet/constants/walletConstants";

export const getWalletDefaultImageListParser = async (): Promise<any> => {
  const result = await getWalletDefaultImagesFromServer();

  const response = result.map((el: any) => ({
    url: el.url,
    walletDefaultImageId: el.wallet_default_image_id,
  }));

  return response;
};

export const getAssetTypeListParser = async (): Promise<any> => {
  const result = await getAssetsFromServer();

  const response = result.map((el: any) => ({
    assetId: el.asset_id,
    name: el.name,
    label: WALLET_TYPE_LIST.find((wallet: any) => wallet.id === el.asset_id)?.label ?? "",
  }));

  return response;
};

export const getMicroChainAssetListParser = async (options?: {
  projectId?: number;
  creatorId?: number;
  mainStatus?: number[];
  microChainId?: number;
  assetId?: number;
}): Promise<any> => {
  const result = await getMicroChainAssetListFromServer({
    query: {
      ...(options?.projectId && { projectId: options.projectId }),
      ...(options?.creatorId && { creatorId: options.creatorId }),
      ...(options?.mainStatus && { mainStatus: options.mainStatus }),
      ...(options?.microChainId && { microChainId: options.microChainId }),
      ...(options?.assetId && { assetId: options.assetId }),
    },
  });
  const walletListWithFilteredToken = result.filter((el: any) => el.asset_id !== 2);

  const response = walletListWithFilteredToken
    .map((el: any) => {
      return {
        assetId: el.asset_id,
        createdAt: el.created_at,
        creatorAccountId: el.creator_account_id,
        endPoint: el.end_point,
        isDefault: el.is_default,
        mainStatus: el.main_status,
        microChainAssetId: el.micro_chain_asset_id,
        microChainId: el.micro_chain_id,
        subStatus: el.sub_status,
        type: WALLET_TYPE_LIST.find((item) => item.id === el.asset_id)?.label ?? "Coin & Token(ERC-20)",
      };
    })
    .slice()
    .reverse();

  return response;
};

export const createMicroChainAssetWalletParser = async (microChainId: number, assetId: number): Promise<any> => {
  const result = await createMicroChainAssetWalletFromServer({
    data: {
      microChainId,
      assetId,
    },
  });

  const response = {
    assetId: result.asset_id,
    createdAt: result.created_at,
    creatorAccountId: result.creator_account_id,
    deletedAt: result.deleted_at,
    endPoint: result.end_point,
    isDefault: result.is_default,
    mainStatus: result.main_status,
    microChainAssetId: result.micro_chain_asset_id,
    microChainId: result.micro_chain_id,
    requestId: result.request_id,
    subStatus: result.sub_status,
    updatedAt: result.updated_at,
  };
  return response;
};

export const getMicroChainAssetDetailParser = async (microChainAssetId: number): Promise<any> => {
  const result = await getMicroChainAssetDetailFromServer({
    params: {
      microChainAssetId,
    },
  });
  const response = {
    assetId: result.asset_id,
    backgroundImage: result.background_image,
    createdAt: result.created_at,
    creatorAccountId: result.creator_account_id,
    deletedAt: result.deleted_at,
    endPoint: result.end_point,
    fontColor: result.font_color,
    isDefault: result.is_default,
    mainStatus: result.main_status,
    microChainAssetId: result.micro_chain_asset_id,
    microChainId: result.micro_chain_id,
    requestId: result.request_id,
    subStatus: result.sub_status,
    updatedAt: result.updated_at,
    type: WALLET_TYPE_LIST.find((item: any) => item.id === result.asset_id)?.label ?? "Coin & Token(ERC-20)",
  };

  return response;
};

export const getMicroChainAssetRejectReasonParser = async (microChainAssetId: number): Promise<string> => {
  const result = await getMicroChainAssetRejectReasonFromServer({
    params: {
      microChainAssetId,
    },
  });

  return result.reject_reason;
};

export const submitMicroChainAssetParser = async (microChainAssetId: number): Promise<any> => {
  return await submitMicroChainAssetFromServer({
    params: {
      microChainAssetId,
    },
  });
};

export const rejectMicroChainAssetParser = async (microChainAssetId: number, rejectReason: string): Promise<any> => {
  return await rejectMicroChainAssetFromServer({
    params: {
      microChainAssetId,
    },
    data: {
      rejectReason,
    },
  });
};

export const confirmMicroChainAssetParser = async (microChainAssetId: number): Promise<any> => {
  return await confirmMicroChainAssetFromServer({
    params: {
      microChainAssetId,
    },
  });
};

export const releaseMicroChainAssetParser = async (projectId: number, microChainAssetId: number): Promise<any> => {
  return await releaseMicroChainAssetFromServer({
    params: {
      microChainAssetId,
    },
    // data: {
    //   projectId,
    // },
  });
};

export const deleteMicroChainAssetParser = async (microChainAssetId: number): Promise<any> => {
  const result = await deleteMicroChainAssetFromServer({
    params: {
      microChainAssetId,
    },
  });
  //TODO:: 삭제 요청에 대한 결과가 서버팀에서 변경시 해당 if문 로직 삭제
  if (result === undefined) {
    return { success: true };
  }
  return result;
};

// --------------------------------------------------------------------------------------------

export const uploadWalletImageParser = async (formData: FormData, fileCount: number): Promise<any> => {
  const result = await uploadWalletImageFromServer({
    query: {
      fileCount,
    },
    data: formData,
  });

  return result;
};

export const createWalletParser = async (microChainId: number, backgroundImage: string, fontColor: string): Promise<any> => {
  const result = await createWalletFromServer({
    data: {
      microChainId,
      backgroundImage,
      fontColor,
    },
  });

  const response = {
    assetId: result.asset_id,
    createdAt: result.created_at,
    creatorAccountId: result.creator_account_id,
    deletedAt: result.deleted_at,
    endPoint: result.end_point,
    isDefault: result.is_default,
    mainStatus: result.main_status,
    microChainAssetId: result.micro_chain_asset_id,
    microChainId: result.micro_chain_id,
    requestId: result.request_id,
    subStatus: result.sub_status,
    updatedAt: result.updated_at,
  };

  return response;
};

export const getWalletRejectReasonParser = async (microChainId: number): Promise<any> => {
  const result = await getWalletRejectReasonFromServer({
    params: {
      microChainId,
    },
  });
  const response = {
    rejectReason: result[0].reject_reason,
  };

  return response;
};

export const submitWalletParser = async (microChainId: number): Promise<any> => {
  return await submitWalletFromServer({
    params: {
      microChainId,
    },
  });
};

export const rejectWalletParser = async (microChainId: number, rejectReason: string): Promise<any> => {
  return await rejectWalletFromServer({
    params: {
      microChainId,
    },
    data: {
      rejectReason,
    },
  });
};

export const confirmWalletParser = async (microChainId: number): Promise<any> => {
  return await confirmWalletFromServer({
    params: {
      microChainId,
    },
  });
};

export const releaseWalletParser = async (microChainId: number): Promise<any> => {
  return await releaseWalletFromServer({
    params: {
      microChainId,
    },
  });
};

export const deleteWalletParser = async (microChainId: number): Promise<any> => {
  return await deleteWalletFromServer({
    params: {
      microChainId,
    },
  });
};

export const getMicroChainCurrencyDetailParser = async (microChainCurrencyId: number): Promise<any> => {
  const result = await getMicroChainCurrencyDetailFromServer({
    params: {
      microChainCurrencyId,
    },
  });
  const response = {
    contractAddress: result.contract_address,
    contractId: result.contract_id,
    currency: {
      initialSupply: result.currency.currency_detail.initial_supply,
      currencyId: result.currency.currency_id,
      image: result.currency.image,
      name: result.currency.name,
      originMicroChainId: result.currency.origin_micro_chain_id,
      originNetworkId: result.currency.origin_network_id,
      originOwnerId: result.currency.origin_owner_id,
      symbol: result.currency.symbol,
      type: result.currency.type,
    },
    microChainCurrencyId: result.micro_chain_currency_id,
    microChainId: result.micro_chain_id,
    type: result.type,
  };

  return response;
};
