import { makeQuery } from "../index/utils";
import { v2ServerByProjectAuth } from "../index/serverInstances";

//----------createERC721
const createERC721 = async (request: CreateERC721Request): Promise<CreateERC721Response> =>
  await v2ServerByProjectAuth.post({
    path: "wallet-manage/erc721",
    data: request.data,
  });

type CreateERC721Request = {
  data: {
    microChainId: number;
    symbol: string;
    name: string;
    description: string;
    image: string;
    mintable: boolean;
    burnable: boolean;
    officialSite: Array<string>;
  };
};

type CreateERC721Response = any;

//----------getERC721List
const uploadERC721Image = async (request: UploadERC721ImageRequest): Promise<UploadERC721ImageResponse> =>
  await v2ServerByProjectAuth.post({
    path: `wallet-manage/erc721/${request.params.fileCount}/upload`,
    data: request.data,
  });

type UploadERC721ImageRequest = {
  params: {
    fileCount: number;
  };
  data: FormData;
};

type UploadERC721ImageResponse = any;

//----------getERC721List
const getERC721List = async (): Promise<GetERC721ListResponse> =>
  await v2ServerByProjectAuth.get({
    path: "wallet-manage/erc721/me",
  });

type GetERC721ListResponse = any;

//----------getERC721Detail
const getERC721Detail = async (request: GetERC721DetailRequest): Promise<GetERC721DetailResponse> =>
  await v2ServerByProjectAuth.get({
    path: `wallet-manage/erc721/${request.params.erc721Id}`,
  });

type GetERC721DetailRequest = {
  params: {
    erc721Id: number;
  };
};
type GetERC721DetailResponse = any;

//----------updateERC721
const updateERC721 = async (request: UpdateERC721Request): Promise<UpdateERC721Response> =>
  await v2ServerByProjectAuth.put({
    path: `wallet-manage/erc721/${request.params.erc721Id}`,
    data: request.data,
  });

type UpdateERC721Request = {
  params: {
    erc721Id: number;
  };
  data: {
    name: string;
    description: string;
    image: string;
    officialSite: Array<string>;
    symbol: string;
  };
};

type UpdateERC721Response = any;

//----------deleteERC721
const deleteERC721 = async (request: DeleteERC721Request): Promise<DeleteERC721Response> =>
  await v2ServerByProjectAuth.delete({
    path: `wallet-manage/erc721/${request.params.erc721Id}`,
  });

type DeleteERC721Request = {
  params: {
    erc721Id: number;
  };
};

type DeleteERC721Response = any;

//----------createERC721Contract
const createERC721Contract = async (request: CreateERC721ContractRequest): Promise<CreateERC721ContractResponse> =>
  await v2ServerByProjectAuth.post({
    path: `wallet-manage/erc721/${request.params.erc721Id}/contract`,
  });

type CreateERC721ContractRequest = {
  params: {
    erc721Id: number;
  };
};

type CreateERC721ContractResponse = any;

//------------------------------------ERC721 token

//----------getERC721Tokens
const getERC721Tokens = async (request: GetERC721TokensRequest): Promise<GetERC721TokensResponse> =>
  await v2ServerByProjectAuth.get({
    path: `wallet-manage/erc721-token-temps`,
    query: makeQuery(request.query),
  });

type GetERC721TokensRequest = {
  query: {
    erc721TempId: number;
  };
};

type GetERC721TokensResponse = any;

//----------getERC721TokenDetail
const getERC721TokenDetail = async (request: GetERC721TokenDetailRequest): Promise<GetERC721TokenDetailResponse> =>
  await v2ServerByProjectAuth.get({
    path: `wallet-manage/erc721-token-temps/${request.params.erc721TokenId}`,
  });

type GetERC721TokenDetailRequest = {
  params: {
    erc721TokenId: number;
  };
};

type GetERC721TokenDetailResponse = any;

//----------getERC721TokenDetail
const uploadNFTContent = async (request: UploadNFTContentRequest): Promise<UploadNFTContentResponse> =>
  await v2ServerByProjectAuth.post({
    path: `wallet-manage/erc721-token-temps-upload`,
    query: makeQuery(request.query),
    data: request.data,
  });

type UploadNFTContentRequest = {
  query: {
    fileCount: number;
  };
  data: FormData;
};

type UploadNFTContentResponse = any;

export {
  createERC721 as createERC721FromServer,
  uploadERC721Image as uploadERC721ImageFromServer,
  getERC721List as getERC721ListFromServer,
  getERC721Detail as getERC721DetailFromServer,
  updateERC721 as updateERC721FromServer,
  deleteERC721 as deleteERC721FromServer,
  createERC721Contract as createERC721ContractFromServer,
  getERC721Tokens as getERC721TokensFromServer,
  getERC721TokenDetail as getERC721TokenDetailFromServer,
  uploadNFTContent as uploadNFTContentFromServer,
};
