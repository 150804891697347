import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ListTypeB from "../../../../components/_atom/list/ListTypeB";
import PageLoading from "../../../../components/_atom/loading/PageLoading";
import EmptyListTemplate from "../../../../components/_template/emptyList/EmptyListTemplate";
import { addComma, errorAlert } from "../../../../../utils/Utils";
import { selected_project_id } from "reducer/projectReducer";
import { getAirdropListAction } from "action/airdropAction";
import PageTitle from "view/components/_molecules/page/PageTitle";
import PageArticle from "view/components/_template/page/PageArticle";
import BasicButton from "view/components/_atom/button/BasicButton";
import { ButtonTheme } from "view/components/_atom/button/StyledButton";
import { ButtonSize } from "view/components/_atom/button/StyledButton";
import useLoading from "hooks/useLoading";

const AirdropList = () => {
  const navigate = useNavigate();
  const projectId = useSelector(selected_project_id);

  const [airdropList, setAirdropList] = useState([]);

  const airdropProjectDetailIndex = [
    { label: "Network / Microchain", accessor: "chain" },
    { label: "Budget", accessor: "depositAmount" },
    { label: "Contract address", accessor: "contractAddress" },
    { label: "Project purpose", accessor: "projectPurpose" },
  ];

  const airdropProjectDetailRenderer = useCallback(
    (data) => ({
      chain: `${data.networkLabel} / ${data.microchainLabel}`,
      depositAmount: `${addComma(data.depositAmount)}`,
      contractAddress: data.contractAddress ?? "-",
      projectPurpose: data.operationPurposeLabel,
    }),
    []
  );

  const onClickAirdropProject = (el) => {
    navigate(`/application/airdrop/manage/${el.airdropId}`);
  };

  const getAirdropList_ = async () => {
    const { result, error } = await getAirdropListAction();
    if (result) {
      setAirdropList(result);
    }
    if (error) {
      errorAlert(error);
    }
  };

  const { loading } = useLoading({
    dependency: [projectId],
    synchronousFunction: async () => {
      await getAirdropList_();
    },
  });

  if (loading) {
    return <PageLoading />;
  }
  return (
    <PageArticle>
      <PageTitle title={"Airdrop Project List"} description={"Create and manage your Airdrop Projects"} size={1240} />
      <div className="page-layout-1240">
        <BasicButton theme={ButtonTheme.CREATE} size={ButtonSize.EXTRALARGE} onClick={() => navigate("/application/airdrop/create")}>
          Create a new Airdrop project
        </BasicButton>
        <div style={{ height: 30 }} />
        {airdropList?.length > 0 && (
          <ListTypeB
            titleKey={"projectName"}
            imageKey={"projectImage"}
            listElement={airdropList}
            renderIndex={airdropProjectDetailIndex}
            renderer={airdropProjectDetailRenderer}
            onClick={onClickAirdropProject}
            containerStyle={{ width: "160px", height: "160px" }}
          />
        )}
        {airdropList?.length <= 0 && <EmptyListTemplate description={"You do not have an airdrop project yet."} />}
      </div>
    </PageArticle>
  );
};

export default AirdropList;
