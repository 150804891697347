import React, { useState } from "react";
import "./EmailVerificationResult.scss";
import invalidImage from "../../../../assets/images/Image_invalid.png";
import validImage from "../../../../assets/images/Image_valid.png";
import { useLocation, useNavigate } from "react-router-dom";
import qs from "qs";
import toast from "react-hot-toast";
import PageLoading from "../../../../components/_atom/loading/PageLoading";
import { verifyAuthCodeAction } from "../../../../../action/userAction";
import BasicButton from "view/components/_atom/button/BasicButton";
import { logout } from "server/index/utils";
import useLoading from "hooks/useLoading";
import logo from "../../../../assets/images/signup_logo_EQHub.png";
import InitialNoticeTemplate from "view/service/initialize/components/InitialNoticeTemplate";
import ButtonContainer from "view/components/_atom/container/ButtonContainer";

function EmailVerificationResult() {
  const location = useLocation();
  const navigate = useNavigate();
  const { authCode } = qs.parse(location.search, { ignoreQueryPrefix: true });
  const [verifyStatus, setVerifyStatus] = useState<boolean>(false);

  const verifyEmail_ = async () => {
    const { result, error } = await verifyAuthCodeAction(authCode as string);
    if (result) {
      setVerifyStatus(true);
    }
    if (error) {
      setVerifyStatus(false);
    }
  };

  const logout_ = async () => {
    try {
      await logout();
      navigate("/initialize");
    } catch (e) {}
  };

  const checkAuthCode = async () => {
    if (!Boolean(authCode)) {
      toast.error("Invalid Access");
      navigate("/initialize");
      return;
    }

    await verifyEmail_();
  };

  const { loading } = useLoading({
    dependency: [authCode],
    synchronousFunction: async () => {
      await checkAuthCode();
    },
  });

  if (loading) {
    return <PageLoading />;
  }

  return (
    <div className="email-verification-container">
      <img className="email-verification-logo" src={logo} alt="" />
      {verifyStatus && <ValidVerifyNotice logout_={logout_} />}
      {!verifyStatus && <InvalidVerifyNotice logout_={logout_} />}
    </div>
  );
}

export default EmailVerificationResult;

type VerifyNoticeProps = {
  logout_: Function;
};
const ValidVerifyNotice = (props: VerifyNoticeProps) => {
  const { logout_ } = props;

  return (
    <InitialNoticeTemplate image={validImage} title="Sign In">
      <div className="email-verification-content">
        <div className="email-verification-content-description">
          {`Your email address has been verified.\nAll EQ Hub services are available after signing in.`}
        </div>
        <div className="email-verification-button-container">
          <ButtonContainer>
            <BasicButton onClick={() => logout_()}>Done</BasicButton>
          </ButtonContainer>
        </div>
      </div>
    </InitialNoticeTemplate>
  );
};

const InvalidVerifyNotice = (props: VerifyNoticeProps) => {
  const { logout_ } = props;

  return (
    <InitialNoticeTemplate image={invalidImage} title="Sign In">
      <div className="email-verification-content">
        <div className="email-verification-content-description">{`The link in the email has expired.\nPlease sign in to verify your email.`}</div>
        <div className="email-verification-button-container">
          <ButtonContainer>
            <BasicButton onClick={() => logout_()}>Done</BasicButton>
          </ButtonContainer>
        </div>
      </div>
    </InitialNoticeTemplate>
  );
};
