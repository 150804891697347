import React, { useMemo, useState } from "react";
import "./NetworkNodeAllocField.scss";
import { convertPercentToValue } from "../../util/networkUtils";
import { FIRST_DECIMAL_REG_EXP, FLOAT_REG_EXP } from "constants/commonConstants";
import { ColumnField, FieldContainer } from "view/components/_atom/section/Field";
import CreateNewKeyModal from "view/components/_modal/CreateNewKeyModal";
import BasicInput from "view/components/_atom/input/BasicInput";
import { removeComma } from "utils/Utils";
import BasicButton from "view/components/_atom/button/BasicButton";
import { ButtonSize } from "view/components/_atom/button/StyledButton";
import keyIcon from "../../../../../assets/images/Icon_key.png";
import { nodeEqualDistribution } from "../../constants/network.utils";
import ButtonContainer from "view/components/_atom/container/ButtonContainer";
import KeystoreFileSettingModal from "../atom/KeystoreFileSettingModal";
import allocationIcon from "../../../../../assets/images/Icon_allocation.png";
import deleteIcon from "../../../../../assets/images/atom/icon-FileDragAndDrop-delete.png";
import { addressSimplificationConvertor } from "utils/convert.utils";

const NetworkEquilibriumNodeAllocField = (props) => {
  const { networkData, setNetworkData, nodeAllocatedAmount = 0 } = props;

  const [openCreateKeyModal, setOpenCreateKeyModal] = useState<Boolean>(false);

  const colGroup = useMemo(() => [20, 45, 35].map((el) => ({ style: { width: `${el}%` } })), []);

  const setNodeAlloc = (nodeAlloc: Array<any>) => {
    setNetworkData((prevState) => ({
      ...prevState,
      nodeAlloc,
    }));
  };

  const setEmptyAmount = (index: number) => {
    return networkData.nodeAlloc.map((el, i) => {
      if (index === i) {
        return {
          ...el,
          mmcBalancePercent: "",
          mmcBalance: "0",
          mmcStaking: "0",
        };
      } else {
        return el;
      }
    });
  };

  const setMaximumAmount = (index: number) => {
    const balance = convertPercentToValue(nodeAllocatedAmount.toString(), "100");
    return networkData.nodeAlloc.map((el, i) => {
      if (index === i) {
        return {
          ...el,
          mmcBalancePercent: "100",
          mmcBalance: balance,
          mmcStaking: balance,
        };
      } else {
        return el;
      }
    });
  };

  const setGeneralAmount = (percent, index: number) => {
    const balance = convertPercentToValue(nodeAllocatedAmount.toString(), percent);
    return networkData.nodeAlloc.map((el, i) => {
      if (index === i) {
        return {
          ...el,
          mmcBalancePercent: percent,
          mmcBalance: balance,
          mmcStaking: balance,
        };
      } else {
        return el;
      }
    });
  };

  const setNodeAllocPercent = (value, index: number) => {
    if (value === "") {
      const result = setEmptyAmount(index);
      setNodeAlloc(result);
      return;
    }

    if (!FLOAT_REG_EXP.test(value)) {
      return;
    }

    if (!FIRST_DECIMAL_REG_EXP.test(value.toString())) {
      return;
    }

    if (Number(value) > 100) {
      const result = setMaximumAmount(index);
      setNodeAlloc(result);
      return;
    }

    const result = setGeneralAmount(value, index);
    setNodeAlloc(result);
  };

  const onClickEqualdistribution = () => {
    const { firstNode, restNode } = nodeEqualDistribution(removeComma(networkData.microChainSetting.initialSupply), networkData.nodeAlloc.length.toString());
    const nodeAllocList = networkData.nodeAlloc.map((el, index) => {
      if (index === 0) {
        return {
          ...el,
          mmcBalancePercent: firstNode.mmcBalancePercent,
          mmcBalance: firstNode.balance,
          mmcStaking: firstNode.balance,
        };
      }
      return {
        ...el,
        mmcBalancePercent: restNode.mmcBalancePercent,
        mmcBalance: restNode.balance,
        mmcStaking: restNode.balance,
      };
    });
    setNetworkData((prevState) => ({
      ...prevState,
      nodeAlloc: nodeAllocList,
    }));
  };

  return (
    <>
      {openCreateKeyModal && <CreateNewKeyModal title={"Create a new key"} isOpen={openCreateKeyModal} closeModal={() => setOpenCreateKeyModal(false)} />}
      <FieldContainer
        icon={allocationIcon}
        title="Node Setting"
        description={`To operate the network, data is set for each node. The data set here may vary depending on the Blockchain Engine settings set in the previous step.
Keystore file is uploaded by each node to enter key information required to participate in consensus.
Staking amount or Validator sets conditions for nodes to participate in consensus.`}
      >
        <div className="node-allocation-button-wrapper">
          <ButtonContainer>
            <BasicButton size={ButtonSize.DYNAMIC} style={{ padding: "8px", width: "fit-content", fontSize: 14 }} onClick={() => setOpenCreateKeyModal(true)}>
              <span style={{ fontWeight: 500 }}>Create a new key</span>
              <img src={keyIcon} className="node-setting-distribute-icon" alt="" />
            </BasicButton>
            {/* <BasicButton size={ButtonSize.DYNAMIC} style={{ padding: "8px", width: "fit-content", fontSize: 14 }} onClick={() => onClickEqualdistribution()}>
              <span style={{ fontWeight: 500 }}>Distribute equally</span>
              <img src={pieIcon} className="node-setting-distribute-icon" alt="" />
            </BasicButton> */}
          </ButtonContainer>
        </div>
        <ColumnField>
          <table className="node-allocation-table">
            <colgroup style={{ padding: "0 40px" }}>
              {colGroup.map((col, index) => (
                <col key={`col-${index}`} {...col} />
              ))}
            </colgroup>
            <thead>
              <tr>
                <th>
                  <span>Name</span>
                </th>
                <th>
                  <span>Keystore File</span>
                </th>
                <th>
                  <span>Staking Amount</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {networkData.nodeAlloc?.length > 0 &&
                networkData.nodeAlloc.map((row, index: number) => (
                  <NodeAllocationTableRow
                    key={`node-allocation-table-row-${index}`}
                    row={row}
                    index={index}
                    networkData={networkData}
                    setNetworkData={setNetworkData}
                    setNodeAllocPercent={setNodeAllocPercent}
                  />
                ))}
              {networkData.nodeAlloc?.length === 0 && (
                <tr className="table-no-data">
                  <td colSpan={colGroup.length}>{`Choose an option for node setup above`}</td>
                </tr>
              )}
            </tbody>
          </table>
        </ColumnField>
        <ColumnField>
          <div className="network-equilibrium-node-alloc-field-notice">
            <div className="network-equilibrium-node-alloc-field-notice-dot">*</div>
            <div className="network-equilibrium-node-alloc-field-notice-description">{`There is no need to set the total staking amount to 100%.\nIn the next step (Step 4), you can pre-allocate coins to your desired accounts.`}</div>
          </div>
        </ColumnField>
      </FieldContainer>
    </>
  );
};

export default NetworkEquilibriumNodeAllocField;

const NodeAllocationTableRow = (props) => {
  const { row, index, networkData, setNetworkData, setNodeAllocPercent } = props;

  const [openKeystoreFileSettingModal, setOpenKeystoreFileSettingModal] = useState<Boolean>(false);

  const resetKeystore = async () => {
    setNetworkData((prevState) => {
      const nodeAlloc = prevState.nodeAlloc.map((item, i: number) => {
        if (i !== index) {
          return item;
        } else {
          return { ...item, keyStoreFile: null, keyStore: null, address: null, encryptedKey: null, isChecked: false };
        }
      });

      return { ...prevState, nodeAlloc };
    });
  };

  return (
    <tr>
      {openKeystoreFileSettingModal && (
        <KeystoreFileSettingModal
          index={index}
          isOpen={openKeystoreFileSettingModal}
          closeModal={() => setOpenKeystoreFileSettingModal(false)}
          setNetworkData={setNetworkData}
        />
      )}
      <td>
        <div className="flex align-items-center justify-content-center">
          {/* <BasicInput readOnly value={row["name"]} style={{ padding: "11px 10px", width: "100%", height: 40 }} /> */}
          {row["name"]}
        </div>
      </td>
      <td>
        <div className="flex align-items-center justify-content-center">
          {Boolean(row.keyStore) && (
            <div className="node-alloc-keystore-button">
              <div className="node-alloc-keystore-button-address">{addressSimplificationConvertor(`0x${row.keyStore.address}`)}</div>
              <img src={deleteIcon} className="node-alloc-keystore-button-delete" alt="" onClick={() => resetKeystore()} />
            </div>
          )}
          {!Boolean(row.keyStore) && (
            <div className="node-alloc-keystore-button" onClick={() => setOpenKeystoreFileSettingModal(true)}>
              <div className="node-alloc-keystore-button-label">Click to register</div>
            </div>
          )}
        </div>
      </td>
      <td>
        <div className="flex align-items-center" style={{ gap: 5 }}>
          <BasicInput
            style={{ padding: "11px 10px", width: 62, height: 40, textAlign: "center" }}
            value={row["mmcBalancePercent"]}
            onChange={(e) => setNodeAllocPercent(e.target.value, index)}
          />
          <span className="node-allocation-table-distribution-percent">{`%`}</span>
          <div className="node-allocation-table-distribution-amount">{`(${row["mmcBalance"] || 0} ${networkData.currency.symbol})`}</div>
        </div>
      </td>
    </tr>
  );
};
