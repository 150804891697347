import './Text.scss'
import {HTMLAttributes} from "react";

type TextProps = HTMLAttributes<HTMLSpanElement> & {
  text: string
}

const Text = ({text, ...rest}: TextProps) => {
  return <span {...rest}>{text}</span>
};

export default Text;
