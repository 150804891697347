import React, {useCallback, useMemo, useState} from 'react';
import './BasicTable.scss'
import {TableCommonProps} from "./constatns/table.type";

type BasicTableProps = TableCommonProps

const BasicTable = ({headers, data, renderer, colGroup, onClick}: BasicTableProps) => {
  const [hoverIndex, setHoverIndex] = useState<number | undefined>(undefined)
  const rowStyle = useCallback((index) => {
    return {
      cursor         : (hoverIndex === index && onClick) ? 'pointer' : 'default',
      backgroundColor: (hoverIndex === index && onClick) ? '#f4f6f8' : 'transparent'
    }
  }, [hoverIndex, onClick])

  const tableColGroup = useMemo(() => colGroup ?? new Array(headers.length).fill(100 / headers.length), [headers, colGroup])

  return (
    <table className={'basic-table'}>
      <colgroup>
        {tableColGroup.map((col, index) => <col key={index} style={{width: `${col}%`}}/>)}
      </colgroup>
      <thead className='basic-table-head'>
      <tr className={'basic-table-head-row'}>{headers.map((header, index) =>
        <th className='basic-table-head-row-data' key={index}>
          {header.title}
        </th>)}
      </tr>
      </thead>
      <tbody className='basic-table-body'>
      {data.map((element, index) =>
        <tr className='basic-table-body-row' key={index} onClick={() => onClick && onClick(element)}
            onMouseOver={() => setHoverIndex(index)} onMouseLeave={() => setHoverIndex(undefined)}
            style={rowStyle(index)}>
          {headers.map((header, idx) =>
            <td className='basic-table-body-row-data' key={idx}>
              {renderer(element)[header.accessor]}
            </td>)}
        </tr>
      )}
      </tbody>
    </table>
  );
};

export default BasicTable;
