import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import "./PagingNumbers.scss";
import rightArrowIcon from "../../../assets/images/arrow_right_btn.png";
import rightMaxArrowIcon from "../../../assets/images/arrow_maximum_right_btn.png";
import leftArrowIcon from "../../../assets/images/arrow_left_btn.png";
import leftMaxArrowIcon from "../../../assets/images/arrow_maximum_left_btn.png";

type PagingNumbersProps = {
  totalNumber: number;
  limit?: number;
  query?: string;
};

function PagingNumbers(props: PagingNumbersProps) {
  const { totalNumber, limit = 10, query = "page" } = props;
    const maxPageNum = useMemo(() => Math.ceil(totalNumber ), [totalNumber, limit]);
  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage = Number(searchParams.get(query)) || 1;
  const paginationNumbers = useMemo(() => {
    return Array.from({ length: totalNumber }, (_, index) => index+1);
  }, [totalNumber, maxPageNum]);

  return (
    <div className="EQBR_paging-numbers">
      <button
        className={`EQBR_paging-button prev${currentPage === 1 ? " disabled" : ""} `}
        disabled={currentPage <= 1}
        onClick={() => {
          searchParams.set(query, "1");
          setSearchParams(searchParams);
        }}
      >
        <img src={leftMaxArrowIcon} alt="" />
      </button>
      <button
        className={`EQBR_paging-button prev${currentPage === 1 ? " disabled" : ""} `}
        disabled={currentPage <= 1}
        onClick={() => {
          searchParams.set(query, Math.max(currentPage - 1, 1).toString());
          setSearchParams(searchParams);
        }}
      >
        <img src={leftArrowIcon} alt="" />
      </button>
      {paginationNumbers.map((page) => (
        <div
          onClick={() => {
            searchParams.set(query, page.toString());
            setSearchParams(searchParams);
          }}
          key={page}
        >
          <div className={`EQBR_paging-number${currentPage === page ? " selected" : ""}`}>{page}</div>
        </div>
      ))}
      <button
        className={`EQBR_paging-button next${currentPage === maxPageNum ? " disabled" : ""} `}
        disabled={currentPage >= maxPageNum}
        onClick={() => {
          searchParams.set(query, Math.min(currentPage + 1, maxPageNum).toString());
          setSearchParams(searchParams);
        }}
      >
        <img src={rightArrowIcon} alt="" />
      </button>
      <button
        className={`EQBR_paging-button next${currentPage === maxPageNum ? " disabled" : ""} `}
        disabled={currentPage >= maxPageNum}
        onClick={() => {
          searchParams.set(query, maxPageNum.toString());
          setSearchParams(searchParams);
        }}
      >
        <img src={rightMaxArrowIcon} alt="" />
      </button>
    </div>
  );
}

export default PagingNumbers;
