import styled from "styled-components";
import { IconTheme } from "view/components/_molecules/select/IconSelector";

type StyledIconProps = {
  size: number;
  theme: IconTheme;
};

function borderRadius(theme: IconTheme) {
  if (theme === IconTheme.CIRCLE) {
    return "100%";
  }
  if (theme === IconTheme.DEFAULT) {
    return "20px";
  }
}

export const StyledIcon = styled.img<StyledIconProps>`
  width: ${(props) => `${props.size}px`};
  height: ${(props) => `${props.size}px`};
  object-fit: contain;
  cursor: pointer;
  border-radius: ${(props) => borderRadius(props.theme)};
  background-color: #f5f7ff;
`;
