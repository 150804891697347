import { useMemo, useState } from "react";
import "./EditNFTProject.scss";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import {
  VALIDATION__NFT_project_description,
  VALIDATION__NFT_project_image,
  VALIDATION__NFT_project_links,
  VALIDATION__NFT_project_name,
  VALIDATION__NFT_project_symbol,
} from "../validation/NFTValidation";
import { errorAlert } from "../../../../../utils/Utils";
import ImageUpload from "view/components/_atom/file/ImageUpload";
import MultipleInput from "view/components/_molecules/input/MultipleInput";
import RadioButtons from "legacy-common/molcules/RadioButtons/RadioButtons";
import { MINTING } from "../constant/nftConstant";
import { getNFTProjectDetailAction, updateNFTProjectAction, uploadProjectImageToS3Action } from "action/nftAction";
import PageArticle from "view/components/_template/page/PageArticle";
import PageTitle from "view/components/_molecules/page/PageTitle";
import PageLoading from "view/components/_atom/loading/PageLoading";
import BasicButton from "view/components/_atom/button/BasicButton";
import { ButtonSize } from "view/components/_atom/button/StyledButton";
import useLoading from "hooks/useLoading";
import useGetSelectedProject from "hooks/useGetSelectedProject";
import useValidateCondition from "hooks/useValidateCondition";
import useIsAble from "hooks/useIsAble";
import { distinguishValidation } from "modules/validation/validationCondition";
import CommentInput from "view/components/_atom/input/CommentInput";
import nftIcon from "../../../../assets/images/service/Icon_SNB_non_fungible_token.png";
import { FieldContainer, FieldLabel, RowField } from "view/components/_atom/section/Field";
import BasicTextarea from "view/components/_atom/input/BasicTextarea";

const EditNftProject = () => {
  const { id: nftProjectId } = useParams();
  const { selectedProjectID } = useGetSelectedProject();
  const navigate = useNavigate();

  const [mintTiming, setMintTiming] = useState(1);

  //========= Get initial data NFT Project =========//
  const [projectDetail, setProjectDetail] = useState({});
  const [projectName, setProjectName] = useState("");
  const [projectSymbol, setProjectSymbol] = useState("");
  const [projectDescription, setProjectDescription] = useState("");
  const [projectImage, setProjectImage] = useState(null);
  const [projectLinks, setProjectLinks] = useState([]);

  const getNFTProjectDetail_ = async () => {
    const { result, error } = await getNFTProjectDetailAction(parseInt(nftProjectId));
    if (result) {
      setProjectDetail(result);
      setProjectName(result.projectName);
      setProjectSymbol(result.symbol);
      setProjectDescription(result.projectDescription);
      setProjectImage(result.image);
      setProjectLinks(result.projectLink);
    }
    if (error) {
      errorAlert(error.data.message);
    }
  };

  //========= Update NFT Project Data =========//

  const nameValidation = useValidateCondition([projectName], VALIDATION__NFT_project_name(projectName));
  const symbolValidation = useValidateCondition([projectSymbol], VALIDATION__NFT_project_symbol(projectSymbol));
  const descriptionValidation = useValidateCondition([projectDescription], VALIDATION__NFT_project_description(projectDescription));
  const imageValidation = useValidateCondition([projectImage], VALIDATION__NFT_project_image(projectImage));
  const linksValidation = useValidateCondition([projectLinks], VALIDATION__NFT_project_links(projectLinks));

  const isAble = useIsAble([nameValidation.isValid, symbolValidation.isValid, descriptionValidation.isValid, imageValidation.isValid]);

  const NFTProjectValidationResult = useMemo(() => {
    return distinguishValidation([nameValidation, symbolValidation, descriptionValidation, imageValidation, linksValidation]);
  }, [nameValidation, symbolValidation, descriptionValidation, imageValidation, linksValidation]);

  const uploadProjectImageToS3_ = async () => {
    let formData = new FormData();
    formData.append("image", projectImage);
    const { result, error } = await uploadProjectImageToS3Action(formData);
    if (result) {
      return result;
    }
    if (error) {
      errorAlert(error.data.message);
    }
  };
  const updateNFTProject_ = async () => {
    if (!NFTProjectValidationResult.isValid) {
      toast.error(NFTProjectValidationResult.message);
      return;
    }

    const imageURL = typeof projectImage === "string" ? projectImage : await uploadProjectImageToS3_();

    if (Boolean(imageURL)) {
      const { result, error } = await updateNFTProjectAction(nftProjectId, projectName, projectDescription, imageURL, projectLinks, projectSymbol);
      if (result) {
        navigate(`/application/nft/manage/${nftProjectId}`);
        toast.success("processed successfully");
      }
      if (error) {
        errorAlert(error.data.message);
      }
    }
  };

  const { loading } = useLoading({
    dependency: [selectedProjectID],
    synchronousFunction: async () => {
      await getNFTProjectDetail_();
    },
  });

  if (loading) {
    return <PageLoading />;
  }
  return (
    <PageArticle>
      <PageTitle title={"Edit NFT Project Detail"} size={810} />
      <div className="page-layout-810">
        {/* Basic Info Description :: Set the basic information for the NFT project you want to create. */}
        <FieldContainer icon={nftIcon} title="Basic Info">
          <RowField>
            <FieldLabel label="Network / Microchain" />
            <span>{`${projectDetail?.networkLabel} / ${projectDetail?.microChainLabel}`}</span>
          </RowField>
          <RowField>
            <FieldLabel label="Project name" />
            <CommentInput
              {...nameValidation}
              value={projectName}
              onChange={(e) => setProjectName(e.target.value)}
              placeholder={"Enter NFT Project name"}
              autoFocus
            />
          </RowField>
        </FieldContainer>

        {/* Details Dsecription :: Enter details for the NFT project you want to create. */}
        <FieldContainer icon={nftIcon} title="Details">
          <RowField>
            <FieldLabel label="Project symbol" />
            <CommentInput
              {...symbolValidation}
              value={projectSymbol}
              onChange={(e) => setProjectSymbol(e.target.value.toUpperCase())}
              placeholder={"Enter NFT Project symbol"}
            />
          </RowField>
          <RowField>
            <FieldLabel label="Project description" />
            <BasicTextarea
              value={projectDescription}
              onChange={(e) => setProjectDescription(e.target.value)}
              placeholder={"Enter the description of NFT project"}
              rows={5}
            />
          </RowField>
          <RowField>
            <FieldLabel label="Project image" />
            <ImageUpload
              style={{ alignItems: "flex-start" }}
              image={projectImage}
              setImage={setProjectImage}
              comment={"Please upload an image over 130x130 size."}
              previewStyle={{ borderRadius: 8 }}
              buttonText={"Upload"}
            />
          </RowField>
          <RowField>
            <FieldLabel label="Project links" essential={false} />
            <MultipleInput placeholder={"https://www.nftproject.com/"} inputArray={projectLinks} setInputArray={setProjectLinks} />
          </RowField>
          <RowField>
            <FieldLabel label="The time of minting" />
            <RadioButtons options={MINTING} selectedValue={mintTiming} onChange={setMintTiming} />
          </RowField>
        </FieldContainer>
        <div className="edit-nft-project-button-container">
          <BasicButton size={ButtonSize.LARGE} disabled={!isAble} onClick={() => updateNFTProject_()}>
            Save
          </BasicButton>
        </div>
      </div>
    </PageArticle>
  );
};

export default EditNftProject;
