import React, { useState } from "react";
import "./SignUp.scss";
import logo from "../../../assets/images/signup_logo_EQHub.png";
import { ColumnField, FieldLabel } from "view/components/_atom/section/Field";
import Checkbox from "view/components/_atom/checkbox/Checkbox";
import ButtonContainer from "view/components/_atom/container/ButtonContainer";
import BasicButton from "view/components/_atom/button/BasicButton";
import { ButtonTheme } from "view/components/_atom/button/StyledButton";
import PasswordCondition from "view/components/_atom/box/PasswordCondition";
import { signUpAction } from "action/userAction";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { errorAlert } from "utils/Utils";
import useValidateCondition from "../../../../hooks/useValidateCondition";
import {
  VALIDATION__USER_SIGNUP_firstName,
  VALIDATION__USER_SIGNUP_email,
  VALIDATION__USER_SIGNUP_lastName,
  VALIDATION__USER_newPassword,
  VALIDATION__USER_confirmPassword,
} from "view/service/user/validation/userValidation";
import CommentInput from "view/components/_atom/input/CommentInput";
import useIsAble from "../../../../hooks/useIsAble";
import InitialContentTemplate from "../components/InitialContentTemplate";

const PASSWORD_CONDITION_ITEM = [
  `8 to 15 characters.`,
  `Includes uppercase letters, lowercase letters, numbers, and special characters.\n( Special characters : ~․!@#$%^&*()_-+=[]|\;:‘”<>,.?/ )`,
  `Does not include consecutive numbers, consecutive letters, or repeated letters, repeated numbers.\n( Ex. aaa, abc, qwe, 111, 123 )`,
];

const SignUp = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [isPolicyChecked, setIsPolicyChecked] = useState<boolean>(false);

  const requestSignUp = async () => {
    const { result, error } = await signUpAction(email, password, moment(new Date("2000/01/01")).format("YYYY/MM/DD"), firstName, lastName);
    if (result) {
      navigate("/initialize/verify-email", {
        state: {
          email: result.email,
          userId: result.id,
        },
      });
    }
    if (error) {
      errorAlert(error.data.message);
    }
  };

  const emailValidation = useValidateCondition([email], VALIDATION__USER_SIGNUP_email(email));
  const passwordValidation = useValidateCondition([password], VALIDATION__USER_newPassword(password));
  const confirmPasswordValidation = useValidateCondition([password, confirmPassword], VALIDATION__USER_confirmPassword(password, confirmPassword));
  const firstNameValidation = useValidateCondition([firstName], VALIDATION__USER_SIGNUP_firstName(firstName));
  const lastNameValidation = useValidateCondition([lastName], VALIDATION__USER_SIGNUP_lastName(lastName));

  const isAble = useIsAble([
    emailValidation.isValid,
    passwordValidation.isValid,
    confirmPasswordValidation.isValid,
    firstNameValidation.isValid,
    lastNameValidation.isValid,
    isPolicyChecked,
  ]);

  return (
    <div className="sign-up-container">
      <img src={logo} className="sign-up-logo" alt="EQHub-logo" />

      <InitialContentTemplate title="Sign Up" description={`With just one EQ Hub ID,\nYou can access to all EQ Hub service`}>
        <div className="sign-up-body-container">
          <ColumnField>
            <FieldLabel label="Email" />
            <CommentInput {...emailValidation} placeholder={"ex) eqbr@eqbr.com"} value={email} onChange={(e) => setEmail(e.target.value)} />
          </ColumnField>
          <ColumnField>
            <FieldLabel label="Password" />
            <CommentInput
              {...passwordValidation}
              type="password"
              placeholder={"Use 8 to 15 characters including numbers and special characters"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </ColumnField>
          <ColumnField>
            <FieldLabel label="Confirm Password" />
            <CommentInput
              {...confirmPasswordValidation}
              type="password"
              placeholder={"Use 8 to 15 characters including numbers and special characters"}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </ColumnField>
          <div className="sign-up-body-password-condition">
            <PasswordCondition passwordConditionItems={PASSWORD_CONDITION_ITEM} />
          </div>
          <div className="sign-up-body-name">
            <div className="sign-up-body-name-item">
              <ColumnField>
                <FieldLabel label="First Name" />
                <CommentInput
                  {...firstNameValidation}
                  placeholder={"First name"}
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  maxLength={50}
                />
              </ColumnField>
            </div>
            <div className="sign-up-body-name-item">
              <ColumnField>
                <FieldLabel label="Last Name" />
                <CommentInput {...lastNameValidation} placeholder={"Last name"} value={lastName} onChange={(e) => setLastName(e.target.value)} maxLength={50} />
              </ColumnField>
            </div>
          </div>
          <ColumnField>
            <Checkbox label={<CheckPolicyContent />} checked={isPolicyChecked} onChange={() => setIsPolicyChecked((prev) => !prev)} id={"SignUpPolicy"} />
          </ColumnField>
        </div>

        <div className="sign-up-button-container">
          <ButtonContainer>
            <BasicButton theme={ButtonTheme.SECONDARY} onClick={() => navigate(-1)}>
              Cancel
            </BasicButton>
            <BasicButton disabled={!isAble} onClick={() => requestSignUp()}>
              Sign up
            </BasicButton>
          </ButtonContainer>
        </div>
      </InitialContentTemplate>
    </div>
  );
};

export default SignUp;

const CheckPolicyContent = () => {
  return (
    <span>
      Agree to{" "}
      <span
        style={{
          color: "#5888FF",
          textDecoration: "underline",
          cursor: "pointer",
          margin: 0,
        }}
        onClick={(e) => {
          e.stopPropagation();
          window.open("https://s3.ap-northeast-2.amazonaws.com/resource-files.eqbr.com/EQBR/EQBRholdings+_+Privacy+Policy.pdf");
        }}
      >
        policy
      </span>{" "}
      of EQ Hub
    </span>
  );
};
