import { useState } from 'react';
import ModalTemplateA from '../../../../../legacy-common/template/modal/ModalTemplateA';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { BooleanParser } from '../utils/tokenUtils';
import { connectInfo } from '../../../../../store/redux/EQconnect/EQConnectSlice';
import EQConnectWalletButton from '../../../../../modules/EQConnect/EQConnectWalletButton';
import DeployTokenContractModalContent from '../modal/DeployTokenContractModalContent';
import IconDeleteRed from '../../../../assets/images/atom/icon_delete_red.png';
import { errorAlert } from '../../../../../utils/Utils';
import { deleteTokenAction, submitTokenAction } from 'action/tokenAction';
import ButtonContainer from 'view/components/_atom/container/ButtonContainer';
import BasicButton from 'view/components/_atom/button/BasicButton';
import { ButtonSize } from 'view/components/_atom/button/StyledButton';
import { ButtonTheme } from 'view/components/_atom/button/StyledButton';

const ManageTokenBeforeDeploy = (props) => {
  const {
    setTokenImage,
    tokenDetail,
    setTokenDetail,
    setMintable,
    // setLockable,
    // lockable,
    setBurnable,
    getTokenDetail_,
  } = props || {};

  const navigate = useNavigate();
  const { id: currencyId } = useParams();
  const { address } = useSelector(connectInfo);

  const [deployTokenContractModalOpen, setDeployTokenContractModalOpen] = useState(false);

  //========= Delete =========//
  const deleteToken_ = async () => {
    const { result, error } = await deleteTokenAction(parseInt(currencyId));
    if (result) {
      toast.success('processed successfully');
      navigate('/application/token/manage');
    }
    if (error) {
      errorAlert(error.data.message);
    }
  };
  //========= Edit =========//
  const onClickEditToken = () => {
    navigate('edit');
  };
  //========= Deploy (Submit +  Deploy) =========//
  const submitToken_ = async () => {
    const { result, error } = await submitTokenAction(parseInt(currencyId));
    if (result) {
      setTokenDetail(result);
      setTokenImage(result.image);
      setMintable(BooleanParser(result.currencyDetail.mintable));
      setBurnable(BooleanParser(result.currencyDetail.burnable));
      setDeployTokenContractModalOpen(true);
    }
    if (error) {
      errorAlert(error.data.message);
    }
  };
  const onClickDeployButton = () => {
    return tokenDetail.mainStatus === 1 ? submitToken_() : setDeployTokenContractModalOpen(true);
  };

  return (
    <>
      {deployTokenContractModalOpen && (
        <ModalTemplateA title={'Deploy Contract'} isOpen={deployTokenContractModalOpen} closeModal={() => setDeployTokenContractModalOpen(false)}>
          <DeployTokenContractModalContent
            callback={getTokenDetail_}
            tokenDetail={tokenDetail}
            setDeployTokenContractModalOpen={setDeployTokenContractModalOpen}
          />
        </ModalTemplateA>
      )}
      <div className="flex justify-content-flex-end">
        <ButtonContainer>
          <BasicButton size={ButtonSize.ICON} theme={ButtonTheme.MINOR} onClick={() => deleteToken_()}>
            <img src={IconDeleteRed} style={{ width: 18, objectFit: 'contain' }} alt={'delete'} />
          </BasicButton>
          {tokenDetail.mainStatus === 1 && (
            <BasicButton size={ButtonSize.LARGE} theme={ButtonTheme.SECONDARY} onClick={() => onClickEditToken()}>
              Edit
            </BasicButton>
          )}
          <EQConnectWalletButton />
          <BasicButton size={ButtonSize.LARGE} disabled={!address} onClick={() => onClickDeployButton()}>
            Deploy
          </BasicButton>
        </ButtonContainer>
      </div>
    </>
  );
};

export default ManageTokenBeforeDeploy;
