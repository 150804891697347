import {parseAction} from "./util/parseAction";
import {
  deleteNotificationParser,
  getNotificationParser,
  readAllNotificationParser,
  readNotificationParser
} from "../parser/notificationParser";

export const getNotificationAction = () =>
  parseAction(async () => {
    return await getNotificationParser();
  })

export const readAllNotificationAction = () =>
  parseAction(async () => {
    return await readAllNotificationParser();
  })

export const readNotificationAction = (platformNotificationId: number) =>
  parseAction(async() => {
    return await readNotificationParser(platformNotificationId)
  })

export const deleteNotificationAction = (platformNotificationId: number) =>
  parseAction(async() => {
    return await deleteNotificationParser(platformNotificationId)
  })
