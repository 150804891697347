import "./AirdropOperatingWayPurposeBox.scss";

const AirdropOperatingWayPurposeBox = (props) => {
  const { data, type, onClickTypeButton } = props || {};

  return (
    <div
      className={`airdrop-operating-way-purpose-box ${type === data.operationPurpose ? "selected" : "not-selected"} ${
        data.disabled ? "disabled" : "enabled"
      }`}
      onClick={() => onClickTypeButton(data)}
    >
      <div className="airdrop-operating-way-purpose-content">
        <span className="airdrop-operating-way-purpose-label">{data.label}</span>
        <p className="airdrop-operating-way-purpose-description">{data.description}</p>
        <ul className="airdrop-operating-way-purpose-data">
          {data.property.map((el, index) => {
            return (
              <li key={`airdrop-operating-way-purpose-property-${index}`} className="airdrop-operating-way-purpose-data-row">
                {el.value}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default AirdropOperatingWayPurposeBox;
