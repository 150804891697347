import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getFoundationListAction } from "../../../../../action/foundationAction";
import Table from "../../../../../legacy-common/atom/table/Table";
import { errorAlert } from "../../../../../utils/Utils";
import TableStatusLabel from "view/components/_atom/label/TableStatusLabel";
import Section from "legacy-common/atom/section/Section";
import { selected_project_id } from "reducer/projectReducer";
import PageTitle from "view/components/_molecules/page/PageTitle";
import PageArticle from "view/components/_template/page/PageArticle";
import PageLoading from "view/components/_atom/loading/PageLoading";
import useLoading from "hooks/useLoading";

function FoundationList() {
  const navigate = useNavigate();

  const projectId = useSelector(selected_project_id);

  const [foundationList, setFoundationList] = useState([]);

  const headers = useMemo(
    () => [
      { title: "No", accessor: "id" },
      { title: "Network", accessor: "networkLabel" },
      { title: "Microchain", accessor: "microChainLabel" },
      { title: "WhitePaper", accessor: "whitePaper" },
      { title: "Status", accessor: "status" },
    ],
    []
  );

  const colGroup = useMemo(() => [10, 20, 20, 30, 20].map((el) => ({ style: { width: `${el}%` } })), []);

  const renderer = useCallback(
    (data) => ({
      id: data.id,
      networkLabel: data.networkLabel,
      microChainLabel: data.microChainLabel,
      whitePaper: data.whitePaper,
      status: <TableStatusLabel mainStatus={5} />,
    }),
    []
  );

  const getFoundationList_ = async () => {
    const { result, error } = await getFoundationListAction();

    if (result) {
      setFoundationList(result);
    }
    if (error) {
      errorAlert(error.data.message);
    }
  };

  const onClickList = (el) => {
    navigate(`${el.id}`);
  };

  const foundationListView = () => [
    {
      renderers: [
        {
          value: (
            <Table headers={headers} colGroup={colGroup} data={foundationList} renderer={renderer} onClick={onClickList} noDataPlaceholder={"No Foundations"} />
          ),
        },
      ],
    },
  ];

  const { loading } = useLoading({
    dependency: [projectId],
    synchronousFunction: async () => {
      await getFoundationList_();
    },
  });

  if (loading) {
    return <PageLoading />;
  }

  return (
    <PageArticle>
      <PageTitle size={1240} title={"Foundation List"} description={"manage the foundation."} />
      <div className="page-layout-1240">
        <Section title={"Foundation"} view={foundationListView} />
      </div>
    </PageArticle>
  );
}

export default FoundationList;
