import {ErrorType} from "../action/util/parseAction";

interface IModuleError {
  type: ErrorType;
}

// type IModuleError = {
//   type: ErrorType;
// }

export class ModuleError extends Error implements IModuleError{
  type: ErrorType;

  constructor(message: string) {
    super(message);
    this.type = 'Module';
  }
}
