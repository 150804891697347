import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PageLoading from "../../../../components/_atom/loading/PageLoading";
import toast from "react-hot-toast";
import GetContractCommonField from "../organism/GetContractCommonField";
import { isArrayParameter, removeEmptyElement } from "../utils/contractUtils";
import ParameterHandler from "../molcules/ParameterHandler";
import { errorAlert } from "../../../../../utils/Utils";
import { isArray, throttle } from "lodash";
import {
  getCategoriesAction,
  getContractLibraryDetailAction,
  getDataForGetContractAction,
  getTagsAction,
} from "../../../../../action/contractLibraryAction";
import { getFixedContractToListAction } from "../../../../../action/contractAction";
import PageArticle from "../../../../components/_template/page/PageArticle";
import PageTitle from "../../../../components/_molecules/page/PageTitle";
import BasicButton from "view/components/_atom/button/BasicButton";
import { ButtonSize } from "view/components/_atom/button/StyledButton";
import useLoading from "hooks/useLoading";
import useIsAble from "hooks/useIsAble";
import useValidateCondition from "hooks/useValidateCondition";
import { VALIDATION__CONTRACT_name } from "../validation/contractValidation";
import { FieldContainer } from "view/components/_atom/section/Field";
import contractIcon from "../../../../assets/images/service/Icon_SNB_contract_library.png";
import ContractDetailInfo from "../organism/ContractDetailInfo";
import "./GetFixedContractToList.scss";
import circleArrowDown from "../../../../assets/images/icon_circle_arrow_down.png";
import QuestionMark, { QuestionMarkTheme } from "view/components/_atom/tooltip/QuestionMark";

const GetFixedContractToList = () => {
  const navigate = useNavigate();
  const { contractId, versionId } = useParams();

  const [allNetworks, setAllNetworks] = useState([]);
  const [allMicroChains, setAllMicroChains] = useState([]);
  const [availableMicroChains, setAvailableMicroChains] = useState([]);
  const [selectedNetwork, setSelectedNetwork] = useState(null);
  const [selectedMicroChain, setSelectedMicroChain] = useState(null);

  const [contractName, setContractName] = useState("");
  const [memo, setMemo] = useState("");

  const [constructorInputs, setConstructorInputs] = useState([]);
  const [constructorValue, setConstructorValue] = useState(null);

  const [contractDetail, setContractDetail] = useState([]);
  const [versionDetail, setVersionDetail] = useState([]);
  const [selectedVersion, setSelectedVersion] = useState(parseInt(versionId));

  const [solutions, setSolutions] = useState([]);
  const [standards, setStandards] = useState([]);

  const getFilters = useCallback(async () => {
    const tags = await getTagsAction();
    const categories = await getCategoriesAction();
    if (tags.result && categories.result) {
      setStandards(categories.result);
      setSolutions(tags.result);
    }
  }, []);

  useEffect(() => {
    getFilters();
  }, []);

  const isAllDataFilled = useMemo(() => {
    if (constructorValue) {
      return constructorValue.reduce((acc, cur) => {
        if (typeof cur === "string") {
          return Boolean(acc) && Boolean(cur);
        }
        if (isArray(cur)) {
          return cur.reduce((_acc, _cur) => Boolean(_acc) && Boolean(_cur), true);
        }
      }, true);
    } else {
      return false;
    }
  }, [constructorValue]);

  const setInitialDataFromServer = async () => {
    const { result, error } = await getDataForGetContractAction(Number(contractId), Number(versionId));
    if (result) {
      const { networks, microChains, versionDetail } = result;
      if (versionDetail.status < 3) {
        toast.error("Invalid Status to access");
        setTimeout(() => {
          navigate(-1);
        }, 1500);
      } else {
        setAllNetworks(networks);
        setAllMicroChains(microChains);
        const { contractConstructor } = versionDetail || {};
        const { inputs: contractConstructorInputs } = contractConstructor || {};
        /**
         * 서버팀(체인팀)에서 forwarder_ key를 constructor에서 제외하여 처리하기로 예정
         * 서버팀에서 처리하면 아래 contractConstructorInputsExceptForwarder 삭제 필요
         */
        const contractConstructorInputsExceptForwarder = contractConstructorInputs?.filter(
          (input) => input.name !== "forwarder_"
        );
        const valueArray = contractConstructorInputsExceptForwarder?.map((input) => {
          if (isArrayParameter(input.type)) return [];
          else return "";
        });
        setConstructorInputs(contractConstructorInputsExceptForwarder);
        setConstructorValue(valueArray);
      }
    }
    if (error) {
      toast.error(error.data.message);
      navigate(-1);
    }
  };

  useEffect(() => {
    if (selectedNetwork) {
      const availableMicroChains = allMicroChains.filter((el) => el.networkId === selectedNetwork);
      if (availableMicroChains.length !== 0) {
        setSelectedMicroChain(availableMicroChains.find((chain) => Boolean(chain.isMain)).id ?? null);
      } else {
        setSelectedMicroChain(null);
      }
      setAvailableMicroChains(availableMicroChains);
    }
  }, [selectedNetwork]);

  const nameValidation = useValidateCondition([contractName], VALIDATION__CONTRACT_name(contractName));
  const memoValidation = useValidateCondition([memo], VALIDATION__CONTRACT_name(memo));

  const isAble = useIsAble([
    Boolean(selectedNetwork),
    Boolean(selectedMicroChain),
    nameValidation.isValid,
    memoValidation.isValid,
    isAllDataFilled,
  ]);

  const getFixedContractToList_ = throttle(
    async () => {
      let hasEmptyValue;
      const constructorArgument = removeEmptyElement(constructorValue);
      if (constructorValue.length !== 0) {
        hasEmptyValue = constructorArgument
          .filter((arg) => typeof arg === "string")
          .reduce((acc, cur) => acc || !Boolean(cur.trim()), false);
        if (hasEmptyValue) {
          toast.error("Have empty value. Please check again");
          return;
        }
      }

      const { result, error } = await getFixedContractToListAction(
        selectedMicroChain,
        contractName,
        memo,
        parseInt(contractId),
        parseInt(versionId),
        constructorArgument
      );

      if (result) {
        toast.success("Successfully added to list!");
        navigate(`/developer/contract/list/${result.contractId}`);
      }
      if (error) {
        errorAlert(error.data.message);
      }
    },
    4000,
    { leading: true, trailing: true }
  );

  const getContractDetail = async () => {
    const { result, error } = await getContractLibraryDetailAction(Number(contractId), Number(versionId));
    if (result) {
      setContractDetail(result.contractDetail);
      setVersionDetail(result.versionDetail);
    }
    if (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getContractDetail();
  }, []);

  const { loading } = useLoading({
    dependency: [contractId, versionId],
    synchronousFunction: async () => {
      await setInitialDataFromServer();
    },
  });

  if (loading) {
    return <PageLoading />;
  }
  return (
    <PageArticle>
      <PageTitle title={"Get Fixed Contract"} size={812} />
      <div className="page-layout-812 get-fixed">
        <ContractDetailInfo
          labelKey={"version"}
          valueKey={"contract_version_id"}
          options={contractDetail.contractVersions}
          selectedValue={selectedVersion}
          setSelectedValue={setSelectedVersion}
          contractDetail={contractDetail}
          versionDetail={versionDetail}
          select={false}
          button={false}
          standards={standards}
          solutions={solutions}
        />
        <GetContractCommonField
          allNetworks={allNetworks}
          availableMicroChains={availableMicroChains}
          selectedNetworkState={{ selectedNetwork, setSelectedNetwork }}
          selectedMicroChainState={{ selectedMicroChain, setSelectedMicroChain }}
          contractNameState={{ contractName, setContractName }}
          memoState={{ memo, setMemo }}
        />
        {constructorInputs.length !== 0 && (
          <>
            <FieldContainer icon={contractIcon} title="Constructor Info" announcement={<AnnouncementOfConstructor />}>
              {constructorInputs.map((item, index) => {
                return (
                  <RowConstructorField
                    key={`constructor-inputs-${index}`}
                    item={item}
                    index={index}
                    constructorValue={constructorValue}
                    setConstructorValue={setConstructorValue}
                  ></RowConstructorField>
                );
              })}
            </FieldContainer>
          </>
        )}
        <div className="flex justify-content-flex-end">
          <BasicButton size={ButtonSize.LARGE} onClick={() => getFixedContractToList_()} disabled={!isAble}>
            Add
          </BasicButton>
        </div>
      </div>
    </PageArticle>
  );
};

export default GetFixedContractToList;

const AnnouncementOfConstructor = () => {
  return (
    <div className="announcement-box">
      <div>
        The constructor is functions hosted only once when deploying them to the blockchain. The creator sets the
        initial status by inputting the initial values of the contract.
      </div>
    </div>
  );
};

const RowConstructorField = (props) => {
  const { constructorValue, setConstructorValue, item, index } = props;
  const [isOpened, setIsOpened] = useState(false);

  return (
    <div>
      <div className="field row constructor">
        <FieldConstructorLabel
          label={`- ${item.name} (${item.type})`}
          isOpened={isOpened}
          setIsOpened={setIsOpened}
          item={item}
        />
        <ParameterHandler
          parameters={constructorValue}
          setParameters={setConstructorValue}
          input={item}
          index={index}
        />
      </div>
      {isOpened && <div className="constructor-description">{item.description}</div>}
    </div>
  );
};

const FieldConstructorLabel = (props) => {
  const { label, essential = true, announcement, isOpened, setIsOpened, item } = props;
  return (
    <div className="field-label">
      <div className="field-label-info">
        <span className="field-label-info-text">{label}</span>
        {/*{essential && <span className="field-label-info-essential">{`*`}</span>}*/}
        {item.description && (
          <button
            className={isOpened ? `field-label-info-btn open` : `field-label-info-btn`}
            onClick={() => setIsOpened((prev) => !prev)}
          >
            <img src={circleArrowDown} alt="" />
          </button>
        )}
        {announcement && (
          <div className="field-label-info-announcement">
            <QuestionMark announcement={announcement} theme={QuestionMarkTheme.SECONDARY} />
          </div>
        )}
      </div>
    </div>
  );
};
