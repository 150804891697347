import useLoading from "hooks/useLoading";
import {useEffect, useMemo, useState} from "react";
import { useSelector } from "react-redux";
import {useSearchParams} from "react-router-dom";
import { selectAllSolution, selectAllStandard } from "reducer/presetReducer";
import PagingNumbers from "view/components/_atom/paging/PagingNumbers";
import {
  getPopularContractLibrariesAction,
  searchContractLibrariesAction,
} from "../../../../../action/contractLibraryAction";
import search from "../../../../assets/images/Icon_search_on_list.png";
import deleteFilter from "../../../../assets/images/icon_delete_filter.png";
import PageLoading from "../../../../components/_atom/loading/PageLoading";
import PageTitle from "../../../../components/_molecules/page/PageTitle";
import PageArticle from "../../../../components/_template/page/PageArticle";
import ContractDropdownFilter from "../organism/ContractDropdownFilter";
import ContractVerticalCard from "../organism/ContractVerticalCard";
import LibraryContractContainer from "../organism/LibraryContractContainer";
import "./ContractLibrary.scss";

const POPULAR_CONTRACT_LIMIT = 3;
const CONTRACT_LIMIT = 10;

const ContractLibrary = () => {
  const [libraryContracts, setLibraryContracts] = useState([]);
  const [popularLibraryContracts, setPopularLibraryContracts] = useState([]);
  const [totalContractCount, setTotalContractCount] = useState(0);
  const [checkedSolutions, setCheckedSolutions] = useState([]);
  const [checkedStandards, setCheckedStandards] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");

  const solutions = useSelector(selectAllSolution);
  const standards = useSelector(selectAllStandard);


  const [searchParams,setSearchParams] = useSearchParams();
  const currentPage = Number(searchParams.get("page")) || 1;
  const maximumPage = useMemo(() => {
    if (totalContractCount === 0) {
      return 1;
    }
    if (totalContractCount % CONTRACT_LIMIT) {
      return parseInt(totalContractCount / CONTRACT_LIMIT) + 1;
    } else {
      return parseInt(totalContractCount / CONTRACT_LIMIT);
    }
  }, [totalContractCount]);

  const [inputValue, setInputValue] = useState("");
  const [isAccessTokenRefresh, setIsAccessTokenRefresh] = useState(false);
  const [changeDataLoading, setChangeDataLoading] = useState(false);

  const getSearchedContractLibrary = async (page = 1, inputValue, checkedSolutions, checkedStandards) => {
    if (typeof page === "string" && page === "") {
      return;
    }
    const { result, error } = await searchContractLibrariesAction(page, inputValue, checkedSolutions, checkedStandards);
    if (error) {
      console.error(error);
      setIsAccessTokenRefresh(true);
    }
    if (result) {
      setLibraryContracts(result.contracts);
      setTotalContractCount(result.count);
      setIsAccessTokenRefresh(false);
    }
  };

  const deleteSolution = (solution) => {
    const newArr = checkedSolutions.filter((sol) => sol !== solution);
    return newArr
  };
  const deleteStandard = (standard) => {
    const newArr = checkedStandards.filter((stan) => stan !== standard);
    return newArr
  };

  const getPopularContractLibrary = async () => {
    const { result, error } = await getPopularContractLibrariesAction(POPULAR_CONTRACT_LIMIT);
    if (error) {
      console.error(error);
      setIsAccessTokenRefresh(true);
    }
    if (result) {
      setPopularLibraryContracts(result.contract);
      setIsAccessTokenRefresh(false);
    }
  };
  const searchFilterHandler = async (page, inputValue, checkedSolutions, checkedStandards) => {
    setChangeDataLoading(true);
    await getSearchedContractLibrary(page, inputValue, checkedSolutions, checkedStandards);
    searchParams.set('page', '1')
    setSearchParams(searchParams)
    setChangeDataLoading(false);
  };



  useEffect(() => {
    setChangeDataLoading(true);
    getSearchedContractLibrary(currentPage, inputValue, checkedSolutions, checkedStandards).finally(() => {
      setChangeDataLoading(false);
    });
    return () => {
      setChangeDataLoading(false);
    }
  },[currentPage])

  const { loading } = useLoading({
    dependency: [isAccessTokenRefresh],
    synchronousFunction: async () => {
      await getPopularContractLibrary();
      setCheckedSolutions([]);
      setCheckedStandards([]);
      setInputValue("");
    },
  });

  if (loading) {
    return <PageLoading />;
  }
  return (
    <div>
      {changeDataLoading && (
        <div
          style={{
            width: "100%",
            height: "100vh",
            backgroundColor: "#ffffff50",
            position: "fixed",
            zIndex: 9999,
          }}
        >
          <PageLoading style={{ width: "892px", height: "calc(100vh - 97px)" }} />
        </div>
      )}
      <PageArticle>
        <PageTitle title={"Contract Library"} size={892} />

        <div className="page-layout-812" style={{ paddingBottom: 20 }}>
          <div className="page-sub-title">Popular contracts</div>
          <div className="popular-contracts">
            {popularLibraryContracts.map((contract, index) => (
              <ContractVerticalCard
                key={`popular-contract-${index}`}
                contractInfo={contract}
                version={contract.contract_versions[0].version}
              />
            ))}
          </div>
        </div>
        <div className="page-layout-812 sticky-point" style={{ paddingTop: 40 }}>
          <div className="page-sub-title">Contracts</div>
          <div className="search-box">
            <div className="search-input-box">
              <input
                className="search-input"
                type="text"
                placeholder="Search Contract"
                maxLength={100}
                onChange={(e) => {
                  setInputValue(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    searchFilterHandler(currentPage, inputValue, checkedSolutions, checkedStandards);
                    setSearchKeyword(inputValue)
                  }
                }}
              />
              <button
                className="search-btn"
                onClick={() => {
                  searchFilterHandler(currentPage, inputValue, checkedSolutions, checkedStandards);
                  setSearchKeyword(inputValue)
                }}
              >
                <img className="search-icon" src={search} alt="" />
              </button>
            </div>
            <ContractDropdownFilter
              page={currentPage}
              searchKeyword={searchKeyword}
              checkedSolutions={checkedSolutions}
              checkedStandards={checkedStandards}
              setCheckedSolutions={setCheckedSolutions}
              setCheckedStandards={setCheckedStandards}
              getData={searchFilterHandler}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
            />
          </div>
        </div>

        <div className="page-layout-812 none-top-padding">
          <div className="selected-filter-type">
            {checkedSolutions.length > 0 && (
              <div className="selected-filter-type-box">
                <div className="selected-filter-type-title">Solution :</div>
                {checkedSolutions.map((solution, index) => {
                  const correspondSolutions = solutions.find((sol) => sol.tag_id === solution);
                  return (
                    <div className="selected-filter-type-solution" key={`solution-${index}`}>
                      {correspondSolutions.name}
                      <img
                        className="selected-filter-delete-icon"
                        src={deleteFilter}
                        alt=""
                        onClick={() => {
                            const newArr = deleteSolution(solution)
                            searchFilterHandler(currentPage, searchKeyword, newArr , checkedStandards);
                            setCheckedSolutions(newArr);
                        }}
                      />
                    </div>
                  );
                })}
                <button
                  className="selected-filter-clear"
                  onClick={() => {
                    setCheckedSolutions([]);
                    searchFilterHandler(currentPage, searchKeyword, [], checkedStandards);
                  }}
                >
                  Clear
                </button>
              </div>
            )}
            {checkedStandards.length > 0 && (
              <div className="selected-filter-type-box">
                <div className="selected-filter-type-title">Standards :</div>
                {checkedStandards.map((standard, index) => {
                  const correspondStandard = standards.find((stan) => stan.category_id === standard);
                  return (
                    <div
                      className="selected-filter-type-standard"
                      key={`standard-${index}`}
                      style={{
                        color: correspondStandard.font_color,
                        backgroundColor: correspondStandard.background_color,
                      }}
                    >
                      {correspondStandard.name}
                      <img
                        className="selected-filter-delete-icon"
                        src={deleteFilter}
                        alt=""
                        onClick={() => {
                            const newArr = deleteStandard(standard);
                            searchFilterHandler(currentPage, searchKeyword, checkedSolutions, newArr);
                            setCheckedStandards(newArr);
                        }}
                      />
                    </div>
                  );
                })}
                <button
                  className="selected-filter-clear"
                  onClick={() => {
                    setCheckedStandards([]);
                    searchFilterHandler(currentPage, searchKeyword, checkedSolutions, []);
                  }}
                >
                  Clear
                </button>
              </div>
            )}
          </div>
          <LibraryContractContainer selectedType={[]} contracts={libraryContracts} />
          {libraryContracts.length !== 0 && (
            <div className="pagination-box">
              <PagingNumbers totalNumber={maximumPage} limit={CONTRACT_LIMIT} />
            </div>
          )}
        </div>
      </PageArticle>
    </div>
  );
};

export default ContractLibrary;
