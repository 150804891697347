import { parseAction } from "./util/parseAction";
import {
  getMicroChainIdsOfProjectParser,
  getMostUsedRequestOfDaysParser,
  getMostUsedRequestOfHoursParser,
  getNetworkUtilizationComparisonParser,
  getProjectRequestsOfDaysParser,
  getProjectRequestsOfHoursParser,
  getRequestsHistoryParser,
} from "../parser/projectParser";
import { ProjectRequests } from "../reducer/clientType/projectClientType";
import { selected_project_id } from "reducer/projectReducer";
import { storeState } from "reducer/index";
import { getInteralNetworkListParser, getMicroChainSettingParser, getNetworkVPNParser } from "parser/networkParser";
import { getCurrenciesParser } from "parser/currencyParser";
import { CURRENCY_MAIN_STATUS } from "view/service/network/network/constants/networkConstants";
import { CURRENCY_TYPE_COIN } from "../view/service/network/network/constants/networkConstants";
import { getExternalAndInnerMicroChainListParser, getMicroChainDetailParser } from "parser/microChainParser";
import { getProjectAPIKeysParser, getWEB3ProviderEndpointListParser } from "../parser/authParser";
import { FromTick, ProjectRequestsTab } from "server/type/projectType";
import { getBlocksForwardParser, getDailySummaryParser, getTransactionsForwardParser } from "parser/blockExplorerParser";
import _ from "lodash";
import { microCreditToCreditConvertor } from "utils/convert.utils";

// -------------------------------------------- DASHBAORD - API

export const getDashboardAPIAction = async () =>
  parseAction(async () => {
    const microChainIds = await getMicroChainIdsOfProjectParser();

    const [apiKey, endpointsData] = await Promise.all([getProjectAPIKeysParser(), getWEB3ProviderEndpointListParser({ microChainIds })]);
    const chainIds = endpointsData.map((el) => el.chainId);

    let endpointList: Array<any> = [];

    if (microChainIds.length !== 0) {
      const microChainSettingList = await Promise.all(
        chainIds.map(async (microChainId) => {
          return await getMicroChainSettingParser(microChainId);
        })
      );

      const currencyList = await getCurrenciesParser({ originMicroChainIds: chainIds, type: CURRENCY_TYPE_COIN, mainStatus: CURRENCY_MAIN_STATUS });
      const microChainList = await getExternalAndInnerMicroChainListParser({ microChainIds: chainIds });

      endpointList = endpointsData.map((endpoint) => {
        const microChainSetting = microChainSettingList.find((microChain) => microChain.microChainId === endpoint.chainId);
        const currency = currencyList.find((currency) => currency.originMicroChainId === endpoint.chainId);
        const microChain = microChainList.find((chain) => chain.id === endpoint.chainId);

        return {
          id: endpoint.id,
          isTestnet: Boolean(microChainSetting.isTestnet),
          endpointKey: endpoint.socketApiKey,
          coinImage: currency.image,
          networkLabel: microChain.network.label,
        };
      });
    }

    return {
      apiKey,
      endpointList,
    };
  });

export const getProjectRequestsAction = async (type: ProjectRequestsTab, from: FromTick) =>
  parseAction(async () => {
    let result: Array<ProjectRequests> = [];
    if (from !== FromTick.DAY && from !== FromTick.HOUR) {
      result = await getProjectRequestsOfDaysParser(type, from);
      result = result.map((el, index: number) => {
        let ts = "";
        if (index === 0 || el.ts.substr(3, 4) === "01") {
          ts = el.ts;
        }
        if (index !== 0 && el.ts.substr(3, 4) !== "01") {
          ts = el.ts.substr(3, 4);
        }
        return {
          ...el,
          ts: ts,
        };
      });
    }
    if (from === FromTick.DAY || from === FromTick.HOUR) {
      result = await getProjectRequestsOfHoursParser(type, from);
    }

    if (type === ProjectRequestsTab.CREDIT) {
      result = result.map((el) => {
        return {
          ...el,
          count: el.count / 1000000,
        };
      });
    }

    return result;
  });

export const getMostUsedRequestsAction = async (from: FromTick) =>
  parseAction(async () => {
    let result: { methodList: Array<string>; data: Array<any> } = { methodList: [], data: [] };
    if (from !== FromTick.DAY && from !== FromTick.HOUR) {
      result = await getMostUsedRequestOfDaysParser(from);
      result["data"] = result["data"].map((el, index: number) => {
        let date = "";
        if (index === 0 || el.date.substr(3, 4) === "01") {
          date = el.date;
        }
        if (index !== 0 && el.date.substr(3, 4) !== "01") {
          date = el.date.substr(3, 4);
        }
        return {
          ...el,
          date: date,
        };
      });
    }
    if (from === FromTick.DAY || from === FromTick.HOUR) {
      result = await getMostUsedRequestOfHoursParser(from);
    }
    return result;
  });

export const getNetworkUtilizationComparisonAction = async (from: FromTick) =>
  parseAction(async () => {
    const paranoid = "false";
    const result = await getNetworkUtilizationComparisonParser(from);
    const validResult = result.filter((el) => el.microChainId !== null);
    const microChainIds = validResult.map((el) => el.microChainId);
    const filteredMicroChainIds = _.uniq(microChainIds);
    const microchainList = await Promise.all(
      filteredMicroChainIds.map((microChainId) => {
        return getMicroChainDetailParser(microChainId, { paranoid });
      })
    );

    const response = validResult.map((el) => {
      const microchain = microchainList.find((microchain) => microchain.microChain.id === el.microChainId);
      return {
        ...el,
        networkLabel: microchain?.network?.label ?? "-",
      };
    });

    return response;
  });

export const getRequestHistoryAction = async (from: FromTick) =>
  parseAction(async () => {
    const paranoid = "false";
    const result = await getRequestsHistoryParser(from);
    const validResult = result.filter((el) => el.microChainId !== null);
    const microChainIds = validResult.map((el) => el.microChainId);
    const filteredMicroChainIds = _.uniq(microChainIds);
    const microchainList = await Promise.all(
      filteredMicroChainIds.map((microChainId) => {
        return getMicroChainDetailParser(microChainId as number, { paranoid });
      })
    );

    const response = result.map((el) => {
      const microchain = microchainList.find((microchain) => microchain.microChain.id === el.microChainId);
      return {
        ...el,
        microCredit: microCreditToCreditConvertor(el.microCredit / 1000000),
        networkLabel: microchain?.network?.label ?? "-",
      };
    });

    return response;
  });

// -------------------------------------------- DASHBAORD - NETWORK

export const getDashboardNetworkAction = async () =>
  parseAction(async () => {
    const projectId = selected_project_id(storeState());

    let result: Array<any> = [];

    const networkList = await getInteralNetworkListParser({ projectId });

    const microChainList = await getExternalAndInnerMicroChainListParser({ projectId });
    if (microChainList.length > 0) {
      const originMicroChainIds = microChainList.map((el: any) => el.id);

      const currencyList = await getCurrenciesParser({ originMicroChainIds, type: CURRENCY_TYPE_COIN, mainStatus: CURRENCY_MAIN_STATUS });

      result = networkList.map((network) => {
        const currency = currencyList.find((currency) => currency.originNetworkId === network.id);

        return {
          ...network,
          currency,
        };
      });
    }

    return result;
  });

export const downloadNetworkVPNAction = async (networkId: number) =>
  parseAction(async () => {
    return await getNetworkVPNParser(networkId);
  });

export const getBlocksForwardAction = (microChainId: number) =>
  parseAction(async () => {
    const limit = 5;
    return await getBlocksForwardParser(microChainId, limit);
  });

export const getTransactionsAction = (microChainId: number) =>
  parseAction(async () => {
    const limit = 5;
    return await getTransactionsForwardParser(microChainId, limit);
  });

export const getDailySummaryAction = (microChainId: number) =>
  parseAction(async () => {
    const endTime = new Date().getTime();
    return await getDailySummaryParser(microChainId, endTime);
  });
