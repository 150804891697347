import React, { useState } from "react";
import { validationController } from "../legacy-common/controller/validationController";

const useInputValidation = (validationCondition) => {
  const [inputValidationStatus, setInputValidationStatus] = useState(validationController().setValidateFormat(validationCondition));

  const setInputValidateStatusInitial = (e) => {
    const { name } = e.target;
    setInputValidationStatus((prevState) => {
      return {
        ...prevState,
        [name]: { comment: "" },
      };
    });
  };
  return { inputValidationStatus, setInputValidationStatus, setInputValidateStatusInitial };
};

export default useInputValidation;
