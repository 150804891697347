import { useMemo } from "react";

// dependency = 변경 감지
// currentStatus = 현재 main status
// unEditableStatus = 수정 불가능한 main status
type props = {
  dependency: Array<any>;
  currentStatus: number;
  unEditableStatus?: Array<number>;
};

export default function useEditable(props: props) {
  const { dependency, currentStatus, unEditableStatus = [2, 3, 4, 5] } = props;

  const editable = useMemo(() => {
    if (!dependency.reduce((acc, cur) => acc && Boolean(cur), true)) {
      return false;
    }
    return !Boolean(unEditableStatus.includes(currentStatus));
  }, [...dependency]);

  return { editable };
}
