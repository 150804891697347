import "./Header.scss";
import React from "react";
import Logo from "../../assets/images/Logo_EQHub.png";
import ProjectSelector from "../side_navigation/organisms/ProjectSelector";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();

  return (
    <header className={"main-header"}>
      <div className="main-header-logo">
        <img src={Logo} className={"main-header-logo-src"} alt="logo" onClick={() => navigate("/")} />
      </div>
      <div className="main-header-content">
        <ProjectSelector />
      </div>
    </header>
  );
};

export default Header;
