import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectAllSolution, selectAllStandard } from "reducer/presetReducer";
import rocket from "../../../../assets/images/icon_rocket.png";
import "./LibraryContractCard.scss";
import { useCallback, useEffect, useState } from "react";
import { getCategoriesAction, getTagsAction } from "action/contractLibraryAction";

const LibraryContractCard = ({ contract, standards, solutions }) => {
  const navigate = useNavigate();

  const onClickLibraryContract = () => {
    navigate(
      `/developer/contract/library/${Number(contract.contract_id)}/version/${Number(
        contract.latest_contract_version_id
      )}`
    );
  };

  return (
    <div className="library-contract-card" onClick={() => onClickLibraryContract()}>
      <div className="library-contract-card-img-box">
        <img className="library-contract-card-img" src={contract.image} alt="" />
      </div>
      <div className="library-contract-card-text">
        <div className="library-contract-card-text-top">
          <div className="library-contract-card-text-top-detail">
            <div>{`${contract.name}`}</div>
            <div className="library-contract-card-text-top-detail-bar"></div>
            <div className="library-contract-card-text-top-deploy-count">
              <img className="library-contract-card-text-top-deploy-icon" src={rocket} alt="" />
              <div className="library-contract-card-text-top-deploy-number">{contract.deploy_count}</div>
            </div>
          </div>
          <div className="library-contract-card-text-top-title">{contract.title}</div>
          <div className="library-contract-card-text-top-description">{contract.description}</div>
        </div>
        <div className="library-contract-card-text-btm">
          <div className="library-contract-card-text-btm-type">
            Standard
            {contract &&
              contract.contractCategories.map((ctg, index) => {
                const correspondStandard = standards.find((stan) => stan.category_id === ctg.category_id);
                return (
                  <div
                    key={`cartegory-${index}`}
                    className="library-contract-card-text-btm-type-standard"
                    style={{
                      color: correspondStandard?.font_color,
                      backgroundColor: correspondStandard?.background_color,
                    }}
                  >
                    {correspondStandard?.name}
                  </div>
                );
              })}
          </div>
          <div className="library-contract-card-text-btm-type">
            Related Solution
            <div className="library-contract-cart-btm-type-container">
              {contract &&
                contract.contractTags?.map((tag, index) => {
                  const correspondSolution = solutions.find((sol) => sol.tag_id === tag.tag_id);
                  return (
                    <div key={`solution-${index}`} className="library-contract-card-text-btm-type-solution">
                      {correspondSolution?.name}
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LibraryContractCard;
