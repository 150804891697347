import { services } from "logic/services";
import { actionController, ActionType } from "../utils";
import { requestAddressToExtension } from "modules/EQExtension/utils/messageUtils";
import {
  connectInfo,
  removeConnectedInfo,
  setConnectedInfo,
} from "store/redux/EQconnect/EQConnectSlice";
import { storeDispatch, storeState } from "reducer/index";
const { wallet } = services;
export const walletController = {
  connectWallet: (connectWalletType: "metamask" | "eqhub") => {
    return actionController(async () => {
      let address: string | null = null;
      let chainId: string | undefined;

      if (connectWalletType === "metamask") {
        if (window.ethereum && window.ethereum.isMetaMask) {
          try {
            chainId = await window.ethereum.request({
              method: "net_version",
            });

            const accounts = await window.ethereum.request({
              method: "eth_requestAccounts",
            });
            address = accounts[0];
            if (!address) {
              throw new Error("metamask_account_notFound");
            }
          } catch (error) {
            throw new Error("metamask_connect_fail");
          }
        } else {
          throw new Error("metamask_not_installed");
        }
      } else if (connectWalletType === "eqhub") {
        const message = {
          event: "",
          payload: {},
        };
        setTimeout(() => {
          const newMessage = { ...message };
          newMessage.event = "getAddress";
          newMessage.payload = {};
          window.postMessage(newMessage, window.origin);
        }, 200);
        address = sessionStorage.getItem("address");
      }

      if (address && connectWalletType) {
        await wallet.sessionStorage.saveAddress(address);
        await wallet.sessionStorage.setConnectedWalletType(connectWalletType);
        storeDispatch(
          setConnectedInfo({
            address: address,
            type: "extension",
          })
        );
      }

      return { chainId, address, connectWalletType };
    });
  },

  disconnectWallet: () => {
    return actionController(async () => {
      const address = await wallet.sessionStorage.getAddress();

      if (address) {
        try {
          await wallet.sessionStorage.removeAddress();
          await wallet.sessionStorage.deleteConnectedWalletType();
          return true;
        } catch (error) {
          throw new Error("remove_account_fail");
        }
      } else {
        throw new Error("account_does_not_exist");
      }
    });
  },

  getConnectWallet: () => {
    return actionController(async () => {
      try {
        const walletType = await wallet.sessionStorage.getConnectedWalletType();
        if (walletType) {
          if (walletType === "metamask") {
            return walletType;
          }
        } else {
          return "eqhub";
        }
      } catch {
        throw new Error("walletType_get_fail");
      }
    });
  },

  setConnectWalletType: (connectWalletType: "metamask" | "eqhub") => {
    return actionController(async () => {
      try {
        const setWalletType =
          await wallet.sessionStorage.setConnectedWalletType(connectWalletType);
        return setWalletType;
      } catch {
        throw new Error("walletType_set_fail");
      }
    });
  },

  //metamask가 연동되었다면 보여주고, 그렇지 않으면 EQextension을 보여줌
  getConnectAddress: () => {
    return actionController(async () => {
      try {
        const metamaskWalletAddress = await wallet.sessionStorage.getAddress();
        const EQwalletAddress = sessionStorage.getItem("address");
        const connectInfoResult = connectInfo(storeState());
        if (EQwalletAddress || connectInfoResult.address) {
          return EQwalletAddress || connectInfoResult.address;
        } else {
          if (metamaskWalletAddress) {
            return metamaskWalletAddress;
          }
          throw new Error("walletAddress_does_not_exist");
        }
      } catch {
        throw new Error("walletAddress_get_fail");
      }
    });
  },
};
