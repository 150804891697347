import React, { useMemo, useState } from "react";
import { numberConvertor } from "utils/convert.utils";
import PagingNumber from "view/components/_atom/paging/PagingNumber";
import "./BillingHistory.scss";
import { getProjectReceiptAction } from "action/projectAction";
import moment from "moment";

const HISTORY_LIMIT = 10;

const BillingHistory = (props) => {
  const { projectReceipt, setProjectReceipt, projectReceiptCount, setProjectReceiptCount } = props;

  const [currentPage, setCurrentPage] = useState("1");

  const getData = async (page) => {
    const { result, error } = await getProjectReceiptAction(Number(page), HISTORY_LIMIT);
    if (result) {
      const { rows, count } = result;
      setProjectReceipt(rows);
      setProjectReceiptCount(count);
    }
    if (error) {
    }
  };

  const maximumPage = useMemo(() => {
    if (projectReceiptCount === 0) {
      return 1;
    }
    if (projectReceiptCount % HISTORY_LIMIT) {
      return Math.floor(Number(projectReceiptCount / HISTORY_LIMIT)) + 1;
    } else {
      return Math.floor(Number(projectReceiptCount / HISTORY_LIMIT));
    }
  }, [projectReceiptCount]);

  return (
    <div className="billing-history-table">
      <div className="billing-history-table-header">
        <div className="billing-history-table-header-item">Date</div>
        <div className="billing-history-table-header-item">Title</div>
        <div className="billing-history-table-header-item">Amount</div>
      </div>
      <div className="billing-history-table-body">
        {projectReceipt.length === 0 && <div className="billing-history-table-body-empty">Billing history can be seen from next month</div>}
        {projectReceipt?.map((el, index) => (
          <BillingHistoryItem
            key={`billing-history-item-${index}`}
            date={moment(el.createdAt).format("MMMM DD, YYYY")}
            title={el.title}
            amount={el.monthlyHistory.totalPaymentPrice}
          />
        ))}
      </div>
      <PagingNumber page={currentPage} setPage={setCurrentPage} maximumPage={maximumPage} getData={getData} />
    </div>
  );
};

export default BillingHistory;

type BillingHistoryProps = {
  date: string;
  title: string;
  amount: number;
};
const BillingHistoryItem = ({ date, title, amount }: BillingHistoryProps) => {
  return (
    <div className="billing-history-table-body-content">
      <div className="billing-history-table-body-content-item" style={{ fontWeight: 700 }}>
        {date}
      </div>
      <div className="billing-history-table-body-content-item">{title}</div>
      <div className="billing-history-table-body-content-item">$ {numberConvertor(amount)}</div>
    </div>
  );
};
