import {Server} from "./server";
import {ApiManager, Queue, Token} from "./Queue";
import EventEmitter from "events";
import {refreshUserAccessToken, setExpiredTime} from "./utils";

const API_GATEWAY = process.env.REACT_APP_HUB_SERVER_URL;
const V1_API_VERSION = process.env.REACT_APP_API_VERSION;
const V2_API_VERSION = process.env.REACT_APP_API_VERSION_V2;

const baseURL_V1 = `${API_GATEWAY}${V1_API_VERSION}`;
const baseURL_V2 = `${API_GATEWAY}${V2_API_VERSION}`;

export const userJWT = new Token(setExpiredTime);
export const userApiResultEventManager = new EventEmitter();
export const userApiManager = new ApiManager(userJWT, refreshUserAccessToken);
export const userApiQueue = new Queue(userApiManager, userApiResultEventManager);

export const projectJWT = new Token();
export const projectApiResultEventManager = new EventEmitter();
export const projectApiManager = new ApiManager(projectJWT, refreshUserAccessToken);
export const projectApiQueue = new Queue(projectApiManager, projectApiResultEventManager);

/* Account & User & Notification */
export const v1ServerByUserAuth = new Server(userApiQueue, userApiResultEventManager, baseURL_V1);
export const v1ServerByProjectAuth = new Server(projectApiQueue, projectApiResultEventManager, baseURL_V1);
export const v2ServerByUserAuth = new Server(userApiQueue, userApiResultEventManager, baseURL_V2);
export const v2ServerByProjectAuth = new Server(projectApiQueue, projectApiResultEventManager, baseURL_V2);

export const l3ServerByProjectAuth = new Server(projectApiQueue, projectApiResultEventManager);

export const showServerLogs = () => {
	v1ServerByUserAuth.serverResponseInterceptor();
	v1ServerByProjectAuth.serverResponseInterceptor();
	v2ServerByProjectAuth.serverResponseInterceptor();
	l3ServerByProjectAuth.serverResponseInterceptor();
};

export const REACT_APP_HUB_SERVER_URL = process.env.REACT_APP_HUB_SERVER_URL;
