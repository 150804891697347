import BigNumber from "bignumber.js";
import { VC__is_empty_value } from "modules/validation/validationCondition";
import qrcode from "qrcode";

export const dataToSelectPropsConvertor = <T>(data: Array<T>, valueKey: string = "value", labelKey: string = "label") => {
  return data.map((element) => ({
    value: element[valueKey],
    label: element[labelKey],
  }));
};

export const numberConvertor = (value: number): string | undefined => {
  const response = new Intl.NumberFormat().format(value);
  return response !== "NaN" ? response : undefined;
};

export const commaRemovalConvertor = (value: string | null | undefined): number | undefined => {
  if (VC__is_empty_value(value)) {
    return 0;
  }
  const response = Number(value?.replaceAll(",", ""));
  return response ?? undefined;
};

export const microCreditToCreditConvertor = (value: number): number => {
  return Number(Math.ceil(value * 100) / 100);
};

export const addressSimplificationConvertor = (text: string, digit: number = 6) => {
  return `${text.substring(0, digit)}...${text.slice(-digit)}`;
};

export const blockExplorerURLConvertor = (microChainId: number, txHash: string) => {
  switch (microChainId) {
    // ETHEREUM & SEPOLIA
    case 1:
      return `${process.env.REACT_APP_ETHEREUM_BLOCK_EXPLORER_URL}/tx/${txHash}`;
    default:
      return `${process.env.REACT_APP_EQ_FIELD_URL}/${microChainId}/transaction/list/${txHash}`;
  }
};

export const makeQRCode = async (url: string) => {
  const QRCodeOptions = {
    color: {
      dark: "#354D7E",
      light: "#ffffffff",
    },
    margin: 0,
  };

  let response: string = "";
  qrcode.toDataURL(url, QRCodeOptions, function (err, url) {
    if (err) throw err;
    response = url;
  });

  return response;
};

export const toWeiDecimals = (amount: string, decimals = 18) => {
  try {
    const result = new BigNumber(amount).multipliedBy(new BigNumber(10).pow(decimals)).toString();
    if (result === "NaN") {
      return "0";
    }
    return result;
  } catch (e) {
    return "0";
  }
};

export const fromWeiDecimals = (amount: string, decimals = 18) => {
  try {
    const result = new BigNumber(amount).dividedBy(new BigNumber(10).pow(decimals)).toString();
    if (result === "NaN") {
      return "0";
    }
    return result;
  } catch (error) {
    return "0";
  }
};
