import {
  VC__is_not_email,
  VC__is_not_same_string,
  VC__is_empty_value,
  VC__is_not_repeat_str_num,
  VC__is_not_string_less_than_greater_than,
  VC__is_not_strong_password_condition,
  VC__is_not_consec_str_on_keyboard,
  VC__is_not_consec_str_in_alphabet,
  VC__is_not_consec_number,
  VC__is_not_same_number,
} from "../../../../modules/validation/validationCondition";

const PW_LESS_CONDITION = 8;
const PW_GREATER_CONDITION = 15;

export function VALIDATION__USER_currentPassword(password) {
  return [
    {
      condition: !VC__is_empty_value(password),
      message: "",
    },
  ];
}

export function VALIDATION__USER_newPassword(password) {
  return [
    {
      condition: !VC__is_empty_value(password),
      message: "",
    },
    {
      condition: !VC__is_not_string_less_than_greater_than(password, PW_LESS_CONDITION, PW_GREATER_CONDITION),
      message: `Please enter within ${PW_LESS_CONDITION} to ${PW_GREATER_CONDITION} characters.`,
    },
    {
      condition: !VC__is_not_strong_password_condition(password),
      message: "Please include uppercase letters, lowercase letters, numbers, and special characters.",
    },
    {
      condition: !VC__is_not_consec_str_on_keyboard(password),
      message: "Please do not enter consecutive numbers, consecutive letters, repeated letters, or repeated numbers.",
    },
    {
      condition: !VC__is_not_consec_str_in_alphabet(password),
      message: "Please do not enter consecutive numbers, consecutive letters, repeated letters, or repeated numbers.",
    },
    {
      condition: !VC__is_not_consec_number(password),
      message: "Please do not enter consecutive numbers, consecutive letters, repeated letters, or repeated numbers.",
    },
    {
      condition: !VC__is_not_repeat_str_num(password),
      message: "Please do not enter consecutive numbers, consecutive letters, repeated letters, or repeated numbers.",
    },
  ];
}

export function VALIDATION__USER_confirmPassword(newPassword, confirmPassword) {
  return [
    {
      condition: !VC__is_empty_value(confirmPassword),
      message: "",
    },
    {
      condition: !VC__is_not_same_string(newPassword, confirmPassword),
      message: "Please enter the same password.",
    },
  ];
}

export function VALIDATION__USER_SIGNUP_email(email) {
  return [
    {
      condition: !VC__is_empty_value(email),
      message: "",
    },
    {
      condition: !VC__is_not_email(email),
      message: "Please enter your email address according to the email format",
    },
  ];
}

export function VALIDATION__USER_SIGNUP_firstName(firstName) {
  return [
    {
      condition: !VC__is_empty_value(firstName),
      message: "",
    },
  ];
}

export function VALIDATION__USER_SIGNUP_lastName(lastName) {
  return [
    {
      condition: !VC__is_empty_value(lastName),
      message: "",
    },
  ];
}

export function VALIDATION__USER_SIGNIN_otp(otp) {
  return [
    {
      condition: !VC__is_empty_value(otp),
      message: "",
    },
    {
      condition: !VC__is_not_same_number(otp.length, 6),
      message: "",
    },
  ];
}
