import { useMemo, useState } from "react";
import "./ManageBlockExplorer.scss";
import { useNavigate, useParams } from "react-router-dom";
import StatusDetail, { DEFAULT_LABEL_STYLE } from "../../../../components/_atom/label/StatusDetail";
import PageLoading from "../../../../components/_atom/loading/PageLoading";
import { errorAlert } from "../../../../../utils/Utils";
import toast from "react-hot-toast";
import { disableBlockExplorerAction, getBlockExplorerDetailAction, releaseBlockExplorerAction } from "action/blockExplorerAction";
import PageTitle from "view/components/_molecules/page/PageTitle";
import PageArticle from "view/components/_template/page/PageArticle";
import useGetSelectedProject from "hooks/useGetSelectedProject";
import BasicButton from "view/components/_atom/button/BasicButton";
import { ButtonSize, ButtonTheme } from "view/components/_atom/button/StyledButton";
import useLoading from "hooks/useLoading";
import ButtonContainer from "view/components/_atom/container/ButtonContainer";
import { ManageFieldContainer, ManageFieldLabel, ManageFieldWrapper, RowManageField } from "view/components/_atom/section/ManageField";
import blockExplorerIcon from "../../../../assets/images/service/Icon_SNB_block_explorer.png";

function ManageBlockExplorer() {
  const { id: microChainId } = useParams();
  const navigate = useNavigate();
  const { isOwner } = useGetSelectedProject();

  const [blockExplorerDetail, setBlockExplorerDetail] = useState({});

  const statusParser = useMemo(() => {
    switch (blockExplorerDetail?.mainStatus) {
      default:
        return blockExplorerDetail?.mainStatus;
    }
  }, [blockExplorerDetail]);

  const statusData = useMemo(() => {
    switch (blockExplorerDetail.mainStatus) {
      case 0:
        return {
          text: DEFAULT_LABEL_STYLE.find((el) => el.status === statusParser).text,
          description: blockExplorerDetail?.rejectReason,
        };
      default:
        return {
          text: DEFAULT_LABEL_STYLE.find((el) => el.status === statusParser)?.text,
          description: DEFAULT_LABEL_STYLE.find((el) => el.status === statusParser)?.description,
        };
    }
  }, [blockExplorerDetail, statusParser]);

  const getBlockExplorerDetail_ = async () => {
    const { result, error } = await getBlockExplorerDetailAction(microChainId);

    if (result) {
      setBlockExplorerDetail(result);
    }

    if (error) {
      if (error.data.status === "404") {
        navigate("/tool/block-explorer/manage");
      }
      errorAlert(error.data.message);
    }
  };

  const releaseBlockExplorer_ = async () => {
    const { result, error } = await releaseBlockExplorerAction(microChainId);

    if (result) {
      toast.success("Release success");
      navigate("/tool/block-explorer/manage");
    }
    if (error) {
      errorAlert(error.data.message);
    }
  };

  const disableBlockExplorer_ = async () => {
    const { result, error } = await disableBlockExplorerAction(microChainId);

    if (result) {
      toast.success("Delete success");
      navigate("/tool/block-explorer/manage");
    }

    if (error) {
      errorAlert(error.data.message);
    }
  };

  const { loading } = useLoading({
    dependency: [microChainId],
    synchronousFunction: async () => {
      await getBlockExplorerDetail_();
    },
  });

  if (loading) {
    return <PageLoading />;
  }

  return (
    <PageArticle>
      <PageTitle size={810} title={"Block Explorer Detail"} />
      <div className="page-layout-810">
        <StatusDetail status={statusParser} data={statusData} />

        <ManageFieldContainer title="Basic Info" icon={blockExplorerIcon}>
          <ManageFieldWrapper>
            <RowManageField>
              <ManageFieldLabel label="ID" />
              <span>{blockExplorerDetail?.microChainId ?? "-"}</span>
            </RowManageField>
            <RowManageField>
              <ManageFieldLabel label="Network" />
              <span>{blockExplorerDetail?.networkLabel ?? "-"}</span>
            </RowManageField>
            <RowManageField>
              <ManageFieldLabel label="Microchain" />
              <span>{blockExplorerDetail?.microChainLabel ?? "-"}</span>
            </RowManageField>
          </ManageFieldWrapper>
        </ManageFieldContainer>

        <div className="manage-block-explorer-button-container">
          <ButtonContainer>
            {blockExplorerDetail?.mainStatus === 3 && (
              <BasicButton size={ButtonSize.LARGE} onClick={() => releaseBlockExplorer_()}>
                Publish
              </BasicButton>
            )}
            {blockExplorerDetail?.mainStatus === 5 && isOwner && (
              <BasicButton size={ButtonSize.LARGE} theme={ButtonTheme.MINOR} onClick={() => disableBlockExplorer_()}>
                Delete
              </BasicButton>
            )}
          </ButtonContainer>
        </div>
      </div>
    </PageArticle>
  );
}

export default ManageBlockExplorer;
