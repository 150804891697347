import React, {useMemo} from 'react';
import {STATUS} from "./constants/status.type";
import {STATUS_DATA} from "./constants/status.data";
import './Status.scss'

type StatusProps = {
  status: STATUS;
  text?: string;
  viewText?: boolean;
}

const Status = ({status, text, viewText = false}: StatusProps) => {
  const statusStyle = useMemo(() => {
    const target = STATUS_DATA.find(statusData => statusData.status === status);
    return target ? target : STATUS_DATA[STATUS_DATA.length - 1]
  },[status])

  return (
    <div className='status'>
      <img className='status-icon' src={statusStyle.icon} alt="icon"/>
      {viewText &&
        <span className='status-text' style={{color: statusStyle.color}}>{text ? text : statusStyle.defaultText}</span>
      }
    </div>
  );
};

export default Status;

