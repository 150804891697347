import { useState } from "react";
import "./EditNetwork.scss";
import { useNavigate, useParams } from "react-router-dom";
import ProgressState from "../../../../components/_molecules/progressState/ProgressState";
import { errorAlert } from "../../../../../utils/Utils";
import { NETWORK_DATA_FORMAT } from "../constants/networkConstants";
import NetworkEquilibriumPreAllocationSetting from "../component/template/NetworkEquilibriumPreAllocationSetting";
import toast from "react-hot-toast";
import {
  getBlockchainEngineListAction,
  getNetworkDetailForEditAction,
  getNetworkPlanListAction,
  updateBesuNetworkAction,
  updateEquilibriumNetworkAction,
} from "../../../../../action/networkAction";
import PageTitle from "view/components/_molecules/page/PageTitle";
import PageArticle from "view/components/_template/page/PageArticle";
import useEditable from "../../../../../hooks/useEditable";
import PageLoading from "view/components/_atom/loading/PageLoading";
import NotFound from "view/components/_page/notFound/NotFound";
import DivideLine from "view/components/_atom/line/DivideLine";
import useGetSelectedProject from "hooks/useGetSelectedProject";
import useLoading from "hooks/useLoading";
import {
  ERROR_DUPLICATE_COIN_NAME_IN_EDIT,
  ERROR_DUPLICATE_COIN_SYMBOL_IN_EDIT,
  ERROR_DUPLICATE_NETWORK_NAME_IN_EDIT,
  NETWORK_ENGINE_TYPE_EQUILIBRIUM__1_1_6__PON,
  NETWORK_ENGINE_TYPE_ETHEREUM__1_1_6__CLIQUE,
  NETWORK_STEP_LIST,
} from "../constants/network.data";
import NetworkBasicInfoSetting from "../component/template/NetworkBasicInfoSetting";
import NetworkEngineSetting from "../component/template/NetworkEngineSetting";
import NetworkNodeEquilibriumSetting from "../component/template/NetworkNodeEquilibriumSetting";
import NetworkNodeBesuSetting from "../component/template/NetworkNodeBesuSetting";
import NetworkBesuPreAllocationSetting from "../component/template/NetworkBesuPreAllocationSetting";

const EditNetwork = () => {
  const navigate = useNavigate();
  const networkId = useParams().id;

  const { selectedProjectID } = useGetSelectedProject();

  const [currentStep, setCurrentStep] = useState(1);
  const [networkType, setNetworkType] = useState(0);

  const [blockchainEngineList, setBlockchainEngineList] = useState<Array<any>>([]);
  const [networkPlanList, setNetworkPlanList] = useState([]);

  const [networkData, setNetworkData] = useState(NETWORK_DATA_FORMAT);

  const getBlockchainEngineList_ = async () => {
    const { result, error } = await getBlockchainEngineListAction();

    if (result) {
      setBlockchainEngineList(result);
    }
    if (error) {
    }
  };

  const getNetworkDetail_ = async () => {
    const { result, error } = await getNetworkDetailForEditAction(Number(networkId));

    if (result) {
      setNetworkData(result);
    }
    if (error) {
      errorAlert(error.data.message);
    }
  };

  const getNetworkPlanList_ = async () => {
    const { result, error } = await getNetworkPlanListAction({ isPrivate: "false" });

    if (result) {
      setNetworkPlanList(result);
    }
    if (error) {
    }
  };

  const getInitialData_ = async () => {
    await getNetworkPlanList_();
    await getNetworkDetail_();
    await getBlockchainEngineList_();
  };

  const updateEquilibriumNetwork_ = async () => {
    const { network, microChain, microChainSetting, nodeAlloc, preAlloc, currency } = networkData;
    const { result, error } = await updateEquilibriumNetworkAction(network, microChain, microChainSetting, nodeAlloc, preAlloc, currency);

    if (result) {
      toast.success("Edit successfully");
      navigate(`/network/mainnet/manage/${networkId}`);
    }

    if (error) {
      if (error.data.serverStatus === ERROR_DUPLICATE_NETWORK_NAME_IN_EDIT) {
        toast.error(error.data.message);
        setCurrentStep(1);
        return;
      }
      if (error.data.serverStatus === ERROR_DUPLICATE_COIN_NAME_IN_EDIT) {
        toast.error(error.data.message);
        setCurrentStep(1);
        return;
      }
      if (error.data.serverStatus === ERROR_DUPLICATE_COIN_SYMBOL_IN_EDIT) {
        toast.error(error.data.message);
        setCurrentStep(1);
        return;
      }
      errorAlert(error.data.message);
      return;
    }
  };

  const updateBesuNetwork_ = async () => {
    const { network, microChain, microChainSetting, nodeAlloc, preAlloc, currency } = networkData;
    const { result, error } = await updateBesuNetworkAction(network, microChain, microChainSetting, nodeAlloc, preAlloc, currency);

    if (result) {
      toast.success("Edit successfully");
      navigate(`/network/mainnet/manage/${networkId}`);
    }

    if (error) {
      if (error.data.serverStatus === ERROR_DUPLICATE_NETWORK_NAME_IN_EDIT) {
        toast.error(error.data.message);
        setCurrentStep(1);
        return;
      }
      if (error.data.serverStatus === ERROR_DUPLICATE_COIN_NAME_IN_EDIT) {
        toast.error(error.data.message);
        setCurrentStep(1);
        return;
      }
      if (error.data.serverStatus === ERROR_DUPLICATE_COIN_SYMBOL_IN_EDIT) {
        toast.error(error.data.message);
        setCurrentStep(1);
        return;
      }
      errorAlert(error.data.message);
      return;
    }
  };

  const editNetwork_ = async (validation) => {
    if (!validation.isValid) {
      toast.error(validation.message);
      return;
    }

    if (networkType === NETWORK_ENGINE_TYPE_EQUILIBRIUM__1_1_6__PON) {
      await updateEquilibriumNetwork_();
    }

    if (networkType === NETWORK_ENGINE_TYPE_ETHEREUM__1_1_6__CLIQUE) {
      await updateBesuNetwork_();
    }
  };

  const { loading } = useLoading({
    dependency: [selectedProjectID],
    synchronousFunction: async () => {
      await getInitialData_();
    },
  });

  const { editable } = useEditable({ dependency: [!loading], currentStatus: networkData.mainStatus });

  if (loading) {
    return <PageLoading />;
  }

  if (!editable) {
    return <NotFound />;
  }

  return (
    <PageArticle scrollDependency={currentStep}>
      <PageTitle title={"Edit Network"} description={"Edit your own Network."} size={810} />
      <div className="page-layout-810">
        <ProgressState step={currentStep} stepList={NETWORK_STEP_LIST} size={730} />
        <DivideLine style={{ margin: "40px 0" }} />

        {currentStep === 1 && <NetworkBasicInfoSetting setCurrentStep={setCurrentStep} networkData={networkData} setNetworkData={setNetworkData} />}
        {currentStep === 2 && (
          <NetworkEngineSetting
            setCurrentStep={setCurrentStep}
            networkData={networkData}
            setNetworkData={setNetworkData}
            blockchainEngineList={blockchainEngineList}
            setNetworkType={setNetworkType}
          />
        )}
        {currentStep === 3 && networkType === NETWORK_ENGINE_TYPE_EQUILIBRIUM__1_1_6__PON && (
          <NetworkNodeEquilibriumSetting setCurrentStep={setCurrentStep} networkData={networkData} setNetworkData={setNetworkData} />
        )}
        {currentStep === 3 && networkType === NETWORK_ENGINE_TYPE_ETHEREUM__1_1_6__CLIQUE && (
          <NetworkNodeBesuSetting setCurrentStep={setCurrentStep} networkData={networkData} setNetworkData={setNetworkData} />
        )}
        {currentStep === 4 && networkType === NETWORK_ENGINE_TYPE_EQUILIBRIUM__1_1_6__PON && (
          <NetworkEquilibriumPreAllocationSetting
            setCurrentStep={setCurrentStep}
            networkData={networkData}
            setNetworkData={setNetworkData}
            updateButtonHandler={editNetwork_}
          />
        )}
        {currentStep === 4 && networkType === NETWORK_ENGINE_TYPE_ETHEREUM__1_1_6__CLIQUE && (
          <NetworkBesuPreAllocationSetting
            setCurrentStep={setCurrentStep}
            networkData={networkData}
            setNetworkData={setNetworkData}
            updateButtonHandler={editNetwork_}
          />
        )}
      </div>
    </PageArticle>
  );
};

export default EditNetwork;
