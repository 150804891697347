import AirdropConditionEventBox from "../atom/AirdropConditionEventBox";
import "./AirdropConditionEventList.scss";

const FixedAirdropConditionEventList = (props) => {
  const { participantEventsData, disabled = false } = props || {};

  return (
    <div className="airdrop-condition-event-list">
      {participantEventsData.map((el, index) => {
        return <AirdropConditionEventBox data={el} index={index} key={`fixed-airdrop-condition-event-box-${index}`} disabled={disabled} />;
      })}
    </div>
  );
};

export default FixedAirdropConditionEventList;
