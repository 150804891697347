import {checkAccessToken, checkRefreshToken, ERROR_TYPE_LOGIN_EXPIRED} from "../../server/index/utils";
import {EQHub} from "./dist";
import {getRequestId} from "../../server/index/Queue";

type JWTToken = {
	access: JWT;
	refresh: JWT;
};

type JWT = {
	token?: string | null;
	expires: string;
};

type QueueItem = {
	method: any;
	requestId: string;
};

enum QueueStatus {
	READY = 0,
	WAIT = 1,
	UNABLE = 2,
}

export class Queue {
	queue: QueueItem[];
	methodManager: any;
	methodEvent: any;
	status: number;

	constructor(methodManager: any, methodEventManager: any) {
		this.queue = [];
		this.methodManager = methodManager;
		this.methodEvent = methodEventManager;
		this.status = QueueStatus.READY;
	}

	public resetQueue() {
		this.queue = [];
		this.status = QueueStatus.READY;
	}

	public async addQueue(method: any, requestId: string) {
		this.queue = [{method, requestId}, ...this.queue];
		if (this.canRequest()) {
			await this.noticeQueueNotEmpty();
		}
	}

	private async runNextQueueItem() {
		if (this.canRequest()) {
			await this.noticeQueueNotEmpty();
		}
	}

	private canRequest() {
		return this.queue.length > 0 && this.status === QueueStatus.READY;
	}

	private async noticeQueueNotEmpty() {
		await this.runMethodManager();
	}

	private async runMethodManager() {
		this.status = QueueStatus.WAIT;
		const methodObject = this.queue[0];
		this.queue = this.queue.filter((el) => el.requestId !== methodObject.requestId);
		try {
			const result = await this.methodManager.activateMethod(methodObject.method);
			this.status = QueueStatus.READY;
			this.methodEvent.emit(methodObject.requestId, result);
		} catch (e) {
			this.methodEvent.emit(methodObject.requestId, e);
			if (e.type === ERROR_TYPE_LOGIN_EXPIRED) {
				this.status = QueueStatus.UNABLE;
			} else {
				this.status = QueueStatus.READY;
			}
		}
		this.runNextQueueItem();
	}
}

export class MethodManager {
	method: any;
	refreshApi: () => Promise<JWTToken>;

	constructor(refreshApi: any) {
		this.method = [];
		this.refreshApi = refreshApi;
	}

	public async activateMethod(method: any) {
		let result;
		try {
			result = await method();
			return result;
		} catch (e) {
			const isAccessTokenExpired = checkAccessToken(e.error.serverStatusCode);
			if (isAccessTokenExpired) {
				try {
					await this.handleRefreshApi();
					// 이후 재요청
					result = await method();
					return result;
				} catch (e) {
					throw e;
				}
			} else {
				throw e;
			}
		}
	}

	private async handleRefreshApi() {
		try {
			const result = await this.refreshApi();
			return result;
		} catch (e) {
			const isRefreshTokenExpired = checkRefreshToken(e.error.serverStatusCode);
			if (isRefreshTokenExpired) {
				throw {type: ERROR_TYPE_LOGIN_EXPIRED};
			}
		}
	}
}

// 프록시 클래스
export class MethodWithAddQueue extends EQHub {
	private queueInstance: Queue;
	eventInstance: any;

	constructor(baseInstance: EQHub, queueInstance: Queue, eventInstance: any) {
		super();
		this.queueInstance = queueInstance;
		this.eventInstance = eventInstance;

		// Apply proxy to each method in baseClass and contractLibrary
		return new Proxy(this, this._createHandler(baseInstance));
	}

	private _createHandler(baseInstance: EQHub) {
		const self = this;

		return {
			get(target: any, property: string) {
				const originalProperty = (baseInstance as any)[property];

				if (typeof originalProperty === 'function') {
					// Wrap the baseClass method
					return function (...args: any[]) {
						const result = originalProperty.apply(target, args);
						return result;
					};
				}

				// Apply proxy to contractLibrary methods
				if (property === 'contractLibrary' && typeof originalProperty === 'object') {
					return new Proxy(originalProperty, {
						get(target, prop) {
							const originalMethod = (target as any)[prop];

							if (typeof originalMethod === 'function') {
								return async function (...args: any[]) {
									const methodFunction = () => originalMethod.apply(this, args);
									const methodRequestId = getRequestId();
									return await new Promise(resolve => {
										self.eventInstance.once(methodRequestId, (response: any) => {
											resolve(response);
										})
										// Add result to queue
										self.queueInstance.addQueue(methodFunction, methodRequestId);
									})

								};
							}
							return originalMethod;
						},
					});
				}

				return originalProperty;
			}
		};
	}
}
