import { useMemo, useState } from "react";
import "./ManageWallet.scss";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import StatusDetail, { DEFAULT_LABEL_STYLE } from "../../../../components/_atom/label/StatusDetail";
import PageLoading from "../../../../components/_atom/loading/PageLoading";
import { errorAlert } from "../../../../../utils/Utils";
import CardPreviewer from "view/components/_atom/viewer/CardPreviewer";
import { toast } from "react-hot-toast";
import {
  confirmWalletAction,
  deleteWalletAction,
  getWalletDetailAction,
  rejectWalletAction,
  releaseWalletAction,
  submitWalletAction,
} from "../../../../../action/walletAction";
import { selected_project_id } from "reducer/projectReducer";
import PageTitle from "view/components/_molecules/page/PageTitle";
import PageArticle from "view/components/_template/page/PageArticle";
import useGetSelectedProject from "hooks/useGetSelectedProject";
import BasicButton from "view/components/_atom/button/BasicButton";
import { ButtonSize, ButtonTheme } from "view/components/_atom/button/StyledButton";
import useLoading from "hooks/useLoading";
import ButtonContainer from "view/components/_atom/container/ButtonContainer";
import { ManageFieldContainer, ManageFieldLabel, ManageFieldWrapper, RowManageField } from "view/components/_atom/section/ManageField";
import DivideLine from "view/components/_atom/line/DivideLine";
import { ASSET_PURPOSE_TYPE_NFT } from "../constants/walletConstants";
import walletIcon from "../../../../assets/images/service/Icon_SNB_wallet.png";

const EMPTY_WALLET_ENDPOINT = "http://";

function ManageWallet() {
  const { id: microChainAssetId } = useParams();
  const { isOwner } = useGetSelectedProject();

  const projectId = useSelector(selected_project_id);

  const navigate = useNavigate();

  const [walletDetail, setWalletDetail] = useState();

  const statusParser = useMemo(() => {
    switch (walletDetail?.mainStatus) {
      default:
        return walletDetail?.mainStatus;
    }
  }, [walletDetail]);

  const statusData = useMemo(() => {
    switch (walletDetail?.mainStatus) {
      case 0:
        return {
          text: DEFAULT_LABEL_STYLE.find((el) => el.status === statusParser).text,
          description: walletDetail?.rejectReason,
        };
      default:
        return {
          text: DEFAULT_LABEL_STYLE.find((el) => el.status === statusParser)?.text,
          description: DEFAULT_LABEL_STYLE.find((el) => el.status === statusParser)?.description,
        };
    }
  }, [walletDetail, statusParser]);

  const getWalletDetail_ = async () => {
    const { result, error } = await getWalletDetailAction(microChainAssetId);

    if (result) {
      setWalletDetail(result);
    }
    if (error) {
      if (error.data.status === "404") {
        navigate("/tool/wallet/manage");
      }
      errorAlert(error.data.message);
      return;
    }
  };

  const submitWallet_ = async () => {
    const { result, error } = await submitWalletAction(walletDetail.microChainId, walletDetail.microChainAssetId, walletDetail.assetId);

    if (result) {
      toast.success("processed successfully");
      setWalletDetail(result);
    }
    if (error) {
      errorAlert(error.data.message);
      return;
    }
  };

  const rejectWallet_ = async (reason) => {
    const { result, error } = await rejectWalletAction({
      microChainId: walletDetail.microChainId,
      microChainAssetId: walletDetail.microChainAssetId,
      rejectReason: reason,
      assetId: walletDetail.assetId,
    });

    if (result) {
      toast.success("processed successfully");
      navigate(-1);
    }
    if (error) {
      errorAlert(error.data.message);
      return;
    }
  };

  const confirmWallet_ = async () => {
    const { result, error } = await confirmWalletAction({
      microChainId: walletDetail.microChainId,
      microChainAssetId: walletDetail.microChainAssetId,
      assetId: walletDetail.assetId,
    });

    if (result) {
      toast.success("processed successfully");
      navigate(-1);
    }
    if (error) {
      errorAlert(error.data.message);
    }
  };

  const releaseWallet_ = async () => {
    const { result, error } = await releaseWalletAction(projectId, walletDetail.microChainId, walletDetail.microChainAssetId, walletDetail.assetId);

    if (result) {
      toast.success("Release success");
      navigate("/tool/wallet/manage");
    }
    if (error) {
      errorAlert(error.data.message);
      return;
    }
  };

  const deleteWallet_ = async () => {
    const { result, error } = await deleteWalletAction(walletDetail.microChainId, walletDetail.microChainAssetId, walletDetail.assetId);

    if (result) {
      toast.success("Delete success");
      navigate("/tool/wallet/manage");
    }
    if (error) {
      errorAlert(error.data.message);
      return;
    }
  };

  const { loading } = useLoading({
    dependency: [microChainAssetId],
    synchronousFunction: async () => {
      await getWalletDetail_();
    },
  });

  if (loading) {
    return <PageLoading />;
  }

  return (
    <PageArticle>
      <PageTitle size={810} title={"Wallet Detail"} />
      <div className="page-layout-810">
        <StatusDetail status={statusParser} data={statusData} />
        <ManageFieldContainer title="Wallet Detail" icon={walletIcon}>
          <ManageFieldWrapper title="Wallet Information">
            <RowManageField>
              <ManageFieldLabel label="ID" />
              <span>{walletDetail?.microChainAssetId ?? "-"}</span>
            </RowManageField>
            <RowManageField>
              <ManageFieldLabel label="Network" />
              <span>{walletDetail?.networkLabel ?? "-"}</span>
            </RowManageField>
            <RowManageField>
              <ManageFieldLabel label="Microchain" />
              <span>{walletDetail?.microChainLabel ?? "-"}</span>
            </RowManageField>
            <RowManageField>
              <ManageFieldLabel label="Type" />
              <span>{walletDetail?.type ?? "-"}</span>
            </RowManageField>
            {walletDetail?.endPoint !== EMPTY_WALLET_ENDPOINT && walletDetail?.endPoint && (
              <RowManageField>
                <ManageFieldLabel label="End point" />
                <span>{walletDetail?.endPoint ?? "-"}</span>
              </RowManageField>
            )}
          </ManageFieldWrapper>
          {walletDetail?.assetId !== ASSET_PURPOSE_TYPE_NFT && (
            <>
              <DivideLine style={{ margin: "20px 0" }} />

              <ManageFieldWrapper title="Wallet Card">
                <RowManageField>
                  <ManageFieldLabel label="Card image" />
                  <CardPreviewer
                    cardImage={walletDetail.backgroundImage}
                    symbol={walletDetail.symbol}
                    cardColor={walletDetail.fontColor}
                    networkLabel={walletDetail.networkLabel}
                  />
                </RowManageField>
              </ManageFieldWrapper>
            </>
          )}
        </ManageFieldContainer>
        <div className="manage-wallet-button-container">
          <ButtonContainer>
            {walletDetail?.mainStatus < 2 && (
              <BasicButton size={ButtonSize.LARGE} onClick={() => submitWallet_()}>
                Submit
              </BasicButton>
            )}
            {walletDetail?.mainStatus === 3 && (
              <BasicButton size={ButtonSize.LARGE} onClick={() => releaseWallet_()}>
                Publish
              </BasicButton>
            )}
            {walletDetail?.mainStatus === 5 && isOwner && (
              <BasicButton size={ButtonSize.LARGE} theme={ButtonTheme.MINOR} onClick={() => deleteWallet_()}>
                Delete
              </BasicButton>
            )}
          </ButtonContainer>
        </div>
      </div>
    </PageArticle>
  );
}

export default ManageWallet;
