import { useEffect, useState } from "react";
import IconChainRequest from "./assets/Icon_dashboard_chain_request.png";
import { useSelector } from "react-redux";
import { connectInfo } from "../../../store/redux/EQconnect/EQConnectSlice";
import { errorAlert } from "../../../utils/Utils";
import Select from "../../components/_atom/select/Select";
import toast from "react-hot-toast";
import IconCopy from "./assets/Icon_copy_address.png";
import EQConnectWalletButton from "../../../modules/EQConnect/EQConnectWalletButton";
import CopyToClipboard from "react-copy-to-clipboard";
import PageLoading from "../../components/_atom/loading/PageLoading";
import { getChainsForChainRequestAction, getContractFunctionsWithAbiCodeAction, getDeployedContractByChainIdAction } from "../../../action/chainRequestAction";
import DashboardRequestCall from "./components/DashboardRequestCall";
import DashboardRequestTransaction from "./components/DashboardRequestTransaction";
import DashboardMethodSelectWrapper from "./components/DashboardMethodSelectWrapper";
import SectionContainer from "../../components/_atom/section/SectionContainer";
import "./DashboardContract.scss";
import Warning from "../../components/_atom/box/Warning";
import IconMethod from "./assets/Icon_contract_method.png";
import IconExecute from "./assets/Icon_contract_execute.png";

const DashboardContract = () => {
  const { address } = useSelector(connectInfo);

  const [isLoading, setIsLoading] = useState(true);

  const [chainState, setChainState] = useState({
    networks: [],
    microChains: [],
    availableMicroChains: [],
    selectedNetworkId: null,
    selectedMicroChain: null,
    selectedMicroChainId: null,
  });

  const [contractState, setContractState] = useState({
    contracts: [],
    selectedContractId: null,
    selectedContract: null,
  });

  const [methodState, setMethodState] = useState({
    abiCode: [],
    transactions: [],
    calls: [],
    selectedMethod: null,
  });

  const setSelectedNetwork = (value) => {
    setChainState((prev) => ({
      ...prev,
      selectedNetworkId: value,
    }));
  };

  const setSelectedMicroChain = (value) => {
    setChainState((prev) => ({
      ...prev,
      selectedMicroChainId: value,
      selectedMicroChain: chainState.availableMicroChains.find((chain) => chain.id === value) ?? null,
    }));
  };

  const setSelectedContract = (value) => {
    setContractState((prev) => ({
      ...prev,
      selectedContractId: value,
      selectedContract: prev.contracts.find((contract) => contract.contractId === value),
    }));
  };

  const setInitialDataForChainRequest = async () => {
    const { error, result } = await getChainsForChainRequestAction();
    if (result) {
      setChainState((prev) => ({
        ...prev,
        ...result,
      }));
    }
    if (error) {
      errorAlert(error.data.message);
    }
  };

  const setContractsByChainId = async () => {
    const { error, result } = await getDeployedContractByChainIdAction(chainState.selectedMicroChainId);
    if (result) {
      setContractState((prev) => ({
        ...prev,
        contracts: result,
      }));
    }
    if (error) {
      errorAlert(error.data.message);
    }
  };

  const setContractFunctionsWithAbiCode = async () => {
    const { error, result } = await getContractFunctionsWithAbiCodeAction(chainState.selectedMicroChainId, contractState.selectedContract.contractAddress);
    if (result) {
      const { abiCode, functions } = result;
      const viewFunction = functions.filter((func) => func.stateMutability === "view");
      const transactionFunction = functions.filter((func) => func.stateMutability === "nonpayable" || func.stateMutability === "payable");

      setMethodState({
        abiCode: abiCode,
        transactions: transactionFunction,
        calls: viewFunction,
        selectedMethod: viewFunction[0] ?? transactionFunction[0] ?? null,
      });
    }
    if (error) {
      errorAlert(error.data.message);
    }
  };

  useEffect(() => {
    setInitialDataForChainRequest().finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    if (chainState.selectedNetworkId) {
      const availableMicroChains = chainState.microChains.filter((el) => el.networkId === chainState.selectedNetworkId);
      if (availableMicroChains.length === 0) {
        setChainState((prev) => ({
          ...prev,
          selectedMicroChainId: null,
          selectedMicroChain: null,
          availableMicroChains,
        }));
      } else {
        setChainState((prev) => ({
          ...prev,
          selectedMicroChainId: availableMicroChains.find((chain) => Boolean(chain.isMain)).id ?? null,
          selectedMicroChain: availableMicroChains.find((chain) => Boolean(chain.isMain)) ?? null,
          availableMicroChains,
        }));
      }
    }
  }, [chainState.selectedNetworkId]);

  useEffect(() => {
    if (chainState.selectedMicroChainId) {
      setContractState((prev) => ({
        ...prev,
        selectedContract: null,
        selectedContractId: null,
      }));
      setContractsByChainId();
    }
  }, [chainState.selectedMicroChainId]);

  useEffect(() => {
    if (Boolean(address) && Boolean(contractState.selectedContract?.contractAddress)) {
      setContractFunctionsWithAbiCode();
    }
  }, [address, contractState.selectedContract]);

  return isLoading ? (
    <PageLoading />
  ) : (
    <div className="flex-column-20">
      <SectionContainer icon={IconChainRequest} title={"Select Network & Contract"}>
        <div className="flex-column-20">
          <div className="flex-20">
            <span className="dashboard-contract-row-label">Network / Microchain</span>
            <Select
              labelKey={"label"}
              valueKey={"id"}
              options={chainState.networks}
              selectedValue={chainState.selectedNetworkId}
              setSelectedValue={setSelectedNetwork}
              canSelect={chainState.networks.length !== 0}
              placeholder={"Select Network"}
            />
            <Select
              labelKey={"label"}
              valueKey={"id"}
              options={chainState.availableMicroChains}
              selectedValue={chainState.selectedMicroChainId}
              setSelectedValue={setSelectedMicroChain}
              canSelect={chainState.selectedNetworkId && chainState.availableMicroChains.length !== 0}
              placeholder={"Select MicroChain"}
            />
          </div>
          <div className="flex-20">
            <span className="dashboard-contract-row-label">Contract</span>
            <div className="dashboard-contract-contract-wrapper">
              <Select
                labelKey={"name"}
                valueKey={"contractId"}
                options={contractState.contracts}
                selectedValue={contractState.selectedContractId}
                setSelectedValue={setSelectedContract}
                canSelect={chainState.selectedMicroChainId && contractState.contracts.length !== 0}
                placeholder={"Select Contract"}
              />
              {Boolean(contractState.selectedContract) && (
                <div className="flex align-items-center" style={{ marginTop: 10 }}>
                  <p className="sub-text">Contract Address: {contractState.selectedContract.contractAddress}</p>
                  <CopyToClipboard text={contractState.selectedContract.contractAddress} onCopy={() => toast("Copied contract address", { icon: "👏" })}>
                    <img src={IconCopy} alt="copy" style={{ width: 20, height: 20, objectFit: "contain", cursor: "pointer" }} />
                  </CopyToClipboard>
                </div>
              )}
            </div>
          </div>
          <div className="flex-20" style={{ justifyContent: "flex-end" }}>
            {Boolean(contractState.selectedContract) && (
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <EQConnectWalletButton className={address ? "addition-main border-blue" : "addition-main main"} />
              </div>
            )}
          </div>
        </div>
      </SectionContainer>
      <Warning
        title={"Caution : Be careful when using the ‘Transaction’ functions"}
        description={
          "Both the 'View' and 'Transaction' functions that occur on this page are functions that send requests to the blockchain network. Therefore, please be careful when using it. In particular, when generating the 'Transaction' function, please be careful when using it because data on the actual blockchain network is affected."
        }
      />
      {Boolean(address) && Boolean(contractState.selectedContract?.contractAddress) ? (
        <div className="flex-20">
          <div style={{ flex: 2 }}>
            <SectionContainer title={"Method"} icon={IconMethod}>
              <DashboardMethodSelectWrapper
                title={"View"}
                count={methodState.calls.length}
                description={"Request the data you need"}
                methods={methodState.calls}
                setMethodState={setMethodState}
                selectedMethod={methodState.selectedMethod}
              />
              <DashboardMethodSelectWrapper
                title={"Transaction"}
                count={methodState.transactions.length}
                description={"Request transactions"}
                methods={methodState.transactions}
                setMethodState={setMethodState}
                selectedMethod={methodState.selectedMethod}
              />
            </SectionContainer>
          </div>
          <div style={{ flex: 8 }}>
            <SectionContainer title={"Execute"} icon={IconExecute}>
              {methodState.calls.find((el) => el === methodState.selectedMethod) && (
                <DashboardRequestCall
                  selectedMethod={methodState.selectedMethod}
                  selectedMicroChain={chainState.selectedMicroChain}
                  selectedContract={contractState.selectedContract}
                />
              )}
              {methodState.transactions.find((el) => el === methodState.selectedMethod) && (
                <DashboardRequestTransaction
                  selectedMethod={methodState.selectedMethod}
                  selectedMicroChain={chainState.selectedMicroChain}
                  selectedContract={contractState.selectedContract}
                />
              )}
            </SectionContainer>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default DashboardContract;
