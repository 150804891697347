import React from "react";
import css from "./NodePresetBox.scss";
import { bindStyle } from "view/styles/classNames";
import consensusNodeIcon from "../../assets/Icon_consensus_node.png";
import fullNodeIcon from "../../assets/Icon_full_node.png";
import bonusCreditIcon from "../../assets/Icon_bonus_credit.png";
import { numberConvertor } from "utils/convert.utils";

type NodePresetBoxProps = {
  name: string;
  description: string;
  equalizerCount: string;
  lightNodeCount: string;
  bonusCredit: string;
  networkPlanId?: number;
  selectedNetworkPlanId?: number;
  disabled?: boolean;
};

const NodePresetBox = (props: NodePresetBoxProps) => {
  const { networkPlanId, selectedNetworkPlanId, name, description, equalizerCount, lightNodeCount, bonusCredit, disabled = true } = props;

  const cn = bindStyle(css);

  return (
    <div className={cn("node-preset-box", { selected: Boolean(selectedNetworkPlanId) && networkPlanId === selectedNetworkPlanId, disabled })}>
      <div className="node-preset-box-info">
        <p className="node-preset-box-info-title">{name}</p>
        <p className="node-preset-box-info-description">{description}</p>
      </div>
      <div className="node-preset-box-content">
        <div className="node-preset-box-content-item">
          <div className="node-preset-box-content-item-icon">
            <img src={consensusNodeIcon} className="node-preset-box-content-item-icon-src" alt="" />
          </div>
          <span className="node-preset-box-content-item-label">{equalizerCount}</span>
        </div>
        <div className="node-preset-box-content-item">
          <div className="node-preset-box-content-item-icon">
            <img src={fullNodeIcon} className="node-preset-box-content-item-icon-src" alt="" />
          </div>
          <span className="node-preset-box-content-item-label">{lightNodeCount}</span>
        </div>
        <div className="node-preset-box-content-item">
          <div className="node-preset-box-content-item-icon">
            <img src={bonusCreditIcon} className="node-preset-box-content-item-icon-src" alt="" />
          </div>
          <span className="node-preset-box-content-item-label">{numberConvertor(Number(bonusCredit))}</span>
        </div>
      </div>
    </div>
  );
};

export default NodePresetBox;
