export type ServiceStatus = {
  mainStatus: number,
  subStatus: number,
  statusDetail?: statusDetail
}

export enum statusType {
  CHECK  = 'check',
  WAIT   = 'wait',
  REJECT = 'reject'
}

export type statusDetail = {
  type: statusType;
  title?: string;
  description?: {
    text: string;
    color?: string;
  };
  showTransactionHash: boolean;
}

export const parseServiceStatus = (serviceStatusLiteral: Record<string, ServiceStatus>, mainStatus: number, subStatus: number) => {
  const targetStatus: [string, ServiceStatus] | undefined = Object.entries(serviceStatusLiteral).find(status => status[1].mainStatus === mainStatus && status[1].subStatus === subStatus);
  if(!targetStatus) {
    return 'UNKNOWN'
  }
  return targetStatus[0];
}

export const isDisplayTransactionStatus = (status: ServiceStatus | undefined) => {
  return Boolean(status?.statusDetail);
}
