import { l3ServerByProjectAuth, v2ServerByProjectAuth } from "../index/serverInstances";
import { TransactionReceiptFromServer, TransactionResultFromServer } from "../type/requestType";
import { makeQuery } from "../index/utils";

const REQUEST_API_VERSION = process.env.REACT_APP_API_VERSION;

// TODO :: 삭제 예정 (gateway만 사용)
const requestBalance = async (request: RequestBalanceRequest): Promise<RequestBalanceResponse> =>
  await l3ServerByProjectAuth.get({
    baseURL: request.baseURL,
    path: `${REQUEST_API_VERSION}request/balance`,
    query: makeQuery(request.query),
  });

type RequestBalanceRequest = {
  baseURL: string;
  query: {
    microChainId: number;
    address: string;
  };
};

type RequestBalanceResponse = {
  balance: string;
};

// TODO :: 삭제 예정 (gateway만 사용)
const getTransactionFee = async (request: GetTransactionFeeRequest): Promise<GetTransactionFeeResponse> =>
  await l3ServerByProjectAuth.get({
    baseURL: request.baseURL,
    path: `${REQUEST_API_VERSION}request/micro-chains/${request.params.microChainId}/fee`,
  });

type GetTransactionFeeRequest = {
  baseURL: string;
  params: {
    microChainId: number;
  };
};

type GetTransactionFeeResponse = {
  fee: string;
};

// TODO :: 삭제 예정 (requestTransactionButton 사용)
// LAGACY
const getTransactionReceipt = async (request: GetTransactionReceiptRequest): Promise<GetTransactionReceiptResponse> =>
  await l3ServerByProjectAuth.get({
    baseURL: request.baseURL,
    path: `${REQUEST_API_VERSION}request/transaction/${request.params.transactionHash}/polling`,
  });

type GetTransactionReceiptRequest = {
  baseURL: string;
  params: {
    transactionHash: string;
  };
};

type GetTransactionReceiptResponse = TransactionReceiptFromServer;

// --------------------------------------2023. 9. 20일 추가-----------------------------------------------------
// -------------- TODO 아래에 추가되는 API는 AG의 request Router로 요청보내는 API들입니다.-------------------------------

// getNonce -----------------------------------------------------
const getNonce = async (request: GetNonceRequest): Promise<GetNonceResponse> =>
  await v2ServerByProjectAuth.get({
    path: `request/nonce`,
    query: makeQuery(request.query),
  });

type GetNonceRequest = {
  query: {
    microChainId: number;
    address: string;
  };
};

type GetNonceResponse = {
  nonce: string;
};

// getBalance -----------------------------------------------------
const getBalance = async (request: GetBalanceRequest): Promise<GetBalanceResponse> =>
  await v2ServerByProjectAuth.get({
    path: `request/balance`,
    query: makeQuery(request.query),
  });

type GetBalanceRequest = {
  query: {
    microChainId: number;
    address: string;
  };
};

type GetBalanceResponse = {
  balance: string;
};

// estimateGas -----------------------------------------------------
const estimateGas = async (request: EstimateGasRequest): Promise<EstimateGasResponse> =>
  await v2ServerByProjectAuth.post({
    path: `request/estimate-gas`,
    query: makeQuery(request.query),
    data: request.data,
  });

type EstimateGasRequest = {
  query: {
    microChainId: number;
  };
  data: {
    to?: string;
    data?: string;
    from?: string;
    gas?: string;
    gasPrice?: string;
    value?: string;
  };
};

type EstimateGasResponse = {
  gas: string;
};

// call -----------------------------------------------------
const call = async (request: CallRequest): Promise<CallResponse> =>
  await v2ServerByProjectAuth.post({
    path: `request/call`,
    query: makeQuery(request.query),
    data: request.data,
  });

type CallRequest = {
  query: {
    microChainId: number;
  };
  data: {
    to: string | null;
    from?: string;
    gas?: string;
    gasPrice?: string;
    value?: string | null;
    data?: string | null;
  };
};

type CallResponse = any;

// getTransactionResult -----------------------------------------------------
const getTransactionResult = async (request: getTransactionResultRequest): Promise<getTransactionResultResponse> =>
  await v2ServerByProjectAuth.get({
    path: `transactions/result`,
    query: makeQuery(request.query),
  });

type getTransactionResultRequest = {
  query: {
    networkId: number;
    microChainId: number;
    transactionHash: string;
  };
};

type getTransactionResultResponse = Array<TransactionResultFromServer>;

export {
  requestBalance as requestBalanceFromServer,
  getTransactionFee as getTransactionFeeFromServer,
  getTransactionReceipt as getTransactionReceiptFromServer,
  //위의 API들은 Legacy 입니다.
  getNonce as getNonceFromServer,
  getBalance as getBalanceFromServer,
  estimateGas as estimateGasFromServer,
  call as callFromServer,
  getTransactionResult as getTransactionResultFromServer,
};
