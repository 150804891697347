import React, { Dispatch, RefObject, SetStateAction, useEffect, useMemo, useRef, useState } from "react";
import toast from "react-hot-toast";
import "./IconSelector.scss";
import useOuterClick from "../../../../hooks/useOutterClick";
import { StyledIcon } from "../../_atom/image/StyledIcon";
import IconReload from "../../../assets/images/icon_reload.png";
import BasicButton from "../../_atom/button/BasicButton";
import { ButtonSize, ButtonTheme } from "../../_atom/button/StyledButton";
import UploadIcon from "../../../assets/images/icon_upload_blue.png";
import DivideLine from "../../_atom/line/DivideLine";

type FileState = File | string;

export enum IconTheme {
  DEFAULT = "DEFAULT",
  CIRCLE = "CIRCLE",
}

type IconSelectorProps = {
  images: Array<string>;
  fileState: FileState;
  setFileState: Dispatch<SetStateAction<FileState>> | Function;
  accept?: Array<string>;
  maxSize?: number;
  iconTheme?: IconTheme;
  size?: number;
};

const stringArrayToString = (arr: Array<string>): string => arr.map((el) => `${el}/*`).join(", ");

const IconSelector = ({
  images,
  fileState,
  setFileState,
  accept = ["image"],
  maxSize = 5 * 1024 * 1024,
  iconTheme = IconTheme.DEFAULT,
  size = 80,
}: IconSelectorProps) => {
  const [preview, setPreview] = useState<string | null>(null);
  const [isHover, setIsHover] = useState(false);
  const [isDefaultImagePopupOpen, setIsDefaultImagePopupOpen] = useState<boolean>(false);
  const fileRef = useRef<HTMLInputElement>(null);
  const onClickFileRef = () => (fileRef.current ? fileRef.current.click() : undefined);
  const closePopUp = () => setIsDefaultImagePopupOpen(false);

  const uploadImage = () => {
    if (fileRef.current && fileRef.current.files && fileRef.current.files[0]) {
      const file = fileRef.current.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        if (file.size > maxSize) {
          toast.error("image file must be less than or equal to 5 MB");
        } else {
          setFileState(file);
        }
      };
    }
  };

  const setDefaultIcon = (icon: string) => setFileState(icon);

  const style = useMemo(() => {
    switch (iconTheme) {
      case IconTheme.CIRCLE:
        return {
          width: size,
          height: size,
          borderRadius: "50%",
        };
      default:
        return {
          width: size,
          height: size,
          borderRadius: 20,
        };
    }
  }, [iconTheme, size]);

  useEffect(() => {
    if (fileState) {
      if (typeof fileState === "string") {
        if (fileRef.current && fileRef.current.files) {
          fileRef.current.files = new DataTransfer().files;
        }
        setPreview(fileState);
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(fileState);
        reader.onloadend = () => {
          if (typeof reader.result === "string") setPreview(reader.result);
        };
      }
    } else {
      setPreview(null);
    }
  }, [fileState]);

  return (
    <div className="icon-selector">
      {isDefaultImagePopupOpen && (
        <DefaultImagePopup
          iconTheme={iconTheme}
          images={images}
          setDefaultIcon={setDefaultIcon}
          close={closePopUp}
          size={size}
          fileRef={fileRef}
          onClickFileRef={onClickFileRef}
        />
      )}

      <input type="file" accept={stringArrayToString(accept)} ref={fileRef} style={{ display: "none" }} onChange={() => uploadImage()} />

      <div className={"icon-selector-preview"} onMouseOver={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)} style={style}>
        {isHover && (
          <div className="hover-edit" style={style} onClick={() => setIsDefaultImagePopupOpen(true)}>
            <img className={"hover-edit-icon"} src={IconReload} alt="" />
            Change
          </div>
        )}
        {!!preview ? <img className="icon-selector-preview-image" src={preview} alt="" /> : "preview"}
      </div>
    </div>
  );
};

export default IconSelector;

type DefaultImagePopupProps = {
  iconTheme: IconTheme;
  images: Array<any>;
  onClickFileRef: Function;
  size: number;
  close: Function;
  setDefaultIcon: Function;
  fileRef: RefObject<HTMLInputElement>;
};

const DefaultImagePopup = ({ iconTheme, images, onClickFileRef, size, close, setDefaultIcon, fileRef }: DefaultImagePopupProps) => {
  const domRef = useRef<HTMLDivElement>(null);
  const [selectedDefaultImage, setSelectedDefaultImage] = useState<number>(0);
  useOuterClick<HTMLDivElement>(domRef, [fileRef], close);

  const onClickDefaultImage = (defaultImage) => {
    setSelectedDefaultImage(defaultImage.projectDefaultImage);
    setDefaultIcon(defaultImage.url);
  };

  return (
    <div ref={domRef} className="default-image-popup" style={{ top: size + 10 }}>
      <div className="default-image-popup-text">We prepared the basic image.</div>
      <div className="default-image-container">
        {images.map((el: any) => (
          <StyledIcon
            loading="lazy"
            style={{ border: el.projectDefaultImage !== selectedDefaultImage ? "2px solid transparent" : "2px solid #5888FF" }}
            onClick={() => onClickDefaultImage(el)}
            size={size}
            src={el.url}
            theme={iconTheme}
            key={`icon-selector-${el.url}`}
            alt={""}
          />
        ))}
      </div>
      <DivideLine style={{ marginBottom: 20 }} />
      <div className="default-image-popup-text">Please upload the prepared image.</div>
      <div className="default-image-popup-upload">
        <BasicButton size={ButtonSize.LARGE} theme={ButtonTheme.SECONDARY} style={{ width: "100%" }} onClick={() => onClickFileRef()}>
          <img style={{ width: 20, objectFit: "contain", marginRight: 10 }} src={UploadIcon} alt="icon " />
          Upload Your File
        </BasicButton>
      </div>
    </div>
  );
};
