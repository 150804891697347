import React, { useEffect } from "react";
import { getEvent, isOurSource, isRightForm } from "./utils/messageUtils";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { setConnectedInfo } from "../../store/redux/EQconnect/EQConnectSlice";
import { setRawTransactionResult } from "../../store/redux/transaction/transactionSlice";

const ExtensionListener = () => {
  const dispatch = useDispatch();

  const messageEventHandler = async (event) => {
    if (
      !isOurSource(event) ||
      !isRightForm(event) ||
      !event.data.event.includes("Response")
    )
      return;
    switch (getEvent(event)) {
      case "getAddressResponse":
        if (event.data.payload.success) {
          sessionStorage.setItem("address", event.data.payload.data.address);
          sessionStorage.setItem("type", "extension");
          dispatch(
            setConnectedInfo({
              address: event.data.payload.data.address,
              type: "extension",
            })
          );
        } else {
          console.warn("login failed");
          toast.error("Failed to Connect");
        }
        break;
      case "makeTransactionResponse":
        if (event.data.payload.success) {
          toast.success("Request transaction success...");
          dispatch(
            setRawTransactionResult({
              tx_hash: event.data.payload.data,
            })
          );
        } else console.warn("send transaction failed");
        break;
      default:
        console.warn("unexpected message", getEvent(event));
        break;
    }
  };

  useEffect(() => {
    window.addEventListener("message", messageEventHandler);
    return () => {
      window.removeEventListener("message", messageEventHandler);
    };
  }, []);
  return <></>;
};

export default ExtensionListener;
