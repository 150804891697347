import React from 'react';
import './KitTab.scss';

const KitTab = ({ tabs, tabIndex, setTabIndex }: { tabs: { label: string; index: number }[]; tabIndex: number; setTabIndex: (index: number) => void }) => {
  return (
    <div className="kit-tab">
      {tabs.map((tab) => (
        <div className={`kit-tab-item ${tabIndex === tab.index ? 'active' : ''}`} key={tab.index} onClick={() => setTabIndex(tab.index)}>
          {tab.label}
        </div>
      ))}
    </div>
  );
};

export default KitTab;
