import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./ContractVerticalCard.scss";
import { selectAllSolution, selectAllStandard } from "reducer/presetReducer";
import rocket from '../../../../assets/images/icon_rocket.png'
import {useEffect} from "react";

const ContractVerticalCard = ({ contractInfo, version }) => {
  const navigate = useNavigate();
  const solutions = useSelector(selectAllSolution);
  const standards = useSelector(selectAllStandard);

  const onClickVerticalCard = async (contractId, versionId) => {
    navigate(`/developer/contract/library/${Number(contractId)}/version/${Number(versionId)}`, {
      state: version,
    });
  };

  return (
    <div
      className="vertical-card-container"
      onClick={() =>
        onClickVerticalCard(Number(contractInfo.contract_id), Number(contractInfo.latest_contract_version_id))
      }
    >
      <div className="vertical-card">
        {contractInfo && (
            <div className="vertical-card-img-contract-box">
              <div className="vertical-card-img-contract-types">
                {contractInfo.contractTags?.map((tag, index) => {
                  const correspondSolution = solutions?.find((sol) => sol.tag_id === tag.tag_id);
                  return (
                      <div key={`solution-${index}`} className="vertical-card-img-contract-type">
                        {correspondSolution?.name}
                      </div>
                  );
                })}
              </div>
              <div className="vertical-card-img-contract-types">
                {contractInfo.contractCategories?.map((ctg, index) => {
                  const correspondCategory = standards?.find((stand) => stand.category_id === ctg.category_id);
                  return (
                      <div key={`category-${index}`} className="vertical-card-img-contract-type category">
                        {correspondCategory?.name}
                      </div>
                  );
                })}
              </div>
            </div>
        )}
        <div className="vertical-card-img-box">

          <img src={contractInfo?.image} alt="" />
        </div>
        <div className="vertical-card-text-box">
          <div className="card-detail">
            <div className="owner-name">{contractInfo?.name}</div>
            <div className="card-detail-bar"></div>
            <div className="deploy-count">
              <img className="deploy-icon" src={rocket} alt="" />
              <div className="deploy-number">{contractInfo?.deploy_count}</div>
            </div>
          </div>
          <div className="card-title">{contractInfo?.title}</div>
          <div className="card-description">{contractInfo?.description}</div>
        </div>
      </div>
    </div>
  );
};

export default ContractVerticalCard;
