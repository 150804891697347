import React, { useEffect, useMemo, useState } from "react";
import NetworkNodeOptionField from "../molecule/NetworkNodeOptionField";
import ButtonContainer from "view/components/_atom/container/ButtonContainer";
import BasicButton from "view/components/_atom/button/BasicButton";
import { ButtonSize, ButtonTheme } from "view/components/_atom/button/StyledButton";
import NetworkEquilibriumNodeAllocField from "../molecule/NetworkEquilibriumNodeAllocField";
import NetworkNodeOperationField from "../molecule/NetworkNodeOperationField";
import useLoading from "hooks/useLoading";
import PageLoading from "view/components/_atom/loading/PageLoading";
import useGetSelectedProject from "hooks/useGetSelectedProject";
import { getNetworkPlanListAction } from "action/networkAction";
import { calculateNodeAllocBalanceByDistributionPercent } from "../../constants/network.utils";
import { removeComma } from "utils/Utils";
import useValidateCondition from "hooks/useValidateCondition";
import useIsAble from "hooks/useIsAble";
import {
  VALIDATION__NETWORK_disinflation_rate,
  VALIDATION__NETWORK_fee,
  VALIDATION__NETWORK_inflation_rate,
  VALIDATION__NETWORK_keystore_file,
  VALIDATION__NETWORK_node_staking_amount,
  VALIDATION__NETWORK_plan,
} from "../../validation/networkValidation";
import { distinguishValidation } from "modules/validation/validationCondition";
import toast from "react-hot-toast";

const NetworkNodeEquilibriumSetting = (props) => {
  const { setCurrentStep, networkData, setNetworkData } = props;

  const { selectedProjectID } = useGetSelectedProject();

  const [networkPlanList, setNetworkPlanList] = useState([]);

  const getNetworkPlanList_ = async () => {
    const { result, error } = await getNetworkPlanListAction({ isPrivate: "false" });

    if (result) {
      setNetworkPlanList(result);
    }
    if (error) {
    }
  };

  const setNodeAlloc = (nodeAlloc: Array<any>) => {
    setNetworkData((prevState) => ({
      ...prevState,
      nodeAlloc,
    }));
  };

  const allocModifyWhenInitialSupplyChange = (initialSupply: string, nodeAlloc: Array<any>) => {
    const result = calculateNodeAllocBalanceByDistributionPercent(initialSupply, nodeAlloc);
    setNodeAlloc(result as Array<any>);
  };

  useEffect(() => {
    if (networkData.microChainSetting.initialSupply) {
      allocModifyWhenInitialSupplyChange(removeComma(networkData.microChainSetting.initialSupply), networkData.nodeAlloc);
    }
  }, [networkData.microChainSetting.initialSupply]);

  // ----------------- VALIDATION -----------------

  const networkPlanValidation = useValidateCondition([networkData.network.networkPlanId], VALIDATION__NETWORK_plan(networkData.network.networkPlanId));
  const keystoreFileValidation = useValidateCondition([networkData.nodeAlloc], VALIDATION__NETWORK_keystore_file(networkData.nodeAlloc));
  const stakingAmountValidation = useValidateCondition([networkData.nodeAlloc], VALIDATION__NETWORK_node_staking_amount(networkData.nodeAlloc));

  const feeValidation = useValidateCondition(
    [networkData.microChainSetting.fee, networkData.microChainSetting.initialSupply],
    VALIDATION__NETWORK_fee(networkData.microChainSetting.fee, networkData.microChainSetting.initialSupply)
  );
  const inflationRateValidation = useValidateCondition(
    [networkData.microChainSetting.inflationRate],
    VALIDATION__NETWORK_inflation_rate(networkData.microChainSetting.inflationRate)
  );
  const disinflationRateValidation = useValidateCondition(
    [networkData.microChainSetting.disInflationRate],
    VALIDATION__NETWORK_disinflation_rate(networkData.microChainSetting.disInflationRate)
  );

  const nodeSettingValidationResult = useMemo(() => {
    return distinguishValidation([keystoreFileValidation, stakingAmountValidation]);
  }, [keystoreFileValidation, stakingAmountValidation]);

  const isAble: boolean = useIsAble([
    networkPlanValidation.isValid,
    feeValidation.isValid,
    inflationRateValidation.isValid,
    disinflationRateValidation.isValid,
  ]);

  // ------------------------------------------------

  const backButtonHandler = () => {
    setCurrentStep((prevState) => prevState - 1);
  };

  const nextButtonHandler = () => {
    if (!nodeSettingValidationResult.isValid) {
      toast.error(nodeSettingValidationResult.message);
      return;
    }
    setCurrentStep((prevState) => prevState + 1);
  };

  const { loading } = useLoading({
    dependency: [selectedProjectID],
    synchronousFunction: async () => {
      await getNetworkPlanList_();
    },
  });

  if (loading) {
    return <PageLoading />;
  }

  return (
    <div>
      <NetworkNodeOptionField networkPlanList={networkPlanList} networkData={networkData} setNetworkData={setNetworkData} />
      <NetworkEquilibriumNodeAllocField
        networkData={networkData}
        setNetworkData={setNetworkData}
        nodeAllocatedAmount={networkData.microChainSetting.initialSupply}
      />
      <NetworkNodeOperationField networkData={networkData} setNetworkData={setNetworkData} />
      <div className="network-button-container">
        <ButtonContainer>
          <BasicButton size={ButtonSize.LARGE} theme={ButtonTheme.SECONDARY} onClick={() => backButtonHandler()}>
            Back
          </BasicButton>
          <BasicButton size={ButtonSize.LARGE} disabled={!isAble} onClick={() => nextButtonHandler()}>
            Next
          </BasicButton>
        </ButtonContainer>
      </div>
    </div>
  );
};

export default NetworkNodeEquilibriumSetting;
