import { useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import ProgressState from "../../../../components/_molecules/progressState/ProgressState";
import { addComma, errorAlert } from "../../../../../utils/Utils";
import { NUMBER_REG_EXP } from "../../token/utils/tokenUtils";
import {
  AIRDROP_DATA_FORMAT,
  AIRDROP_DISTRIBUTION_AMOUNT_FORMAT,
  AIRDROP_PARTICIPANT_EVENT_FORMAT,
  AIRDROP_PARTICIPANT_LIMIT_FORMAT,
  AIRDROP_STEP_LIST,
} from "../constants/airdropConstants";
import AirdropBasicInfoSetting from "../component/organism/AirdropBasicInfoSetting";
import AirdropFixedBudgetSetting from "../component/organism/AirdropFixedBudgetSetting";
import AirdropOperatingWaySetting from "../component/organism/AirdropOperatingWaySetting";
import AirdropProjectPurposeSetting from "../component/organism/AirdropProjectPurposeSetting";
import { getAirdropDetailForUpdateAirdropAction, getAirdropPresetAction, updateAirdropAction } from "action/airdropAction";
import PageArticle from "view/components/_template/page/PageArticle";
import PageTitle from "view/components/_molecules/page/PageTitle";
import PageLoading from "view/components/_atom/loading/PageLoading";
import DivideLine from "view/components/_atom/line/DivideLine";
import useLoading from "hooks/useLoading";

const EditAirdrop = () => {
  const navigate = useNavigate();
  const params = useParams();

  const airdropId = useMemo(() => {
    return params.id;
  }, [params]);

  const [currentStep, setCurrentStep] = useState(0);
  const [airdropData, setAirdropData] = useState(AIRDROP_DATA_FORMAT);

  const [participationPeriodTypeList, setparticipationPeriodTypeList] = useState([]);
  const [distributionTypeList, setDistributionTypeList] = useState([]);
  const [distributionAmountTypeList, setDistributionAmountTypeList] = useState([]);
  const [participantConditionTypeList, setParticipantConditionTypeList] = useState([]);

  const [marketingChannelList, setMarketingChannelList] = useState([]);

  const [distributionAmountsData, setDistributionAmountsData] = useState([AIRDROP_DISTRIBUTION_AMOUNT_FORMAT]);
  const [participationLimitData, setParticipationLimitData] = useState(AIRDROP_PARTICIPANT_LIMIT_FORMAT);
  const [participationEventsData, setParticipationEventsData] = useState([AIRDROP_PARTICIPANT_EVENT_FORMAT]);

  const setData = (value, key, type) => {
    let parseValue = value;

    // 값이 빈값이면 null로 세팅
    if (typeof value === "string" && value === "") {
      setAirdropData((prevState) => {
        return { ...prevState, [key]: null };
      });
    }

    if (type === "number") {
      if (!NUMBER_REG_EXP.test(value)) {
        return;
      }
      parseValue = addComma(value);
    }

    setAirdropData((prevState) => {
      return { ...prevState, [key]: parseValue };
    });
  };

  const getInitialData_ = async () => {
    const { result, error } = await getAirdropPresetAction();
    if (result) {
      const { participationPeriodTypes, distributionTypes, distributionAmountTypes, participationConditionTypes } = result || {};
      setparticipationPeriodTypeList(participationPeriodTypes);
      setDistributionTypeList(distributionTypes);
      setDistributionAmountTypeList(distributionAmountTypes);
      setParticipantConditionTypeList(participationConditionTypes);
    }
    if (error) {
      errorAlert(error.data.message);
    }
  };

  const getAirdropData_ = async () => {
    const { result, error } = await getAirdropDetailForUpdateAirdropAction(airdropId);
    if (result) {
      const { data, distributionAmountData, participationConditionLimitData, participationConditionEventsData } = result || {};
      setAirdropData(data);
      setMarketingChannelList(data.marketingChannels);
      setDistributionAmountsData(distributionAmountData);
      setParticipationLimitData(participationConditionLimitData ?? AIRDROP_PARTICIPANT_LIMIT_FORMAT);
      setParticipationEventsData(participationConditionEventsData ?? [AIRDROP_PARTICIPANT_EVENT_FORMAT]);
    }
    if (error) {
      errorAlert(error.data.message);
    }
  };

  const onClickBackButton = () => {
    setCurrentStep((prevState) => prevState - 1);
  };

  // 분배 금액 초기화
  const initializeDistributionAmounts = () => {
    setDistributionAmountsData([AIRDROP_DISTRIBUTION_AMOUNT_FORMAT]);
  };

  // 진행 기간 초기화
  const initializeOperationPeriod = () => {
    setAirdropData((prevState) => {
      return { ...prevState, participationEndDate: null, participationStartDate: null };
    });
  };

  const onClickTypeButton = (el) => {
    if (el.disabled) {
      return;
    }

    setData(el.distributionAmountTypeId, "distributionAmountTypeId");
    setData(el.participationPeriodTypeId, "participationPeriodTypeId");
    setData(el.distributionTypeId, "distributionTypeId");
    setData(el.participationConditionTypeId, "participationConditionTypeId");
    setData(el.operationPurpose, "operationPurpose");
    initializeDistributionAmounts();
    initializeOperationPeriod();
  };

  const updateAirdrop_ = async () => {
    let formData = new FormData();
    if (typeof airdropData.projectImage !== "string") {
      formData.append("projectImage", airdropData.projectImage);
    }
    const { result, error } = await updateAirdropAction(
      airdropId,
      formData,
      airdropData,
      distributionAmountsData,
      participationLimitData,
      participationEventsData
    );
    if (result) {
      toast.success("processed successfully");
      navigate(`/application/airdrop/manage/${airdropId}`);
    }
    if (error) {
      errorAlert(error.data.message);
    }
  };

  const { loading } = useLoading({
    dependency: [airdropId],
    synchronousFunction: async () => {
      await getInitialData_();
      await getAirdropData_();
    },
  });

  if (loading) {
    return <PageLoading />;
  }
  return (
    <PageArticle scrollDependency={currentStep}>
      <PageTitle title={"Edit Airdrop"} size={810} />
      <div className="page-layout-810">
        <ProgressState step={currentStep} stepList={AIRDROP_STEP_LIST} size={730} />
        <DivideLine style={{ margin: "40px 0" }} />
        {currentStep === 0 && <AirdropProjectPurposeSetting airdropData={airdropData} setCurrentStep={setCurrentStep} onClickTypeButton={onClickTypeButton} />}
        {currentStep === 1 && (
          <AirdropBasicInfoSetting
            onClickBackButton={onClickBackButton}
            airdropData={airdropData}
            setAirdropData={setData}
            setCurrentStep={setCurrentStep}
            marketingChannelList={marketingChannelList}
            setMarketingChannelList={setMarketingChannelList}
          />
        )}
        {currentStep === 2 && (
          <AirdropFixedBudgetSetting onClickBackButton={onClickBackButton} airdropData={airdropData} setAirdropData={setData} setCurrentStep={setCurrentStep} />
        )}
        {currentStep === 3 && (
          <AirdropOperatingWaySetting
            onClickBackButton={onClickBackButton}
            onClickSaveButton={updateAirdrop_}
            airdropData={airdropData}
            setAirdropData={setData}
            setCurrentStep={setCurrentStep}
            participationPeriodTypeList={participationPeriodTypeList}
            distributionTypeList={distributionTypeList}
            distributionAmountTypeList={distributionAmountTypeList}
            participantConditionTypeList={participantConditionTypeList}
            distributionAmountsData={distributionAmountsData}
            setDistributionAmountsData={setDistributionAmountsData}
            participationLimitData={participationLimitData}
            setParticipationLimitData={setParticipationLimitData}
            participationEventsData={participationEventsData}
            setParticipationEventsData={setParticipationEventsData}
          />
        )}
      </div>
    </PageArticle>
  );
};

export default EditAirdrop;
