import {CSSProperties} from "react";
import IconWarning from '../../../assets/images/atom/Icon_warning_black.png';
import './Warning.scss'

type WarningProps = {
  title: string;
  description: string;
  icon?: string;
  style?: CSSProperties;
};

const Warning = (
  {
    title,
    description,
    icon,
    style
  }: WarningProps) => {
  return (
    <div className='warning' style={style && style}>
      <div className="warning-header">
        <img src={icon ?? IconWarning} alt="icon"/>
        <span className='warning-title'>{title}</span>
      </div>
      <span className='warning-description'>{description}</span>
    </div>
  );
};

export default Warning;
