import { DashboardIntervalOptionType, PageToggleType, SectionTabType } from "./dashboard.type";
import { FromTick, ProjectRequestsTab } from "server/type/projectType";

export const DASHBOARD_PAGE_TOGGLE: Array<PageToggleType> = [
  {
    id: "DASHBOARD",
    label: "Dashboard",
    accessor: 1,
  },
  {
    id: "SETTING",
    label: "Setting",
    accessor: 2,
  },
];

export const DASHBOARD_SECTION_TAB: Array<SectionTabType> = [
  {
    id: 1,
    label: "API",
    accessor: 1,
  },
  {
    id: 2,
    label: "Network",
    accessor: 2,
  },
  {
    id: 3,
    label: "Smart Contract",
    accessor: 3,
  },
];

export const DASHBOARD_SECTION_API_ID = 1;
export const DASHBOARD_SECTION_NETWORK_ID = 2;
export const DASHBOARD_SECTION_SMART_CONTRACT_ID = 3;

export const DASHBOARD_PROJECT_REQUESTS_TYPE_LIST = [
  { id: ProjectRequestsTab.COUNT, label: "API Call" },
  { id: ProjectRequestsTab.CREDIT, label: "EQ Credit" },
];

export const SETTING_SECTION_TAB: Array<SectionTabType> = [
  {
    id: 1,
    label: "Info",
    accessor: 1,
  },
  {
    id: 2,
    label: "Member",
    accessor: 2,
  },
  {
    id: 3,
    label: "Plan",
    accessor: 1,
  },
  {
    id: 4,
    label: "Billing",
    accessor: 1,
  },
];

export const CHART_INTERVAL_OPTIONS: Array<DashboardIntervalOptionType> = [
  {
    id: 1,
    value: FromTick.DAY,
    label: "Last 24 hours",
  },
  {
    id: 2,
    value: FromTick.WEEK,
    label: "Last 7 days",
  },
  {
    id: 3,
    value: FromTick.MONTH,
    label: "Last 30 days",
  },
];

export const DASHBOARD_MOST_USED_REQUESTS_COLOR = ["#5D9D52", "#E79848", "#8331A4"];

export const DASHBOARD_CHART_INTERVAL_COUNT = 30;

export const DASHBOARD_NETWORK_UTILIZATION_COMPARISON_COLOR = [
  "#3860E2",
  "#48A0FF",
  "#C7E2FF",
  "#6683E0",
  "#7EB6F2",
  "#4785C7",
  "#C7D6FF",
  "#8F76D4",
  "#513895",
  "#1F489B",
];

export const EMPTY_CHART_TEXT =
  "There is no data to display within the selected period.\nWhen data is continuously generated, you can see neatly organized contents!";
