import { makeQuery } from "../index/utils";
import { v2ServerByProjectAuth } from "../index/serverInstances";
import { NodeSpecFromServer } from "../type/nodeType";
import {
  AlgorithmChainClientVersionFromServer,
  AlgorithmFromServer,
  ChainClientFromServer,
  ChainClientVersionFromServer,
  NetworkPlanFromServer,
  ProtocolChainClientFromServer,
  ProtocolFromServer,
} from "server/type/networkType";

const getNetworkNodeSpecList = async (): Promise<getNetworkNodeSpecListResponse> =>
  await v2ServerByProjectAuth.get({
    path: `networks/spec-types`,
  });

type getNetworkNodeSpecListResponse = Array<NodeSpecFromServer>;
//---------------------------------------------------------------------------------------

const getNetworkPlanList = async (request: getNetworkPlanListRequest): Promise<getNetworkPlanListResponse> =>
  await v2ServerByProjectAuth.get({
    path: `network-plans`,
    query: makeQuery(request.query),
  });

type getNetworkPlanListRequest = {
  query: {
    isPrivate?: boolean;
  };
};

type getNetworkPlanListResponse = Array<NetworkPlanFromServer>;
//---------------------------------------------------------------------------------------

const getNetworkPlan = async (request: getNetworkPlanRequest): Promise<getNetworkPlanResponse> =>
  await v2ServerByProjectAuth.get({
    path: `network-plans/${request.params.networkPlanId}`,
  });

type getNetworkPlanRequest = {
  params: {
    networkPlanId: number;
  };
};

type getNetworkPlanResponse = NetworkPlanFromServer;
//---------------------------------------------------------------------------------------

const getProtocolList = async (): Promise<getProtocolListResponse> =>
  await v2ServerByProjectAuth.get({
    path: `protocols`,
  });

type getProtocolListResponse = Array<ProtocolFromServer>;
//---------------------------------------------------------------------------------------

const getProtocolChainClientList = async (request: getProtocolChainClientListRequest): Promise<getProtocolChainClientListResponse> =>
  await v2ServerByProjectAuth.get({
    path: `protocols/${request.params.protocolId}/chain-clients`,
  });

type getProtocolChainClientListRequest = {
  params: {
    protocolId: number;
  };
};

type getProtocolChainClientListResponse = Array<ProtocolChainClientFromServer>;
//---------------------------------------------------------------------------------------

const getChainClientList = async (): Promise<getChainClientListResponse> =>
  await v2ServerByProjectAuth.get({
    path: `chain-clients`,
  });

type getChainClientListResponse = Array<ChainClientFromServer>;
//---------------------------------------------------------------------------------------

const getChainClientVersion = async (request: getChainClientVersionRequest): Promise<getChainClientVersionResponse> =>
  await v2ServerByProjectAuth.get({
    path: `chain-client-versions/${request.params.chainClientVersionId}`,
  });

type getChainClientVersionRequest = {
  params: {
    chainClientVersionId: number;
  };
};

type getChainClientVersionResponse = ChainClientVersionFromServer;
//---------------------------------------------------------------------------------------

const getChainClientVersionList = async (request: getChainClientVersionListRequest): Promise<getChainClientVersionListResponse> =>
  await v2ServerByProjectAuth.get({
    path: `chain-client-versions`,
    query: makeQuery(request.query),
  });

type getChainClientVersionListRequest = {
  query: {
    chainClientId: number;
  };
};

type getChainClientVersionListResponse = Array<ChainClientVersionFromServer>;
//---------------------------------------------------------------------------------------

const getAlgorithmChainClientVersionList = async (request: getAlgorithmChainClientVersionListRequest): Promise<getAlgorithmChainClientVersionListResponse> =>
  await v2ServerByProjectAuth.get({
    path: `chain-client-versions/${request.params.chainClientVersionId}/algorithms`,
  });

type getAlgorithmChainClientVersionListRequest = {
  params: {
    chainClientVersionId: number;
  };
};

type getAlgorithmChainClientVersionListResponse = Array<AlgorithmChainClientVersionFromServer>;
//---------------------------------------------------------------------------------------

const getAlgorithmList = async (): Promise<getAlgorithmListResponse> =>
  await v2ServerByProjectAuth.get({
    path: `algorithms`,
  });

type getAlgorithmListResponse = Array<AlgorithmFromServer>;
//---------------------------------------------------------------------------------------

const getNetworkList = async (request: getNetworkListRequest): Promise<getNetworkListResponse> =>
  await v2ServerByProjectAuth.get({
    path: `networks`,
    query: makeQuery(request.query),
  });

type getNetworkListRequest = {
  query: {
    isInternalUse?: boolean;
    networkIds?: Array<number>;
    projectId?: number;
    creatorId?: number;
    mainStatus?: Array<number>;
    external?: boolean;
    subStatus?: number;
  };
};

type getNetworkListResponse = any;
//---------------------------------------------------------------------------------------

const createNetwork = async (request: CreateNetworkRequest): Promise<CreateNetworkResponse> =>
  await v2ServerByProjectAuth.post({
    path: `networks`,
    data: request.data,
  });

type CreateNetworkRequest = {
  data: {
    network: any;
    microChain: any;
    microChainSetting: object;
    alloc: [];
    currency: object;
  };
};

type CreateNetworkResponse = any;
//---------------------------------------------------------------------------------------

const getNetworkDetail = async (request: getNetworkDetailRequest): Promise<getNetworkDetailResponse> =>
  await v2ServerByProjectAuth.get({
    path: `networks/${request.params.networkId}`,
    query: makeQuery(request.query),
  });
type getNetworkDetailRequest = {
  params: {
    networkId: number;
  };
  query: {
    isInternalUse?: boolean;
    paranoid?: boolean;
  };
};

export type getNetworkDetailResponse = any;
//---------------------------------------------------------------------------------------

const getNetworkAlloc = async (request: getNetworkAllocRequest): Promise<getNetworkAllocResponse> =>
  await v2ServerByProjectAuth.get({
    path: `networks/${request.params.networkId}/alloc`,
  });
type getNetworkAllocRequest = {
  params: {
    networkId: number;
  };
};

export type getNetworkAllocResponse = any;
//---------------------------------------------------------------------------------------

const getMicrochainSetting = async (request: getMicrochainSettingRequest): Promise<getMicrochainSettingResponse> =>
  await v2ServerByProjectAuth.get({
    path: `networks/${request.params.networkId}/micro-chain-setting`,
  });
type getMicrochainSettingRequest = {
  params: {
    networkId: number;
  };
};

export type getMicrochainSettingResponse = any;
//---------------------------------------------------------------------------------------
// TODO :: 삭제 예정
const getCurrencyDetail = async (request: getCurrencyDetailRequest): Promise<getCurrencyDetailResponse> =>
  await v2ServerByProjectAuth.get({
    path: `micro-chain-currency/coin`,
    query: makeQuery(request.query),
  });
type getCurrencyDetailRequest = {
  query: {
    microChainId: number;
  };
};

export type getCurrencyDetailResponse = any;
//---------------------------------------------------------------------------------------

const createCurrencyImage = async (request: CreateCurrencyImageRequest): Promise<CreateCurrencyImageResponse> =>
  await v2ServerByProjectAuth.post({
    path: `currency/upload-image`,
    query: makeQuery(request.query),
    data: request.data,
    config: {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
  });

type CreateCurrencyImageRequest = {
  query: {
    fileCount: number;
  };
  data: FormData;
};

type CreateCurrencyImageResponse = any;
//---------------------------------------------------------------------------------------

const updateNetwork = async (request: UpdateNetworkRequest): Promise<UpdateNetworkResponse> =>
  await v2ServerByProjectAuth.put({
    path: `networks/${request.params.networkId}`,
    data: request.data,
  });

type UpdateNetworkRequest = {
  params: {
    networkId: number;
  };
  data: {
    network: any;
    microChain: any;
    microChainSetting: object;
    alloc: [];
    currency: object;
  };
};

type UpdateNetworkResponse = any;
//---------------------------------------------------------------------------------------

const submitNetwork = async (request: submitNetworkRequest): Promise<submitNetworkResponse> =>
  await v2ServerByProjectAuth.post({
    path: `networks/${request.params.networkId}/submit`,
  });

type submitNetworkRequest = {
  params: {
    networkId: number;
  };
};

type submitNetworkResponse = any;
//---------------------------------------------------------------------------------------

const confirmNetwork = async (request: confirmNetworkRequest): Promise<confirmNetworkResponse> =>
  await v2ServerByProjectAuth.post({
    path: `networks/${request.params.networkId}/confirm`,
  });

type confirmNetworkRequest = {
  params: {
    networkId: number;
  };
};

type confirmNetworkResponse = any;
//---------------------------------------------------------------------------------------

const rejectNetwork = async (request: rejectNetworkRequest): Promise<rejectNetworkResponse> =>
  await v2ServerByProjectAuth.post({
    path: `networks/${request.params.networkId}/reject`,
    data: request.data,
  });

type rejectNetworkRequest = {
  params: {
    networkId: number;
  };
  data: {
    rejectReason: string;
  };
};

type rejectNetworkResponse = any;
//---------------------------------------------------------------------------------------

const getNetworkRejectReason = async (request: getNetworkRejectReasonRequest): Promise<getNetworkRejectReasonResponse> =>
  await v2ServerByProjectAuth.get({
    path: `networks/${request.params.networkId}/reject`,
  });

type getNetworkRejectReasonRequest = {
  params: {
    networkId: number;
  };
};

type getNetworkRejectReasonResponse = any;
//---------------------------------------------------------------------------------------

const releaseNetwork = async (request: releaseNetworkRequest): Promise<releaseNetworkResponse> =>
  await v2ServerByProjectAuth.post({
    path: `networks/${request.params.networkId}/release`,
  });

type releaseNetworkRequest = {
  params: {
    networkId: number;
  };
};

type releaseNetworkResponse = any;
//---------------------------------------------------------------------------------------

const deleteNetwork = async (request: deleteNetworkRequest): Promise<deleteNetworkResponse> =>
  await v2ServerByProjectAuth.delete({
    path: `networks/${request.params.networkId}`,
  });

type deleteNetworkRequest = {
  params: {
    networkId: number;
  };
};

type deleteNetworkResponse = any;
//---------------------------------------------------------------------------------------

const getNetworkVPN = async (request: getNetworkVPNRequest): Promise<getNetworkVPNResponse> =>
  await v2ServerByProjectAuth.post({
    path: `networks/${request.params.networkId}/vpn`,
  });

type getNetworkVPNRequest = {
  params: {
    networkId: number;
  };
};

type getNetworkVPNResponse = { url: string };
//---------------------------------------------------------------------------------------

export {
  getNetworkNodeSpecList as getNetworkNodeSpecListFromServer,
  getNetworkPlanList as getNetworkPlanListFromServer,
  getNetworkPlan as getNetworkPlanFromServer,
  getProtocolList as getProtocolListFromServer,
  getProtocolChainClientList as getProtocolChainClientListFromServer,
  getChainClientList as getChainClientListFromServer,
  getChainClientVersion as getChainClientVersionFromServer,
  getChainClientVersionList as getChainClientVersionListFromServer,
  getAlgorithmChainClientVersionList as getAlgorithmChainClientVersionListFromServer,
  getAlgorithmList as getAlgorithmListFromServer,
  getNetworkList as getNetworkListFromServer,
  createNetwork as createNetworkFromServer,
  getNetworkDetail as getNetworkDetailFromServer,
  getNetworkAlloc as getNetworkAllocFromServer,
  getMicrochainSetting as getMicrochainSettingFromServer,
  createCurrencyImage as createCurrencyImageFromServer,
  getCurrencyDetail as getCurrencyDetailFromServer,
  updateNetwork as updateNetworkFromServer,
  submitNetwork as submitNetworkFromServer,
  confirmNetwork as confirmNetworkFromServer,
  rejectNetwork as rejectNetworkFromServer,
  getNetworkRejectReason as getNetworkRejectReasonFromServer,
  releaseNetwork as releaseNetworkFromServer,
  deleteNetwork as deleteNetworkFromServer,
  getNetworkVPN as getNetworkVPNFromServer,
};
