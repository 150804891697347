import { DesiredNetwork } from "logic/services/Request/server/index.type";
import { actionController } from "../utils";
import { MicroChainCurrencyType } from "reducer/clientType/tokenClientType";
import { storeState } from "reducer/index";
import { connectInfo } from "store/redux/EQconnect/EQConnectSlice";
import { project_access } from "reducer/projectReducer";
import {
  getAbiCodeByContractAddressParser,
  getLoadBalancerParser,
  makeTransactionParser,
} from "parser/requestParser";
import { removeComma } from "utils/Utils";
import { WEB3 } from "modules/web3/Web3";
import { encodeABI } from "modules/web3/Web3Utils";
import { makeQuery } from "server/index/utils";
import Web3 from "web3";

export const requestController = {
  setMetaMaskNetwork: async (
    desiredNetwork: DesiredNetwork,
    currentChainId: string
  ) => {
    return actionController(async () => {
      if (!window.ethereum) {
        console.error("MetaMask가 설치되어 있지 않습니다.");
        return false;
      }

      try {
        // 현재 네트워크가 원하는 네트워크인지 확인
        if (currentChainId === desiredNetwork.chainId) {
          return true;
        }

        // 네트워크 변경 요청
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: desiredNetwork.chainId }],
        });
        return true;
      } catch (error) {
        // 네트워크가 MetaMask에 추가되지 않은 경우
        if (error.code === 4902) {
          try {
            // 네트워크 추가 요청
            await window.ethereum.request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  chainId: desiredNetwork.chainId,
                  chainName: desiredNetwork.name,
                  rpcUrls: desiredNetwork.rpcUrls,
                  ...(desiredNetwork.nativeCurrency && {
                    nativeCurrency: desiredNetwork.nativeCurrency,
                  }),
                  ...(desiredNetwork.blockExplorerUrls && {
                    blockExplorerUrls: desiredNetwork.blockExplorerUrls,
                  }),
                },
              ],
            });
            return true;
          } catch (error) {
            throw new Error("metamask_add_network_fail");
          }
        } else {
          throw new Error("metamask_change_network_fail");
        }
      }
    });
  },

  sendTokenTransactionWithMetamask: async (
    transaction: Function,
    callback: Function
  ) => {
    return await actionController(async () => {
      const web3 = new Web3(window.ethereum);
      try {
        const { result, error } = await transaction();
        if (error) {
          throw new Error("tx_make_fail");
        }
        // MetaMask에서 사용할 계정 가져오기
        const accounts = await web3.eth.getAccounts();
        const account = accounts[0];
        const gasPrice = await web3.eth.getGasPrice();
        // 트랜잭션 파라미터 설정
        const txParams = {
          from: account, // 현재 MetaMask 연결된 계정
          to: result.to, // 민트할 컨트랙트 주소
          data: result.data, // 인코딩된 Transfer 함수 데이터
          value: result.value || "0x0", // 트랜잭션 값 (없으면 0)
          gasPrice: gasPrice, // gasPrice 설정
        };
        // 트랜잭션을 MetaMask를 통해 전송
        const txHash = await window.ethereum.request({
          method: "eth_sendTransaction",
          params: [txParams],
        });
        callback(txHash);
        return true;
      } catch (error) {
        throw new Error("tx_send_fail");
      }
    });
  },

  //-----approve-----//
  makeApproveTransactionWithMetamask: async (
    currencyId: number,
    spender: string,
    amount: string,
    originMicroChainId: number,
    originMicroChainCurrency: MicroChainCurrencyType
  ) => {
    return actionController(async () => {
      const connectInfoResult = connectInfo(storeState());
      const token = project_access(storeState());

      if (currencyId) {
        const abiCode = await getAbiCodeByContractAddressParser(
          originMicroChainId,
          originMicroChainCurrency.contractAddress
        );
        const parameters = [spender, WEB3.toWei(removeComma(amount))];
        const data = encodeABI(abiCode, "approve", parameters);

        const baseURL = await getLoadBalancerParser(originMicroChainId);
        const transactionPath = `${process.env.REACT_APP_HUB_SERVER_URL}${
          process.env.REACT_APP_API_VERSION_V2
        }wallet-manage/erc20/approve${makeQuery({
          microChainId: originMicroChainId,
        })}`;

        const additionalQuery = {
          body: {
            erc20Id: currencyId,
          },
        };

        const transaction = makeTransactionParser({
          address: connectInfoResult.address,
          microChainId: originMicroChainId,
          to: originMicroChainCurrency.contractAddress,
          value: "0x0",
          functionName: "approve",
          baseURL,
          transactionPath,
          token,
          additionalQuery,
        });
        return {
          ...transaction,
          data,
        };
      } else {
        throw new Error("tx_make_fail");
      }
    });
  },

  addERC20TokenToMetamask: async (
    tokenAddress: string,
    tokenSymbol: string,
    tokenDecimals?: number
  ) => {
    return actionController(async () => {
      try {
        const wasAdded = await window.ethereum.request({
          method: "wallet_watchAsset",
          params: {
            type: "ERC20",
            options: {
              address: tokenAddress, // 배포된 토큰의 계약 주소
              symbol: tokenSymbol, // 토큰 심볼 (예: "USDT")
              decimals: tokenDecimals || 18, // 토큰 소수점 자리 수
            },
          },
        });
        return wasAdded;
      } catch {
        throw new Error("token_add_fail");
      }
    });
  },
};
