import DivideLine from "view/components/_atom/line/DivideLine";
import TableStatusLabel from "../label/TableStatusLabel";
import "./ListTypeBCard.scss";

const ListTypeBCard = ({ dataObject, imageKey, onClick, renderIndex, renderer, title, hasStatus = true, containerStyle = null }) => {
  return (
    <div className="list-type-b-card" onClick={() => onClick(dataObject)}>
      <div className="list-card-image-container" style={containerStyle && containerStyle}>
        <img className="list-card-image" src={dataObject[imageKey]} alt="" />
      </div>
      <div className="list-card-detail-container">
        <div className="list-card-detail-top-content">
          <div className="list-card-detail-name">{title}</div>
          {hasStatus && (
            <TableStatusLabel
              mainStatus={dataObject.mainStatus ? dataObject.mainStatus : dataObject.main_status}
              customLabelStyle={{ margin: 0 }}
              custom={[{ status: 5, style: 5, text: "Deployed" }]}
            />
          )}
        </div>
        <DivideLine style={{ margin: "10px 0" }} />
        <div className="list-card-detail-bottom-content">
          {renderIndex.map((el, index) => {
            return (
              <div key={index} className="list-card-detail-row">
                <div className="list-card-detail-index">{el.label}</div>
                <div className="list-card-detail-data">{renderer(dataObject)[el.accessor]}</div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ListTypeBCard;
