import {AbiItem, AbiType} from "web3-utils";
import {AbiCodeObject} from "../reducer/clientType/contractClientType";

export const parseAbiCode = (ABIObject: AbiItem[]): AbiCodeObject => {
  const constructor = ABIObject.find(getABIStructByType('constructor'));

  const functions = ABIObject.filter(getABIStructByType('function'));

  const events = ABIObject.filter(getABIStructByType('event'));

  //TODO: fallback과 receive는 사용하게 되면 이 함수에서 리턴하도록 추가하시면 됩니다.
  const fallback = ABIObject.filter(getABIStructByType('fallback'));

  const receive = ABIObject.filter(getABIStructByType('receive'));

  return {
    constructor,
    functions,
    events
  }
}

const getABIStructByType = (type: AbiType) => (ABIObject: AbiItem) => {
  return type === ABIObject.type
}

