export enum GenerateMFAStep {
  INSTALL = 1,
  REGISTER = 2,
  VERIFY = 3,
}

export const GENERATE_MFA_STEP_LIST = [
  {
    step: GenerateMFAStep.INSTALL,
    label: "Install",
  },
  {
    step: GenerateMFAStep.REGISTER,
    label: "Register",
  },
  {
    step: GenerateMFAStep.VERIFY,
    label: "Verify",
  },
];

export const EQHUB_CS_EMAIL = "help_eqhub@eqbr.com";
