import React, { useMemo, useState } from "react";
import { getNetworkPlanListAction } from "action/networkAction";
import useGetSelectedProject from "hooks/useGetSelectedProject";
import useLoading from "hooks/useLoading";
import PageLoading from "view/components/_atom/loading/PageLoading";
import ButtonContainer from "view/components/_atom/container/ButtonContainer";
import BasicButton from "view/components/_atom/button/BasicButton";
import { ButtonSize, ButtonTheme } from "view/components/_atom/button/StyledButton";
import NetworkBesuNodeAllocField from "../molecule/NetworkBesuNodeAllocField";
import NetworkNodeOptionField from "../molecule/NetworkNodeOptionField";
import useValidateCondition from "hooks/useValidateCondition";
import { VALIDATION__NETWORK_keystore_file, VALIDATION__NETWORK_plan } from "../../validation/networkValidation";
import useIsAble from "hooks/useIsAble";
import { distinguishValidation } from "modules/validation/validationCondition";
import toast from "react-hot-toast";

const NetworkNodeBesuSetting = (props) => {
  const { setCurrentStep, networkData, setNetworkData } = props;

  const { selectedProjectID } = useGetSelectedProject();

  const [networkPlanList, setNetworkPlanList] = useState<Array<any>>([]);

  const getNetworkPlanList_ = async () => {
    const { result, error } = await getNetworkPlanListAction({ isPrivate: "false" });

    if (result) {
      setNetworkPlanList(result);
    }
    if (error) {
    }
  };

  // ----------------- VALIDATION -----------------

  const networkPlanValidation = useValidateCondition([networkData.network.networkPlanId], VALIDATION__NETWORK_plan(networkData.network.networkPlanId));
  const keystoreFileValidation = useValidateCondition([networkData.nodeAlloc], VALIDATION__NETWORK_keystore_file(networkData.nodeAlloc));

  const nodeSettingValidationResult = useMemo(() => {
    return distinguishValidation([keystoreFileValidation]);
  }, [keystoreFileValidation]);

  const isAble: boolean = useIsAble([networkPlanValidation.isValid]);

  // ------------------------------------------------

  const backButtonHandler = () => {
    setCurrentStep((prevState) => prevState - 1);
  };

  const nextButtonHandler = () => {
    if (!nodeSettingValidationResult.isValid) {
      toast.error(nodeSettingValidationResult.message);
      return;
    }
    setCurrentStep((prevState) => prevState + 1);
  };

  const { loading } = useLoading({
    dependency: [selectedProjectID],
    synchronousFunction: async () => {
      await getNetworkPlanList_();
    },
  });

  if (loading) {
    return <PageLoading />;
  }

  return (
    <div>
      <NetworkNodeOptionField networkPlanList={networkPlanList} networkData={networkData} setNetworkData={setNetworkData} />
      <NetworkBesuNodeAllocField networkData={networkData} setNetworkData={setNetworkData} />
      <div className="network-button-container">
        <ButtonContainer>
          <BasicButton size={ButtonSize.LARGE} theme={ButtonTheme.SECONDARY} onClick={() => backButtonHandler()}>
            Back
          </BasicButton>
          <BasicButton size={ButtonSize.LARGE} disabled={!isAble} onClick={() => nextButtonHandler()}>
            Next
          </BasicButton>
        </ButtonContainer>
      </div>
    </div>
  );
};

export default NetworkNodeBesuSetting;
