import {
  ApiKeyFromServer,
  ObjectPolicyFromServer,
  PlatformFromServer,
  PolicyFromServer,
  ProjectAPIKeysFromServer,
  WEB3ProviderEndpointsFromServer,
} from "../type/authType";
import { makeQuery } from "../index/utils";
import { v1ServerByProjectAuth } from "../index/serverInstances";

//getPlatforms---------------------------------------------------------------------------------------
const getPlatforms = async (): Promise<GetPlatformsResponse> =>
  await v1ServerByProjectAuth.get({
    path: `auth/platforms`,
  });

type GetPlatformsResponse = Array<PlatformFromServer>;

//API Keys
//getApiKeys---------------------------------------------------------------------------------------
const getApiKeys = async (request: GetApiKeysRequest): Promise<GetApiKeysResponse> =>
  await v1ServerByProjectAuth.get({
    path: `auth/api-keys`,
    query: makeQuery(request.query),
  });

type GetApiKeysRequest = {
  query: {
    projectId: string;
    developerUserId: number;
  };
};

type GetApiKeysResponse = Array<ApiKeyFromServer>;

//createApiKey---------------------------------------------------------------------------------------
const createApiKey = async (request: CreateApiKeysRequest): Promise<CreateApiKeysResponse> =>
  await v1ServerByProjectAuth.post({
    path: `auth/api-keys`,
    data: request.data,
  });

type CreateApiKeysRequest = {
  data: {
    projectId: string;
    developerUserId: number;
    policyIds: Array<number>;
  };
};

type CreateApiKeysResponse = {
  api_key: ApiKeyFromServer;
  object_policies: Array<PolicyFromServer>;
};

//getApiKeyDetail---------------------------------------------------------------------------------------
const getApiKeyDetail = async (request: GetApiKeyDetailRequest): Promise<GetApiKeyDetailResponse> =>
  await v1ServerByProjectAuth.get({
    path: `auth/api-keys/${request.params.apiKeyId}`,
    query: makeQuery(request.query),
  });

type GetApiKeyDetailRequest = {
  params: {
    apiKeyId: number;
  };
  query: {
    developerUserId: number;
  };
};

type GetApiKeyDetailResponse = {
  api_key: ApiKeyFromServer;
  object_policies: Array<ObjectPolicyFromServer>;
};

//updateApiKey---------------------------------------------------------------------------------------
const updateApiKey = async (request: UpdateApiKeyRequest): Promise<UpdateApiKeyResponse> =>
  await v1ServerByProjectAuth.put({
    path: `auth/api-keys/${request.params.apiKeyId}`,
    data: request.data,
  });

type UpdateApiKeyRequest = {
  params: {
    apiKeyId: number;
  };
  data: {
    developerUserId: number;
    name: string;
    policyIds: Array<number>;
  };
};

type UpdateApiKeyResponse = {
  api_key: ApiKeyFromServer;
  object_policies: Array<ObjectPolicyFromServer>;
};

//deleteApiKey---------------------------------------------------------------------------------------
const deleteApiKey = async (request: DeleteApiKeyRequest): Promise<DeleteApiKeyResponse> =>
  await v1ServerByProjectAuth.delete({
    path: `auth/api-keys/${request.params.apiKeyId}`,
    query: makeQuery(request.query),
  });

type DeleteApiKeyRequest = {
  params: {
    apiKeyId: number;
  };
  query: {
    developerUserId: number;
  };
};

type DeleteApiKeyResponse = { success: boolean };

//TODO::서버타입 기입
// Oauth Client
//getOAuthClients---------------------------------------------------------------------------------------
const getOAuthClients = async (request: GetOAuthClientsRequest): Promise<GetOAuthClientsResponse> =>
  await v1ServerByProjectAuth.get({
    path: `auth/oauth-clients`,
    query: makeQuery(request.query),
  });

type GetOAuthClientsRequest = {
  query: {
    projectId: string;
    developerUserId: number;
  };
};

type GetOAuthClientsResponse = any;

//TODO::서버타입 기입
//createOAuthClient---------------------------------------------------------------------------------------
const createOAuthClient = async (request: CreateOAuthClientRequest): Promise<CreateOAuthClientResponse> =>
  await v1ServerByProjectAuth.post({
    path: `auth/oauth-clients`,
    data: request.data,
  });

type CreateOAuthClientRequest = {
  data: {
    name: number;
    projectId: string;
    grantType: string;
    platformId: number;
    developerUserId: number;
  };
};

type CreateOAuthClientResponse = any;

//TODO::서버타입 기입
//getOauthClientDetail---------------------------------------------------------------------------------------
const getOauthClientDetail = async (request: GetOauthClientDetailRequest): Promise<GetOauthClientDetailResponse> =>
  await v1ServerByProjectAuth.get({
    path: `auth/oauth-clients/${request.params.oauthClientId}`,
    query: makeQuery(request.query),
  });

type GetOauthClientDetailRequest = {
  params: {
    oauthClientId: number;
  };
  query: {
    developerUserId: number;
  };
};

type GetOauthClientDetailResponse = any;

//TODO::서버타입 기입
//updateOauthClient---------------------------------------------------------------------------------------
const updateOauthClient = async (request: UpdateOauthClientRequest): Promise<UpdateOauthClientResponse> =>
  await v1ServerByProjectAuth.put({
    path: `auth/oauth-clients/${request.params.oauthClientId}`,
    query: makeQuery(request.query),
    data: request.data,
  });

type UpdateOauthClientRequest = {
  params: {
    oauthClientId: number;
  };
  query: {
    developerUserId: number;
  };
  data: {
    name: string;
  };
};

type UpdateOauthClientResponse = any;

//TODO::서버타입 기입
//getRedirectUris---------------------------------------------------------------------------------------
const getRedirectUris = async (request: GetRedirectUrisRequest): Promise<GetRedirectUrisResponse> =>
  await v1ServerByProjectAuth.get({
    path: `auth/oauth-clients/${request.params.oauthClientId}/redirect-uris`,
    query: makeQuery(request.query),
  });

type GetRedirectUrisRequest = {
  params: {
    oauthClientId: number;
  };
  query: {
    developerUserId: number;
  };
};

type GetRedirectUrisResponse = any;

//TODO::서버타입 기입
//updateRedirectUris---------------------------------------------------------------------------------------
const updateRedirectUris = async (request: UpdateRedirectUrisRequest): Promise<UpdateRedirectUrisResponse> =>
  await v1ServerByProjectAuth.put({
    path: `auth/oauth-clients/${request.params.oauthClientId}/redirect-uris`,
    data: request.data,
  });

type UpdateRedirectUrisRequest = {
  params: {
    oauthClientId: number;
  };
  data: {
    redirectUris: Array<string>;
    developerUserId: number;
  };
};

type UpdateRedirectUrisResponse = any;

//TODO::서버타입 기입
//TODO: 기획 없음
//deleteOauthClient---------------------------------------------------------------------------------------
const deleteOauthClient = async (request: DeleteOauthClientRequest): Promise<DeleteOauthClientResponse> =>
  await v1ServerByProjectAuth.delete({
    path: `auth/oauth-clients/${request.params.oauthClientId}`,
    query: makeQuery(request.query),
  });

type DeleteOauthClientRequest = {
  query: {
    developerUserId: number;
  };
  params: {
    oauthClientId: number;
  };
};

type DeleteOauthClientResponse = any;

//Policy
const getPolicies = async (request: GetPoliciesRequest): Promise<GetPoliciesResponse> =>
  await v1ServerByProjectAuth.get({
    path: `auth/policies`,
    query: makeQuery(request.query),
  });

type GetPoliciesRequest = {
  query: {
    type: number;
  };
};

type GetPoliciesResponse = Array<PolicyFromServer>;

// TODO::사용처 없음
//////////////////////////////////////////////////////////
const getPolicyDetail = async (request: GetPolicyDetailRequest): Promise<GetPolicyDetailResponse> =>
  await v1ServerByProjectAuth.get({
    path: `auth/policies/${request.params.policyId}`,
  });

type GetPolicyDetailRequest = {
  params: {
    policyId: number;
  };
};

type GetPolicyDetailResponse = any;

//getAccountMemberPolicies---------------------------------------------------------------------------------------
const getAccountMemberPolicies = async (request: GetAccountMemberPoliciesRequest): Promise<GetAccountMemberPoliciesResponse> =>
  await v1ServerByProjectAuth.get({
    path: `auth/member-policies/${request.params.memberId}`,
    query: makeQuery(request.query),
  });

type GetAccountMemberPoliciesRequest = {
  query: {
    accountId: number;
  };
  params: {
    memberId: number;
  };
};

type GetAccountMemberPoliciesResponse = Array<ObjectPolicyFromServer>;

///////////////////////////////////////////////////
const getPolicyResources = async (request: GetPolicyResourcesRequest): Promise<GetPolicyResourcesResponse> =>
  await v1ServerByProjectAuth.get({
    path: `auth/policies/${request.params.policyId}/resources`,
  });

type GetPolicyResourcesRequest = {
  params: {
    policyId: number;
  };
};

type GetPolicyResourcesResponse = Array<PolicyFromServer>;

// TODO::사용처 없음
///////////////////////////////////////////////////
const updateAccountMemberPolicies = async (request: UpdateAccountMemberPoliciesRequest): Promise<UpdateAccountMemberPoliciesResponse> =>
  await v1ServerByProjectAuth.put({
    path: `auth/member-policies/${request.params.memberId}`,
    query: makeQuery(request.query),
    data: request.data,
  });

type UpdateAccountMemberPoliciesRequest = {
  params: {
    memberId: number;
  };
  query: {
    accountId: number;
  };
  data: {
    policies: Array<object>;
  };
};

type UpdateAccountMemberPoliciesResponse = any;

//getProjectAPIKeys---------------------------------------------------------------------------------------
const getProjectAPIKeys = async (): Promise<getProjectAPIKeysResponse> =>
  await v1ServerByProjectAuth.get({
    path: `auth/api-keys`,
  });

type getProjectAPIKeysResponse = Array<ProjectAPIKeysFromServer>;

//getWEB3ProviderEndpoint---------------------------------------------------------------------------------------
const getWEB3ProviderEndpoint = async (request: getWEB3ProviderEndpointRequest): Promise<getWEB3ProviderEndpointResponse> =>
  await v1ServerByProjectAuth.get({
    path: `auth/socket-api-keys`,
    query: makeQuery(request.query),
  });
type getWEB3ProviderEndpointRequest = {
  query: {
    microChainIds?: Array<number>;
  };
};

type getWEB3ProviderEndpointResponse = Array<WEB3ProviderEndpointsFromServer>;

//updateAccountMemberPolicies---------------------------------------------------------------------------------------
// const updateAccountMemberPolicies = async (request: UpdateAccountMemberPoliciesRequest): Promise<UpdateAccountMemberPoliciesResponse> =>
//   await authServer.put({
//     path: `auth/accounts/${request.params.accountId}/members/${request.params.memberId}/policies`,
//     query: makeQuery(request.query),
//     data: request.data
//   })
//
// type UpdateAccountMemberPoliciesRequest = {
//   query: {
//     developerUserId: number
//   }
//   params: {
//     accountId: number,
//     memberId: number
//   }
//   data: {
//     policies: Array<Policy>
//   }
// }

export {
  getPlatforms as getPlatformsFromServer,
  getApiKeys as getApiKeysFromServer,
  createApiKey as createApiKeyFromServer,
  getApiKeyDetail as getApiKeyDetailFromServer,
  updateApiKey as updateApiKeyFromServer,
  deleteApiKey as deleteApiKeyFromServer,
  getOAuthClients as getOAuthClientsFromServer,
  createOAuthClient as createOAuthClientFromServer,
  getOauthClientDetail as getOauthClientDetailFromServer,
  updateOauthClient as updateOauthClientFromServer,
  getRedirectUris as getRedirectUrisFromServer,
  updateRedirectUris as updateRedirectUrisFromServer,
  getPolicies as getPoliciesFromServer,
  getPolicyResources as getPolicyResourcesFromServer,
  getPolicyDetail as getPolicyDetailFromServer,
  getAccountMemberPolicies as getAccountMemberPoliciesFromServer,
  updateAccountMemberPolicies as updateAccountMemberPoliciesFromServer,
  deleteOauthClient as deleteOauthClientFromServer,
  getProjectAPIKeys as getProjectAPIKeysFromServer,
  getWEB3ProviderEndpoint as getWEB3ProviderEndpointFromServer,
  // updateAccountMemberPolicies as updateAccountMemberPoliciesFromServer
};
