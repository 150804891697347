import {
  createERC721FromServer,
  uploadERC721ImageFromServer,
  getERC721ListFromServer,
  getERC721DetailFromServer,
  updateERC721FromServer,
  deleteERC721FromServer,
  createERC721ContractFromServer,
  getERC721TokensFromServer,
  getERC721TokenDetailFromServer,
  uploadNFTContentFromServer,
} from "server/API/erc721API";

export const createNFTProjectParser = async (
  microChainId: number,
  symbol: string,
  name: string,
  description: string,
  image: string,
  mintable: boolean,
  burnable: boolean,
  officialSite: Array<string>
): Promise<any> => {
  const result = await createERC721FromServer({
    data: {
      microChainId,
      symbol,
      name,
      description,
      image,
      mintable,
      burnable,
      officialSite,
    },
  });
  return result;
};

export const uploadNFTProjectImageParser = async (fileCount: number, formData: FormData): Promise<any> => {
  const result = await uploadERC721ImageFromServer({
    params: {
      fileCount,
    },
    data: formData,
  });
  return result;
};

export const getNFTProjectListParser = async (): Promise<any> => {
  const result = await getERC721ListFromServer();
  return result;
};

export const getNFTProjectDetailParser = async (erc721Id: number): Promise<any> => {
  const result = await getERC721DetailFromServer({
    params: {
      erc721Id,
    },
  });
  return result;
};

export const updateNFTProjectParser = async (
  erc721Id: number,
  name: string,
  description: string,
  image: string,
  officialSite: Array<string>,
  symbol: string
): Promise<any> => {
  const result = await updateERC721FromServer({
    params: {
      erc721Id,
    },
    data: {
      name,
      description,
      image,
      officialSite,
      symbol,
    },
  });
  return result;
};

export const deleteNFTProjectParser = async (erc721Id: number): Promise<any> => {
  const result = await deleteERC721FromServer({
    params: {
      erc721Id,
    },
  });
  return result;
};

export const createNFTProjectContractParser = async (erc721Id: number): Promise<any> => {
  const result = await createERC721ContractFromServer({
    params: {
      erc721Id,
    },
  });
  return result;
};

export const getNFTTokensParser = async (erc721TempId: number): Promise<any> => {
  const result = await getERC721TokensFromServer({
    query: {
      erc721TempId,
    },
  });
  return result;
};

export const getNFTTokenDetailParser = async (erc721TokenId: number): Promise<any> => {
  const result = await getERC721TokenDetailFromServer({
    params: {
      erc721TokenId,
    },
  });
  return result;
};

export const uploadNFTContentParser = async (fileCount: number, formData: FormData): Promise<any> => {
  const result = await uploadNFTContentFromServer({
    query: {
      fileCount,
    },
    data: formData,
  });
  return result;
};
