export type Legacy_ProjectFromServer = {
  id: number;
  account_id: number;
  name: string;
  description: string;
  eqhub_service: boolean;
  created_at?: string;
  updated_at?: string;
  project_members?: Array<LegacyProjectMemberFromServer>;
};

export type LegacyProjectMemberFromServer = {
  project_id: number;
  member_id: number;
};

// 새로운 project type
export type ProjectWithMemberInfoFromServer = ProjectMemberFromServer & {
  project: ProjectFromServer;
};

export type ProjectMemberFromServer = {
  project_member_id: number;
  project_id: number;
  user_id: number;
  project_role_id: number;
  created_at: string;
};

export type ProjectNetworksFromServer = {
  project_id: number;
  micro_chain_id: number;
  created_at: string;
};

export type ProjectFromServer = {
  id: number;
  creator_id: number;
  name: string;
  description: string;
  image: string;
  pricing_plan_id: number;
  created_at: string;
};

export type ProjectRoleFromServer = {
  project_role_id: number;
  name: string;
  description: string | null;
  created_at: string;
};

export type ProjectInvitationFromServer = {
  id: number;
  project_id: number;
  sender_user_id: number;
  email: string;
  is_accept: number;
  expire_time: string;
  created_at: string;
  updated_at: string;
};

export type ProjectDefaultImageFromServer = {
  project_default_image: number;
  url: string;
  created_at: string;
};

export type MicroChainListOfProjectFromServer = {
  created_at: string;
  micro_chain_id: number;
  project_id: number;
};

export enum FromTick {
  HOUR = "1h",
  DAY = "24h",
  WEEK = "7d",
  MONTH = "30d",
}

export enum ProjectRequestsTab {
  COUNT = "count",
  CREDIT = "credit",
}

export type PricingPlanFromServer = {
  pricing_plan_id: number;
  monthly_price: string;
  default_usage_credit: string;
  name: string;
  image: string | null;
  description: string | null;
  is_extra_charge: boolean;
  created_at: string;
  features?: Array<PlanFeatureFromServer>;
};

type PlanFeatureFromServer = {
  feature_id: number;
  name: string;
  image: string | null;
  description: string | null;
};

export type MonthlyHistoryFromServer = {
  api_usage_credit: string;
  infra_usage_credit: string;
  extra_charge_credit: string;
  total_payment_credit: string;
  bonus_credit: string;
  total_payment_price: string;
  exchange_rate: string;
  payment_day?: string;
  project_id?: number;
  start_date?: string;
  end_date?: string;
};

export type ProjectReceiptFromServer = {
  count: number;
  rows: ProjectReceiptRows;
};

type ProjectReceiptRows = Array<{
  created_at: string;
  is_paid: boolean;
  monthly_history_id: number;
  project_id: number;
  receipt_id: number;
  title: string;
  monthly_history: MonthlyHistoryFromServer;
}>;

export type ProjectCreditFromServer = {
  project_id: number;
  credit: string;
};
