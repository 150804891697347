import React, {useCallback} from "react";
import {useDropzone} from "react-dropzone";
import "./FileDrangAndDrop.scss";
import uploadIcon from "../../../../view/assets/images/atom/icon-FileDragAndDrop-upload.png";
import reUploadIcon from "../../../../view/assets/images/atom/icon-FileDragAndDrop-re-upload.png";
import deleteIcon from "../../../../view/assets/images/atom/icon-FileDragAndDrop-delete.png";
import toast from "react-hot-toast";

const defaultDropzoneStyle = {
  display        : "flex",
  width          : "100%",
  height         : 50,
  border         : "1px dashed #B7BABE",
  borderRadius   : 8,
  backgroundColor: "#FAFBFF",
  cursor         : "pointer",
};

const FileDragAndDrop = (props) => {
  const {
          file,
          setFile,
          maxFile     = 1,
          allowExtension,
          placeholder = "Upload File",
          label,
          disabled    = false
        } = props || {};
  const checkAllowedExtension = (file, allowExtension) => {
    if (!allowExtension) {
      return true;
    }
    return allowExtension.find((el) => el === file.name.slice(-el.length));
  };

  const onDrop = useCallback((acceptedFiles) => {
    if (maxFile === 1) {
      if (checkAllowedExtension(acceptedFiles[0], allowExtension)) {
        setFile(acceptedFiles[0]);
      } else {
        toast.error("Invalid File type");
      }
    } else {
      setFile(acceptedFiles);
    }
  }, []);

  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop,
    maxFiles: maxFile,
    disabled,
  });

  return (
    <div className="file-drag-and-drop">
      <div {...getRootProps({className: "dropzone", style: defaultDropzoneStyle})}>
        <input {...getInputProps()} />
        <div className="upload-filed">
          {<img className="upload-file-icon" src={file ? reUploadIcon : uploadIcon} alt="upload"/>}
          {isDragActive && <span className="upload-file-label">Drag over here to upload file</span>}
          {file && (
            <>
              <span className="upload-file-label file">{label ?? file.name ?? ''}</span>
              {/*{label ?? <span className="upload-file-label file">{label}</span>}*/}
              <img
                className="delete-uploaded-file-btn"
                src={deleteIcon}
                alt="x"
                onClick={(e) => {
                  e.stopPropagation();
                  setFile(null);
                }}
              />
            </>
          )}
          {!isDragActive && !file && <span className="upload-file-label">{placeholder}</span>}
        </div>
      </div>
    </div>
  );
};

export default FileDragAndDrop;
