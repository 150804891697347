import {parseAction} from "./util/parseAction";
import {
	acceptContractVersionParser,
	createContractParser,
	createContractVersionParser,
	deleteContractVersionParser,
	getABICodeByFileURLParser,
	getContractVersionDetailParser,
	getContractVersionsParser,
	getMyContractsParser,
	getPublishedContractsParser,
	publishContractVersionParser,
	rejectContractVersionParser,
	submitContractVersionParser,
	updateContractVersionParser,
	withdrawContractVersionParser,
} from "../parser/contractLibraryParser";
import {flattenVersions} from "../view/service/developer/contract/constants/contract.utils";
import {FlattenVersion} from "../view/service/developer/contract/constants/contract.type";
import {getExternalAndInnerMicroChainListParser} from "../parser/microChainParser";
import _ from "lodash";
import {LibraryContract} from "../reducer/clientType/contractClientType";
import {getMicroChainIdsOfProjectParser, getProjectsParser} from "../parser/projectParser";
import {Project} from "../reducer/clientType/projectClientType";
import {eqhub} from "App";

// 컨트랙트를 최초로 생성하기 위한 Action 함수
// 생성에 성공하면 해당 스마트 컨트랙트 상세 정보 페이지로 이동시키기 위해 contractId와 versionId를 리턴한다.
export const createContractAction = (fileCount: number, data: FormData) =>
	parseAction(async () => {
		const result = await createContractParser(fileCount, data);
		return {
			contractId: result.contractId,
			versionId: result.contractVersions[0].contractVersionId,
		};
	});

// Contract storage 에서 생성한 스마트 컨트랙트 목록을 조회하는 Action
// 조회한 스마트 컨트랙트 목록을 디자인에 맞게 보여주기 위해 파싱하는 단계를 거친다.
// EQ Hub 개발문서 - Contract 개발문서의 Functions - Utils - flattenVersions 참고
export const getStorageContractAction = () =>
	parseAction(async () => {
		const contracts = (await getMyContractsParser()) as LibraryContract[];
		const contractVersionWithContractDetail = contracts.reduce(
			(acc: FlattenVersion[], cur) => flattenVersions(acc, cur),
			[]
		);
		return {
			inProgressVersions: contractVersionWithContractDetail.filter((version) => version.status < 3),
			approvedVersions: contractVersionWithContractDetail.filter((version) => version.status >= 3),
		};
	});

// 컨트랙트의 특정 버전 정보를 조회하기 위한 Action
export const getContractVersionDetailAction = (contractId: number, versionId: number) =>
	parseAction(async () => {
		const versionDetail = await getContractVersionDetailParser(contractId, versionId);
		const {contractVersionCode} = versionDetail;
		const customAbiCode = await getABICodeByFileURLParser(contractVersionCode.customAbiCode);

		const currentContract = await getContractVersionsParser(contractId);
		return {
			contractDetail: currentContract,
			versionDetail: {
				...versionDetail,
				contractConstructor: customAbiCode.constructor,
				contractFunctions: customAbiCode.functions,
				contractEvents: customAbiCode.events,
			},
		};
	});

// Contract를 Get 하기 위해 특정 스마트 컨트랙트의 정보를 조회하는 Action
export const getDataForGetContractAction = (contractId: number, versionId: number) =>
	parseAction(async () => {
		let microChains: Array<any> = [];
		//프로젝트에서 사용하기로 설정한 microChain 목록을 조회합니다.
		const microChainIdsOfProject = await getMicroChainIdsOfProjectParser();
		if (microChainIdsOfProject.length !== 0) {
			microChains = await getExternalAndInnerMicroChainListParser({
				microChainIds: microChainIdsOfProject,
				mainStatus: [5],
			});
		}
		//조회한 microChain 목록에서 network 정보를 중복되지 않게 파싱하여 배열로 반환합니다.
		const networks = _.uniqBy(
			microChains.map((el) => el.network),
			"id"
		);
		const [versionDetail, contractDetail] = await Promise.all([
			getContractVersionDetailParser(contractId, versionId),
			getContractVersionsParser(contractId),
		]);

		const {contractVersionCode} = versionDetail;
		const customAbiCode = await getABICodeByFileURLParser(contractVersionCode.customAbiCode);

		return {
			microChains,
			networks,
			versionDetail: {
				...versionDetail,
				contractConstructor: customAbiCode.constructor,
			},
			contractDetail,
		};
	});

// 생성한 스마트 컨트랙트의 정보를 수정하기 위한 Action
export const updateContractVersionAction = (
	contractId: number,
	contractVersionId: number,
	isPrivate: number,
	description: string,
	versionDescription: string,
	updateAbi: File
) =>
	parseAction(async () => {
		let formData = new FormData();
		formData.append("isPrivate", isPrivate.toString());
		formData.append("description", description);
		formData.append("versionDescription", versionDescription);
		formData.append("customAbiCode", updateAbi);
		const result = await updateContractVersionParser(contractId, contractVersionId, formData);
		const {contractVersionCode} = result;
		const customAbiCode = await getABICodeByFileURLParser(contractVersionCode.customAbiCode);

		return {
			...result,
			contractConstructor: customAbiCode.constructor,
			contractFunctions: customAbiCode.functions,
			contractEvents: customAbiCode.events,
		};
	});

// 컨트랙트의 특정 버전을 삭제하기 위한 Action
export const deleteContractVersionAction = (contractId: number, contractVersionId: number) =>
	parseAction(async () => {
		return await deleteContractVersionParser(contractId, contractVersionId);
	});

// 컨트랙트의 특정 버전을 심의 요청 상태로 변경하기 위한 Action
export const submitContractVersionAction = (contractId: number, contractVersionId: number) =>
	parseAction(async () => {
		return await submitContractVersionParser(contractId, contractVersionId);
	});

// 컨트랙트의 특정 버전을 심의 완료 상태로 변경하기 위한 Action
export const acceptContractVersionAction = (contractId: number, contractVersionId: number) =>
	parseAction(async () => {
		return await acceptContractVersionParser(contractId, contractVersionId);
	});

// 컨트랙트의 특정 버전을 심의 거부 상태로 변경하기 위한 Action
export const rejectContractVersionAction = (contractId: number, contractVersionId: number, rejectReason: string) =>
	parseAction(async () => {
		return await rejectContractVersionParser(contractId, contractVersionId, rejectReason);
	});

// 컨트랙트를 contract library에 공개 상태로 변경하기 위한 Action
export const publishContractAction = (contractId: number, contractVersionId: number) =>
	parseAction(async () => {
		return await publishContractVersionParser(contractId, contractVersionId);
	});

// 컨트랙트를 contract library에 비공개 상태로 변경하기 위한 Action
export const withdrawContractAction = (contractId: number, contractVersionId: number) =>
	parseAction(async () => {
		return await withdrawContractVersionParser(contractId, contractVersionId);
	});

// 스마트 컨트랙트의 버전을 생성하기 위해 기존 스마트 컨트랙트 정보를 조회하는 Action
export const getContractDetailForCreateVersionAction = (contractId: number) =>
	parseAction(async () => {
		const result = (await getMyContractsParser(contractId)) as LibraryContract;
		return {
			title: result.title,
			description: result.description,
			type: result.type,
			ercType: result.ercType,
		};
	});

// 기존에 생성한 스마트 컨트랙트에 새로운 버전을 생성하기 위한 Action
export const createContractVersionAction = (contractId: number, fileCount: number, formData: FormData) =>
	parseAction(async () => {
		return await createContractVersionParser(contractId, fileCount, formData);
	});

// Contract Library에 보여지는 스마트 컨트랙트 목록을 조회하기 위한 Action
export const getPublishedContractAction = () =>
	parseAction(async () => {
		const contracts = await getPublishedContractsParser({limit: 500});
		if (contracts.length !== 0) {
			//스마트 컨트랙트를 생성한 Project Id 정보를 중복되지 않게 파싱합니다.
			const projectIds = _.compact(_.uniq(contracts.map((el) => el.projectId)));
			//파싱한 project Id를 바탕으로 Project 정보를 모두 조회합니다.
			const projectInfos = await getProjectsParser({projectIds});

			//아래 reduce 함수는 서버로 부터 전달받은 스마트 컨트랙트 목록을 UI에 맞게 보여주기 위해 파싱하는 함수입니다.
			return contracts.reduce(
				(
					acc: Array<{
						latestLibraryVersionId: number;
						contractId: number;
						title: string;
						type: number;
						owner: string;
						description: string;
						ercType: number | null;
					}>,
					cur
				) => {
					//contract 객체에서 contract version 정보를 추출합니다.
					const {contractVersions} = cur;
					//contract 객체에 저장되어 있는 project Id와 같은 Project 객체 정보를 조죄한 Project 정보에서 찾습니다.
					const owner = projectInfos.find((el) => el.id === cur.projectId) as Project;
					//아래 코드는 다음의 흐름을 따라갑니다.
					//1. 스마트 컨트랙트 객체에 저장되어 있는 버전 정보중,
					//2. 공개 여부가 "공개" 인 버전만을 추출하고
					//3. 2번 과정으로 파싱된 배열에서 contractVersionId만 뽑아서 새로운 배열을 만들고
					//4. 최신 생성순으로 정렬 한 후
					//5. 첫번째 인덱스의 값을 할당합니다.
					const latestPublishedVersionId: number = contractVersions
						.filter((el) => el.isPrivate === 0)
						.map((el) => el.contractVersionId)
						.sort()
						.slice()
						.reverse()[0];

					return acc.concat({
						latestLibraryVersionId: latestPublishedVersionId,
						contractId: cur.contractId,
						owner: owner.name,
						title: cur.title,
						type: cur.type,
						description: cur.description,
						ercType: cur.ercType,
					});
				},
				[]
			);
		} else {
			return [];
		}
	});

// Contract library애서 선택한 특정 스마트 컨트랙트의 상세 정보를 조회하기 위한 Action
export const getPublishedContractVersionDetailAction = (contractId: number, versionId: number) =>
	parseAction(async () => {
		const currentContract = await getContractVersionsParser(contractId);
		const currentVersion = await getContractVersionDetailParser(contractId, versionId);
		const {contractVersionCode} = currentVersion;
		const customAbiCode = await getABICodeByFileURLParser(contractVersionCode.customAbiCode);

		return {
			contractDetail: {
				...currentContract,
				//여기서 파싱하는 contract version은 contract library 상세 페이지에서 공개 여부 상태가 "공개"인 모든 버전 정보를 보여주기 위함입니다.
				contractVersions: currentContract.contractVersions.filter((el) => el.status === 4),
			},
			versionDetail: {
				...currentVersion,
				contractConstructor: customAbiCode.constructor,
				contractFunctions: customAbiCode.functions,
				contractEvents: customAbiCode.events,
			},
		};
	});

// Contract library에서 배포 수가 높은 컨트랙트 라이브러리를 조회하기 위한 Action
export const getPopularContractLibrariesAction = (limit: number) =>
  parseAction(async () => {
    const contracts = await eqhub.contractLibrary.getPopularContractLibraries({ limit });
    if (contracts.length !== 0) {
      let contractIds: number[] = [];
      contracts.map((row) => contractIds.push(row.contract_id));
      const categoriesOfContract = await eqhub.contractLibrary.getCategoriesOfContractLibraries(contractIds);
      const tagsOfContract = await eqhub.contractLibrary.getTagsOfContractLibraries(contractIds);

      //스마트 컨트랙트를 생성한 Project Id 정보를 중복되지 않게 파싱합니다.
      const projectIds = _.compact(_.uniq(contracts.map((el) => el.project_id)));
      //파싱한 project Id를 바탕으로 Project 정보를 모두 조회합니다.
      const projectInfos = await getProjectsParser({ projectIds });
      const contractsWithOwner = await Promise.all(
        contracts.map(async (contract) => {
          const project = projectInfos.find((proj) => proj.id === contract.project_id);
          const contractCategories = categoriesOfContract.filter((ctg) => ctg.contract_id === contract.contract_id);
          const contractTags = tagsOfContract.filter((tag) => tag.contract_id === contract.contract_id);
          return { name: project?.name, ...contract, contractCategories, contractTags };
        })
      );

      return { contract: contractsWithOwner };
    } else {
      return { contract: [] };
    }
  });

export const getContractLibrariesAction = (limit: number, offset: number) =>
  parseAction(async () => {
    const contracts = await eqhub.contractLibrary.getContractLibraries({ limit, offset });
    const contractsRows = contracts.rows;
    const contractCount = contracts.count;
    if (contractsRows.length !== 0) {
      //스마트 컨트랙트를 생성한 Project Id 정보를 중복되지 않게 파싱합니다.
      const projectIds = _.compact(_.uniq(contractsRows.map((el) => el.project_id)));
      //파싱한 project Id를 바탕으로 Project 정보를 모두 조회합니다.
      const projectInfos = await getProjectsParser({ projectIds });
      const contractsWithOwner = await Promise.all(
        contractsRows.map(async (contract) => {
          const project = projectInfos.find((proj) => proj.id === contract.project_id);
          const contractCategories = await eqhub.contractLibrary.getContractLibraryCategories(contract.contract_id);
          const contractTags = await eqhub.contractLibrary.getContractLibraryTags(contract.contract_id);
          return { name: project?.name, ...contract, contractCategories, contractTags };
        })
      );
      return { count: contractCount, contracts: contractsWithOwner };
    } else {
      return { count: 0, contracts: [] };
    }
  });

export const getCategoriesAction = () =>
  parseAction(async () => {
    return await eqhub.contractLibrary.getCategories();
  });

export const getTagsAction = () =>
  parseAction(async () => {
    return await eqhub.contractLibrary.getTags();
  });

export const searchContractLibrariesAction = (
  page: number,
  keyword: string,
  tag_ids: number[],
  category_ids: number[]
) =>
  parseAction(async () => {
    const offset = 10 * (Number(page) - 1);
    const contracts = await eqhub.contractLibrary.searchContractLibraries({
      offset,
      keyword,
      tag_ids,
      category_ids,
    });
    const contractsRows = contracts.rows;
    const contractCount = contracts.count;

    if (contractsRows.length !== 0) {
		let contractIds: number[] = [];
		contractsRows.map((row) => contractIds.push(row.contract_id));
		const categoriesOfContractRows = await eqhub.contractLibrary.getCategoriesOfContractLibraries(contractIds);
		const tagsOfContractRows = await eqhub.contractLibrary.getTagsOfContractLibraries(contractIds);
      //스마트 컨트랙트를 생성한 Project Id 정보를 중복되지 않게 파싱합니다.
      const projectIds = _.compact(_.uniq(contractsRows.map((el) => el.project_id)));
      //파싱한 project Id를 바탕으로 Project 정보를 모두 조회합니다.
      const projectInfos = await getProjectsParser({ projectIds });
      const contractsWithOwner = await Promise.all(
        contractsRows.map(async (contract) => {
          const project = projectInfos.find((proj) => proj.id === contract.project_id);
          const contractCategories = categoriesOfContractRows.filter((ctg) => ctg.contract_id === contract.contract_id);
          const contractTags = tagsOfContractRows.filter((tag) => tag.contract_id === contract.contract_id);
          return { name: project?.name, ...contract, contractCategories, contractTags };
        })
      );

      return { count: contractCount, contracts: contractsWithOwner };
    } else {
      return { count: contractCount, contracts: [] };
    }
  });

export const getContractLibraryDetailAction = (contract_id: number, version_id: number) =>
  parseAction(async () => {
    const contract = await eqhub.contractLibrary.getContractLibraryByContractId(contract_id);
    const currentVersion = await eqhub.contractLibrary.getContractLibraryVersionByVersionId(version_id, contract_id);
    const { contract_version_code } = currentVersion;
    const customAbiCode = await getABICodeByFileURLParser(contract_version_code.custom_abi_code);

    const contractCategories = await eqhub.contractLibrary.getContractLibraryCategories(contract.contract_id);
    const contractTags = await eqhub.contractLibrary.getContractLibraryTags(contract.contract_id);


	if(contract.project_id) {
		const projectIds = [contract.project_id]
		const projectInfo = await getProjectsParser({projectIds})
		const projectName = projectInfo[0].name

		const contractWithProjectName = {...contract, name: projectName}
		return {
			contractDetail: {
				...contractWithProjectName,
				//여기서 파싱하는 contract version은 contract library 상세 페이지에서 공개 여부 상태가 "공개"인 모든 버전 정보를 보여주기 위함입니다.
				contractVersions: contract.contract_versions.filter((el) => el.status === 4),
				contractCategories,
				contractTags,
			},
			versionDetail: {
				...currentVersion,
				contractConstructor: customAbiCode.constructor,
				contractFunctions: customAbiCode.functions,
				contractEvents: customAbiCode.events,
			},
		}
	}

  });

export const getRecommendContractLibrariesByCategoryAction = (contract_id: number) =>
  parseAction(async () => {
    const contracts = await eqhub.contractLibrary.getRecommendContractLibrariesByCategory(contract_id);
    if (contracts.length !== 0) {
      let contractIds: number[] = [];
      contracts.map((row) => contractIds.push(row.contract_id));
      const categoriesOfContract = await eqhub.contractLibrary.getCategoriesOfContractLibraries(contractIds);
      const tagsOfContract = await eqhub.contractLibrary.getTagsOfContractLibraries(contractIds);

      //스마트 컨트랙트를 생성한 Project Id 정보를 중복되지 않게 파싱합니다.
      const projectIds = _.compact(_.uniq(contracts.map((el) => el.project_id)));
      //파싱한 project Id를 바탕으로 Project 정보를 모두 조회합니다.
      const projectInfos = await getProjectsParser({ projectIds });
      const contractsWithOwner = await Promise.all(
        contracts.map(async (contract) => {
          const project = projectInfos.find((proj) => proj.id === contract.project_id);
          const contractCategories = categoriesOfContract.filter((ctg) => ctg.contract_id === contract.contract_id);
          const contractTags = tagsOfContract.filter((tag) => tag.contract_id === contract.contract_id);
          return { name: project?.name, ...contract, contractCategories, contractTags };
        })
      );
      return { contract: contractsWithOwner };
    } else {
      return { contract: [] };
    }
  });

export const getRecommendContractLibrariesByTagAction = (contract_id: number) =>
  parseAction(async () => {
    const contracts = await eqhub.contractLibrary.getRecommendContractLibrariesByTag(contract_id);
    if (contracts.length !== 0) {
      let contractIds: number[] = [];
      contracts.map((row) => contractIds.push(row.contract_id));
      const categoriesOfContract = await eqhub.contractLibrary.getCategoriesOfContractLibraries(contractIds);
      const tagsOfContract = await eqhub.contractLibrary.getTagsOfContractLibraries(contractIds);
      //스마트 컨트랙트를 생성한 Project Id 정보를 중복되지 않게 파싱합니다.
      const projectIds = _.compact(_.uniq(contracts.map((el) => el.project_id)));
      //파싱한 project Id를 바탕으로 Project 정보를 모두 조회합니다.
      const projectInfos = await getProjectsParser({ projectIds });
      const contractsWithOwner = await Promise.all(
        contracts.map(async (contract) => {
          const project = projectInfos.find((proj) => proj.id === contract.project_id);
          const contractCategories = categoriesOfContract.filter((ctg) => ctg.contract_id === contract.contract_id);
          const contractTags = tagsOfContract.filter((tag) => tag.contract_id === contract.contract_id);
          return { name: project?.name, ...contract, contractCategories, contractTags };
        })
      );
      return { contract: contractsWithOwner };
    } else {
      return { contract: [] };
    }
  });
