import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setRawTransactionResult, transaction_result } from '../store/redux/transaction/transactionSlice';
import { requestTransactionToExtension } from '../modules/EQExtension/utils/messageUtils';
import { errorAlert } from '../utils/Utils';

// store에 저장된 txhash를 callback의 파라미터에 넘겨주는 구조의 hook입니다.
// TODO: useTransaction과 병합될 수 있습니다.

const useTransactionDirectly = (transaction: Function, callback: Function) => {
  const dispatch = useDispatch();

  const { rawTransactionResult } = useSelector(transaction_result);

  async function requestTransactionDirectly() {
    const { result, error } = await transaction();

    if (result) {
      requestTransactionToExtension({ ...result });
    }

    if (error) {
      switch (error.type) {
        case 'API':
          errorAlert(error.data.message);
          break;
        case 'Module':
          errorAlert(`${error.data.argument}::${error.data.code}`);
          break;
        default:
          errorAlert(error.data.message);
      }
    }
  }

  useEffect(() => {
    if (Boolean(rawTransactionResult.tx_hash)) {
      callback(rawTransactionResult.tx_hash);
      dispatch(setRawTransactionResult({ tx_hash: '' }));
    }
  }, [rawTransactionResult]);

  return { requestTransactionDirectly };
};

export default useTransactionDirectly;
