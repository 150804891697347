import axios, {AxiosResponse} from "axios";
import qs from "qs";
import {projectApiQueue, projectJWT, userApiQueue, userJWT} from "./serverInstances";
import {storeDispatch} from "reducer/index";
import {removeUser, setIsExpired, setUserWithJWT} from "reducer/userReducer";
import {removeAllProjects, setProjectToken} from "reducer/projectReducer";
import moment from "moment";
import CryptoJS from "crypto-browserify";
import {MyInfo} from "reducer/clientType/userClientType";
import {eqhub} from "App";

export const makeQuery = (object: object): string => qs.stringify(object, {addQueryPrefix: true});

export const roundNumber = (float: number, decimal: number): number => {
	const pow = Math.pow(10, decimal);
	return Math.round(float * pow) / pow;
};

export const refreshUserAccessToken = async (): Promise<MyInfo> => {
  const baseURL = `${process.env.REACT_APP_HUB_SERVER_URL}${process.env.REACT_APP_API_VERSION}`;

  const result = await axios({
    method: "post",
    baseURL,
    url: `developer-tokens/refresh`,
    withCredentials: true,
  }).then((res: AxiosResponse) => {

    const { data } = res;
    return {
      id: data.id,
      firstName: data.first_name,
      lastName: data.last_name,
      email: data.email,
      emailVerification: Boolean(data.email_verification),
      image: data.image,
      region: data.region,
      createdAt: data.created_at,
      updatedAt: data.updated_at,
      token: data.token,
      projectToken: data.project_token,
      lastPasswordChangedAt: data.last_password_changed_at,
      changePasswordRecommendedDate: data.change_password_recommended_date,
      isMFASet: Boolean(data.is_mfa_set),
    };
  });

  storeDispatch(setUserWithJWT(result));
  userJWT.setAccess(result.token.access);
  userJWT.setRefresh(result.token.refresh);
  eqhub.setUserAccessToken(result.token.access.token);

  if (result.projectToken) {
    storeDispatch(setProjectToken(result.projectToken));
    projectJWT.setAccess(result.projectToken.access);
    projectJWT.setRefresh(result.projectToken.refresh);
    eqhub.setProjectAccessToken(result.projectToken.access.token);
  }

  return result;
};

export const changeToHash = (value: string) => {
  return CryptoJS.createHash("sha256").update(value).digest("hex");
};

export const login = async (email: string, password: string, options?: { totpCode?: string }): Promise<MyInfo> => {
  const baseURL = `${process.env.REACT_APP_HUB_SERVER_URL}${process.env.REACT_APP_API_VERSION}`;

  const hashPassword = changeToHash(password);

  const result = await axios({
    method: "post",
    baseURL,
    url: `developer-users/login`,
    withCredentials: true,
    data: {
      email,
      password: hashPassword,
      ...(options?.totpCode && { totpCode: options.totpCode }),
    },
  }).then((res: AxiosResponse) => {
    const { data } = res;
    return {
      id: data.id,
      firstName: data.first_name,
      lastName: data.last_name,
      email: data.email,
      emailVerification: Boolean(data.email_verification),
      image: data.image,
      region: data.region,
      createdAt: data.created_at,
      updatedAt: data.updated_at,
      token: data.token,
      projectToken: data.project_token,
      lastPasswordChangedAt: data.last_password_changed_at,
      changePasswordRecommendedDate: data.change_password_recommended_date,
      isMFASet: Boolean(data.is_mfa_set),
    };
  });

  userJWT.setAccess(result.token.access);
  userJWT.setRefresh(result.token.refresh);
  storeDispatch(setUserWithJWT(result));
  eqhub.setUserAccessToken(result.token.access.token);

  return result;
};

export const logout = async (): Promise<any> => {
  const baseURL = `${process.env.REACT_APP_HUB_SERVER_URL}${process.env.REACT_APP_API_VERSION}`;

  await axios({
    method: "post",
    baseURL,
    url: `developer-users/logout`,
    withCredentials: true,
  }).then((res: AxiosResponse) => {
    return res.data;
  });

  userJWT.removeToken();
  projectJWT.removeToken();
  storeDispatch(removeUser());
  storeDispatch(removeAllProjects());

  userApiQueue.resetQueue();
  projectApiQueue.resetQueue();
  // projectMethodQueue.resetQueue();

  storeDispatch(setIsExpired(false));
};

export const checkAccessToken = (serverErrorCode: number): boolean => {
  const invalidAccessTokenErrorCode = 34;
  if (serverErrorCode === invalidAccessTokenErrorCode) {
    return true;
  }
  return false;
};

export const checkRefreshToken = (serverErrorCode: number): boolean => {
  const invalidRefreshTokenErrorCode = 35;
  if (serverErrorCode === invalidRefreshTokenErrorCode) {
    return true;
  }
  return false;
};

export const ERROR_TYPE_LOGIN_EXPIRED = "LOGIN_EXPIRED";

export const setExpiredTime = () => {
  const spareTime = 500;
  const refreshTokenExpirationTime = userJWT.getRefresh().expires;
  const currentTime = Number(moment().utc().valueOf());
  const expiredTime = Number(moment(refreshTokenExpirationTime).utc().valueOf());
  const remainingTime = expiredTime - currentTime - spareTime;

  const expired = setTimeout(() => {
    storeDispatch(setIsExpired(true));
  }, remainingTime);

  return expired;
};

export const refreshAction = async (error: any) => {
  const isAccessTokenExpired = checkAccessToken(error.error.serverStatusCode);
  if (isAccessTokenExpired) {
    try {
      const result = await refreshUserAccessToken();
      return result;
    } catch (e) {
      const isRefreshTokenExpired = checkRefreshToken(error.error.serverStatusCode);
      if (isRefreshTokenExpired) {
        throw { type: ERROR_TYPE_LOGIN_EXPIRED };
      }
    }
  }
};
