import React, { useMemo } from "react";
import "./DivideLine.scss";

const BORDER_STYLE_LIST = [
  { type: 0, style: "solid" },
  { type: 1, style: "dashed" },
];

const DivideLine = (props) => {
  const { width = 2, type = 0, style } = props || {};

  const borderStyle = useMemo(() => {
    return BORDER_STYLE_LIST.find((el) => el.type === type).style;
  }, [type]);

  return <div className="divide-line" style={{ borderBottomWidth: width, borderBottomStyle: borderStyle, ...style }} />;
};

export default DivideLine;
