import css from "./NetworkCard.scss";
import privateIcon from "../../assets/Icon_private_blue.png";
import publicIcon from "../../assets/Icon_public_blue.png";
import mainnetIcon from "../../assets/Icon_mainnet_blue.png";
import consensusNodeIcon from "../../assets/Icon_consensus_node.png";
import fullNodeIcon from "../../assets/Icon_full_node.png";
import { bindStyle } from "view/styles/classNames";

const enum NetworkType {
  MAINNET = "Mainnet",
  TESTNET = "Testnet",
}

const enum NetworkAccessType {
  PUBLIC = "Public",
  PRIVATE = "Private",
}

//  * - (0) Reject (red)
//  * - (1) Created (gray)
//  * - (2) Approval (yellow)
//  * - (3) Prepared (green)
//  * - (4) Processing (yellow)
//  * - (5) Deployed (blue)
const NETWORK_STATUS = [
  {
    status: 0,
    label: "Reject",
    color: "#F2C440",
  },
  {
    status: 1,
    label: "Created",
    color: "#F2C440",
  },
  {
    status: 2,
    label: "Approval",
    color: "##F2C440",
  },
  {
    status: 3,
    label: "Prepared",
    color: "#F2C440",
  },
  {
    status: 4,
    label: "Processing",
    color: "#F2C440",
  },
  {
    status: 5,
    label: "Deployed",
    color: "#78C58C",
  },
];
function NetworkCard(props) {
  const { networkInfo, equalizerCount, lightNodeCount, onClick } = props;

  const currentNetworkStatus = NETWORK_STATUS.find((status) => status.status === networkInfo.mainStatus);

  const cn = bindStyle(css);

  const isStatusActive = (index: number) => {
    if (!currentNetworkStatus) {
      return;
    }
    if (currentNetworkStatus.status === 0) {
      return currentNetworkStatus?.color;
    }
    if (currentNetworkStatus.status >= index) {
      return currentNetworkStatus?.color;
    }
  };

  const cardClickHandler = () => {
    if (!onClick) return;
    onClick();
  };

  return (
    <div className={cn("network-card", { able: onClick })} onClick={() => cardClickHandler()}>
      <div className="network-id-wrapper">
        <div className="network-id-title">ID</div>
        <div className="network-id">{networkInfo?.id ?? ""}</div>
      </div>
      <div className="network-info">
        <div className="network-name">{networkInfo?.label ?? "-"}</div>
        <div className="network-coin-wrapper">
          <img className="network-coin-icon" src={networkInfo?.currency?.image} alt="" />
          <div className="network-coin-text">{networkInfo?.currency?.name ?? "-"}</div>
        </div>
      </div>
      <div className="network-type-info">
        {networkInfo?.isTestnet && (
          <div className="network-type-wrapper">
            <img src={mainnetIcon} alt="" className="network-type-icon" />
            <div className="network-type-text">{NetworkType.TESTNET}</div>
          </div>
        )}
        {!networkInfo?.isTestnet && (
          <div className="network-type-wrapper">
            <img src={mainnetIcon} alt="" className="network-type-icon" />
            <div className="network-type-text">{NetworkType.MAINNET}</div>
          </div>
        )}
        {networkInfo?.isContractUploadAvailability && (
          <div className="network-type-wrapper">
            <img src={publicIcon} alt="" className="network-type-icon" />
            <div className="network-type-text">{NetworkAccessType.PUBLIC}</div>
          </div>
        )}
        {!networkInfo?.isContractUploadAvailability && (
          <div className="network-type-wrapper">
            <img src={privateIcon} alt="" className="network-type-icon" />
            <div className="network-type-text">{NetworkAccessType.PRIVATE}</div>
          </div>
        )}
      </div>
      <div className="network-node-info">
        <div className="network-node-wrapper">
          <div className="network-node-label">Consensus Node</div>
          <div className="network-node-info-wrapper">
            <img src={consensusNodeIcon} alt="" className="network-node-icon" />
            <div className="network-node-text">{equalizerCount ?? "-"}</div>
          </div>
        </div>
        <div className="node-divide-line" />
        <div className="network-node-wrapper">
          <div className="network-node-label">Full Node</div>
          <div className="network-node-info-wrapper">
            <img src={fullNodeIcon} alt="" className="network-node-icon" />
            <div className="network-node-text">{lightNodeCount ?? "-"}</div>
          </div>
        </div>
      </div>
      <div className="network-status-info">
        <div className="network-status-info-wrapper">
          <div className="network-status-wrapper">
            <div className="network-status-label">Status</div>
            <div className="network-status-value">{currentNetworkStatus?.label ?? "-"}</div>
          </div>
          <div className="network-status-step-wrapper">
            <div className="network-status" style={{ background: isStatusActive(1) }} />
            <div className="network-status" style={{ background: isStatusActive(2) }} />
            <div className="network-status" style={{ background: isStatusActive(3) }} />
            <div className="network-status" style={{ background: isStatusActive(4) }} />
            <div className="network-status" style={{ background: isStatusActive(5) }} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default NetworkCard;
