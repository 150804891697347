import React, { useState } from "react";
import ButtonContainer from "view/components/_atom/container/ButtonContainer";
import css from "./JoinProject.scss";
import BasicButton from "../../../../components/_atom/button/BasicButton";
import { ButtonSize, ButtonTheme } from "view/components/_atom/button/StyledButton";
import { useNavigate } from "react-router-dom";
import IconProject from "../../../dashboard/assets/Icon_dashboard_project_info.png";
import plusIcon from "../../../../assets/images/icon_plus.png";
import checkIcon from "../../../../assets/images/icon_check.png";
import { CommonTimestamp } from "utils/TimeUtils";
import inviteProjectImage from "../assets/img_invite_project.png";
import { acceptInvitationAction } from "action/projectAction";
import { errorAlert } from "utils/Utils";
import { toast } from "react-hot-toast";
import useIsAble from "../../../../../hooks/useIsAble";
import { bindStyle } from "view/styles/classNames";

type JoinProjectProps = {
  invitedList: Array<any>;
};

const JoinProject = (props: JoinProjectProps) => {
  const { invitedList } = props;
  const cn = bindStyle(css);

  const navigate = useNavigate();

  const [selectedProjectList, setSelectedProjectList] = useState<Array<any>>([]);

  const isAble = useIsAble([selectedProjectList.length !== 0]);

  const addProject = (project: any) => {
    setSelectedProjectList((prevState: any) => {
      return prevState.concat(project);
    });
  };

  const removeProject = (project: any) => {
    setSelectedProjectList((prevState: any) => {
      return prevState.filter((el: any) => el.id !== project.id);
    });
  };

  const joinProject_ = async () => {
    const invitationIds = selectedProjectList.map((el) => el.id);
    const { result, error } = await acceptInvitationAction(invitationIds);

    if (result) {
      toast.success("You have successfully participated in the project.");
      navigate("/");
    }

    if (error) {
      if (error.data.serverStatus === 20) {
        errorAlert(error.data.data);
        navigate("/");
        return;
      }
      errorAlert(error.data.message);
    }
  };

  return (
    <div id={"JoinProject"}>
      <div className="join-project-wrapper">
        <div className="join-project-header">
          <span className="join-project-header-title">Join the Invited projects</span>
        </div>
        <div className="join-project-selected">
          <div className="join-project-selected-notice">
            <p>
              You have received invitations from <span className="join-project-selected-notice-count">{invitedList.length}</span> projects currently.
            </p>
            <p>Please join the project you prefer.</p>
          </div>
          <div className="join-project-selected-list">
            <p className="join-project-selected-list-label">These are the projects you're going to join.</p>
            <div className="join-project-selected-list-wrapper">
              {selectedProjectList.map((project: any) => (
                <div className="join-project-selected-list-item" key={`selected-project-item-${project.id}`}>
                  {project.project.name}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="join-project-label">
          <div className="join-project-label-icon-box">
            <img src={IconProject} className="join-project-label-icon-box-img" alt={""} />
          </div>
          <span className="join-project-label-text">Please select a project to join</span>
        </div>
        <div className="join-project-invited-project-list-container">
          {invitedList.map((invitedProject) => (
            <div
              className={cn("invited-project-box", { selected: selectedProjectList.find((state: any) => state.id === invitedProject.id) })}
              key={`invited-project-${invitedProject.id}`}
            >
              <div className="invited-project-box-info">
                <div className="invited-project-box-info-header">
                  <div className="invited-project-box-info-header-image">
                    <img src={invitedProject.project.image} className="invited-project-box-info-header-image-src" alt="project" />
                  </div>
                  <div className="invited-project-box-info-header-detail">
                    <p className="invited-project-box-info-header-detail-name">{invitedProject.project.name}</p>
                    <div className="invited-project-box-info-header-detail-owner">
                      <span className="invited-project-box-info-header-detail-owner-label">Owned by</span>
                      <div className="invited-project-box-info-header-detail-owner-wrapper">
                        <div className="invited-project-box-info-header-detail-owner-image">
                          <img src={invitedProject.project.owner.image} className="invited-project-box-info-header-detail-owner-image-src" alt="" />
                        </div>
                        <div className="invited-project-box-info-header-detail-owner-name">{`${invitedProject.project.owner.firstName} ${invitedProject.project.owner.lastName}`}</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="invited-project-box-info-description">{invitedProject.project.description}</div>
                <div className="invited-project-box-info-expiration-date">{`Valid until ${CommonTimestamp(invitedProject.expireTime)}`}</div>
              </div>
              <div className="invited-project-box-button-wrapper">
                {!selectedProjectList.find((state: any) => state.id === invitedProject.id) && (
                  <BasicButton size={ButtonSize.FULL} onClick={() => addProject(invitedProject)}>
                    <div className="invited-project-box-button-label">
                      <img src={plusIcon} className="invited-project-box-button-label-icon" alt="" />
                      Click to join
                    </div>
                  </BasicButton>
                )}
                {selectedProjectList.find((state: any) => state.id === invitedProject.id) && (
                  <BasicButton theme={ButtonTheme.SECONDARY} size={ButtonSize.FULL} onClick={() => removeProject(invitedProject)}>
                    <div className="invited-project-box-button-label">
                      <img src={checkIcon} className="invited-project-box-button-label-icon" alt="" />
                      Added to list
                    </div>
                  </BasicButton>
                )}
              </div>
            </div>
          ))}
        </div>
        <div className="join-project-button-wrapper">
          <ButtonContainer>
            <BasicButton theme={ButtonTheme.SECONDARY} size={ButtonSize.LARGE} onClick={() => navigate("/manage-project")}>{`Back`}</BasicButton>
            <BasicButton disabled={!isAble} size={ButtonSize.LARGE} onClick={() => joinProject_()}>{`Join`}</BasicButton>
          </ButtonContainer>
        </div>
      </div>
      <div className="join-project-background">
        <div className="join-project-background-image">
          <img src={inviteProjectImage} className="join-project-background-image-src" alt="" />
        </div>
      </div>
    </div>
  );
};

export default JoinProject;
