import { useNavigate, useParams } from "react-router-dom";
import BasicButton from "view/components/_atom/button/BasicButton";
import ButtonContainer from "view/components/_atom/container/ButtonContainer";
import { ButtonSize, ButtonTheme } from "view/components/_atom/button/StyledButton";
import toast from "react-hot-toast";
import { errorAlert } from "utils/Utils";
import { registerTokenToWalletAction } from "../../../../../action/tokenAction";
import { ManageFieldContainer, ManageFieldLabel, RowManageField } from "view/components/_atom/section/ManageField";
import tokenIcon from "../../../../assets/images/service/Icon_SNB_token.png";

const ManageTokenAfterDeploy = (props) => {
  const { tokenDetail, getTokenDetail_ } = props || {};
  const navigate = useNavigate();
  const { id: currencyId } = useParams();

  //========= Register Token in Wallet =========//
  const requestUsingEQWallet = async () => {
    const { result, error } = await registerTokenToWalletAction(parseInt(currencyId));

    if (result) {
      toast.success("Success to register token in EQ wallet");
      await getTokenDetail_();
    }

    if (error) {
      errorAlert(error.data.message);
      return;
    }
  };

  return (
    <>
      <ManageFieldContainer icon={tokenIcon} title="Application">
        <RowManageField>
          <ManageFieldLabel label="EQ wallet" />
          <>
            {tokenDetail?.currencyDetail?.useWallet ? (
              <p style={{ color: "#5888FF" }}>Registered</p>
            ) : (
              <BasicButton onClick={() => requestUsingEQWallet()}>Register in EQ Wallet</BasicButton>
            )}
          </>
        </RowManageField>
      </ManageFieldContainer>
      <div className="flex justify-content-flex-end">
        <ButtonContainer>
          {tokenDetail.currencyDetail.mintable && (
            <BasicButton size={ButtonSize.LARGE} onClick={() => navigate("mint")}>
              Mint
            </BasicButton>
          )}
          {tokenDetail.currencyDetail.burnable && (
            <BasicButton size={ButtonSize.LARGE} theme={ButtonTheme.MINOR} onClick={() => navigate("burn")}>
              Burn
            </BasicButton>
          )}
        </ButtonContainer>
      </div>
    </>
  );
};

export default ManageTokenAfterDeploy;
