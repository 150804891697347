import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { selectAllSolution, selectAllStandard } from "reducer/presetReducer";
import SelectBox from "view/components/_atom/select/SelectBox";
import share from "../../../../assets/images/icon_share.png";
import "./ContractDetailInfo.scss";

const ContractDetailInfo = ({
  navigateToGetContract,
  labelKey,
  valueKey,
  options,
  select,
  contractDetail,
  versionDetail,
  selectedValue,
  setSelectedValue,
  button,
  placeholder,
}) => {
  const location = useLocation();
  const solutions = useSelector(selectAllSolution);
  const standards = useSelector(selectAllStandard);

  const onClickShareBtn = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      toast.success("Copied");
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="contract-info">
      <div className="contract-info-left">
        <div className="contract-info-left-top">
          <div className="contract-info-title">{contractDetail.title}</div>
          <div className="contract-info-description">{contractDetail.description}</div>
          <div className="contract-info-detail">
            <div className="contract-info-detail-box">
              <div>Made by :</div>
              <div className="contract-info-detail-data">{contractDetail.name}</div>
            </div>
            <div className="contract-info-detail-box">
              <div>Total Deployed :</div>
              <div className="contract-info-detail-data">{contractDetail.deploy_count}</div>
            </div>
          </div>
        </div>
        <div className="contract-info-left-mid">
          <div className="contract-info-version-box">
            <div className="contract-info-sub-title">Version</div>
            {select ? (
              <SelectBox
                labelKey={labelKey}
                valueKey={valueKey}
                options={options}
                setSelectedValue={setSelectedValue}
                selectedValue={selectedValue}
                placeholder={placeholder}
              />
            ) : (
              <div className="contract-info-version">{versionDetail.version}</div>
            )}
            <div className="contract-info-version-description">{versionDetail.version_description}</div>
          </div>
        </div>
        <div className="contract-info-left-btm">
          <div className="contract-info-type">
            <div className="contract-info-sub-title">Standard</div>
            <div className="contract-info-type-box">
              {contractDetail &&
                contractDetail.contractCategories?.map((ctg, index) => {
                  const correspondStandard = standards?.find((stan) => stan.category_id === ctg.category_id);
                  return (
                    <div
                      key={`cartegory-${index}`}
                      className="type-standard"
                      style={{
                        color: correspondStandard?.font_color,
                        backgroundColor: correspondStandard?.background_color,
                      }}
                    >
                      {correspondStandard?.name}
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="contract-info-type">
            <div className="contract-info-sub-title">Related Solution</div>
            <div className="contract-info-type-box">
              {contractDetail &&
                contractDetail.contractTags?.map((tag, index) => {
                  const correspondSolution = solutions?.find((sol) => sol.tag_id === tag.tag_id);
                  return (
                    <div key={`solution-${index}`} className="type-solution">
                      {correspondSolution?.name}
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
      <div className="contract-info-right">
        <div className="contract-info-right-img-box">
          <img src={contractDetail.image} alt="" />
        </div>
        {button ? (
          <div className="contract-info-right-btns">
            <div
              className="contract-info-right-btn-share"
              onClick={() => onClickShareBtn(`${process.env.REACT_APP_HUB_BASE_URL}${location.pathname}`)}
            >
              <img src={share} alt="" />
            </div>
            <div className="contract-info-right-btn-get" onClick={navigateToGetContract}>
              Get Contract
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ContractDetailInfo;
