import { useEffect, useState } from "react";
import ModalTemplateA from "../../../../../../legacy-common/template/modal/ModalTemplateA";
import { errorAlert } from "../../../../../../utils/Utils";
import { AIRDROP_PARTICIPANT_LIMIT_PERIOD_LIST } from "../../constants/airdropConstants";
import "./AirdropParticipantDetailModal.scss";
import { getAirdropLimitParticipantDetailByAddressAction } from "action/airdropAction";
import BasicButton from "view/components/_atom/button/BasicButton";
import { ButtonSize } from "view/components/_atom/button/StyledButton";
import { ButtonTheme } from "view/components/_atom/button/StyledButton";

const AirdropParticipantDetailModal = (props) => {
  const { isOpen, closeModal, selectedParticipantAddress, participationConditionLimit, airdropId } = props || {};

  const [participationData, setParticipationData] = useState();
  const getAirdropLimitParticipantDetailByAddress_ = async () => {
    const { result, error } = await getAirdropLimitParticipantDetailByAddressAction(airdropId, selectedParticipantAddress);
    if (result) {
      setParticipationData(result);
    }
    if (error) {
      errorAlert(error.data.message);
    }
  };

  useEffect(() => {
    getAirdropLimitParticipantDetailByAddress_();
  }, []);

  return (
    <ModalTemplateA title="Participant detail" isOpen={isOpen} closeModal={closeModal}>
      <section className="airdrop-participant-detail-modal">
        <div className="airdrop-participant-detail-modal-information">
          <div className="airdrop-participant-detail-modal-information-row">
            <div className="airdrop-participant-detail-modal-information-row-label">Total number of user participations</div>
            <div className="airdrop-participant-detail-modal-information-row-value">
              <p>{`${participationData?.totalParticipations ?? "-"}`}</p>
            </div>
          </div>
          <div className="airdrop-participant-detail-modal-information-row">
            <div className="airdrop-participant-detail-modal-information-row-label">
              <p>
                <span>{`Total number of user participations `}</span>
                <span className="highlight-text" style={{ textDecoration: "underline" }}>
                  {AIRDROP_PARTICIPANT_LIMIT_PERIOD_LIST.find((el) => el.value === participationConditionLimit.period).label}
                </span>
              </p>
            </div>
            <div className="airdrop-participant-detail-modal-information-row-value">
              <p>{`${participationData?.participationsByPeriod ?? "-"}`}</p>
            </div>
          </div>
        </div>
        <div className="airdrop-participant-detail-modal-button-container">
          <BasicButton size={ButtonSize.LARGE} theme={ButtonTheme.SECONDARY} onClick={closeModal}>
            Close
          </BasicButton>
        </div>
      </section>
    </ModalTemplateA>
  );
};

export default AirdropParticipantDetailModal;
