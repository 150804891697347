import React from "react";
import "./AirdropOperatingWaySetting.scss";
import RadioButton from "../../../../../../legacy-common/atom/button/RadioButton";
import { NUMBER_REG_EXP } from "../../../../../../constants/commonConstants";
import { addComma } from "../../../../../../utils/Utils";
import AirdropConditionEventList from "../molecule/AirdropConditionEventList";
import AirdropConditionLimitSelector from "../molecule/AirdropConditionLimitSelector";
import {
  VALIDATION__AIRDROP_events_result,
  VALIDATION__AIRDROP_events_title,
  VALIDATION__AIRDROP_fixed_period_of_time,
  VALIDATION__AIRDROP_limit_participation_count,
  VALIDATION__AIRDROP_limit_participation_time,
  VALIDATION__AIRDROP_reward_fixed_amount,
} from "../../validation/airdropValidation";
import BasicButton from "view/components/_atom/button/BasicButton";
import { ButtonSize, ButtonTheme } from "view/components/_atom/button/StyledButton";
import ButtonContainer from "view/components/_atom/container/ButtonContainer";
import useValidateCondition from "hooks/useValidateCondition";
import useIsAble from "hooks/useIsAble";
import CommentInput from "view/components/_atom/input/CommentInput";
import BasicInput from "view/components/_atom/input/BasicInput";
import moment from "moment";
import { FieldContainer, FieldLabel, RowField } from "view/components/_atom/section/Field";
import airdropIcon from "../../../../../assets/images/service/Icon_SNB_airdrop.png";

const DISTRIBUTION_TYPE_FIXED = 1;
const PROJECT_OPERATION_PERIOD_NO_TIME_LIMIT = 2;
const PARTICIPANT_CONDITION_TYPE_LIMIT = 1;
const PARTICIPANT_CONDITION_TYPE_EVENT = 2;
const AIRDROP_OPERATION_PURPOSE_FAUCET = 1;
const AIRDROP_OPERATION_PURPOSE_BOUNTY = 2;

const AirdropOperatingWaySetting = (props) => {
  const {
    onClickBackButton,
    onClickCreateButton,
    onClickSaveButton,
    airdropData,
    setAirdropData,
    participationPeriodTypeList,
    distributionTypeList,
    distributionAmountTypeList,
    participantConditionTypeList,
    distributionAmountsData,
    setDistributionAmountsData,
    participationLimitData,
    setParticipationLimitData,
    participationEventsData,
    setParticipationEventsData,
  } = props || {};

  const setDistributionAmount = (value) => {
    if (typeof value === "string" && value === "") {
      setDistributionAmountsData((prevState) => {
        const result = prevState.map((el) => {
          return { ...el, amount: null };
        });
        return result;
      });
      return;
    }
    if (!NUMBER_REG_EXP.test(value)) {
      return;
    }
    setDistributionAmountsData((prevState) => {
      const result = prevState.map((el) => {
        if (Boolean(value)) {
          return { ...el, amount: addComma(value) };
        } else {
          return { ...el, amount: null };
        }
      });
      return result;
    });
  };

  const fixedAmountValidation = useValidateCondition(
    [distributionAmountsData, airdropData.depositAmount],
    VALIDATION__AIRDROP_reward_fixed_amount(distributionAmountsData, airdropData.depositAmount)
  );
  const limitParticipationCountValidation = useValidateCondition(
    [participationLimitData.count],
    VALIDATION__AIRDROP_limit_participation_count(participationLimitData.count)
  );
  const limitParticipationTimeValidation = useValidateCondition(
    [participationLimitData.period],
    VALIDATION__AIRDROP_limit_participation_time(participationLimitData.period)
  );

  const fixedPeriodOfTimeValidation = useValidateCondition(
    [airdropData.participationStartDate, airdropData.participationEndDate],
    VALIDATION__AIRDROP_fixed_period_of_time(airdropData.participationStartDate, airdropData.participationEndDate)
  );
  const eventsTitleValidation = useValidateCondition([participationEventsData], VALIDATION__AIRDROP_events_title(participationEventsData));
  const eventsResultValidation = useValidateCondition([participationEventsData], VALIDATION__AIRDROP_events_result(participationEventsData));

  const isFaucetAble = useIsAble([fixedAmountValidation.isValid, limitParticipationCountValidation.isValid, limitParticipationTimeValidation.isValid]);
  const isBountyAble = useIsAble([
    fixedAmountValidation.isValid,
    fixedPeriodOfTimeValidation.isValid,
    eventsTitleValidation.isValid,
    eventsResultValidation.isValid,
  ]);

  return (
    <>
      {/* Reward Description :: Set what type and how much you want to offer as a reward for participation. */}
      <FieldContainer icon={airdropIcon} title="Reward">
        <RowField>
          <FieldLabel label="Type" />
          <div className="airdrop-operating-way-distribution-type">
            {distributionAmountTypeList.map((el) => {
              return (
                <RadioButton
                  key={`distribution-amount-type-${el.distributionAmountTypeId}`}
                  id={el.name}
                  onChange={(e) => setAirdropData(Number(e.target.value), "distributionAmountTypeId")}
                  value={el.distributionAmountTypeId}
                  isSelected={airdropData?.distributionAmountTypeId === el.distributionAmountTypeId}
                  label={el.name}
                  labelStyle={{ paddingRight: "0px" }}
                  disabled={true}
                />
              );
            })}
          </div>
        </RowField>
        <RowField>
          <FieldLabel label="Amount" />
          {airdropData?.distributionAmountTypeId === DISTRIBUTION_TYPE_FIXED && (
            <CommentInput
              {...fixedAmountValidation}
              value={distributionAmountsData[0].amount || ""}
              placeholder={"Enter the amount of currency to be paid to winner"}
              onChange={(e) => setDistributionAmount(e.target.value)}
              autoFocus
            />
          )}
        </RowField>
      </FieldContainer>

      {/* Payment Processing System Description :: Set whether you want to pay the winners automatically or manually. */}
      <FieldContainer icon={airdropIcon} title="Payment Processing System">
        <RowField>
          <FieldLabel label="System" />
          <div className="airdrop-operating-way-payment-processing-system">
            {distributionTypeList.map((el) => {
              return (
                <RadioButton
                  key={`distribution-type-${el.distributionTypeId}`}
                  id={el.name}
                  onChange={(e) => setAirdropData(Number(e.target.value), "distributionTypeId")}
                  value={el.distributionTypeId}
                  isSelected={airdropData?.distributionTypeId === el.distributionTypeId}
                  label={el.name}
                  disabled={true}
                  labelStyle={{ padding: "0 0 0 25px" }}
                />
              );
            })}
          </div>
        </RowField>
      </FieldContainer>

      {/* Project Operation Period Description :: Set how long you want to operate a project. */}
      <FieldContainer icon={airdropIcon} title="Project Operation Period">
        <RowField>
          <FieldLabel label="Type" />
          <div className="airdrop-operating-way-period-type">
            {participationPeriodTypeList.map((el) => {
              return (
                <RadioButton
                  key={`participant-period-type-${el.participationPeriodTypeId}`}
                  id={el.name}
                  onChange={(e) => setAirdropData(Number(e.target.value), "participationPeriodTypeId")}
                  value={el.participationPeriodTypeId}
                  isSelected={airdropData?.participationPeriodTypeId === el.participationPeriodTypeId}
                  label={el.name}
                  disabled={true}
                  labelStyle={{ padding: "0 0 0 25px" }}
                />
              );
            })}
          </div>
        </RowField>
        <RowField>
          <FieldLabel label="Date" />
          <div className="airdrop-operating-way-period-date">
            <BasicInput
              type={"datetime-local"}
              onChange={(e) => setAirdropData(e.target.value, "participationStartDate")}
              value={airdropData.participationStartDate ?? ""}
              disabled={airdropData.participationPeriodTypeId === PROJECT_OPERATION_PERIOD_NO_TIME_LIMIT ? true : false}
              min={moment(new Date()).format("YYYY-MM-DDTHH:mm")}
              max={airdropData.participationEndDate}
            />
            <span>~</span>
            <BasicInput
              type={"datetime-local"}
              onChange={(e) => setAirdropData(e.target.value, "participationEndDate")}
              value={airdropData.participationEndDate ?? ""}
              disabled={airdropData.participationPeriodTypeId === PROJECT_OPERATION_PERIOD_NO_TIME_LIMIT ? true : false}
              min={airdropData.participationStartDate ?? moment(new Date()).format("YYYY-MM-DDTHH:mm")}
            />
          </div>
        </RowField>
      </FieldContainer>

      {/* Conditions of Participation Description :: Set the condition for users to participate in the project. */}
      <FieldContainer icon={airdropIcon} title="Conditions of Participation">
        <RowField>
          <FieldLabel label="Type" />
          <div style={{ width: "100%" }}>
            <p style={{ marginBottom: "20px" }}>
              {participantConditionTypeList.find((el) => el.participationConditionTypeId === airdropData.participationConditionTypeId)?.name}
            </p>
            {airdropData?.participationConditionTypeId === PARTICIPANT_CONDITION_TYPE_LIMIT && (
              <AirdropConditionLimitSelector participationLimitData={participationLimitData} setParticipationLimitData={setParticipationLimitData} />
            )}
            {airdropData?.participationConditionTypeId === PARTICIPANT_CONDITION_TYPE_EVENT && (
              <AirdropConditionEventList participationEventsData={participationEventsData} setParticipationEventsData={setParticipationEventsData} />
            )}
          </div>
        </RowField>
      </FieldContainer>

      <div className="flex justify-content-flex-end">
        <ButtonContainer>
          <BasicButton size={ButtonSize.LARGE} theme={ButtonTheme.SECONDARY} onClick={() => onClickBackButton()}>
            Back
          </BasicButton>
          {onClickCreateButton && (
            <BasicButton
              size={ButtonSize.LARGE}
              disabled={airdropData.operationPurpose === AIRDROP_OPERATION_PURPOSE_BOUNTY ? !isBountyAble : !isFaucetAble}
              onClick={() => onClickCreateButton()}
            >
              Create
            </BasicButton>
          )}
          {onClickSaveButton && (
            <BasicButton
              size={ButtonSize.LARGE}
              disabled={airdropData.operationPurpose === AIRDROP_OPERATION_PURPOSE_BOUNTY ? !isBountyAble : !isFaucetAble}
              onClick={() => onClickSaveButton()}
            >
              Save
            </BasicButton>
          )}
        </ButtonContainer>
      </div>
    </>
  );
};

export default AirdropOperatingWaySetting;
