import React from "react";
import { bindStyle } from "view/styles/classNames";
import css from "./NetworkTypeLabel.scss";
import mainnetIcon from "../../../assets/images/icon-endpoint-mainnet-white.svg";
import testnetIcon from "../../../assets/images/icon-endpoint-testnet-white.svg";

function NetworkTypeLabel(props) {
  const { isTestnet } = props;
  const cn = bindStyle(css);

  const networkTypeIcon = (isTestnet: boolean): string => {
    return isTestnet ? testnetIcon : mainnetIcon;
  };

  const networkTypeLabel = (isTestnet: boolean): string => {
    return isTestnet ? "Testnet" : "Mainnet";
  };

  return (
    <div className={cn("end-point-label-wrapper", { mainnet: !isTestnet, testnet: isTestnet })}>
      <img src={networkTypeIcon(isTestnet)} alt="icon" className="end-point-label-icon" />
      <span className="end-point-label">{networkTypeLabel(isTestnet)}</span>
    </div>
  );
}

export default NetworkTypeLabel;
