import RadioButton from '../../atom/button/RadioButton';
import './RadioButtons.scss';

const RadioButtons = ({ options, selectedValue, onChange, readOnly, disabled }) => {
  const onChangeTypeHandler = (options, value) => {
    switch (typeof options[0].value) {
      case 'number':
        return parseInt(value);
      default:
        return value;
    }
  };

  return (
    <div className="radio-buttons">
      {readOnly ? (
        <p style={{ color: '#3F4248' }}>{options.find((el) => el.value === selectedValue)?.label}</p>
      ) : (
        <div className={'radio-button-container'}>
          {options.map((el, key) => {
            if (!el) {
              return null;
            }
            return (
              <div key={key}>
                <RadioButton
                  id={el.id ? el.id : el.label}
                  onChange={(e) => onChange(onChangeTypeHandler(options, e.target.value))}
                  value={el.value}
                  isSelected={selectedValue === el.value}
                  label={el.label}
                  disabled={disabled}
                />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default RadioButtons;
