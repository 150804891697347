import { forwardRef } from "react";
import Text from "../../../../components/_atom/text/Text";
import "./NotificationPanel.scss";
import NotificationContainer from "./NotificationContainer";
import { errorAlert } from "../../../../../utils/Utils";
import { readAllNotificationAction } from "../../../../../action/notificationAction";

const NotificationPanel = forwardRef((props, ref) => {
  const { notifications, getNotifications_, setNotificationPanelOpen } = props;
  const clickReadAll = async () => {
    const result = await requestReadAllNotification();
    if (result) {
      await getNotifications_();
    }
  };

  const requestReadAllNotification = async () => {
    const { error, result } = await readAllNotificationAction();
    if (error) {
      errorAlert(error.data.message);
    } else {
      return result;
    }
  };

  return (
    <div className="notification-panel" ref={ref}>
      <div className="notification-panel-header">
        <Text text={"Notifications"} className="TT__section-title TC__black" />
        {notifications.length !== 0 && (
          <Text
            text={"Mark as read"}
            className={"TT__section-description TC__black"}
            onClick={() => clickReadAll()}
            style={{ fontWeight: 500, textDecoration: "underline", cursor: "pointer" }}
          />
        )}
      </div>
      <NotificationContainer setNotificationPanelOpen={setNotificationPanelOpen} notifications={notifications} getNotifications_={getNotifications_} />
    </div>
  );
});

export default NotificationPanel;
