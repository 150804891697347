import React, { useState } from "react";
import "./NetworkBasicInfoField.scss";
import { NUMBER_REG_EXP } from "constants/commonConstants";
import { addComma } from "utils/Utils";
import CommentInput from "view/components/_atom/input/CommentInput";
import { ColumnField, FieldContainer, FieldLabel } from "view/components/_atom/section/Field";
import { NETWORK_ACCESS_STATUS, NETWORK_ENVIRONMENT_LIST } from "../../constants/networkConstants";
import RadioButton from "legacy-common/atom/button/RadioButton";
import CurrencyImageUploadModal from "view/components/_modal/CurrencyImageUploadModal";
import { Validation } from "reducer/clientType/commonClientType";
import networkIcon from "view/assets/images/service/Icon_SNB_network.png";
import CoinImageSelector from "../atom/CoinImageSelector";
import {
  NETWORK_ANNOUNCEMENT_ACCESSIBILITY,
  NETWORK_ANNOUNCEMENT_ENVIRONMENT,
  NETWORK_ANNOUNCEMENT_INITIAL_SUPPLY,
  NETWORK_ANNOUNCEMENT_SYMBOL,
} from "../../constants/network.data";

type NetworkBasicInfoFieldProps = {
  networkData: any;
  setNetworkData: React.Dispatch<React.SetStateAction<any>>;
  networkNameValidation: Validation;
  coinNameValidation: Validation;
  coinSymbolValidation: Validation;
  coinImageValidation: Validation;
  initialSupplyValidation: Validation;
};

const NetworkBasicInfoField = (props: NetworkBasicInfoFieldProps) => {
  const { networkData, setNetworkData, networkNameValidation, coinNameValidation, coinSymbolValidation, coinImageValidation, initialSupplyValidation } = props;

  const [coinImageModalOpen, setCoinImageModalOpen] = useState<Boolean>(false);

  const setNetworkName = (name: string) => {
    setNetworkData((prevState) => ({
      ...prevState,
      microChain: {
        ...prevState.microChain,
        label: name,
      },
      network: {
        ...prevState.network,
        label: name,
      },
    }));
  };

  const setEnvironmentType = (value: string) => {
    setNetworkData((prevState) => ({
      ...prevState,
      microChainSetting: {
        ...prevState.microChainSetting,
        isTestnet: JSON.parse(value),
      },
    }));
  };

  const setNetworkAccessType = (value: string) => {
    setNetworkData((prevState) => ({
      ...prevState,
      microChainSetting: {
        ...prevState.microChainSetting,
        isContractUploadAvailability: JSON.parse(value),
        isNodeParticipationAvailability: JSON.parse(value),
      },
    }));
  };

  const setCoinName = (name: string) => {
    setNetworkData((prevState) => ({
      ...prevState,
      currency: {
        ...prevState.currency,
        name,
      },
    }));
  };

  const setCoinSymbol = (symbol: string) => {
    setNetworkData((prevState) => ({
      ...prevState,
      currency: {
        ...prevState.currency,
        symbol: symbol.toUpperCase(),
      },
    }));
  };

  const setCoinImage = (image: File | string) => {
    setNetworkData((prevState) => ({
      ...prevState,
      currency: {
        ...prevState.currency,
        image,
      },
    }));
  };
  const setCoinInitialSupply = (initialSupply: string) => {
    if (initialSupply === "") {
      setNetworkData((prevState) => ({
        ...prevState,
        microChainSetting: {
          ...prevState.microChainSetting,
          initialSupply: null,
        },
      }));
      return;
    }

    if (!NUMBER_REG_EXP.test(initialSupply)) {
      return;
    }

    const parsedInitialSupply = addComma(initialSupply);

    setNetworkData((prevState) => ({
      ...prevState,
      microChainSetting: {
        ...prevState.microChainSetting,
        initialSupply: parsedInitialSupply,
      },
    }));
  };

  return (
    <article>
      {coinImageModalOpen && (
        <CurrencyImageUploadModal
          title={"Select Coin Image"}
          currencyImage={networkData?.currency?.image}
          setCurrencyImage={setCoinImage}
          currencyImageModalOpen={coinImageModalOpen}
          setCurrencyImageModalOpen={setCoinImageModalOpen}
        />
      )}
      <FieldContainer icon={networkIcon} title={"Basic Info"}>
        <ColumnField>
          <FieldLabel label={"Network Name"} />
          <CommentInput
            {...networkNameValidation}
            type="text"
            value={networkData?.network?.label || ""}
            onChange={(e) => setNetworkName(e.target.value)}
            placeholder={"Ex) EQHub"}
            autoFocus
          />
        </ColumnField>
        <ColumnField>
          <FieldLabel label={"Coin Name"} />
          <CommentInput
            {...coinNameValidation}
            type={"text"}
            onChange={(e) => setCoinName(e.target.value)}
            value={networkData?.currency?.name ?? ""}
            placeholder={"Ex) EQHub Coin"}
          />
        </ColumnField>
        <ColumnField>
          <FieldLabel label={"Coin Symbol"} announcement={NETWORK_ANNOUNCEMENT_SYMBOL} />
          <CommentInput
            {...coinSymbolValidation}
            type={"text"}
            onChange={(e) => setCoinSymbol(e.target.value)}
            value={networkData?.currency?.symbol ?? ""}
            placeholder={"Ex) EQH"}
          />
        </ColumnField>
        <ColumnField>
          <FieldLabel label={"Coin Image"} />
          <CoinImageSelector
            image={networkData?.currency?.image ?? null}
            setImage={(image) => setCoinImage(image)}
            onClick={() => setCoinImageModalOpen(true)}
          />
        </ColumnField>
        <ColumnField>
          <FieldLabel label={"Initial Supply"} announcement={NETWORK_ANNOUNCEMENT_INITIAL_SUPPLY} />
          <CommentInput
            {...initialSupplyValidation}
            type={"text"}
            onChange={(e) => setCoinInitialSupply(e.target.value)}
            value={networkData?.microChainSetting?.initialSupply ?? ""}
            placeholder={"Ex) 1,000,000,000"}
            maxLength={55}
            // onWheel={(event) => {
            //   event.currentTarget.blur();
            // }}
          />
        </ColumnField>
      </FieldContainer>

      <FieldContainer icon={networkIcon} title={"Operation"}>
        <ColumnField>
          <FieldLabel label={"Environment"} announcement={NETWORK_ANNOUNCEMENT_ENVIRONMENT} />
          <div className="flex align-items-center">
            {NETWORK_ENVIRONMENT_LIST.map((el) => {
              return (
                <RadioButton
                  key={el.id}
                  id={el.id ? el.id : el.label}
                  onChange={(e) => setEnvironmentType(e.target.value)}
                  value={el.value}
                  isSelected={networkData?.microChainSetting.isTestnet === el.value}
                  label={el.label}
                  labelStyle={{ padding: "0 0 0 25px", width: 130 }}
                />
              );
            })}
          </div>
        </ColumnField>
        <ColumnField>
          <FieldLabel label={"Accessibility"} announcement={NETWORK_ANNOUNCEMENT_ACCESSIBILITY} />
          <div className="flex align-items-center">
            {NETWORK_ACCESS_STATUS.map((el) => {
              return (
                <RadioButton
                  key={el.id}
                  id={el.id ? el.id : el.label}
                  onChange={(e) => setNetworkAccessType(e.target.value)}
                  value={el.value}
                  isSelected={networkData?.microChainSetting.isContractUploadAvailability === el.value}
                  label={el.label}
                  labelStyle={{ padding: "0 0 0 25px", width: 130 }}
                />
              );
            })}
          </div>
        </ColumnField>
      </FieldContainer>
    </article>
  );
};

export default NetworkBasicInfoField;
