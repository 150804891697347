export const ALL_MAIN_STATUS = [0, 1, 2, 3, 4, 5];
export const SYSTEM_CONTRACT = "0x0000000000000000000000000000000000000001";

// 정규식 표현
export const NUMBER_REG_EXP = /^[,0-9]+$/;
export const FLOAT_REG_EXP = /^[\0.0-9]+$/;
export const FIRST_DECIMAL_REG_EXP = /(^\d+$)|(^\d{1,2}\.\d{0,1})$/;
export const SECOND_DECIMAL_REG_EXP = /(^\d+$)|(^\d{1,2}\.\d{0,2})$/;
export const SYMBOL_REG_EXP = /^[A-Z\s]+$/;
export const URL_REG_EXP = /(http|ftp|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&amp;:/~\+#]*[\w\-\@?^=%&amp;/~\+#])?/;
export const ENG_CAPITAL_LETTERS_REG_EXP = /^[A-Z\s]+$/;

export const EMAIL_REG_EXP = /^[A-Za-z0-9_\.\-]+@[A-Za-z0-9\-]+\.[A-Za-z0-9\-]+/;
export const EQHUB_PASSWORD_REG_EXP = /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^\w\d\s:])([^\s]){0,}$/;

export const REPEAT_STR_NUM_REG_EXP = /(.)\1\1/;
export const CONTINUOUS_NUMBER_REG_EXP = /(012)|(123)|(234)|(345)|(456)|(567)|(678)|(789)|(890)/;

export const VERSION_REG_EXP = /^[\d.]+$/;
