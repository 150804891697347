import { useCallback, useEffect, useMemo, useState } from "react";
import "./ManageAirdropProjectManagementContainer.scss";
import Table from "../../../../../../legacy-common/atom/table/Table";
import detailButton from "../../../../../assets/images/icon-common-detail-button.png";
import { addComma, errorAlert, reduceAddress, removeComma } from "../../../../../../utils/Utils";
import PagingNumber from "../../../../../components/_atom/paging/PagingNumber";
import { CommonTimestamp } from "../../../../../../utils/TimeUtils";
import AirdropParticipantDetailModal from "../modal/AirdropParticipantDetailModal";
import { getAirdropParticipationsAction } from "action/airdropAction";
import Progressbar from "view/components/_atom/progressbar/Progressbar";
import { ManageFieldContainer, ManageFieldLabel, ManageFieldWrapper, RowManageField } from "view/components/_atom/section/ManageField";
import airdropIcon from "../../../../../assets/images/service/Icon_SNB_airdrop.png";
import DivideLine from "view/components/_atom/line/DivideLine";

const PARTICIPATION_HISTORY_LIMIT = 10;

const ManageAirdropProjectManagementContainer = (props) => {
  const { airdropId, airdropDetail } = props || {};

  const [page, setPage] = useState("1");
  const [totalHistoryCount, setTotalHistoryCount] = useState();
  const [historyList, setHistoryList] = useState([]);

  const [participantDetailModalOpen, setParticipantDetailModalOpen] = useState(false);
  const [selectedParticipantAddress, setSelectedParticipantAddress] = useState();

  const distributionAmountPercent = useMemo(() => {
    const depositAmount = Number(removeComma(airdropDetail?.depositAmount));
    const distributionAmount = airdropDetail?.estimatedDistributionAmount;
    return Number(((distributionAmount / depositAmount) * 100).toFixed(2));
  }, [airdropDetail]);

  const maximumPage = useMemo(() => {
    if (totalHistoryCount === 0) {
      return 1;
    }
    if (totalHistoryCount % PARTICIPATION_HISTORY_LIMIT) {
      return parseInt(totalHistoryCount / PARTICIPATION_HISTORY_LIMIT) + 1;
    } else {
      return parseInt(totalHistoryCount / PARTICIPATION_HISTORY_LIMIT);
    }
  }, [totalHistoryCount]);

  const participationHistoryColGroup = useMemo(() => {
    return [10, 35, 20, 20, 15].map((el) => ({ style: { width: `${el}%` } }));
  }, []);

  const participationHistoryHeaders = useMemo(
    () => [
      { title: "ID", accessor: "id" },
      { title: "Address", accessor: "address" },
      { title: "Date", accessor: "timestamp" },
      { title: "Amount", accessor: "amount" },
      { title: "Detail", accessor: "detail" },
    ],
    []
  );

  const participationHistoryRender = useCallback(
    (data) => ({
      id: data.participantId,
      address: reduceAddress(data.address),
      timestamp: CommonTimestamp(data.timestamp),
      amount: addComma(data.amount),
      detail: <img src={detailButton} className="participation-history-table-detail-icon" alt="" onClick={() => onClickParticipantDetail(data.address)} />,
    }),
    []
  );

  const participationConditionLimit = useMemo(() => {
    return airdropDetail?.participationConditionTypes?.find((el) => el.participationConditionTypeId === 1)?.limit;
  }, [airdropDetail]);

  const getAirdropParticipations_ = async (value = 1) => {
    if (typeof page === "string" && page === "") {
      return;
    }
    const offset = (Number(value) - 1) * PARTICIPATION_HISTORY_LIMIT;
    const { result, error } = await getAirdropParticipationsAction(airdropId, PARTICIPATION_HISTORY_LIMIT, offset);
    if (result) {
      const { count, rows } = result || {};
      setHistoryList(rows);
      setTotalHistoryCount(count);
    }
    if (error) {
      errorAlert(error.data.message);
    }
  };

  const onClickParticipantDetail = (id) => {
    setParticipantDetailModalOpen(true);
    setSelectedParticipantAddress(id);
  };

  useEffect(() => {
    getAirdropParticipations_(page);
  }, [page]);

  return (
    <>
      {participantDetailModalOpen && (
        <AirdropParticipantDetailModal
          isOpen={participantDetailModalOpen}
          closeModal={() => setParticipantDetailModalOpen(false)}
          selectedParticipantAddress={selectedParticipantAddress}
          participationConditionLimit={participationConditionLimit}
          airdropId={airdropId}
        />
      )}
      <ManageFieldContainer icon={airdropIcon} title="Project Management">
        <RowManageField>
          <ManageFieldLabel label="Received participations" />
          <div className="faucet-received-participations-container">
            <div className="faucet-received-participations-row">
              <div className="faucet-received-participations-row-value">
                <strong>{`${distributionAmountPercent}%`}</strong>
                <span className="faucet-received-participations-row-value-description" style={{ marginLeft: 5 }}>{`${
                  airdropDetail?.estimatedDistributionAmount ? addComma(airdropDetail?.estimatedDistributionAmount) : 0
                } / ${addComma(airdropDetail?.depositAmount)}`}</span>
              </div>
            </div>
            <div className="faucet-received-participations-row">
              <div className="faucet-received-participations-row-value" style={{ width: "100%" }}>
                <Progressbar percent={distributionAmountPercent} />
              </div>
            </div>
          </div>
        </RowManageField>
        <DivideLine style={{ margin: "10px 0" }} />
        <ManageFieldWrapper title="Participation History">
          <>
            <Table
              headers={participationHistoryHeaders}
              data={historyList}
              renderer={participationHistoryRender}
              colGroup={participationHistoryColGroup}
              noDataPlaceholder={"There are no participants in the airdrop"}
            />
            <PagingNumber page={page} setPage={setPage} maximumPage={maximumPage} getData={getAirdropParticipations_} />
          </>
        </ManageFieldWrapper>
      </ManageFieldContainer>
    </>
  );
};

export default ManageAirdropProjectManagementContainer;
