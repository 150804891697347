import {
  LibraryContract,
} from "../../../../../reducer/clientType/contractClientType";
import {
  FlattenVersion,
} from "./contract.type";
import {AbiItem} from "web3-utils";

export const flattenVersions = (
  acc: Array<FlattenVersion>,
  cur: LibraryContract
): Array<FlattenVersion> => {
  const parseVersion = cur.contractVersions.map((version) => {
    return {
      ...version,
      title: cur.title,
      type: cur.type,
    };
  });
  return acc.concat(...parseVersion);
}

export const unionABI = (ABIArray: Array<AbiItem | AbiItem[]>) => {
  return ABIArray.reduce((acc: Array<AbiItem>, cur) => {
   if(Array.isArray(cur)){
     return acc.concat(...cur)
   }
   return acc.concat(cur);
  }, [])
}

export const generateABiFile = (ABI: AbiItem | AbiItem[], name: string): File => {
  const abiBlob = new Blob([`${JSON.stringify(ABI)}`])
  return new File([abiBlob], `${name}_custom.txt`)
}
