import React, { useState } from "react";
import "./InviteMemberModal.scss";
import ModalTemplateB from "../../../components/_template/modal/ModalTemplateB";
import ButtonContainer from "view/components/_atom/container/ButtonContainer";
import BasicButton from "view/components/_atom/button/BasicButton";
import { ButtonSize, ButtonTheme } from "view/components/_atom/button/StyledButton";
import { inviteProjectMemberAction } from "../../../../action/projectAction";
import { errorAlert } from "utils/Utils";
import { toast } from "react-hot-toast";
import { ProjectInvitation } from "reducer/clientType/projectClientType";
import CommentInput from "../../../components/_atom/input/CommentInput";
import useIsAble from "../../../../hooks/useIsAble";
import useValidateCondition from "../../../../hooks/useValidateCondition";
import { VALIDATION__PROJECT_invite_member_email } from "view/service/dashboard/validation/projectValidation";

type InviteMemberModalProps = {
  inviteMemberModalOpen: boolean;
  setInviteMemberModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setInvitationList: React.Dispatch<React.SetStateAction<Array<ProjectInvitation>>>;
};

const InviteMemberModal = (props: InviteMemberModalProps) => {
  const { inviteMemberModalOpen, setInviteMemberModalOpen, setInvitationList } = props;

  const [email, setEmail] = useState<string>("");

  const emailValidation = useValidateCondition([email], VALIDATION__PROJECT_invite_member_email(email));

  const isAble = useIsAble([emailValidation.isValid]);

  const inviteMember_ = async () => {
    const { result, error } = await inviteProjectMemberAction(email);

    if (result) {
      setInvitationList(result);
      setInviteMemberModalOpen(false);
      toast.success("member invitation request has been sent successfully");
    }

    if (error) {
      errorAlert(error.data.message);
    }
  };

  return (
    <ModalTemplateB isOpen={inviteMemberModalOpen} onRequestClose={() => setInviteMemberModalOpen(false)} title={"Invite member"}>
      <div className="invite-member-modal">
        <div className="invite-member-modal-row">
          <div className="invite-member-modal-row-label">
            <div className="invite-member-modal-row-label-mark" />
            <span className="invite-member-modal-row-label-text">{`Email`}</span>
          </div>
          <CommentInput {...emailValidation} value={email} placeholder={"Enter the email address"} onChange={(e) => setEmail(e.target.value)} />
        </div>
        <div className="invite-member-modal-description">
          {`An invitation email is sent to the person you are inviting.\nThe invitation must be accepted by the invited user via email before completing the invitation.\nInvited users must be the user of the EQ Hub before they can work on the project.\nInvitation mail is valid for 7 days`}
        </div>
        <ButtonContainer>
          <BasicButton theme={ButtonTheme.SECONDARY} size={ButtonSize.LARGE} onClick={() => setInviteMemberModalOpen(false)}>
            Close
          </BasicButton>
          <BasicButton disabled={!isAble} size={ButtonSize.LARGE} onClick={() => inviteMember_()}>
            Invite
          </BasicButton>
        </ButtonContainer>
      </div>
    </ModalTemplateB>
  );
};

export default InviteMemberModal;
