import { v1ServerByProjectAuth } from "server/index/serverInstances";
import { makeQuery } from "server/index/utils";
import {
  createTokenKitRequest,
  createTokenKitResponse,
  getDefaultImageForTokenKitRequest,
  getDefaultImageForTokenKitResponse,
  getMyPublicTokenKitsRequest,
  getMyPublicTokenKitsResponse,
  getMyTokenKitsRequest,
  getMyTokenKitsResponse,
  getPublicTokenKitsRequest,
  getPublicTokenKitsResponse,
  getSupportedChainListRequest,
  getSupportedChainListResponse,
  getSupportedModuleContractListRequest,
  getSupportedModuleContractListResponse,
  getTokenAllowanceHistoryRequest,
  getTokenAllowanceHistoryResponse,
  getTokenApprovalsHistoryRequest,
  getTokenApprovalsHistoryResponse,
  getTokenBalanceOfRequest,
  getTokenBalanceOfResponse,
  getTokenFunctionListWithVersionIdRequest,
  getTokenFunctionListWithVersionIdResponse,
  getTokenFunctionRequest,
  getTokenFunctionResponse,
  getTokenKitDetailRequest,
  getTokenKitDetailResponse,
  getTokenKitTypeDetailRequest,
  getTokenKitTypeDetailResponse,
  getTokenKitTypeListRequest,
  getTokenKitTypeListResponse,
  getTokenKitTypeVersionListRequest,
  getTokenKitTypeVersionListResponse,
  getTokenKitVersionRequest,
  getTokenKitVersionResponse,
  getTokenTotalSupplyRequest,
  getTokenTotalSupplyResponse,
  getTokenTransferHistoryRequest,
  getTokenTransferHistoryResponse,
  postImageUploadForTokenKitRequest,
  postImageUploadForTokenKitResponse,
  postKitIdDeployResultRequest,
  postKitIdDeployResultResponse,
  postKitIdForMakeContractRequest,
  postKitIdForMakeContractResponse,
  postMyPublicTokenKitsRequest,
  postMyPublicTokenKitsResponse,
} from "./index.type";
//유저가 생성한 토큰키트 조회
const getMyTokenKits = async (
  request: getMyTokenKitsRequest
): Promise<getMyTokenKitsResponse> =>
  await v1ServerByProjectAuth.get({
    path: "/token-kits/kits/me",
    query: makeQuery(request.query),
  });

//ID Token 값을 검증하여 해당 projectId 로 추가된 Public Token Kit 목록을 조회
const getMyPublicTokenKits = async (
  request: getMyPublicTokenKitsRequest
): Promise<getMyPublicTokenKitsResponse> =>
  await v1ServerByProjectAuth.get({
    path: "/token-kits/kits/my-kits",
    query: makeQuery(request.query),
  });

//[Public Token Kit] 모달창 리스트의 목록에서 선택된 Public Token Kit를 추가
const postMyPublicTokenKits = async (
  request: postMyPublicTokenKitsRequest
): Promise<postMyPublicTokenKitsResponse> =>
  await v1ServerByProjectAuth.post({
    path: "/token-kits/kits/my-kits",
    query: makeQuery(request.query),
    data: request.data,
  });

//[Public Token Kit] 모달창 리스트의 목록을 조회합니다.
const getPublicTokenKits = async (
  request: getPublicTokenKitsRequest
): Promise<getPublicTokenKitsResponse> =>
  await v1ServerByProjectAuth.get({
    path: "/token-kits/kits/public",
    query: makeQuery(request.query),
  });

const getTokenKitDetail = async (
  request: getTokenKitDetailRequest
): Promise<getTokenKitDetailResponse> =>
  await v1ServerByProjectAuth.get({
    path: `/token-kits/kits/${request.params.kitId}`,
    query: makeQuery(request.query),
  });

const getTokenKitVersion = async (
  request: getTokenKitVersionRequest
): Promise<getTokenKitVersionResponse> =>
  await v1ServerByProjectAuth.get({
    path: `/token-kits/versions`,
    query: makeQuery(request.query),
  });

const getTokenTotalSupply = async (
  request: getTokenTotalSupplyRequest
): Promise<getTokenTotalSupplyResponse> =>
  await v1ServerByProjectAuth.get({
    path: `/token-kits/kits/${request.params.kitId}/issuances/total-supply`,
    query: makeQuery(request.query),
  });

const getTokenFunctionList = async (
  request: getTokenFunctionRequest
): Promise<getTokenFunctionResponse> =>
  await v1ServerByProjectAuth.get({
    path: `/token-kits/kits/${request.params.kitId}/features`,
    query: makeQuery(request.query),
  });

const getTokenFunctionListWithVersionId = async (
  request: getTokenFunctionListWithVersionIdRequest
): Promise<getTokenFunctionListWithVersionIdResponse> =>
  await v1ServerByProjectAuth.get({
    path: `/token-kits/versions/${request.params.versionId}/features`,
    query: makeQuery(request.query),
  });

const getTokenBalanceOf = async (
  request: getTokenBalanceOfRequest
): Promise<getTokenBalanceOfResponse> =>
  await v1ServerByProjectAuth.get({
    path: `/token-kits/kits/${request.params.kitId}/ownerships/balance`,
    query: makeQuery(request.query),
  });

const getTokenTransferHistory = async (
  request: getTokenTransferHistoryRequest
): Promise<getTokenTransferHistoryResponse> =>
  await v1ServerByProjectAuth.get({
    path: `/token-kits/kits/${request.params.kitId}/transfers/histories`,
    query: makeQuery(request.query),
  });

const getTokenApprovalsHistory = async (
  request: getTokenApprovalsHistoryRequest
): Promise<getTokenApprovalsHistoryResponse> =>
  await v1ServerByProjectAuth.get({
    path: `/token-kits/kits/${request.params.kitId}/permissions/histories`,
    query: makeQuery(request.query),
  });

const getTokenAllowanceHistory = async (
  request: getTokenAllowanceHistoryRequest
): Promise<getTokenAllowanceHistoryResponse> =>
  await v1ServerByProjectAuth.get({
    path: `/token-kits/kits/${request.params.kitId}/permissions/allowance`,
    query: makeQuery(request.query),
  });

const getSupportedChainList = async (
  request: getSupportedChainListRequest
): Promise<getSupportedChainListResponse> =>
  await v1ServerByProjectAuth.get({
    path: `/token-kits/versions/${request.params.versionId}/supported-chains`,
    query: makeQuery(request.query),
  });

const getSupportedModuleContractList = async (
  request: getSupportedModuleContractListRequest
): Promise<getSupportedModuleContractListResponse> =>
  await v1ServerByProjectAuth.get({
    path: `/token-kits/kits/${request.params.kitId}/module-contracts`,
    query: makeQuery(request.query),
  });
//토큰 키트 타입 목록 조회
const getTokenKitTypeList = async (
  request: getTokenKitTypeListRequest
): Promise<getTokenKitTypeListResponse> =>
  await v1ServerByProjectAuth.get({
    path: `/token-kits/kit-types`,
    query: makeQuery(request.query),
  });

//키트타입 상세
const getTokenKitTypeDetail = async (
  request: getTokenKitTypeDetailRequest
): Promise<getTokenKitTypeDetailResponse> =>
  await v1ServerByProjectAuth.get({
    path: `/token-kits/kit-types/${request.params.kitTypeId}`,
    query: makeQuery(request.query),
  });

//키트타입
const getTokenKitTypeVersionList = async (
  request: getTokenKitTypeVersionListRequest
): Promise<getTokenKitTypeVersionListResponse> =>
  await v1ServerByProjectAuth.get({
    path: `/token-kits/kit-types/${request.params.kitTypeId}/versions`,
    query: makeQuery(request.query),
  });

const createTokenKit = async (
  request: createTokenKitRequest
): Promise<createTokenKitResponse> =>
  await v1ServerByProjectAuth.post({
    path: `token-kits/kits`,
    data: request.data,
    query: makeQuery(request.query),
  });

const getDefaultImageForTokenKit = async (
  request: getDefaultImageForTokenKitRequest
): Promise<getDefaultImageForTokenKitResponse> =>
  await v1ServerByProjectAuth.get({
    path: `/token-kits/kits/default-image`,
    query: makeQuery(request.query),
  });

const postImageUploadForTokenKit = async (
  request: postImageUploadForTokenKitRequest
): Promise<postImageUploadForTokenKitResponse> =>
  await v1ServerByProjectAuth.post({
    path: `/token-kits/kits/upload-image`,
    query: makeQuery(request.query),
    config: {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
  });

const postKitIdForMakeContract = async (
  request: postKitIdForMakeContractRequest
): Promise<postKitIdForMakeContractResponse> =>
  await v1ServerByProjectAuth.post({
    path: `/token-kits/kits/${request.params.kitId}/submit`,
    data: request.data,
    query: makeQuery(request.query),
  });

const postKitIdDeployResult = async (
  request: postKitIdDeployResultRequest
): Promise<postKitIdDeployResultResponse> =>
  await v1ServerByProjectAuth.post({
    path: `/token-kits/kits/${request.params.kitId}/release`,
    query: makeQuery(request.query),
  });

export {
  getMyPublicTokenKits as getMyPublicTokenKitsFromServer,
  getMyTokenKits as getMyTokenKitsFromServer,
  getPublicTokenKits as getPublicTokenKitsFromServer,
  getSupportedChainList as getSupportedChainListFromServer,
  getSupportedModuleContractList as getSupportedModuleContractListFromServer,
  getTokenAllowanceHistory as getTokenAllowanceHistoryFromServer,
  getTokenApprovalsHistory as getTokenApprovalsHistoryFromServer,
  getTokenBalanceOf as getTokenBalanceOfFromServer,
  getTokenFunctionList as getTokenFunctionListFromServer,
  getTokenKitDetail as getTokenKitDetailFromServer,
  getTokenKitVersion as getTokenKitVersionFromServer,
  getTokenTotalSupply as getTokenTotalSupplyFromServer,
  getTokenTransferHistory as getTokenTransferHistoryFromServer,
  postMyPublicTokenKits as postMyPublicTokenKitsFromServer,
  getTokenKitTypeList as getTokenKitTypeListFromServer,
  getTokenKitTypeDetail as getTokenKitTypeDetailFromServer,
  getTokenKitTypeVersionList as getTokenKitTypeVersionListFromServer,
  createTokenKit as createTokenKitFromServer,
  getDefaultImageForTokenKit as getDefaultImageForTokenKitFromServer,
  postImageUploadForTokenKit as postImageUploadForTokenKitFromServer,
  getTokenFunctionListWithVersionId as getTokenFunctionListWithVersionIdFromServer,
  postKitIdForMakeContract as postKitIdForMakeContractFromServer,
  postKitIdDeployResult as postKitIdDeployResultFromServer,
};
// let formData = new FormData();
//     if (typeof airdropData.projectImage !== "string") {
//       formData.append("projectImage", airdropData.projectImage);
//     }
