import { useCallback, useEffect, useMemo, useState } from "react";
import "./PaymentGatewayVirtualAccountDeposit.scss";
import moment from "moment";
import { useParams } from "react-router-dom";
import { WEB3 } from "../../../../../modules/web3/Web3";
import Table from "../../../../../legacy-common/atom/table/Table";
import { errorAlert } from "../../../../../utils/Utils";
import PagingNumber from "../../../../components/_atom/paging/PagingNumber";
import { getDepositHistoryAction, resendDepositAction, retryDepositAlertAction } from "../../../../../action/paymentGatewayAction";
import IconCopyDefault from "../../../../assets/images/icon_copy_default.png";
import IconCopyHover from "../../../../assets/images/icon_copy_hover.png";
import toast from "react-hot-toast";
import BasicButton from "view/components/_atom/button/BasicButton";
import { ButtonSize, ButtonTheme } from "view/components/_atom/button/StyledButton";
import ButtonContainer from "view/components/_atom/container/ButtonContainer";
import { DEPOSIT_LIMIT, VIRTUAL_ACCOUNT_DEPOSIT_STATUS, VIRTUAL_ACCOUNT_TRANSFER_STATUS } from "../constants/paymentGateway.data";
import BasicInput from "view/components/_atom/input/BasicInput";
import RadioButton from "legacy-common/atom/button/RadioButton";
import retryIcon from "../../../../assets/images/Icon_retry.png";
import { ColumnManageField, ManageFieldContainer, ManageFieldLabel } from "view/components/_atom/section/ManageField";
import pgIcon from "../../../../assets/images/service/Icon_SNB_payment_gateway.png";

function PaymentGatewayVirtualAccountDeposit() {
  const { id: paymentMethodId } = useParams();

  const [depositStartDate, setDepositStartDate] = useState("");
  const [depositEndDate, setDepositEndDate] = useState("");
  const [addressForSearch, setAddressForSearch] = useState("");
  const [depositStatus, setDepositStatus] = useState(null);
  const [callbackStatus, setCallbackStatus] = useState(null);
  const [searchQuery, setSearchQuery] = useState({});
  const [totalDepositHistoryCount, setTotalDepositHistoryCount] = useState(null);
  const [depositHistoryData, setDepositHistoryData] = useState([]);
  const [isDepositAction, setIsDepositAction] = useState(false);
  const [currentPage, setCurrentPage] = useState("1");

  const getDepositHistory_ = async (value = 1) => {
    if (typeof currentPage === "string" && currentPage === "") {
      return;
    }
    const offset = (Number(value) - 1) * DEPOSIT_LIMIT;
    const { error, result } = await getDepositHistoryAction(paymentMethodId, DEPOSIT_LIMIT, offset, { ...searchQuery });
    if (result) {
      const { count, rows } = result;
      setTotalDepositHistoryCount(count);
      setDepositHistoryData(rows);
    }
    if (error) {
      errorAlert(error.data.message);
    }
  };

  const depositResultHeaders = useMemo(
    () => [
      { title: "VA created", accessor: "date" },
      { title: "Virtual Address", accessor: "address" },
      { title: "Deposit Time", accessor: "depositTime" },
      { title: "Amount", accessor: "amount" },
      { title: "Deposit", accessor: "depositStatus" },
      { title: "Callback", accessor: "callbackStatus" },
    ],
    []
  );

  const colGroup = useMemo(() => [15, 20, 15, 20, 15, 15].map((el) => ({ style: { width: `${el}%` } })), []);

  const depositResultRenderer = useCallback(
    (data) => ({
      date: <CreatedVirtualAccount date={data?.virtualAddress?.createdAt} />,
      address: <VirtualAddress address={data.virtualAddress.address} />,
      depositTime: <DepositTime date={data.datetime} />,
      amount: Boolean(data.amount) ? WEB3.fromWei(data.amount) : "-",
      depositStatus: <DepositStatus data={data} resendDeposit_={resendDeposit_} />,
      callbackStatus: <CallbackStatus data={data} retryDepositAlert_={retryDepositAlert_} />,
    }),
    []
  );

  const retryDepositAlert_ = async (selectedDeposit) => {
    const { error, result } = await retryDepositAlertAction(selectedDeposit.virtualAddressTransactionId, selectedDeposit.virtualAddress.virtualAddressId);
    if (result) {
      setIsDepositAction(true);
    }
    if (error) {
      errorAlert(error.data.message);
    }
  };

  const resendDeposit_ = async (selectedDeposit) => {
    const { error, result } = await resendDepositAction(selectedDeposit.virtualAddressTransactionId, selectedDeposit.virtualAddress.virtualAddressId);
    if (result) {
      setIsDepositAction(true);
    }
    if (error) {
      errorAlert(error.data.message);
    }
  };

  const maximumPage = useMemo(() => {
    if (totalDepositHistoryCount === 0) {
      return 1;
    }
    if (totalDepositHistoryCount % DEPOSIT_LIMIT) {
      return parseInt(totalDepositHistoryCount / DEPOSIT_LIMIT) + 1;
    } else {
      return parseInt(totalDepositHistoryCount / DEPOSIT_LIMIT);
    }
  }, [totalDepositHistoryCount]);

  const onClickSearch = async () => {
    setSearchQuery({
      ...(!!depositStartDate && { createdStartDate: depositStartDate }),
      ...(!!depositEndDate && { createdEndDate: depositEndDate }),
      ...(!!addressForSearch && { address: addressForSearch }),
      ...(!!callbackStatus && { depositStatus: callbackStatus }),
      ...(!!depositStatus && { transferStatus: depositStatus }),
    });
    setCurrentPage("1");
  };

  const onClickResetThenSearch = async () => {
    setCallbackStatus(null);
    setDepositStatus(null);
    setAddressForSearch("");
    setDepositStartDate("");
    setDepositEndDate("");
    setSearchQuery({});
    setCurrentPage("1");
  };

  useEffect(() => {
    if (isDepositAction) {
      getDepositHistory_();
      setIsDepositAction(false);
    }
  }, [isDepositAction]);

  useEffect(() => {
    getDepositHistory_();
  }, [searchQuery]);

  return (
    <>
      {/* Filter Description :: (Optional) You can set conditions for the data shown in the 'Deposit info' area at the bottom. */}
      <ManageFieldContainer icon={pgIcon} title="Filter">
        <ColumnManageField>
          <ManageFieldLabel label="Date" />
          <div className="payment-gateway-date-picker-container">
            <BasicInput
              type="date"
              value={depositStartDate}
              onChange={(e) => setDepositStartDate(e.target.value)}
              max={moment(new Date()).format("YYYY-MM-DD")}
            />
            <span>~</span>
            <BasicInput
              type="date"
              value={depositEndDate}
              onChange={(e) => setDepositEndDate(e.target.value)}
              min={depositStartDate}
              max={moment(new Date()).format("YYYY-MM-DD")}
            />
          </div>
        </ColumnManageField>
        <ColumnManageField>
          <ManageFieldLabel label="Address" />
          <BasicInput value={addressForSearch} onChange={(e) => setAddressForSearch(e.target.value)} placeholder={"Ex) 0x………"} />
        </ColumnManageField>
        <ColumnManageField>
          <ManageFieldLabel label="Deposit" />
          <div className="payment-gateway-status-option-container">
            {VIRTUAL_ACCOUNT_TRANSFER_STATUS.map((option) => (
              <RadioButton
                key={`payment-gateway-virtual-account-transfer-option-${option.value}`}
                id={`virtual-account-transfer-option-${option.value}`}
                label={option.label}
                value={option.value}
                name={`payment-gateway-virtual-account-transfer`}
                onChange={(e) => setDepositStatus(e.target.value)}
                checked={depositStatus === option.value}
                labelStyle={{ padding: "0 0 0 25px" }}
              />
            ))}
          </div>
        </ColumnManageField>
        <ColumnManageField>
          <ManageFieldLabel label="Callback" />
          <div className="payment-gateway-status-option-container">
            {VIRTUAL_ACCOUNT_DEPOSIT_STATUS.map((option) => (
              <RadioButton
                key={`payment-gateway-virtual-account-deposit-option-${option.value}`}
                id={`virtual-account-deposit-option-${option.value}`}
                label={option.label}
                value={option.value}
                name={`payment-gateway-virtual-account-deposit`}
                onChange={(e) => setCallbackStatus(e.target.value)}
                checked={callbackStatus === option.value}
                labelStyle={{ padding: "0 0 0 25px" }}
              />
            ))}
          </div>
        </ColumnManageField>
        <div className="payment-gateway-virtual-account-deposit-filter-button-container">
          <ButtonContainer>
            <BasicButton theme={ButtonTheme.SECONDARY} size={ButtonSize.DYNAMIC} onClick={() => onClickResetThenSearch()}>
              Reset
            </BasicButton>
            <BasicButton size={ButtonSize.DYNAMIC} onClick={() => onClickSearch()}>
              Search
            </BasicButton>
          </ButtonContainer>
        </div>
      </ManageFieldContainer>
      <div style={{ height: 20 }} />
      {/* Deposit Info Description :: Check the details of coin deposited through Payment gateway. */}
      <ManageFieldContainer icon={pgIcon} title="Deposit Info">
        <div className={"flex-column"}>
          <Table headers={depositResultHeaders} colGroup={colGroup} data={depositHistoryData} renderer={depositResultRenderer} />
          <PagingNumber page={currentPage} setPage={setCurrentPage} maximumPage={maximumPage} getData={getDepositHistory_} />
        </div>
      </ManageFieldContainer>
    </>
  );
}

export default PaymentGatewayVirtualAccountDeposit;

const VirtualAddress = ({ address }) => {
  const [isIconHover, setIsIconHover] = useState(false);
  const onClickCopyAddress = () => {
    window.navigator.clipboard.writeText(address).then(() => toast.success("Copied!"));
  };
  return (
    <div className="virtual-address">
      <span>{`${address.slice(0, 4)}...${address.slice(-4)}`}</span>
      <div className="copy-icon-wrapper" onMouseOver={() => setIsIconHover(true)} onMouseLeave={() => setIsIconHover(false)}>
        <img className="copy-icon" src={isIconHover ? IconCopyHover : IconCopyDefault} alt="copy" onClick={() => onClickCopyAddress()} />
      </div>
    </div>
  );
};

const CreatedVirtualAccount = (props) => {
  const { date } = props || {};

  return (
    <>
      {date ? (
        <div className="payment-gateway-virtual-account-deposit-date">
          <p className="payment-gateway-virtual-account-deposit-date date">{moment(date).format("YY/MM/DD")}</p>
          <p className="payment-gateway-virtual-account-deposit-date time">{moment(date).format("HH:mm:ss")}</p>
        </div>
      ) : (
        "-"
      )}
    </>
  );
};

const DepositTime = (props) => {
  const { date } = props || {};

  return (
    <>
      {date ? (
        <div className="payment-gateway-virtual-account-deposit-date">
          <p className="payment-gateway-virtual-account-deposit-date date">{moment(date).format("YY/MM/DD")}</p>
          <p className="payment-gateway-virtual-account-deposit-date time">{moment(date).format("HH:mm:ss")}</p>
        </div>
      ) : (
        "-"
      )}
    </>
  );
};

const DepositStatus = (props) => {
  const { data, resendDeposit_ } = props;
  const { virtualAddressPayout } = data || {};

  switch (virtualAddressPayout.status) {
    case 0:
      return (
        <div className="payment-gateway-virtual-account-deposit-status">
          <div className="payment-gateway-virtual-account-deposit-status-label not-confirmed">Unsent</div>
        </div>
      );
    case 1:
      return (
        <div className="payment-gateway-virtual-account-deposit-status">
          <div className="payment-gateway-virtual-account-deposit-status-label inprogress">Processing</div>
        </div>
      );
    case 2:
      return (
        <div className="payment-gateway-virtual-account-deposit-status">
          <div className="payment-gateway-virtual-account-deposit-status-label retry" onClick={() => resendDeposit_(data)}>
            <span>Resend</span>
            <img src={retryIcon} className="payment-gateway-virtual-account-deposit-status-label-icon" alt="retry" />
          </div>
        </div>
      );
    case 3:
      return (
        <div className="payment-gateway-virtual-account-deposit-status">
          <div className="payment-gateway-virtual-account-deposit-status-label success">Success</div>
        </div>
      );
    default:
      return;
  }
};

const CallbackStatus = (props) => {
  const { data, retryDepositAlert_ } = props;
  const { virtualAddressAlert } = data || {};
  switch (virtualAddressAlert.status) {
    case 0:
      return (
        <div className="payment-gateway-virtual-account-deposit-status">
          <div className="payment-gateway-virtual-account-deposit-status-label not-confirmed">Not confirmed</div>
        </div>
      );
    case 1:
      return (
        <div className="payment-gateway-virtual-account-deposit-status">
          <div className="payment-gateway-virtual-account-deposit-status-label inprogress">Processing</div>
        </div>
      );
    case 2:
      return (
        <div className="payment-gateway-virtual-account-deposit-status">
          <div className="payment-gateway-virtual-account-deposit-status-label retry" onClick={() => retryDepositAlert_(data)}>
            <span>Retry</span>
            <img src={retryIcon} className="payment-gateway-virtual-account-deposit-status-label-icon" alt="retry" />
          </div>
        </div>
      );
    case 3:
      return (
        <div className="payment-gateway-virtual-account-deposit-status">
          <div className="payment-gateway-virtual-account-deposit-status-label success">Complete</div>
        </div>
      );
    default:
      return;
  }
};
