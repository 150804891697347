import IconInProgressContract from "../../../../assets/images/atom/icon-table-in-progress-contract.png";
import IconApprovedContract from "../../../../assets/images/atom/icon-table-approved-contract.png";
import {ServiceStatus, statusType} from "../../../../../utils/status.utils";

//REGEX
export const CONTRACT_REGEX = {
  contractVersionRegex: /^[\d.]+$/,
};

//TYPE(Array)
export const CONTRACT_ERC = [
  {
    value: null,
    label: "etc",
  },
  {
    value: 20,
    label: "ERC-20",
  },
  {
    value: 721,
    label: "ERC-721",
  },
  // {
  //   value: 1400,
  //   label: 'ERC-1400'
  // }
];
export const CONTRACT_TYPE = [
  {
    value: 0,
    label: "Fixed",
  },
  {
    value: 1,
    label: "Proxy",
  },
  {
    value: 2,
    label: "Implementation",
  },
];
export const CONTRACT_DISCLOSURE = [
  {
    value: 0,
    label: "Public",
  },
  {
    value: 1,
    label: "Private",
  },
];

export const UPDATE_VERSION_VALIDATE_CONDITION = {
  version           : [
    {
      condition: (value) => CONTRACT_REGEX.contractVersionRegex.test(value),
      comment  : "version must be include number & .",
    },
  ],
  versionDescription: [
    {
      condition: (value) => JSON.stringify(value).length <= 1000,
      comment  : "Please enter under 1,000 characters",
    },
  ],
};

export const IN_PROGRESS_INDEX = {
  icon: IconInProgressContract,
  text: "In progress",
};

export const APPROVED_INDEX = {
  icon: IconApprovedContract,
  text: "Approved",
};

//Filter Renderer
export const LIBRARY_FILTER_RENDERER = [
  {
    value: 0,
    color: "#77C87F",
  },
  {
    value: 1,
    color: "#779FC8",
  },
  {
    value: 2,
    color: "#EA8954",
  },
];

export const ERC_FILTER_RENDERER = [
  {
    value: null,
    color: "#7685FF",
  },
  {
    value: 20,
    color: "#7685FF",
  },
  {
    value: 721,
    color: "#7685FF",
  },
];

export const CONTRACT_STATUS: Record<string, ServiceStatus> = {
  CONTRACT_CREATED          : {
    mainStatus: 1,
    subStatus : 0,
  },
  CONTRACT_FAILED_REORGANIZE: {
    mainStatus  : 1,
    subStatus   : 1,
    statusDetail: {
      type               : statusType.REJECT,
      title              : 'The deployment of the smart contract was not completed successfully. Please try again.',
      description        : {
        text : 'Reason: Reorganization of blockchain network',
        color: '#FF1717'
      },
      showTransactionHash: false
    }
  },
  CONTRACT_DEPLOY_PROCESSING       : {
    mainStatus  : 4,
    subStatus   : 0,
    statusDetail: {
      type               : statusType.WAIT,
      title              : 'Smart contract is being deployed.',
      showTransactionHash: true
    }
  },
  CONTRACT_DEPLOYED         : {
    mainStatus: 5,
    subStatus : 0
  },
}


