import React, { ReactNode, useState } from "react";
import "./Field.scss";
import QuestionMark, { QuestionMarkTheme } from "../tooltip/QuestionMark";
import DivideLine from "../line/DivideLine";

const FieldContainer = (props: FieldContainerProps) => {
  const { icon, title, description, announcement, options, children } = props;
  return (
    <div className="field-container">
      <div className="field-header">
        <div className="field-header-info-wrapper">
          <div className="field-header-info">
            {icon && (
              <div className="field-header-info-icon">
                <img src={icon} className="field-header-info-icon-src" alt="" />
              </div>
            )}
            <div className="field-header-info-title">{title}</div>
            {announcement && (
              <div className="field-header-info-announcement">
                <QuestionMark announcement={announcement} />
              </div>
            )}
          </div>
          {options && <div className="field-header-option">{options}</div>}
        </div>
        {description && <p className="field-header-description">{description}</p>}
        <DivideLine />
      </div>
      {children}
    </div>
  );
};

const RowField = (props: FieldProps) => {
  const { children } = props;
  return <div className="field row">{children}</div>;
};

const ColumnField = (props: FieldProps) => {
  const { children } = props;
  return <div className="field column">{children}</div>;
};

const FieldLabel = (props: FieldLabelProps) => {
  const { label, essential = true, announcement, description } = props;
  return (
    <div className="field-label">
      <div className="field-label-info">
        <span className="field-label-info-text">{label}</span>
        {essential && <span className="field-label-info-essential">{`*`}</span>}
        {announcement && (
          <div className="field-label-info-announcement">
            <QuestionMark announcement={announcement} theme={QuestionMarkTheme.SECONDARY} />
          </div>
        )}
      </div>
      <span className="field-label-description">{description}</span>
    </div>
  );
};

export { RowField, ColumnField, FieldContainer, FieldLabel };

type FieldContainerProps = {
  icon?: string;
  title: string;
  description?: string;
  announcement?: any;
  options?: any;
  children: ReactNode;
};

type FieldProps = {
  children: ReactNode;
};

type FieldLabelProps = {
  label: string;
  essential?: boolean;
  announcement?: any;
  description?: string;
};
