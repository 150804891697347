import React, { useMemo } from "react";
import "./NetworkSelector.scss";
import Checkbox from "../checkbox/Checkbox";
import DivideLine from "../line/DivideLine";

type NetworkSelectorProps = {
  networkList: Array<any>;
  selectedList: Array<any>;
  setSelectedList: React.Dispatch<React.SetStateAction<any>> | Function;
  idKey?: number;
  labelKey?: string;
};

const NetworkSelector = (props: NetworkSelectorProps) => {
  const { networkList, selectedList, setSelectedList, idKey = "id", labelKey = "label" } = props;

  const selectNetworkList = useMemo(() => networkList.filter((network) => selectedList.includes(network.id)), [networkList, selectedList]);

  const notSelectNetworkList = useMemo(() => networkList.filter((network) => !selectedList.includes(network.id)), [networkList, selectedList]);

  return (
    <div className="network-selector">
      <div className="network-selector-container">
        {selectNetworkList.map((network: any) => (
          <div className="network-selector-item" key={`network-selector-${network.id}`}>
            <Checkbox
              id={network[idKey]}
              label={network[labelKey]}
              checked={selectedList.find((id: number) => id === network.id) || ""}
              onChange={() => setSelectedList(network[idKey])}
            />
          </div>
        ))}
        {selectNetworkList.length !== 0 && notSelectNetworkList.length !== 0 && <DivideLine style={{ margin: "5px 0" }} />}
        {notSelectNetworkList.map((network: any) => (
          <div className="network-selector-item" key={`network-selector-${network.id}`}>
            <Checkbox
              id={network[idKey]}
              label={network[labelKey]}
              checked={selectedList.find((id: number) => id === network.id) || ""}
              onChange={() => setSelectedList(network[idKey])}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default NetworkSelector;
