import { useMemo, useState } from "react";
import "./EditToken.scss";
import CurrencyImageUploadModal from "../../../../components/_modal/CurrencyImageUploadModal";
import { useNavigate, useParams } from "react-router-dom";
import { BooleanParser, stringNumInputHandler } from "../utils/tokenUtils";
import { addComma, errorAlert } from "utils/Utils";
import { toast } from "react-hot-toast";
import { VALIDATION__TOKEN_image, VALIDATION__TOKEN_initial_supply, VALIDATION__TOKEN_name, VALIDATION__Token_symbol } from "../validation/tokenValidation";
import { WEB3 } from "modules/web3/Web3";
import { removeComma } from "../../../../../utils/Utils";
import PageLoading from "../../../../components/_atom/loading/PageLoading";
import RadioButtons from "../../../../../legacy-common/molcules/RadioButtons/RadioButtons";
import { BURNABLE, MINTABLE } from "../constant/tokenConstant";
import ImageUpload from "../../../../components/_atom/file/ImageUpload";
import { getTokenDetailAction, updateTokenAction, uploadCurrencyImageToS3Action } from "action/tokenAction";
import PageArticle from "view/components/_template/page/PageArticle";
import PageTitle from "view/components/_molecules/page/PageTitle";
import BasicButton from "view/components/_atom/button/BasicButton";
import { ButtonSize } from "view/components/_atom/button/StyledButton";
import useLoading from "hooks/useLoading";
import useGetSelectedProject from "hooks/useGetSelectedProject";
import { distinguishValidation } from "modules/validation/validationCondition";
import CommentInput from "view/components/_atom/input/CommentInput";
import useValidateCondition from "hooks/useValidateCondition";
import useIsAble from "hooks/useIsAble";
import tokenIcon from "../../../../assets/images/service/Icon_SNB_token.png";
import { FieldContainer, FieldLabel, RowField } from "view/components/_atom/section/Field";

function EditToken() {
  const { id: currencyId } = useParams();
  const { selectedProjectID } = useGetSelectedProject();
  const navigate = useNavigate();

  const [tokenImageModalOpen, setTokenImageModalOpen] = useState(false);

  //========= Get initial data Token =========//
  const [tokenDetail, setTokenDetail] = useState({});
  const [tokenSupply, setTokenSupply] = useState(null);
  const [tokenImage, setTokenImage] = useState(null);
  const [mintable, setMintable] = useState(null);
  const [burnable, setBurnable] = useState(null);
  // const [lockable, setLockable] = useState(null);

  const getTokenDetail_ = async () => {
    const { result, error } = await getTokenDetailAction(parseInt(currencyId), {
      mainStatus: [5],
    });
    if (result) {
      setTokenDetail(result);
      setTokenSupply(addComma(result.currencyDetail.initialSupply));
      setTokenImage(result.image);
      setMintable(BooleanParser(result.currencyDetail.mintable));
      setBurnable(BooleanParser(result.currencyDetail.burnable));
      // setLockable(BooleanParser(result.currencyDetail.lockable));
    }
    if (error) {
      errorAlert(error.data.message);
      return;
    }
  };

  //========= Update Token Data =========//
  const uploadTokenImage_ = async () => {
    const formData = new FormData();
    if (tokenImage) {
      const fileCount = 1;
      formData.append("image", tokenImage);
      const { result, error } = await uploadCurrencyImageToS3Action(formData, fileCount);
      if (result) {
        return result;
      }
      if (error) {
        toast.error("Something wrong. Please try again");
        return "";
      }
    } else {
      toast.error("Please upload correct token image");
    }
  };

  const tokenNameValidation = useValidateCondition([tokenDetail.name], VALIDATION__TOKEN_name(tokenDetail.name));
  const tokenImageValidation = useValidateCondition([tokenImage], VALIDATION__TOKEN_image(tokenImage));
  const tokenSymbolValidation = useValidateCondition([tokenDetail.symbol], VALIDATION__Token_symbol(tokenDetail.symbol));
  const tokenInitialSupplyValidation = useValidateCondition([tokenSupply], VALIDATION__TOKEN_initial_supply(tokenSupply));

  const isAble = useIsAble([
    tokenNameValidation.isValid,
    tokenImageValidation.isValid,
    tokenSymbolValidation.isValid,
    tokenInitialSupplyValidation.isValid,
    mintable !== null,
    burnable !== null,
  ]);

  const tokenValidationResult = useMemo(() => {
    return distinguishValidation([tokenNameValidation, tokenImageValidation, tokenSymbolValidation, tokenInitialSupplyValidation]);
  }, [tokenNameValidation, tokenImageValidation, tokenSymbolValidation, tokenInitialSupplyValidation]);

  const updateTokenDetail_ = async () => {
    if (!tokenValidationResult.isValid) {
      toast.error(tokenValidationResult.message);
      return;
    }

    const imageURL = typeof tokenImage === "string" ? tokenImage : await uploadTokenImage_();
    if (Boolean(imageURL)) {
      const { result, error } = await updateTokenAction(
        parseInt(currencyId),
        tokenDetail.name,
        imageURL,
        tokenDetail.symbol,
        WEB3.toWei(removeComma(tokenSupply)),
        Boolean(mintable),
        Boolean(burnable),
        tokenDetail.currencyDetail.officialSite,
        tokenDetail.currencyDetail.useWallet
      );
      if (result) {
        toast.success("processed successfully");
        navigate(`/application/token/manage/${result.currencyId}`);
      }
      if (error) {
        errorAlert(error.data.message);
        return;
      }
    }
  };

  const { loading } = useLoading({
    dependency: [selectedProjectID],
    synchronousFunction: async () => {
      await getTokenDetail_();
    },
  });

  if (loading) {
    return <PageLoading />;
  }
  return (
    <>
      {tokenImageModalOpen && (
        <CurrencyImageUploadModal
          title={"Select Token Image"}
          currencyImage={tokenImage}
          setCurrencyImage={setTokenImage}
          currencyImageModalOpen={tokenImageModalOpen}
          setCurrencyImageModalOpen={setTokenImageModalOpen}
        />
      )}
      <PageArticle>
        <PageTitle title={"Edit Token"} size={810} />
        <div className="page-layout-810">
          {/* Basic Info Description :: Set the basic information for the token you want to create. */}
          <FieldContainer icon={tokenIcon} title="Basic Info">
            <RowField>
              <FieldLabel label="Network / Microchain" />
              <span>{`${tokenDetail?.networkLabel} / ${tokenDetail?.microChainLabel}`}</span>
            </RowField>
          </FieldContainer>

          {/* Details Description :: Enter details for the token you want to create. */}
          <FieldContainer icon={tokenIcon} title="Details">
            <RowField>
              <FieldLabel label="Token name" />
              <CommentInput
                {...tokenNameValidation}
                value={tokenDetail?.name ?? ""}
                onChange={(e) =>
                  setTokenDetail((prev) => ({
                    ...prev,
                    name: e.target.value,
                  }))
                }
                autoFocus
              />
            </RowField>
            <RowField>
              <FieldLabel label="Token symbol" />
              <CommentInput
                {...tokenSymbolValidation}
                value={tokenDetail?.symbol ?? ""}
                onChange={(e) =>
                  setTokenDetail((prev) => ({
                    ...prev,
                    symbol: e.target.value.toUpperCase(),
                  }))
                }
              />
            </RowField>
            <RowField>
              <FieldLabel label="Token image" />
              <ImageUpload
                image={tokenImage}
                setImage={setTokenImage}
                style={{ alignItems: "start" }}
                previewStyle={{ borderRadius: "50%" }}
                onClickUploader={() => setTokenImageModalOpen(true)}
              />
            </RowField>
            <RowField>
              <FieldLabel label="Token supply" />
              <CommentInput
                {...tokenInitialSupplyValidation}
                value={tokenSupply}
                onChange={(e) => {
                  stringNumInputHandler(e.target.value, setTokenSupply);
                }}
                maxLength={55}
              />
            </RowField>
            <RowField>
              <FieldLabel label="Mintable" />
              <RadioButtons options={MINTABLE} selectedValue={mintable} onChange={setMintable} />
            </RowField>
            <RowField>
              <FieldLabel label="Burnable" />
              <RadioButtons options={BURNABLE} selectedValue={burnable} onChange={setBurnable} />
            </RowField>
            {/* <RowField>
              <FieldLabel label="Lockable" />
              <RadioButtons options={LOCKABLE} selectedValue={lockable} onChange={setLockable} />
            </RowField> */}
          </FieldContainer>
          <div className="edit-token-button-container">
            <BasicButton size={ButtonSize.LARGE} disabled={!isAble} onClick={() => updateTokenDetail_()}>
              Save
            </BasicButton>
          </div>
        </div>
      </PageArticle>
    </>
  );
}

export default EditToken;
