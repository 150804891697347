import { useEffect, useState } from "react";

type Validation = {
  condition: boolean;
  message?: string;
};

const useValidateCondition = (dependency: Array<any>, validations: Array<Validation>) => {
  const [result, setResult] = useState({
    isValid: true,
    message: "",
  });

  useEffect(() => {
    for (const validation of validations) {
      if (!validation.condition) {
        setResult({
          isValid: false,
          message: validation.message ?? "",
        });
        return;
      }
      setResult({
        isValid: true,
        message: "",
      });
    }
  }, [...dependency]);

  return result;
};

export default useValidateCondition;
