import { Dispatch, ReactNode, SetStateAction } from "react";
import css from "./CreateProjectStep2.scss";
import { ProjectDataType } from "../page/CreateProject";
import BasicButton from "../../../../components/_atom/button/BasicButton";
import ButtonContainer from "../../../../components/_atom/container/ButtonContainer";
import { ButtonSize, ButtonTheme } from "view/components/_atom/button/StyledButton";
import { Plan, PlanOptions } from "view/service/dashboard/constants/setting.type";
import { bindStyle } from "view/styles/classNames";
import { Project } from "../../../../../reducer/clientType/projectClientType";
import { errorAlert } from "../../../../../utils/Utils";

type CreateProjectStep2Props = {
  setStep: Dispatch<SetStateAction<number>>;
  projectData: ProjectDataType;
  myProjects: Array<Project>;
  setProjectData: Dispatch<SetStateAction<ProjectDataType>>;
  // TODO :: step3 생기면 삭제
  createProject: Function;
};

const CreateProjectStep2 = ({ setStep, myProjects, projectData, setProjectData, createProject }: CreateProjectStep2Props) => {
  const cn = bindStyle(css);

  const setProjectPlan = (value: number): void => {
    setProjectData((prevState) => {
      return { ...prevState, pricingPlanId: value };
    });
  };

  const optionDataHandler = (value: number): string => {
    switch (value) {
      case Infinity:
        return "Unlimited";
      default:
        return new Intl.NumberFormat("en-us").format(value);
    }
  };

  const planOptionRenderer = (options: PlanOptions): Array<string> => {
    return [
      `Up To ${optionDataHandler(options.participants)} Participants`,
      `Teams ${optionDataHandler(options.team)}`,
      `Boards ${optionDataHandler(options.board)}`,
      `Chat/Video ${optionDataHandler(options.stream)}`,
      `Sketch 5 ${optionDataHandler(options.sketch)}`,
      `Video Call Times ${optionDataHandler(options.callTime)} Min `,
      `Provide ${optionDataHandler(options.provideCredit)} credits`,
    ];
  };

  const priceRenderer = (price: number): ReactNode => {
    switch (price) {
      case 0:
        return (
          <div className="initial-plan-price">
            <span className={"initial-per-price"}>{price}</span>
          </div>
        );
      default:
        return (
          <div className="initial-plan-price">
            <span className={"initial-per-price"}>${price}</span>
            <span className={"initial-month"}>/mo.</span>
          </div>
        );
    }
  };
  const FreePlanId = 1;

  const checkHasFreeProject = (projects: Array<Project>) => {
    return projects.map((el) => el.pricingPlanId).includes(FreePlanId);
  };

  const onClickNext = () => {
    const hasFreeProject = checkHasFreeProject(myProjects);
    if (projectData.pricingPlanId === FreePlanId && hasFreeProject) {
      errorAlert("You already using a free plan.\nPlease choose a different plan.");
      return;
    }
    setStep((prevStep) => prevStep + 1);
  };

  return (
    <div className="create-project-step2">
      <div className="initial-project-plan-container">
        {/* {PLAN_LIST.map((plan: Plan) => (
          <div className={"initial-price-plan-wrapper"} key={`initial-price-plan-${plan.id}`}>
            <div
              className={cn("initial-price-plan", { selected: plan.id === projectData.pricingPlanId })}
              style={plan.id === projectData.pricingPlanId ? { border: `2px solid ${plan.theme.color}` } : { border: `2px solid #e7e9ef` }}
              onClick={() => setProjectPlan(plan.id)}
            >
              <ul className="initial-price-plan-option-list">
                {planOptionRenderer(plan.options).map((option, index) => (
                  <li className="initial-price-plan-option" key={`initial-plan-options-${index}`}>
                    {option}
                  </li>
                ))}
              </ul>
              {priceRenderer(plan.price)}
            </div>
          </div>
        ))} */}
      </div>
      <div className="flex justify-content-flex-end">
        <ButtonContainer>
          <BasicButton theme={ButtonTheme.SECONDARY} size={ButtonSize.LARGE} onClick={() => setStep((prevStep) => prevStep - 1)}>
            Back
          </BasicButton>
          {/* <BasicButton size={ButtonSize.LARGE} onClick={() => onClickNext()}>
            Next
          </BasicButton> */}
          <BasicButton size={ButtonSize.LARGE} onClick={() => createProject()}>
            Create
          </BasicButton>
        </ButtonContainer>
      </div>
    </div>
  );
};

export default CreateProjectStep2;
