import React from "react";
import { useNavigate } from "react-router-dom";
import "./PaymentGatewayDetail.scss";
import toast from "react-hot-toast";
import { errorAlert } from "../../../../../utils/Utils";
import iconDeleteRed from "../../../../assets/images/atom/icon_delete_red.png";
import { deletePaymentMethodAction, submitPaymentMethodAction } from "../../../../../action/paymentGatewayAction";
import { ButtonSize, ButtonTheme } from "../../../../components/_atom/button/StyledButton";
import { paymentTypeLabelParser, selectPaymentTypeById } from "../../../../../reducer/presetReducer";
import { useSelector } from "react-redux";
import ButtonContainer from "view/components/_atom/container/ButtonContainer";
import BasicButton from "../../../../components/_atom/button/BasicButton";
import { PAYMENT_GATEWAY_STATUS_CREATED, PAYMENT_GATEWAY_STATUS_DEPLOYED, PAYMENT_GATEWAY_STATUS_PREPARED } from "../constants/paymentGateway.data";
import { connectInfo } from "store/redux/EQconnect/EQConnectSlice";
import { project_access } from "reducer/projectReducer";
import { makeTransactionForUploadContractAction } from "action/requestAction";
import EQConnectWalletButton from "modules/EQConnect/EQConnectWalletButton";
import useTransaction from "hooks/useTransaction";
import CopyLabelWithIcon from "view/components/_atom/label/CopyLabelWithIcon";
import { ManageFieldContainer, ManageFieldLabel, RowManageField } from "view/components/_atom/section/ManageField";
import pgIcon from "../../../../assets/images/service/Icon_SNB_payment_gateway.png";

function PaymentGatewayDetail(props) {
  const { paymentMethodDetail, setPaymentMethodDetail, getPaymentGatewayDetail } = props;

  const navigate = useNavigate();

  const { address: connectedWalletAddress } = useSelector(connectInfo);
  const projectAccessToken = useSelector(project_access);
  const selectedPaymentType = useSelector((state) => selectPaymentTypeById(state, paymentMethodDetail.paymentTypeId));

  const submitPaymentGateway_ = async () => {
    const { result, error } = await submitPaymentMethodAction(paymentMethodDetail.paymentMethodId);
    if (result) {
      setPaymentMethodDetail(result);
      toast.success("Submit successfully");
    }
    if (error) {
      errorAlert(error.data.message);
    }
  };

  const deployPaymentGatewayContract_ = async () => {
    return await makeTransactionForUploadContractAction({
      address: connectedWalletAddress,
      contractId: paymentMethodDetail.contractId,
      microChainId: paymentMethodDetail.microChainId,
      parameters: [paymentMethodDetail.adminAddress],
      to: null,
      value: null,
      transactionPath: `${process.env.REACT_APP_HUB_SERVER_URL}${process.env.REACT_APP_API_VERSION}payment-gateway/payment-methods/${paymentMethodDetail.paymentMethodId}/release`,
      token: projectAccessToken,
    });
  };

  const deletePaymentGateway_ = async () => {
    const { error, result } = await deletePaymentMethodAction(paymentMethodDetail.paymentMethodId);
    if (result) {
      toast.success("Deleted successfully");
      navigate("/developer/payment-gateway/manage");
    }
    if (error) {
      errorAlert(error.data.message);
    }
  };

  const deleteButtonHandler = async () => {
    if (window.confirm("Are you sure you want to delete it? You cannot recover deleted data")) {
      await deletePaymentGateway_();
    }
  };

  const { requestTransaction } = useTransaction(deployPaymentGatewayContract_, getPaymentGatewayDetail);

  return (
    <>
      {selectedPaymentType?.methodType === "account" && <DetailInfoForVA paymentMethodDetail={paymentMethodDetail} selectedPaymentType={selectedPaymentType} />}
      {selectedPaymentType?.methodType === "contract" && (
        <DetailInfoForSC paymentMethodDetail={paymentMethodDetail} selectedPaymentType={selectedPaymentType} />
      )}
      <div className="payment-gateway-detail-bottom-container">
        <ButtonContainer>
          {paymentMethodDetail.mainStatus === PAYMENT_GATEWAY_STATUS_CREATED && (
            <>
              <BasicButton size={ButtonSize.ICON} theme={ButtonTheme.MINOR} onClick={() => deleteButtonHandler()}>
                <img src={iconDeleteRed} style={{ width: 18, objectFit: "contain" }} alt={"delete"} />
              </BasicButton>
              <BasicButton size={ButtonSize.LARGE} theme={ButtonTheme.SECONDARY} onClick={() => navigate("edit")}>
                Edit
              </BasicButton>
              <BasicButton size={ButtonSize.LARGE} onClick={() => submitPaymentGateway_()}>
                Submit
              </BasicButton>
            </>
          )}
          {paymentMethodDetail.mainStatus === PAYMENT_GATEWAY_STATUS_PREPARED && (
            <>
              <EQConnectWalletButton />
              {Boolean(connectedWalletAddress) && (
                <BasicButton size={ButtonSize.LARGE} disabled={!connectedWalletAddress} onClick={() => requestTransaction()}>
                  Deploy
                </BasicButton>
              )}
            </>
          )}
          {paymentMethodDetail.mainStatus === PAYMENT_GATEWAY_STATUS_DEPLOYED && (
            <>
              <BasicButton size={ButtonSize.ICON} theme={ButtonTheme.MINOR} onClick={() => deleteButtonHandler()}>
                <img src={iconDeleteRed} style={{ width: 18, objectFit: "contain" }} alt={"delete"} />
              </BasicButton>
              <BasicButton size={ButtonSize.LARGE} onClick={() => navigate("edit")}>
                Edit
              </BasicButton>
            </>
          )}
        </ButtonContainer>
      </div>
    </>
  );
}

export default PaymentGatewayDetail;

const DetailInfoForVA = (props) => {
  const { paymentMethodDetail, selectedPaymentType } = props;

  return (
    <ManageFieldContainer icon={pgIcon} title="Basic Info">
      <RowManageField>
        <ManageFieldLabel label="UUID (payment_method_id)" />
        {Boolean(paymentMethodDetail?.paymentMethodId) ? <CopyLabelWithIcon text={paymentMethodDetail?.paymentMethodId} /> : <span>-</span>}
      </RowManageField>
      <RowManageField>
        <ManageFieldLabel label="Name" />
        <span>{paymentMethodDetail?.name ?? "-"}</span>
      </RowManageField>
      <RowManageField>
        <ManageFieldLabel label="Network" />
        <span>{paymentMethodDetail?.networkLabel ?? "-"}</span>
      </RowManageField>
      <RowManageField>
        <ManageFieldLabel label="Currency" />
        <span>{paymentMethodDetail?.currencyDetail?.currency?.symbol ?? "-"}</span>
      </RowManageField>
      <RowManageField>
        <ManageFieldLabel label="Type" />
        <span>{paymentTypeLabelParser(selectedPaymentType?.name) ?? "-"}</span>
      </RowManageField>
      <RowManageField>
        <ManageFieldLabel label="Merchant address" />
        <span>{paymentMethodDetail?.adminAddress ?? "-"}</span>
      </RowManageField>
      <RowManageField>
        <ManageFieldLabel label="Deposit redirect URI" />
        <span>{paymentMethodDetail?.depositCallbackURI ?? "-"}</span>
      </RowManageField>
      <RowManageField>
        <ManageFieldLabel label="Withdrawal redirect URI" />
        <span>{paymentMethodDetail?.withdrawalCallbackURI ?? "-"}</span>
      </RowManageField>
    </ManageFieldContainer>
  );
};

const DetailInfoForSC = (props) => {
  const { paymentMethodDetail, selectedPaymentType } = props;

  return (
    <>
      <ManageFieldContainer icon={pgIcon} title="Basic Info">
        <RowManageField>
          <ManageFieldLabel label="UUID (payment_method_id)" />
          {Boolean(paymentMethodDetail?.paymentMethodId) ? <CopyLabelWithIcon text={paymentMethodDetail?.paymentMethodId} /> : <span>-</span>}
        </RowManageField>
        <RowManageField>
          <ManageFieldLabel label="Name" />
          <span>{paymentMethodDetail?.name ?? "-"}</span>
        </RowManageField>
        <RowManageField>
          <ManageFieldLabel label="Network" />
          <span>{paymentMethodDetail?.networkLabel ?? "-"}</span>
        </RowManageField>
        <RowManageField>
          <ManageFieldLabel label="Currency" />
          <span>{paymentMethodDetail?.currencyDetail?.currency?.symbol ?? "-"}</span>
        </RowManageField>
        <RowManageField>
          <ManageFieldLabel label="Type" />
          <span>{paymentTypeLabelParser(selectedPaymentType?.name) ?? "-"}</span>
        </RowManageField>
        <RowManageField>
          <ManageFieldLabel label="Block confirmation" />
          <span>{paymentMethodDetail?.blockConfirmationCount ?? "-"}</span>
        </RowManageField>
        <RowManageField>
          <ManageFieldLabel label="Contract address" />
          {Boolean(paymentMethodDetail?.contractAddress) ? <CopyLabelWithIcon text={paymentMethodDetail?.contractAddress} /> : <span>-</span>}
        </RowManageField>
        <RowManageField>
          <ManageFieldLabel label="Merchant address" />
          <span>{paymentMethodDetail?.adminAddress ?? "-"}</span>
        </RowManageField>
        <RowManageField>
          <ManageFieldLabel label="Deposit redirect URI" />
          <span>{paymentMethodDetail?.depositCallbackURI ?? "-"}</span>
        </RowManageField>
        <RowManageField>
          <ManageFieldLabel label="Withdrawal redirect URI" />
          <span>{paymentMethodDetail?.withdrawalCallbackURI ?? "-"}</span>
        </RowManageField>
      </ManageFieldContainer>
    </>
  );
};
