import { makeTransactionAction } from "action/requestAction";
import { getGasPriceFromServer } from "server/API/microChainAPI";
import Web3 from "web3";
import { actionController } from "../utils";
import { useSelector } from "react-redux";
import { connectInfo } from "store/redux/EQconnect/EQConnectSlice";
import { project_access } from "reducer/projectReducer";
import { makeQuery } from "server/index/utils";
import { removeComma } from "utils/Utils";
import { WEB3 } from "modules/web3/Web3";
import { getTokenDetailParser } from "parser/currencyParser";
import { getNetworkLabelAndMicroChainLabelParser } from "parser/microChainParser";
import { getListedContractDetailParser } from "parser/contractParser";
import { encodeABI, encodeABIWithByteCode } from "modules/web3/Web3Utils";
import {
  getAbiCodeByContractAddressParser,
  getAbiCodeByContractIdParser,
  getByteCodeByContractIdParser,
  getLoadBalancerParser,
  makeTransactionParser,
} from "parser/requestParser";
import { MicroChainCurrencyType } from "reducer/clientType/tokenClientType";
import { storeDispatch, storeState } from "reducer/index";
import { setRawTransactionResult } from "store/redux/transaction/transactionSlice";
import { getMicroChainFromServer } from "logic/services/MicroChain/server";

export const microChainController = {
  tokenMintWithMetamask: async (transaction: Function, callback: Function) => {
    return await actionController(async () => {
      const web3 = new Web3(window.ethereum);
      try {
        const { result, error } = await transaction();
        if (error) {
          throw new Error("tx_make_fail");
        }
        // MetaMask에서 사용할 계정 가져오기
        const accounts = await web3.eth.getAccounts();
        const account = accounts[0];
        const gasPrice = await web3.eth.getGasPrice();
        // 트랜잭션 파라미터 설정
        const txParams = {
          from: account, // 현재 MetaMask 연결된 계정
          to: result.to, // 민트할 컨트랙트 주소
          data: result.data, // 인코딩된 mint 함수 데이터
          value: result.value || "0x0", // 트랜잭션 값 (없으면 0)
          gasPrice: gasPrice, // gasPrice 설정
        };
        // 트랜잭션을 MetaMask를 통해 전송
        const txHash = await window.ethereum.request({
          method: "eth_sendTransaction",
          params: [txParams],
        });
        callback(txHash);
        return true;
      } catch (error) {
        throw new Error("tx_send_fail");
      }
    });
  },

  getGasPrice: async (microChainId: number) => {
    return await actionController(async () => {
      const { gas_price } = await getGasPriceFromServer({
        params: { microChainId },
      });
      if (gas_price) {
        return gas_price;
      } else {
        throw new Error("gas_not_found");
      }
    });
  },
  //tokenMintWithMetamask의 인자값
  makeMintTokenTransaction: async (
    currencyId: number,
    amount: number,
    toAddress: string,
    originMicroChainId: number,
    originMicroChainCurrency: MicroChainCurrencyType
  ) => {
    return await actionController(async () => {
      const connectInfoResult = connectInfo(storeState());
      const token = project_access(storeState());

      if (currencyId) {
        const abiCode = await getAbiCodeByContractAddressParser(
          originMicroChainId,
          originMicroChainCurrency.contractAddress
        );

        const parameters = [toAddress, WEB3.toWei(removeComma(amount))];
        const data = encodeABI(abiCode, "mint", parameters);

        const baseURL = await getLoadBalancerParser(originMicroChainId);

        const transactionPath = `${process.env.REACT_APP_HUB_SERVER_URL}${
          process.env.REACT_APP_API_VERSION_V2
        }wallet-manage/erc20/mint${makeQuery({
          microChainId: originMicroChainId,
        })}`;

        const additionalQuery = {
          body: {
            erc20Id: currencyId,
          },
        };

        const transaction = makeTransactionParser({
          address: connectInfoResult.address,
          microChainId: originMicroChainId,
          to: originMicroChainCurrency.contractAddress,
          value: null,
          functionName: "mint",
          baseURL,
          transactionPath,
          token,
          additionalQuery,
        });
        return {
          ...transaction,
          data,
        };
      } else {
        throw new Error("tx_make_fail");
      }
    });
  },

  makeMultiMintTokenTransaction: async (
    currencyId: number,
    amount: number,
    toAddress: string[],
    originMicroChainId: number,
    originMicroChainCurrency: MicroChainCurrencyType
  ) => {
    return await actionController(async () => {
      const connectInfoResult = connectInfo(storeState());
      const token = project_access(storeState());

      if (currencyId) {
        const abiCode = await getAbiCodeByContractAddressParser(
          originMicroChainId,
          originMicroChainCurrency.contractAddress
        );

        const parameters = toAddress.map((address) => {
          // 각 주소에 대해 mintParameters와 mintData를 생성
          const mintParameters = [address, WEB3.toWei(removeComma(amount))];
          return encodeABI(abiCode, "mint", mintParameters);
        });
        const data = encodeABI(abiCode, "multicall", [parameters]);
        //되는지 한번 보기

        const baseURL = await getLoadBalancerParser(originMicroChainId);

        const transactionPath = `${process.env.REACT_APP_HUB_SERVER_URL}${
          process.env.REACT_APP_API_VERSION_V2
        }wallet-manage/erc20/mint${makeQuery({
          microChainId: originMicroChainId,
        })}`;

        const additionalQuery = {
          body: {
            erc20Id: currencyId,
          },
        };

        const transaction = makeTransactionParser({
          address: connectInfoResult.address,
          microChainId: originMicroChainId,
          to: originMicroChainCurrency.contractAddress,
          value: null,
          functionName: "multicall",
          baseURL,
          transactionPath,
          token,
          additionalQuery,
        });
        return {
          ...transaction,
          data,
        };
      } else {
        throw new Error("tx_make_fail");
      }
    });
  },

  //getTokenDetailAction 참고
  //makeMintTokenTransaction을 위한 인자 생성
  getTokenDetail: async (currencyId: number) => {
    return await actionController(async () => {
      try {
        // tokenDetail 파싱
        const tokenDetail = await getTokenDetailParser(currencyId);

        // 네트워크 라벨과 마이크로체인 라벨을 가져옵니다.
        const { networkLabel, microChainLabel } =
          await getNetworkLabelAndMicroChainLabelParser(
            tokenDetail.originMicroChainId
          );

        // 해당하는 마이크로체인 통화 가져오기
        const originMicroChainCurrency = tokenDetail?.microChainCurrencies.find(
          (el) => el.microChainId === tokenDetail.originMicroChainId
        );

        // 트랜잭션 해시 설정
        let tokenContractTxHash: string | null = null;
        if (tokenDetail.mainStatus > 3 && originMicroChainCurrency) {
          const { transactionHash } = await getListedContractDetailParser(
            originMicroChainCurrency.contractId
          );
          tokenContractTxHash = transactionHash;
        }

        // 결과 객체 리턴
        return {
          ...tokenDetail,
          currencyDetail: {
            ...tokenDetail.currencyDetail,
            initialSupply: WEB3.fromWei(
              tokenDetail.currencyDetail.initialSupply
            ),
          },
          ...(originMicroChainCurrency && {
            originMicroChainCurrency: originMicroChainCurrency,
          }),
          transactionHash: tokenContractTxHash,
          networkLabel,
          microChainLabel,
        };
      } catch (error) {
        throw new Error("get_Token_detail_fail");
      }
    });
  },

  //deploy-----------------------------------------//

  makeDeployTokenTransaction: async (
    currencyId: number,
    originMicroChainCurrency: MicroChainCurrencyType,
    name: string,
    symbol: string,
    initialSupply: string
  ) => {
    return await actionController(async () => {
      const connectInfoResult = connectInfo(storeState());
      const token = project_access(storeState());

      if (currencyId) {
        const baseURL: string = await getLoadBalancerParser(
          originMicroChainCurrency.microChainId
        );
        const abiCode = await getAbiCodeByContractIdParser(
          originMicroChainCurrency.contractId
        );
        const byteCode: string = await getByteCodeByContractIdParser(
          originMicroChainCurrency.contractId
        );
        const projectAccessToken = project_access(storeState());

        const additionalQuery = {
          body: {
            contractId: originMicroChainCurrency.contractId,
          },
        };
        const parameters = [
          name,
          symbol,
          WEB3.toWei(removeComma(initialSupply)),
        ];

        const transaction = makeTransactionParser({
          address: connectInfoResult.address,
          microChainId: originMicroChainCurrency.microChainId,
          to: null,
          value: null,
          functionName: "registerContract",
          baseURL,
          transactionPath: `${process.env.REACT_APP_HUB_SERVER_URL}${process.env.REACT_APP_API_VERSION_V2}currency/${currencyId}/release-contract`,
          token: projectAccessToken,
          additionalQuery,
        });
        const data: string = encodeABIWithByteCode(
          abiCode,
          byteCode,
          parameters
        );
        return {
          ...transaction,
          data,
        };
      } else {
        throw new Error("tx_make_fail");
      }
    });
  },

  tokenDeployWithMetamask: async (
    transaction: Function,
    callback: Function
  ) => {
    return await actionController(async () => {
      const web3 = new Web3(window.ethereum);
      try {
        const { result, error } = await transaction();
        if (error) {
          throw new Error("tx_make_fail");
        }
        // MetaMask에서 사용할 계정 가져오기
        const accounts = await web3.eth.getAccounts();
        const account = accounts[0];
        const gasPrice = await web3.eth.getGasPrice();
        // 트랜잭션 파라미터 설정
        const txParams = {
          from: account, // 현재 MetaMask 연결된 계정
          to: result.to, // 민트할 컨트랙트 주소
          data: result.data, // 인코딩된 mint 함수 데이터
          value: result.value || "0x0", // 트랜잭션 값 (없으면 0)
          gasPrice: gasPrice, // gasPrice 설정
        };
        // 트랜잭션을 MetaMask를 통해 전송
        const txHash = await window.ethereum.request({
          method: "eth_sendTransaction",
          params: [txParams],
        });
        if (txHash) {
          callback();
          storeDispatch(setRawTransactionResult({ tx_hash: "" }));
          return true;
        }
      } catch (error) {
        throw new Error("tx_send_fail");
      }
    });
  },

  //burn-----------------------------------------//

  makeBurnTokenTransaction: async (
    currencyId: number,
    amount: number,
    originMicroChainId: number,
    originMicroChainCurrency: MicroChainCurrencyType
  ) => {
    return await actionController(async () => {
      const connectInfoResult = connectInfo(storeState());
      const token = project_access(storeState());

      if (currencyId) {
        const abiCode = await getAbiCodeByContractAddressParser(
          originMicroChainId,
          originMicroChainCurrency.contractAddress
        );

        const parameters = [WEB3.toWei(removeComma(amount))];
        const data = encodeABI(abiCode, "burn", parameters);

        const baseURL = await getLoadBalancerParser(originMicroChainId);

        const transactionPath = `${process.env.REACT_APP_HUB_SERVER_URL}${
          process.env.REACT_APP_API_VERSION_V2
        }wallet-manage/erc20/burn${makeQuery({
          microChainId: originMicroChainId,
        })}`;

        const additionalQuery = {
          body: {
            erc20Id: currencyId,
          },
        };

        const transaction = makeTransactionParser({
          address: connectInfoResult.address,
          microChainId: originMicroChainId,
          to: originMicroChainCurrency.contractAddress,
          value: null,
          functionName: "burn",
          baseURL,
          transactionPath,
          token,
          additionalQuery,
        });
        return {
          ...transaction,
          data,
        };
      } else {
        throw new Error("tx_make_fail");
      }
    });
  },

  makeBurnFromTokenTransaction: async (
    currencyId: number,
    amount: number,
    originMicroChainId: number,
    originMicroChainCurrency: MicroChainCurrencyType,
    fromAddress: string //기존 소유주 계정
  ) => {
    return await actionController(async () => {
      const connectInfoResult = connectInfo(storeState());
      const token = project_access(storeState());

      if (currencyId) {
        const abiCode = await getAbiCodeByContractAddressParser(
          originMicroChainId,
          originMicroChainCurrency.contractAddress
        );

        const parameters = [fromAddress, WEB3.toWei(removeComma(amount))];
        const data = encodeABI(abiCode, "burnFrom", parameters);

        const baseURL = await getLoadBalancerParser(originMicroChainId);

        const transactionPath = `${process.env.REACT_APP_HUB_SERVER_URL}${
          process.env.REACT_APP_API_VERSION_V2
        }wallet-manage/erc20/burnFrom${makeQuery({
          microChainId: originMicroChainId,
        })}`;

        const additionalQuery = {
          body: {
            erc20Id: currencyId,
          },
        };

        const transaction = makeTransactionParser({
          address: connectInfoResult.address, //allowance를 받은 소유자
          microChainId: originMicroChainId,
          to: originMicroChainCurrency.contractAddress,
          value: null,
          functionName: "burnFrom",
          baseURL,
          transactionPath,
          token,
          additionalQuery,
        });
        return {
          ...transaction,
          data,
        };
      } else {
        throw new Error("tx_make_fail");
      }
    });
  },

  tokenBurnWithMetamask: async (transaction: Function, callback: Function) => {
    return await actionController(async () => {
      const web3 = new Web3(window.ethereum);
      try {
        const { result, error } = await transaction();
        if (error) {
          throw new Error("tx_make_fail");
        }
        // MetaMask에서 사용할 계정 가져오기
        const accounts = await web3.eth.getAccounts();
        const account = accounts[0];
        const gasPrice = await web3.eth.getGasPrice();
        // 트랜잭션 파라미터 설정
        const txParams = {
          from: account, // 현재 MetaMask 연결된 계정
          to: result.to, // 컨트랙트 주소
          data: result.data, // 인코딩된 mint 함수 데이터
          value: result.value || "0x0", // 트랜잭션 값 (없으면 0)
          gasPrice: gasPrice, // gasPrice 설정
        };
        // 트랜잭션을 MetaMask를 통해 전송
        const txHash = await window.ethereum.request({
          method: "eth_sendTransaction",
          params: [txParams],
        });
        callback(txHash);
        return true;
      } catch (error) {
        throw new Error("tx_send_fail");
      }
    });
  },

  //Transfer-----------------------------------------//
  makeTransferTokenTransaction: async (
    currencyId: number,
    amount: number,
    originMicroChainId: number,
    originMicroChainCurrency: MicroChainCurrencyType,
    value: string,
    toAddress: string
  ) => {
    return await actionController(async () => {
      const connectInfoResult = connectInfo(storeState());
      const token = project_access(storeState());

      if (currencyId) {
        const abiCode = await getAbiCodeByContractAddressParser(
          originMicroChainId,
          originMicroChainCurrency.contractAddress
        );

        const parameters = [toAddress, WEB3.toWei(removeComma(amount))];
        const data = encodeABI(abiCode, "transfer", parameters);

        const baseURL = await getLoadBalancerParser(originMicroChainId);

        const transactionPath = `${process.env.REACT_APP_HUB_SERVER_URL}${
          process.env.REACT_APP_API_VERSION_V2
        }wallet-manage/erc20/transfer${makeQuery({
          microChainId: originMicroChainId,
        })}`;

        const additionalQuery = {
          body: {
            erc20Id: currencyId,
          },
        };

        const transaction = makeTransactionParser({
          address: connectInfoResult.address,
          microChainId: originMicroChainId,
          to: originMicroChainCurrency.contractAddress,
          value,
          functionName: "transfer",
          baseURL,
          transactionPath,
          token,
          additionalQuery,
        });
        return {
          ...transaction,
          data,
        };
      } else {
        throw new Error("tx_make_fail");
      }
    });
  },
  //transferFrom
  makeTransferFromTokenTransaction: async (
    currencyId: number,
    amount: number,
    originMicroChainId: number,
    originMicroChainCurrency: MicroChainCurrencyType,
    value: string,
    toAddress: string,
    fromAddress: string
  ) => {
    return await actionController(async () => {
      const connectInfoResult = connectInfo(storeState());
      const token = project_access(storeState());

      if (currencyId) {
        const abiCode = await getAbiCodeByContractAddressParser(
          originMicroChainId,
          originMicroChainCurrency.contractAddress
        );

        const parameters = [
          fromAddress,
          toAddress,
          WEB3.toWei(removeComma(amount)),
        ];
        const data = encodeABI(abiCode, "transferFrom", parameters);

        const baseURL = await getLoadBalancerParser(originMicroChainId);

        const transactionPath = `${process.env.REACT_APP_HUB_SERVER_URL}${
          process.env.REACT_APP_API_VERSION_V2
        }wallet-manage/erc20/transferFrom${makeQuery({
          microChainId: originMicroChainId,
        })}`;

        const additionalQuery = {
          body: {
            erc20Id: currencyId,
          },
        };

        const transaction = makeTransactionParser({
          address: connectInfoResult.address,
          microChainId: originMicroChainId,
          to: originMicroChainCurrency.contractAddress,
          value,
          functionName: "transferFrom",
          baseURL,
          transactionPath,
          token,
          additionalQuery,
        });
        return {
          ...transaction,
          data,
        };
      } else {
        throw new Error("tx_make_fail");
      }
    });
  },
  //multiTransfer
  makeMultiTransferTokenTransaction: async (
    currencyId: number,
    amount: number,
    originMicroChainId: number,
    originMicroChainCurrency: MicroChainCurrencyType,
    value: string,
    toAddress: string[]
  ) => {
    return await actionController(async () => {
      const connectInfoResult = connectInfo(storeState());
      const token = project_access(storeState());

      if (currencyId) {
        const abiCode = await getAbiCodeByContractAddressParser(
          originMicroChainId,
          originMicroChainCurrency.contractAddress
        );

        const parameters = toAddress.map((address) => {
          // 각 주소에 대해 mintParameters와 mintData를 생성
          const mintParameters = [address, WEB3.toWei(removeComma(amount))];
          return encodeABI(abiCode, "transfer", mintParameters);
        });
        const data = encodeABI(abiCode, "multicall", [parameters]);

        const baseURL = await getLoadBalancerParser(originMicroChainId);

        const transactionPath = `${process.env.REACT_APP_HUB_SERVER_URL}${
          process.env.REACT_APP_API_VERSION_V2
        }wallet-manage/erc20/transfer${makeQuery({
          microChainId: originMicroChainId,
        })}`;

        const additionalQuery = {
          body: {
            erc20Id: currencyId,
          },
        };

        const transaction = makeTransactionParser({
          address: connectInfoResult.address,
          microChainId: originMicroChainId,
          to: originMicroChainCurrency.contractAddress,
          value,
          functionName: "multicall",
          baseURL,
          transactionPath,
          token,
          additionalQuery,
        });
        return {
          ...transaction,
          data,
        };
      } else {
        throw new Error("tx_make_fail");
      }
    });
  },

  sendTokenTransactionWithMetamask: async (
    transaction: Function,
    callback: Function
  ) => {
    return await actionController(async () => {
      const web3 = new Web3(window.ethereum);
      try {
        const { result, error } = await transaction();
        if (error) {
          throw new Error("tx_make_fail");
        }
        // MetaMask에서 사용할 계정 가져오기
        const accounts = await web3.eth.getAccounts();
        const account = accounts[0];
        const gasPrice = await web3.eth.getGasPrice();
        // 트랜잭션 파라미터 설정
        const txParams = {
          from: account, // 현재 MetaMask 연결된 계정
          to: result.to, // 민트할 컨트랙트 주소
          data: result.data, // 인코딩된 Transfer 함수 데이터
          value: result.value || "0x0", // 트랜잭션 값 (없으면 0)
          gasPrice: gasPrice, // gasPrice 설정
        };
        // 트랜잭션을 MetaMask를 통해 전송
        const txHash = await window.ethereum.request({
          method: "eth_sendTransaction",
          params: [txParams],
        });
        callback(txHash);
        return true;
      } catch (error) {
        throw new Error("tx_send_fail");
      }
    });
  },

  getMicroChainList: (option: {
    networkId?: number;
    microChainIds?: number[];
    projectId?: number;
    creatorId?: number;
  }) => {
    return actionController(async () => {
      try {
        const externalMicroChain = await getMicroChainFromServer({
          query: {
            ...(option.networkId && { networkId: option.networkId }),
            ...(option.microChainIds && {
              microChainIds: option.microChainIds,
            }),
            external: true,
            ...(option.projectId && { projectId: option.projectId }),
            ...(option.creatorId && { creatorId: option.creatorId }),
          },
        });
        const internalMicroChain = await getMicroChainFromServer({
          query: {
            ...(option.networkId && { networkId: option.networkId }),
            ...(option.microChainIds && {
              microChainIds: option.microChainIds,
            }),
            external: false,
            ...(option.projectId && { projectId: option.projectId }),
            ...(option.creatorId && { creatorId: option.creatorId }),
          },
        });
        const combinedMicroChain = [
          ...externalMicroChain,
          ...internalMicroChain,
        ];
        return combinedMicroChain;
      } catch (e) {
        throw new Error("microChain_get_fail");
      }
    });
  },

  getGasFee: () => {
    return actionController(async () => {
      const web3 = new Web3(window.ethereum);
      try {
        const gasPriceInWei = await web3.eth.getGasPrice();
        const gasPriceInEther = web3.utils.fromWei(gasPriceInWei, "ether");
        const gasPrice = {
          gasPriceInWei: gasPriceInWei,
          gasPriceInEther: gasPriceInEther,
        };
        return gasPrice;
      } catch (e) {
        throw new Error("gasPrice_get_fail");
      }
    });
  },
};
