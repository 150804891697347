import {
  confirmMicroChainAssetParser,
  confirmWalletParser,
  createMicroChainAssetWalletParser,
  createWalletParser,
  deleteMicroChainAssetParser,
  deleteWalletParser,
  getAssetTypeListParser,
  getMicroChainAssetDetailParser,
  getMicroChainAssetListParser,
  getMicroChainAssetRejectReasonParser,
  getWalletDefaultImageListParser,
  getWalletRejectReasonParser,
  rejectMicroChainAssetParser,
  rejectWalletParser,
  releaseMicroChainAssetParser,
  releaseWalletParser,
  submitMicroChainAssetParser,
  submitWalletParser,
  uploadWalletImageParser,
} from "../parser/walletParser";
import { parseAction } from "./util/parseAction";
import { getMicroChainDetailParser, getMicroChainListParser, getNetworkLabelAndMicroChainLabelParser } from "../parser/microChainParser";
import _ from "lodash";
import { networkParserNew } from "../utils/Utils";
import { ASSET_PURPOSE_TYPE_COIN, ASSET_PURPOSE_TYPE_NFT, ASSET_PURPOSE_TYPE_TOKEN } from "view/service/tool/wallet/constants/walletConstants";

export const getInitialDataFromRegisterWalletAction = (options: { projectId: number; mainStatus: number[] }) =>
  parseAction(async () => {
    const registeredStatus = [5];
    const [defaultImageList, assetTypeList, microChainList, createdWalletList] = await Promise.all([
      getWalletDefaultImageListParser(),
      getAssetTypeListParser(),
      getMicroChainListParser({ projectId: options.projectId, mainStatus: registeredStatus }),
      getMicroChainAssetListParser(options),
    ]);

    const response = assetTypeList.map((asset: any) => {
      const filterCreatedWalletListByAssetId = createdWalletList.filter((wallet: any) => wallet.assetId === asset.assetId);
      const createdWalletMicroChainIds = _.uniq(filterCreatedWalletListByAssetId.map((el: any) => el.microChainId));
      const availableMicroChains = microChainList.filter((chain: any) => !createdWalletMicroChainIds.includes(chain.id));
      const networkList = networkParserNew(availableMicroChains);
      return {
        assetTypeId: asset.assetId,
        defaultImageList,
        assetType: asset,
        microChainList: availableMicroChains,
        networkList,
      };
    });

    return response;
  });

export const createWalletAction = (microChainId: number, assetId: number, cardImage: string | File, fontColor: string) =>
  parseAction(async () => {
    let result: any;
    if (assetId === ASSET_PURPOSE_TYPE_COIN) {
      let uploadWalletImage;
      if (typeof cardImage !== "string") {
        let formData = new FormData();
        formData.append("image", cardImage);
        uploadWalletImage = await uploadWalletImageParser(formData, 1);
      }

      const { url } = uploadWalletImage || {};
      if (url) {
        uploadWalletImage = url;
      } else {
        uploadWalletImage = cardImage;
      }
      result = await createWalletParser(microChainId, uploadWalletImage, fontColor);
    }

    if (assetId === ASSET_PURPOSE_TYPE_NFT) {
      result = await createMicroChainAssetWalletParser(microChainId, assetId);
    }

    return result;
  });

export const getWalletListAction = (options: { projectId: number; mainStatus: number[] }) =>
  parseAction(async () => {
    const walletList = await getMicroChainAssetListParser(options);

    const microChainIds = _.uniq(walletList.map((el: any) => el.microChainId));
    const microChainList = await Promise.all(
      microChainIds.map(async (el: any) => {
        const microChainDetailResult = await getMicroChainDetailParser(el);
        return microChainDetailResult;
      })
    );

    const walletListWithFilteredToken = walletList.filter((el: any) => el.assetId !== ASSET_PURPOSE_TYPE_TOKEN);
    const response = walletListWithFilteredToken.map((el: any) => {
      const chainData = microChainList.find((item: any) => item.microChain.id === el.microChainId);
      return {
        ...el,
        networkLabel: chainData.network.label ?? null,
        microChainLabel: chainData.microChain.label ?? null,
      };
    });

    return response;
  });

export const getWalletDetailAction = (microChainAssetId: number) =>
  parseAction(async () => {
    const walletDetail = await getMicroChainAssetDetailParser(microChainAssetId);

    const { mainStatus, assetId, microChainId } = walletDetail || {};

    const chainData = await getNetworkLabelAndMicroChainLabelParser(microChainId);

    let rejectReason: string = "";
    if (mainStatus === 0) {
      if (assetId === ASSET_PURPOSE_TYPE_NFT) {
        // 지갑의 유형이 NFT (ERC-721) 지갑일 경우 리뉴얼된 라우터로 요청
        rejectReason = await getMicroChainAssetRejectReasonParser(microChainAssetId);
      } else {
        // 지갑의 유형이 NFT (ERC-721) 지갑이 아닌 경우 이전 라우터로 요청
        rejectReason = await getWalletRejectReasonParser(microChainId);
      }
    }

    const response = {
      ...walletDetail,
      ...chainData,
      ...(rejectReason && { rejectReason }),
    };

    return response;
  });

export const submitWalletAction = (microChainId: number, microChainAssetId: number, assetId: number) =>
  parseAction(async () => {
    let submitResult;

    if (assetId === ASSET_PURPOSE_TYPE_NFT) {
      submitResult = await submitMicroChainAssetParser(microChainAssetId);
    } else {
      submitResult = await submitWalletParser(microChainId);
    }

    let response: object = {};
    if (submitResult.success) {
      const walletDetail = await getMicroChainAssetDetailParser(microChainAssetId);

      const { microChainId } = walletDetail || {};

      const chainData = await getNetworkLabelAndMicroChainLabelParser(microChainId);

      response = {
        ...walletDetail,
        ...chainData,
      };
    }

    return response;
  });

export const rejectWalletAction = ({ microChainId, microChainAssetId, rejectReason, assetId }: any) =>
  parseAction(async () => {
    let rejectResult;

    if (assetId === ASSET_PURPOSE_TYPE_NFT) {
      rejectResult = await rejectMicroChainAssetParser(microChainAssetId, rejectReason);
    } else {
      rejectResult = await rejectWalletParser(microChainId, rejectReason);
    }

    return rejectResult;
  });

export const confirmWalletAction = ({ microChainId, microChainAssetId, assetId }: any) =>
  parseAction(async () => {
    let confirmResult;

    if (assetId === ASSET_PURPOSE_TYPE_NFT) {
      confirmResult = await confirmMicroChainAssetParser(microChainAssetId);
    } else {
      confirmResult = await confirmWalletParser(microChainId);
    }

    return confirmResult;
  });

export const releaseWalletAction = (projectId: number, microChainId: number, microChainAssetId: number, assetId: number) =>
  parseAction(async () => {
    let releaseResult;

    if (assetId === ASSET_PURPOSE_TYPE_NFT) {
      releaseResult = await releaseMicroChainAssetParser(projectId, microChainAssetId);
    } else {
      releaseResult = await releaseWalletParser(microChainId);
    }

    return releaseResult;
  });

export const deleteWalletAction = (microChainId: number, microChainAssetId: number, assetId: number) =>
  parseAction(async () => {
    let deleteResult;

    if (assetId === ASSET_PURPOSE_TYPE_NFT) {
      deleteResult = await deleteMicroChainAssetParser(microChainAssetId);
    } else {
      deleteResult = await deleteWalletParser(microChainId);
    }

    return deleteResult;
  });
