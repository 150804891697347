import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { isArrayParameter, isCorrectWalletAddressRegex, isProxyContract, removeEmptyElement } from "../utils/contractUtils";
import toast from "react-hot-toast";
import PageLoading from "../../../../components/_atom/loading/PageLoading";
import Input from "../../../../../legacy-common/atom/input/Input";
import Select from "../../../../components/_atom/select/Select";
import ParameterHandler from "../molcules/ParameterHandler";
import ButtonContainer from "../../../../../legacy-common/template/ButtonContainer";
import EQConnectWalletButton from "../../../../../modules/EQConnect/EQConnectWalletButton";
import useIsAbleByConditionLegacy from "../../../../../hooks/useIsAbleByConditionLegacy";
import { connectInfo } from "../../../../../store/redux/EQconnect/EQConnectSlice";
import RequestTransactionButton from "../../../../../modules/EQExtension/RequestTransactionButton";
import { errorAlert } from "../../../../../utils/Utils";
import { makeTransactionAction } from "../../../../../action/requestAction";
import { encodeABI } from "../../../../../modules/web3/Web3Utils";
import Section from "../../../../../legacy-common/atom/section/Section";
import { getAbiCodeForUpgradeContractAction, getProxyContractDetailForUpgradeAction } from "../../../../../action/contractAction";
import PageArticle from "../../../../components/_template/page/PageArticle";
import PageTitle from "../../../../components/_molecules/page/PageTitle";

const UpgradeProxyContract = () => {
  const navigate = useNavigate();
  const { contractId } = useParams();
  const { address } = useSelector(connectInfo);

  const [isLoading, setIsLoading] = useState(true);

  const [proxyContractDetail, setProxyContractDetail] = useState(null);
  const [implementationContractAddress, setImplementationContractAddress] = useState("");

  const [abiCode, setAbiCode] = useState(null);
  const [functions, setFunctions] = useState(null);
  const [selectedFunctionId, setSelectedFunctionId] = useState(0);
  const [selectedFunction, setSelectedFunction] = useState(null);
  const [selectedFunctionInputs, setSelectedFunctionInputs] = useState(null);

  const [functionParameters, setFunctionParameters] = useState(null);

  const isAbleToUpgrade = useMemo(() => {
    if (selectedFunctionId === 0) {
      return true;
    } else {
      if (functionParameters && selectedFunctionInputs) {
        return functionParameters.reduce((acc, cur, index) => {
          if (typeof selectedFunctionInputs[index].type === "string") {
            return Boolean(acc) && Boolean(cur);
          }
        }, true);
      } else return false;
    }
  }, [selectedFunctionId, functionParameters, selectedFunctionInputs]);

  const { isAble } = useIsAbleByConditionLegacy([implementationContractAddress, isAbleToUpgrade, address]);

  const implementationAddressHandler = (e) => {
    if (e.target.value.length > 42) return;
    setImplementationContractAddress(e.target.value);
  };

  const setProxyContractDetailForUpgrade = async () => {
    const { error, result } = await getProxyContractDetailForUpgradeAction(Number(contractId));
    if (error) {
      errorAlert(error.data.message);
    } else {
      if (!isProxyContract(result)) {
        toast.error("Only proxy contract can request upgrade");
        setTimeout(() => {
          navigate(`/developer/contract/list/${contractId}`);
        }, 1500);
      } else {
        setProxyContractDetail(result);
        setIsLoading(false);
      }
    }
  };

  const setAbiCodeForUpgradeContract = async () => {
    const { error, result } = await getAbiCodeForUpgradeContractAction(implementationContractAddress, proxyContractDetail.microChainId);
    if (error) {
      errorAlert(error.data.message);
    } else {
      const { abiCode, functions } = result;
      const excludeUpgradeFunctions = functions.filter((func) => func.name !== "upgradeTo" && func.name !== "upgradeToAndCall");
      const functionArray = excludeUpgradeFunctions.map((el, index) => {
        return {
          ...el,
          id: index + 1,
        };
      });
      const defaultValue = [
        {
          id: 0,
          name: "Not selected",
        },
      ];
      setAbiCode(abiCode);
      setFunctions(defaultValue.concat(functionArray));
    }
  };

  const requestUpgradeContract = async () => {
    if (selectedFunctionId === 0) {
      return await makeTransactionAction({
        address: address,
        microChainId: proxyContractDetail.microChainId,
        contractAddress: implementationContractAddress,
        parameters: [implementationContractAddress, "0x"],
        to: proxyContractDetail.contractAddress,
        value: null,
        functionName: "upgradeToAndCall",
      });
    } else {
      try {
        const parameters = removeEmptyElement(functionParameters);
        const data = encodeABI(abiCode, selectedFunction.name, [...parameters]);
        return await makeTransactionAction({
          address: address,
          microChainId: proxyContractDetail.microChainId,
          contractAddress: implementationContractAddress,
          parameters: [implementationContractAddress, data],
          to: proxyContractDetail.contractAddress,
          value: null,
          functionName: "upgradeToAndCall",
        });
      } catch (e) {
        return {
          result: undefined,
          error: e,
        };
      }
    }
  };

  const onSuccessUpgrade = async () => {
    navigate(`/developer/contract/list/${contractId}`);
  };

  const UPGRADE_CONTRACT_VIEW = () => [
    {
      renderers: [
        {
          index: "Contract name",
          value: proxyContractDetail.name,
        },
        {
          index: "Contract Address",
          value: proxyContractDetail.contractAddress,
        },
        {
          index: "Previous implementation",
          value: proxyContractDetail.implementationContractAddress,
        },
        {
          index: "New implementation",
          value: (
            <Input
              value={implementationContractAddress}
              onChange={(e) => implementationAddressHandler(e)}
              placeholder={"Enter the address of new implementation"}
              maxLength={42}
            />
          ),
        },
      ],
    },
  ];

  const UPGRADE_FUNCTION_VIEW = () => [
    functions && {
      renderers: [
        {
          index: "Select function to call",
          value: (
            <Select
              placeholder={"Not selected"}
              options={functions}
              selectedValue={selectedFunctionId}
              setSelectedValue={setSelectedFunctionId}
              labelKey={"name"}
              valueKey={"id"}
            />
          ),
        },
      ],
    },
    functionParameters &&
      functionParameters.length !== 0 && {
        renderers: selectedFunctionInputs.map((input, index) => {
          return {
            index: `- ${input.name} (${input.type})`,
            value: <ParameterHandler key={index} input={input} parameters={functionParameters} setParameters={setFunctionParameters} index={index} />,
          };
        }),
      },
  ];

  useEffect(() => {
    setProxyContractDetailForUpgrade();
  }, []);

  useEffect(() => {
    if (isCorrectWalletAddressRegex(implementationContractAddress)) {
      setAbiCodeForUpgradeContract();
    } else {
      setAbiCode(null);
      setFunctions(null);
    }
  }, [implementationContractAddress]);

  useEffect(() => {
    if (Boolean(selectedFunctionId)) {
      const selectedFunction = functions.find((func) => func.id === selectedFunctionId);
      const newArr = selectedFunction.inputs.map((input) => {
        if (isArrayParameter(input.type)) return [];
        else return "";
      });
      setSelectedFunction(selectedFunction);
      setSelectedFunctionInputs(selectedFunction.inputs);
      setFunctionParameters(newArr);
    } else {
      setSelectedFunction(null);
      setSelectedFunctionInputs(null);
      setFunctionParameters(null);
    }
  }, [selectedFunctionId]);

  if (isLoading) {
    return <PageLoading />;
  }

  return (
    <PageArticle>
      <PageTitle title={"Upgrade Contract"} size={810} />
      <div className="page-layout-810">
        <Section
          title={"Contract Info"}
          description={"Upgrade the implementation contract connected with the proxy contract to a new contract."}
          view={UPGRADE_CONTRACT_VIEW}
        />
        {functions && <Section title={"Function Call"} view={UPGRADE_FUNCTION_VIEW} />}
        <ButtonContainer>
          <EQConnectWalletButton />
          <div style={{ width: 10 }} />
          <RequestTransactionButton text={"Upgrade"} disabled={!isAble} onRequest={() => requestUpgradeContract()} onSuccess={() => onSuccessUpgrade()} />
        </ButtonContainer>
      </div>
    </PageArticle>
  );
};

export default UpgradeProxyContract;
