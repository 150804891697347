import {
  getContractAbiCodeByContractAddressFromServer,
  getContractByContractAddressFromServer,
  getContractConstructorByContractIdFromServer,
  getContractToListFromServer,
  getListedContractDetailFromServer,
  getMyListedContractFromServer,
  postTransactionRequestFromServer,
} from "../server/API/contractAPI";
import { ListedContract } from "../reducer/clientType/contractClientType";
import { AbiWithValue } from "../server/type/contract.server.type";

export const getContractToListParser = async (
  microChainId: number,
  name: string,
  description: string,
  contractId: number,
  contractVersionId: number,
  contractConstructorInputs: Array<any>,
  implementationContractId?: number
): Promise<{
  contractId: number;
}> => {
  const result = await getContractToListFromServer({
    data: {
      microChainId,
      name,
      description,
      contractId,
      contractVersionId,
      contractConstructorInputs,
      ...(implementationContractId && { implementationContractId }),
    },
  });
  return {
    contractId: result.contract_id,
  };
};

export const getMyListedContractParser = async (options?: {
  contractAddress?: Array<string>;
  microChainId?: Array<number>;
  creatorId?: number;
  name?: string;
  mainStatus?: Array<number>;
  subStatus?: Array<number>;
  createdAt?: string;
  ercType?: number;
  lastContractId?: number;
  limit?: number;
  order?: "ASC" | "DESC";
}): Promise<ListedContract[]> => {
  const result = await getMyListedContractFromServer({
    query: {
      ...(options?.contractAddress && {
        contractAddress: options.contractAddress,
      }),
      ...(options?.microChainId && { microChainId: options.microChainId }),
      ...(options?.creatorId && { creatorId: options.creatorId }),
      ...(options?.name && { name: options.name }),
      ...(options?.mainStatus && { mainStatus: options.mainStatus }),
      ...(options?.subStatus && { subStatus: options.subStatus }),
      ...(options?.createdAt && { createdAt: options.createdAt }),
      ...(options?.ercType && { ercType: options.ercType }),
      ...(options?.lastContractId && {
        lastContractId: options.lastContractId,
      }),
      ...(options?.limit && { limit: options.limit }),
      order: options?.order ?? "DESC",
    },
  });
  return result.map((contract) => ({
    contractId: contract.contract_id,
    microChainId: contract.micro_chain_id,
    creatorId: contract.creator_id,
    name: contract.name,
    contractAddress: contract.contract_address,
    description: contract.description,
    type: contract.type,
    mainStatus: contract.main_status,
    subStatus: contract.sub_status,
    ercType: contract.erc_type,
    isSystem: contract.is_system,
    originContractId: contract.origin_contract_id,
    originContractVersionId: contract.origin_contract_version_id,
    originVersion: contract.origin_version,
    projectId: contract.project_id,
    transactionHash: contract.transaction_hash,
    implementationContractId: contract.implementation_contract_id,
  }));
};

export const getContractConstructorByContractIdParser = async (
  contractId: number
): Promise<AbiWithValue> => {
  return await getContractConstructorByContractIdFromServer({
    params: {
      contractId,
    },
    query: {
      isInternalUse: true,
    },
  });
};

export const getContractByContractAddressParser = async (
  contractAddress: string,
  microChainId: number
): Promise<ListedContract> => {
  const result = await getContractByContractAddressFromServer({
    params: {
      contractAddress,
    },
    query: {
      microChainId,
    },
  });
  return {
    contractId: result.contract_id,
    microChainId: result.micro_chain_id,
    creatorId: result.creator_id,
    name: result.name,
    contractAddress: result.contract_address,
    description: result.description,
    type: result.type,
    mainStatus: result.main_status,
    subStatus: result.sub_status,
    ercType: result.erc_type,
    isSystem: result.is_system,
    originContractId: result.origin_contract_id,
    originContractVersionId: result.origin_contract_version_id,
    originVersion: result.origin_version,
    projectId: result.project_id,
    transactionHash: result.transaction_hash,
    implementationContractId: result.implementation_contract_id,
  };
};

export const getListedContractDetailParser = async (
  contractId: number
): Promise<ListedContract> => {
  const result = await getListedContractDetailFromServer({
    params: {
      contractId,
    },
    query: {
      isInternalUse: true,
    },
  });

  return {
    contractId: result.contract_id,
    microChainId: result.micro_chain_id,
    creatorId: result.creator_id,
    name: result.name,
    contractAddress: result.contract_address,
    description: result.description,
    type: result.type,
    mainStatus: result.main_status,
    subStatus: result.sub_status,
    ercType: result.erc_type,
    isSystem: result.is_system,
    originContractId: result.origin_contract_id,
    originContractVersionId: result.origin_contract_version_id,
    originVersion: result.origin_version,
    projectId: result.project_id,
    transactionHash: result.transaction_hash,
    implementationContractId: result.implementation_contract_id,
  };
};

export const getABICodeByContractAddressParser = async (
  contractAddress: string,
  microChainId: number
) => {
  return await getContractAbiCodeByContractAddressFromServer({
    params: { contractAddress },
    query: { microChainId },
  });
};

export const postTransactionRequestParser = async (
  currencyId: number,
  contractId: number,
  rawTransaction: string
) => {
  return await postTransactionRequestFromServer({
    params: { currencyId },
    data: { contractId, rawTransaction },
  });
};
