import {
  checkProjectNameFromServer,
  createProjectFromServer,
  deleteInvitationFromServer,
  generateProjectTokenFromServer,
  getMethodDominanceFromServer,
  getMonthlyHistoryFromServer,
  getMyProjectByProjectTokenFromServer,
  getNetworkDominanceFromServer,
  getPricingPlanFromServer,
  getPricingPlansFromServer,
  getProjectCreditFromServer,
  getProjectDefaultImageFromServer,
  getProjectInvitationsFromServer,
  getProjectMembersFromServer,
  getProjectNetworksFromServer,
  getProjectReceiptsFromServer,
  getProjectRolesFromServer,
  getProjectsFromServer,
  getRequestActivityFromServer,
  getRequestVolumeFromServer,
  inviteProjectMemberFromServer,
  removeMemberFromServer,
  updateProjectFromServer,
  uploadProjectImageFromServer,
} from "../server/API/projectAPI";
import {
  NetworkDominance,
  PricingPlan,
  Project,
  ProjectChart,
  ProjectDefaultImage,
  ProjectInvitation,
  ProjectMember,
  ProjectRequests,
  ProjectRole,
  MonthlyHistory,
  ProjectReceipt,
  ProjectCredit,
} from "../reducer/clientType/projectClientType";
import { Tokens } from "../reducer/clientType/userClientType";
import { acceptInvitationFromServer, getInvitedProjectListFromServer, geyMyProjectsFromServer } from "../server/API/userAPI";
import { FromTick, ProjectRequestsTab } from "server/type/projectType";
import moment from "moment";
import _ from "lodash";

export const getProjectDefaultImageParser = async (): Promise<Array<ProjectDefaultImage>> => {
  const result = await getProjectDefaultImageFromServer();

  const response = result.map((el) => ({
    projectDefaultImage: el.project_default_image,
    url: el.url,
    createdAt: el.created_at,
  }));
  return response;
};

export const generateProjectTokenParser = async (projectId: number, accessToken?: string): Promise<Tokens> => {
  const result = await generateProjectTokenFromServer({
    data: {
      projectId,
    },
    ...(accessToken && {
      config: {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    }),
  });
  return {
    access: {
      token: result.project_token.access.token,
      expires: result.project_token.access.expires,
    },
    refresh: {
      expires: result.project_token.refresh.expires,
    },
  };
};

export const uploadProjectImageParser = async (formData: FormData, fileCount: number): Promise<string> => {
  const result = await uploadProjectImageFromServer({
    query: {
      fileCount,
    },
    data: formData,
  });

  return result.project_image;
};

export const createProjectParser = async (
  name: string,
  image: string,
  pricingPlanId: number,
  options?: { description?: string; selectedMicroChainIds?: Array<number> }
): Promise<Project> => {
  const result = await createProjectFromServer({
    data: {
      name,
      image,
      pricingPlanId,
      ...(options?.description && { description: options.description }),
      ...(options?.selectedMicroChainIds && { selectedMicroChainIds: options.selectedMicroChainIds }),
    },
  });
  return {
    createdAt: result.created_at,
    creatorId: result.creator_id,
    description: result.description,
    id: result.id,
    image: result.image,
    name: result.name,
    pricingPlanId: result.pricing_plan_id,
  };
};

export const getMyProjectsParser = async (): Promise<Project[]> => {
  const result = await geyMyProjectsFromServer();
  return result.map((el) => ({
    createdAt: el.created_at,
    creatorId: el.creator_id,
    description: el.description,
    id: el.id,
    image: el.image,
    name: el.name,
    pricingPlanId: el.pricing_plan_id,
  }));
};

export const getMyProjectByProjectTokenParser = async (accessToken?: string): Promise<Project> => {
  const result = await getMyProjectByProjectTokenFromServer({
    ...(accessToken && {
      config: {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    }),
  });
  return {
    createdAt: result.created_at,
    creatorId: result.creator_id,
    description: result.description,
    id: result.id,
    image: result.image,
    name: result.name,
    pricingPlanId: result.pricing_plan_id,
  };
};

export const getProjectMemberListParser = async (): Promise<ProjectMember[]> => {
  const result = await getProjectMembersFromServer();
  return result.map((el) => ({
    createdAt: el.created_at,
    projectId: el.project_id,
    projectMemberId: el.project_member_id,
    projectRoleId: el.project_role_id,
    userId: el.user_id,
  }));
};

export const getMicroChainIdsOfProjectParser = async (): Promise<Array<number>> => {
  const result = await getProjectNetworksFromServer();
  const response = result.map((item) => item.micro_chain_id);

  return response;
};

export const getProjectInvitationListParser = async (): Promise<Array<ProjectInvitation>> => {
  const result = await getProjectInvitationsFromServer();

  const response = result
    .map((el) => ({
      id: el.id,
      projectId: el.project_id,
      senderUserId: el.sender_user_id,
      email: el.email,
      isAccept: el.is_accept,
      expireTime: el.expire_time,
      createdAt: el.created_at,
      updatedAt: el.updated_at,
    }))
    .slice()
    .reverse();
  return response;
};

export const updateProjectParser = async (name: string, description: string, image: string, selectedMicroChainIds: Array<number>): Promise<Project> => {
  const result = await updateProjectFromServer({
    data: {
      name,
      description,
      image,
      selectedMicroChainIds,
    },
  });

  return {
    createdAt: result.created_at,
    creatorId: result.creator_id,
    description: result.description,
    id: result.id,
    image: result.image,
    name: result.name,
    pricingPlanId: result.pricing_plan_id,
  };
};

export const inviteProjectMemberParser = async (receiverEmail: string): Promise<ProjectInvitation> => {
  const result = await inviteProjectMemberFromServer({
    data: {
      receiverEmail,
    },
  });

  return {
    id: result.id,
    projectId: result.project_id,
    senderUserId: result.sender_user_id,
    email: result.email,
    isAccept: result.is_accept,
    expireTime: result.expire_time,
    createdAt: result.created_at,
    updatedAt: result.updated_at,
  };
};

export const deleteInvitationParser = async (invitationId: number): Promise<boolean> => {
  const { status } = await deleteInvitationFromServer({
    params: {
      invitationId,
    },
  });

  return status;
};

export const getInvitedProjectListParser = async (): Promise<Array<ProjectInvitation>> => {
  const result = await getInvitedProjectListFromServer({});

  const response = result.map((el) => ({
    id: el.id,
    projectId: el.project_id,
    senderUserId: el.sender_user_id,
    email: el.email,
    isAccept: el.is_accept,
    expireTime: el.expire_time,
    createdAt: el.created_at,
    updatedAt: el.updated_at,
  }));
  return response;
};

export const acceptInvitationParser = async (invitationIds: Array<number>): Promise<boolean> => {
  const { status } = await acceptInvitationFromServer({
    data: {
      invitationIds,
    },
  });
  return status;
};

export const getProjectsParser = async (options?: {
  limit?: number;
  offset?: number;
  order?: "ASC" | "DESC";
  projectIds?: Array<number>;
}): Promise<Array<Project>> => {
  const result = await getProjectsFromServer({
    query: {
      ...(options?.limit && { limit: options.limit }),
      ...(options?.offset && { offset: options.offset }),
      ...(options?.order && { order: options.order }),
      ...(options?.projectIds && { projectIds: options.projectIds }),
    },
  });

  const response = result.map((el) => ({
    createdAt: el.created_at,
    creatorId: el.creator_id,
    description: el.description,
    id: el.id,
    image: el.image,
    name: el.name,
    pricingPlanId: el.pricing_plan_id,
  }));

  return response;
};

export const getProjectRolesParser = async (): Promise<Array<ProjectRole>> => {
  const result = await getProjectRolesFromServer();

  const response = result.map((role) => {
    const splitName = role.name.split("");
    const parsedName = splitName
      .map((el, index) => {
        if (index === 0) {
          return el.toUpperCase();
        }
        return el;
      })
      .join()
      .replaceAll(",", "");
    return {
      projectRoleId: role.project_role_id,
      name: parsedName,
      description: role.description,
      createdAt: role.created_at,
    };
  });
  return response;
};

export const removeMemberParser = async (projectMemberId: number): Promise<boolean> => {
  const { status } = await removeMemberFromServer({
    params: {
      projectMemberId,
    },
  });
  return status;
};

export const checkProjectNameParser = async (name: string): Promise<boolean> => {
  const { status } = await checkProjectNameFromServer({
    data: {
      name,
    },
  });

  return status;
};

export const getProjectRequestsOfHoursParser = async (type: ProjectRequestsTab, from: FromTick): Promise<Array<ProjectRequests>> => {
  const result = await getRequestVolumeFromServer({
    query: {
      type,
      from,
    },
  });

  const hour: number = 24;
  let hours: Array<string> = [];
  for (let i = 0; i < hour; i++) {
    hours[hour - 1 - i] = moment().subtract(i, "hours").format();
  }

  const response = hours.map((el: string) => {
    const target = result.find((r) => moment(r.ts).get("hour") === moment(el).get("hour"));
    let key: string = type;
    return {
      ts: `${moment(el).format("HH")}:00`,
      count: target ? target[key] : 0,
      type,
    };
  });
  return response;
};

export const getProjectRequestsOfDaysParser = async (type: ProjectRequestsTab, from: FromTick): Promise<Array<ProjectRequests>> => {
  const result = await getRequestVolumeFromServer({
    query: {
      type,
      from,
    },
  });

  let days: number = 0;
  if (from === FromTick.WEEK) {
    days = 7;
  }
  if (from === FromTick.MONTH) {
    days = 30;
  }

  let date: Array<string> = [];
  for (let i = 0; i < days; i++) {
    date[days - 1 - i] = moment().subtract(i, "days").format();
  }

  const response = date.map((el: string) => {
    const target = result.find((r) => moment(r.ts).get("date") === moment(el).get("date"));
    let key: string = type;
    return {
      ts: moment(el).format("MM/DD"),
      count: target ? target[key] : 0,
      type,
    };
  });
  return response;
};

export const getMostUsedRequestOfHoursParser = async (from: FromTick): Promise<{ methodList: Array<string>; data: Array<any> }> => {
  const result = await getMethodDominanceFromServer({
    query: {
      from,
    },
  });

  const methodList = _.uniq(result.map((el) => el.method));

  const hour: number = 24;
  let hours: Array<string> = [];
  for (let i = 0; i < hour; i++) {
    hours[hour - 1 - i] = moment().subtract(i, "hours").format();
  }

  const data = hours.map((day) => {
    const filteredSpecificHourData = result.filter((el) => moment(el.ts).get("hour") === moment(day).get("hour"));
    const parsedMethod = methodList.map((method) => {
      const specificMethodData = filteredSpecificHourData.find((data) => method === data.method);
      return { [method]: specificMethodData?.count ?? 0 };
    });

    return parsedMethod.reduce(
      (acc, cur) => {
        return { ...cur, ...acc };
      },
      { date: `${moment(day).format("HH")}:00` }
    );
  });

  const response = {
    data,
    methodList,
  };

  return response;
};

export const getMostUsedRequestOfDaysParser = async (from: FromTick): Promise<{ methodList: Array<string>; data: Array<any> }> => {
  const result = await getMethodDominanceFromServer({
    query: {
      from,
    },
  });

  const methodList = _.uniq(result.map((el) => el.method));

  let days: number = 0;
  if (from === FromTick.WEEK) {
    days = 7;
  }
  if (from === FromTick.MONTH) {
    days = 30;
  }

  let date: Array<string> = [];
  for (let i = 0; i < days; i++) {
    date[days - 1 - i] = moment().subtract(i, "days").format();
  }

  const data = date.map((day) => {
    const filteredSpecificDateData = result.filter((el) => moment(el.ts).get("date") === moment(day).get("date"));
    const parsedMethod = methodList.map((method) => {
      const specificMethodData = filteredSpecificDateData.find((data) => method === data.method);
      return { [method]: specificMethodData?.count ?? 0 };
    });

    return parsedMethod.reduce(
      (acc, cur) => {
        return { ...cur, ...acc };
      },
      { date: moment(day).format("MM/DD") }
    );
  });

  const response = {
    data,
    methodList,
  };

  return response;
};

export const getNetworkUtilizationComparisonParser = async (from: FromTick): Promise<Array<NetworkDominance>> => {
  const result = await getNetworkDominanceFromServer({
    query: {
      from,
    },
  });

  const response = result.map((el) => ({
    microChainId: el.micro_chain_id,
    count: el.count,
  }));

  return response;
};

export const getRequestsHistoryParser = async (from: FromTick): Promise<Array<ProjectChart>> => {
  const result = await getRequestActivityFromServer({
    query: {
      from,
    },
  });

  const response = result.map((el) => ({
    microChainId: el.micro_chain_id,
    method: el.method,
    count: el.count,
    microCredit: el.micro_credit,
  }));

  return response;
};

export const getPricingPlansParser = async (): Promise<Array<PricingPlan>> => {
  const result = await getPricingPlansFromServer();

  return result.map((el) => ({
    pricingPlanId: el.pricing_plan_id,
    monthlyPrice: el.monthly_price,
    defaultUsageCredit: el.default_usage_credit,
    name: el.name,
    image: el.image,
    description: el.description,
    isExtraCharge: el.is_extra_charge,
    createdAt: el.created_at,
    features: el.features?.map((feature) => ({
      featureId: feature.feature_id,
      name: feature.name,
      image: feature.image,
      description: feature.description,
    })),
  }));
};

export const getPricingPlanParser = async (pricingPlanId: number): Promise<PricingPlan> => {
  const result = await getPricingPlanFromServer({
    params: {
      pricingPlanId,
    },
  });

  return {
    pricingPlanId: result.pricing_plan_id,
    monthlyPrice: result.monthly_price,
    defaultUsageCredit: result.default_usage_credit,
    name: result.name,
    image: result.image,
    description: result.description,
    isExtraCharge: result.is_extra_charge,
  };
};

export const getMonthlyHistoryParser = async (): Promise<MonthlyHistory> => {
  const result = await getMonthlyHistoryFromServer();

  return {
    apiUsageCredit: result.api_usage_credit,
    infraUsageCredit: result.infra_usage_credit,
    extraChargeCredit: result.extra_charge_credit,
    totalPaymentCredit: result.total_payment_credit,
    bonusCredit: result.bonus_credit,
    totalPaymentPrice: result.total_payment_price,
    paymentDay: result.payment_day,
    exchangeRate: result.exchange_rate,
  };
};

export const getProjectCreditParser = async (): Promise<ProjectCredit> => {
  const result = await getProjectCreditFromServer();

  return {
    projectId: result.project_id,
    credit: result.credit,
  };
};

export const getProjectReceiptParser = async (offset: number, limit: number): Promise<ProjectReceipt> => {
  const result = await getProjectReceiptsFromServer({
    query: {
      offset,
      limit,
    },
  });

  const response = {
    count: result.count,
    rows: result.rows.map((el) => ({
      createdAt: el.created_at,
      isPaid: el.is_paid,
      monthlyHistoryId: el.monthly_history_id,
      projectId: el.project_id,
      receiptId: el.receipt_id,
      title: el.title,
      monthlyHistory: {
        apiUsageCredit: el.monthly_history.api_usage_credit,
        infraUsageCredit: el.monthly_history.infra_usage_credit,
        extraChargeCredit: el.monthly_history.extra_charge_credit,
        totalPaymentCredit: el.monthly_history.total_payment_credit,
        bonusCredit: el.monthly_history.bonus_credit,
        totalPaymentPrice: el.monthly_history.total_payment_price,
        paymentDay: el.monthly_history.payment_day,
        projectId: el.monthly_history.project_id,
        startDate: el.monthly_history.start_date,
        endDate: el.monthly_history.end_date,
      },
    })),
  };

  return response;
};
