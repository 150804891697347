import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Table from "../../../../../legacy-common/atom/table/Table";
import TableStatusLabel from "../../../../components/_atom/label/TableStatusLabel";
import { errorAlert } from "../../../../../utils/Utils";
import Section from "legacy-common/atom/section/Section";
import { getNodeListAction } from "../../../../../action/nodeAction";
import { selected_project_id } from "reducer/projectReducer";
import { ALL_MAIN_STATUS } from "../../../../../constants/commonConstants";
import PageTitle from "view/components/_molecules/page/PageTitle";
import PageArticle from "view/components/_template/page/PageArticle";
import PageLoading from "view/components/_atom/loading/PageLoading";
import BasicButton from "view/components/_atom/button/BasicButton";
import { ButtonSize, ButtonTheme } from "view/components/_atom/button/StyledButton";
import useLoading from "hooks/useLoading";
import toast from "react-hot-toast";

const NodeList = () => {
  const navigate = useNavigate();

  const projectId = useSelector(selected_project_id);

  const [nodeList, setNodeList] = useState([]);

  const colGroup = useMemo(() => [10, 35, 20, 15, 20].map((el) => ({ style: { width: `${el}%` } })), []);

  const headers = useMemo(
    () => [
      { title: "Node ID", accessor: "id" },
      { title: "Node Address", accessor: "address" },
      { title: "Network", accessor: "networkLabel" },
      { title: "Type", accessor: "type" },
      { title: "Status", accessor: "status" },
    ],
    []
  );

  const renderer = useCallback(
    (data) => ({
      id: data?.id ?? "-",
      address: data?.address ?? "-",
      networkLabel: data?.networkLabel ?? "-",
      type: data?.typeLabel ?? "-",
      status: <TableStatusLabel mainStatus={data?.mainStatus} />,
    }),
    []
  );

  const getNodeList_ = async () => {
    const { result, error } = await getNodeListAction({ projectId, mainStatus: ALL_MAIN_STATUS });

    if (result) {
      setNodeList(result);
    }
    if (error) {
      errorAlert(error.data.message);
    }
  };

  const onCreateNode = () => {
    // navigate("/network/node/create");
    toast("Coming Soon !", { icon: "✋🏻" });
  };

  const onNodeClicked = (el) => {
    navigate(`${el.id}`);
  };

  const nodeListView = () => [
    {
      renderers: [
        {
          value: (
            <Table
              headers={headers}
              colGroup={colGroup}
              data={nodeList}
              renderer={renderer}
              onClick={onNodeClicked}
              noDataPlaceholder={"Node does not exist"}
            />
          ),
        },
      ],
    },
  ];

  const { loading } = useLoading({
    dependency: [projectId],
    synchronousFunction: async () => {
      await getNodeList_();
    },
  });

  if (loading) {
    return <PageLoading />;
  }

  return (
    <PageArticle>
      <PageTitle size={1240} title={"Node List"} description={"Manage nodes in operation."} />
      <div className="page-layout-1240">
        <BasicButton theme={ButtonTheme.CREATE} size={ButtonSize.EXTRALARGE} onClick={() => onCreateNode()}>
          Create a new Node
        </BasicButton>
        <div style={{ height: 30 }} />
        <Section title={"Node"} view={nodeListView} />
      </div>
    </PageArticle>
  );
};

export default NodeList;
