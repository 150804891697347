import { removeComma } from "utils/Utils";

export type ApiErrorData = {
  data: null;
  error: {
    code: string;
    message: string;
  };
  body: {
    locked: boolean;
  };
  bodyUsed: boolean;
  headers: object;
  ok: boolean;
  redirected: boolean;
  status: number;
  statusText: string;
  type: string;
  url: string;
};

export type ApiError = {
  type: "API";
  data: ApiErrorData;
};

type MessageError = {
  message: string;
};

type ErrorType = "API" | "Realm" | "message" | "unknown";
type ActionErrorType = { type: ErrorType; data: any };

export type ActionType<T> =
  | {
      result: T;
      error: undefined;
    }
  | {
      result: undefined;
      error: ActionErrorType;
    };

export const isMessageError = (error: any): error is MessageError => {
  return !!error.message;
};

export const isApiError = (response: any): boolean => {
  // console.log(response?.error?.error);
  // export const isApiError = (error: any): error is ApiError => {
  return response?.error?.error;
};

export const actionController = async <T>(
  fn: () => Promise<T>
): Promise<ActionType<T>> => {
  try {
    const result = await fn();
    return { result, error: undefined };
  } catch (e: unknown) {
    let actionError: ActionErrorType;
    if (isApiError(e)) {
      // const apiError = e as ApiErrorData;
      actionError = {
        type: "API",
        data: e,
      };
    } else if (isMessageError(e)) {
      actionError = {
        type: "message",
        data: e.message,
      };
    } else {
      actionError = {
        type: "unknown",
        data: e,
      };
    }
    return {
      result: undefined,
      error: actionError,
    };
  }
};

// -------------------------------- middleware ---------------------------
type Controller = (...args: any[]) => any;
type MiddlewareFunction = (controller: Controller, ...args: any[]) => void;
export type AppControllers = { [k: string]: { [p: string]: Controller } };
export type DomainControllers = { [k: string]: Controller };
export const controllerMiddleware = <T>(
  middlewares: MiddlewareFunction[],
  controllers: T & DomainControllers
): T & DomainControllers => {
  const controllerNameArray = Object.keys(controllers);
  let controllersWithMiddleware = {
    ...controllers,
  };
  controllerNameArray.forEach((name) => {
    controllersWithMiddleware = {
      ...controllersWithMiddleware,
      [name]: (...args: any[]): Controller => {
        middlewares.forEach((middleware) => {
          middleware(controllers[name], args);
        });
        return controllers[name](...args);
      },
    };
  });
  return controllersWithMiddleware;
};

// export const loggerForController = (fn: Controller, args: any) => {
//   Logger.custom({
//     name: "Controller",
//     preview: fn.name,
//     value: {
//       name: fn.name,
//       args: args ?? "no arguments",
//     },
//     important: true,
//   });
//   return fn;
// };

// 스네이크케이스를 카멜케이스로 변환하는 함수
const toCamelCase = (str: string): string => {
  return str.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase());
};

// 객체의 키를 재귀적으로 카멜케이스로 변환하는 함수
export const convertKeysToCamelCase = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map((item) => convertKeysToCamelCase(item)); // 배열 처리
  } else if (obj !== null && obj && typeof obj === "object") {
    const newObj: { [key: string]: any } = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        newObj[toCamelCase(key)] = convertKeysToCamelCase(obj[key]); // 키를 변환하고 재귀 호출
      }
    }
    return newObj;
  }
  return obj; // 객체나 배열이 아니면 그대로 반환
};

export function convertFromDecimal(amount: number, decimal: number) {
  // amount를 string으로 처리하고, 소수점을 처리하기 위해 removeComma 함수도 적용
  let amountString = removeComma(amount.toString());

  // decimal 값 만큼 자릿수를 곱하여 변환 (amount * 10^decimal)
  let weiAmount = BigInt(amountString.replace(".", "") + "0".repeat(decimal));

  return weiAmount;
}

//decimal만큼 줄이기
export function convertToDecimal(weiAmount: string, decimal: number): string {
  // 문자열에서 소수점 위치를 맞추기 위해 10^decimal을 계산
  const factor = Math.pow(10, decimal); // 10^decimal

  // 숫자 문자열을 BigInt로 변환하여 계산 (BigInt는 정수만 처리)
  const weiBigInt = BigInt(weiAmount);

  // 결과 값 계산 (weiAmount를 10^decimal 만큼 나누기)
  const result = weiBigInt / BigInt(factor);
  const remainder = weiBigInt % BigInt(factor);

  // 나머지 부분을 문자열로 변환하고, 필요한 자릿수만큼 0을 채움
  const remainderStr = remainder.toString().padStart(decimal, "0");
  let decimalResult = result + "." + remainderStr;

  // 소수점 이하 불필요한 0 제거
  decimalResult = decimalResult.replace(/(\.\d+?)0+$/, "$1"); // 소수점 뒤에 0을 제거

  // 소수점이 0만 남아있으면 정수 부분만 반환
  if (decimalResult.endsWith(".")) {
    decimalResult = decimalResult.slice(0, -1);
  }

  // "4.0"과 같은 경우 "4"로 반환
  if (
    decimalResult.includes(".") &&
    parseFloat(decimalResult) === Math.floor(parseFloat(decimalResult))
  ) {
    decimalResult = Math.floor(parseFloat(decimalResult)).toString();
  }

  return decimalResult;
}
