import './Table.scss';

function Table(props) {
  const { index, headers, data, renderer, onClick, colGroup, addRow, style, noData, noDataPlaceholder = 'No Data', rowHeight } = props || {};

  return (
    <div className={'table'}>
      {index && (
        <div className="table-index">
          {index.icon && <img className="table-index-icon" src={index.icon} alt="index" />}
          <div className="table-index-text">{index.text}</div>
        </div>
      )}
      <table className={'main-table'} style={{ ...style }}>
        {!!colGroup && (
          <colgroup style={{ padding: '0 40px' }}>
            {colGroup.map((col, index) => (
              <col key={`col-${index}`} {...col} />
            ))}
          </colgroup>
        )}
        <thead>
          <tr>{Array.isArray(headers) && headers.map((header, index) => <th key={header.accessor || `header-${index}`}>{header.title || '-'}</th>)}</tr>
        </thead>
        <tbody className="table-body">
          {Array.isArray(data) &&
            data.map((el, index) => (
              <tr key={`data-${index}`} onClick={() => onClick && onClick(el, index)} style={{ height: rowHeight }}>
                {Array.isArray(headers) &&
                  headers.map((header) => (
                    <td
                      className={header.accessor === 'status' ? 'status-container' : undefined}
                      key={header.accessor}
                      title={
                        typeof ((!!renderer && renderer(el)[header.accessor]) || el[header.accessor] || '-') === 'string'
                          ? (!!renderer && renderer(el)[header.accessor]) || el[header.accessor] || '-'
                          : ''
                      }
                    >
                      {header.accessor === 'app_icon_image' ? (
                        renderer(el)[header.accessor] || el[header.accessor] !== undefined ? (
                          <img className="image-data" src={(!!renderer && renderer(el)[header.accessor]) || el[header.accessor]} alt="" />
                        ) : (
                          <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <div className="no-img">No Image</div>
                          </div>
                        )
                      ) : (
                        (!!renderer && renderer(el)[header.accessor]) || el[header.accessor] || '-'
                      )}
                    </td>
                  ))}
              </tr>
            ))}
          {data?.length === 0 && (
            <tr className="table-no-data">
              <td colSpan={headers.length}>{noData || noDataPlaceholder}</td>
            </tr>
          )}
          {addRow && (
            <tr onClick={addRow}>
              <td colSpan={headers.length} className={'add-row'}>
                +
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default Table;
