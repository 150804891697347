import React from "react";
import { VALIDATION__AIRDROP_budget } from "../../validation/airdropValidation";
import ButtonContainer from "view/components/_atom/container/ButtonContainer";
import BasicButton from "view/components/_atom/button/BasicButton";
import { ButtonSize, ButtonTheme } from "view/components/_atom/button/StyledButton";
import useValidateCondition from "hooks/useValidateCondition";
import useIsAble from "hooks/useIsAble";
import CommentInput from "view/components/_atom/input/CommentInput";
import { FieldContainer, FieldLabel, RowField } from "view/components/_atom/section/Field";
import airdropIcon from "../../../../../assets/images/service/Icon_SNB_airdrop.png";

const AirdropFixedBudgetSetting = (props) => {
  const { onClickBackButton, airdropData, setAirdropData, setCurrentStep } = props || {};

  const budgetValidation = useValidateCondition([airdropData.depositAmount], VALIDATION__AIRDROP_budget(airdropData.depositAmount));

  const isAble = useIsAble([budgetValidation.isValid]);

  const onClickNextButton = () => {
    setCurrentStep((prevState) => prevState + 1);
  };

  return (
    <>
      {/* Budget Description :: The network label will have the closest meaning to your project name. */}
      <FieldContainer icon={airdropIcon} title="Budget">
        <RowField>
          <FieldLabel label="Network / Microchain" />
          <span>{`${airdropData?.networkLabel} / ${airdropData?.microchainLabel}`}</span>
        </RowField>
        <RowField>
          <FieldLabel label="Currency" />
          <span>{`${airdropData?.currencySymbol ?? "-"}`}</span>
        </RowField>
        <RowField>
          <FieldLabel label="Distribution amount" />
          <CommentInput
            {...budgetValidation}
            onChange={(e) => setAirdropData(e.target.value, "depositAmount", "number")}
            value={airdropData.depositAmount ?? ""}
            autoFocus
          />
        </RowField>
      </FieldContainer>

      <div className="flex justify-content-flex-end">
        <ButtonContainer>
          <BasicButton size={ButtonSize.LARGE} theme={ButtonTheme.SECONDARY} onClick={() => onClickBackButton()}>
            Back
          </BasicButton>
          <BasicButton size={ButtonSize.LARGE} disabled={!isAble} onClick={() => onClickNextButton()}>
            Next
          </BasicButton>
        </ButtonContainer>
      </div>
    </>
  );
};

export default AirdropFixedBudgetSetting;
