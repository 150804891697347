import DivideLine from "view/components/_atom/line/DivideLine";
import "./NFTCard.scss";
import { useNavigate } from "react-router-dom";

const NftCard = ({ nftDetail }) => {
  const navigate = useNavigate();
  return (
    <div className="nft-card" onClick={() => navigate(`${nftDetail.tokenId}`)}>
      <div className="nft-image-container">
        <img className="nft-content" src={nftDetail.image} alt="" />
      </div>
      <DivideLine style={{ margin: "15px 0" }} />
      <div className="nft-detail-wrapper">
        {/* <div className="nft-token-id">#{nftDetail.tokenId}</div> */}
        <div className="nft-token-title">{nftDetail.title}</div>
      </div>
    </div>
  );
};

export default NftCard;
