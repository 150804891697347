import React, { useEffect, useState } from "react";
import emailImage from "../../../assets/images/Image_send_email.png";
import "./InitialEmailVerification.scss";
import { useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { errorAlert } from "../../../../utils/Utils";
import { sendAuthCodeEmailAction } from "../../../../action/userAction";
import useLoading from "hooks/useLoading";
import BasicButton from "view/components/_atom/button/BasicButton";
import InitialNoticeTemplate from "../components/InitialNoticeTemplate";
import logo from "../../../assets/images/signup_logo_EQHub.png";
import ButtonContainer from "view/components/_atom/container/ButtonContainer";

function InitialEmailVerification() {
  const navigate = useNavigate();
  const location = useLocation();
  const { email, userId }: any = location.state;

  const [resendDisabled, setResendDisabled] = useState(false);

  const sendAuthCodeByEmail_ = async () => {
    if (resendDisabled) {
      toast.error("Please try again in a few seconds.");
      return;
    }

    const { result, error } = await sendAuthCodeEmailAction(userId, email);
    if (result) {
      toast.success("Verification mail has been sent again!");
      setResendDisabled(true);
    }
    if (error) {
      errorAlert(error.data.message);
    }
  };

  const checkUser = () => {
    if (!!!email && !!!userId) {
      errorAlert("Invalid Access");
      navigate("/initialize");
    }
  };

  useEffect(() => {
    if (resendDisabled) {
      setTimeout(() => {
        setResendDisabled(false);
      }, 5000);
    }
  }, [resendDisabled]);

  const { loading } = useLoading({
    dependency: [email, userId],
    synchronousFunction: async () => {
      checkUser();
    },
  });

  if (loading) {
    return <></>;
  }

  return (
    <div className="verify-email-container">
      <img className="verify-email-logo" src={logo} alt="" />
      <InitialNoticeTemplate image={emailImage} title="Verify your email">
        <div className="verify-email-content">
          <div className="verify-email-content-description">
            <p>
              We've sent an email to <span className="verify-email-content-description-email">{email}</span>
            </p>
            <p>to verify your email address.</p>
            <p>The link in the email will expire in 24 hours.</p>
          </div>

          <div className="verify-email-content-receive">
            <span className="verify-email-content-receive-trigger" onClick={() => sendAuthCodeByEmail_()}>
              Click here
            </span>
            <span> if you did not receive an email.</span>
          </div>
        </div>
        <div className="verify-email-button-container">
          <ButtonContainer>
            <BasicButton onClick={() => navigate("/initialize")}>Done</BasicButton>
          </ButtonContainer>
        </div>
      </InitialNoticeTemplate>
    </div>
  );
}

export default InitialEmailVerification;
