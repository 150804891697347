import React, { useEffect, useMemo, useState } from 'react';
import PageArticle from '../../../../components/_template/page/PageArticle';
import KitTab from '../../../../components/_atom/tab/KitTab';
import '../../../../styles/ManageTokenKit.scss';
import { KitFunction, TokenKit } from '../constant/tokenKit.type';
import { logic } from '../../../../../logic';
import { useParams } from 'react-router-dom';
import { networkParserNew } from '../../../../../utils/Utils';
import DivideLine from '../../../../components/_atom/line/DivideLine';
import useLoading from '../../../../../hooks/useLoading';
import PageLoading from '../../../../components/_atom/loading/PageLoading';
import TableStatus, { TABLE_STATUS } from '../../../../components/_atom/status/TableStatus';
import EQConnectWalletButtonWithMetamask from '../../../../../modules/EQConnect/EQConnectWalletButtonWithMetamask';
import BasicButton from '../../../../components/_atom/button/BasicButton';
import { ButtonSize, ButtonTheme } from '../../../../components/_atom/button/StyledButton';
import KitContainer from '../../../../components/_atom/section/KitContainer';
import Skeleton from '../../../../components/_atom/skeleton/Skeleton';
import CommentInput from '../../../../components/_atom/input/CommentInput';
import Select from '../../../../components/_atom/select/Select';
import { TOKEN_KIT_FUNCTION_METHOD } from '../constant/tokenKit.constant';
import ModalTemplateB from '../../../../components/_template/modal/ModalTemplateB';
import deleteButtonImage from '../../../../assets/images/button_delete.png';
import CopyToClipboard from 'react-copy-to-clipboard/lib';
import toast from 'react-hot-toast';
import IconCopy from '../../../dashboard/assets/Icon_copy_address.png';
import Table from '../../../../../legacy-common/atom/table/Table';
import Checkbox from '../../../../components/_atom/checkbox/Checkbox';
import { isDisplayTransactionStatus, statusType } from '../../../../../utils/status.utils';
import { TOKEN_STATUS } from '../constant/tokenConstant';
import TransactionStatus from '../../../../components/_atom/transactionStatus/TransactionStatus';
import warningIcon from '../../../../assets/images/atom/Icon_warning_black.png';
import SortingTable from '../../../dashboard/components/SortingTable';

const TOKEN_KIT_TABS = [
  { label: 'Dashboard', index: 0 },
  { label: 'Function', index: 1 },
  { label: 'Dev', index: 2 },
  { label: 'Setting', index: 3 },
];
type KitFunctionMethod = {
  description: string; //해당 기능(인자값)에 관한 description입니다.
  feature_id: number;
  function_name: string; //기능 명 입니다. ex) 전송기능 : transfer
  methods: {
    method_id: number;
    input_type: 'single' | 'multiple'; //single : input 단일 항목 입력 , multiple: multicall같은 사용자에게 input값을 1개 이상을 받을 수 있음을 의미합니다.
    method_description: string; //메서드에 대한 설명 입니다.
    method: string; //기능에 속하는 메서드 입니다.
    need_wallet: boolean; //해당 기능을 사용하기 위해서는 지갑이 필요한지 여부입니다.
    input: {
      name: string; //메서드를 위해 사용자에게 받아야하는 input명 입니다.
      type: 'address' | 'amount'; //placeholder 구분을 위한 input이 가지는 타입 입니다.
      data_type: 'string' | 'number'; // input이 실제로 받아야하는 데이터 타입
    }[];
  }[];
};
type KitType = {
  kit_type_id: number;
  origin_contract_id: number;
  name: string;
  description: string;
};
type Contract = {
  contract_id: number;
  micro_chain_id: number;
  name: string;
  project_id: number;
  creator_id: number;
  contract_address: string;
  description: string;
  type: number;
  transaction_hash: string;
  main_status: number;
  sub_status: number;
  erc_type: number;
  is_system: boolean;
  origin_contract_id: number;
  origin_contract_version_id: number;
  origin_version: string;
  implementation_contract_id: number; //여기까지 기존 contract 정보
  contractLibrary: {
    //contract librarby 정보
    contract_version_id: number;
    contract_id: number;
    version: string; //contract의 버전
    version_description: string; //contract의 버전 설명
    status: number;
    is_private: number;
    contract: {
      contract_id: number;
      project_id: number;
      creator_id: number;
      latest_contract_version_id: number;
      title: string; //contract의 title
      description: string; //contract의 description
      type: number; //contract의 type
      erc_type: number | null; //erc의 type 예)erc20 이면 erc_type: 20
      is_official: number;
      contract_standard_id: number;
      deploy_count: number;
      image: string;
    };
    contract_version_code: {
      contract_version_id: number;
      solidity_code: string;
      byte_code: string;
      abi_code: string;
      custom_abi_code: string;
    };
    contract_version_reject_detail: string;
    project: string; //project Id에 해당하는 프로젝트 명
    contract_type_labeling: 'FIXED' | 'PROXY' | 'IMPLEMENTATION' | 'UNKNOWN'; // contract의 type을 라벨링 한 값
  };
};
type Contract_Constructor = {
  internalType?: string;
  name: string;
  type: string;
  description?: string;
}[];

const ManageTokenKit = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const params = useParams();
  const { id: kit_id } = params;
  const [kitDetail, setKitDetail] = useState<TokenKit>();
  const [kitType, setKitType] = useState<KitType>();
  const status =
    kitDetail?.status === 4
      ? TABLE_STATUS.DEPLOYED
      : kitDetail?.status === 3
        ? TABLE_STATUS.PROCESSING
        : kitDetail?.status === 2
          ? TABLE_STATUS.PREPARED
          : TABLE_STATUS.CREATED;
  const getTokenKitDetail = async (kit_id: number) => {
    const { result, error } = await logic.tokenKit.getTokenKitDetail(kit_id);
    if (error) {
      return;
    }
    const { result: kitType, error: kitTypeError } = await logic.tokenKit.getTokenKitTypeDetail(result?.version?.kit_type_id);
    if (kitTypeError) {
      return;
    }
    const { result: microChains, error: microChainsError } = await logic.microChain.getMicroChainList({});
    if (microChainsError) {
      return;
    }
    const networks = networkParserNew(microChains);
    const network = networks.find((network) => network.id === result.chain_id);
    const tokenKit: TokenKit = {
      ...result,
      network: network?.label,
    };
    setKitDetail(tokenKit);
    setKitType(kitType);
  };
  const [transactionHash, setTransactionHash] = useState('');

  const { loading } = useLoading({
    dependency: [kit_id],
    synchronousFunction: async () => {
      await getTokenKitDetail(Number(kit_id));
    },
  });
  const { version } = kitDetail || {};

  if (loading) {
    return <PageLoading />;
  }
  return (
    <PageArticle id="ManageTokenKit">
      <div className="kit-title-wrapper">
        <div className="kit-info-wrapper">
          <img src={kitDetail?.image} alt="token image" className="kit-info-image" />
          <div>
            <div className="kit-info-name">{kitDetail?.name}</div>
            <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
              <div className="kit-info-text">Type : {kitType?.name}</div>
              <div className="kit-info-text">Version : {version?.version}</div>
              <TableStatus status={status} />
            </div>
          </div>
        </div>
        {version?.description && version.description.length > 0 && (
          <>
            <DivideLine style={{ margin: '20px 0' }} />
            <div className="kit-description">{version.description}</div>
          </>
        )}
      </div>
      <div className="kit-tab-wrapper">
        <KitTab tabs={TOKEN_KIT_TABS} tabIndex={tabIndex} setTabIndex={setTabIndex} />
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {kitDetail?.status === 3 && transactionHash && (
          <div style={{ width: 1064, marginTop: 20, marginBottom: -40 }}>
            <TransactionStatus
              transactionHash={transactionHash}
              statusDetail={{
                type: statusType.WAIT,
                title: 'Smart contract is being deployed.',
                showTransactionHash: true,
              }}
              microChainId={kitDetail?.chain_id as number}
            />
          </div>
        )}
      </div>
      {tabIndex === 0 && <Dashboard kitDetail={kitDetail!} />}
      {tabIndex === 1 && <Function kitDetail={kitDetail!} />}
      {tabIndex === 2 && <Dev kitDetail={kitDetail!} setKitDetail={setKitDetail} setTransactionHash={setTransactionHash} />}
      {tabIndex === 3 && <Setting kitDetail={kitDetail!} />}
    </PageArticle>
  );
};

export default ManageTokenKit;

const Dashboard = ({ kitDetail }: { kitDetail: TokenKit }) => {
  const status =
    kitDetail?.status === 4
      ? TABLE_STATUS.DEPLOYED
      : kitDetail?.status === 3
        ? TABLE_STATUS.PROCESSING
        : kitDetail?.status === 2
          ? TABLE_STATUS.PREPARED
          : TABLE_STATUS.CREATED;
  const datas = [
    { label: 'Kit Name', value: kitDetail?.name },
    {
      label: 'Kit Image',
      value: <img src={kitDetail?.image} alt={''} className={'kit-info-image'} />,
    },
    { label: 'Kit Symbol', value: kitDetail?.symbol },
    { label: 'States', value: <TableStatus status={status} /> },
    { label: 'Kit Version', value: kitDetail?.version?.version },
  ];
  return (
    <div className="tab-content-wrapper">
      <KitContainer
        title={'Kit Basic Info'}
        // description="The Setting tab allows users to configure token details, manage access permissions, customize appearance, and optimize operational settings for seamless management."
      >
        {datas.map((data, index) => {
          return <LabelValue key={index} label={data.label} value={data.value} />;
        })}
      </KitContainer>
    </div>
  );
};

const Function = ({ kitDetail }: { kitDetail: TokenKit }) => {
  const [functions, setFunctions] = useState<KitFunction[]>([]);
  const [selectedFunction, setSelectedFunction] = useState<KitFunction>();
  const getFunctions = async (kitId: number) => {
    const { result, error } = await logic.tokenKit.getTokenFunctionList(kitId);
    if (error) {
      return;
    }
    setFunctions(result);
    setSelectedFunction(result[0]);
  };
  const { loading } = useLoading({
    dependency: [kitDetail?.kit_id],
    synchronousFunction: async () => {
      await getFunctions(kitDetail?.kit_id);
    },
  });
  const [functionMethod, setFunctionMethod] = useState<KitFunctionMethod>();
  const getFunctionMethods = async (feature_id: number) => {
    const { result, error } = await logic.tokenKit.getTokenFunctionMethodList(feature_id);
    if (error) {
      return;
    }
    setFunctionMethod(result);
  };
  useEffect(() => {
    if (selectedFunction) {
      getFunctionMethods(selectedFunction.feature_id);
    }
  }, [selectedFunction]);

  if (loading) {
    return <PageLoading />;
  }
  return (
    <div className="tab-content-wrapper">
      {kitDetail?.status !== 4 && (
        <div className="kit-container" style={{ width: 1124, gap: 12 }}>
          <div style={{ display: 'flex', gap: 8 }}>
            <img src={warningIcon} alt="!" style={{ width: 20, height: 20, objectFit: 'contain' }} />
            <div className="kit-container-title">Notice : Prerequisite for using functions</div>
          </div>
          <div className="kit-container-description">Please complete the 'Deploy' process in the 'Dev' tab, then use the functions in the 'Function' tab.</div>
        </div>
      )}
      <div style={{ display: 'flex', gap: 20 }}>
        <div className="function-wrapper">
          {functions.map((func, index) => (
            <div
              key={index}
              className={`function-name${selectedFunction?.feature_id === func?.feature_id ? ' selected' : ''}`}
              onClick={() => setSelectedFunction(func)}
            >
              {func.name}
            </div>
          ))}
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
          <KitContainer title={selectedFunction?.name ?? ''} description={selectedFunction?.description} />
          {functionMethod?.methods.map((method, index) => <MethodForm method={method} selectedFunction={selectedFunction} kitDetail={kitDetail} key={index} />)}
        </div>
      </div>
    </div>
  );
};

const TRANSFER_COLGROUP = [1, 1.1, 1, 1, 1.3];
const TRANSFER_HEADERS = [
  { title: 'TX Hash', accessor: 'tx_hash' },
  { title: 'Date', accessor: 'timestamp' },
  { title: 'From', accessor: 'sender' },
  { title: 'To', accessor: 'receiver' },
  { title: 'Amount', accessor: 'amount' },
];
const TRANSFER_RENDERER = (data: any) => {
  return {
    tx_hash: (
      <div style={{ display: 'flex', gap: 10 }}>
        <div
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
          title={data.tx_hash}
        >
          {data.tx_hash}
        </div>
        <CopyToClipboard text={data.tx_hash} onCopy={() => toast('Copied TX Hash', { icon: '👏' })}>
          <img src={IconCopy} alt="copy" style={{ width: 20, height: 20, objectFit: 'contain', cursor: 'pointer' }} />
        </CopyToClipboard>
      </div>
    ),
    timestamp: data.timestamp,
    sender: (
      <div style={{ display: 'flex', gap: 10 }}>
        <div
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
          title={data.sender}
        >
          {data.sender}
        </div>
        <CopyToClipboard text={data.sender} onCopy={() => toast('Copied Sender Address', { icon: '👏' })}>
          <img src={IconCopy} alt="copy" style={{ width: 20, height: 20, objectFit: 'contain', cursor: 'pointer' }} />
        </CopyToClipboard>
      </div>
    ),
    receiver: (
      <div style={{ display: 'flex', gap: 10 }}>
        <div
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
          title={data.receiver}
        >
          {data.receiver}
        </div>
        <CopyToClipboard text={data.receiver} onCopy={() => toast('Copied Receiver Address', { icon: '👏' })}>
          <img src={IconCopy} alt="copy" style={{ width: 20, height: 20, objectFit: 'contain', cursor: 'pointer' }} />
        </CopyToClipboard>
      </div>
    ),
    amount: data.amount,
  };
};

const APPROVAL_COLGROUP = [1, 1.1, 1, 1, 1.3];
const APPROVAL_HEADERS = [
  { title: 'TX Hash', accessor: 'tx_hash' },
  { title: 'Date', accessor: 'timestamp' },
  { title: 'Approver', accessor: 'approver' },
  { title: 'Spender', accessor: 'spender' },
  { title: 'Allowance', accessor: 'allowance' },
];
const APPROVAL_RENDERER = (data: any) => ({
  tx_hash: (
    <div style={{ display: 'flex', gap: 10 }}>
      <div title={data.tx_hash}>{data.tx_hash}</div>
      <CopyToClipboard text={data.tx_hash} onCopy={() => toast('Copied TX Hash', { icon: '👏' })}>
        <img src={IconCopy} alt="copy" style={{ width: 20, height: 20, objectFit: 'contain', cursor: 'pointer' }} />
      </CopyToClipboard>
    </div>
  ),
  timestamp: data.timestamp,
  approver: data.approver,
  spender: data.spender,
  allowance: data.allowance,
});

const MethodForm = ({ method, selectedFunction, kitDetail }: { method: any; selectedFunction?: KitFunction; kitDetail?: TokenKit }) => {
  const { method_id, input_type, method_description, method: methodName, input, need_wallet } = method;
  const selectedFunctionMethods = TOKEN_KIT_FUNCTION_METHOD[selectedFunction?.feature_id ?? 1];
  const currentMethod = selectedFunctionMethods.find((item) => item.method_id === method_id);
  const [inputValues, setInputValues] = useState<any>();
  const [result, setResult] = useState<any>();
  useEffect(() => {
    if (input_type === 'single') {
      setInputValues({});
    } else {
      setInputValues([{}]);
    }
    setResult(undefined);
  }, [selectedFunction]);

  const [walletAddress, setWalletAddress] = useState('');

  const colgroup = method_id === 105 ? TRANSFER_COLGROUP : APPROVAL_COLGROUP;
  const headers = method_id === 105 ? TRANSFER_HEADERS : APPROVAL_HEADERS;
  const renderer = method_id === 105 ? TRANSFER_RENDERER : APPROVAL_RENDERER;
  return (
    <KitContainer title={methodName} description={method_description}>
      <DivideLine />
      {input_type === 'single' ? (
        <>
          {input?.map((input, index) => (
            <div style={{ paddingLeft: 10 }} key={'input' + index}>
              <div className="kit-label-value-wrapper column">
                <div className="kit-label">{input.name}</div>
                <CommentInput
                  value={inputValues?.[input.name] ?? ''}
                  disabled={kitDetail?.status !== 4}
                  placeholder={currentMethod?.inputs.find((item) => item.name === input.name)?.placeholder}
                  onChange={(e) => {
                    setInputValues({ ...inputValues, [input.name]: e.target.value });
                  }}
                  maxLength={55}
                />
              </div>
            </div>
          ))}
        </>
      ) : (
        <>
          {inputValues?.map((_, valueIndex) => (
            <div style={{ display: 'grid', gridTemplateColumns: '506px 206px 20px', gap: 10 }} key={valueIndex}>
              {input?.map((input, index) => (
                <div key={'input' + index}>
                  <div className="kit-label-value-wrapper column">
                    {valueIndex === 0 && <div className="kit-label">{input.name}</div>}
                    <CommentInput
                      value={inputValues?.[valueIndex]?.[input.name] ?? ''}
                      disabled={kitDetail?.status !== 4}
                      placeholder={currentMethod?.inputs.find((item) => item.name === input.name)?.placeholder}
                      onChange={(e) => {
                        setInputValues((prev) => {
                          const newInputValues = [...prev];
                          newInputValues[valueIndex] = { ...newInputValues[valueIndex], [input.name]: e.target.value };
                          return newInputValues;
                        });
                      }}
                      maxLength={55}
                    />
                  </div>
                </div>
              ))}
              {valueIndex !== 0 && (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <img
                    src={deleteButtonImage}
                    alt="delete"
                    style={{ cursor: 'pointer', width: 16, height: 16, objectFit: 'contain' }}
                    onClick={() => {
                      setInputValues(inputValues.filter((_, index) => index !== valueIndex));
                    }}
                  />
                </div>
              )}
            </div>
          ))}

          <BasicButton
            size={ButtonSize.SMALL}
            style={{ alignSelf: 'center', height: 34, width: 103 }}
            theme={ButtonTheme.SECONDARY}
            onClick={() => {
              setInputValues([...inputValues, {}]);
            }}
          >
            + Add Row
          </BasicButton>
        </>
      )}
      {kitDetail?.status === 4 && (
        <>
          <div style={{ display: 'flex', gap: 10, justifyContent: 'flex-end' }}>
            {need_wallet && <EQConnectWalletButtonWithMetamask size={'small'} setWalletAddress={setWalletAddress} />}
            <BasicButton
              size={ButtonSize.DYNAMIC}
              style={{ height: 40 }}
              theme={ButtonTheme.PRIMARY}
              onClick={async () => {
                const result = await currentMethod.onClick(inputValues, {
                  kitId: kitDetail?.kit_id,
                  microChainId: kitDetail?.chain_id,
                  contractAddress: kitDetail?.contract_address,
                });
                setResult(result);
              }}
              disabled={need_wallet && !walletAddress}
            >
              Send
            </BasicButton>
          </div>
          {result && (
            <div className="method-result-wrapper">
              {method.method_id === 105 || method.method_id === 403 ? (
                <Table style={{ maxWidth: 1160 }} data={result} colGroup={colgroup} headers={headers} renderer={renderer} rowHeight={65} />
              ) : (
                <>
                  {need_wallet ? (
                    <>
                      <div className="method-result-text" style={{ marginBottom: 10 }}>
                        Request : Success!
                      </div>
                      <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                        <div className="method-result-text">TX Hash : {result}</div>
                        <CopyToClipboard text={result} onCopy={() => toast('Copied TX Hash', { icon: '👏' })}>
                          <img src={IconCopy} alt="copy" style={{ width: 20, height: 20, objectFit: 'contain', cursor: 'pointer' }} />
                        </CopyToClipboard>
                      </div>
                    </>
                  ) : (
                    <div>{JSON.stringify(result).replace(/["{}]/g, '').replace(':', ' : ')}</div>
                  )}
                </>
              )}
            </div>
          )}
        </>
      )}
    </KitContainer>
  );
};

const Dev = ({ kitDetail, setKitDetail, setTransactionHash }: { kitDetail: TokenKit; setKitDetail: any; setTransactionHash: any }) => {
  const status =
    kitDetail?.status === 4
      ? TABLE_STATUS.DEPLOYED
      : kitDetail?.status === 3
        ? TABLE_STATUS.PROCESSING
        : kitDetail?.status === 2
          ? TABLE_STATUS.PREPARED
          : TABLE_STATUS.CREATED;
  const [contractInfo, setContractInfo] = useState<Contract>();
  const contractLibrary = useMemo(() => contractInfo?.contractLibrary, [contractInfo]);
  const [constructor, setConstructor] = useState<Contract_Constructor>();
  const getContractInfo = async () => {
    const { result, error } = await logic.contract.getContractDetail(kitDetail?.contract_id);
    if (error) {
      return;
    }
    setContractInfo(result);
  };
  const { loading } = useLoading({
    dependency: [kitDetail?.contract_id],
    synchronousFunction: async () => {
      await getContractInfo();
    },
  });

  const getContractConstructor = async (contractId: number) => {
    const { result, error } = await logic.contract.getContractConstructor(contractId);
    if (error) {
      return;
    }
    setConstructor(result as Contract_Constructor);
  };
  useEffect(() => {
    if (contractInfo) {
      getContractConstructor(contractInfo.contract_id);
    }
  }, [contractInfo]);

  const [isOpenSelectNetworkModal, setIsOpenSelectNetworkModal] = useState(false);
  const [selectedNetwork, setSelectedNetwork] = useState<any>();
  const submitKit = async () => {
    const { result, error } = await logic.tokenKit.makeContractInfo(kitDetail?.kit_id, selectedNetwork.id);
    if (error) {
      return;
    }
    const { result: microChains, error: microChainsError } = await logic.microChain.getMicroChainList({});
    if (microChainsError) {
      return;
    }
    const networks = networkParserNew(microChains);
    const network = networks.find((network) => network.id === result.chain_id);
    const tokenKit: TokenKit = {
      ...result,
      network: network?.label,
    };
    setKitDetail(tokenKit);
    return true;
  };
  const deployKit = async () => {
    const { result, error } = await logic.tokenKit.tokenKitDeployTransactionWithMetamask(kitDetail?.kit_id);
    if (error) {
      return;
    }
    return result;
  };
  const changeKitStatus = async (kit_id: number) => {
    const { result, error } = await logic.tokenKit.changeTokenKitStatusProcessing(kit_id);
    if (error) {
      return;
    }
    setKitDetail((prev) => ({ ...prev, ...result }));
  };
  const onClickDeploy = async () => {
    if (status === TABLE_STATUS.CREATED) {
      if (!selectedNetwork) {
        setIsOpenSelectNetworkModal(true);
        return;
      }
      const submitResult = await submitKit();
      if (submitResult) {
        const tx_hash = await deployKit();
        if (tx_hash) {
          setTransactionHash(tx_hash);
          await changeKitStatus(kitDetail?.kit_id);
        }
      }
    }
    if (status === TABLE_STATUS.PREPARED) {
      const tx_hash = await deployKit();
      if (tx_hash) {
        setTransactionHash(tx_hash);
        await changeKitStatus(kitDetail?.kit_id);
      }
    }
  };
  const [address, setAddress] = useState('');

  return (
    <div className="tab-content-wrapper">
      <SelectNetworkModal
        selectedNetwork={selectedNetwork}
        setSelectedNetwork={setSelectedNetwork}
        tokenKitDetail={kitDetail}
        isOpen={isOpenSelectNetworkModal}
        closeModal={() => setIsOpenSelectNetworkModal(false)}
      />
      <KitContainer title={'Network Info'}>
        {status === TABLE_STATUS.CREATED ? (
          <LabelValue
            label={'Network'}
            value={
              <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
                {selectedNetwork?.label && <div>{selectedNetwork?.label}</div>}
                <div
                  className="kit-select-network-button"
                  onClick={() => {
                    setIsOpenSelectNetworkModal(true);
                  }}
                >
                  Select Network
                </div>
              </div>
            }
          />
        ) : (
          <LabelValue label={'Network'} value={kitDetail?.network} />
        )}
      </KitContainer>
      <KitContainer title={'Contract Info'}>
        <LabelValue label={'Contract Title'} value={contractLibrary?.contract?.title} isLoading={loading} />
        <LabelValue label={'Contract ID'} value={contractInfo?.contract_id} isLoading={loading} />
        <LabelValue label={'Contract Name'} value={contractInfo?.name} isLoading={loading} />
        <LabelValue label={'Made By'} value={contractLibrary?.project} isLoading={loading} />
        <LabelValue label={'Contract Type'} value={contractLibrary?.contract_type_labeling} isLoading={loading} />
        <LabelValue label={'ERC'} value={contractInfo?.erc_type ? `ERC ${contractInfo?.erc_type}` : ''} isLoading={loading} />
        <LabelValue label={'Description'} value={contractLibrary?.contract?.description} isLoading={loading} />
        <LabelValue label={'Contract Address'} value={contractInfo?.contract_address} isLoading={loading} />
      </KitContainer>
      <KitContainer title={'Version Info'}>
        <LabelValue label={'Contract Version'} value={contractInfo?.origin_version} isLoading={loading} />
        <LabelValue label={'Version Description'} value={contractLibrary?.version_description} isLoading={loading} />
      </KitContainer>
      {constructor && constructor.length > 0 && (
        <KitContainer title={'Constructor'}>
          {constructor.map((item, index) => (
            <LabelValue label={`${item.name} (${item.type})`} value={item.description} key={index} />
          ))}
        </KitContainer>
      )}
      {(status === TABLE_STATUS.CREATED || status === TABLE_STATUS.PREPARED) && (
        <div style={{ display: 'flex', gap: 10, justifyContent: 'flex-end', alignItems: 'center', width: 812 }}>
          <EQConnectWalletButtonWithMetamask setWalletAddress={setAddress} />
          <BasicButton size={ButtonSize.LARGE} onClick={() => onClickDeploy()} disabled={!address}>
            Deploy
          </BasicButton>
        </div>
      )}
    </div>
  );
};

const Setting = ({ kitDetail }: { kitDetail: TokenKit }) => {
  const status =
    kitDetail?.status === 4
      ? TABLE_STATUS.DEPLOYED
      : kitDetail?.status === 3
        ? TABLE_STATUS.PROCESSING
        : kitDetail?.status === 2
          ? TABLE_STATUS.PREPARED
          : TABLE_STATUS.CREATED;
  const datas = [
    { label: 'Kit Name', value: kitDetail?.name },
    {
      label: 'Kit Image',
      value: <img src={kitDetail?.image} alt={''} className={'kit-info-image'} />,
    },
    { label: 'Kit Symbol', value: kitDetail?.symbol },
    { label: 'States', value: <TableStatus status={status} /> },
    { label: 'Kit Version', value: kitDetail?.version?.version },
  ];
  return (
    <div className="tab-content-wrapper">
      <KitContainer
        title={'Kit Basic Info'}
        description="The Setting tab allows users to configure token details, manage access permissions, customize appearance, and optimize operational settings for seamless management."
      >
        {datas.map((data, index) => {
          return <LabelValue key={index} label={data.label} value={data.value} />;
        })}
      </KitContainer>
      {/*<div className="container" >*/}
      {/*  <div>*/}
      {/*    <div style={{ display: 'flex', gap: 8 }}>*/}
      {/*      <img src={iconWarning} alt="!" style={{ width: 24, height: 24, objectFit: 'contain' }} />*/}
      {/*      <div className="container-title" style={{ marginBottom: 12 }}>*/}
      {/*        Token Kit Deletion*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*    <div className="container-description" style={{ color: '#E70707' }}>*/}
      {/*      Warning: This token will be permanently deleted. Deleted tokens cannot be recovered.*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*  <BasicButton style={{ alignSelf: 'flex-end' }} size={ButtonSize.ICON} theme={ButtonTheme.MINOR} onClick={() => {}}>*/}
      {/*    <img src={IconDeleteRed} style={{ width: 18, objectFit: 'contain' }} alt={'delete'} />*/}
      {/*  </BasicButton>*/}
      {/*</div>*/}
    </div>
  );
};

const LabelValue = ({ label, value, isLoading }: { label: string; value: string | React.ReactNode; isLoading?: boolean }) => {
  return (
    <div className="kit-label-value-wrapper">
      <div className="kit-label">{label}</div>
      {isLoading && !value ? <Skeleton style={{ height: 30 }} /> : <div className="kit-value">{value === null || value === undefined ? '-' : value}</div>}
    </div>
  );
};

// const NETWORKS_COLGROUP = [1, 1.5, 6.2];
// const NETWORKS_HEADERS = ['', '', 'Network Name'];

type SelectNetworkModalProps = {
  isOpen: boolean;
  tokenKitDetail: TokenKit;
  closeModal: () => void;
  selectedNetwork: any;
  setSelectedNetwork: (network: any) => void;
};
const SelectNetworkModal = (props: SelectNetworkModalProps) => {
  const { isOpen, tokenKitDetail, closeModal, selectedNetwork, setSelectedNetwork } = props;
  const [networks, setNetworks] = useState<any[]>([]);
  const getNetworks = async () => {
    const { result, error } = await logic.tokenKit.getSupportedChainList(tokenKitDetail?.version_id);
    if (error) {
      return;
    }
    const { result: microChains, error: microChainsError } = await logic.microChain.getMicroChainList({});
    if (microChainsError) {
      return;
    }
    const networks = networkParserNew(microChains);
    const supportedChains = result.map((chain) => {
      const network = networks.find((network) => network.id === chain.chainId);
      return network;
    });
    setNetworks(supportedChains);
  };
  useEffect(() => {
    getNetworks();
  }, [tokenKitDetail?.version_id]);
  // const handleSelectNetwork = (network: any) => {
  //   const isExist = selectedNetworks.some((el) => el.id === network.id);
  //   if (isExist) {
  //     setSelectedNetworks(selectedNetworks.filter((el) => el.id !== network.id));
  //   } else {
  //     setSelectedNetworks([...selectedNetworks, network]);
  //   }
  // };
  // const NETWORKS_RENDERER = (data: any) => ({
  //   select: <Checkbox id={''} label={''} checked={selectedNetworks.some((el) => el.id === data.id)} onChange={() => {}} />,
  //   icon: <img src={data.image} alt={data.name} style={{ width: 40, height: 40, objectFit: 'contain', border: '2px solid #E7E9EF' }} />,
  //   name: data.label,
  // });
  return (
    <ModalTemplateB
      isOpen={isOpen}
      onRequestClose={closeModal}
      title={'Select Network'}
      description={'Select the network you want to connect to.'}
      showDivideLine={false}
    >
      <div style={{ width: 520, marginBottom: 40 }}>
        {networks.length !== 0 && (
          <Select
            labelKey={'label'}
            valueKey={null}
            options={networks}
            selectedValue={selectedNetwork}
            setSelectedValue={(el) => {
              setSelectedNetwork(el);
              closeModal();
            }}
            canSelect={networks.length !== 0}
            placeholder={'Select a network'}
            isAbsolute={false}
          />
        )}
        {/*{networks.length !== 0 && (*/}
        {/*  <Table*/}
        {/*    style={{ maxWidth: 1160 }}*/}
        {/*    data={networks}*/}
        {/*    colGroup={NETWORKS_COLGROUP}*/}
        {/*    headers={NETWORKS_HEADERS}*/}
        {/*    renderer={NETWORKS_RENDERER}*/}
        {/*    rowHeight={65}*/}
        {/*    onClick={(data) => handleSelectNetwork(data)}*/}
        {/*  />*/}
        {/*)}*/}
      </div>
      {/*<div style={{ display: 'flex', gap: 10, justifyContent: 'center', alignItems: 'center' }}>*/}
      {/*  <BasicButton theme={ButtonTheme.MINOR} size={ButtonSize.LARGE} onClick={closeModal} style={{ width: 200, height: 40 }}>*/}
      {/*    Back*/}
      {/*  </BasicButton>*/}
      {/*  <BasicButton size={ButtonSize.LARGE} onClick={closeModal} style={{ width: 200, height: 40 }}>*/}
      {/*    Select*/}
      {/*  </BasicButton>*/}
      {/*</div>*/}
    </ModalTemplateB>
  );
};
