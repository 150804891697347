import {WEB3} from './Web3'
import {Web3Error} from "./Web3Error";
import {AbiItem} from "web3-utils";

export const encodeABI = (abiCode: AbiItem | AbiItem[], functionName: string, parameters: Array<any>): string => {
  try{
    const contract = WEB3.Contract(abiCode)
    return contract.methods[functionName](...parameters).encodeABI()
  }catch (e) {
    throw new Web3Error(e.reason, e)
  }
}

export const encodeABIWithByteCode = (abiCode: AbiItem | AbiItem[], byteCode: string, parameters: Array<any>): string => {
  try{
    const contract = WEB3.Contract(abiCode);
    const options = {
      data: '0x' + byteCode,
      arguments: parameters
    }
    return contract.deploy(options).encodeABI()
  }catch (e) {
    throw new Web3Error(e.reason, e)
  }
}
