import React, { useMemo } from "react";
import "./TableStatusLabel.scss";
import rejectIcon from "../../../../view/assets/images/icon-status-label-rejected.png";
import createdIcon from "../../../../view/assets/images/icon-status-label-created.png";
import preparedIcon from "../../../../view/assets/images/icon-status-label-prepared.png";
import processingIcon from "../../../../view/assets/images/icon-status-label-processing.png";
import distributedIcon from "../../../../view/assets/images/icon-status-label-distributed.png";

const DEFAULT_LABEL_STYLE = [
  {
    status: 0,
    style: { icon: rejectIcon, backgroundColor: "#FFF3F3", textColor: "#CF7171", border: "1px solid #EFC2C2" },
    text: "Rejected",
  },
  {
    status: 1,
    style: { icon: createdIcon, backgroundColor: "#F2F5FE", textColor: "#8997C9", border: "1px solid #C8D5EE" },
    text: "Created",
  },
  {
    status: 2,
    style: { icon: processingIcon, backgroundColor: "#FFFCF8", textColor: "#F4C983", border: "1px solid #F1DEC0" },
    text: "Waiting a approval",
  },
  {
    status: 3,
    style: { icon: preparedIcon, backgroundColor: "#EDF8FF", textColor: "#7A95AE", border: "1px solid #C8D9EE" },
    text: "Prepared",
  },
  {
    status: 4,
    style: { icon: processingIcon, backgroundColor: "#FFFCF8", textColor: "#F4C983", border: "1px solid #F1DEC0" },
    text: "Processing",
  },
  {
    status: 5,
    style: { icon: distributedIcon, backgroundColor: "#F4FFF8", textColor: "#6BC97E", border: "1px solid #B0DCB9" },
    text: "Published",
  },
];

/**
 * props = mainStatus(int) #required, custom(object)
 *
 * custom = { status:(int), style:(int), text:(string) }
 *
 * style
 * - (0) Reject (red)
 * - (1) Create (gray)
 * - (2) Approval (yellow)
 * - (3) Prepared (green)
 * - (4) Processing (yellow)
 * - (5) Distributed (blue)
 */
const TableStatusLabel = (props) => {
  const { mainStatus, custom = [], customLabelStyle = {} } = props || {};

  const labelStyle = useMemo(() => {
    const target = custom.find((el) => el.status === mainStatus);
    if (target) {
      return {
        style: DEFAULT_LABEL_STYLE.find((el) => el.status === target.style).style,
        text: target.text,
      };
    }
    if (!target) {
      const defaultStyle = DEFAULT_LABEL_STYLE.find((el) => el.status === mainStatus);
      return {
        style: defaultStyle.style,
        text: defaultStyle.text,
      };
    }
  }, []);

  return (
    <div className="table-status-label" style={{ ...customLabelStyle, backgroundColor: labelStyle.style.backgroundColor, border: labelStyle.style.border }}>
      <img src={labelStyle.style.icon} className="table-status-label-icon" alt="status" />
      <span className="table-status-label-label" style={{ color: labelStyle.style.textColor }}>
        {labelStyle.text}
      </span>
    </div>
  );
};

export default TableStatusLabel;
