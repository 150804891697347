import { makeQuery } from "server/index/utils";
import { v2ServerByProjectAuth } from "../index/serverInstances";
import {
  AbiWithValue,
  ListedContractFromServer,
} from "../type/contract.server.type";
import { AbiItem } from "web3-utils";

//----------createContract
const getContractToList = async (
  request: getContractToListRequest
): Promise<getContractToListResponse> =>
  await v2ServerByProjectAuth.post({
    path: "contracts/library",
    data: request.data,
  });

type getContractToListRequest = {
  data: {
    microChainId: number;
    name: string;
    description: string;
    contractId: number;
    contractVersionId: number;
    contractConstructorInputs: Array<any>;
    implementationContractId?: number;
  };
};
type getContractToListResponse = {
  contract_id: number;
};

//----------getMyListedContract
const getMyListedContract = async (
  request: getMyListedContractRequest
): Promise<getMyListedContractResponse> =>
  await v2ServerByProjectAuth.get({
    path: `contracts/me`,
    query: makeQuery(request.query),
  });

type getMyListedContractRequest = {
  query: {
    contractAddress?: Array<string>;
    microChainId?: Array<number>;
    creatorId?: number;
    name?: string;
    mainStatus?: Array<number>;
    subStatus?: Array<number>;
    createdAt?: string;
    ercType?: number;
    lastContractId?: number;
    limit?: number;
    order?: "ASC" | "DESC";
  };
};
type getMyListedContractResponse = ListedContractFromServer[];

//----------getContracts
const getListedContracts = async (
  request: GetListedContractRequest
): Promise<GetListedContractResponse> =>
  await v2ServerByProjectAuth.get({
    path: `contracts`,
    query: makeQuery(request.query),
  });

type GetListedContractRequest = {
  query: {
    contractAddress?: Array<string>;
    microChainId?: Array<number>;
    ownerId?: number;
    name?: string;
    mainStatus?: Array<number>;
    subStatus?: Array<number>;
    lastContractId?: number;
    createdAt?: string;
    ercType?: string;
    limit?: number;
  };
};
type GetListedContractResponse = ListedContractFromServer[];

//----------getContractByContractAddress
const getContractByContractAddress = async (
  request: GetContractByContractAddressRequest
): Promise<GetContractByContractAddressResponse> =>
  await v2ServerByProjectAuth.get({
    path: `contracts/address/${request.params.contractAddress}`,
    query: makeQuery(request.query),
  });

type GetContractByContractAddressRequest = {
  params: {
    contractAddress: string;
  };
  query: {
    microChainId: number;
  };
};
type GetContractByContractAddressResponse = ListedContractFromServer;

//----------getContractDetail
const getListedContractDetail = async (
  request: GetListedContractDetailRequest
): Promise<GetListedContractDetailResponse> =>
  await v2ServerByProjectAuth.get({
    path: `contracts/${request.params.contractId}`,
    query: makeQuery(request.query),
  });

type GetListedContractDetailRequest = {
  params: {
    contractId: number;
  };
  query: {
    isInternalUse: boolean;
  };
};
type GetListedContractDetailResponse = ListedContractFromServer;

//----------getContractAbiCodeByContractId
const getContractAbiCodeByContractId = async (
  request: GetContractAbiCodeByContractIdRequest
): Promise<GetContractAbiCodeByContractIdResponse> =>
  await v2ServerByProjectAuth.get({
    path: `contracts/${request.params.contractId}/abi-code`,
  });

type GetContractAbiCodeByContractIdRequest = {
  params: {
    contractId: number;
  };
};
type GetContractAbiCodeByContractIdResponse = AbiItem | AbiItem[];

//----------getContractAbiCodeByContractAddress
const getContractAbiCodeByContractAddress = async (
  request: GetContractAbiCodeByContractAddressRequest
): Promise<GetContractAbiCodeByContractAddressResponse> =>
  await v2ServerByProjectAuth.get({
    path: `contracts/address/${request.params.contractAddress}/abi-code`,
    query: makeQuery(request.query),
  });

type GetContractAbiCodeByContractAddressRequest = {
  params: {
    contractAddress: string;
  };
  query: {
    microChainId: number;
  };
};
type GetContractAbiCodeByContractAddressResponse = AbiItem | AbiItem[];

//----------getContractByteCodeByContractId
const getContractByteCodeByContractId = async (
  request: GetContractByteCodeByContractIdRequest
): Promise<GetContractByteCodeByContractIdResponse> =>
  await v2ServerByProjectAuth.get({
    path: `contracts/${request.params.contractId}/byte-code`,
  });

type GetContractByteCodeByContractIdRequest = {
  params: {
    contractId: number;
  };
};
type GetContractByteCodeByContractIdResponse = {
  byte_code: string;
};

//----------getContractConstructorByContractId
const getContractConstructorByContractId = async (
  request: getContractConstructorByContractIdRequest
): Promise<getContractConstructorByContractIdResponse> =>
  await v2ServerByProjectAuth.get({
    path: `contracts/${request.params.contractId}/constructor`,
    query: makeQuery(request.query),
  });

type getContractConstructorByContractIdRequest = {
  params: {
    contractId: number;
  };
  query: {
    isInternalUse: boolean;
  };
};

// type AbiInputWithValue = {
//   name: string;
//   type: string;
//   indexed?: boolean;
//   components?: AbiInputWithValue[];
//   internalType?: string;
//   value: any;
// };

const postTransactionRequest = async (request: {
  params: {
    currencyId: number;
  };
  data: {
    contractId: number;
    rawTransaction: string;
  };
}): Promise<any> =>
  await v2ServerByProjectAuth.post({
    path: `currency/${request.params.currencyId}/release-contract`,
    data: request.data,
  });

export type getContractConstructorByContractIdResponse = AbiWithValue;

export {
  getContractToList as getContractToListFromServer,
  getMyListedContract as getMyListedContractFromServer,
  getListedContractDetail as getListedContractDetailFromServer,
  getContractByContractAddress as getContractByContractAddressFromServer,
  getContractAbiCodeByContractId as getContractAbiCodeByContractIdFromServer,
  getContractAbiCodeByContractAddress as getContractAbiCodeByContractAddressFromServer,
  getContractByteCodeByContractId as getContractByteCodeByContractIdFromServer,
  getContractConstructorByContractId as getContractConstructorByContractIdFromServer,
  postTransactionRequest as postTransactionRequestFromServer,
};
