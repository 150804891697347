import { useEffect, useState } from "react";
import "./FindPassword.scss";
import emailImage from "../../../../assets/images/Image_send_email.png";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { enterKeyPress, errorAlert } from "../../../../../utils/Utils";
import { findUserPasswordAction } from "../../../../../action/userAction";
import BasicButton from "view/components/_atom/button/BasicButton";
import logo from "../../../../assets/images/signup_logo_EQHub.png";
import InitialContentTemplate from "../../components/InitialContentTemplate";
import ButtonContainer from "view/components/_atom/container/ButtonContainer";
import InitialNoticeTemplate from "../../components/InitialNoticeTemplate";
import useValidateCondition from "hooks/useValidateCondition";
import { VALIDATION__USER_SIGNUP_email } from "view/service/user/validation/userValidation";
import useIsAble from "hooks/useIsAble";
import CommentInput from "view/components/_atom/input/CommentInput";

enum FindPasswordSteps {
  ENTER_EMAIL = 1,
  SUCCESS = 2,
}

function FindPassword() {
  const [email, setEmail] = useState<string>("");
  const [findPasswordStep, setFindPasswordStep] = useState<FindPasswordSteps>(FindPasswordSteps.ENTER_EMAIL);

  return (
    <div className="find-password-container">
      <img src={logo} className="find-password-logo" alt="" />
      {findPasswordStep === FindPasswordSteps.ENTER_EMAIL && <EnterEmail email={email} setEmail={setEmail} setFindPasswordStep={setFindPasswordStep} />}
      {findPasswordStep === FindPasswordSteps.SUCCESS && <SendEmailSuccess email={email} />}
    </div>
  );
}

export default FindPassword;

type EnterEmailProps = {
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  setFindPasswordStep: React.Dispatch<React.SetStateAction<FindPasswordSteps>>;
};

const EnterEmail = (props: EnterEmailProps) => {
  const { email, setEmail, setFindPasswordStep } = props;

  const sendEmailForFindPassword_ = async () => {
    const { result, error } = await findUserPasswordAction(email);
    if (result) {
      setFindPasswordStep(FindPasswordSteps.SUCCESS);
    }
    if (error) {
      errorAlert(error.data.message);
    }
  };

  const emailValidation = useValidateCondition([email], VALIDATION__USER_SIGNUP_email(email));

  const isAble = useIsAble([emailValidation.isValid]);

  return (
    <InitialContentTemplate
      title="Reset password"
      description={`Please enter the email address you registered with.\n We will send you a password reset link by email.`}
    >
      <div className="find-password-enter-email">
        <CommentInput
          {...emailValidation}
          placeholder={"ex) eqbr@eqbr.com"}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onKeyPress={(e) => enterKeyPress(e, sendEmailForFindPassword_)}
        />
        <div className="find-password-enter-email-button-container">
          <ButtonContainer>
            <BasicButton disabled={!isAble} onClick={() => sendEmailForFindPassword_()}>
              Send mail
            </BasicButton>
          </ButtonContainer>
        </div>
      </div>
    </InitialContentTemplate>
  );
};

type SendEmailSuccessProps = {
  email: string;
};

const SendEmailSuccess = (props: SendEmailSuccessProps) => {
  const { email } = props;
  const navigate = useNavigate();

  const [resendDisabled, setResendDisabled] = useState<boolean>(false);

  const resendButtonHandler = async () => {
    if (resendDisabled) {
      toast.error("Please try again in a few seconds.");
      return;
    }

    await resendEmailForFindPassword_();
  };

  const resendEmailForFindPassword_ = async () => {
    const { result, error } = await findUserPasswordAction(email);
    if (result) {
      toast.success("Verification mail has been sent again!");
      setResendDisabled(true);
    }
    if (error) {
      errorAlert(error.data.message);
    }
  };

  useEffect(() => {
    if (resendDisabled) {
      setTimeout(() => {
        setResendDisabled(false);
      }, 5000);
    }
  }, [resendDisabled]);

  return (
    <InitialNoticeTemplate image={emailImage}>
      <div className="find-password-success-content">
        <div className="find-password-success-content-description">
          <p>
            We've sent an email to <span className="find-password-success-content-description-email">{email}</span> to reset your password.
          </p>
          <p>Please reset the password through the link in the email.</p>
          <p>The link in the email will expire in 24 hours.</p>
        </div>
        <div className="find-password-success-content-receive">
          <span className="find-password-success-content-receive-trigger" onClick={() => resendButtonHandler()}>
            Click here
          </span>
          <span> if you did not receive an email.</span>
        </div>
      </div>
      <div className="find-password-success-button-container">
        <ButtonContainer>
          <BasicButton onClick={() => navigate("/initialize")}>Done</BasicButton>
        </ButtonContainer>
      </div>
    </InitialNoticeTemplate>
  );
};
