import "./AirdropConditionEventBox.scss";
import removeIcon from "../../../../../assets/images/button-common-remove.png";
import BasicInput from "view/components/_atom/input/BasicInput";

const AirdropConditionEventBox = (props) => {
  const { data, setData, index, disabled = false } = props;

  const setEventInput = (value) => {
    setData((prevState) => {
      return prevState.map((el, i) => {
        if (i === index) {
          return { ...el, title: value };
        } else {
          return { ...el };
        }
      });
    });
  };

  const setResultInput = (value) => {
    setData((prevState) => {
      return prevState.map((el, i) => {
        if (i === index) {
          return { ...el, placeholder: value };
        } else {
          return { ...el };
        }
      });
    });
  };

  const onClickRemoveBox = () => {
    setData((prevState) => {
      return prevState.filter((el, i) => i !== index);
    });
  };

  return (
    <div style={{ marginBottom: 10 }}>
      <div className="airdrop-condition-event-box">
        <div className="airdrop-condition-event-box-wrapper">
          <div className="airdrop-condition-event-box-header">
            <div className="airdrop-condition-event-box-label">Event</div>
            {!disabled && (
              <div className="airdrop-condition-event-box-delete" onClick={() => onClickRemoveBox()}>
                <img className="airdrop-condition-event-box-delete-icon" src={removeIcon} alt="" />
              </div>
            )}
          </div>
          <BasicInput
            placeholder={"Enter a description of the event that the user must perform in order to participate in the project"}
            onChange={(e) => setEventInput(e.target.value)}
            value={data.title ?? ""}
            readOnly={disabled}
          />
        </div>
        <div className="airdrop-condition-event-box-wrapper">
          <div className="airdrop-condition-event-box-header">
            <div className="airdrop-condition-event-box-label">Result</div>
          </div>
          <BasicInput
            placeholder={"For the above event, please explain what data should be entered to certify that the user participated"}
            onChange={(e) => setResultInput(e.target.value)}
            value={data.placeholder ?? ""}
            readOnly={disabled}
          />
        </div>
      </div>
    </div>
  );
};

export default AirdropConditionEventBox;
