import { useState, useRef } from "react";
import "./WalletCardSetting.scss";
import CardPreviewer from "../../../../../components/_atom/viewer/CardPreviewer";
import ColorPicker from "view/components/_atom/select/ColorPicker";
import BasicButton from "view/components/_atom/button/BasicButton";
import { ButtonSize } from "view/components/_atom/button/StyledButton";

const WalletCardSetting = ({ cardImage, setCardImage, cardColor, setCardFontColor, symbol, networkLabel }) => {
  const imageRef = useRef(null);

  const [colorPickerOpen, setColorPickerOpen] = useState(false);

  const uploadImage = () => {
    if (imageRef.current.files[0]) {
      const file = imageRef.current.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        if (file.size > 2 * 1024 * 1024 * 1024) {
          alert("2GB OVER");
        } else {
          setCardImage(file);
        }
      };
    }
  };

  return (
    <div className="wallet-card-setting">
      <CardPreviewer cardImage={cardImage} cardColor={cardColor} networkLabel={networkLabel ?? ""} symbol={symbol} />
      <div className="wallet-card-setting-button">
        <div className="wallet-card-setting-button-row">
          <div className="wallet-card-setting-button-index">Image</div>
          <div className="wallet-card-uploader">
            <input
              name={"wallet-card-uploader"}
              type="file"
              accept="image/*"
              ref={imageRef}
              onChange={(e) => {
                uploadImage(e);
              }}
            />
            {/* <Button className={"round main"} text={"Upload"} onClick={() => imageRef.current.click()} /> */}
            <BasicButton size={ButtonSize.DYNAMIC} onClick={() => imageRef.current.click()}>
              Upload
            </BasicButton>
          </div>
        </div>
        <div className="wallet-card-setting-button-row">
          <div className="wallet-card-setting-button-index">Text color</div>
          <ColorPicker color={cardColor} setColor={setCardFontColor} isOpen={colorPickerOpen} setIsOpen={setColorPickerOpen} />
        </div>
      </div>
    </div>
  );
};

export default WalletCardSetting;
