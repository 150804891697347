import React, { useEffect, useMemo, useState } from "react";
import "./PaymentGatewaySmartContractDeposit.scss";
import { useParams } from "react-router-dom";
import BasicInput from "view/components/_atom/input/BasicInput";
import {
  DEPOSIT_LIMIT,
  DEPOSIT_QUERY_DATA_FORMAT,
  SMART_CONTRACT_DEPOSIT_STATUS,
} from "../constants/paymentGateway.data";
import ButtonContainer from "view/components/_atom/container/ButtonContainer";
import BasicButton from "view/components/_atom/button/BasicButton";
import {
  ButtonSize,
  ButtonTheme,
} from "view/components/_atom/button/StyledButton";
import moment from "moment";
import {
  getDepositListForContractTypeAction,
  retryDepositAlertForContractTypeAction,
} from "action/paymentGatewayAction";
import { addComma, errorAlert } from "utils/Utils";
import toast from "react-hot-toast";
import iconCopyDefault from "../../../../assets/images/icon_copy_default.png";
import iconCopyHover from "../../../../assets/images/icon_copy_hover.png";
import retryIcon from "../../../../assets/images/Icon_retry.png";
import {
  addressSimplificationConvertor,
  fromWeiDecimals,
} from "utils/convert.utils";
import Table from "legacy-common/atom/table/Table";
import PagingNumber from "view/components/_atom/paging/PagingNumber";
import RadioButton from "legacy-common/atom/button/RadioButton";
import {
  ColumnManageField,
  ManageFieldContainer,
  ManageFieldLabel,
} from "view/components/_atom/section/ManageField";
import pgIcon from "../../../../assets/images/service/Icon_SNB_payment_gateway.png";

const PaymentGatewaySmartContractDeposit = (props) => {
  const { paymentMethodDetail } = props;
  const { id: paymentMethodId } = useParams();

  const [searchQuery, setSearchQuery] = useState(DEPOSIT_QUERY_DATA_FORMAT);

  const [totalDepositHistoryCount, setTotalDepositHistoryCount] =
    useState(null);
  const [depositList, setDepositList] = useState([]);
  const [isDepositAction, setIsDepositAction] = useState(false);
  const [currentPage, setCurrentPage] = useState("1");

  const setStartDate = (date) => {
    setSearchQuery((prevState) => {
      return {
        ...prevState,
        createdStartDate: date,
      };
    });
  };

  const setEndDate = (date) => {
    setSearchQuery((prevState) => {
      return {
        ...prevState,
        createdEndDate: date,
      };
    });
  };

  const setUserAddress = (address) => {
    setSearchQuery((prevState) => {
      return {
        ...prevState,
        address,
      };
    });
  };

  const setStatus = (status) => {
    setSearchQuery((prevState) => {
      return {
        ...prevState,
        status: status,
      };
    });
  };

  const getDepositList_ = async (page = 1) => {
    if (typeof currentPage === "string" && currentPage === "") {
      return;
    }

    const offset = (Number(page) - 1) * DEPOSIT_LIMIT;
    const { error, result } = await getDepositListForContractTypeAction(
      paymentMethodId,
      DEPOSIT_LIMIT,
      offset,
      { ...searchQuery }
    );
    if (result) {
      const { count, rows } = result;
      setTotalDepositHistoryCount(count);
      setDepositList(rows);
    }
    if (error) {
      errorAlert(error.data.message);
    }
  };

  const onClickSearch = async () => {
    setCurrentPage("1");
    getDepositList_();
  };

  const onClickReset = () => {
    setCurrentPage("1");
    setSearchQuery(DEPOSIT_QUERY_DATA_FORMAT);
  };

  const maximumPage = useMemo(() => {
    if (totalDepositHistoryCount === 0) {
      return 1;
    }
    if (totalDepositHistoryCount % DEPOSIT_LIMIT) {
      return parseInt(totalDepositHistoryCount / DEPOSIT_LIMIT) + 1;
    } else {
      return parseInt(totalDepositHistoryCount / DEPOSIT_LIMIT);
    }
  }, [totalDepositHistoryCount]);

  const depositResultHeaders = useMemo(
    () => [
      { title: "ID", accessor: "id" },
      { title: "Purchase ID", accessor: "purchaseId" },
      { title: "Account", accessor: "address" },
      { title: "Time", accessor: "createdAt" },
      { title: "Amount", accessor: "amount" },
      { title: "Tx Hash", accessor: "txHash" },
      { title: "Status", accessor: "status" },
    ],
    []
  );

  const colGroup = useMemo(
    () =>
      [7, 16, 16, 16, 16, 16, 13].map((el) => ({ style: { width: `${el}%` } })),
    []
  );

  const depositResultRenderer = (data) => ({
    id: Boolean(data.contractTransactionId) ? data.contractTransactionId : "-",
    purchaseId: Boolean(data.paymentRequest.requestId) ? (
      <CopyLabel data={data.paymentRequest.requestId} />
    ) : (
      "-"
    ),
    address: Boolean(data.from) ? <CopyLabel data={data.from} /> : "-",
    createdAt: Boolean(data.createdAt) ? (
      <DepositCreationDate date={data.createdAt} />
    ) : (
      "-"
    ),
    amount: Boolean(data.amount) ? (
      <ParseAmount
        amount={data.amount}
        decimals={paymentMethodDetail.wallet20?.decimals}
      />
    ) : (
      "-"
    ),
    txHash: Boolean(data.txHash) ? <CopyLabel data={data.txHash} /> : "-",
    status: Boolean(data) ? (
      <DepositStatus data={data} setIsDepositAction={setIsDepositAction} />
    ) : (
      "-"
    ),
  });

  useEffect(() => {
    if (isDepositAction) {
      getDepositList_(currentPage);
      setIsDepositAction(false);
    }
  }, [isDepositAction]);

  return (
    <>
      {/* Filter Description :: (Optional) You can set conditions for the data shown in the 'Deposit info' area at the bottom. */}
      <ManageFieldContainer icon={pgIcon} title="Filter">
        <ColumnManageField>
          <ManageFieldLabel label="Date" />
          <div className="payment-gateway-date-picker-container">
            <BasicInput
              type="date"
              value={searchQuery.createdStartDate}
              onChange={(e) => setStartDate(e.target.value)}
              max={moment(new Date()).format("YYYY-MM-DD")}
            />
            <span>~</span>
            <BasicInput
              type="date"
              value={searchQuery.createdEndDate}
              onChange={(e) => setEndDate(e.target.value)}
              min={searchQuery.createdStartDate}
              max={moment(new Date()).format("YYYY-MM-DD")}
            />
          </div>
        </ColumnManageField>
        <ColumnManageField>
          <ManageFieldLabel label="Account" />
          <BasicInput
            value={searchQuery.address}
            onChange={(e) => setUserAddress(e.target.value)}
            placeholder={"Ex) 0x………"}
          />
        </ColumnManageField>
        <ColumnManageField>
          <ManageFieldLabel label="Status" />
          <div className="payment-gateway-status-option-container">
            {SMART_CONTRACT_DEPOSIT_STATUS.map((option) => (
              <RadioButton
                key={`payment-gateway-smart-contract-deposit-option-${option.value}`}
                id={`smart-contract-deposit-option-${option.value}`}
                label={option.label}
                value={option.value}
                name={`payment-gateway-smart-contract-deposit`}
                onChange={(e) => setStatus(e.target.value)}
                checked={searchQuery.status === option.value}
                labelStyle={{ padding: "0 0 0 25px" }}
              />
            ))}
          </div>
        </ColumnManageField>

        <div className="payment-gateway-contract-deposit-filter-button-container">
          <ButtonContainer>
            <BasicButton
              theme={ButtonTheme.SECONDARY}
              size={ButtonSize.DYNAMIC}
              onClick={() => onClickReset()}
            >
              Reset
            </BasicButton>
            <BasicButton
              size={ButtonSize.DYNAMIC}
              onClick={() => onClickSearch()}
            >
              Search
            </BasicButton>
          </ButtonContainer>
        </div>
      </ManageFieldContainer>
      <div style={{ height: 20 }} />
      {/* Deposit Info Description :: Check the details of coin deposited through Payment gateway. */}
      <ManageFieldContainer icon={pgIcon} title="Deposit Info">
        <div>
          <Table
            headers={depositResultHeaders}
            colGroup={colGroup}
            data={depositList}
            renderer={depositResultRenderer}
            style={{ cursor: "default" }}
          />
          <PagingNumber
            page={currentPage}
            setPage={setCurrentPage}
            maximumPage={maximumPage}
            getData={getDepositList_}
          />
        </div>
      </ManageFieldContainer>
    </>
  );
};
export default PaymentGatewaySmartContractDeposit;

// ------------------------------ COMPONENTS -------------------------------------

// -------------------------------------------------------------------------------
// -------------------------------------------------------------------------------
// DATA LABEL WITH COPY
const DepositCreationDate = ({ date }) => {
  return (
    <div className="payment-gateway-deposit-creation-date">
      <p className="payment-gateway-deposit-creation-date date">
        {moment(date).format("YY/MM/DD")}
      </p>
      <p className="payment-gateway-deposit-creation-date time">
        {moment(date).format("HH:mm:ss")}
      </p>
    </div>
  );
};

// -------------------------------------------------------------------------------
// -------------------------------------------------------------------------------
// DATA LABEL WITH COPY
const CopyLabel = ({ data }) => {
  const [isIconHover, setIsIconHover] = useState(false);

  const onClickCopyData = () => {
    window.navigator.clipboard
      .writeText(data)
      .then(() => toast.success("Copied!"));
  };

  return (
    <div className="payment-gateway-smart-contract-deposit-copy-label">
      <span>{addressSimplificationConvertor(data, 4)}</span>
      <div className="payment-gateway-smart-contract-deposit-copy-label-icon">
        <img
          className="payment-gateway-smart-contract-deposit-copy-label-icon-src"
          onMouseOver={() => setIsIconHover(true)}
          onMouseLeave={() => setIsIconHover(false)}
          src={isIconHover ? iconCopyHover : iconCopyDefault}
          alt="copy"
          onClick={() => onClickCopyData()}
        />
      </div>
    </div>
  );
};

// -------------------------------------------------------------------------------
// -------------------------------------------------------------------------------
// DATA LABEL WITH COPY
const DepositStatus = ({ data, setIsDepositAction }) => {
  const { status, contractTransactionAlert, contractTransactionId } =
    data || {};
  const { status: callbackAlert } = contractTransactionAlert || {};

  const retryDepositCallback_ = async () => {
    const { result, error } = await retryDepositAlertForContractTypeAction(
      contractTransactionId
    );
    if (result) {
      setIsDepositAction(true);
    }

    if (error) {
      errorAlert(error.data.message);
    }
  };

  return (
    <div className="payment-gateway-deposit-status">
      {status === 0 && (
        <div className="payment-gateway-deposit-status-label ready">Ready</div>
      )}
      {status === 1 && (
        <div className="payment-gateway-deposit-status-label inprogress">
          In progress
        </div>
      )}
      {status === 2 && (
        <div className="payment-gateway-deposit-status-label failed">
          Failed
        </div>
      )}
      {status === 3 && callbackAlert === 0 && (
        <div className="payment-gateway-deposit-status-label inprogress">
          Processing
        </div>
      )}
      {status === 3 && callbackAlert === 1 && (
        <div className="payment-gateway-deposit-status-label inprogress">
          Processing
        </div>
      )}
      {status === 3 && callbackAlert === 2 && (
        <div
          className="payment-gateway-deposit-status-label aborted"
          onClick={() => retryDepositCallback_()}
        >
          <span>Aborted</span>
          <img
            src={retryIcon}
            className="payment-gateway-deposit-status-label-icon"
            alt="retry"
          />
        </div>
      )}
      {status === 3 && callbackAlert === 3 && (
        <div className="payment-gateway-deposit-status-label success">
          Success
        </div>
      )}
    </div>
  );
};

// -------------------------------------------------------------------------------
// -------------------------------------------------------------------------------
// ParseAmount COMPONENT
const ParseAmount = (props) => {
  const { amount, decimals } = props;

  return <span>{addComma(fromWeiDecimals(amount, decimals))}</span>;
};
