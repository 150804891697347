import React, { useMemo, useState } from "react";
import CreateProjectStep1 from "../organisms/CreateProjectStep1";
import CreateProjectStep2 from "../organisms/CreateProjectStep2";
import CreateProjectStep3 from "../organisms/CreateProjectStep3";
import "./CreateProject.scss";
import IconProject from "../../../dashboard/assets/Icon_dashboard_project_info.png";
import IconPlan from "../../../dashboard/assets/Icon_dashboard_price.png";
import IconPayment from "../../../dashboard/assets/Icon_dashboard_card.png";
import { createProjectAction, getInitialDataForCreateProjectAction } from "../../../../../action/projectAction";
import { errorAlert } from "utils/Utils";
import PageLoading from "../../../../components/_atom/loading/PageLoading";
import createProjectImage from "../assets/img_create_project.png";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { Project } from "../../../../../reducer/clientType/projectClientType";
import useLoading from "hooks/useLoading";

export type ProjectDataType = {
  name: string;
  description: string;
  image: string | File;
  selectedMicroChainIds: Array<number>;
  pricingPlanId: number;
  cardDetail: string;
  expirationDate: string;
  cvc: string;
};

const INIT_PROJECT_DATA = {
  name: "",
  description: "",
  image: "",
  selectedMicroChainIds: [],
  pricingPlanId: 1,
  cardDetail: "",
  expirationDate: "",
  cvc: "",
};

const CreateProject = () => {
  const navigate = useNavigate();

  const [projectDefaultImageList, setProjectDefaultImageList] = useState<Array<string>>([]);
  const [networkList, setNetworkList] = useState<Array<any>>([]);
  const [myProjects, setMyProjects] = useState<Array<Project>>([]);

  const [projectData, setProjectData] = useState<ProjectDataType>(INIT_PROJECT_DATA);
  const [step, setStep] = useState<number>(1);

  const stepData = useMemo(() => {
    switch (step) {
      case 1:
        return {
          icon: IconProject,
          label: "Enter Basic Information",
        };
      case 2:
        return {
          icon: IconPlan,
          label: "API Pricing Plans",
        };
      case 3:
        return {
          icon: IconPayment,
          label: "Add Payment",
        };
      default:
        return {
          icon: IconProject,
          label: "Enter Basic Information",
        };
    }
  }, [step]);

  const setProjectImage = (value: string | File): void => {
    setProjectData((prevState) => {
      return { ...prevState, image: value };
    });
  };

  const getInitialData_ = async () => {
    const { result, error } = await getInitialDataForCreateProjectAction();

    if (result) {
      const { defaultImage, networkList, myProjects } = result;
      setMyProjects(myProjects);
      setProjectDefaultImageList(defaultImage);
      setProjectImage(defaultImage[0].url);
      setNetworkList(networkList);
    }

    if (error) {
      // errorAlert(error.data.message);
    }
  };

  const createProject_ = async () => {
    const { result, error } = await createProjectAction(projectData.name, projectData.image, projectData.pricingPlanId, {
      description: projectData.description,
      selectedMicroChainIds: projectData.selectedMicroChainIds,
    });

    if (result) {
      toast.success("Create successfully");
      navigate("/");
    }

    if (error) {
      errorAlert(error.data.message);
    }
  };

  const { loading } = useLoading({
    dependency: [true],
    synchronousFunction: async () => {
      await getInitialData_();
    },
  });

  if (loading) {
    return <PageLoading />;
  }

  return (
    <div id={"CreateProject"}>
      <div className="create-project-wrapper">
        <div className="create-project-header">
          <span className="create-project-header-title">Create your Project</span>
          <span className="create-project-header-description">
            We will begin creating your project. It's essential to have at least one project to use the EQ Hub.
          </span>
        </div>
        <div className="create-project-label">
          <div className="create-project-label-icon-box">
            <img className="create-project-label-icon" src={stepData.icon} alt="icon" />
          </div>
          <span className="create-project-label-text">{stepData.label}</span>
        </div>
        {step === 1 && (
          <CreateProjectStep1
            setStep={setStep}
            projectData={projectData}
            setProjectData={setProjectData}
            images={projectDefaultImageList}
            networkList={networkList}
            createProject={createProject_}
          />
        )}
        {step === 2 && (
          <CreateProjectStep2
            myProjects={myProjects}
            setStep={setStep}
            projectData={projectData}
            setProjectData={setProjectData}
            createProject={createProject_}
          />
        )}
        {step === 3 && <CreateProjectStep3 setStep={setStep} projectData={projectData} setProjectData={setProjectData} createProject={createProject_} />}
      </div>
      <div className="create-project-background">
        <div className="create-project-background-image">
          <img src={createProjectImage} className="create-project-background-image-src" alt="" />
        </div>
      </div>
    </div>
  );
};

export default CreateProject;
