import { useCallback, useEffect, useMemo, useState } from "react";
import PageLoading from "../../../../components/_atom/loading/PageLoading";
import Table from "../../../../../legacy-common/atom/table/Table";
import { CONTRACT_DISCLOSURE, CONTRACT_TYPE } from "../constants/contract.data";
import { useNavigate } from "react-router-dom";
import TableStatusLabel from "../../../../components/_atom/label/TableStatusLabel";
import { errorAlert } from "../../../../../utils/Utils";
import Section from "../../../../../legacy-common/atom/section/Section";
import { getStorageContractAction } from "../../../../../action/contractLibraryAction";
import PageArticle from "../../../../components/_template/page/PageArticle";
import PageTitle from "../../../../components/_molecules/page/PageTitle";
import BasicButton from "view/components/_atom/button/BasicButton";
import { ButtonSize, ButtonTheme } from "view/components/_atom/button/StyledButton";
import useLoading from "hooks/useLoading";
import { useSelector } from "react-redux";
import { selected_project_id } from "reducer/projectReducer";

const ContractStorage = () => {
  const navigate = useNavigate();
  const projectId = useSelector(selected_project_id);

  const headers = useMemo(
    () => [
      { title: "ID", accessor: "contractId" },
      { title: "Title", accessor: "title" },
      { title: "Type", accessor: "type" },
      { title: "Public/Private", accessor: "isPrivate" },
      { title: "Version", accessor: "version" },
      { title: "Status", accessor: "status" },
    ],
    []
  );
  const renderer = useCallback(
    (data) => ({
      contractId: data.contractId,
      title: data.title,
      type: CONTRACT_TYPE.find((el) => el.value === data.type).label,
      isPrivate: CONTRACT_DISCLOSURE.find((el) => el.value === data.isPrivate).label,
      version: data.version,
      status: TableStatusLabel({
        mainStatus: data.status,
        custom: [
          { status: 3, text: "Unpublished", style: 1 },
          { status: 4, text: "Published", style: 5 },
        ],
      }),
    }),
    []
  );
  const colGroup = useMemo(() => [10, 20, 20, 15, 15, 20].map((el) => ({ style: { width: `${el}%` } })), []);

  const [inProgressContracts, setInProgressContracts] = useState([]);
  const [approvedContracts, setApprovedContracts] = useState([]);

  const setStoredContractsFromServer = async () => {
    const { error, result } = await getStorageContractAction();
    if (error) {
      errorAlert(error.data.message);
    } else {
      const { inProgressVersions, approvedVersions } = result;
      setInProgressContracts(inProgressVersions);
      setApprovedContracts(approvedVersions);
    }
  };

  const onClickStoredContract = (el) => {
    navigate(`/developer/contract/storage/${el.contractId}/version/${el.contractVersionId}`);
  };

  const CONTRACT_STORAGE_IN_PROGRESS_VIEW = () => [
    {
      renderers: [
        {
          value: (
            <Table
              headers={headers}
              data={inProgressContracts}
              colGroup={colGroup}
              renderer={renderer}
              onClick={onClickStoredContract}
              noDataPlaceholder={"No Contracts"}
            />
          ),
        },
      ],
    },
  ];

  const CONTRACT_STORAGE_APPROVED_VIEW = () => [
    {
      renderers: [
        {
          value: (
            <Table
              headers={headers}
              data={approvedContracts}
              colGroup={colGroup}
              renderer={renderer}
              onClick={onClickStoredContract}
              noDataPlaceholder={"No Contracts"}
            />
          ),
        },
      ],
    },
  ];

  const { loading } = useLoading({
    dependency: [projectId],
    synchronousFunction: async () => {
      await setStoredContractsFromServer();
    },
  });

  if (loading) {
    return <PageLoading />;
  }

  return (
    <PageArticle>
      <PageTitle title={"Contract Storage"} description={"Create and manage your own contracts"} size={1240} />
      <div className="page-layout-1240">
        <BasicButton theme={ButtonTheme.CREATE} size={ButtonSize.EXTRALARGE} onClick={() => navigate("/developer/contract/create")}>
          Create a new Contract
        </BasicButton>
        <div style={{ height: 30 }} />

        <Section title={"In Progress"} view={CONTRACT_STORAGE_IN_PROGRESS_VIEW} />
        <Section title={"Approved"} view={CONTRACT_STORAGE_APPROVED_VIEW} />
      </div>
    </PageArticle>
  );
};

export default ContractStorage;
