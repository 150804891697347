import "./Notification.scss";
import Text from "../../../components/_atom/text/Text";
import moment from "moment";
import { errorAlert } from "../../../../utils/Utils";
import { useState } from "react";
import Icon_DeleteNoti from "../../../assets/images/atom/Icon_delete_noti.png";
import { useNavigate } from "react-router-dom";
import { deleteNotificationAction, readNotificationAction } from "../../../../action/notificationAction";

const Notification = ({ notification, getNotifications_, setNotificationPanelOpen }) => {
  const navigate = useNavigate();
  const [isMouseOver, setIsMouseOver] = useState(false);

  const clickNotification = async () => {
    if (!!notification.link) navigate(notification.link);
    const result = await readNotification();
    if (result) {
      await getNotifications_();
    }
  };

  const readNotification = async () => {
    const { error, result } = await readNotificationAction(notification.notificationId);
    if (error) {
      errorAlert(error.data.message);
    } else {
      return result;
    }
  };

  const clickDeleteNotification = async (e) => {
    e.stopPropagation();
    const result = await deleteNotification();
    if (result) {
      await getNotifications_();
    }
  };

  const deleteNotification = async () => {
    const { error, result } = await deleteNotificationAction(notification.notificationId);
    if (error) {
      errorAlert(error.data.message);
    } else {
      return result;
    }
  };

  return (
    <div className="notification" onClick={() => clickNotification()} onMouseOver={() => setIsMouseOver(true)} onMouseLeave={() => setIsMouseOver(false)}>
      <div className="flex justify-content-space-between" style={{ width: "100%" }}>
        <div className="unread-mark" style={{ backgroundColor: notification.isRead ? "transparent" : "#5888ff" }} />
        <div className="notification-content">
          {notification.contents.map((content, index) => (
            <Text key={index} text={content.text} className="TC__black TT__section-description" />
          ))}
          <Text
            text={moment(notification.createdAt).format("MMMM Do YYYY, h:mm a")}
            className="TC__gray TT__section-description"
            style={{ fontSize: 12, marginBottom: 2 }}
          />
        </div>
        <div className="delete-icon-wrapper">
          {isMouseOver && <img className="delete-notification-icon" src={Icon_DeleteNoti} alt="delete" onClick={(e) => clickDeleteNotification(e)} />}
        </div>
      </div>
    </div>
  );
};

export default Notification;
