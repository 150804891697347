import { makeQuery } from "../index/utils";
import { v1ServerByProjectAuth } from "../index/serverInstances";
import {
  DepositHistoryFromServer,
  DepositListForContractTypeForServer,
  PaymentGatewayFromServer,
  PaymentTypeFromServer,
  SelectedPaymentGatewayTypeFromServer,
  WithdrawalHistoryFromServer,
} from "../type/paymentMethodType";

//----------getPaymentTypePresets
const getPaymentTypePresets = async (): Promise<getPaymentTypePresetsResponse> =>
  await v1ServerByProjectAuth.get({
    path: "payment-gateway/payment-types",
  });
type getPaymentTypePresetsResponse = PaymentTypeFromServer[];

//----------getPaymentGateways
const getPaymentGateways = async (): Promise<GetPaymentGatewaysResponse> =>
  await v1ServerByProjectAuth.get({
    path: "payment-gateway/payment-methods/me",
  });

type GetPaymentGatewaysResponse = PaymentGatewayFromServer[];

//----------getPaymentGatewayDetail
const getPaymentGatewayDetail = async (request: GetPaymentMethodDetailRequest): Promise<GetPaymentMethodDetailResponse> =>
  await v1ServerByProjectAuth.get({
    path: `payment-gateway/payment-methods/${request.params.paymentMethodId}`,
  });

type GetPaymentMethodDetailRequest = {
  params: {
    paymentMethodId: string;
  };
};

type GetPaymentMethodDetailResponse = PaymentGatewayFromServer;

//----------getSelectedPaymentGatewayType
const getSelectedPaymentGatewayType = async (request: getSelectedPaymentGatewayTypeRequest): Promise<getSelectedPaymentGatewayTypeResponse> =>
  await v1ServerByProjectAuth.get({
    path: `payment-gateway/payment-method-types`,
    query: makeQuery(request.query),
  });

type getSelectedPaymentGatewayTypeRequest = {
  query: {
    paymentMethodId: string;
  };
};

type getSelectedPaymentGatewayTypeResponse = SelectedPaymentGatewayTypeFromServer[];

//----------createPaymentMethod
const createPaymentMethod = async (request: CreatePaymentMethodRequest): Promise<CreatePaymentMethodResponse> =>
  await v1ServerByProjectAuth.post({
    path: "payment-gateway/payment-methods",
    data: request.data,
  });

type CreatePaymentMethodRequest = {
  data: {
    paymentMethodName: string;
    microChainId: number;
    microChainCurrencyId: number;
    paymentTypeIds: Array<number>;
    walletAddress: string;
    depositCallbackURI: string;
    withdrawalCallbackURI: string;
    blockConfirmationCount?: number;
  };
};

type CreatePaymentMethodResponse = PaymentGatewayFromServer;

//----------updatePaymentMethod
const updatePaymentMethod = async (request: UpdatePaymentMethodRequest): Promise<UpdatePaymentMethodResponse> =>
  await v1ServerByProjectAuth.put({
    path: `payment-gateway/payment-methods/${request.params.paymentMethodId}`,
    data: request.data,
  });

type UpdatePaymentMethodRequest = {
  params: {
    paymentMethodId: string;
  };
  data: {
    walletAddress: string;
    depositCallbackURI: string;
    withdrawalCallbackURI: string;
  };
};

type UpdatePaymentMethodResponse = PaymentGatewayFromServer;

//----------submitPaymentMethod
const submitPaymentMethod = async (request: submitPaymentMethodRequest): Promise<submitPaymentMethodResponse> =>
  await v1ServerByProjectAuth.post({
    path: `payment-gateway/payment-methods/${request.params.paymentMethodId}/submit`,
  });

type submitPaymentMethodRequest = {
  params: {
    paymentMethodId: string;
  };
};

type submitPaymentMethodResponse = any;

//----------deletePaymentMethod
const deletePaymentMethod = async (request: DeletePaymentMethodRequest): Promise<DeletePaymentMethodResponse> =>
  await v1ServerByProjectAuth.delete({
    path: `payment-gateway/payment-methods/${request.params.paymentMethodId}`,
  });

type DeletePaymentMethodRequest = {
  params: {
    paymentMethodId: string;
  };
};

type DeletePaymentMethodResponse = {
  status: boolean;
};

//----------getDepositHistory
const getDepositHistory = async (request: GetDepositHistoryRequest): Promise<GetDepositHistoryResponse> =>
  await v1ServerByProjectAuth.get({
    path: `payment-gateway/virtual-addresses/deposit-history`,
    query: makeQuery(request.query),
  });

type GetDepositHistoryRequest = {
  query: {
    paymentMethodId: string;
    limit: number;
    offset: number;
    address?: string;
    createdStartDate?: string;
    createdEndDate?: string;
    depositStatus?: string;
    transferStatus?: string;
  };
};

type GetDepositHistoryResponse = {
  count: number;
  rows: DepositHistoryFromServer[];
};

//----------retryAlert
const retryAlert = async (request: RetryAlertRequest): Promise<RetryAlertResponse> =>
  await v1ServerByProjectAuth.post({
    path: `payment-gateway/virtual-addresses/${request.params.virtualAddressId}/virtual-address-transactions/${request.params.virtualAddressTransactionId}/retry`,
  });

type RetryAlertRequest = {
  params: {
    virtualAddressId: number;
    virtualAddressTransactionId: number;
  };
};

type RetryAlertResponse = {
  status: boolean;
};

//----------resendDeposit
const resendDeposit = async (request: ResendDepositRequest): Promise<ResendDepositResponse> =>
  await v1ServerByProjectAuth.post({
    path: `payment-gateway/virtual-addresses/${request.params.virtualAddressId}/virtual-address-transactions/${request.params.virtualAddressTransactionId}/resend`,
  });

type ResendDepositRequest = {
  params: {
    virtualAddressId: number;
    virtualAddressTransactionId: number;
  };
};

type ResendDepositResponse = {
  status: boolean;
};

//----------getWithdrawals
const getWithdrawals = async (request: GetWithdrawalsRequest): Promise<GetWithdrawalsResponse> =>
  await v1ServerByProjectAuth.get({
    path: `payment-gateway/withdrawal`,
    query: makeQuery(request.query),
  });

type GetWithdrawalsRequest = {
  query: {
    paymentMethodId: string;
    limit: number;
    offset: number;
    address?: string;
    withdrawalStartDate?: string;
    withdrawalEndDate?: string;
    withdrawalStatus?: string;
    withdrawalAlertStatus?: string;
  };
};

type GetWithdrawalsResponse = {
  count: number;
  rows: WithdrawalHistoryFromServer[];
};

//----------retryWithdrawalAlert
const retryWithdrawalAlert = async (request: RetryWithdrawalAlertRequest): Promise<RetryWithdrawalAlertResponse> =>
  await v1ServerByProjectAuth.post({
    path: `payment-gateway/withdrawal/${request.params.withdrawalId}/retry`,
  });

type RetryWithdrawalAlertRequest = {
  params: {
    withdrawalId: number;
  };
};

type RetryWithdrawalAlertResponse = {
  status: boolean;
};

//----------getDepositListForContractType
const getDepositListForContractType = async (request: getDepositListForContractTypeRequest): Promise<getDepositListForContractTypeResponse> =>
  await v1ServerByProjectAuth.get({
    path: `payment-gateway/contract-transactions/deposit-history`,
    query: makeQuery(request.query),
  });

type getDepositListForContractTypeRequest = {
  query: {
    paymentMethodId: string;
    limit: number;
    offset: number;
    address?: string;
    createdStartDate?: string;
    createdEndDate?: string;
    status?: string;
  };
};

type getDepositListForContractTypeResponse = {
  count: number;
  rows: Array<DepositListForContractTypeForServer>;
};

//----------retryDepositAlertForContractType
const retryDepositAlertForContractType = async (request: retryDepositAlertForContractTypeRequest): Promise<retryDepositAlertForContractTypeResponse> =>
  await v1ServerByProjectAuth.post({
    path: `payment-gateway/contract-transactions/${request.params.contractTransactionId}/retry`,
  });

type retryDepositAlertForContractTypeRequest = {
  params: {
    contractTransactionId: number;
  };
};

type retryDepositAlertForContractTypeResponse = {
  status: boolean;
};

export {
  getPaymentTypePresets as getPaymentTypePresetsFromServer,
  getPaymentGateways as getPaymentMethodsFromServer,
  getPaymentGatewayDetail as getPaymentMethodDetailFromServer,
  getSelectedPaymentGatewayType as getSelectedPaymentGatewayTypeFromServer,
  createPaymentMethod as createPaymentMethodFromServer,
  updatePaymentMethod as updatePaymentMethodFromServer,
  submitPaymentMethod as submitPaymentMethodFromServer,
  deletePaymentMethod as deletePaymentMethodFromServer,
  getDepositHistory as getDepositHistoryFromServer,
  retryAlert as retryAlertFromServer,
  resendDeposit as resendDepositFromServer,
  getWithdrawals as getWithdrawalsFromServer,
  retryWithdrawalAlert as retryWithdrawalAlertFromServer,
  getDepositListForContractType as getDepositListForContractTypeFromServer,
  retryDepositAlertForContractType as retryDepositAlertForContractTypeFromServer,
};
