import "./MethodRequestTemplate.scss";
import Input from "../../../../../legacy-common/atom/input/Input";
import MethodInputWrapper from "../molcules/MethodInputWrapper";
import DivideLine from "view/components/_atom/line/DivideLine";
import BasicButton from "view/components/_atom/button/BasicButton";
import { ButtonShape, ButtonSize } from "view/components/_atom/button/StyledButton";

const MethodRequestTemplate = ({ selectedMethod, methodInputs, setMethodInputs, buttonText, onRequest, result, value, setValue }) => {
  return (
    <div className="method-request-template">
      <div className="method-request-template-content">
        <div className="method-request-template-header">
          <div className="method-request-template-header-name">{selectedMethod?.name ?? ""}</div>
          {selectedMethod?.description && <div className="method-request-template-header-description">{selectedMethod?.description ?? ""}</div>}
        </div>
        <DivideLine />
        <div className="method-request-template-inputs">
          {selectedMethod?.inputs.length === 0 ? (
            <>
              <div className="flex justify-content-center align-items-center" style={{ height: "100%", fontSize: 20, fontWeight: 500, color: "#848a96" }}>
                No requested parameters
              </div>
              <DivideLine style={{ marginTop: 20 }} />
            </>
          ) : (
            <>
              {selectedMethod?.inputs.map((input, index) => {
                return <MethodInputWrapper key={index} index={index} methodInputs={methodInputs} setMethodInputs={setMethodInputs} input={input} />;
              })}
              {selectedMethod.stateMutability === "payable" && (
                <div>
                  <div style={{ marginBottom: 15, fontWeight: 500, color: "#5888ff" }}>For Payable Transaction</div>
                  <div className="method-input-wrapper">
                    <div className="method-input-wrapper-name">value (uint256)</div>
                    <div className="method-input-wrapper-description">
                      Enter the number of coins to be sent together in the Transaction request (with decimals)
                    </div>
                    <div className="method-input-wrapper-input">
                      <Input value={value ?? ""} onChange={(e) => setValue(e.target.value)} placeholder={`Required Type: uint256`} />
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        <div className="flex justify-content-end" style={{ paddingTop: 20 }}>
          <BasicButton size={ButtonSize.SMALL} shape={ButtonShape.ROUNDED} onClick={() => onRequest()}>
            {buttonText}
          </BasicButton>
        </div>
      </div>
      <div className="method-request-template-result">
        <div className="method-request-template-result-title">Result</div>
        <div className="method-request-template-result-data">{result}</div>
      </div>
    </div>
  );
};

export default MethodRequestTemplate;
