import { Route, Routes } from "react-router-dom";

import ContractList from "./contract/page/ContractList";
import ManageListedContract from "./contract/page/ManageListedContract";
import UpgradeProxyContract from "./contract/page/UpgradeProxyContract";

import PaymentGatewayList from "./paymentGateway/page/PaymentGatewayList";
import CreatePaymentGateway from "./paymentGateway/page/CreatePaymentGateway";
import EditPaymentGateway from "./paymentGateway/page/EditPaymentGateway";
import ManagePaymentGateway from "./paymentGateway/page/ManagePaymentGateway";

import ChainRequest from "./chain-request/page/ChainRequest";

import ContractLibrary from "./contract/page/ContractLibrary";
import ManageLibraryContract from "./contract/page/ManageLibraryContract";
import GetProxyContractToList from "./contract/page/GetProxyContractToList";
import GetFixedContractToList from "./contract/page/GetFixedContractToList";

import CreatePrimaryContractVersion from "./contract/page/CreatePrimaryContractVersion";
import ContractStorage from "./contract/page/ContractStorage";
import CreateAdditionalContractVersion from "./contract/page/CreateAdditionalContractVersion";
import ManageStoredContract from "./contract/page/ManageStoredContract";

const DeveloperRouter = () => {
  return (
    <Routes>
      <Route path="contract/*" element={<ContractRouter />} />
      <Route path="payment-gateway/*" element={<PaymentGatewayRouter />} />
      <Route path="chain-request/*" element={<ChainRequestRouter />} />
    </Routes>
  );
};

export default DeveloperRouter;

export const ContractRouter = () => {
  return (
    <Routes>
      <Route path="create" element={<CreatePrimaryContractVersion />} />
      <Route path="storage/*">
        <Route index element={<ContractStorage />} />
        <Route path={":contractId/version/:versionId"}>
          <Route index element={<ManageStoredContract />} />
          <Route path={"update"} element={<CreateAdditionalContractVersion />} />
        </Route>
      </Route>
      <Route path="library/*">
        <Route index element={<ContractLibrary />} />
        <Route path=":contractId/version/:versionId">
          <Route index element={<ManageLibraryContract />} />
          <Route path={"get-proxy"} element={<GetProxyContractToList />} />
          <Route path={"get-fixed"} element={<GetFixedContractToList />} />
        </Route>
      </Route>
      <Route path="list/*">
        <Route index element={<ContractList />} />
        <Route path={":contractId"}>
          <Route index element={<ManageListedContract />} />
          <Route path={"upgrade"} element={<UpgradeProxyContract />} />
        </Route>
      </Route>
    </Routes>
  );
};

const DAppRouter = () => {
  return (
    <Routes>
      {/*<Route path="create" element={<>create dApp</>} />*/}
      {/*<Route path="manage/*">*/}
      {/*  <Route index element={<>dApp list</>} />*/}
      {/*  <Route path={":id"} element={<>manage dApp</>} />*/}
      {/*</Route>*/}
    </Routes>
  );
};

const PaymentGatewayRouter = () => {
  return (
    <Routes>
      <Route path="create" element={<CreatePaymentGateway />} />
      <Route path="manage/*">
        <Route index element={<PaymentGatewayList />} />
        <Route path={":id/*"}>
          <Route index element={<ManagePaymentGateway />} />
          <Route path={"edit"} element={<EditPaymentGateway />} />
        </Route>
      </Route>
    </Routes>
  );
};

const ChainRequestRouter = () => {
  return (
    <Routes>
      <Route path="request" element={<ChainRequest />} />
    </Routes>
  );
};
