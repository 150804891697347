import BigNumber from "bignumber.js";
import { removeComma } from "../../../../../utils/Utils";

/**
 *
 * @param {String} supply
 * @param {String} percent
 * @return String
 */
// 퍼센트(백분율) -> 값으로 변환
export const convertPercentToValue = (supply, percent) => {
  if (!percent) {
    return "";
  }
  const BN_supply = BigNumber(removeComma(supply));
  const BN_percent = BigNumber(removeComma(percent));
  const result = BN_supply.multipliedBy(BN_percent).dividedBy(100).toFormat();
  return result;
};

/**
 *
 * @param {String} value
 * @param {String} decimalPoint default = 1
 * @return String
 */
// 소수점 자리수 제거
export const customToFixed = (value, decimalPoint = 1) => {
  const decimalNumber = 10 ** decimalPoint;
  const BN_value = BigNumber(value);
  const BN_decimalNumber = BigNumber(decimalNumber);
  const mulDecimalPoint = Math.floor(Number(removeComma(BN_value.multipliedBy(BN_decimalNumber).toFormat())));
  const result = BigNumber(mulDecimalPoint).dividedBy(BN_decimalNumber).toFormat();
  return result;
};

/**
 *
 * @param {String} firstValue
 * @param {String} secondValue
 * @return String
 */
// BigNumber를 사용한 덧셈
export const plusCalculate = (firstValue, secondValue) => {
  const BN_firstValue = BigNumber(removeComma(firstValue));
  const BN_secondValue = BigNumber(removeComma(secondValue));
  const result = BN_firstValue.plus(BN_secondValue).toFormat();
  return result;
};

/**
 *
 * @param {String} firstValue
 * @param {String} secondValue
 * @return String
 */
// BigNumber를 사용한 뺄셈
export const subCalculate = (firstValue, secondValue) => {
  const BN_firstValue = BigNumber(removeComma(firstValue));
  const BN_secondValue = BigNumber(removeComma(secondValue));
  const result = BN_firstValue.minus(BN_secondValue).toFormat();
  return result;
};

/**
 *
 * @param {String} firstValue
 * @param {String} secondValue
 * @return String
 */
// BigNumber를 사용한 곱셈
export const mulCalculate = (firstValue, secondValue) => {
  const BN_firstValue = BigNumber(removeComma(firstValue));
  const BN_secondValue = BigNumber(removeComma(secondValue));
  const result = BN_firstValue.multipliedBy(BN_secondValue).toFormat();
  return result;
};

/**
 *
 * @param {String} firstValue
 * @param {String} secondValue
 * @return String
 */
// BigNumber를 사용한 나눗셈
export const divCalculate = (firstValue, secondValue) => {
  const BN_firstValue = BigNumber(removeComma(firstValue));
  const BN_secondValue = BigNumber(removeComma(secondValue));
  const result = BN_firstValue.dividedBy(BN_secondValue).toFormat();
  return result;
};

export const isLessThan = (value, refValue) => {
  const BN_value = BigNumber(value);
  const BN_refValue = BigNumber(refValue);
  // 값 < 기준값
  // value < refValue
  const result = BN_refValue.lt(BN_value);
  return result;
};
