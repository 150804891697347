import { WEB3ProviderEndpoint } from "reducer/clientType/authClientType";
import { getProjectAPIKeysFromServer, getWEB3ProviderEndpointFromServer } from "server/API/authAPI";

export const getProjectAPIKeysParser = async (): Promise<string> => {
  const result = await getProjectAPIKeysFromServer();

  return result[0].api_key;
};

export const getWEB3ProviderEndpointListParser = async (options?: { microChainIds?: Array<number> }): Promise<Array<WEB3ProviderEndpoint>> => {
  const result = await getWEB3ProviderEndpointFromServer({
    query: {
      ...(options?.microChainIds && { microChainIds: options.microChainIds }),
    },
  });
  const response = result.map((el) => ({
    apiKey: el.api_key,
    apiKeyId: el.api_key_id,
    chainId: el.chain_id,
    createdAt: el.created_at,
    id: el.id,
    projectId: el.project_id,
    socketApiKey: el.socket_api_key,
  }));

  return response.slice().reverse();
};
