import AirdropConditionEventBox from "../atom/AirdropConditionEventBox";
import IconAddInput from "../../../../../assets/images/icon-common-add.png";
import "./AirdropConditionEventList.scss";
import {AIRDROP_PARTICIPANT_EVENT_FORMAT} from "../../constants/airdropConstants";

const AirdropConditionEventList = (props) => {
  const { participationEventsData, setParticipationEventsData } = props || {};

  const addInput = () => {
    setParticipationEventsData((prevState) => {
      return prevState.concat(AIRDROP_PARTICIPANT_EVENT_FORMAT);
    });
  };

  return (
    <>
      <div className="airdrop-condition-event-list">
        {participationEventsData.map((el, index) => {
          return <AirdropConditionEventBox data={el} setData={setParticipationEventsData} index={index} key={`airdrop-condition-event-box-${index}`} />;
        })}

        <div className="airdrop-condition-event-list-add" onClick={() => addInput()}>
          <img className="airdrop-condition-event-list-add-icon" src={IconAddInput} alt="" />
        </div>
      </div>
    </>
  );
};

export default AirdropConditionEventList;
