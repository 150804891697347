import React, { useMemo, useState } from "react";
import "./NetworkNodeAllocField.scss";
import BasicButton from "view/components/_atom/button/BasicButton";
import { ButtonSize } from "view/components/_atom/button/StyledButton";
import ButtonContainer from "view/components/_atom/container/ButtonContainer";
import { ColumnField, FieldContainer } from "view/components/_atom/section/Field";
import keyIcon from "../../../../../assets/images/Icon_key.png";
import KeystoreFileSettingModal from "../atom/KeystoreFileSettingModal";
import allocationIcon from "../../../../../assets/images/Icon_allocation.png";
import deleteIcon from "../../../../../assets/images/atom/icon-FileDragAndDrop-delete.png";
import { addressSimplificationConvertor } from "utils/convert.utils";
import CreateNewKeyModal from "view/components/_modal/CreateNewKeyModal";

const NetworkBesuNodeAllocField = (props) => {
  const { networkData, setNetworkData } = props;

  const [openCreateKeyModal, setOpenCreateKeyModal] = useState<Boolean>(false);

  const colGroup = useMemo(() => [20, 45, 35].map((el) => ({ style: { width: `${el}%` } })), []);

  return (
    <>
      {openCreateKeyModal && <CreateNewKeyModal title={"Create a new key"} isOpen={openCreateKeyModal} closeModal={() => setOpenCreateKeyModal(false)} />}

      <FieldContainer
        icon={allocationIcon}
        title="Node Setting"
        description={`To operate the network, data is set for each node. The data set here may vary depending on the Blockchain Engine settings set in the previous step.
Keystore file is uploaded by each node to enter key information required to participate in consensus.
Staking amount or Validator sets conditions for nodes to participate in consensus.`}
      >
        <div className="node-allocation-button-wrapper">
          <ButtonContainer>
            <BasicButton size={ButtonSize.DYNAMIC} style={{ padding: "8px", width: "fit-content", fontSize: 14 }} onClick={() => setOpenCreateKeyModal(true)}>
              <span style={{ fontWeight: 500 }}>Create a new key</span>
              <img src={keyIcon} className="node-setting-distribute-icon" alt="" />
            </BasicButton>
          </ButtonContainer>
        </div>
        <ColumnField>
          <table className="node-allocation-table">
            <colgroup style={{ padding: "0 40px" }}>
              {colGroup.map((col, index) => (
                <col key={`col-${index}`} {...col} />
              ))}
            </colgroup>
            <thead>
              <tr>
                <th>
                  <span>Name</span>
                </th>
                <th>
                  <span>Keystore File</span>
                </th>
                <th>
                  <span>Validator</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {networkData.nodeAlloc?.length > 0 &&
                networkData.nodeAlloc.map((row, index: number) => (
                  <NodeAllocationTableRow
                    key={`node-allocation-table-row-${index}`}
                    row={row}
                    index={index}
                    networkData={networkData}
                    setNetworkData={setNetworkData}
                  />
                ))}
              {networkData.nodeAlloc?.length === 0 && (
                <tr className="table-no-data">
                  <td colSpan={colGroup.length}>{`Choose an option for node setup above`}</td>
                </tr>
              )}
            </tbody>
          </table>
        </ColumnField>
      </FieldContainer>
    </>
  );
};

export default NetworkBesuNodeAllocField;

const NodeAllocationTableRow = (props) => {
  const { row, index, setNetworkData } = props;

  const [openKeystoreFileSettingModal, setOpenKeystoreFileSettingModal] = useState<Boolean>(false);

  const resetKeystore = async () => {
    setNetworkData((prevState) => {
      const nodeAlloc = prevState.nodeAlloc.map((item, i: number) => {
        if (i !== index) {
          return item;
        } else {
          return { ...item, keyStoreFile: null, keyStore: null, address: null, encryptedKey: null, isChecked: false };
        }
      });

      return { ...prevState, nodeAlloc };
    });
  };

  return (
    <tr>
      {openKeystoreFileSettingModal && (
        <KeystoreFileSettingModal
          index={index}
          isOpen={openKeystoreFileSettingModal}
          closeModal={() => setOpenKeystoreFileSettingModal(false)}
          setNetworkData={setNetworkData}
        />
      )}
      <td>
        <div className="flex align-items-center justify-content-center ">
          {/* <BasicInput readOnly value={row["name"]} style={{ padding: "11px 10px", width: "100%", height: 40 }} /> */}
          {row["name"]}
        </div>
      </td>
      <td>
        <div className="flex align-items-center justify-content-center">
          {Boolean(row.keyStore) && (
            <div className="node-alloc-keystore-button">
              <div className="node-alloc-keystore-button-address">{addressSimplificationConvertor(`0x${row.keyStore.address}`)}</div>
              <img src={deleteIcon} className="node-alloc-keystore-button-delete" alt="" onClick={() => resetKeystore()} />
            </div>
          )}
          {!Boolean(row.keyStore) && (
            <div className="node-alloc-keystore-button" onClick={() => setOpenKeystoreFileSettingModal(true)}>
              <div className="node-alloc-keystore-button-label">Click to register</div>
            </div>
          )}
        </div>
      </td>
      <td>
        <div className="flex align-items-center justify-content-center">Validator</div>
      </td>
    </tr>
  );
};
