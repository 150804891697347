import { Block, DailySummary, Transaction, BlockExplorer } from "reducer/clientType/blockExplorerClientType";
import {
  registerBlockExplorerFromServer,
  disableBlockExplorerFromServer,
  getBlockExplorerDetailFromServer,
  getBlockExplorerListFromServer,
  getBlocksForwardFromServer,
  getDailySummaryFromServer,
  getTransactionsForwardFromServer,
  releaseBlockExplorerFromServer,
} from "../server/API/blockExplorerAPI";
import { WEB3 } from "../modules/web3/Web3";

export const regitserBlockExplorerParser = async (microChainId: number): Promise<BlockExplorer> => {
  const result = await registerBlockExplorerFromServer({
    data: {
      microChainId,
    },
  });

  const response = {
    microChainId: result.micro_chain_id,
    height: result.height,
    isPrivate: result.is_private,
    isExternal: result.is_external,
    externalUrl: result.external_url,
    externalTransactionUrl: result.external_transaction_url,
    mainStatus: result.main_status,
    subStatus: result.sub_status,
    projectId: result.project_id,
    creatorId: result.creator_id,
    networkId: result.network_id,
    networkLabel: result.network_label,
    microChainLabel: result.micro_chain_label,
  };

  return response;
};

export const getBlockExplorerListParser = async (options?: {
  projectId?: number;
  mainStatus?: Array<number>;
  isExternal?: number;
}): Promise<Array<BlockExplorer>> => {
  const result = await getBlockExplorerListFromServer({
    query: {
      ...(options?.projectId && { projectId: options.projectId }),
      ...(options?.mainStatus && { mainStatus: options.mainStatus }),
      ...(options?.isExternal && { isExternal: options.isExternal }),
    },
  });

  const response = result
    .map((el: any) => ({
      microChainId: el.micro_chain_id,
      height: el.height,
      isPrivate: el.is_private,
      isExternal: el.is_external,
      externalUrl: el.external_url,
      externalTransactionUrl: el.external_transaction_url,
      mainStatus: el.main_status,
      subStatus: el.sub_status,
      projectId: el.project_id,
      creatorId: el.creator_id,
      networkId: el.network_id,
      networkLabel: el.network_label,
      microChainLabel: el.micro_chain_label,
    }))
    .slice()
    .reverse();

  return response;
};

export const getBlockExplorerDetailParser = async (microChainId: number, options?: { mainStatus?: number; isExternal?: number }): Promise<any> => {
  const result = await getBlockExplorerDetailFromServer({
    params: {
      microChainId,
    },
    query: {
      ...(options?.mainStatus && { mainStatus: options.mainStatus }),
      ...(options?.isExternal && { isExternal: options.isExternal }),
    },
  });

  const response = {
    microChainId: result.micro_chain_id,
    height: result.height,
    isPrivate: result.is_private,
    isExternal: result.is_external,
    externalUrl: result.external_url,
    externalTransactionUrl: result.external_transaction_url,
    mainStatus: result.main_status,
    subStatus: result.sub_status,
    projectId: result.project_id,
    creatorId: result.creator_id,
    networkId: result.network_id,
    networkLabel: result.network_label,
    microChainLabel: result.micro_chain_label,
  };

  return response;
};

export const releaseBlockExplorerParser = async (microChainId: number): Promise<boolean> => {
  const result = await releaseBlockExplorerFromServer({
    params: {
      microChainId,
    },
  });

  return result.success;
};

export const disableBlockExplorerParser = async (microChainId: number): Promise<boolean> => {
  const result = await disableBlockExplorerFromServer({
    params: {
      microChainId,
    },
  });

  return result.success;
};

// ----------------------------------------------------------------------------------------
export const getBlocksForwardParser = async (microChainId: number, limit: number): Promise<Array<Block>> => {
  const result = await getBlocksForwardFromServer({
    query: {
      microChainId,
      limit,
    },
  });

  const { blocks } = result;

  const response = blocks?.map((block) => ({
    height: block.number,
    hash: block.hash,
    transactionCount: block.transaction_count,
    timestamp: block.timestamp,
  }));

  return response;
};

export const getTransactionsForwardParser = async (microChainId: number, limit: number): Promise<Array<Transaction>> => {
  const result = await getTransactionsForwardFromServer({
    query: {
      microChainId,
      limit,
    },
  });

  const { transactions } = result;

  const response = transactions?.map((tx) => ({
    transactionId: tx.transaction_id,
    hash: tx.hash,
    from: tx.from,
    to: tx.to,
    value: WEB3.fromWei(tx.value),
    timestamp: tx.timestamp,
  }));

  return response;
};

export const getDailySummaryParser = async (microChainId: number, endTime: number): Promise<Array<DailySummary>> => {
  const result = await getDailySummaryFromServer({
    query: {
      microChainId,
      endTime,
    },
  });

  const { summary } = result;

  const response = summary?.map((s) => ({
    startTime: s.start_time,
    endTime: s.end_time,
    blockCount: s.block_count,
    transactionCount: s.transaction_count,
    microChainId: s.micro_chain_id,
  }));

  return response.slice().reverse();
};
