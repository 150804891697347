import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { CONTRACT_ERC, CONTRACT_STATUS, CONTRACT_TYPE } from "../constants/contract.data";
import PageLoading from "../../../../components/_atom/loading/PageLoading";
import ContractConstructorViewer from "../organism/ContractConstructorViewer";
import ContractMethodViewer from "../organism/ContractMethodViewer";
import EQConnectWalletButton from "../../../../../modules/EQConnect/EQConnectWalletButton";
import { connectInfo } from "../../../../../store/redux/EQconnect/EQConnectSlice";
import StatusDetail, { DEFAULT_LABEL_STYLE } from "../../../../components/_atom/label/StatusDetail";
import toast from "react-hot-toast";
import { makeTransactionForUploadContractAction } from "../../../../../action/requestAction";
import ContractChainViewer from "../organism/ContractChainViewer";
import { project_access } from "reducer/projectReducer";
import { getListedContractDetailAction } from "../../../../../action/contractAction";
import PageArticle from "../../../../components/_template/page/PageArticle";
import PageTitle from "../../../../components/_molecules/page/PageTitle";
import ButtonContainer from "view/components/_atom/container/ButtonContainer";
import { isDisplayTransactionStatus, parseServiceStatus } from "../../../../../utils/status.utils";
import TransactionStatus from "../../../../components/_atom/transactionStatus/TransactionStatus";
import useTransaction from "../../../../../hooks/useTransaction";
import BasicButton from "../../../../components/_atom/button/BasicButton";
import { ButtonSize } from "../../../../components/_atom/button/StyledButton";
import { ManageFieldContainer, ManageFieldLabel, RowManageField } from "view/components/_atom/section/ManageField";
import contractIcon from "../../../../assets/images/service/Icon_SNB_contract_library.png";

const ManageListedContract = () => {
  const navigate = useNavigate();
  const { contractId } = useParams();
  const { address } = useSelector(connectInfo);
  const token = useSelector(project_access);

  const [isLoading, setIsLoading] = useState(true);

  const [contractDetail, setContractDetail] = useState({});

  const [contractConstructor, setContractConstructor] = useState({});
  const [contractFunctions, setContractFunctions] = useState([]);
  const [contractEvents, setContractEvents] = useState([]);

  const [contractStatus, setContractStatus] = useState(null);

  const setListedContractDetailFromServer = async () => {
    const { error, result } = await getListedContractDetailAction(Number(contractId));
    if (error) {
      toast.error("Invalid Contract");
      navigate("/developer/contract/list");
    } else {
      const { contractDetail, contractConstructor, contractFunctions, contractEvents } = result;
      const { mainStatus, subStatus } = contractDetail;
      const targetStatus = parseServiceStatus(CONTRACT_STATUS, mainStatus, subStatus);
      setContractStatus(targetStatus);
      setContractDetail(contractDetail);
      setContractConstructor(contractConstructor);
      setContractFunctions(contractFunctions);
      setContractEvents(contractEvents);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setListedContractDetailFromServer();
  }, []);

  const statusParser = useMemo(() => {
    switch (contractDetail?.mainStatus) {
      default:
        return contractDetail?.mainStatus;
    }
  }, [contractDetail]);

  const statusData = useMemo(() => {
    switch (contractDetail.mainStatus) {
      default:
        return {
          text: DEFAULT_LABEL_STYLE.find((el) => el.status === statusParser)?.text,
          description: DEFAULT_LABEL_STYLE.find((el) => el.status === statusParser)?.description,
        };
    }
  }, [contractDetail, statusParser]);

  const deployContract = async () => {
    return await makeTransactionForUploadContractAction({
      address: address,
      contractId: contractDetail.contractId,
      microChainId: contractDetail.microChainId,
      parameters: contractConstructor.inputs.map((constructorInput) => constructorInput?.value),
      to: null,
      value: null,
      transactionPath: `${process.env.REACT_APP_HUB_SERVER_URL}${process.env.REACT_APP_API_VERSION_V2}contracts/${contractId}/release-contract`,
      token,
    });
  };

  const { requestTransaction } = useTransaction(deployContract, setListedContractDetailFromServer);

  if (isLoading) {
    return <PageLoading />;
  }

  return (
    <PageArticle>
      <PageTitle title={"Contract Detail"} size={1080} />
      <div className="page-layout-1080">
        <StatusDetail status={statusParser} data={statusData} />
        {isDisplayTransactionStatus(CONTRACT_STATUS[contractStatus]) && (
          <TransactionStatus
            {...(contractDetail.transactionHash && { transactionHash: contractDetail.transactionHash })}
            statusDetail={CONTRACT_STATUS[contractStatus].statusDetail}
            microChainId={contractDetail.microChainId}
          />
        )}
        <ManageFieldContainer icon={contractIcon} title="Contract Info">
          <RowManageField>
            <ManageFieldLabel label="Contract title" />
            <span>{contractDetail.originContractTitle ?? "-"}</span>
          </RowManageField>
          <RowManageField>
            <ManageFieldLabel label="Contract ID" />
            <span>{contractDetail.contractId ?? "-"}</span>
          </RowManageField>
          <RowManageField>
            <ManageFieldLabel label="Contract name" />
            <span>{contractDetail.name ?? "-"}</span>
          </RowManageField>
          <RowManageField>
            <ManageFieldLabel label="Made by" />
            <span>{contractDetail.owner ?? "-"}</span>
          </RowManageField>
          <RowManageField>
            <ManageFieldLabel label="Contract type" />
            <span>{CONTRACT_TYPE.find((el) => el.value === contractDetail?.type)?.label ?? "-"}</span>
          </RowManageField>
          <RowManageField>
            <ManageFieldLabel label="ERC" />
            <span>{CONTRACT_ERC.find((el) => el.value === contractDetail?.ercType)?.label ?? "-"}</span>
          </RowManageField>
          <RowManageField>
            <ManageFieldLabel label="Description" />
            <span>{contractDetail?.originContractDescription ?? "-"}</span>
          </RowManageField>
        </ManageFieldContainer>
        <ManageFieldContainer icon={contractIcon} title="Version Info">
          <RowManageField>
            <ManageFieldLabel label="Contract version" />
            <span>{contractDetail?.originVersion ?? "-"}</span>
          </RowManageField>
          <RowManageField>
            <ManageFieldLabel label="Version description" />
            <span>{contractDetail?.originContractVersionDescription ?? "-"}</span>
          </RowManageField>
        </ManageFieldContainer>
        <ContractChainViewer contractDetail={contractDetail} />
        {contractDetail?.description && (
          <>
            <ManageFieldContainer icon={contractIcon} title="Memo">
              <span>{contractDetail?.description}</span>
            </ManageFieldContainer>
          </>
        )}
        {contractConstructor && contractConstructor?.inputs.length !== 0 && (
          <ContractConstructorViewer constructorState={{ contractConstructor, setContractConstructor }} disabled={true} valueKey={"value"} />
        )}
        {contractFunctions.length !== 0 && (
          <ContractMethodViewer label={"Functions"} methodState={{ methods: contractFunctions, setMethods: setContractFunctions }} disabled={true} />
        )}
        {contractEvents.length !== 0 && (
          <ContractMethodViewer label={"Events"} methodState={{ methods: contractEvents, setMethods: setContractEvents }} disabled={true} />
        )}
        {contractDetail.mainStatus === 1 && (
          <div className="flex justify-content-flex-end">
            <ButtonContainer>
              <EQConnectWalletButton />
              <BasicButton size={ButtonSize.LARGE} disabled={!address} onClick={() => requestTransaction()}>
                Deploy
              </BasicButton>
            </ButtonContainer>
          </div>
        )}
      </div>
    </PageArticle>
  );
};

export default ManageListedContract;
