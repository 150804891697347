import { Route, Routes } from "react-router-dom";
import React from "react";
import UserProfile from "./page/UserProfile";

const UserRouter = () => {
  return (
    <Routes>
      <Route path="profile" element={<UserProfile />} />
    </Routes>
  );
};
export default UserRouter;
