import './DashboardMethodRequestTemplate.scss';
import DivideLine from "view/components/_atom/line/DivideLine";
import Input from "../../../../legacy-common/atom/input/Input";
import DashboardMethodInputWrapper from "./DashboardMethodInputWrapper";
import BasicButton from "../../../components/_atom/button/BasicButton";
import {ButtonShape, ButtonSize} from "../../../components/_atom/button/StyledButton";

const DashboardMethodRequestTemplate = ({ selectedMethod, methodInputs, setMethodInputs, buttonText, onRequest, result, value, setValue }) => {
  return (
    <div className="dashboard-method-request-template">
      <div className="dashboard-method-request-template-content">
        <div className="dashboard-method-request-template-header">
          <div className="dashboard-method-request-template-header-name">{selectedMethod?.name ?? ""}</div>
          {selectedMethod?.description && <div className="dashboard-method-request-template-header-description">{selectedMethod?.description ?? ""}</div>}
        </div>
        <DivideLine />
        <div className="dashboard-method-request-template-inputs">
          {selectedMethod?.inputs.length === 0 ? (
            <>
              <div className="flex justify-content-center align-items-center" style={{ height: "100%", fontSize: 20, fontWeight: 500, color: "#848a96" }}>
                No requested parameters
              </div>
              <DivideLine style={{ marginTop: 20 }} />
            </>
          ) : (
            <>
              {selectedMethod?.inputs.map((input, index) => {
                return <DashboardMethodInputWrapper key={index} index={index} methodInputs={methodInputs} setMethodInputs={setMethodInputs} input={input} />;
              })}
              {selectedMethod.stateMutability === "payable" && (
                <div>
                  <div style={{ marginBottom: 15, fontWeight: 500, color: "#5888ff" }}>For Payable Transaction</div>
                  <div className="dashboard-method-input-wrapper">
                    <div className="dashboard-method-input-wrapper-name">value (uint256)</div>
                    <div className="dashboard-method-input-wrapper-description">
                      Enter the number of coins to be sent together in the Transaction request (with decimals)
                    </div>
                    <div className="dashboard-method-input-wrapper-input">
                      <Input value={value ?? ""} onChange={(e) => setValue(e.target.value)} placeholder={`Required Type: uint256`} />
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        <div className="flex justify-content-end" style={{ paddingTop: 20 }}>
          <BasicButton onClick={() => onRequest()} size={ButtonSize.SMALL} shape={ButtonShape.ROUNDED}>
            {buttonText}
          </BasicButton>
        </div>
      </div>
      <div className="dashboard-method-request-template-result">
        <div className="dashboard-method-request-template-result-title">Result</div>
        <div className="dashboard-method-request-template-result-data">{result}</div>
      </div>
    </div>
  );
};

export default DashboardMethodRequestTemplate;
