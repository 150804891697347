import {
  callFromServer,
  estimateGasFromServer,
  getTransactionFeeFromServer,
  getTransactionReceiptFromServer,
  getTransactionResultFromServer,
  requestBalanceFromServer,
} from "../server/API/requestAPI";
import { Transaction, TransactionPayload, TransactionQuery, TransactionReceipt, TransactionResult } from "../reducer/clientType/requestClientType";
import { getMicroChainDetailFromServer } from "../server/API/microChainAPI";
import {
  getContractAbiCodeByContractAddressFromServer,
  getContractAbiCodeByContractIdFromServer,
  getContractByteCodeByContractIdFromServer,
} from "../server/API/contractAPI";
import { makeQuery } from "../server/index/utils";
import { AbiItem } from "web3-utils";

// LEGACY
export const getTransactionReceiptParser = async (baseURL: string, transactionHash: string): Promise<TransactionReceipt> => {
  return await getTransactionReceiptFromServer({
    baseURL,
    params: {
      transactionHash,
    },
  });
};

export const getAbiCodeByContractAddressParser = async (microChainId: number, contractAddress: string): Promise<AbiItem[] | AbiItem> => {
  const result = await getContractAbiCodeByContractAddressFromServer({
    query: { microChainId },
    params: { contractAddress },
  });
  return result;
};

export const getAbiCodeByContractIdParser = async (contractId: number): Promise<AbiItem[] | AbiItem> => {
  return await getContractAbiCodeByContractIdFromServer({
    params: { contractId },
  });
};

export const getByteCodeByContractIdParser = async (contractId: number): Promise<string> => {
  const result = await getContractByteCodeByContractIdFromServer({
    params: { contractId },
  });
  return result.byte_code;
};

export const callParser = async (microChainId: number, queryObject: TransactionQuery): Promise<object> => {
  return await callFromServer({
    query: { microChainId },
    data: { ...queryObject },
  });
};

export const makeTransactionParser = ({
  address,
  microChainId,
  to,
  value,
  functionName,
  baseURL,
  transactionPath,
  noncePath,
  gasPath,
  gasPricePath,
  token,
  additionalQuery,
}: TransactionPayload): Transaction => {
  const nonceQuery: string = makeQuery({ microChainId, address });
  const transactionQuery: string = makeQuery({ microChainId });
  return {
    address,
    chainId: microChainId,
    to,
    data: null,
    value,
    functionName,
    loadBalancer: baseURL,
    transactionPath: transactionPath ?? `${process.env.REACT_APP_HUB_SERVER_URL}${process.env.REACT_APP_API_VERSION_V2}transactions/request${transactionQuery}`,
    ...(!noncePath && { noncePath: `${process.env.REACT_APP_HUB_SERVER_URL}${process.env.REACT_APP_API_VERSION_V2}request/nonce${nonceQuery}` }),
    ...(!gasPath && { gasPath: `${process.env.REACT_APP_HUB_SERVER_URL}${process.env.REACT_APP_API_VERSION_V2}request/estimate-gas` }),
    ...(!gasPricePath && {
      gasPricePath: `${process.env.REACT_APP_HUB_SERVER_URL}${process.env.REACT_APP_API_VERSION_V2}micro-chains/${microChainId}/gas-price`,
    }),
    ...(token && { token }),
    ...(additionalQuery && { additionalQuery }),
  };
};

export const estimateGasParser = async (
  microChainId: number,
  estimateGasPayload: {
    to?: string;
    value?: string;
    from?: string;
    data?: string;
    gasPrice?: string;
  }
) => {
  const { gas } = await estimateGasFromServer({
    query: { microChainId },
    data: {
      ...(estimateGasPayload.to && { to: estimateGasPayload.to }),
      ...(estimateGasPayload.value && { value: estimateGasPayload.value }),
      ...(estimateGasPayload.from && { from: estimateGasPayload.from }),
      ...(estimateGasPayload.data && { data: estimateGasPayload.data }),
      ...(estimateGasPayload.gasPrice && { gasPrice: estimateGasPayload.gasPrice }),
    },
  });
  return gas;
};

export const getTransactionResultParser = async (networkId: number, microChainId: number, transactionHash: string): Promise<Array<TransactionResult>> => {
  const result = await getTransactionResultFromServer({
    query: {
      networkId,
      microChainId,
      transactionHash,
    },
  });

  const response = result.map((el) => ({
    blockConfirmationCount: el.block_confirmation_count,
    blockHeight: el.block_height,
    createdAt: el.created_at,
    execBlockHeight: el.exec_block_height,
    isReorg: el.is_reorg,
    microChainId: el.micro_chain_id,
    pastStatus: el.past_status,
    projectId: el.project_id,
    receipt: el.receipt,
    responseType: el.response_type,
    status: el.status,
    transactionHash: el.transaction_hash,
    transactionId: el.transaction_id,
    updatedAt: el.updated_at,
  }));

  return response;
};

// ----------------------------------------------------------------------------------

// TODO :: 삭제 예정 (gateway만 사용)
export const getTransactionFeeParser = async (baseURL: string, microChainId: number): Promise<string> => {
  const result = await getTransactionFeeFromServer({
    baseURL,
    params: { microChainId },
  });
  return result.fee;
};

// TODO :: 삭제 예정 (gateway만 사용)
export const getCoinBalanceParser = async (baseURL: string, microChainId: number, address: string): Promise<string> => {
  const result = await requestBalanceFromServer({
    baseURL,
    query: {
      microChainId,
      address,
    },
  });
  return result.balance;
};

export const getLoadBalancerParser = async (microChainId: number, options?: { paranoid: string }): Promise<string> => {
  const { micro_chain_detail } = await getMicroChainDetailFromServer({
    params: {
      microChainId,
    },
    query: {
      ...(options?.paranoid && { paranoid: JSON.parse(options.paranoid) }),
    },
  });
  return micro_chain_detail.micro_chain.load_balancer;
};
