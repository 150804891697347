import { useEffect, useState } from "react";
import "./RegisterBlockExplorer.scss";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "../../../../components/_atom/select/Select";
import PageLoading from "../../../../components/_atom/loading/PageLoading";
import { errorAlert } from "../../../../../utils/Utils";
import { getInitialDataFromRegisterBEAction, registerBlockExplorerAction } from "../../../../../action/blockExplorerAction";
import { selected_project_id } from "reducer/projectReducer";
import PageTitle from "view/components/_molecules/page/PageTitle";
import PageArticle from "view/components/_template/page/PageArticle";
import BasicButton from "view/components/_atom/button/BasicButton";
import useLoading from "hooks/useLoading";
import useIsAble from "hooks/useIsAble";
import { FieldContainer, FieldLabel, RowField } from "view/components/_atom/section/Field";
import blockExplorerIcon from "../../../../assets/images/service/Icon_SNB_block_explorer.png";

const RegisterBlockExplorer = function () {
  const navigate = useNavigate();

  const projectId = useSelector(selected_project_id);

  const [allNetworkList, setAllNetworkList] = useState([]);
  const [allMicroChainList, setAllMicroChainList] = useState([]);
  const [availableMicroChainList, setAvailableMicroChainList] = useState([]);
  const [selectedNetworkId, setSelectedNetworkId] = useState(null);
  const [selectedMicroChainId, setSelectedMicroChainId] = useState(null);

  const setInitialDataForRegisterBE = async () => {
    const { result, error } = await getInitialDataFromRegisterBEAction(projectId);

    if (result) {
      const { networkList, microChainList } = result || {};
      setAllNetworkList(networkList);
      setAllMicroChainList(microChainList);
    }
    if (error) {
      errorAlert(error.data.message);
    }
  };

  const registerBlockExplorer_ = async () => {
    const { result, error } = await registerBlockExplorerAction(selectedMicroChainId);

    if (result) {
      toast.success("processed successfully");
      navigate(`/tool/block-explorer/manage/${result.microChainId}`);
    }
    if (error) {
      errorAlert(error.data.message);
    }
  };

  useEffect(() => {
    if (selectedNetworkId) {
      const availableMicroChainList = allMicroChainList.filter((el) => el.networkId === selectedNetworkId);
      if (availableMicroChainList.length === 0) {
        setSelectedMicroChainId(null);
      } else {
        setSelectedMicroChainId(availableMicroChainList.find((chain) => Boolean(chain.isMain)).id ?? null);
      }
      setAvailableMicroChainList(availableMicroChainList);
    }
  }, [selectedNetworkId]);

  const isAble = useIsAble([Boolean(selectedMicroChainId)]);

  const { loading } = useLoading({
    dependency: [projectId],
    synchronousFunction: async () => {
      await setInitialDataForRegisterBE();
    },
  });

  if (loading) {
    return <PageLoading />;
  }

  return (
    <PageArticle>
      <PageTitle size={810} title={"Register in Block Explorer"} />
      <div className="page-layout-810">
        <FieldContainer icon={blockExplorerIcon} title="Basic Info">
          <RowField>
            <FieldLabel label="Network / Microchain" />

            <div className="register-block-explorer-chain-selector-container">
              <Select
                labelKey={"label"}
                valueKey={"id"}
                options={allNetworkList}
                selectedValue={selectedNetworkId}
                setSelectedValue={setSelectedNetworkId}
                canSelect={allNetworkList.length !== 0}
                placeholder={"Select Network"}
              />
              <Select
                labelKey={"label"}
                valueKey={"id"}
                options={availableMicroChainList}
                selectedValue={selectedMicroChainId}
                setSelectedValue={setSelectedMicroChainId}
                canSelect={selectedNetworkId && availableMicroChainList.length !== 0}
                placeholder={"Select Microchain"}
              />
            </div>
          </RowField>
        </FieldContainer>
        <div className="register-block-explorer-button-container">
          <BasicButton disabled={!isAble} onClick={() => registerBlockExplorer_()}>
            Register
          </BasicButton>
        </div>
      </div>
    </PageArticle>
  );
};

export default RegisterBlockExplorer;
