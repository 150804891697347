import React, { ButtonHTMLAttributes, forwardRef } from "react";
import "./Button.scss";

type ButtonProps = {
  img?: string;
  text: any;
};

const Button = forwardRef<HTMLButtonElement, ButtonProps & ButtonHTMLAttributes<HTMLButtonElement>>((props, ref) => {
  const { className, img, text, ...rest } = props;
  return (
    <button ref={ref} className={`atom-button ${className && className}`} {...rest}>
      {img && <img src={img} alt="" className="button-image" />}
      {text}
    </button>
  );
});

export default Button;
