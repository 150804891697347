import { useEffect, useMemo, useState } from "react";
import MethodRequestTemplate from "../template/MethodRequestTemplate";
import { requestTransactionToExtension } from "../../../../../modules/EQExtension/utils/messageUtils";
import { useDispatch, useSelector } from "react-redux";
import { connectInfo } from "../../../../../store/redux/EQconnect/EQConnectSlice";
import { setRawTransactionResult, transaction_result } from "../../../../../store/redux/transaction/transactionSlice";
import ButtonLoading from "../../../../components/_atom/loading/ButtonLoading";
import { WEB3 } from "../../../../../modules/web3/Web3";
import { getTransactionResultAction, makeTransactionAction } from "../../../../../action/requestAction";
import { errorAlert } from "../../../../../utils/Utils";

const TX_STATUS = [
  {
    status: null,
    label: "Pending",
  },
  {
    status: 0,
    label: "Failed",
  },
  {
    status: 1,
    label: "Success",
  },
  {
    status: 2,
    label: "Pending",
  },
];

const TRANSACTION_STATUS_PENDING = 2;

const RequestTransaction = ({ selectedMethod, selectedMicroChain, selectedContract }) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState("");
  const [transactionMethodInputs, setTransactionMethodInputs] = useState([]);
  const [transactionResult, setTransactionResult] = useState(null);
  const [isPolling, setIsPolling] = useState(false);
  const [receipt, setReceipt] = useState(null);
  const { address } = useSelector(connectInfo);
  const { rawTransactionResult } = useSelector(transaction_result);

  const transactionResultParser = useMemo(() => {
    return Boolean(transactionResult?.tx_hash) ? (
      isPolling ? (
        "Pending..."
      ) : receipt ? (
        <div className="flex-column">
          <div style={{ marginBottom: 5 }}>{`Status: ${TX_STATUS.find((el) => el.status === receipt.status)?.label ?? "-"}`}</div>
          <div>{`Receipt tx hash: ${receipt.data.transactionHash}`}</div>
        </div>
      ) : null
    ) : null;
  }, [isPolling, receipt, transactionResult]);

  const onRequestTransaction = async () => {
    const parameters = transactionMethodInputs.map((el) => {
      if (el.type.includes("[]")) {
        return el.value.replace(/ /g, "").split(",");
      } else {
        return el.value;
      }
    });
    const { error, result } = await makeTransactionAction({
      address,
      microChainId: selectedMicroChain.id,
      contractAddress: selectedContract.contractAddress,
      parameters,
      to: selectedContract.contractAddress,
      value: selectedMethod.stateMutability === "payable" ? (Boolean(value) ? WEB3.toHex(value.toString()) : "0x0") : null,
      functionName: selectedMethod.name,
    });
    if (result) {
      requestTransactionToExtension({
        ...result,
      });
    }
    if (error) {
      switch (error.type) {
        case "API":
          errorAlert(error.data.message);
          break;
        case "Module":
          errorAlert(`${error.data.argument}::${error.data.code}`);
          break;
        default:
          errorAlert(error.data.message);
      }
    }
  };

  useEffect(() => {
    if (selectedMethod) {
      const params = selectedMethod.inputs.map((input) => {
        if (input.type === "bool") {
          return {
            type: input.type,
            value: null,
          };
        } else {
          return {
            type: input.type,
            value: "",
          };
        }
      });
      setTransactionMethodInputs(params);
    }

    return () => setTransactionMethodInputs([]);
  }, [selectedMethod]);

  useEffect(() => {
    if (Boolean(rawTransactionResult?.tx_hash)) {
      setReceipt(null);
      setTransactionResult(rawTransactionResult);
    }

    return () => {
      dispatch(
        setRawTransactionResult({
          tx_hash: "",
        })
      );
    };
  }, [rawTransactionResult]);

  useEffect(() => {
    if (!receipt && Boolean(transactionResult?.tx_hash)) {
      setIsPolling(true);
    }
  }, [transactionResult, receipt]);

  useEffect(() => {
    let transactionPolling;
    if (isPolling) {
      transactionPolling = setInterval(async () => {
        const { result, error } = await getTransactionResultAction(selectedMicroChain.networkId, selectedMicroChain.id, transactionResult.tx_hash);
        if (result) {
          const { status, ...data } = result[0] || {};
          setReceipt({
            status,
            data: data,
          });
          if (status === TRANSACTION_STATUS_PENDING || !Boolean(status)) {
            return;
          }
          setIsPolling(false);
        }

        if (error) {
          setReceipt({
            status: false,
            data: "Get receipt failed",
          });
          setIsPolling(false);
        }
      }, 2000);
    }
    return () => clearInterval(transactionPolling);
  }, [isPolling, transactionResult, selectedMethod]);

  useEffect(() => {
    return () => {
      setTransactionResult(null);
      setIsPolling(false);
      setReceipt(null);
    };
  }, []);

  useEffect(() => {
    setTransactionResult(null);
    setIsPolling(false);
    setReceipt(null);
  }, [selectedMethod]);

  return (
    <MethodRequestTemplate
      selectedMethod={selectedMethod}
      methodInputs={transactionMethodInputs}
      setMethodInputs={setTransactionMethodInputs}
      onRequest={() => (isPolling ? undefined : onRequestTransaction())}
      buttonText={isPolling ? <ButtonLoading /> : "Send"}
      result={transactionResultParser}
      value={value}
      setValue={setValue}
    />
  );
};

export default RequestTransaction;
