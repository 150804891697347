import { useState } from "react";
import deleteIcon from "../../../../assets/images/icon-common-delete.png";
import addIcon from "../../../../assets/images/icon-common-add.png";
import "./ParameterHandler.scss";
import { arrayHandler, isArrayParameter } from "../utils/contractUtils";
import Button from "../../../../../legacy-common/atom/button/Button";
import BasicInput from "view/components/_atom/input/BasicInput";

const ParameterHandler = ({ setParameters, parameters, input, index }) => {
  return isArrayParameter(input.type) ? (
    <ArrayParameterInput setParameters={setParameters} parameters={parameters} index={index} />
  ) : (
    <StringParameterInput setParameters={setParameters} parameters={parameters} index={index} />
  );
};

export default ParameterHandler;

const ArrayParameterInput = (props) => {
  const { setParameters, parameters, index } = props || {};

  const [parameterArr, setParameterArr] = useState(parameters[index]);

  return (
    <div className="array-parameter-input-container" style={{ width: "100%", flex: 1 }}>
      {parameterArr.map((el, idx) => {
        return (
          <div className="array-parameter-input-row" key={idx}>
            <BasicInput
              value={parameters[index][idx] ?? ""}
              onChange={(e) =>
                setParameters((prev) => {
                  let arrParameter = arrayHandler(parameterArr, idx, e.target.value);
                  setParameterArr(arrParameter);
                  return arrayHandler(prev, index, arrParameter);
                })
              }
              placeholder={"Enter the correct value"}
            />
            <img
              className="add-parameter-icon"
              src={deleteIcon}
              alt=""
              onClick={() => {
                const deletedArr = parameterArr.filter((el, i) => i !== idx);
                setParameterArr(deletedArr);
                setParameters((prev) => {
                  return arrayHandler(prev, index, deletedArr);
                });
              }}
            />
          </div>
        );
      })}
      <div className="flex justify-content-center">
        <Button
          className="addition"
          text={"Add a value"}
          img={addIcon}
          onClick={() => {
            setParameterArr((prevState) => prevState.concat(""));
            setParameters((prev) => arrayHandler(prev, index, prev[index].concat("")));
          }}
        />
      </div>
    </div>
  );
};

const StringParameterInput = ({ setParameters, parameters, index }) => {
  return (
    <BasicInput
      value={parameters[index] ?? ""}
      onChange={(e) =>
        setParameters((prev) => {
          return arrayHandler(prev, index, e.target.value);
        })
      }
      placeholder={"Enter the correct value"}
    />
  );
};
