import {ServiceStatus, statusType} from "../../../../../utils/status.utils";

export const PAYMENT_GATEWAY_TYPE_LIST = [
  {
    id                : 1,
    value             : "account",
    label             : "Virtual Account",
    description       : `Build a payment system that issues a virtual account for each end-user by using the virtual account method used in the Web 2 payment system.`,
    availableAssetList: ["For Coins"],
  },
  {
    id                : 2,
    value             : "contract",
    label             : "Smart Contract",
    description       : `Build a simple and reliable payment\nsystem using Smart Contract.`,
    availableAssetList: ["For Coins", "For Tokens"],
  },
];

export const PAYMENT_GATEWAY_DATA_FORMAT = {
  name                  : "",
  microChainId          : null,
  microChainCurrencyId  : null,
  paymentTypeIds        : [],
  blockConfirmationCount: 0,
  walletAddress         : "",
  depositCallbackURI    : "",
  withdrawalCallbackURI : "",
};

export const VIRTUAL_ACCOUNT_PAYMENT_GATEWAY_DATA_FORMAT = {
  name                 : "",
  microChainId         : null,
  microChainCurrencyId : null,
  paymentTypeIds       : [],
  walletAddress        : "",
  depositCallbackURI   : "",
  withdrawalCallbackURI: "",
};

export const SMART_CONTRACT_PAYMENT_GATEWAY_DATA_FORMAT = {
  name                  : "",
  microChainId          : null,
  microChainCurrencyId  : null,
  paymentTypeIds        : [],
  blockConfirmationCount: "",
  walletAddress         : "",
  depositCallbackURI    : "",
  withdrawalCallbackURI : "",
};

export const COIN_CA = "0x0000000000000000000000000000000000000001";

export const MANAGE_PAYMENT_GATEWAY_TAB = [
  {
    id   : 0,
    label: "Info",
  },
  {
    id   : 1,
    label: "Deposit",
  },
  {
    id   : 2,
    label: "Withdrawal",
  },
];

export const WITHDRAWAL_STATUS = [
  {
    value: "wait",
    label: "Wait",
  },
  {
    value: "success",
    label: "Success",
  },
  {
    value: "fail",
    label: "Fail",
  },
];

export const VIRTUAL_ACCOUNT_DEPOSIT_STATUS = [
  {
    value: "success",
    label: "Complete",
  },
  {
    value: "unconfirmed",
    label: "Not confirmed",
  },
  {
    value: "unchecked",
    label: "Not Checked",
  },
];

export const VIRTUAL_ACCOUNT_TRANSFER_STATUS = [
  {
    value: "success",
    label: "Success",
  },
  {
    value: "unsent",
    label: "Unsent",
  },
  {
    value: "fail",
    label: "Fail",
  },
];

export const SMART_CONTRACT_DEPOSIT_STATUS = [
  {
    // 0
    value: "ready",
    label: "Ready",
  },
  {
    // 1
    value: "processing",
    label: "In progress",
  },
  {
    // 2
    value: "failed",
    label: "Failed",
  },
  {
    // [null, 0, 1]
    value: "aborted",
    label: "Aborted",
  },
  {
    // 3
    value: "success",
    label: "Success",
  },
];

export const DEPOSIT_LIMIT = 10;
export const WITHDRAWAL_LIMIT = 10;

export const PAYMENT_GATEWAY_STATUS_CREATED = 1;
export const PAYMENT_GATEWAY_STATUS_PREPARED = 3;
export const PAYMENT_GATEWAY_STATUS_IN_PROGRESS = 4;
export const PAYMENT_GATEWAY_STATUS_DEPLOYED = 5;

export const PAYMENT_GATEWAY_TYPE_SMART_CONTRACT = 3;

export const DEPOSIT_QUERY_DATA_FORMAT = {
  createdStartDate: "",
  createdEndDate  : "",
  address         : "",
  status          : null,
};

export const WITHDRAWAL_QUERY_DATA_FORMAT = {
  withdrawalStartDate: "",
  withdrawalEndDate  : "",
  withdrawalStatus   : null,
};

export const PAYMENT_GATEWAY_STATUS: Record<string, ServiceStatus> = {
  PAYMENT_GATEWAY_CREATED          : {
    mainStatus: 1,
    subStatus : 0
  },
  PAYMENT_GATEWAY_PREPARED         : {
    mainStatus: 3,
    subStatus : 0,
  },
  PAYMENT_GATEWAY_DEPLOY_PROCESSING: {
    mainStatus  : 4,
    subStatus   : 0,
    statusDetail: {
      type               : statusType.WAIT,
      title              : 'Smart contract is being deployed.',
      showTransactionHash: true
    }
  },
  PAYMENT_GATEWAY_DEPLOYED         : {
    mainStatus: 5,
    subStatus : 0,
  }
}

