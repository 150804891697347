import React, { useEffect, useState } from "react";
import "./NetworkEngineField.scss";
import { ColumnField, FieldContainer, FieldLabel } from "view/components/_atom/section/Field";
import RadioButton from "legacy-common/atom/button/RadioButton";
import DivideLine from "view/components/_atom/line/DivideLine";
import { getAlgorithmChainClientVersionListAction } from "action/networkAction";
import { NETWORK_MICRO_CHAIN_SETTING_INITIAL_FORMAT } from "../../constants/networkConstants";
import { networkTypeConverter } from "../../constants/network.utils";
import networkIcon from "view/assets/images/service/Icon_SNB_network.png";
import BlockchainEngineInfo from "../atom/BlockchainEngineInfo";
import { NETWORK_ANNOUNCEMENT_BLOCKCHAIN_CLIENT, NETWORK_ANNOUNCEMENT_CONSENSUS_ALGORITHM } from "../../constants/network.data";

type NetworkEngineFieldProps = {
  networkData: any;
  setNetworkData: React.Dispatch<React.SetStateAction<any>>;
  setNetworkType: React.Dispatch<React.SetStateAction<any>>;
  blockchainEngineList: Array<any>;
};

const NetworkEngineField = (props: NetworkEngineFieldProps) => {
  const { networkData, setNetworkData, setNetworkType, blockchainEngineList } = props;

  const [versionList, setVersionList] = useState<Array<any>>([]);
  const [algorithmList, setAlgorithmList] = useState<Array<any>>([]);

  const setAlgorithmId = (algorithmId: number) => {
    setNetworkData((prevState) => ({
      ...prevState,
      microChain: {
        ...prevState.microChain,
        algorithmId,
      },
    }));
  };

  // TODO :: create, edit 페이지 진입 시 초기 세팅
  const getVersionAndAlgorithm_ = async (chainClientId: number) => {
    const { result, error } = await getAlgorithmChainClientVersionListAction(chainClientId);

    if (result) {
      const { versionList, algorithmList } = result;
      setVersionList(versionList);
      setAlgorithmList(algorithmList);
      setNetworkData((prevState) => ({
        ...prevState,
        microChain: {
          ...prevState.microChain,
          chainClientVersionId: versionList[0]?.chainClientVersionId,
        },
      }));
      return result;
    }

    if (error) {
    }
  };

  const initializeNetworkData = () => {
    setNetworkData((prevState) => ({
      ...prevState,
      microChainSetting: {
        ...NETWORK_MICRO_CHAIN_SETTING_INITIAL_FORMAT,
        initialSupply: prevState.microChainSetting.initialSupply,
        isTestnet: prevState.microChainSetting.isTestnet,
        isContractUploadAvailability: prevState.microChainSetting.isContractUploadAvailability,
        isNodeParticipationAvailability: prevState.microChainSetting.isNodeParticipationAvailability,
      },
      nodeAlloc: [],
      preAlloc: [],
      network: {
        ...prevState.network,
        networkPlanId: null,
      },
    }));
  };

  // TODO :: 변경
  const chainClientClickHandler = async (item) => {
    if (item.chainClient?.isPrivate) {
      return;
    }

    setNetworkData((prevState) => ({
      ...prevState,
      microChain: {
        ...prevState.microChain,
        protocolId: item.protocolId,
        chainClientId: item.chainClientId,
        algorithmId: null,
      },
    }));
    await getVersionAndAlgorithm_(item.chainClientId);

    initializeNetworkData();
  };

  useEffect(() => {
    if (networkData.microChain.chainClientId) {
      getVersionAndAlgorithm_(networkData.microChain.chainClientId);
    }
  }, [networkData.microChain.chainClientId]);

  useEffect(() => {
    if (networkData.microChain.algorithmId) {
      const { microChain } = networkData;
      const { protocolId, chainClientVersionId, algorithmId } = microChain;
      const networkType = networkTypeConverter(protocolId, chainClientVersionId, algorithmId);
      setNetworkType(networkType);
    }
  }, [networkData.microChain.algorithmId]);

  return (
    <>
      <FieldContainer icon={networkIcon} title="Blockchain Engine">
        <ColumnField>
          <FieldLabel label={"Protocol & Blockchain Client"} announcement={NETWORK_ANNOUNCEMENT_BLOCKCHAIN_CLIENT} />
          <div className="network-chain-client-selector">
            {blockchainEngineList.map((data) => (
              <BlockchainEngineInfo
                key={`blockchain-client-item-${data.chainClientId}`}
                engineInfo={data}
                networkData={networkData}
                setSelectedEngine={chainClientClickHandler}
                canSelect={true}
              />
            ))}
          </div>
        </ColumnField>
        <DivideLine style={{ margin: "30px 0" }} />
        <ColumnField>
          <FieldLabel label={"Software"} />
          <div className="network-engine-version">
            {versionList.length === 0 && <span>-</span>}
            {versionList.length > 0 && <span>{versionList[0]?.version ? `${versionList[0].version} (Stable)` : "-"}</span>}
          </div>
        </ColumnField>
        <DivideLine style={{ margin: "30px 0" }} />
        <ColumnField>
          <FieldLabel label={"Consensus Algorithm"} announcement={NETWORK_ANNOUNCEMENT_CONSENSUS_ALGORITHM} />
          <div className="network-chain-consensus-algorithm-wrapper">
            {algorithmList.length === 0 && <span>-</span>}
            {algorithmList.length > 0 &&
              algorithmList.map((algorithm) => (
                <RadioButton
                  key={`algorithm-${algorithm.algorithmId}`}
                  id={algorithm.algorithmId}
                  onChange={(e) => setAlgorithmId(Number(e.target.value))}
                  value={algorithm.algorithmId}
                  isSelected={networkData?.microChain?.algorithmId === algorithm.algorithmId}
                  label={algorithm?.algorithm.name ?? "-"}
                  labelStyle={{ padding: "0 0 0 25px", width: 130 }}
                />
              ))}
          </div>
        </ColumnField>
      </FieldContainer>
    </>
  );
};

export default NetworkEngineField;
