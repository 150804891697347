import Select from "../../../../components/_atom/select/Select";
import { FieldContainer, FieldLabel, RowField } from "view/components/_atom/section/Field";
import contractIcon from "../../../../assets/images/service/Icon_SNB_contract_library.png";
import { useState } from "react";
import QuestionMark, { QuestionMarkTheme } from "view/components/_atom/tooltip/QuestionMark";

const GetContractCommonField = (props) => {
  const {
    allNetworks,
    availableMicroChains,
    selectedNetworkState: { selectedNetwork, setSelectedNetwork },
    selectedMicroChainState: { selectedMicroChain, setSelectedMicroChain },
    contractNameState: { contractName, setContractName },
    memoState: { memo, setMemo },
  } = props || {};

  const [isInputLimit, setIsInputLimit] = useState(false);
  const [isTextareaLimit, setIsTextareaLimit] = useState(false);

  const contractNameMaxLength = 100;
  const onChangeInput = (e) => {
    if(e.target.value.length === 100){
      setIsInputLimit(true);
      setContractName(e.target.value);
      return
    }
    if (e.target.value.length > 100) {
      setIsInputLimit(true);
      return;
    } else {
      if (isInputLimit) {
        setIsInputLimit(false);
      }
      setContractName(e.target.value);
    }
  };

  const onChangeTextarea = (e) => {
    if (e.target.value.length === 1000) {
      setIsTextareaLimit(true);
      setMemo(e.target.value);
      return;
    } else if (e.target.value.length > 1000) {
      setMemo(e.target.value);
      return;
    } else {
      if (isTextareaLimit) {
        setIsTextareaLimit(false);
      }
      setMemo(e.target.value);
    }
  };

  return (
    <>
      {/* Basic Info Description :: Enter basic information about the contract you want to get. */}
      <FieldContainer icon={contractIcon} title="Basic Info">
        <FieldLabel label="Network / Microchain" essential={false} />
        <div className={"flex"} style={{ width: "100%", marginBottom: "40px" }}>
          <Select
            labelKey={"label"}
            valueKey={"id"}
            options={allNetworks}
            selectedValue={selectedNetwork}
            setSelectedValue={setSelectedNetwork}
            canSelect={allNetworks.length !== 0}
            placeholder={"Select Network"}
          />
          <div style={{ width: 10 }} />
          <Select
            labelKey={"label"}
            valueKey={"id"}
            options={availableMicroChains}
            selectedValue={selectedMicroChain}
            setSelectedValue={setSelectedMicroChain}
            canSelect={selectedNetwork}
            placeholder={"Select MicroChain"}
          />
        </div>
        <div style={{ marginBottom: "40px" }}>
          <FieldLabel label="Contract name" essential={false} />
          <input
            className={isInputLimit ? "basic-input default limit" : "basic-input default"}
            value={contractName}
            placeholder={"Enter Contract name"}
            maxLength={100}
            onChange={(e) => {
              if(e.target.value.length > 100) {
                return
              }
              onChangeInput(e)
            }}
          />
          {isInputLimit && <div className="limit-text">Contract name must be up to 100 characters long</div>}
        </div>
        <FieldLabel label="Memo" essential={false} />
        <textarea
          className={isTextareaLimit ? "basic-textarea limit" : "basic-textarea"}
          value={memo}
          maxLength={1000}
          onChange={(e) => {
            if(e.target.value.length > 1000)
            {
              return
            }
            onChangeTextarea(e)
          }}
          placeholder={"Enter the description of Contract."}
          rows={5}
        />
        {isTextareaLimit && <div className="limit-text">Contract memo must be up to 1,000 characters long</div>}
      </FieldContainer>
    </>
  );
};

export default GetContractCommonField;

