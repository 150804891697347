import React, { useCallback, useState } from "react";
import "./ProjectMember.scss";
import memberIcon from "../assets/icon_dashboard_member_setting.png";
import membersIcon from "../assets/icon_dashboard_member_icon.png";
import inviteIcon from "../assets/Icon_project_invite.png";
import inviteWhiteIcon from "../assets/Icon_project_invite_white.png";
import iconDeleteRed from "../../../assets/images/atom/icon_delete_red.png";
import InviteMemberModal from "../components/InviteMemberModal";
import { ProjectInvitation, ProjectMember as ProjectMemberType } from "reducer/clientType/projectClientType";
import { User } from "reducer/clientType/userClientType";
import { useSelector } from "react-redux";
import { user_info } from "reducer/userReducer";
import { deleteInvitationAction, getProjectMemberInfoAction } from "../../../../action/projectAction";
import { errorAlert } from "utils/Utils";
import { toast } from "react-hot-toast";
import { selectAllProjectRoles } from "reducer/presetReducer";
import useGetSelectedProject from "../../../../hooks/useGetSelectedProject";
import PageLoading from "view/components/_atom/loading/PageLoading";
import useLoading from "hooks/useLoading";
import SectionContainer from "view/components/_atom/section/SectionContainer";
import RemoveMemberModal from "../components/RemoveMemberModal";
import PageHeader from "view/components/_molecules/page/PageHeader";
import EmptyDataTemplate from "view/service/dashboard/components/EmptyDataTemplate";
import moment from "moment";

const ProjectMember = () => {
  const userInfo = useSelector(user_info);
  const { isOwner, selectedProjectID } = useGetSelectedProject();
  const projectRoleList = useSelector(selectAllProjectRoles);

  const [memberList, setMemberList] = useState<Array<ProjectMemberType & User>>([]);
  const [invitationList, setInvitationList] = useState<Array<ProjectInvitation>>([]);
  const [selectedMemberDetail, setSelectedMemberDetail] = useState<ProjectMemberType & User>();

  const [inviteMemberModalOpen, setInviteMemberModalOpen] = useState<boolean>(false);
  const [removeMemberModalOpen, setRemoveMemberModalOpen] = useState<boolean>(false);

  const getSettingMemberInfo_ = async () => {
    const { result, error } = await getProjectMemberInfoAction();

    if (result) {
      const { memberList, invitationList } = result;
      setMemberList(memberList);
      setInvitationList(invitationList);
    }

    if (error) {
      errorAlert(error.data.messagea);
    }
  };

  const belongToMemberListAccessor = ["member", "email", "role", "remove"];
  const belongToMemberListRenderer = useCallback(
    (data: any) => ({
      member: <MemberInformation id={data.id} name={`${data.firstName} ${data.lastName}`} image={data.image} />,
      email: data.email,
      role: projectRoleList.find((el) => el.projectRoleId === data.projectRoleId)?.name ?? "-",
      remove: memberListRemoveButtonRenderer(data),
    }),
    []
  );

  const invitedListAccessor = ["email", "createdAt", "expireTime", "cancel"];
  const invitedListRenderer = useCallback(
    (data: any) => ({
      email: data.email,
      createdAt: `${moment(data.createdAt).format("MMMM DD, YYYY HH:mm")}`,
      expireTime: `${moment(data.expireTime).format("MMMM DD, YYYY HH:mm")}`,
      cancel: invitedListCancelButtonRenderer(data),
    }),
    []
  );

  const memberListRemoveButtonRenderer = (data: any) => {
    const { id } = data;
    const memberRemoveButtonHandler = (data: any) => {
      setSelectedMemberDetail(data);
      setRemoveMemberModalOpen(true);
    };
    return (
      <>
        {isOwner && userInfo.id !== id ? (
          <div className="member-list-remove-button-wrapper" onClick={() => memberRemoveButtonHandler(data)}>
            <img src={iconDeleteRed} style={{ width: 14, objectFit: "contain" }} alt={""} />
          </div>
        ) : (
          <div style={{ height: 32 }} />
        )}
      </>
    );
  };

  const invitedListCancelButtonRenderer = (data: any) => {
    const { id } = data;

    const deleteInvitation_ = async (id: number) => {
      const { result, error } = await deleteInvitationAction(id);

      if (result) {
        setInvitationList(result);
        toast.success("member invitation has been canceled");
      }

      if (error) {
        errorAlert(error.data.message);
      }
    };

    const cancelButtonHandler = () => {
      if (window.confirm("Are you sure you want to cancel the invitation?")) {
        deleteInvitation_(id);
      }
    };

    return (
      <>
        {isOwner ? (
          <div className="invited-list-cancel-button-wrapper" onClick={() => cancelButtonHandler()}>
            Cancel
          </div>
        ) : (
          <div style={{ height: 32 }} />
        )}
      </>
    );
  };

  const { loading } = useLoading({
    dependency: [selectedProjectID],
    synchronousFunction: async () => {
      await getSettingMemberInfo_();
    },
  });

  if (loading) {
    return <PageLoading style={{ height: "calc(100vh - 60px - 112px - 141px - 116px - 100px)" }} />;
  }
  const ProjectMemberHeader = () => {
    return (
      <div className="project-member-header">
        <div className="project-member-header-left">
          <div className="project-member-header-left-title">Project Members</div>
          <div className="project-member-header-left-description">You can manage members belonging to the project and invite new members.</div>
          {isOwner && (
            <div className="project-member-header-left-button" onClick={() => setInviteMemberModalOpen((prevState: boolean) => !prevState)}>
              Invite member
            </div>
          )}
        </div>
        <div className="project-member-header-right">
          <div className="project-member-header-right-item">
            <div>
              <div className="project-member-header-right-item-label">Total Member</div>
              <div className="project-member-header-right-item-value">
                <img className="project-member-header-right-item-value-image" src={membersIcon} alt="" />
                {memberList.length}
              </div>
            </div>
          </div>
          <div className="project-member-header-right-item-divide-line" />
          <div className="project-member-header-right-item">
            <div>
              <div className="project-member-header-right-item-label">Invited</div>
              <div className="project-member-header-right-item-value">
                <img className="project-member-header-right-item-value-image" src={inviteWhiteIcon} alt="" />
                {invitationList.length}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const UserSection = () => {
    return (
      <div className="user">
        <div className="project-member-user-header">
          <div className="project-member-user-header-item">Name</div>
          <div className="project-member-user-header-item">Email</div>
          <div className="project-member-user-header-item">Member</div>
          {isOwner && <div className="project-member-user-header-item">Delete</div>}
        </div>
        <div className="project-member-user-list">
          {memberList.map((member: ProjectMemberType & User) => (
            <MemberListRow
              accessor={belongToMemberListAccessor}
              renderer={belongToMemberListRenderer}
              data={member}
              key={`project-member-user-row-${member.id}`}
            />
          ))}
        </div>
      </div>
    );
  };
  const InviteSection = () => {
    return (
      <div>
        {invitationList.length === 0 && <EmptyDataTemplate>{`You have not sent any invitations.`}</EmptyDataTemplate>}
        {invitationList.length !== 0 && (
          <>
            <div className="project-member-invite-header">
              <div className="project-member-invite-header-item">Email</div>
              <div className="project-member-invite-header-item">Invited</div>
              <div className="project-member-invite-header-item">Valid</div>
              {isOwner && <div className="project-member-invite-header-item">Cancel</div>}
            </div>
            <div className="project-member-invite-list">
              {invitationList.map((member: any) => (
                <InviteListRow accessor={invitedListAccessor} renderer={invitedListRenderer} data={member} key={`setting-member-row-${member.id}`} />
              ))}
            </div>
          </>
        )}
      </div>
    );
  };
  return (
    <div id={"ProjectMember"}>
      {inviteMemberModalOpen && (
        <InviteMemberModal
          inviteMemberModalOpen={inviteMemberModalOpen}
          setInviteMemberModalOpen={setInviteMemberModalOpen}
          setInvitationList={setInvitationList}
        />
      )}
      {removeMemberModalOpen && (
        <RemoveMemberModal
          selectedMemberDetail={selectedMemberDetail}
          setSelectedMemberDetail={setSelectedMemberDetail}
          setMemberList={setMemberList}
          removeMemberModalOpen={removeMemberModalOpen}
          setRemoveMemberModalOpen={setRemoveMemberModalOpen}
        />
      )}
      <PageHeader title={"Member"} />
      <div className="project-member-container">
        <ProjectMemberHeader />
        <SectionContainer title="Project Member" icon={memberIcon}>
          <UserSection />
        </SectionContainer>
        <SectionContainer title="Invited" icon={inviteIcon}>
          <InviteSection />
        </SectionContainer>
      </div>
    </div>
  );
};

type ListRowProps = {
  accessor: Array<string>;
  renderer: Function;
  data: any;
};

const MemberListRow = (props: ListRowProps) => {
  const { accessor, renderer, data } = props;
  return (
    <div className="project-member-user-row">
      {accessor.map((acc: any) => (
        <div className="project-member-user-row-item" key={`project-member-user-row-item-${acc}`}>
          {renderer(data)[acc]}
        </div>
      ))}
    </div>
  );
};
const InviteListRow = (props: ListRowProps) => {
  const { accessor, renderer, data } = props;
  return (
    <div className="project-member-invite-row">
      {accessor.map((acc: any) => (
        <div className="project-member-invite-row-item" key={`project-member-invite-row-item-${acc}`}>
          {renderer(data)[acc]}
        </div>
      ))}
    </div>
  );
};

type MemberInformationProps = {
  id?: number;
  name: string;
  image: string;
};

const MemberInformation = ({ id, name, image }: MemberInformationProps) => {
  const userId = useSelector(user_info).id;
  return (
    <div className="new-member-information">
      <div className="new-member-information-profile">
        <img src={image} className="new-member-information-profile-image" alt={""} />
      </div>
      <div className="new-member-information-name">{name}</div>
      {id === userId && <div className="new-member-information-its-me">you</div>}
    </div>
  );
};

export default ProjectMember;
