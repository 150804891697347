export type getContractDetailRequest = {
  params: {
    contractId: number;
  };
  query: {};
};

export type getContractDetailResponse = {
  contract_id: number;
  micro_chain_id: number;
  name: string;
  project_id: number;
  creator_id: number;
  contract_address: string;
  description: string;
  type: number;
  transaction_hash: string;
  main_status: number;
  sub_status: number;
  erc_type: number;
  is_system: boolean;
  origin_contract_id: number;
  origin_contract_version_id: number;
  origin_version: string;
  implementation_contract_id: number;
};

export type getContractListRequest = {
  params: {};
  query: {
    contractId: number[];
  };
};

export const CONTRACT_TYPE = {
  0: "FIXED",
  1: "PROXY",
  2: "IMPLEMENTATION",
};

export type getContractListResponse = {
  contract_id: number;
  micro_chain_id: number;
  name: string;
  project_id: number;
  creator_id: number;
  contract_address: string;
  description: string;
  main_status: number;
  sub_status: number;
  erc_type: number;
  is_system: boolean;
  origin_version: string;
  created_at: string;
}[];

export type getContractByteCodeRequest = {
  params: {
    contractId: number;
  };
  query: {};
};

export type getContractByteCodeResponse = {
  byte_code: string;
};

export type getContractAbiCodeRequest = {
  params: {
    contractId: number;
  };
  query: {};
};

export type getContractAbiCodeResponse = AbiItem | AbiItem[];
export interface AbiItem {
  anonymous?: boolean;
  constant?: boolean;
  inputs?: AbiInput[];
  name?: string;
  outputs?: AbiOutput[];
  payable?: boolean;
  stateMutability?: StateMutabilityType;
  type: AbiType;
  gas?: number;
}

export interface AbiInput {
  name: string;
  type: string;
  internalType: string;
  description?: string;
}

export interface AbiOutput {
  name: string;
  type: string;
  components?: AbiOutput[];
  internalType?: string;
}
export type AbiType =
  | "function"
  | "constructor"
  | "event"
  | "fallback"
  | "receive";
export type StateMutabilityType = "pure" | "view" | "nonpayable" | "payable";

export type getContractLibraryDetailRequest = {
  params: {
    contractId: number;
    contractVersionId: number;
  };
  query: {};
};

export type getContractLibraryDetailResponse = {
  contract_version_id: number;
  contract_id: number;
  version: string;
  version_description: string;
  status: number;
  is_private: number;
  contract: Contract;
  contract_version_code: ContractVersionCode;
  contract_version_reject_detail: string;
};

type ContractVersionCode = {
  contract_version_id: number;
  solidity_code: string;
  byte_code: string;
  abi_code: string;
  custom_abi_code: string;
};

type Contract = {
  contract_id: number;
  project_id: number;
  creator_id: number;
  latest_contract_version_id: number;
  title: string;
  description: string;
  type: number;
  erc_type: number | null;
  is_official: number;
  contract_standard_id: number;
  deploy_count: number;
  image: string;
};
