import {Notification} from "../reducer/clientType/notificationClientType";
import {NotificationFromServer} from "../server/type/notificationServerType";
import {
  deleteNotificationFromServer,
  getNotificationsFromServer,
  readAllNotificationFromServer,
  readNotificationFromServer
} from "../server/API/notificationAPI";

export const getNotificationParser = async (device: 'WEB' | 'MOBILE' | 'APP' = 'WEB', limit: number = 50): Promise<Notification[]> => {
  const result: NotificationFromServer[] = await getNotificationsFromServer({
    query: {
      device,
      limit,
    }
  });
  return result.map(notification => {
    return {
      notificationId: notification.platform_notification_id,
      createdAt: notification.created_at,
      isRead: notification.is_read,
      link: notification.link,
      categoryId: notification.platform_category_id,
      serviceId: notification.platform_service_id,
      contents: notification.platform_notification_contents,
      title: notification.title,
    }
  })
}

export const readAllNotificationParser = async(): Promise<boolean> => {
  const result = await readAllNotificationFromServer()
  return result.success
}

export const readNotificationParser = async(platformNotificationId: number): Promise<boolean> => {
  const result = await readNotificationFromServer({
    params: {platformNotificationId},
  });
  return result.success
}

export const deleteNotificationParser = async(platformNotificationId: number): Promise<boolean> => {
  const result = await deleteNotificationFromServer({
    params: {platformNotificationId},
  });
  return result.success;
}
