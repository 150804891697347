import { makeQuery } from "../index/utils";
import { v2ServerByProjectAuth, v2ServerByUserAuth } from "../index/serverInstances";
import { GetMicroChainListOptionsType } from "reducer/clientType/microChainClientType";

//----------getMicroChainList
// TODO :: 이후 서버 인스턴스 변경 및 위치 변경
const getMicroChainList = async (request: getMicroChainListRequest): Promise<getMicroChainListResponse> =>
  await v2ServerByUserAuth.get({
    path: `micro-chains`,
    query: makeQuery(request.query),
  });

type getMicroChainListRequest = {
  query: GetMicroChainListOptionsType;
};
type getMicroChainListResponse = any;

//----------createMicroChain
const createMicroChain = async (request: CreateMicroChainRequest): Promise<CreateMicroChainResponse> =>
  await v2ServerByProjectAuth.post({
    path: "micro-chains",
    data: request.data,
  });

type CreateMicroChainRequest = {
  data: {
    label: string;
    microChainId: number;
  };
};
type CreateMicroChainResponse = any;

//----------updateMicroChain
const updateMicroChain = async (request: updateMicroChainRequest): Promise<updateMicroChainResponse> =>
  await v2ServerByProjectAuth.put({
    path: `micro-chains/${request.params.microChainId}`,
    data: request.data,
  });

type updateMicroChainRequest = {
  params: {
    microChainId: number;
  };
  data: {
    label: string;
    microChainId: number;
  };
};
type updateMicroChainResponse = any;

//----------getMicroChainDetail
const getMicroChainDetail = async (request: GetMicroChainDetailRequest): Promise<GetMicroChainDetailResponse> =>
  await v2ServerByProjectAuth.get({
    path: `micro-chains/${request.params.microChainId}`,
    query: makeQuery(request.query),
  });

type GetMicroChainDetailRequest = {
  params: {
    microChainId: number;
  };
  query: {
    paranoid?: boolean;
  };
};
type GetMicroChainDetailResponse = any;
//---------------------------------------------------------------------------------------

const getNodesByMicroChainId = async (request: getNodesByMicrochainIdRequest): Promise<getNodesByMicrochainIdResponse> =>
  await v2ServerByProjectAuth.get({
    path: `nodes`,
    query: makeQuery(request.query),
  });

type getNodesByMicrochainIdRequest = {
  query: {
    microChainId: number;
  };
  data: {
    networkId: number;
  };
};

type getNodesByMicrochainIdResponse = any;
//---------------------------------------------------------------------------------------

const getGasPrice = async (request: GetGasPriceRequest): Promise<GetGasPriceResponse> =>
  await v2ServerByProjectAuth.get({
    path: `micro-chains/${request.params.microChainId}/gas-price`,
  });

type GetGasPriceRequest = {
  params: {
    microChainId: number;
  };
};

type GetGasPriceResponse = {
  gas_price: string;
};
//---------------------------------------------------------------------------------------
export {
  getMicroChainList as getMicroChainListFromServer,
  createMicroChain as createMicroChainFromServer,
  updateMicroChain as updateMicroChainFromServer,
  getMicroChainDetail as getMicroChainDetailFromServer,
  getNodesByMicroChainId as getNodesByMicroChainIdFromServer,
  getGasPrice as getGasPriceFromServer,
};
