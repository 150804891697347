import {ModuleError} from "../ModuleError";

interface IWeb3ErrorData {
  reason: string;
  code: string;
  argument: string;
  value: string;
}

export class Web3Error extends ModuleError{
  name: string;

  constructor(message:string, public data: IWeb3ErrorData) {
    super(message);
    this.name = 'Web3'
  }
}