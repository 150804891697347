import { ERROR_TYPE_LOGIN_EXPIRED } from "./utils";

export type APIError = {
  type: "API";
  data: {
    baseURL: string | undefined;
    path: string | undefined;
    message: string | undefined;
    status: string | undefined;
    serverStatus: string | undefined;
  };
};

export type RequestError = {
  type: string | undefined;
  data: {
    baseURL: string | undefined;
    path: string | undefined;
    message: string | undefined;
    status: string | undefined;
    serverStatus: string | undefined;
  };
};

export const isApiError = (error: any): error is APIError => {
  return error.type === "API";
};

export const isExpiredError = (error: any): any => {
  return error.type === ERROR_TYPE_LOGIN_EXPIRED;
};
