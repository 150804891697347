import React from "react";
import "./TextArea.scss";

const TextArea = ({ value, placeholder, onChange, comment, style, rows = 5, readOnly, onFocus, ...rest }) => {
  return (
    <div className={'text-area'}>
      <textarea
        className={"atom-textarea"}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        rows={rows}
        readOnly={readOnly}
        onFocus={!readOnly ? onFocus : undefined}
        style={{ ...style, borderColor: !Boolean(comment) ? "#E7E9EF" : "#DE1F1F" }}
        {...rest}
      />
      {Boolean(comment) && <div className="textarea-invalid-comment">{comment}</div>}
    </div>
  );
};

export default TextArea;
