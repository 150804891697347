import EQConnectWalletButton from "../../../../../../modules/EQConnect/EQConnectWalletButton";
import { useSelector } from "react-redux";
import { connectInfo } from "../../../../../../store/redux/EQconnect/EQConnectSlice";
import BasicButton from "view/components/_atom/button/BasicButton";
import { ButtonSize } from "view/components/_atom/button/StyledButton";
import ButtonContainer from "view/components/_atom/container/ButtonContainer";

const ButtonTemplate = (props) => {
  const { subStatus, onClickDepositButton, onClickSendFeeButton, onClickPublishButton, onClickDeployButton } = props || {};

  const { address: connectedAddress } = useSelector(connectInfo);

  //  Wallet 연결이 되지 않은 상태
  if (Boolean(!connectedAddress)) {
    return (
      <div className="flex justify-content-flex-end">
        <ButtonContainer>
          <EQConnectWalletButton />
        </ButtonContainer>
      </div>
    );
  }

  return (
    <div className="flex justify-content-flex-end">
      <ButtonContainer>
        {/* Contract Deploy를 해야 하는 status */}
        {(subStatus === 0 || subStatus === 2) && Boolean(connectedAddress) && (
          <>
            <EQConnectWalletButton />
            <BasicButton size={ButtonSize.LARGE} onClick={() => onClickDeployButton()}>
              Deploy
            </BasicButton>
          </>
        )}

        {/* Contract Deploy 완료, Deposit 해야 하는 status */}
        {(subStatus === 3 || subStatus === 5) &&  Boolean(connectedAddress) && (
          <>
            <EQConnectWalletButton />
            <BasicButton size={ButtonSize.LARGE} onClick={() => onClickDepositButton()}>
              Deposit
            </BasicButton>
          </>
        )}

        {/* Contract Deploy, Deposit 완료, Send fee 해야 하는 status */}
        {(subStatus === 6 || subStatus === 8) && Boolean(connectedAddress) && (
          <>
            <EQConnectWalletButton />
            <BasicButton size={ButtonSize.LARGE} onClick={() => onClickSendFeeButton()}>
              Send fee
            </BasicButton>
          </>
        )}

        {/* Publish가 준비 된 상태 */}
        {subStatus === 9 && (
          <BasicButton size={ButtonSize.LARGE} onClick={() => onClickPublishButton()}>
            Publish
          </BasicButton>
        )}
      </ButtonContainer>
    </div>
  );
};

export default ButtonTemplate;
