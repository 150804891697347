import React from "react";
import IconRemoveArea from "../../../../view/assets/images/atom/icon-FileDragAndDrop-delete.png";
import IconAddInput from "../../../../view/assets/images/icon-common-add.png";
import "./MultipleInput.scss";
import BasicInput from "view/components/_atom/input/BasicInput";

const MultipleInput = ({ inputArray, setInputArray, placeholder = "" }) => {
  const addInput = () => {
    setInputArray((prev) => prev.concat(""));
  };

  const removeInput = (index) => {
    const updatedLinks = inputArray.filter((el, idx) => index !== idx);
    setInputArray(updatedLinks);
  };

  return (
    <div className="multiple-input-wrapper">
      {inputArray?.map((el, index) => {
        return (
          <div className="multiple-input-item-wrapper" key={index}>
            <BasicInput
              value={inputArray[index] ?? ""}
              placeholder={placeholder}
              onChange={(e) =>
                setInputArray((prev) => {
                  return prev.map((el, idx) => {
                    if (idx === index) {
                      return e.target.value;
                    } else {
                      return el;
                    }
                  });
                })
              }
            />
            <div style={{ width: 10 }} />
            <img className="remove-input-button" src={IconRemoveArea} alt="" onClick={() => removeInput(index)} />
          </div>
        );
      })}
      <div className="add-input-button" onClick={() => addInput()}>
        <img className="add-input-button-icon" src={IconAddInput} alt="" />
      </div>
    </div>
  );
};

export default MultipleInput;
