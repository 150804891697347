import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { connectInfo } from "../../../../../store/redux/EQconnect/EQConnectSlice";
import FileUpload from "../../../../components/_atom/file/FileUpload";
import ImageUpload from "../../../../components/_atom/file/ImageUpload";
import { makeQuery } from "../../../../../server/index/utils";
import { errorAlert } from "../../../../../utils/Utils";
import { callAction, makeTransactionAction } from "../../../../../action/requestAction";
import { project_access } from "reducer/projectReducer";
import { getNFTProjectDetailAction, uploadNFTContentToS3Action } from "action/nftAction";
import PageArticle from "view/components/_template/page/PageArticle";
import PageTitle from "view/components/_molecules/page/PageTitle";
import PageLoading from "view/components/_atom/loading/PageLoading";
import BasicTextarea from "view/components/_atom/input/BasicTextarea";
import BasicButton from "view/components/_atom/button/BasicButton";
import { ButtonSize, ButtonTheme } from "view/components/_atom/button/StyledButton";
import ButtonContainer from "view/components/_atom/container/ButtonContainer";
import useValidateCondition from "hooks/useValidateCondition";
import { VALIDATION__NFT_description, VALIDATION__NFT_name, VALIDATION__NFT_recipient } from "../validation/NFTValidation";
import useIsAble from "hooks/useIsAble";
import CommentInput from "view/components/_atom/input/CommentInput";
import useTransactionDirectly from "../../../../../hooks/useTransactionDirectly";
import { statusType } from "../../../../../utils/status.utils";
import TransactionStatus from "../../../../components/_atom/transactionStatus/TransactionStatus";
import { FieldContainer, FieldLabel, RowField } from "view/components/_atom/section/Field";
import nftIcon from "../../../../assets/images/service/Icon_SNB_non_fungible_token.png";

const EXTENSION = {
  image: ["png", "jpeg", "gif"],
  video: ["mp4", "webm"],
  audio: ["mpeg", "wav"],
};

const MintNft = () => {
  const navigate = useNavigate();
  const { id: nftProjectId } = useParams();
  const { address: connectedWalletAddress } = useSelector(connectInfo);
  const token = useSelector(project_access);

  const [isLoading, setIsLoading] = useState(true);
  const [projectDetail, setProjectDetail] = useState({});
  const [currentStep, setCurrentStep] = useState(0);
  const [nftProjectOwner, setNFTProjectOwner] = useState("");

  const [nftName, setNFTName] = useState("");
  const [nftDescription, setNFTDescription] = useState("");
  const [nftContent, setNFTContent] = useState(null);
  const [contentType, setContentType] = useState("");
  const [additionalContent, setAdditionalContent] = useState(null);

  const [nftRecipient, setNFTRecipient] = useState("");

  const [mintNFTTransactionStatus, setMintNFTTransactionStatus] = useState(null);

  const isContractOwner = useMemo(() => {
    return connectedWalletAddress === nftProjectOwner;
  }, [connectedWalletAddress, nftProjectOwner]);

  const nameValidation = useValidateCondition([nftName], VALIDATION__NFT_name(nftName));
  const descriptionValidation = useValidateCondition([nftDescription], VALIDATION__NFT_description(nftDescription));
  const recipientValidation = useValidateCondition([nftRecipient], VALIDATION__NFT_recipient(nftRecipient));

  const isAbleToCheck = useIsAble([
    connectedWalletAddress,
    nameValidation.isValid,
    descriptionValidation.isValid,
    nftContent,
    recipientValidation.isValid,
    contentType !== "image" ? additionalContent && nftContent : nftContent,
  ]);

  const getNFTProjectContractOwner = async () => {
    const { error, result } = await callAction(projectDetail.microChainId, projectDetail.contractAddress, "owner", []);
    if (result) {
      setNFTProjectOwner(result[0]);
    }
    if (error) {
      switch (error.type) {
        case "API":
          errorAlert(error.data.message);
          break;
        case "Module":
          errorAlert(`${error.data.argument}::${error.data.code}`);
          break;
        default:
          errorAlert("Error::unknown");
      }
    }
  };

  const getNFTProjectDetail_ = async () => {
    const { result, error } = await getNFTProjectDetailAction(parseInt(nftProjectId));
    if (result) {
      if (result.mainStatus !== 5) {
        toast.error("Project is not deployed yet.");
        setTimeout(() => {
          navigate(`/application/nft/manage/${nftProjectId}`);
        }, 500);
      }
      if (result.mainStatus === 5) {
        setProjectDetail(result);
        setTimeout(() => {
          setIsLoading(false);
        }, 500);
      }
    }
    if (error) {
      errorAlert(error.data.message);
    }
  };

  const onClickNext = async () => {
    let formData = new FormData();
    let fileCount;
    if (nftName.trim().length <= 0) {
      toast.error("Please enter 'NFT name'");
      return;
    }
    if (nftDescription.trim().length <= 0) {
      toast.error("Please enter 'NFT description'");
      return;
    }
    switch (contentType) {
      case "image":
        if (typeof nftContent === "string") {
          setCurrentStep(1);
        } else {
          fileCount = 1;
          formData.append("image", nftContent);
          const { result, error } = await uploadNFTContentToS3Action(fileCount, formData);
          if (result) {
            setNFTContent(result.find((el) => el.fieldName === contentType).location);
            setCurrentStep(1);
          }
          if (error) {
            errorAlert(error.data.message);
          }
        }
        break;
      default:
        if (typeof nftContent === "string" || typeof additionalContent === "string") {
          //둘중 하나라도 올린 적이 있을 경우
          if (typeof nftContent === "string" && typeof additionalContent !== "string") {
            fileCount = 1;
            formData.append("image", additionalContent);
            const { result, error } = await uploadNFTContentToS3Action(fileCount, formData);
            if (result) {
              setAdditionalContent(result.find((el) => el.fieldName === "image").location);
              setCurrentStep(1);
            }
            if (error) {
              errorAlert(error.data.message);
            }
          }
          if (typeof nftContent !== "string" && typeof additionalContent === "string") {
            fileCount = 1;
            formData.append(contentType, nftContent);
            const { result, error } = await uploadNFTContentToS3Action(fileCount, formData);
            if (result) {
              setNFTContent(result.find((el) => el.fieldName === contentType).location);
              setCurrentStep(1);
            }
            if (error) {
              errorAlert(error.data.message);
            }
          }
          if (typeof nftContent === "string" && typeof additionalContent === "string") {
            setCurrentStep(1);
          }
        } else {
          //없을 경우
          fileCount = 2;
          formData.append(contentType, nftContent);
          formData.append("image", additionalContent);
          const { result, error } = await uploadNFTContentToS3Action(fileCount, formData);
          if (result) {
            setNFTContent(result.find((el) => el.fieldName === contentType).location);
            setAdditionalContent(result.find((el) => el.fieldName === "image").location);
            setCurrentStep(1);
          }
          if (error) {
            errorAlert(error.data.message);
          }
        }
    }
  };

  const onClickCreateNFT = async () => {
    const commonJson = {
      name: nftName,
      description: nftDescription,
    };
    let NFTJson;
    switch (contentType) {
      case "image":
        NFTJson = {
          ...commonJson,
          image: nftContent,
        };
        break;
      default:
        NFTJson = {
          ...commonJson,
          image: additionalContent,
          animation_url: nftContent,
        };
    }
    const file = new File([`${JSON.stringify(NFTJson)}`], `${Date.now()}`, {
      type: "application/octet-stream",
    });
    let formData = new FormData();
    formData.append("json", file);
    const { result, error } = await uploadNFTContentToS3Action(1, formData);
    if (result) {
      const jsonURL = result.find((el) => el.fieldName === "json").location;
      let L2Json;
      switch (contentType) {
        case "image":
          L2Json = {
            imgUrl: nftContent,
          };
          break;
        default:
          L2Json = {
            imgUrl: additionalContent,
            animationUrl: nftContent,
          };
          break;
      }
      return await makeTransactionAction({
        address: connectedWalletAddress,
        microChainId: projectDetail.microChainId,
        contractAddress: projectDetail.contractAddress,
        parameters: [nftRecipient, jsonURL],
        to: projectDetail.contractAddress,
        value: "0x0",
        functionName: "safeMint",
        transactionPath: `${process.env.REACT_APP_HUB_SERVER_URL}${process.env.REACT_APP_API_VERSION_V2}wallet-manage/erc721-token/mint${makeQuery({
          microChainId: projectDetail.microChainId,
        })}`,
        token,
        additionalQuery: {
          body: {
            erc721TempId: projectDetail.projectId,
            name: nftName,
            description: nftDescription,
            ...L2Json,
            type: contentType,
          },
        },
      });
    }
    if (error) {
      errorAlert(error.data.message);
    }
  };

  const mintNFTCallback = (transactionHash) => {
    setMintNFTTransactionStatus({
      statusDetail: {
        type: statusType.CHECK,
        title: "Transaction is sent",
        showTransactionHash: true,
      },
      transactionHash,
      microChainId: projectDetail.microChainId,
    });
  };

  useEffect(() => {
    getNFTProjectDetail_();
  }, []);

  useEffect(() => {
    if (projectDetail.contractAddress) {
      getNFTProjectContractOwner();
    }
  }, [projectDetail]);

  useEffect(() => {
    if (!!!connectedWalletAddress) {
      toast.error("Please connect your wallet first");
      navigate(`/application/nft/manage/${nftProjectId}`);
    }
  }, []);

  useEffect(() => {
    if (!!nftProjectOwner && !!connectedWalletAddress) {
      if (!isContractOwner) {
        toast.error("Only contract manager can mint NFT");
        navigate(`/application/nft/manage/${nftProjectId}`);
      }
    }
  }, [isContractOwner, nftProjectOwner, connectedWalletAddress]);

  const { requestTransactionDirectly } = useTransactionDirectly(onClickCreateNFT, mintNFTCallback);

  if (isLoading) {
    return <PageLoading />;
  }

  return (
    <PageArticle>
      <PageTitle
        title={"Mint NFT"}
        description={"Create your own NFT. \n You can mint the NFT you want through the NFT project and manage it in the EQ Hub wallet."}
        size={810}
      />
      <div className="page-layout-810">
        {mintNFTTransactionStatus && <TransactionStatus {...mintNFTTransactionStatus} />}

        {/* NFT Info Description :: Enter information about the new NFT to be minted in the NFT project. */}
        <FieldContainer icon={nftIcon} title="NFT Info">
          <RowField>
            <FieldLabel label="Name" />
            <CommentInput
              {...nameValidation}
              value={nftName}
              onChange={(e) => setNFTName(e.target.value)}
              readOnly={currentStep !== 0}
              placeholder={"Enter the name of NFT"}
              autoFocus
            />
          </RowField>
          <RowField>
            <FieldLabel label="Description" />
            <>
              {currentStep === 0 ? (
                <BasicTextarea
                  value={nftDescription}
                  onChange={(e) => setNFTDescription(e.target.value)}
                  placeholder={"Enter the description of NFT"}
                  readOnly={currentStep !== 0}
                  maxLength={2000}
                  rows={5}
                />
              ) : (
                <div style={{ whiteSpace: "pre-wrap" }}>{nftDescription}</div>
              )}
            </>
          </RowField>
          <RowField>
            <FieldLabel label="NFT Content" />
            <FileUpload
              file={nftContent}
              setFile={setNFTContent}
              fileType={contentType}
              setFileType={setContentType}
              allowExtension={EXTENSION}
              maxFileSize={5 * 1024 ** 2}
              disabled={currentStep !== 0}
              previewLabel={"JPG, PNG, GIF, MP4, \n WEBM, MP3, WAV Only \nMax 5MB"}
            />
          </RowField>
          {(contentType === "video" || contentType === "audio") && (
            <RowField>
              <FieldLabel label="Preview Image" />
              <ImageUpload
                image={additionalContent}
                setImage={setAdditionalContent}
                disabled={currentStep !== 0}
                style={{ alignItems: "flex-start" }}
                previewStyle={{ width: 180, height: 180 }}
              />
            </RowField>
          )}

          <RowField>
            <FieldLabel label="Recipient" />
            <CommentInput
              {...recipientValidation}
              value={nftRecipient}
              onChange={(e) => setNFTRecipient(e.target.value)}
              readOnly={currentStep !== 0}
              placeholder={"Enter the wallet address to receive this NFT"}
            />
          </RowField>
        </FieldContainer>
        <div className="flex justify-content-flex-end">
          <ButtonContainer>
            {currentStep === 0 && (
              <BasicButton size={ButtonSize.LARGE} disabled={!isAbleToCheck} onClick={() => onClickNext()}>
                Next
              </BasicButton>
            )}
            {currentStep === 1 && !mintNFTTransactionStatus && (
              <>
                <BasicButton size={ButtonSize.LARGE} theme={ButtonTheme.SECONDARY} onClick={() => setCurrentStep(0)}>
                  Back
                </BasicButton>
                <BasicButton size={ButtonSize.LARGE} disabled={!connectedWalletAddress || !isContractOwner} onClick={() => requestTransactionDirectly()}>
                  Mint
                </BasicButton>
              </>
            )}
            {currentStep === 1 && mintNFTTransactionStatus && (
              <>
                <BasicButton size={ButtonSize.LARGE} onClick={() => navigate(`/application/nft/manage/${nftProjectId}`)}>
                  Close
                </BasicButton>
              </>
            )}
          </ButtonContainer>
        </div>
      </div>
    </PageArticle>
  );
};

export default MintNft;
