import { createEntityAdapter, createSlice, EntityId, PayloadAction } from "@reduxjs/toolkit";
import { EntityState } from "@reduxjs/toolkit/src/entities/models";
import { Project } from "./clientType/projectClientType";
import { RootState } from "./index";
import { Tokens } from "./clientType/userClientType";
import { JWTInitialState } from "./userReducer";

const projectAdapter = createEntityAdapter<Project>({
  selectId: (project) => project.id,
});

type ProjectReducer = {
  projects: EntityState<Project>;
  selectedProjectId: number;
  token: any;
};

const ProjectInitialState: ProjectReducer = {
  projects: projectAdapter.getInitialState({}),
  selectedProjectId: -1,
  token: JWTInitialState,
};

export const {
  selectAll: selectAllProjects,
  selectIds: selectProjectIds,
  selectById: selectProjectById,
} = projectAdapter.getSelectors<RootState>((state) => state.project.projects);

const projectSlice = createSlice({
  name: "project",
  initialState: ProjectInitialState,
  reducers: {
    setProjects(state, action: PayloadAction<Project[]>) {
      projectAdapter.setMany(state.projects, action.payload);
    },
    addProject(state, action: PayloadAction<Project>) {
      projectAdapter.addOne(state.projects, action.payload);
    },
    updateProject(state, action: PayloadAction<Project>) {
      projectAdapter.updateOne(state.projects, {
        id: action.payload.id,
        changes: action.payload,
      });
    },
    removeAllProjects(state) {
      projectAdapter.removeAll(state.projects);
      state.selectedProjectId = -1;
      state.token = JWTInitialState;
    },
    setSelectedProjectId(state, action: PayloadAction<number>) {
      state.selectedProjectId = action.payload;
    },
    setProjectToken(state, action: PayloadAction<any>) {
      state.token.access = action.payload.access;
      state.token.refresh = action.payload.refresh;
    },
  },
});

export const { setProjects, setSelectedProjectId, addProject, updateProject, removeAllProjects, setProjectToken } = projectSlice.actions;

export const selected_project_id = (state: RootState): number => state.project.selectedProjectId;
export const project_token = (state: RootState): Tokens => state.project.token;
export const project_access = (state: RootState): string => state.project.token.access.token;
export const project_id_list = (state: RootState): EntityId[] => state.project.projects.ids;

export default projectSlice.reducer;
