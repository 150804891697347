import {useEffect, useState} from "react";
import PageLoading from "../../../components/_atom/loading/PageLoading";
import FaqSnb from "../molcules/FaqSnb";
import FaqToggle from "../molcules/FaqToggle";
import "./Faq.scss";

function Faq() {
  const [selectedType, setSelectedType] = useState(null);
  const [selectedContents, setSelectedContents] = useState(null);
  const [selectedToggleId, setSelectedToggleId] = useState(null);
  // TODO: 서버로부터 내려오는 faq의 type들을 목록에 맞게 thunk에서 파싱하여 배치
  // const [faqlist, setFaqList] = useState(null);
  const faqList = [
    {
      type : 0,
      label: "General",
    },
    {
      type : 1,
      label: "Poster",
    },
    {
      type : 2,
      label: "Payments",
    },
    {
      type : 3,
      label: "Shipping",
    },
    {
      type : 4,
      label: "International payments",
    },
    {
      type : 5,
      label: "Refunds",
    },
  ];

  const onClickSnbTab = (e) => {
    const selectedType = e.type;
    if (faqList.find((el) => el.type === selectedType) !== undefined) {
      setSelectedType(faqList.find((el) => el.type === selectedType));
    }

    const selectedContents = faqContentList.find((content) => content.type === selectedType);
    setSelectedContents(selectedContents);
  };

  const onClickToggle = (e) => {
    if (selectedToggleId !== e) {
      setSelectedToggleId(e);
    }
    if (selectedToggleId === e) {
      setSelectedToggleId(null);
    }
  };

  useEffect(() => {
    const setInitialType = async () => {
      if (faqList) {
        // TODO: action발생함수로 데이터를 가져온다.
        // const faqlist = await getFaqData();
        // setFaqList(faqList);
        setSelectedType(faqList[0]);
      }
    };

    setInitialType();
  }, []);

  // TODO: 서버로부터 해당 faq 내용을 받아와 클릭된 type에 맞게 보여준다.
  const faqContentList = [
    {
      type: 0,
      // description:
      // "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
      contents: [
        {
          question: "How can I bid for your products?",
          answer  : "",
        },
        {
          question: "What is the function of your products?",
          answer  : "Our products help you to attain the perfect beauty you can ever imagined.",
        },
        {
          question: "How can I download your app?",
          answer  : "",
        },
        {
          question: "How are your exchange rates calculated?",
          answer  : "",
        },
        {
          question: "How can I buy your products?",
          answer  : "",
        },
      ],
    },
    {
      type: 1,
      // description: "",
      contents: [
        {
          question: "",
          answer  : "",
        },
      ],
    },
    {
      type: 2,
      // description: "",
      contents: [
        {
          question: "",
          answer  : "",
        },
      ],
    },
    {
      type: 3,
      // description: "",
      contents: [
        {
          question: "",
          answer  : "",
        },
      ],
    },
    {
      type: 4,
      // description: "",
      contents: [
        {
          question: "",
          answer  : "",
        },
      ],
    },
    {
      type: 5,
      // description: "",
      contents: [
        {
          question: "",
          answer  : "",
        },
      ],
    },
  ];
  useEffect(() => {
    const setInitialContent = async () => {
      if (selectedType) {
        // TODO: action발생함수로 데이터를 가져온다.
        // const type = selectedType.type
        // const selectedContents = getFaqContent(type);
        const selectedContents = faqContentList.find((el) => el.type === selectedType.type);
        setSelectedContents(selectedContents);
      }
    };
    setInitialContent();
    setSelectedToggleId(null);
  }, [selectedType]);

  if (!selectedContents || !selectedType) {
    return <PageLoading/>;
  }

  //TODO: 6. 28일 기준 공개 X
  return (
    <>
      <FaqSnb faqList={faqList} onClick={onClickSnbTab} selectedType={selectedType}/>
      <div className="faq-content">
        <div className="faq-title">
          <div className="faq-title__title">{selectedType.label}</div>
          <div className="faq-title__sub-title">{selectedContents.description}</div>
        </div>
        {selectedContents.contents.map((el, index) => {
          return (
            <div className="faq-content__toggle" onClick={() => onClickToggle(index)} key={index}>
              <FaqToggle question={el.question} answer={el.answer} isOpen={index === selectedToggleId}/>
            </div>
          );
        })}
      </div>
    </>

  );
}

export default Faq;
