import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PageLoading from '../../../../components/_atom/loading/PageLoading';
import { connectInfo } from '../../../../../store/redux/EQconnect/EQConnectSlice';
import EQConnectWalletButton from '../../../../../modules/EQConnect/EQConnectWalletButton';
import { WEB3 } from '../../../../../modules/web3/Web3';
import { stringNumInputHandler } from '../utils/tokenUtils';
import { removeComma } from '../../../../../utils/Utils';
import { makeTransactionAction } from '../../../../../action/requestAction';
import { getTokenDetailAction } from 'action/tokenAction';
import PageArticle from 'view/components/_template/page/PageArticle';
import PageTitle from 'view/components/_molecules/page/PageTitle';
import useLoading from 'hooks/useLoading';
import useIsAble from 'hooks/useIsAble';
import useGetSelectedProject from 'hooks/useGetSelectedProject';
import ButtonContainer from 'view/components/_atom/container/ButtonContainer';
import useValidateCondition from 'hooks/useValidateCondition';
import { VALIDATION__TOKEN_address, VALIDATION__TOKEN_amount } from '../validation/tokenValidation';
import CommentInput from 'view/components/_atom/input/CommentInput';
import BasicButton from '../../../../components/_atom/button/BasicButton';
import { ButtonSize } from '../../../../components/_atom/button/StyledButton';
import TransactionStatus from '../../../../components/_atom/transactionStatus/TransactionStatus';
import { statusType } from '../../../../../utils/status.utils';
import useTransactionDirectly from '../../../../../hooks/useTransactionDirectly';
import { project_access } from '../../../../../reducer/projectReducer';
import { makeQuery } from 'server/index/utils';
import { FieldContainer, FieldLabel, RowField } from 'view/components/_atom/section/Field';
import tokenIcon from '../../../../assets/images/service/Icon_SNB_token.png';

const MintToken = () => {
  const { id: currencyId } = useParams();
  const { address: connectedWalletAddress } = useSelector(connectInfo);
  const { selectedProjectID } = useGetSelectedProject();
  const token = useSelector(project_access);

  const [tokenDetail, setTokenDetail] = useState(null);
  const [toAddress, setToAddress] = useState('');
  const [amount, setAmount] = useState('');

  const [mintTransactionResult, setMintTransactionResult] = useState(null);

  const setTokenDetailFromServer = async () => {
    const { result, error } = await getTokenDetailAction(parseInt(currencyId));
    if (result) {
      setTokenDetail(result);
    }
    if (error) {
    }
  };

  const makeMintTokenTransaction = async () => {
    return await makeTransactionAction({
      address: connectedWalletAddress,
      microChainId: tokenDetail.originMicroChainId,
      contractAddress: tokenDetail.originMicroChainCurrency.contractAddress,
      parameters: [toAddress, WEB3.toWei(removeComma(amount))],
      to: tokenDetail.originMicroChainCurrency.contractAddress,
      value: null,
      transactionPath: `${process.env.REACT_APP_HUB_SERVER_URL}${process.env.REACT_APP_API_VERSION_V2}wallet-manage/erc20/mint${makeQuery({
        microChainId: tokenDetail.originMicroChainId,
      })}`,
      functionName: 'mint',
      token,
      additionalQuery: {
        body: {
          erc20Id: tokenDetail.currencyId,
        },
      },
    });
  };

  const mintTokenCallback = (transactionHash) => {
    setMintTransactionResult({
      statusDetail: {
        type: statusType.CHECK,
        title: 'Transaction is sent',
        showTransactionHash: true,
      },
      transactionHash,
      microChainId: tokenDetail.originMicroChainId,
    });
    setToAddress('');
    setAmount('');
  };

  const toAddressValidation = useValidateCondition([toAddress], VALIDATION__TOKEN_address(toAddress));
  const amountValidation = useValidateCondition([amount], VALIDATION__TOKEN_amount(amount));

  const { requestTransactionDirectly } = useTransactionDirectly(makeMintTokenTransaction, mintTokenCallback);

  const isAble = useIsAble([connectedWalletAddress, toAddressValidation.isValid, amountValidation.isValid]);

  const { loading } = useLoading({
    dependency: [selectedProjectID],
    synchronousFunction: async () => {
      await setTokenDetailFromServer();
    },
  });

  if (loading) {
    return <PageLoading />;
  }

  return (
    <PageArticle>
      <PageTitle title={'Mint Token'} size={810} />
      <div className="page-layout-810">
        {mintTransactionResult && <TransactionStatus {...mintTransactionResult} />}
        <FieldContainer icon={tokenIcon} title="Mint Token">
          <RowField>
            <FieldLabel label="To" />
            <CommentInput
              {...toAddressValidation}
              value={toAddress}
              onChange={(e) => setToAddress(e.target.value)}
              placeholder={'Enter the address to receive the token to mint'}
              autoFocus
            />
          </RowField>
          <RowField>
            <FieldLabel label="Amount" />
            <CommentInput
              {...amountValidation}
              value={amount}
              onChange={(e) => {
                stringNumInputHandler(e.target.value, setAmount);
              }}
              placeholder={'Enter amount of token to mint'}
              maxLength={55}
            />
          </RowField>
        </FieldContainer>
        <div className="flex justify-content-flex-end">
          <ButtonContainer>
            <EQConnectWalletButton />
            <BasicButton size={ButtonSize.LARGE} disabled={!isAble} onClick={() => requestTransactionDirectly()}>
              Mint
            </BasicButton>
          </ButtonContainer>
        </div>
      </div>
    </PageArticle>
  );
};

export default MintToken;
