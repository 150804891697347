import {useMemo} from "react";

const useIsAbleByConditionLegacy = (deps) => {
  const isAble = useMemo(() => {
    return deps.reduce((acc, cur) => {
      return Boolean(acc) && Boolean(cur);
    }, true);
  }, [...deps]);

  return { isAble };
};

export default useIsAbleByConditionLegacy;
