import React from "react";
import "./SignInExpiredModal.scss";
import ModalTemplateB from "../_template/modal/ModalTemplateB";
import ButtonContainer from "../_atom/container/ButtonContainer";
import BasicButton from "../_atom/button/BasicButton";
import { ButtonSize } from "../_atom/button/StyledButton";
import { logoutAction } from "../../../action/userAction";
import { logout } from "server/index/utils";

const EQHUB_CS_EMAIL = "help_eqhub@eqbr.com";

type SignInExpiredModalProps = {
  isOpen: boolean;
  closeModal: Function;
};

const SignInExpiredModal = (props: SignInExpiredModalProps) => {
  const { isOpen, closeModal } = props;

  const signInButtonClickHandler = async () => {
    await logout();
    closeModal();
    window.location.reload();
  };

  return (
    <ModalTemplateB isOpen={isOpen} onRequestClose={() => closeModal()} title="Sign In Expired" shouldCloseOnOverlayClick={false}>
      <div className="sign-in-expired-modal">
        <div className="sign-in-expired-modal-body">
          <div className="sign-in-expired-modal-body-row">
            <span>{`Your sign in has been securely expired after a period of inactivity to uphold the highest security standards.`}</span>
          </div>
          <div className="sign-in-expired-modal-body-row">
            <span>{`Please sign in again to access your account and ensure the ongoing safety of your project information.`}</span>
          </div>
          <div className="sign-in-expired-modal-body-row">
            <span>{`For any inquiries or assistance, please contact us (`}</span>
            <a href={`mailto:${EQHUB_CS_EMAIL}`} className="sign-in-expired-modal-body-row-contact">
              {EQHUB_CS_EMAIL}
            </a>
            <span>{`).`}</span>
          </div>
        </div>
        <div className="sign-in-expired-modal-button-wrapper">
          <ButtonContainer>
            <BasicButton size={ButtonSize.LARGE} onClick={() => signInButtonClickHandler()}>
              Go to sign in
            </BasicButton>
          </ButtonContainer>
        </div>
      </div>
    </ModalTemplateB>
  );
};

export default SignInExpiredModal;
