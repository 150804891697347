import { Action, configureStore } from "@reduxjs/toolkit";
import userReducer from "../userReducer";
import projectReducer from "../projectReducer";
import connectReducer from "../../store/redux/EQconnect/EQConnectSlice";
import transactionResultReducer from "../../store/redux/transaction/transactionSlice";
import presetReducer from "../presetReducer";

export const store = configureStore({
  reducer: {
    user: userReducer,
    project: projectReducer,
    connect: connectReducer,
    transactionResult: transactionResultReducer,
    preset: presetReducer,
  },
  middleware: (getDefaultMiddleware) => [...getDefaultMiddleware()],
  devTools: process.env.NODE_ENV !== "production",
});

export const storeDispatch = (action: Action) => store.dispatch(action);
export const storeState = () => store.getState();
export type RootState = ReturnType<typeof store.getState>;
