import {useEffect, useMemo, useState} from 'react';
import LibraryContractCard from "../molcules/LibraryContractCard";
import {CONTRACT_ERC} from "../constants/contract.data";
import "./LibraryContractContainer.scss";
import EmptyDataTemplate from "view/service/dashboard/components/EmptyDataTemplate";
import { useSelector } from "react-redux";
import { selectAllSolution, selectAllStandard } from "reducer/presetReducer";

const LibraryContractContainer = ({ selectedType, contracts }) => {
  //TODO: 빈 contracts 경우 처리

  const [filteredContracts, setFilteredContracts] = useState([]);
  const solutions = useSelector(selectAllSolution);
  const standards = useSelector(selectAllStandard);

  const isAllChecked = useMemo(() => {
    return !CONTRACT_ERC.reduce((acc, cur) => {
      return Boolean(selectedType.find((el) => el.value === cur.value)) || acc;
    }, false);
  }, [selectedType]);

  useEffect(() => {
    if (selectedType.length === 0 || isAllChecked) {
      setFilteredContracts(contracts);
    } else {
      const filteredContracts = contracts.filter((el) => selectedType.find((type) => type.value === el.ercType));
      setFilteredContracts(filteredContracts);
    }
  }, [selectedType, isAllChecked, contracts]);

  return (
    <div className="library-contract-container">
      {contracts.length !== 0 ? (
        filteredContracts.map((contract) => {
          return (
            <LibraryContractCard
              key={contract.contract_id}
              contract={contract}
              standards={standards}
              solutions={solutions}
            />
          );
        })
      ) : (
        <EmptyDataTemplate>Sorry, we couldn’t find any results</EmptyDataTemplate>
      )}
    </div>
  );
};

export default LibraryContractContainer;
