import _ from "lodash";
import {
  VC__is_empty_value,
  VC__is_equal_or_less_than,
  VC__is_less_than,
  VC__is_more_than,
  VC__is_not_address,
  VC__is_not_capital_letter,
  VC__is_not_same_number,
  VC__is_text_exceed_limit,
} from "../../../../../modules/validation/validationCondition";
import { commaRemovalConvertor } from "utils/convert.utils";

export function VALIDATION__NETWORK_name(name) {
  return [
    {
      condition: !VC__is_empty_value(name),
      message: "",
    },
    {
      condition: !VC__is_text_exceed_limit(name, 20),
      message: "Network name must be up to 20 characters long",
    },
  ];
}

export function VALIDATION__NETWORK_plan(plan) {
  return [
    {
      condition: !VC__is_empty_value(plan),
      message: "",
    },
  ];
}

export function VALIDATION__NETWORK_coin_name(name) {
  return [
    {
      condition: !VC__is_empty_value(name),
      message: "",
    },
    {
      condition: !VC__is_text_exceed_limit(name, 50),
      message: "Coin name must be up to 50 characters long",
    },
  ];
}

export function VALIDATION__NETWORK_coin_symbol(symbol) {
  return [
    {
      condition: !VC__is_empty_value(symbol),
      message: "",
    },
    {
      condition: !VC__is_not_capital_letter(symbol),
      message: "Enter no more than 10 characters, including English capital letters and spaces",
    },
    {
      condition: !VC__is_text_exceed_limit(symbol, 10),
      message: "Enter no more than 10 characters, including English capital letters and spaces",
    },
  ];
}

export function VALIDATION__NETWORK_coin_image(image) {
  return [
    {
      condition: !VC__is_empty_value(image),
      message: "Coin image must be set",
    },
  ];
}

export function VALIDATION__NETWORK_initial_supply(supply) {
  return [
    {
      condition: !VC__is_empty_value(supply),
      message: "",
    },
    {
      condition: !VC__is_equal_or_less_than(commaRemovalConvertor(supply), 0),
      message: "Enter more than 0 for node staking",
    },
  ];
}

// ------------------------------------------------------------

export function VALIDATION__NETWORK_algirothm(algorithm) {
  return [
    {
      condition: !VC__is_empty_value(algorithm),
      message: "",
    },
  ];
}

// ------------------------------------------------------------

export function VALIDATION__NETWORK_fee(fee, supply) {
  return [
    {
      condition: !VC__is_empty_value(fee),
      message: "",
    },
    {
      condition: !VC__is_less_than(commaRemovalConvertor(fee), 0),
      message: "Enter a value of 0 or more",
    },
    {
      condition: !VC__is_more_than(commaRemovalConvertor(fee), commaRemovalConvertor(supply)),
      message: "Enter a value less than the initial supply",
    },
  ];
}

export function VALIDATION__NETWORK_inflation_rate(rate) {
  return [
    {
      condition: !VC__is_empty_value(rate),
      message: "",
    },
    {
      condition: !VC__is_less_than(Number(rate), 0),
      message: "Enter a value of 0 or more",
    },
  ];
}

export function VALIDATION__NETWORK_disinflation_rate(rate) {
  return [
    {
      condition: !VC__is_empty_value(rate),
      message: "",
    },
    {
      condition: !VC__is_equal_or_less_than(Number(rate), 0),
      message: "Enter a number greater than 0 and less than 100",
    },
    {
      condition: !VC__is_more_than(Number(rate), 100),
      message: "Enter a number greater than 0 and less than 100",
    },
  ];
}

//------------------------------------------------------------------------------------

export function VALIDATION__NETWORK_foundation_name(list) {
  return [
    {
      condition: Boolean(!list.map((el) => VC__is_empty_value(el.name)).find((el) => el)),
      message: "Please enter 'Foundation Name'",
    },
    {
      condition: Boolean(!list.map((el) => VC__is_text_exceed_limit(el.name, 20)).find((el) => el)),
      message: "Name must be up to 20 characters long",
    },
  ];
}

export function VALIDATION__NETWORK_foundation_address(list) {
  const uniqByAddressList = _.uniqBy(list.map((el) => el.address));
  return [
    {
      condition: Boolean(!list.map((el) => VC__is_empty_value(el.address)).find((el) => el)),
      message: "Please enter 'Foundation Address'",
    },
    {
      condition: Boolean(!list.map((el) => VC__is_not_address(el.address)).find((el) => el)),
      message: "Address must start with ‘0x’ and be 42 characters long",
    },
    {
      condition: !VC__is_not_same_number(list.length, uniqByAddressList.length),
      message: "Duplicate key address exists",
    },
  ];
}

export function VALIDATION__NETWORK_foundation_distribution(list) {
  return [
    {
      condition: Boolean(!list.map((el) => VC__is_empty_value(el.distributionPercent)).find((el) => el)),
      message: "Please enter 'Foundation Distribution'",
    },
    {
      condition: Boolean(!list.map((el) => VC__is_less_than(Number(el.distributionPercent), 0)).find((el) => el)),
      message: "For Distribution, enter a value greater than 0 and less than 100",
    },
    {
      condition: Boolean(!list.map((el) => VC__is_more_than(Number(el.distributionPercent), 100)).find((el) => el)),
      message: "For Distribution, enter a value greater than 0 and less than 100",
    },
  ];
}

export function VALIDATION__NETWORK_ecosystem(list) {
  const ecosystem = list[0];
  return [
    {
      condition: !VC__is_empty_value(ecosystem.distributionPercent),
      message: "Please enter 'Staking amount to Nodes Distribution'",
    },
    {
      condition: !VC__is_equal_or_less_than(ecosystem.distributionPercent, 0),
      message: "In order to stake coins to nodes, 'Staking amount to Nodes' must be a number greater than zero",
    },
  ];
}

export function VALIDATION__NETWORK_foundation_total_alloc_percent(value) {
  return [
    {
      condition: !VC__is_not_same_number(value, 100),
      message: "Total Distribution value must be 100",
    },
  ];
}

//------------------------------------------------------------------------------------

export function VALIDATION__NETWORK_keystore_file(list) {
  const nodeAddress = list.map((el) => el.address);
  const uniqByNodeAddress = _.uniqBy(list.map((el) => el.address));

  return [
    {
      condition: Boolean(!list.map((el) => VC__is_empty_value(el.keyStoreFile)).find((el) => el)),
      message: "Please upload keystore file",
    },
    {
      condition: !VC__is_not_same_number(nodeAddress.length, uniqByNodeAddress.length),
      message: "Please upload different keystore files to each node",
    },
  ];
}

export function VALIDATION__NETWORK_node_staking_amount(list) {
  return [
    {
      condition: Boolean(!list.map((el) => VC__is_empty_value(el.mmcBalancePercent)).find((el) => el)),
      message: "Please enter Allocation 'Staking Amount'",
    },
    {
      condition: Boolean(!list.map((el) => VC__is_equal_or_less_than(Number(el.mmcBalancePercent), 0)).find((el) => el)),
      message: "Enter a value greater than 0 and less than 100",
    },
    {
      condition: Boolean(!list.map((el) => VC__is_more_than(Number(el.mmcBalancePercent), 100)).find((el) => el)),
      message: "Enter a value greater than 0 and less than 100",
    },
  ];
}

export function VALIDATION__NETWORK_node_total_alloc_percent(value) {
  return [
    {
      condition: !VC__is_not_same_number(value, 100),
      message: "Total Distribution value must be 100",
    },
  ];
}

export function VALIDATION__NETWORK_allocation_address_duplicate(foundationAllocList, nodeAllocList) {
  const allocList = [].concat(foundationAllocList).concat(nodeAllocList);

  const addressList = allocList.map((el) => el.address);
  const validAddressList = addressList.filter((el) => el).map((el) => el.toLowerCase());
  const uniqByAddressList = _.uniqBy(validAddressList);

  return [
    {
      condition: !VC__is_not_same_number(addressList.length, uniqByAddressList.length),
      message: "Please enter different addresses in Step 4 'Coin pre-allocation' and the wallet address of the keystore file uploaded in Step 3 'Node'",
    },
  ];
}
