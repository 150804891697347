import React from "react";
import "./InitialContentTemplate.scss";
import DivideLine from "view/components/_atom/line/DivideLine";

type InitialContentTemplateProps = {
  title: string;
  description: string;
  children: any;
};
const InitialContentTemplate = (props: InitialContentTemplateProps) => {
  const { title, description, children } = props;
  return (
    <div className="initial-content-template">
      <div className="initial-content-template-wrapper">
        <div className="initial-content-template-title">{title}</div>
        <div className="initial-content-template-description">{description}</div>
      </div>
      <DivideLine style={{ margin: "40px 0" }} />
      <div className="initial-content-template-body">{children}</div>
    </div>
  );
};

export default InitialContentTemplate;
