import { useCallback, useMemo, useState } from "react";
import "./ManageNetwork.scss";
import { useNavigate, useParams } from "react-router-dom";
import ImageUpload from "../../../../components/_atom/file/ImageUpload";
import PageLoading from "../../../../components/_atom/loading/PageLoading";
import StatusDetail, { DEFAULT_LABEL_STYLE } from "../../../../components/_atom/label/StatusDetail";
import { NETWORK_ACCESS_STATUS, NETWORK_CHART_COLOR, NETWORK_CHART_INSUFFICIENT_COLOR, NETWORK_ENVIRONMENT_LIST } from "../constants/networkConstants";
import { errorAlert, reduceAddress } from "../../../../../utils/Utils";
import iconDeleteRed from "../../../../assets/images/atom/icon_delete_red.png";
import toast from "react-hot-toast";
import { Cell, Pie, PieChart } from "recharts";
import Table from "legacy-common/atom/table/Table";
import {
  getAdditionalFromBesuNetworkAction,
  getNetworkBlockchainEngineAction,
  getNetworkDetailAction,
  getNetworkBasicInfoAction,
  getNodeFromBesuNetworkAction,
  getNodeFromEquilibriumNetworkAction,
  submitNetworkAction,
  getAdditionalFromEquilibriumNetworkAction,
} from "action/networkAction";
import { releaseNetworkAction } from "../../../../../action/networkAction";
import PageArticle from "view/components/_template/page/PageArticle";
import PageTitle from "../../../../components/_molecules/page/PageTitle";
import useGetSelectedProject from "../../../../../hooks/useGetSelectedProject";
import useLoading from "hooks/useLoading";
import NodePresetBox from "../component/atom/NodePresetBox";
import BasicButton from "view/components/_atom/button/BasicButton";
import ButtonContainer from "view/components/_atom/container/ButtonContainer";
import { ButtonSize } from "view/components/_atom/button/StyledButton";
import { ButtonTheme } from "view/components/_atom/button/StyledButton";
import useInit from "../../../../../hooks/useInit";
import NetworkDeleteModal from "../component/atom/NetworkDeleteModal";
import { numberConvertor } from "utils/convert.utils";
import { ColumnManageField, ManageFieldContainer, ManageFieldLabel, ManageFieldWrapper, RowManageField } from "view/components/_atom/section/ManageField";
import DivideLine from "view/components/_atom/line/DivideLine";
import BlockchainEngineInfo from "../component/atom/BlockchainEngineInfo";
import chartIcon from "../../../../assets/images/Icon_chart.png";
import nodeIcon from "../../../../assets/images/service/Icon_SNB_node.png";
import allocationIcon from "../../../../assets/images/Icon_allocation.png";
import { NETWORK_ENGINE_TYPE_EQUILIBRIUM__1_1_6__PON, NETWORK_ENGINE_TYPE_ETHEREUM__1_1_6__CLIQUE } from "../constants/network.data";

const ManageNetwork = () => {
  const networkId = useParams().id;
  const navigate = useNavigate();
  const { isOwner } = useGetSelectedProject();

  const [networkDetail, setNetworkDetail] = useState();

  const [networkDeleteModalOpen, setNetworkDeleteModalOpen] = useState(false);

  const isExternal = useMemo(() => Boolean(networkDetail?.network?.externalNetworkId), [networkDetail]);

  const getNetworkDetail_ = async () => {
    const { result, error } = await getNetworkDetailAction(networkId);

    if (result) {
      setNetworkDetail(result);
    }

    if (error) {
      errorAlert(error.data.message);
      if (error.data.status === "401") {
        navigate("/network/mainnet/manage");
      }
      if (error.data.status === "404") {
        navigate("/network/mainnet/manage");
      }
      return;
    }
  };

  const submitNetwork_ = async () => {
    if (networkDetail.mainStatus === 0) {
      toast.error("Please check the reason for rejection, correct it accordingly and resubmit it");
      return;
    }

    const { result, error } = await submitNetworkAction(networkId);

    if (result) {
      toast.success("Submission successfully completed");
      setNetworkDetail(result);
    }

    if (error) {
      errorAlert(error.data.message);
      return;
    }
  };

  const statusData = useMemo(() => {
    const networkMainStatus = networkDetail?.mainStatus;
    switch (networkMainStatus) {
      case 0:
        return {
          text: DEFAULT_LABEL_STYLE.find((el) => el.status === networkMainStatus).text,
          description: networkDetail?.rejectReason?.rejectReason,
        };
      default:
        return {
          text: DEFAULT_LABEL_STYLE.find((el) => el.status === networkMainStatus)?.text,
          description: DEFAULT_LABEL_STYLE.find((el) => el.status === networkMainStatus)?.description,
        };
    }
  }, [networkDetail]);

  const deployNetwork_ = async () => {
    const { result, error } = await releaseNetworkAction(networkId);

    if (result) {
      toast.success(`Successfully deployed`);
      navigate("/network/mainnet/manage");
    }

    if (error) {
      errorAlert(error.data.message);
      return;
    }
  };

  const deleteButtonHandler = async () => {
    if (!isOwner) {
      errorAlert("Only project owner can delete network");
      return;
    }
    setNetworkDeleteModalOpen(true);
  };

  const onClickManageNode = () => {
    navigate("/network/node/manage");
  };

  const { loading } = useLoading({
    dependency: [networkId],
    synchronousFunction: async () => {
      await getNetworkDetail_();
    },
  });

  if (loading) {
    return <PageLoading />;
  }

  return (
    <PageArticle>
      {networkDeleteModalOpen && (
        <NetworkDeleteModal
          isOpen={networkDeleteModalOpen}
          setIsOpen={setNetworkDeleteModalOpen}
          networkId={Number(networkId)}
          networkLabel={networkDetail.network.label}
          mainStatus={networkDetail.mainStatus}
        />
      )}
      <PageTitle size={810} title={`Network Detail`} />
      <div className="page-layout-810">
        {(Boolean(networkDetail?.mainStatus) || networkDetail?.mainStatus === 0) && <StatusDetail status={networkDetail?.mainStatus} data={statusData} />}
        <NetworkBasicInfo networkId={Number(networkId)} />
        <NetworkBlockchainEngine networkId={Number(networkId)} />
        {networkDetail?.networkType === NETWORK_ENGINE_TYPE_EQUILIBRIUM__1_1_6__PON && <NetworkEquilibriumNodeInfo networkId={Number(networkId)} />}
        {networkDetail?.networkType === NETWORK_ENGINE_TYPE_ETHEREUM__1_1_6__CLIQUE && <NetworkBesuNodeInfo networkId={Number(networkId)} />}
        {networkDetail?.networkType === NETWORK_ENGINE_TYPE_EQUILIBRIUM__1_1_6__PON && <NetworkEquilibriumAdditional networkId={Number(networkId)} />}
        {networkDetail?.networkType === NETWORK_ENGINE_TYPE_ETHEREUM__1_1_6__CLIQUE && <NetworkBesuAdditional networkId={Number(networkId)} />}
        {/* -------------------------- 버튼 영역 -------------------------- */}

        {!isExternal && networkDetail?.mainStatus <= 1 && (
          <div className="network-button-container">
            <ButtonContainer>
              <BasicButton size={ButtonSize.ICON} theme={ButtonTheme.MINOR} onClick={() => deleteButtonHandler()}>
                <img src={iconDeleteRed} style={{ width: 18, objectFit: "contain" }} alt={"delete"} />
              </BasicButton>
              <BasicButton
                size={ButtonSize.LARGE}
                theme={ButtonTheme.SECONDARY}
                onClick={() => navigate(`/network/mainnet/manage/${networkDetail.network.id}/edit`)}
              >
                Edit
              </BasicButton>
              <BasicButton size={ButtonSize.LARGE} onClick={() => submitNetwork_()}>
                Submit
              </BasicButton>
            </ButtonContainer>
          </div>
        )}

        {!isExternal && networkDetail?.mainStatus === 3 && (
          <div className="network-button-container">
            <ButtonContainer>
              <BasicButton size={ButtonSize.ICON} theme={ButtonTheme.MINOR} onClick={() => deleteButtonHandler()}>
                <img src={iconDeleteRed} style={{ width: 18, objectFit: "contain" }} alt={"delete"} />
              </BasicButton>
              <BasicButton size={ButtonSize.LARGE} onClick={() => deployNetwork_()}>
                Deploy
              </BasicButton>
            </ButtonContainer>
          </div>
        )}

        {!isExternal && networkDetail?.mainStatus === 5 && (
          <div className="network-button-container">
            <ButtonContainer>
              {isOwner && (
                <BasicButton size={ButtonSize.ICON} theme={ButtonTheme.MINOR} onClick={() => deleteButtonHandler()}>
                  <img src={iconDeleteRed} style={{ width: 18, objectFit: "contain" }} alt={"delete"} />
                </BasicButton>
              )}
              <BasicButton size={ButtonSize.LARGE} onClick={() => onClickManageNode()}>
                Manage Node
              </BasicButton>
            </ButtonContainer>
          </div>
        )}
      </div>
    </PageArticle>
  );
};

export default ManageNetwork;

const NetworkBasicInfo = (props) => {
  const { networkId } = props;

  const [networkBasicInfo, setNetworkBasicInfo] = useState();

  const getNetworkBasicInfo_ = async () => {
    const { result, error } = await getNetworkBasicInfoAction(networkId);

    if (result) {
      setNetworkBasicInfo(result);
    }

    if (error) {
      console.error(error);
    }
  };

  useInit(() => getNetworkBasicInfo_());

  return (
    <ManageFieldContainer icon={chartIcon} title="Basic Info">
      <ManageFieldWrapper title="Basic info">
        <RowManageField>
          <ManageFieldLabel label="Network ID" />
          <span>{networkBasicInfo?.network?.id ?? "-"}</span>
        </RowManageField>
        <RowManageField>
          <ManageFieldLabel label="Network Name" />
          <span>{networkBasicInfo?.network?.label ?? "-"}</span>
        </RowManageField>
        <RowManageField>
          <ManageFieldLabel label="Coin Name" />
          <span>{networkBasicInfo?.currency?.name ?? "-"}</span>
        </RowManageField>
        <RowManageField>
          <ManageFieldLabel label="Coin Symbol" />
          <span>{networkBasicInfo?.currency?.symbol ?? "-"}</span>
        </RowManageField>
        <RowManageField>
          <ManageFieldLabel label="Coin Image" />
          <ImageUpload
            previewStyle={{ width: 130, height: 130, borderRadius: "100%" }}
            style={{ alignItems: "normal" }}
            image={networkBasicInfo?.currency?.image}
            disabled={true}
          />
        </RowManageField>
        <RowManageField>
          <ManageFieldLabel label="Initial Supply" />
          <span>
            {numberConvertor(networkBasicInfo?.microChainSetting?.initialSupply) ?? "-"} {networkBasicInfo?.currency?.symbol ?? ""}
          </span>
        </RowManageField>
      </ManageFieldWrapper>
      <DivideLine style={{ margin: "20px 0" }} />
      <ManageFieldWrapper title="Operation">
        <RowManageField>
          <ManageFieldLabel label="Environment" />
          <span>{NETWORK_ENVIRONMENT_LIST.find((el) => el.value === networkBasicInfo?.microChainSetting?.isTestnet)?.label || "-"}</span>
        </RowManageField>
        <RowManageField>
          <ManageFieldLabel label="Accessibility" />
          <span>{NETWORK_ACCESS_STATUS.find((el) => el.value === networkBasicInfo?.microChainSetting?.isContractUploadAvailability)?.label || "-"}</span>
        </RowManageField>
      </ManageFieldWrapper>
    </ManageFieldContainer>
  );
};

const NetworkBlockchainEngine = (props) => {
  const { networkId } = props;

  const [blockchainEngineData, setBlockchainEngineData] = useState();

  const getNetworkBlockchainEngine_ = async () => {
    const { result, error } = await getNetworkBlockchainEngineAction(networkId);

    if (result) {
      setBlockchainEngineData(result);
    }

    if (error) {
    }
  };

  useInit(() => getNetworkBlockchainEngine_());

  return (
    <ManageFieldContainer icon={chartIcon} title="Blockchain Engine">
      <ManageFieldWrapper title="Engine">
        <ColumnManageField>
          <ManageFieldLabel label="Protocol & Blockchain Client" />
          <BlockchainEngineInfo engineInfo={blockchainEngineData?.chainClient} networkData={blockchainEngineData?.networkDetail} />
        </ColumnManageField>
        <RowManageField>
          <ManageFieldLabel label="Consensus Algorithm" />
          <span>{blockchainEngineData?.algorithm?.name ?? "-"}</span>
        </RowManageField>
        <RowManageField>
          <ManageFieldLabel label="Software" />
          <span>{blockchainEngineData?.chainClientVersion?.version ?? "-"}</span>
        </RowManageField>
      </ManageFieldWrapper>
    </ManageFieldContainer>
  );
};

const NetworkEquilibriumNodeInfo = (props) => {
  const { networkId } = props;

  const [networkNodeData, setNetworkNodeData] = useState();

  const colGroup = useMemo(() => [25, 30, 45].map((el) => ({ style: { width: `${el}%` } })), []);

  const nodeAllocationHeader = useMemo(
    () => [
      { title: "Name", accessor: "name" },
      { title: "Keystore File", accessor: "keystoreFile" },
      { title: "Staking Amount", accessor: "stakingAmount" },
    ],
    []
  );
  const nodeAllocRenderer = useCallback(
    (data) => ({
      name: <span style={{ fontSize: 14, fontWeight: 500 }}>{data.name ?? "-"}</span>,
      keystoreFile: <span style={{ fontSize: 14 }}>{data.address ? reduceAddress(data.address) : "-"}</span>,
      stakingAmount: (
        <span style={{ fontSize: 14 }}>{`${data.mmcBalancePercent}% (${numberConvertor(data.mmcBalance)} ${networkNodeData?.currency?.symbol})`}</span>
      ),
    }),
    [networkNodeData?.nodeAlloc, networkNodeData?.currency?.symbol]
  );

  const getNodeFromEquilibriumNetwork_ = async () => {
    const { result, error } = await getNodeFromEquilibriumNetworkAction(networkId);

    if (result) {
      setNetworkNodeData(result);
    }

    if (error) {
      console.error(error.data.message);
    }
  };

  useInit(() => getNodeFromEquilibriumNetwork_());

  return (
    <ManageFieldContainer icon={nodeIcon} title="Node">
      <ManageFieldWrapper title="Node option">
        <NodePresetBox
          name={networkNodeData?.networkPlan?.name ?? ""}
          description={networkNodeData?.networkPlan?.description ?? ""}
          equalizerCount={networkNodeData?.networkPlan?.equalizerCount ?? ""}
          lightNodeCount={networkNodeData?.networkPlan?.lightNodeCount ?? ""}
          bonusCredit={networkNodeData?.networkPlan?.bonusCredit ?? ""}
        />
      </ManageFieldWrapper>
      <DivideLine style={{ margin: "20px 0" }} />
      <ManageFieldWrapper title="Node setting">
        <RowManageField>
          <Table headers={nodeAllocationHeader} colGroup={colGroup} data={networkNodeData?.nodeAlloc} renderer={nodeAllocRenderer} />
        </RowManageField>
        <RowManageField>
          <div className="manage-network-total-allocated">
            <div className="manage-network-total-allocated-label">Total amount of coins to be allocated to nodes</div>
            <div className="manage-network-total-allocated-value">
              {Boolean(networkNodeData?.totalBalance) ? (
                <>
                  <div className="manage-network-total-allocated-value-amount">{networkNodeData?.totalBalance ?? "-"}</div>
                  <div className="manage-network-total-allocated-value-symbol">{networkNodeData?.currency?.symbol}</div>
                </>
              ) : (
                "-"
              )}
            </div>
          </div>
        </RowManageField>
      </ManageFieldWrapper>
      <DivideLine style={{ margin: "20px 0" }} />
      <ManageFieldWrapper title="Node Operation">
        <RowManageField>
          <ManageFieldLabel label="Gas Fee" />
          <span>{`${networkNodeData?.microChainSetting?.fee ?? "-"} ${networkNodeData?.currency?.symbol}`}</span>
        </RowManageField>
        <RowManageField>
          <ManageFieldLabel label="Inflation Rate" />
          <span>{`${networkNodeData?.microChainSetting?.inflationRate ?? "-"} %`}</span>
        </RowManageField>
        <RowManageField>
          <ManageFieldLabel label="Disinflation Rate" />
          <span>{`${networkNodeData?.microChainSetting?.disInflationRate ?? "-"} %`}</span>
        </RowManageField>
      </ManageFieldWrapper>
    </ManageFieldContainer>
  );
};

const NetworkBesuNodeInfo = (props) => {
  const { networkId } = props;

  const [networkNodeData, setNetworkNodeData] = useState();

  const colGroup = useMemo(() => [25, 30, 45].map((el) => ({ style: { width: `${el}%` } })), []);

  const nodeAllocationHeader = useMemo(
    () => [
      { title: "Name", accessor: "name" },
      { title: "Keystore File", accessor: "address" },
      { title: "Validator", accessor: "validator" },
    ],
    []
  );
  const nodeAllocRenderer = useCallback(
    (data) => ({
      name: <span style={{ fontSize: 14, fontWeight: 500 }}>{data.name ?? "-"}</span>,
      address: <span style={{ fontSize: 14 }}>{data.address ? reduceAddress(data.address) : "-"}</span>,
      validator: <span style={{ fontSize: 14 }}>{`Validator`}</span>,
    }),
    [networkNodeData?.nodeAlloc]
  );

  const getNodeFromBesuNetwork_ = async () => {
    const { result, error } = await getNodeFromBesuNetworkAction(networkId);

    if (result) {
      setNetworkNodeData(result);
    }

    if (error) {
      console.error(error.data.message);
    }
  };

  useInit(() => getNodeFromBesuNetwork_());

  return (
    <ManageFieldContainer icon={nodeIcon} title="Node">
      <ManageFieldWrapper title="Node option">
        <NodePresetBox
          name={networkNodeData?.networkPlan?.name ?? ""}
          description={networkNodeData?.networkPlan?.description ?? ""}
          equalizerCount={networkNodeData?.networkPlan?.equalizerCount ?? ""}
          lightNodeCount={networkNodeData?.networkPlan?.lightNodeCount ?? ""}
          bonusCredit={networkNodeData?.networkPlan?.bonusCredit ?? ""}
        />
      </ManageFieldWrapper>
      <DivideLine style={{ margin: "20px 0" }} />
      <ManageFieldWrapper title="Node setting">
        <RowManageField>
          <Table headers={nodeAllocationHeader} colGroup={colGroup} data={networkNodeData?.nodeAlloc} renderer={nodeAllocRenderer} />
        </RowManageField>
      </ManageFieldWrapper>
    </ManageFieldContainer>
  );
};

const NetworkEquilibriumAdditional = (props) => {
  const { networkId } = props;

  const [currency, setCurrency] = useState();
  const [alloc, setAlloc] = useState([]);

  const colGroup = useMemo(() => [25, 30, 45].map((el) => ({ style: { width: `${el}%` } })), []);

  const allocationHeader = useMemo(
    () => [
      { title: "Name", accessor: "name" },
      { title: "Address", accessor: "address" },
      { title: "Distribution", accessor: "distribution" },
    ],
    []
  );

  const allocRenderer = useCallback(
    (data) => ({
      name: <span style={{ fontSize: 14, fontWeight: 500 }}>{data.name ?? "-"}</span>,
      address: <span style={{ fontSize: 14 }}>{data.address ? reduceAddress(data.address) : "-"}</span>,
      distribution: <span style={{ fontSize: 14 }}>{`${data.distributionPercent}% (${numberConvertor(data.mmcBalance)} ${currency?.symbol})`}</span>,
    }),
    [alloc, currency]
  );

  const getAdditionalFromEquilibriumNetwork_ = async () => {
    const { result, error } = await getAdditionalFromEquilibriumNetworkAction(networkId);

    if (result) {
      const { currency, alloc } = result;
      setCurrency(currency);
      setAlloc(alloc);
    }

    if (error) {
      console.error(error.data.message);
    }
  };

  useInit(() => getAdditionalFromEquilibriumNetwork_());

  return (
    <ManageFieldContainer icon={allocationIcon} title="Additionals">
      <ManageFieldWrapper title="Pre-allocation">
        <Table headers={allocationHeader} colGroup={colGroup} data={alloc} renderer={allocRenderer} noData={"No value set."} />
        {alloc.length > 0 && (
          <div className="manage-network-visualization-allocation">
            <PieChart width={400} height={400}>
              <Pie
                data={alloc}
                cx={"50%"}
                cy={"50%"}
                outerRadius={100}
                innerRadius={50}
                fill="#fff"
                dataKey="distributionPercent"
                startAngle={90}
                endAngle={-270}
                label={(value) => {
                  const { cx, cy, midAngle, innerRadius, outerRadius, index } = value || {};
                  const RADIAN = Math.PI / 180;
                  const radius = 25 + innerRadius + (outerRadius - innerRadius);
                  const x = cx + radius * Math.cos(-midAngle * RADIAN);
                  const y = cy + radius * Math.sin(-midAngle * RADIAN);
                  return (
                    <text x={x} y={y} dominantBaseline="central" textAnchor={x > cx ? "start" : "end"} className="chart-label" style={{ fill: value.fill }}>
                      <tspan x={x} y={y} className="chart-label-name">
                        {alloc[index]?.name}
                      </tspan>
                      <tspan x={x} y={y + 16} className="chart-label-percent">{`(${value.distributionPercent}%)`}</tspan>
                    </text>
                  );
                }}
                labelLine={false}
              >
                {alloc?.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.name === "External network" ? NETWORK_CHART_INSUFFICIENT_COLOR : NETWORK_CHART_COLOR[index]} />
                ))}
              </Pie>
            </PieChart>
          </div>
        )}
      </ManageFieldWrapper>
    </ManageFieldContainer>
  );
};

const NetworkBesuAdditional = (props) => {
  const { networkId } = props;

  const [currency, setCurrency] = useState();
  const [preAlloc, setPreAlloc] = useState([]);

  const colGroup = useMemo(() => [25, 30, 45].map((el) => ({ style: { width: `${el}%` } })), []);

  const preAllocationHeader = useMemo(
    () => [
      { title: "Name", accessor: "name" },
      { title: "Address", accessor: "address" },
      { title: "Distribution", accessor: "distribution" },
    ],
    []
  );

  const preAllocRenderer = useCallback(
    (data) => ({
      name: <span style={{ fontSize: 14, fontWeight: 500 }}>{data.name ?? "-"}</span>,
      address: <span style={{ fontSize: 14 }}>{data.address ? reduceAddress(data.address) : "-"}</span>,
      distribution: <span style={{ fontSize: 14 }}>{`${data.distributionPercent}% (${numberConvertor(data.mmcBalance)} ${currency?.symbol})`}</span>,
    }),
    [preAlloc, currency]
  );

  const getAdditionalFromBesuNetwork_ = async () => {
    const { result, error } = await getAdditionalFromBesuNetworkAction(networkId);

    if (result) {
      const { currency, preAlloc } = result;
      setCurrency(currency);
      setPreAlloc(preAlloc);
    }

    if (error) {
      console.error(error.data.message);
    }
  };

  useInit(() => getAdditionalFromBesuNetwork_());

  return (
    <ManageFieldContainer icon={allocationIcon} title="Additionals">
      <ManageFieldWrapper title="Pre-allocation">
        <Table headers={preAllocationHeader} colGroup={colGroup} data={preAlloc} renderer={preAllocRenderer} noData={"No value set."} />
        <div className="manage-network-visualization-allocation">
          <PieChart width={400} height={400}>
            <Pie
              data={preAlloc}
              cx={"50%"}
              cy={"50%"}
              outerRadius={100}
              innerRadius={50}
              fill="#fff"
              dataKey="distributionPercent"
              startAngle={90}
              endAngle={-270}
              label={(value) => {
                const { cx, cy, midAngle, innerRadius, outerRadius, index } = value || {};
                const RADIAN = Math.PI / 180;
                const radius = 25 + innerRadius + (outerRadius - innerRadius);
                const x = cx + radius * Math.cos(-midAngle * RADIAN);
                const y = cy + radius * Math.sin(-midAngle * RADIAN);
                return (
                  <text x={x} y={y} dominantBaseline="central" textAnchor={x > cx ? "start" : "end"} className="chart-label" style={{ fill: value.fill }}>
                    <tspan x={x} y={y} className="chart-label-name">
                      {preAlloc[index]?.name}
                    </tspan>
                    <tspan x={x} y={y + 16} className="chart-label-percent">{`(${value.distributionPercent}%)`}</tspan>
                  </text>
                );
              }}
              labelLine={false}
            >
              {preAlloc?.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.name === "External network" ? NETWORK_CHART_INSUFFICIENT_COLOR : NETWORK_CHART_COLOR[index]} />
              ))}
            </Pie>
          </PieChart>
        </div>
      </ManageFieldWrapper>
    </ManageFieldContainer>
  );
};
