import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  connectInfo,
  removeConnectedInfo,
  setConnectedInfo,
} from "../../store/redux/EQconnect/EQConnectSlice";
import { requestAddressToExtension } from "../EQExtension/utils/messageUtils";
import BasicButton from "view/components/_atom/button/BasicButton";
import {
  ButtonSize,
  ButtonTheme,
} from "view/components/_atom/button/StyledButton";

const EQConnectWalletButton = ({ ...rest }) => {
  const dispatch = useDispatch();
  const { address } = useSelector(connectInfo);
  const isConnected = useMemo(() => Boolean(address), [address]);

  const onClickButton = async () => {
    if (!isConnected) {
      requestAddressToExtension();
    } else {
      disconnectWallet();
    }
  };

  const disconnectWallet = () => {
    dispatch(removeConnectedInfo());
    sessionStorage.removeItem("address");
    sessionStorage.removeItem("type");
    sessionStorage.removeItem("account");
  };

  const connectButtonRenderer = useMemo(() => {
    if (Boolean(address)) {
      return (
        <div className="flex-column">
          <div>Disconnect</div>
          <div style={{ fontSize: 12, color: "#AFAFAF" }}>{`${address.slice(
            0,
            6
          )}...${address.slice(-5)}`}</div>
        </div>
      );
    } else {
      return <div>Connect</div>;
    }
  }, [address]);

  useEffect(() => {
    const sessionAddress = sessionStorage.getItem("address");
    const sessionType = sessionStorage.getItem("type");
    dispatch(
      setConnectedInfo({
        address: sessionAddress,
        type: sessionType,
      })
    );
  }, []);

  // return <Button className={address ? "border-blue" : "main"} {...rest} onClick={onClickButton} text={connectButtonRenderer} />;
  return (
    <BasicButton
      size={ButtonSize.LARGE}
      theme={address ? ButtonTheme.SECONDARY : ButtonTheme.PRIMARY}
      onClick={onClickButton}
      {...rest}
    >
      {connectButtonRenderer}
    </BasicButton>
  );
};

export default EQConnectWalletButton;
