import React, { useEffect, useState } from "react";
import css from "./MostUsedRequestsChart.scss";
import { DashboardMostUsedRequestVolumeType } from "../constants/dashboard.type";
import { FromTick } from "server/type/projectType";
import { getMostUsedRequestsAction } from "../../../../action/dashboardAction";
import { CHART_INTERVAL_OPTIONS, DASHBOARD_CHART_INTERVAL_COUNT, DASHBOARD_MOST_USED_REQUESTS_COLOR, EMPTY_CHART_TEXT } from "../constants/dashboard.data";
import { numberConvertor } from "utils/convert.utils";
import { ResponsiveContainer, Tooltip, LineChart, XAxis, YAxis, CartesianGrid, Legend, Line } from "recharts";
import EmptyDataTemplate from "./EmptyDataTemplate";
import useLoading from "hooks/useLoading";
import ChartLoading from "view/components/_atom/loading/ChartLoading";
import SectionContainer from "view/components/_atom/section/SectionContainer";
import chartIcon from "../assets/Icon_dashboard_chart.png";
import { bindStyle } from "view/styles/classNames";

type CustomChartProps = {
  payload?: any;
};

const MostUsedRequestsChart = () => {
  const cn = bindStyle(css);

  const [mostUsedRequests, setMostUsedRequests] = useState<DashboardMostUsedRequestVolumeType[]>([]);
  const [requestMethodList, setRequestMethodList] = useState<string[]>([]);
  const [intervalCount, setIntervalCount] = useState<number>(-1);

  const [selectedMostUsedRequestPeriodId, setSelectedMostUsedRequestPeriodId] = useState<FromTick>(FromTick.WEEK);

  const totalUsedRequestsInPeriod: number = mostUsedRequests.reduce(
    (acc, cur) => acc + requestMethodList.reduce((acc, currentKey) => acc + cur[currentKey], 0),
    0
  );

  const getMostUsedRequests_ = async (from: FromTick) => {
    const { result, error } = await getMostUsedRequestsAction(from);
    if (result) {
      setMostUsedRequests(result.data);
      setRequestMethodList(result.methodList);
    }
    if (error) {
    }
  };

  useEffect(() => {
    let interval;
    if (intervalCount !== -1) {
      interval = setInterval(() => {
        setIntervalCount((prev) => prev + 1);
      }, 1000);
      if (intervalCount === DASHBOARD_CHART_INTERVAL_COUNT) {
        setIntervalCount(-1);
        getMostUsedRequests_(selectedMostUsedRequestPeriodId).then(() => setIntervalCount(0));
        clearInterval(interval);
      }
    }

    return () => clearInterval(interval);
  }, [intervalCount]);

  useEffect(() => {
    setIntervalCount(-1);
  }, [selectedMostUsedRequestPeriodId]);

  const { loading } = useLoading({
    dependency: [selectedMostUsedRequestPeriodId],
    synchronousFunction: async () => {
      await getMostUsedRequests_(selectedMostUsedRequestPeriodId).then(() => setIntervalCount(0));
    },
  });

  return (
    <SectionContainer title="Most Used Requests" icon={chartIcon}>
      <div className="most-used-requests">
        {loading ? (
          <ChartLoading />
        ) : (
          <>
            {requestMethodList.length > 0 ? (
              <>
                <div className="total-request">
                  {numberConvertor(totalUsedRequestsInPeriod)} <div className="total-request-unit">(Total)</div>
                </div>
                <div className="most-used-requests-chart">
                  <ResponsiveContainer width={"100%"} height={"100%"}>
                    <LineChart data={mostUsedRequests}>
                      <XAxis dataKey={"date"} axisLine={{ stroke: "#d8d9d9" }} tickLine={{ stroke: "#d8d9d9" }} />
                      <YAxis axisLine={false} tickLine={false} width={44} />
                      <Tooltip
                        wrapperStyle={{ outline: "none" }}
                        content={<CustomMostUsedReqeustsChartTooltip />}
                        cursor={{ stroke: "#404248", strokeWidth: 1 }}
                      />
                      <CartesianGrid strokeDasharray="3 3" stroke={"#d8d9d9"} vertical={false} />
                      <Legend content={<CustomMostUsedRequestsChartLegend />} />
                      {requestMethodList.map((method: string, index: number) => (
                        <Line
                          type="monotone"
                          key={`most-used-requests-chart-line-${index}`}
                          dot={false}
                          activeDot={{ r: 4, fill: "#fff", strokeWidth: 2, stroke: `${DASHBOARD_MOST_USED_REQUESTS_COLOR[index]}` }}
                          dataKey={method}
                          stroke={DASHBOARD_MOST_USED_REQUESTS_COLOR[index]}
                        />
                      ))}
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              </>
            ) : (
              <EmptyDataTemplate>{EMPTY_CHART_TEXT}</EmptyDataTemplate>
            )}
          </>
        )}

        <div className="most-used-requests-options">
          {CHART_INTERVAL_OPTIONS.map((option) => (
            <div
              key={`most-used-requests-chart-interval-option-item-${option.id}`}
              className={cn("most-used-requests-options-item", { selected: selectedMostUsedRequestPeriodId === option.value })}
              onClick={() => setSelectedMostUsedRequestPeriodId(option.value)}
            >
              {option.label}
            </div>
          ))}
        </div>
      </div>
    </SectionContainer>
  );
};

const CustomMostUsedReqeustsChartTooltip = (props: CustomChartProps) => {
  const { payload } = props || {};

  return (
    <div className="most-used-requests-chart-custom-tooltip">
      {payload?.map((data: any) => {
        const { color, dataKey, value } = data;
        return (
          <div className="most-used-requests-chart-custom-tooltip-item" key={`most-used-requests-chart-${dataKey}`}>
            <div className="most-used-requests-chart-custom-tooltip-item-block" style={{ backgroundColor: color }} />
            <span className="most-used-requests-chart-custom-tooltip-item-label">{numberConvertor(value)}</span>
          </div>
        );
      })}
    </div>
  );
};

const CustomMostUsedRequestsChartLegend = (props: CustomChartProps) => {
  const { payload } = props || {};

  return (
    <div className="most-used-requests-chart-custom-legend">
      {payload.map((data: any) => {
        const { color, dataKey } = data;
        return (
          <div className="most-used-requests-chart-custom-legend-item" key={`most-used-requests-chart-legend-${dataKey}`} data-legend-key={dataKey}>
            <div className="most-used-requests-chart-custom-legend-item-icon" style={{ backgroundColor: color }} />
            <div className="most-used-requests-chart-custom-legend-item-label">{dataKey}</div>
          </div>
        );
      })}
    </div>
  );
};

export default MostUsedRequestsChart;
