import { getWallet20sFromServer } from "server/API/wallet20API";

export const getWallet20sParser = async (options?: {
  limit?: number;
  lastId?: number;
  contractIds?: number[];
}): Promise<
  {
    contract_id: number;
    decimals: number;
    token_20_id: number;
    total_amount: string;
  }[]
> => {
  const result = await getWallet20sFromServer({
    query: {
      ...(options?.limit && { limit: options.limit }),
      ...(options?.lastId && { lastId: options.lastId }),
      ...(options?.contractIds && { contractIds: options.contractIds }),
    },
  });
  return result;
};
