import { makeQuery } from "../index/utils";
import { v1ServerByProjectAuth, v1ServerByUserAuth } from "../index/serverInstances";
import {
  FromTick,
  MicroChainListOfProjectFromServer,
  MonthlyHistoryFromServer,
  PricingPlanFromServer,
  ProjectCreditFromServer,
  ProjectDefaultImageFromServer,
  ProjectFromServer,
  ProjectInvitationFromServer,
  ProjectMemberFromServer,
  ProjectReceiptFromServer,
  ProjectRequestsTab,
  ProjectRoleFromServer,
} from "../type/projectType";
import { AxiosRequestConfig } from "axios";
import { TokensFromServer } from "../type/userType";

//generateProjectToken---------------------------------------------------------------------------------------
const generateProjectToken = async (request: generateProjectTokenRequest): Promise<generateProjectTokenResponse> =>
  await v1ServerByUserAuth.post({
    path: `projects/token`,
    data: request.data,
    config: {
      withCredentials: true,
      ...(request.config && request.config),
    },
  });

type generateProjectTokenRequest = {
  data: {
    projectId: number;
  };
  config?: AxiosRequestConfig;
};

type generateProjectTokenResponse = {
  project_token: TokensFromServer;
};

//getProjectDefaultImage---------------------------------------------------------------------------------------
const getProjectDefaultImage = async (): Promise<getProjectDefaultImageResponse> =>
  await v1ServerByUserAuth.get({
    path: `projects/default-image`,
  });

type getProjectDefaultImageResponse = Array<ProjectDefaultImageFromServer>;
// ------------------------------------------------------

const uploadProjectImage = async (request: uploadProjectImageRequest): Promise<uploadProjectImageResponse> =>
  await v1ServerByUserAuth.post({
    path: `projects/upload-image`,
    query: makeQuery(request.query),
    data: request.data,
  });

type uploadProjectImageRequest = {
  query: {
    fileCount: number;
  };
  data: FormData;
};

type uploadProjectImageResponse = { project_image: string };
// ------------------------------------------------------

const createProject = async (request: CreateProjectRequest): Promise<CreateProjectResponse> =>
  await v1ServerByUserAuth.post({
    path: `projects`,
    data: request.data,
  });

type CreateProjectRequest = {
  data: {
    name: string;
    image: string;
    pricingPlanId: number;
    description?: string;
    selectedMicroChainIds?: Array<number>;
  };
};

type CreateProjectResponse = ProjectFromServer;
// ------------------------------------------------------

const getMyProjectByProjectToken = async (request: getMyProjectByProjectTokenRequest): Promise<getMyProjectByProjectTokenResponse> =>
  await v1ServerByProjectAuth.get({
    path: `projects/me`,
    ...(request.config && { config: request.config }),
  });
type getMyProjectByProjectTokenRequest = {
  config?: AxiosRequestConfig;
};

type getMyProjectByProjectTokenResponse = ProjectFromServer;

// ------------------------------------------------------

const getProjects = async (request: getProjectsRequest): Promise<getProjectsResponse> =>
  await v1ServerByUserAuth.get({
    path: `projects`,
    query: makeQuery(request.query),
  });

type getProjectsRequest = {
  query: {
    limit?: number;
    offset?: number;
    order?: "ASC" | "DESC";
    projectIds?: Array<number>;
  };
};
type getProjectsResponse = ProjectFromServer[];
// ------------------------------------------------------
const getProjectRoles = async (): Promise<getProjectRolesResponse> =>
  await v1ServerByUserAuth.get({
    path: `projects/roles`,
  });

type getProjectRolesResponse = ProjectRoleFromServer[];
// ------------------------------------------------------

const getProjectMembers = async (): Promise<getProjectMembersResponse> =>
  await v1ServerByProjectAuth.get({
    path: `projects/members`,
  });

type getProjectMembersResponse = ProjectMemberFromServer[];
// ------------------------------------------------------

const getProjectNetworks = async (): Promise<getProjectNetworksResponse> =>
  await v1ServerByProjectAuth.get({
    path: `projects/networks`,
  });

type getProjectNetworksResponse = Array<MicroChainListOfProjectFromServer>;
// ------------------------------------------------------

const getProjectInvitations = async (): Promise<getProjectInvitationsResponse> =>
  await v1ServerByProjectAuth.get({
    path: `projects/invitations`,
  });

type getProjectInvitationsResponse = Array<ProjectInvitationFromServer>;
// ------------------------------------------------------

const updateProject = async (request: updateProjectRequest): Promise<updateProjectResponse> =>
  await v1ServerByProjectAuth.put({
    path: `projects`,
    data: request.data,
  });

type updateProjectRequest = {
  data: {
    name: string;
    description: string;
    image: string;
    selectedMicroChainIds: Array<number>;
  };
};

type updateProjectResponse = ProjectFromServer;
// ------------------------------------------------------

const inviteProjectMember = async (request: inviteProjectMemberRequest): Promise<inviteProjectMemberResponse> =>
  await v1ServerByProjectAuth.post({
    path: `projects/invitations`,
    data: request.data,
  });

type inviteProjectMemberRequest = {
  data: {
    receiverEmail: string;
  };
};

type inviteProjectMemberResponse = ProjectInvitationFromServer;
// ------------------------------------------------------

const deleteInvitation = async (request: deleteInvitationRequest): Promise<deleteInvitationResponse> =>
  await v1ServerByProjectAuth.delete({
    path: `projects/invitations/${request.params.invitationId}`,
  });

type deleteInvitationRequest = {
  params: {
    invitationId: number;
  };
};

type deleteInvitationResponse = {
  status: boolean;
};
// ------------------------------------------------------

const removeMember = async (request: removeMemberRequest): Promise<removeMemberResponse> =>
  await v1ServerByProjectAuth.delete({
    path: `projects/members/${request.params.projectMemberId}`,
  });

type removeMemberRequest = {
  params: {
    projectMemberId: number;
  };
};

type removeMemberResponse = {
  status: boolean;
};
// ------------------------------------------------------

const checkProjectName = async (request: checkProjectNameRequest): Promise<checkProjectNameResponse> =>
  await v1ServerByUserAuth.post({
    path: `projects/check-name`,
    data: request.data,
  });

type checkProjectNameRequest = {
  data: {
    name: string;
  };
};

type checkProjectNameResponse = {
  status: boolean;
};
// ------------------------------------------------------
// 사용 비중 - Project Requests
const getRequestVolume = async (request: getRequestVolumeRequest): Promise<getRequestVolumeResponse[]> =>
  await v1ServerByProjectAuth.get({
    path: `api-keys/request-volume`,
    query: makeQuery(request.query),
  });

type getRequestVolumeRequest = {
  query: {
    type: ProjectRequestsTab;
    from: FromTick;
  };
};

type getRequestVolumeResponse = {
  ts: string;
  micro_chain_id: number;
  method: string;
  count: number;
};
// ------------------------------------------------------
// 사용 내역 - Requests History
const getRequestActivity = async (request: getRequestActivityRequest): Promise<getRequestActivityResponse[]> =>
  await v1ServerByProjectAuth.get({
    path: `api-keys/request-activity`,
    query: makeQuery(request.query),
  });

type getRequestActivityRequest = {
  query: {
    from: FromTick;
  };
};

type getRequestActivityResponse = {
  micro_chain_id: number;
  method: string;
  count: number;
  micro_credit: number;
};
// ------------------------------------------------------
// 최대 사용 method 3개 - Most Used Requests
const getMethodDominance = async (request: getMethodDominanceRequest): Promise<getMethodDominanceResponse[]> =>
  await v1ServerByProjectAuth.get({
    path: `api-keys/method-dominance`,
    query: makeQuery(request.query),
  });

type getMethodDominanceRequest = {
  query: {
    from: FromTick;
  };
};

type getMethodDominanceResponse = {
  ts: string;
  micro_chain_id: number;
  method: string;
  count: number;
};
// ------------------------------------------------------
// 네트워크 사용 비중 - Network Utilization Comparison
const getNetworkDominance = async (request: getNetworkDominanceRequest): Promise<getNetworkDominanceResponse[]> =>
  await v1ServerByProjectAuth.get({
    path: `api-keys/network-dominance`,
    query: makeQuery(request.query),
  });

type getNetworkDominanceRequest = {
  query: {
    from: FromTick;
  };
};

type getNetworkDominanceResponse = {
  micro_chain_id: number;
  count: number;
};
// ------------------------------------------------------

const getPricingPlans = async (): Promise<getPricingPlansResponse> =>
  await v1ServerByProjectAuth.get({
    path: `pricing-plans`,
  });

type getPricingPlansResponse = Array<PricingPlanFromServer>;
// ------------------------------------------------------

const getPricingPlan = async (request: getPricingPlanRequest): Promise<getPricingPlanResponse> =>
  await v1ServerByProjectAuth.get({
    path: `pricing-plans/${request.params.pricingPlanId}`,
  });

type getPricingPlanRequest = {
  params: {
    pricingPlanId: number;
  };
};
type getPricingPlanResponse = PricingPlanFromServer;

// ------------------------------------------------------

const getMonthlyHistory = async (): Promise<getMonthlyHistoryResponse> =>
  await v1ServerByProjectAuth.get({
    path: `payment/monthly-history/latest`,
  });

type getMonthlyHistoryResponse = MonthlyHistoryFromServer;
// ------------------------------------------------------

const getProjectCredit = async (): Promise<getProjectCreditResponse> =>
  await v1ServerByProjectAuth.get({
    path: `payment/credit`,
  });

type getProjectCreditResponse = ProjectCreditFromServer;
// ------------------------------------------------------

const getProjectReceipts = async (request: getProjectReceiptsRequest): Promise<getProjectReceiptsResponse> =>
  await v1ServerByProjectAuth.get({
    path: `payment/receipts`,
    query: makeQuery(request.query),
  });

type getProjectReceiptsRequest = {
  query: {
    offset: number;
    limit: number;
  };
};

type getProjectReceiptsResponse = ProjectReceiptFromServer;

// -----------------------------------------------------------------------------------------------------

export {
  generateProjectToken as generateProjectTokenFromServer,
  getProjectDefaultImage as getProjectDefaultImageFromServer,
  uploadProjectImage as uploadProjectImageFromServer,
  createProject as createProjectFromServer,
  getProjects as getProjectsFromServer,
  getMyProjectByProjectToken as getMyProjectByProjectTokenFromServer,
  getProjectRoles as getProjectRolesFromServer,
  getProjectNetworks as getProjectNetworksFromServer,
  getProjectMembers as getProjectMembersFromServer,
  getProjectInvitations as getProjectInvitationsFromServer,
  updateProject as updateProjectFromServer,
  inviteProjectMember as inviteProjectMemberFromServer,
  deleteInvitation as deleteInvitationFromServer,
  removeMember as removeMemberFromServer,
  checkProjectName as checkProjectNameFromServer,
  // ---- 차트 데이터
  getRequestVolume as getRequestVolumeFromServer,
  getRequestActivity as getRequestActivityFromServer,
  getMethodDominance as getMethodDominanceFromServer,
  getNetworkDominance as getNetworkDominanceFromServer,
  // ---- pricing
  getPricingPlans as getPricingPlansFromServer,
  getPricingPlan as getPricingPlanFromServer,
  getMonthlyHistory as getMonthlyHistoryFromServer,
  getProjectCredit as getProjectCreditFromServer,
  getProjectReceipts as getProjectReceiptsFromServer,
};
