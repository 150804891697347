import React, { useEffect, useState } from "react";
import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { user_access, user_info } from "../../reducer/userReducer";
import InitializationRouter from "../service/initialize/InitializeRouter";
import UserRouter from "../service/user/UserRouter";
import VerificationRouter from "../service/verification/VerificationRouter";
import NetworkRouter from "../service/network/NetworkRouter";
import ToolRouter from "../service/tool/ToolRouter";
import SolutionRouter from "../service/solution/SolutionRouter";
import ApplicationRouter from "../service/application/ApplicationRouter";
import DeveloperRouter from "../service/developer/DeveloperRouter";
import Faq from "../service/faq/page/Faq";
import NotFound from "../components/_page/notFound/NotFound";
import Dashboard from "../service/dashboard/Dashboard";
import InitialProjectRouter from "../service/initialize/project/InitialProjectRouter";
import ServiceInit from "./ServiceInit";
import ProjectRouter from "../service/project/ProjectRouter";
import { refreshUserAccessToken } from "server/index/utils";
import { eqhub } from "App";

const RootRouter = () => {
  const location = useLocation();
  const userInfo = useSelector(user_info);
  const userAuth = useSelector(user_access);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const refreshToken_ = async () => {
    await refreshUserAccessToken();
  };

  useEffect(() => {
    refreshToken_().finally(() => setLoading(false));
  }, []);

  if (loading) {
    return <></>;
  } else {
    return (
      <>
        <Routes>
          <Route path="initialize/*" element={<InitializationRouter userAccess={userAuth} userInfo={userInfo} />} />
          <Route path="verify/*" element={<VerificationRouter />} />
          <Route element={<Auth userAccess={userAuth} userInfo={userInfo} />}>
            <Route path="manage-project/*" element={<InitialProjectRouter />} />
            <Route element={<ServiceInit />}>
              <Route index element={<Dashboard />} />
              <Route path="user/*" element={<UserRouter />} />
              <Route path="project/*" element={<ProjectRouter />} />
              <Route path="network/*" element={<NetworkRouter />} />
              <Route path="tool/*" element={<ToolRouter />} />
              <Route path="solution/*" element={<SolutionRouter />} />
              <Route path="application/*" element={<ApplicationRouter />} />
              <Route path="developer/*" element={<DeveloperRouter />} />
              {/* <Route path="faq/*" element={<Faq />} /> */}
            </Route>
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </>
    );
  }
};
export default RootRouter;

const Auth = ({ userAccess, userInfo }) => {
  if (!!!userAccess || !!!userInfo.emailVerification) {
    return <Navigate to={"/initialize"} />;
  }
  return <Outlet />;
};
