import React from "react";
import MainLayout from "./layout/MainLayout";
import { useSelector } from "react-redux";
import { selectAllProjects } from "../../reducer/projectReducer";
import { initializeProjectAction } from "../../action/commonAction";
import PageLoading from "../components/_atom/loading/PageLoading";
import { Navigate } from "react-router-dom";
import useLoading from "hooks/useLoading";
import { getMyUserAction } from "action/userAction";

const ServiceInit = () => {
  const projectList = useSelector(selectAllProjects);

  const initializeProject_ = async () => {
    const { result, error } = await initializeProjectAction();
    if (error) {
    }
  };

  // TODO :: 서버에서 비밀번호 변경 후 모든 엑세스토큰 만료 로직 추가되면 삭제될 요청
  const getMyUserData_ = async () => {
    const { result, error } = await getMyUserAction();
    if (error) {
    }
  };

  const initializeData_ = async () => {
    await initializeProject_();
    await getMyUserData_();
  };

  const { loading } = useLoading({
    dependency: [1],
    synchronousFunction: async () => {
      await initializeData_();
    },
  });

  if (loading) {
    return <PageLoading />;
  }
  if (projectList.length === 0) {
    return <Navigate to={"/manage-project"} />;
  }
  return <MainLayout />;
};
export default ServiceInit;
