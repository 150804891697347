import lastButton from "../../../../view/assets/images/icon-paging-last-button.png";
import firstButton from "../../../../view/assets/images/icon-paging-first-button.png";
import nextButton from "../../../../view/assets/images/icon-paging-next-button.png";
import prevButton from "../../../../view/assets/images/icon-paging-prev-button.png";
import "./PagingNumber.scss";
import { useEffect } from "react";

const NUMBER_REG_EXP = /^[,0-9]+$/;

/**
 *
 * @param {Number} currentPage
 * @param {Function} setCurrentPage
 * @param {Number} maximumPage
 * @param {Function} getPage
 * @returns
 */
const PagingNumber = (props) => {
  const { page, setPage, maximumPage = 1, getData } = props || {};
  const pageSetting = (value) => {
    if (typeof value === "string" && value === "") {
      setPage(value);
      return;
    }

    if (!NUMBER_REG_EXP.test(Number(value))) {
      return;
    }
    if (Number(value) >= maximumPage) {
      setPage(maximumPage);
      return;
    }
    if (Number(value) < 1) {
      setPage(1);
      return;
    }
    setPage(value);
  };

  const movePage = (where) => {
    let result = page;
    switch (where) {
      case "first":
        result = 1;
        break;
      case "prev":
        if (Number(page) > 1) {
          result = Number(page) - 1;
        }
        break;
      case "next":
        if (Number(page) < Number(maximumPage)) {
          result = Number(page) + 1;
        }
        break;
      case "last":
        result = Number(maximumPage);
        break;
      default:
        break;
    }
    return result;
  };

  const onClickPageMoveButton = async (where) => {
    const result = movePage(where);
    setPage(result);
  };

  useEffect(() => {
    getData(page);
  }, [page]);

  return (
    <div className="paging-number">
      <div className="paging-number-box">
        <img
          onClick={() => onClickPageMoveButton("first")}
          className="paging-number-button-icon"
          src={firstButton}
          alt=""
        />
        <img
          onClick={() => onClickPageMoveButton("prev")}
          className="paging-number-button-icon"
          src={prevButton}
          alt=""
        />
        <div>
          <input
            type="text"
            className="paging-number-input"
            value={page ?? ""}
            onChange={(e) => pageSetting(e.target.value)}
          />
        </div>
        <div>{"/"}</div>
        <div style={{ width: 34, textAlign: "center" }}>{maximumPage}</div>
        <img
          onClick={() => onClickPageMoveButton("next")}
          className="paging-number-button-icon"
          src={nextButton}
          alt=""
        />
        <img
          onClick={() => onClickPageMoveButton("last")}
          className="paging-number-button-icon"
          src={lastButton}
          alt=""
        />
      </div>
    </div>
  );
};

export default PagingNumber;
