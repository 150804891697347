import { v2ServerByProjectAuth } from "server/index/serverInstances";
import { makeQuery } from "server/index/utils";
import { GetMicroChainResponse, getMicroChainRequest } from "./index.type";

const getMicroChain = async (
  request: getMicroChainRequest
): Promise<GetMicroChainResponse> =>
  await v2ServerByProjectAuth.get({
    path: `/micro-chains`,
    query: makeQuery(request.query),
  });

export { getMicroChain as getMicroChainFromServer };
