import {
  createNFTProjectParser,
  uploadNFTProjectImageParser,
  getNFTProjectListParser,
  getNFTProjectDetailParser,
  updateNFTProjectParser,
  deleteNFTProjectParser,
  createNFTProjectContractParser,
  getNFTTokensParser,
  getNFTTokenDetailParser,
  uploadNFTContentParser,
} from "parser/erc721Parser";
import { parseAction } from "./util/parseAction";
import { getExternalAndInnerMicroChainListParser, getNetworkLabelAndMicroChainLabelParser } from "parser/microChainParser";
import { networkParserNew } from "utils/Utils";
import { getMicroChainIdsOfProjectParser } from "parser/projectParser";
import { getListedContractDetailParser } from "../parser/contractParser";

export const getInitialDataForCreateNFTProjectAction = () =>
  parseAction(async () => {
    const microChainIdsOfProject = await getMicroChainIdsOfProjectParser();
    let microChainList = [];
    if (microChainIdsOfProject.length) {
      microChainList = await getExternalAndInnerMicroChainListParser({ microChainIds: microChainIdsOfProject, mainStatus: [5] });
    }
    const networkList = networkParserNew(microChainList);

    return {
      microChains: microChainList,
      networks: networkList,
    };
  });

export const uploadProjectImageToS3Action = (formData: FormData) =>
  parseAction(async () => {
    const result = await uploadNFTProjectImageParser(1, formData);
    return result;
  });

export const createNFTProjectAction = (
  microChainId: number,
  symbol: string,
  name: string,
  description: string,
  image: string,
  mintable: boolean,
  burnable: boolean,
  officialSite: Array<string>
) =>
  parseAction(async () => {
    const result = await createNFTProjectParser(microChainId, symbol, name, description, image, mintable, burnable, officialSite);
    return result;
  });

export const updateNFTProjectAction = (projectId: number, name: string, description: string, image: string, officialSite: string[], symbol: string) =>
  parseAction(async () => {
    const result = await updateNFTProjectParser(projectId, name, description, image, officialSite, symbol);
    return result;
  });

export const deleteNFTProjectAction = (projectId: number) =>
  parseAction(async () => {
    const result = await deleteNFTProjectParser(projectId);
    return result;
  });

export const create721ContractAction = (projectId: number) =>
  parseAction(async () => {
    const nftProjectDetail = await createNFTProjectContractParser(projectId);
    const { networkLabel, microChainLabel } = await getNetworkLabelAndMicroChainLabelParser(nftProjectDetail.micro_chain_id);
    const result = {
      projectId: nftProjectDetail.erc721_temp_id,
      image: nftProjectDetail.image,
      microChainId: nftProjectDetail.micro_chain_id,
      projectName: nftProjectDetail.name,
      projectDescription: nftProjectDetail.description,
      mainStatus: nftProjectDetail.main_status,
      projectLink: nftProjectDetail.official_site,
      symbol: nftProjectDetail.symbol,
      contractAddress: nftProjectDetail.contract_address,
      contractId: nftProjectDetail.contract_id,
      mintTiming: 1,
      networkLabel,
      microChainLabel,
    };
    return result;
  });

export const getNFTsAction = () =>
  parseAction(async () => {
    const nftProjectList = await getNFTProjectListParser();
    const microChainList = await getExternalAndInnerMicroChainListParser({ mainStatus: [5] });

    const result = nftProjectList.map((nftProject) => ({
      ...nftProject,
      nftProjectId: nftProject.erc721_temp_id,
      projectName: nftProject.name,
      mainStatus: nftProject.main_status,
      contractAddress: nftProject.contract_address,
      image: nftProject.image,
      networkLabel: microChainList.find((chain) => chain.id === nftProject.micro_chain_id)?.network?.label,
      microChainLabel: microChainList.find((chain) => chain.id === nftProject.micro_chain_id)?.label,
    }));
    return result;
  });

export const getNFTProjectDetailAction = (nftProjectId: number) =>
  parseAction(async () => {
    const nftProjectDetail = await getNFTProjectDetailParser(nftProjectId);
    const { networkLabel, microChainLabel } = await getNetworkLabelAndMicroChainLabelParser(nftProjectDetail.micro_chain_id);
    let nftContractTxHash: string | null = null;

    if (nftProjectDetail.main_status > 3) {
      const { transactionHash } = await getListedContractDetailParser(nftProjectDetail.contract_id);
      nftContractTxHash = transactionHash;
    }
    const result = {
      projectId: nftProjectDetail.erc721_temp_id,
      image: nftProjectDetail.image,
      microChainId: nftProjectDetail.micro_chain_id,
      projectName: nftProjectDetail.name,
      projectDescription: nftProjectDetail.description,
      mainStatus: nftProjectDetail.main_status,
      subStatus: nftProjectDetail.sub_status,
      projectLink: nftProjectDetail.official_site,
      symbol: nftProjectDetail.symbol,
      contractAddress: nftProjectDetail.contract_address,
      contractId: nftProjectDetail.contract_id,
      transactionHash: nftContractTxHash,
      mintTiming: 1,
      erc721TempId: nftProjectDetail.erc721_temp_id,
      networkLabel,
      microChainLabel,
    };
    return result;
  });

export const getMintedNFTsByProjectIdAction = (nftProjectId: number) =>
  parseAction(async () => {
    const nftTokenList = await getNFTTokensParser(nftProjectId);
    const result = nftTokenList.map((nftToken) => ({
      tokenId: nftToken.erc721_token_temp_id,
      image: nftToken.img_url,
      title: nftToken.name,
    }));
    return result;
  });

export const uploadNFTContentToS3Action = (fileCount: number, formData: FormData) =>
  parseAction(async () => {
    const result = await uploadNFTContentParser(fileCount, formData);
    return result;
  });

export const getNFTDetailByTokenIdAction = (projectId: number, tokenId: number) =>
  parseAction(async () => {
    const nftDetail = await getNFTTokenDetailParser(tokenId);
    const nftProjectDetail = await getNFTProjectDetailParser(projectId);
    const result = {
      animationUrl: nftDetail.animation_url,
      imageUrl: nftDetail.img_url,
      type: nftDetail.type,
      microChainId: nftProjectDetail.micro_chain_id,
      tokenId: nftDetail.erc721_token_temp_id,
      title: nftDetail.name,
      description: nftDetail.description,
      contractAddress: nftProjectDetail.contract_address,
      projectName: nftProjectDetail.name,
      mainStatus: nftDetail.main_status,
      subStatus: nftDetail.sub_status,
      transactionHash: nftDetail.transaction_hash ?? null,
    };
    return result;
  });
