import { useEffect, useState } from "react";
import "./CreateNetwork.scss";
import { NETWORK_DATA_FORMAT } from "../constants/networkConstants";
import { errorAlert } from "../../../../../utils/Utils";
import usePreventLeaving from "../../../../../hooks/usePreventLeaving";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import ProgressState from "../../../../components/_molecules/progressState/ProgressState";
import { createBesuNetworkAction, createEquilibriumNetworkAction, getBlockchainEngineListAction } from "action/networkAction";
import PageTitle from "../../../../components/_molecules/page/PageTitle";
import PageArticle from "view/components/_template/page/PageArticle";
import DivideLine from "view/components/_atom/line/DivideLine";
import PageLoading from "view/components/_atom/loading/PageLoading";
import useLoading from "hooks/useLoading";
import {
  ERROR_DUPLICATE_COIN_NAME_IN_CREATE,
  ERROR_DUPLICATE_COIN_SYMBOL_IN_CREATE,
  ERROR_DUPLICATE_NETWORK_NAME_IN_CREATE,
  NETWORK_ENGINE_TYPE_EQUILIBRIUM__1_1_6__PON,
  NETWORK_ENGINE_TYPE_ETHEREUM__1_1_6__CLIQUE,
  NETWORK_STEP_LIST,
} from "../constants/network.data";
import NetworkBasicInfoSetting from "../component/template/NetworkBasicInfoSetting";
import NetworkEngineSetting from "../component/template/NetworkEngineSetting";
import NetworkNodeEquilibriumSetting from "../component/template/NetworkNodeEquilibriumSetting";
import NetworkNodeBesuSetting from "../component/template/NetworkNodeBesuSetting";
import NetworkEquilibriumPreAllocationSetting from "../component/template/NetworkEquilibriumPreAllocationSetting";
import useGetSelectedProject from "hooks/useGetSelectedProject";
import NetworkBesuPreAllocationSetting from "../component/template/NetworkBesuPreAllocationSetting";

const CreateNetwork = () => {
  const navigate = useNavigate();

  const { selectedProjectID } = useGetSelectedProject();

  const [currentStep, setCurrentStep] = useState(1);
  const [networkType, setNetworkType] = useState(0);

  const [blockchainEngineList, setBlockchainEngineList] = useState<Array<any>>([]);

  const [networkData, setNetworkData] = useState(NETWORK_DATA_FORMAT);

  const getBlockchainEngineList_ = async () => {
    const { result, error } = await getBlockchainEngineListAction();

    if (result) {
      setBlockchainEngineList(result);
      setNetworkData((prevState) => ({
        ...prevState,
        microChain: {
          ...prevState.microChain,
          protocolId: result[0].protocolId,
          chainClientId: result[0].chainClientId,
        },
      }));
    }
    if (error) {
    }
  };

  const createEquilibriumNetwork_ = async () => {
    const { network, microChain, microChainSetting, nodeAlloc, preAlloc, currency } = networkData;
    const { result, error } = await createEquilibriumNetworkAction(network, microChain, microChainSetting, nodeAlloc, preAlloc, currency);

    if (result) {
      toast.success("Create successfully");
      navigate(`/network/mainnet/manage/${result.networkId}`);
    }

    if (error) {
      if (error.data.serverStatus === ERROR_DUPLICATE_NETWORK_NAME_IN_CREATE) {
        toast.error(error.data.message);
        setCurrentStep(1);
        return;
      }
      if (error.data.serverStatus === ERROR_DUPLICATE_COIN_NAME_IN_CREATE) {
        toast.error(error.data.message);
        setCurrentStep(1);
        return;
      }
      if (error.data.serverStatus === ERROR_DUPLICATE_COIN_SYMBOL_IN_CREATE) {
        toast.error(error.data.message);
        setCurrentStep(1);
        return;
      }
      errorAlert(error.data.message);
      return;
    }
  };

  const createBesuNetwork_ = async () => {
    const { network, microChain, microChainSetting, nodeAlloc, preAlloc, currency } = networkData;
    const { result, error } = await createBesuNetworkAction(network, microChain, microChainSetting, nodeAlloc, preAlloc, currency);

    if (result) {
      toast.success("Create successfully");
      navigate(`/network/mainnet/manage/${result.networkId}`);
    }

    if (error) {
      if (error.data.serverStatus === ERROR_DUPLICATE_NETWORK_NAME_IN_CREATE) {
        toast.error(error.data.message);
        setCurrentStep(1);
        return;
      }
      if (error.data.serverStatus === ERROR_DUPLICATE_COIN_NAME_IN_CREATE) {
        toast.error(error.data.message);
        setCurrentStep(1);
        return;
      }
      if (error.data.serverStatus === ERROR_DUPLICATE_COIN_SYMBOL_IN_CREATE) {
        toast.error(error.data.message);
        setCurrentStep(1);
        return;
      }
      errorAlert(error.data.message);
      return;
    }
  };

  const createNetwork_ = async (validation) => {
    if (!validation.isValid) {
      toast.error(validation.message);
      return;
    }

    if (networkType === NETWORK_ENGINE_TYPE_EQUILIBRIUM__1_1_6__PON) {
      await createEquilibriumNetwork_();
    }

    if (networkType === NETWORK_ENGINE_TYPE_ETHEREUM__1_1_6__CLIQUE) {
      await createBesuNetwork_();
    }
  };

  const { enablePrevent, disablePrevent } = usePreventLeaving();

  useEffect(() => {
    enablePrevent();

    return () => {
      disablePrevent();
    };
  }, []);

  const { loading } = useLoading({
    dependency: [selectedProjectID],
    synchronousFunction: async () => {
      await getBlockchainEngineList_();
    },
  });

  if (loading) {
    return <PageLoading />;
  }

  return (
    <PageArticle scrollDependency={currentStep}>
      <PageTitle title={"Create Network"} description={"Create your own Network."} size={810} />
      <div className="page-layout-810">
        <ProgressState step={currentStep} stepList={NETWORK_STEP_LIST} size={730} />
        <DivideLine style={{ margin: "40px 0" }} />
        {currentStep === 1 && <NetworkBasicInfoSetting setCurrentStep={setCurrentStep} networkData={networkData} setNetworkData={setNetworkData} />}
        {currentStep === 2 && (
          <NetworkEngineSetting
            setCurrentStep={setCurrentStep}
            networkData={networkData}
            setNetworkData={setNetworkData}
            blockchainEngineList={blockchainEngineList}
            setNetworkType={setNetworkType}
          />
        )}
        {currentStep === 3 && networkType === NETWORK_ENGINE_TYPE_EQUILIBRIUM__1_1_6__PON && (
          <NetworkNodeEquilibriumSetting setCurrentStep={setCurrentStep} networkData={networkData} setNetworkData={setNetworkData} />
        )}
        {currentStep === 3 && networkType === NETWORK_ENGINE_TYPE_ETHEREUM__1_1_6__CLIQUE && (
          <NetworkNodeBesuSetting setCurrentStep={setCurrentStep} networkData={networkData} setNetworkData={setNetworkData} />
        )}
        {currentStep === 4 && networkType === NETWORK_ENGINE_TYPE_EQUILIBRIUM__1_1_6__PON && (
          <NetworkEquilibriumPreAllocationSetting
            setCurrentStep={setCurrentStep}
            networkData={networkData}
            setNetworkData={setNetworkData}
            createButtonHandler={createNetwork_}
          />
        )}
        {currentStep === 4 && networkType === NETWORK_ENGINE_TYPE_ETHEREUM__1_1_6__CLIQUE && (
          <NetworkBesuPreAllocationSetting
            setCurrentStep={setCurrentStep}
            networkData={networkData}
            setNetworkData={setNetworkData}
            createButtonHandler={createNetwork_}
          />
        )}
      </div>
    </PageArticle>
  );
};

export default CreateNetwork;
