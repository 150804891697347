import axios, {AxiosResponse} from "axios";
import * as uuid from "uuid";
import {checkAccessToken, checkRefreshToken, ERROR_TYPE_LOGIN_EXPIRED} from "./utils";

type JWTToken = {
	access: JWT;
	refresh: JWT;
};

type JWT = {
	token?: string | null;
	expires: string;
};

type QueueItem = {
	requestId: string;
	api: ApiData;
};

type ApiData = {
	method: string;
	baseURL: string;
	path: string;
	query?: string;
	data?: any;
	config?: any;
};

enum QueueStatus {
	READY = 0,
	WAIT = 1,
	UNABLE = 2,
}

export class Queue {
	queue: QueueItem[];
	apiManager: ApiManager;
	apiEvent: any;
	status: number;

	constructor(apiManager: ApiManager, apiResultEventManager: any) {
		this.queue = [];
		this.apiManager = apiManager;
		this.apiEvent = apiResultEventManager;
		this.status = QueueStatus.READY;
	}

	public resetQueue() {
		this.queue = [];
		this.status = QueueStatus.READY;
	}

	public async addQueue(api: ApiData, requestId: string) {
		this.queue = [{api, requestId}, ...this.queue];
		if (this.canRequest()) {
			await this.noticeQueueNotEmpty();
		}
	}

	private async runNextQueueItem() {
		if (this.canRequest()) {
			await this.noticeQueueNotEmpty();
		}
	}

	private canRequest() {
		return this.queue.length > 0 && this.status === QueueStatus.READY;
	}

	private async noticeQueueNotEmpty() {
		await this.runApiManager();
	}

	private async runApiManager() {
		this.status = QueueStatus.WAIT;
		const apiObject = this.queue[0];
		this.queue = this.queue.filter((el) => el.requestId !== apiObject.requestId);
		try {
			const result = await this.apiManager.sendApi(apiObject.api, apiObject.requestId);
			this.status = QueueStatus.READY;
			this.apiEvent.emit(apiObject.requestId, result);
		} catch (e) {
			this.apiEvent.emit(apiObject.requestId, e);
			if (e.type === ERROR_TYPE_LOGIN_EXPIRED) {
				this.status = QueueStatus.UNABLE;
			} else {
				this.status = QueueStatus.READY;
			}
		}
		this.runNextQueueItem();
	}
}

export class ApiManager {
	token: Token;
	api: Promise<any>;
	server: any;
	refreshApi: () => Promise<JWTToken>;

	constructor(token: Token, refreshApi: any) {
		this.token = token;
		this.refreshApi = refreshApi;
	}

	public async sendApi(api: ApiData, requestId: string): Promise<AxiosResponse> {
		let result;
		try {
			result = await axios({
				...(api.config && api.config),
				baseURL: api.baseURL,
				url: `${api.path}${api.query}`,
				method: api.method,
				headers: {Authorization: this.token.getAccessTokenWithBearer()},
				timeout: 20000,
				...(api.data && {data: api.data}),
			});
			return result;
		} catch (e) {
			const isAccessTokenExpired = checkAccessToken(e.response.data.serverStatusCode);
			if (isAccessTokenExpired) {
				try {
					await this.handleRefreshApi();
					// 이후 재요청
					result = await axios({
						...(api.config && api.config),
						baseURL: api.baseURL,
						url: `${api.path}${api.query}`,
						method: api.method,
						headers: {Authorization: this.token.getAccessTokenWithBearer()},
						timeout: 20000,
						...(api.data && {data: api.data}),
					});
					return result;
				} catch (e) {
					throw e;
				}
			} else {
				throw e;
			}
		}
	}

	private async handleRefreshApi() {
		try {
			const result = await this.refreshApi();

			return result;
		} catch (e) {
			const isRefreshTokenExpired = checkRefreshToken(e.response.data.serverStatusCode);
			if (isRefreshTokenExpired) {
				throw {type: ERROR_TYPE_LOGIN_EXPIRED};
			}
		}
	}
}

export class Token {
	access: JWT;
	refresh: JWT;
	onSetToken?: Function;
	valueOfOnSetToken?: NodeJS.Timeout;

	constructor(onSetToken?: Function) {
		this.access = {
			token: "",
			expires: "",
		};
		this.refresh = {
			token: undefined,
			expires: "",
		};
		this.onSetToken = onSetToken;
	}

	public getJWT(): JWTToken {
		return this;
	}

	public getAccess(): JWT {
		return this.access;
	}

	public setAccess(token: JWT): void {
		this.access = token;
	}

	public getRefresh(): JWT {
		return this.refresh;
	}

	public setRefresh(token: JWT): void {
		this.refresh = token;
		if (this.valueOfOnSetToken) {
			clearTimeout(this.valueOfOnSetToken);
		}
		if (this.onSetToken !== undefined) {
			const timeout = this.onSetToken();
			this.valueOfOnSetToken = timeout;
		}
	}

	public removeToken(): void {
		this.access = {
			token: "",
			expires: "",
		};
		this.refresh = {
			token: undefined,
			expires: "",
		};
	}

	public getAccessTokenWithBearer(): string {
		return `Bearer ${this.access.token}`;
	}
}

export function getRequestId() {
	return JSON.stringify(uuid.v4());
}
