import React from "react";
import Text from "../../../../components/_atom/text/Text";
import Notification from "../../molcules/Notification";
import "./NotificationContainer.scss";

const NotificationContainer = ({ notifications, getNotifications_, setNotificationPanelOpen }) => {
  return (
    <div className="notification-container">
      {notifications.length === 0 ? (
        <div className="flex justify-content-center" style={{ marginTop: 10 }}>
          <Text text={"No notification"} className="TT__section-description TC__black" />
        </div>
      ) : (
        notifications.map((notification) => (
          <Notification
            key={notification.notificationId}
            setNotificationPanelOpen={setNotificationPanelOpen}
            notification={notification}
            getNotifications_={getNotifications_}
          />
        ))
      )}
    </div>
  );
};

export default NotificationContainer;
