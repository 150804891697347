export const ASSET_PURPOSE_TYPE_COIN = 1;
export const ASSET_PURPOSE_TYPE_TOKEN = 2;
export const ASSET_PURPOSE_TYPE_NFT = 3;

// TODO :: currency 서버 삭제 후 wallet manage AS 로 변경할 때
// 아래 상수값 말고 Asset 받아온 값으로 purpose 세팅
export const WALLET_PURPOSE_TYPE = [
  { assetId: 1, value: 1, name: "coin", label: "Coin & Token(ERC-20)" },
  { assetId: 3, value: 3, name: "721", label: "NFT(ERC-721)" },
];

export const WALLET_TYPE_LIST = [
  {
    id: 1,
    label: "Coin & Token",
  },
  {
    id: 2,
    label: "Coin & Token",
  },
  {
    id: 3,
    label: "NFT",
  },
];
