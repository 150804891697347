import "./EmptyListTemplate.scss";
import noDataInListImage from "../../../../view/assets/images/image-empty-list.png";

const EmptyListTemplate = (props) => {
  const { description } = props || {};
  return (
    <div className="empty-list-template">
      <div className="empty-list-template-wrapper">
        <div className="empty-list-image">
          <img className="empty-list-image-src" src={noDataInListImage} alt="" />
        </div>
        <div className="empty-list-description">{description}</div>
      </div>
    </div>
  );
};
export default EmptyListTemplate;
