import { useState } from "react";
import "./UserProfile.scss";
import { useSelector } from "react-redux";
import { user_info } from "../../../../reducer/userReducer";
import { ManageFieldContainer, ManageFieldLabel, RowManageField } from "view/components/_atom/section/ManageField";
import profileIcon from "../../../assets/images/service/Icon_SNB_node.png";
import PageHeader from "view/components/_molecules/page/PageHeader";
import deleteIcon from "../../../assets/images/atom/icon_delete_red.png";
import checkIcon from "../assets/Icon_MFA_check.png";
import GenerateMFAModal from "../component/GenerateMFAModal";
import DeleteMFAModal from "../component/DeleteMFAModal";
import ChangePasswordModal from "../component/ChangePasswordModal";

function UserProfile() {
  const userData = useSelector(user_info);

  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] = useState<boolean>(false);
  const [generateMFAModalOpen, setGenerateMFAModalOpen] = useState<boolean>(false);

  return (
    <div id="UserProfile">
      {isChangePasswordModalOpen && <ChangePasswordModal isOpen={isChangePasswordModalOpen} setIsOpen={setIsChangePasswordModalOpen} />}
      {generateMFAModalOpen && <GenerateMFAModal isOpen={generateMFAModalOpen} closeModal={setGenerateMFAModalOpen} />}

      <PageHeader title={"Profile"} />
      <div className="user-profile-container">
        <ManageFieldContainer title="Profile" icon={profileIcon}>
          <RowManageField>
            <ManageFieldLabel label="Name" />
            <span>{`${userData?.firstName ?? ""} ${userData?.lastName ?? ""}`}</span>
          </RowManageField>
          <RowManageField>
            <ManageFieldLabel label="Email" />
            <span>{userData?.email ?? "-"}</span>
          </RowManageField>
          <RowManageField>
            <ManageFieldLabel label="Password" />
            <button className="user-profile-button" onClick={() => setIsChangePasswordModalOpen(true)}>
              Change password
            </button>
          </RowManageField>
        </ManageFieldContainer>

        <ManageFieldContainer title="Security" icon={profileIcon}>
          <RowManageField>
            <ManageFieldLabel label="Multi-Factor Authentication" announcement={<AnnouncementOfMFA />} />
            {!userData.isMFASet && (
              <button className="user-profile-button" onClick={() => setGenerateMFAModalOpen(true)}>
                Add
              </button>
            )}
            {userData.isMFASet && <MultiFactorAuthentication />}
          </RowManageField>
        </ManageFieldContainer>
      </div>
    </div>
  );
}

export default UserProfile;

// ------------------------------ COMPONENTS -------------------------------------

const MultiFactorAuthentication = () => {
  const mfaType = "Google OTP";

  const [deleteMFAModalOpen, setDeleteMFAModalOpen] = useState<boolean>(false);

  return (
    <>
      {deleteMFAModalOpen && <DeleteMFAModal isOpen={deleteMFAModalOpen} closeModal={setDeleteMFAModalOpen} />}
      <div className="multi-factor-authentication">
        <div className="multi-factor-authentication-info">
          <div className="multi-factor-authentication-info-type">
            <img className="multi-factor-authentication-info-type-icon" src={checkIcon} alt="" />
            <div className="multi-factor-authentication-info-type-label">{mfaType}</div>
          </div>
        </div>
        <div className="multi-factor-authentication-delete">
          <img src={deleteIcon} className="multi-factor-authentication-delete-icon" alt="" onClick={() => setDeleteMFAModalOpen(true)} />
        </div>
      </div>
    </>
  );
};

const AnnouncementOfMFA = () => {
  return (
    <div className="user-profile-mfa-announcement">
      {`Multi-Factor Authentication(MFA) refers to a method of\nauthenticating your identity in two or more ways by\nperforming additional authentication after entering\nyour ID/password during login process.`}
    </div>
  );
};
