import { makeQuery } from "../index/utils";
import { v2ServerByProjectAuth } from "../index/serverInstances";

const createNode = async (request: createNodeRequest): Promise<createNodeResponse> =>
  await v2ServerByProjectAuth.post({
    path: `nodes`,
    data: request.data,
  });

type createNodeRequest = {
  data: {
    networkId: number;
    microChainId: number;
    specTypeId: number;
    nodes: [];
  };
};

type createNodeResponse = any;
//---------------------------------------------------------------------------------------

const getNodeList = async (request: getNodeListRequest): Promise<getNodeListResponse> =>
  await v2ServerByProjectAuth.get({
    path: `nodes`,
    query: makeQuery(request.query),
  });

type getNodeListRequest = {
  query: {
    projectId?: number;
    microChainId?: number;
    mainStatus?: number[];
  };
};

type getNodeListResponse = any;
//---------------------------------------------------------------------------------------

const getNodeDetail = async (request: getNodeDetailRequest): Promise<getNodeDetailResponse> =>
  await v2ServerByProjectAuth.get({
    path: `nodes/${request.params.nodeId}`,
  });

type getNodeDetailRequest = {
  params: {
    nodeId: number;
  };
};

type getNodeDetailResponse = any;
//---------------------------------------------------------------------------------------

const submitNode = async (request: submitNodeRequest): Promise<submitNodeResponse> =>
  await v2ServerByProjectAuth.post({
    path: `nodes/${request.params.nodeId}/submit`,
  });

type submitNodeRequest = {
  params: {
    nodeId: number;
  };
};

type submitNodeResponse = any;
//---------------------------------------------------------------------------------------

const confirmNode = async (request: confirmNodeRequest): Promise<confirmNodeResponse> =>
  await v2ServerByProjectAuth.post({
    path: `nodes/${request.params.nodeId}/confirm`,
  });

type confirmNodeRequest = {
  params: {
    nodeId: number;
  };
  data: {
    projectId: number;
  };
};

type confirmNodeResponse = any;
//---------------------------------------------------------------------------------------

const rejectNode = async (request: rejectNodeRequest): Promise<rejectNodeResponse> =>
  await v2ServerByProjectAuth.post({
    path: `nodes/${request.params.nodeId}/reject`,
    data: request.data,
  });

type rejectNodeRequest = {
  params: {
    nodeId: number;
  };
  data: {
    rejectReason: string;
  };
};

type rejectNodeResponse = any;
//---------------------------------------------------------------------------------------

const getNodeRejectReason = async (request: getNodeRejectReasonRequest): Promise<getNodeRejectReasonResponse> =>
  await v2ServerByProjectAuth.get({
    path: `nodes/${request.params.nodeId}/reject`,
  });

type getNodeRejectReasonRequest = {
  params: {
    nodeId: number;
  };
};

type getNodeRejectReasonResponse = any;
//---------------------------------------------------------------------------------------

const deployNode = async (request: deployNodeRequest): Promise<deployNodeResponse> =>
  await v2ServerByProjectAuth.post({
    path: `nodes/${request.params.nodeId}/release`,
  });

type deployNodeRequest = {
  params: {
    nodeId: number;
  };
};

type deployNodeResponse = any;
//---------------------------------------------------------------------------------------

const deleteNode = async (request: deleteNodeRequest): Promise<deleteNodeResponse> =>
  await v2ServerByProjectAuth.delete({
    path: `nodes/${request.params.nodeId}`,
  });

type deleteNodeRequest = {
  params: {
    nodeId: number;
  };
};

type deleteNodeResponse = any;
//---------------------------------------------------------------------------------------

export {
  createNode as createNodeFromServer,
  getNodeList as getNodeListFromServer,
  getNodeDetail as getNodeDetailFromServer,
  submitNode as submitNodeFromServer,
  confirmNode as confirmNodeFromServer,
  rejectNode as rejectNodeFromServer,
  getNodeRejectReason as getNodeRejectReasonFromServer,
  deployNode as deployNodeFromServer,
  deleteNode as deleteNodeFromServer,
};
