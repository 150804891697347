import { useMemo, useState } from "react";
import "./SignIn.scss";
import VerifyEmailModal from "../component/VerifyEmailModal";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import Logo_EQHub_SignIn from "../../../../assets/images/Logo_EQHub.png";
import emailIcon from "../../../../assets/images/Icon_sign_in_email.png";
import passwordIcon from "../../../../assets/images/Icon_sign_in_password.png";
import { enterKeyPress, errorAlert } from "../../../../../utils/Utils";
import DivideLine from "view/components/_atom/line/DivideLine";
import { login } from "server/index/utils";
import logo from "../../../../assets/images/signup_logo_EQHub.png";
import BasicButton from "view/components/_atom/button/BasicButton";
import ButtonContainer from "view/components/_atom/container/ButtonContainer";
import BasicInput from "view/components/_atom/input/BasicInput";
import { EQHUB_CS_EMAIL } from "view/service/user/constants/user.data";
import useValidateCondition from "hooks/useValidateCondition";
import { VALIDATION__USER_SIGNIN_otp } from "view/service/user/validation/userValidation";
import useIsAble from "hooks/useIsAble";
import InitialContentTemplate from "../../components/InitialContentTemplate";

export default function SignIn() {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [otpCode, setOtpCode] = useState<string>("");

  const [verifyEmailModalOpen, setVerifyModalOpen] = useState<boolean>(false);
  const [verifyEmailProps, setVerifyEmailProps] = useState({
    userId: -1,
    email: "",
  });

  const [isMFAOn, setIsMFAOn] = useState<boolean>(false);

  const ableToSignIn = useMemo(() => {
    return email.length > 0 && password.length > 0;
  }, [email, password]);

  const login_ = async () => {
    try {
      if (!ableToSignIn) {
        toast.error("Please enter email and password");
        return;
      }

      const result = await login(email, password, { ...(Boolean(otpCode) && { totpCode: otpCode }) });
      // TODO :: 이메일 인증여부를 클라에서 판단하지 않고
      // 서버에서 판단하여 에러를 내려주도록 수정 요청 필요
      if (result && result.emailVerification === false) {
        setVerifyEmailProps({ userId: result.id, email: result.email });
        setVerifyModalOpen(true);
      }
    } catch (e) {
      // TODO :: server error code === ?? -> 이메일 인증을 하지 않은 유저일 경우
      // 인증이메일을 보낼 때 userId를 넣어야하는 이유를 모르겠음
      // setVerifyEmailProps({ userId: result.id, email: result.email });
      // setVerifyModalOpen(true);

      // 매직리터럴 변경 (MFA를 설정한 유저가 totpKey를 보내지 않았을 경우)
      if (e.response.data.serverStatusCode === 37) {
        setIsMFAOn(true);
        return;
      }

      // 매직리터럴 변경 입력한 OTP가 유효하지 않은 OTP일 경우
      if (e.response.data.serverStatusCode === 38) {
        errorAlert("Please enter the correct OTP number");
        return;
      }

      // TODO :: 유효하지 않은 이메일이거나 비밀번호일 경우 조건 추가 필요
      toast.error("Please check the data you entered");
    }
  };

  return (
    <>
      {verifyEmailModalOpen && <VerifyEmailModal isOpen={verifyEmailModalOpen} setIsOpen={setVerifyModalOpen} verifyEmailProps={verifyEmailProps} />}
      <div id={"SignIn"}>
        {!isMFAOn && <DefaultSignIn email={email} setEmail={setEmail} password={password} setPassword={setPassword} requestLogin={login_} />}
        {isMFAOn && <MFAAuthentication otpCode={otpCode} setOtpCode={setOtpCode} requestLogin={login_} />}
        <span className={"copyright"}>ⓒ 2023. EQBR Holdings all rights reserved.</span>
      </div>
    </>
  );
}

type SignInProps = {
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  password: string;
  setPassword: React.Dispatch<React.SetStateAction<string>>;
  requestLogin: Function;
};

const DefaultSignIn = (props: SignInProps) => {
  const { email, setEmail, password, setPassword, requestLogin } = props;

  const navigate = useNavigate();

  return (
    <div className="sign-in">
      <div className="sign-in-container">
        <div className="sign-in-header">
          <div className="sign-in-header-title">
            <span className="sign-in-header-title-text">Welcome to</span>
            <img className="sign-in-header-title-logo" src={Logo_EQHub_SignIn} alt="logo" />
          </div>
          <span className="sign-in-header-comment">{"We make it easy for everyone to Build In\nreal Blockchain Network"}</span>
        </div>

        <div className="sign-in-body">
          <div className="sign-in-body-input-container">
            <div className="sign-in-body-input-box">
              <img src={emailIcon} className={"sign-in-body-input-icon"} alt={"ID"} />
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onKeyPress={(e) => enterKeyPress(e, requestLogin)}
                className="sign-in-body-input"
                placeholder={"Email"}
              />
            </div>
            <DivideLine />
            <div className="sign-in-body-input-box">
              <img src={passwordIcon} className={"sign-in-body-input-icon"} alt={"Password"} />
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onKeyPress={(e) => enterKeyPress(e, requestLogin)}
                className="sign-in-body-input"
                placeholder={"Password"}
              />
            </div>
          </div>

          <div className="sign-in-body-function-container">
            <div className="sign-in-body-notice"></div>
            <span className="sign-in-body-find-password" onClick={() => navigate("reset-password")}>
              Forgot password?
            </span>
          </div>
        </div>

        <div className="sign-in-button-container">
          <button className="sign-in-button sign-up" onClick={() => navigate("sign-up")}>
            Sign Up
          </button>
          <button className="sign-in-button sign-in" onClick={() => requestLogin()}>
            Sign In
          </button>
        </div>
      </div>
    </div>
  );
};

type MFAAuthenticationProps = {
  otpCode: string;
  setOtpCode: React.Dispatch<React.SetStateAction<string>>;
  requestLogin: Function;
};

const MFAAuthentication = (props: MFAAuthenticationProps) => {
  const { otpCode, setOtpCode, requestLogin } = props;

  const otpValidation = useValidateCondition([otpCode], VALIDATION__USER_SIGNIN_otp(otpCode));

  const isAble = useIsAble([otpValidation.isValid]);

  return (
    <div className="otp-authentication-container">
      <img src={logo} className="otp-authentication-logo" alt="" />

      <InitialContentTemplate
        title="Multi-Factor Authentication"
        description={`Enter the 6-digit OTP authentication number for EQ Hub account\ndisplayed on the Google OTP app`}
      >
        <div className="otp-authentication-content-body">
          <BasicInput
            value={otpCode}
            onChange={(e) => setOtpCode(e.target.value)}
            placeholder="Enter the OTP number"
            maxLength={6}
            onKeyPress={(e) => enterKeyPress(e, requestLogin)}
            autoFocus={true}
          />
        </div>
        <div className="otp-authentication-content-button-container">
          <ButtonContainer>
            <BasicButton onClick={() => requestLogin()} disabled={!isAble}>
              Confirm
            </BasicButton>
          </ButtonContainer>
        </div>
        <div className="otp-authentication-content-cs">
          <span>{`Do you have a problem with OTP authentication?\nPlease contact `}</span>
          <a className="otp-authentication-content-cs-email" href={`mailto:${EQHUB_CS_EMAIL}`}>
            {EQHUB_CS_EMAIL}
          </a>
        </div>
      </InitialContentTemplate>
    </div>
  );
};
