import {useEffect, useState} from 'react';
import Input from "../../../../legacy-common/atom/input/Input";
import RadioButtons from "../../../../legacy-common/molcules/RadioButtons/RadioButtons";

const DashboardMethodInputWrapper = (
  {
    setMethodInputs,
    methodInputs,
    input,
    index
  }) => {

  const [boolean, setBoolean] = useState(null)
  const Bool = [
    {
      id: 'boolean-true',
      value: 1,
      label: 'True'
    },
    {
      id: 'boolean-false',
      value: 0,
      label: 'False'
    },
  ]

  useEffect(() => {
    const newParams = methodInputs.map((el, idx) => {
      if (idx === index) {
        return {
          ...el,
          value: Boolean(boolean)
        }
      } else {
        return el;
      }
    })
    setMethodInputs(newParams)
  },[boolean])

  return (
    <div className='dashboard-method-input-wrapper'>
      <div className="dashboard-method-input-wrapper-name">
        {`${input.name} (${input.type})`}
      </div>
      {
        input.description &&
        <div className="dashboard-method-input-wrapper-description">
          {input.description}
        </div>
      }
      <div className="dashboard-method-input-wrapper-input">
        {
          input.type === 'bool' ?
            <div style={{marginTop: 20}}>
              <RadioButtons
                options={Bool}
                selectedValue={boolean}
                onChange={setBoolean}
              />
            </div>

            :
            <Input
              value={methodInputs[index]?.value ?? ''}
              onChange={(e) => {
                const newParams = methodInputs.map((el, idx) => {
                  if (idx === index) {
                    return {
                      ...el,
                      value: e.target.value
                    }
                  } else {
                    return el;
                  }
                })
                setMethodInputs(newParams)
              }}
              placeholder={`Required Type: ${input.type}`}/>
        }

      </div>
    </div>
  )
}

export default DashboardMethodInputWrapper;
