import React, { ReactNode, useState } from "react";
import "./QuestionMark.scss";

export enum QuestionMarkTheme {
  PRIMARY = "PRIMARY",
  SECONDARY = "SECONDARY",
}

type QuestionMarkProps = {
  announcement: string | ReactNode;
  theme?: QuestionMarkTheme;
};

const QuestionMark = (props: QuestionMarkProps) => {
  const { announcement, theme = QuestionMarkTheme.PRIMARY } = props;
  const [shownAnnouncement, setShownAnnouncement] = useState<boolean>(false);

  return (
    <div className={`question-mark ${theme}`} onMouseEnter={() => setShownAnnouncement(true)} onMouseLeave={() => setShownAnnouncement(false)}>
      <div className="question-mark-area">
        <span className="question-mark-area-label">?</span>
      </div>

      {shownAnnouncement && (
        <div className="question-mark-area-tooltip">
          <div className="question-mark-area-tooltip-area">{announcement}</div>
        </div>
      )}
    </div>
  );
};

export default QuestionMark;
