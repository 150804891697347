import { Navigate, Route, Routes } from "react-router-dom";
import SignUp from "./sign-up/SignUp";
import InitialEmailVerification from "./sign-up/InitialEmailVerification";
import FindPassword from "./find-password/page/FindPassword";
import SignIn from "./sign-in/page/SignIn";

const InitializationRouter = ({ userAccess, userInfo }) => {
  //로그인된 유저는 진입 불가
  if (!!userAccess && Boolean(userInfo.emailVerification)) return <Navigate to="/" />;
  else {
    return (
      <Routes>
        <Route index element={<SignIn />} />
        <Route path="sign-up" element={<SignUp />} />
        <Route path="verify-email" element={<InitialEmailVerification />} />
        <Route path="reset-password" element={<FindPassword />} />
      </Routes>
    );
  }
};
export default InitializationRouter;
