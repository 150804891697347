import {
  CurrencyDefaultImageTypeFromServer,
  MicroChainCurrenciesTypeFromServer,
  MicroChainCurrencyTypeFromServer,
  TokenCurrencyDetailTypeFromServer,
  TokenTypeFromServer,
} from "../type/currencyType";
import { makeQuery } from "../index/utils";
import { v2ServerByProjectAuth } from "../index/serverInstances";
import { GetTokenListOptions, TokenCurrencyDetailType } from "reducer/clientType/tokenClientType";

//----------createToken
const createCurrency = async (request: CreateCurrencyRequest): Promise<CreateCurrencyResponse> =>
  await v2ServerByProjectAuth.post({
    path: "currency/token",
    data: request.data,
  });

type CreateCurrencyRequest = {
  data: {
    name: string;
    image: string;
    symbol: string;
    originMicroChainId: number;
    currencyDetail: TokenCurrencyDetailType;
  };
};

type CreateCurrencyResponse = {
  currency_id: number;
  name: string;
  image: string;
  unit: string;
  symbol: string;
  origin_network_id: number;
  origin_micro_chain_id: number;
  origin_owner_id: number;
  type: number;
  main_status: number;
  sub_status: number;
  currency_detail: TokenCurrencyDetailTypeFromServer;
  micro_chain_currencies: Array<MicroChainCurrencyTypeFromServer>;
};

//----------getDefaultCurrencyImage
const getDefaultCurrencyImageList = async (): Promise<GetDefaultCurrencyImageResponse> =>
  await v2ServerByProjectAuth.get({
    path: "currency/default-image",
  });

type GetDefaultCurrencyImageResponse = Array<CurrencyDefaultImageTypeFromServer>;

//----------uploadCurrencyImage
const uploadCurrencyImage = async (request: UploadCurrencyImageRequest): Promise<UploadCurrencyImageResponse> =>
  await v2ServerByProjectAuth.post({
    path: "currency/upload-image",
    query: makeQuery(request.query),
    data: request.data,
    config: {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
  });

type UploadCurrencyImageRequest = {
  query: {
    fileCount: number;
  };
  data: FormData;
};

type UploadCurrencyImageResponse = {
  url: string;
};

//----------checkCurrencyName
const checkCurrencyName = async (request: CheckCurrencyNameRequest): Promise<CheckCurrencyNameResponse> =>
  await v2ServerByProjectAuth.post({
    path: "currency/check/name",
    data: request.data,
  });

type CheckCurrencyNameRequest = {
  data: {
    name: string;
  };
};

type CheckCurrencyNameResponse = {
  valid: boolean;
};

//----------getTokenList
const getTokenList = async (request: GetTokensRequest): Promise<GetTokensResponse> =>
  await v2ServerByProjectAuth.get({
    path: `currency/me`,
    query: makeQuery(request.query),
  });

type GetTokensRequest = {
  query: GetTokenListOptions;
};

type GetTokensResponse = Array<TokenTypeFromServer>;

//----------getCurrencyList
const getCurrencyList = async (request: GetCurrenciesRequest): Promise<GetCurrenciesResponse> =>
  await v2ServerByProjectAuth.get({
    path: `currency`,
    query: makeQuery(request.query),
  });

type GetCurrenciesRequest = {
  query: GetTokenListOptions;
};

type GetCurrenciesResponse = Array<TokenTypeFromServer>;

//----------getCurrencyDetail
const getCurrencyDetail = async (request: GetCurrencyDetailRequest): Promise<GetCurrencyDetailResponse> =>
  await v2ServerByProjectAuth.get({
    path: `currency/${request.params.currencyId}`,
  });

type GetCurrencyDetailRequest = {
  params: {
    currencyId: number;
  };
};

type GetCurrencyDetailResponse = {
  currency_id: number;
  name: string;
  image: string;
  unit: string;
  symbol: string;
  origin_network_id: number;
  origin_micro_chain_id: number;
  origin_owner_id: number;
  type: number;
  main_status: number;
  sub_status: number;
  transaction_hash?: string;
  currency_detail: TokenCurrencyDetailTypeFromServer;
  micro_chain_currencies: Array<MicroChainCurrencyTypeFromServer>;
};

//----------updateCurrencyDetail
const updateCurrencyDetail = async (request: UpdateCurrencyDetailRequest): Promise<UpdateCurrencyDetailResponse> =>
  await v2ServerByProjectAuth.put({
    path: `currency/${request.params.currencyId}`,
    data: request.data,
  });

type UpdateCurrencyDetailRequest = {
  params: {
    currencyId: number;
  };
  data: {
    name: string;
    image: string;
    symbol: string;
    currencyDetail: TokenCurrencyDetailType;
  };
};

type UpdateCurrencyDetailResponse = {
  currency_id: number;
  name: string;
  image: string;
  unit: string;
  symbol: string;
  origin_network_id: number;
  origin_micro_chain_id: number;
  origin_owner_id: number;
  type: number;
  main_status: number;
  sub_status: number;
  currency_detail: TokenCurrencyDetailTypeFromServer;
  micro_chain_currencies: Array<MicroChainCurrencyTypeFromServer>;
};

//----------deleteCurrency
const deleteCurrency = async (request: DeleteCurrencyRequest): Promise<DeleteCurrencyResponse> =>
  await v2ServerByProjectAuth.delete({
    path: `currency/${request.params.currencyId}`,
  });

type DeleteCurrencyRequest = {
  params: {
    currencyId: number;
  };
};

type DeleteCurrencyResponse = {
  success: boolean;
};

//----------submitCurrency
const submitCurrency = async (request: SubmitCurrencyRequest): Promise<SubmitCurrencyResponse> =>
  await v2ServerByProjectAuth.post({
    path: `currency/${request.params.currencyId}/submit`,
  });

type SubmitCurrencyRequest = {
  params: {
    currencyId: number;
  };
};

type SubmitCurrencyResponse = {
  currency_id: number;
  name: string;
  image: string;
  unit: string;
  symbol: string;
  origin_network_id: number;
  origin_micro_chain_id: number;
  origin_owner_id: number;
  type: number;
  main_status: number;
  sub_status: number;
  currency_detail: TokenCurrencyDetailTypeFromServer;
  micro_chain_currencies: Array<MicroChainCurrencyTypeFromServer>;
};

//----------registerCurrencyToWallet
const registerCurrencyToWallet = async (request: RegisterCurrencyToWalletRequest): Promise<RegisterCurrencyToWalletResponse> =>
  await v2ServerByProjectAuth.post({
    path: `currency/${request.params.currencyId}/use-wallet`,
  });

type RegisterCurrencyToWalletRequest = {
  params: {
    currencyId: number;
  };
};

type RegisterCurrencyToWalletResponse = {
  success: boolean;
};

//----------getMicroChainCurrencies
const getMicroChainCurrencyList = async (request: GetMicroChainCurrencyListRequest): Promise<GetMicroChainCurrencyListResponse> =>
  await v2ServerByProjectAuth.get({
    path: `micro-chain-currency`,
    query: makeQuery(request.query),
  });

type GetMicroChainCurrencyListRequest = {
  query: {
    microChainId: number;
  };
};

type GetMicroChainCurrencyListResponse = Array<MicroChainCurrenciesTypeFromServer>;

//----------getMicroChainCurrencies
const getMicroChainCurrencyDetail = async (request: GetMicroChainCurrencyDetailRequest): Promise<GetMicroChainCurrencyDetailResponse> =>
  await v2ServerByProjectAuth.get({
    path: `micro-chain-currency/${request.params.microChainCurrencyId}`,
  });

type GetMicroChainCurrencyDetailRequest = {
  params: {
    microChainCurrencyId: number;
  };
};
type GetMicroChainCurrencyDetailResponse = MicroChainCurrencyTypeFromServer & {
  currency: TokenTypeFromServer;
};

//----------getMicroChainCurrencies
const getCoinByMicroChainId = async (request: GetCoinByMicroChainIdRequest): Promise<GetCoinByMicroChainIdResponse> =>
  await v2ServerByProjectAuth.get({
    path: `micro-chain-currency/coin`,
    query: makeQuery(request.query),
  });

type GetCoinByMicroChainIdRequest = {
  query: {
    microChainId: number;
  };
};

type GetCoinByMicroChainIdResponse = MicroChainCurrenciesTypeFromServer;

export {
  createCurrency as createCurrencyFromServer,
  getDefaultCurrencyImageList as getDefaultCurrencyImageListFromServer,
  uploadCurrencyImage as uploadCurrencyImageFromServer,
  checkCurrencyName as checkCurrencyNameFromServer,
  getTokenList as getTokenListFromServer,
  getCurrencyList as getCurrencyListFromServer,
  getCurrencyDetail as getCurrencyDetailFromServer,
  updateCurrencyDetail as updateCurrencyDetailFromServer,
  deleteCurrency as deleteCurrencyFromServer,
  submitCurrency as submitCurrencyFromServer,
  registerCurrencyToWallet as registerCurrencyToWalletFromServer,
  getMicroChainCurrencyList as getMicroChainCurrencyListFromServer,
  getMicroChainCurrencyDetail as getMicroChainCurrencyDetailFromServer,
  getCoinByMicroChainId as getCoinByMicroChainIdFromServer,
};
