import { requestTransactionToExtension } from "modules/EQExtension/utils/messageUtils";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setRawTransactionResult,
  transaction_result,
} from "store/redux/transaction/transactionSlice";
import { errorAlert } from "utils/Utils";

const useTransaction = (transaction: Function, callback: Function) => {
  const dispatch = useDispatch();

  const { rawTransactionResult } = useSelector(transaction_result);

  async function requestTransaction() {
    const { result, error } = await transaction();

    if (result) {
      requestTransactionToExtension({ ...result });
    }

    if (error) {
      switch (error.type) {
        case "API":
          errorAlert(error.data.message);
          break;
        case "Module":
          errorAlert(`${error.data.argument}::${error.data.code}`);
          break;
        default:
          errorAlert(error.data.message);
      }
    }
  }

  useEffect(() => {
    if (Boolean(rawTransactionResult.tx_hash)) {
      callback();
      dispatch(setRawTransactionResult({ tx_hash: "" }));
    }
  }, [rawTransactionResult]);

  return { requestTransaction };
};

export default useTransaction;
