import { parseAction } from "./util/parseAction";
import {
  getExternalAndInnerMicroChainListParser,
  getMicroChainListParser,
} from "../parser/microChainParser";
import {
  getMicroChainCurrencyDetailParser,
  getMicroChainCurrencyListParser,
} from "../parser/currencyParser";
import {
  createPaymentMethodParser,
  deletePaymentMethodParser,
  getDepositHistoryParser,
  getDepositListForContractTypeParser,
  getPaymentMethodDetailParser,
  getPaymentMethodsParser,
  getSelectedPaymentGatewayTypeParser,
  getWithdrawalHistoryParser,
  resendDepositParser,
  retryDepositAlertForContractTypeParser,
  retryDepositAlertParser,
  retryWithdrawalAlertParser,
  submitPaymentMethodParser,
  updatePaymentMethodParser,
} from "../parser/paymentMethodParser";
import {
  GetDepositHistoryOptions,
  GetDepositListOptionsForSmartContractType,
  GetWithdrawalHistoryOptions,
} from "../reducer/clientType/paymentMethodClientType";
import { getMicroChainIdsOfProjectParser } from "../parser/projectParser";
import _ from "lodash";
import { getListedContractDetailParser } from "parser/contractParser";
import { SYSTEM_CONTRACT } from "constants/commonConstants";
import { getWallet20sParser } from "parser/wallet20Parser";

export const getDataForCreateVirtualAccountPaymentMethodAction = () =>
  parseAction(async () => {
    let microChains: Array<any> = [];
    const microChainIdsOfProject = await getMicroChainIdsOfProjectParser();
    if (microChainIdsOfProject.length !== 0) {
      microChains = await getMicroChainListParser({
        microChainIds: microChainIdsOfProject,
        mainStatus: [5],
      });
    }
    return microChains;
  });

export const getDataForCreateSmartContractPaymentMethodAction = () =>
  parseAction(async () => {
    let microChains: Array<any> = [];
    const microChainIdsOfProject = await getMicroChainIdsOfProjectParser();
    if (microChainIdsOfProject.length !== 0) {
      microChains = await getExternalAndInnerMicroChainListParser({
        microChainIds: microChainIdsOfProject,
        mainStatus: [5],
      });
    }
    return microChains;
  });

export const getCurrenciesBySelectedMicroChainAction = (microChainId: number) =>
  parseAction(async () => {
    const result = await getMicroChainCurrencyListParser(microChainId);
    return result;
  });

export const createPaymentMethodAction = (
  paymentMethodName: string,
  microChainId: number,
  microChainCurrencyId: number,
  paymentTypeIds: Array<number>,
  walletAddress: string,
  depositCallbackURI: string,
  withdrawalCallbackURI: string,
  options?: { blockConfirmationCount: number }
) =>
  parseAction(async () => {
    return await createPaymentMethodParser(
      paymentMethodName,
      microChainId,
      microChainCurrencyId,
      paymentTypeIds,
      walletAddress,
      depositCallbackURI,
      withdrawalCallbackURI,
      options
    );
  });

export const createPaymentMethodForSmartContractAction = (
  paymentMethodName: string,
  microChainId: number,
  microChainCurrencyId: number,
  paymentTypeIds: Array<number>,
  walletAddress: string,
  depositCallbackURI: string,
  withdrawalCallbackURI: string,
  blockConfirmationCount: number
) =>
  parseAction(async () => {
    return await createPaymentMethodParser(
      paymentMethodName,
      microChainId,
      microChainCurrencyId,
      paymentTypeIds,
      walletAddress,
      depositCallbackURI,
      withdrawalCallbackURI,
      { blockConfirmationCount }
    );
  });

export const getPaymentMethodListAction = () =>
  parseAction(async () => {
    const paymentMethods = await getPaymentMethodsParser();
    const microChains = await getExternalAndInnerMicroChainListParser({
      mainStatus: [5],
    });
    const microChainCurrencyIds = _.uniq(
      paymentMethods.map((el) => el.microChainCurrencyId)
    );

    const currencyDetails = await Promise.all(
      microChainCurrencyIds.map(async (id) => {
        return await getMicroChainCurrencyDetailParser(id);
      })
    );
    const response = await Promise.all(
      paymentMethods.map(async (paymentMethod) => {
        const selectedPaymentGatewayType =
          await getSelectedPaymentGatewayTypeParser(
            paymentMethod.paymentMethodId
          );
        return {
          ...paymentMethod,
          paymentTypeId: selectedPaymentGatewayType[0].paymentTypeId,
          microChainLabel: microChains.find(
            (chain) => chain.id === paymentMethod.microChainId
          )?.label,
          currencyDetail: currencyDetails.find(
            (currency) =>
              currency.microChainCurrencyId ===
              paymentMethod.microChainCurrencyId
          ),
        };
      })
    );

    return _.sortBy(response, "createdAt").reverse();
  });

export const getPaymentMethodDetailAction = (paymentMethodId: string) =>
  parseAction(async () => {
    const paymentMethodDetail = await getPaymentMethodDetailParser(
      paymentMethodId
    );
    const microChains = await getExternalAndInnerMicroChainListParser({
      mainStatus: [5],
    });
    const currency = await getMicroChainCurrencyDetailParser(
      paymentMethodDetail.microChainCurrencyId
    );

    let wallet20: object | undefined = undefined;
    if (currency.contractAddress !== SYSTEM_CONTRACT) {
      const targetCurrencyContractId = currency.contractId;

      const wallet20s = await getWallet20sParser({
        contractIds: [targetCurrencyContractId],
      });
      wallet20 = wallet20s[0];
    }
    const selectedPaymentGatewayType =
      await getSelectedPaymentGatewayTypeParser(
        paymentMethodDetail.paymentMethodId
      );

    let deployTransactionHash: string | null = null;
    if (
      paymentMethodDetail.mainStatus === 4 &&
      paymentMethodDetail.contractId
    ) {
      const { transactionHash } = await getListedContractDetailParser(
        paymentMethodDetail.contractId
      );
      deployTransactionHash = transactionHash;
    }

    return {
      ...paymentMethodDetail,
      depositCallbackURI: selectedPaymentGatewayType[0].setting.callbackURI,
      paymentTypeId: selectedPaymentGatewayType[0].paymentTypeId,
      networkLabel: microChains?.find(
        (chain) => chain.id === paymentMethodDetail?.microChainId
      )?.network?.label,
      currencyDetail: currency,
      wallet20,
      deployTransactionHash,
    };
  });

export const updatePaymentMethodAction = (
  paymentMethodId: string,
  walletAddress: string,
  depositCallbackURI: string,
  withdrawalCallbackURI: string
) =>
  parseAction(async () => {
    const result = await updatePaymentMethodParser(
      paymentMethodId,
      walletAddress,
      depositCallbackURI,
      withdrawalCallbackURI
    );
    const microChains = await getExternalAndInnerMicroChainListParser({
      mainStatus: [5],
    });
    const currency = await getMicroChainCurrencyDetailParser(
      result.microChainCurrencyId
    );
    const selectedPaymentGatewayType =
      await getSelectedPaymentGatewayTypeParser(result.paymentMethodId);

    return {
      ...result,
      depositCallbackURI: selectedPaymentGatewayType[0].setting.callbackURI,
      paymentTypeId: selectedPaymentGatewayType[0].paymentTypeId,
      microChainLabel: microChains.find(
        (chain) => chain.id === result.microChainId
      ).label,
      currencyDetail: currency,
    };
  });

export const submitPaymentMethodAction = (paymentMethodId: string) =>
  parseAction(async () => {
    await submitPaymentMethodParser(paymentMethodId);
    const paymentMethodDetail = await getPaymentMethodDetailParser(
      paymentMethodId
    );
    const microChains = await getExternalAndInnerMicroChainListParser({
      mainStatus: [5],
    });
    const currency = await getMicroChainCurrencyDetailParser(
      paymentMethodDetail.microChainCurrencyId
    );
    const selectedPaymentGatewayType =
      await getSelectedPaymentGatewayTypeParser(
        paymentMethodDetail.paymentMethodId
      );

    const response = {
      ...paymentMethodDetail,
      depositCallbackURI: selectedPaymentGatewayType[0].setting.callbackURI,
      paymentTypeId: selectedPaymentGatewayType[0].paymentTypeId,
      networkLabel: microChains?.find(
        (chain) => chain.id === paymentMethodDetail?.microChainId
      )?.network?.label,
      currencyDetail: currency,
    };

    return response;
  });

export const deletePaymentMethodAction = (paymentMethodId: string) =>
  parseAction(async () => {
    return await deletePaymentMethodParser(paymentMethodId);
  });

// 가상계좌 방식 구매객체 목록 조회
export const getDepositHistoryAction = (
  paymentMethodId: string,
  limit: number,
  offset: number,
  options?: GetDepositHistoryOptions
) =>
  parseAction(async () => {
    return await getDepositHistoryParser(
      paymentMethodId,
      limit,
      offset,
      options
    );
  });

// 가상계좌 방식 구매객체 알림 보내기
export const retryDepositAlertAction = (
  virtualAddressTransactionId: number,
  virtualAddressId: number
) =>
  parseAction(async () => {
    return await retryDepositAlertParser(
      virtualAddressTransactionId,
      virtualAddressId
    );
  });

// 가상계좌 방식 구매객체 송금 재시도
export const resendDepositAction = (
  virtualAddressTransactionId: number,
  virtualAddressId: number
) =>
  parseAction(async () => {
    return await resendDepositParser(
      virtualAddressTransactionId,
      virtualAddressId
    );
  });

// 컨트랙트 방식 구매객체 목록 조회
export const getDepositListForContractTypeAction = (
  paymentMethodId: string,
  limit: number,
  offset: number,
  options?: GetDepositListOptionsForSmartContractType
) =>
  parseAction(async () => {
    return await getDepositListForContractTypeParser(
      paymentMethodId,
      limit,
      offset,
      options
    );
  });

// 컨트랙트 방식 구매객체 알림 보내기
export const retryDepositAlertForContractTypeAction = (
  contractTransactionId: number
) =>
  parseAction(async () => {
    return await retryDepositAlertForContractTypeParser(contractTransactionId);
  });

// 환불객체 목록 조회 (가상계좌, 컨트랙트)
export const getWithdrawalHistoryAction = (
  paymentMethodId: string,
  limit: number,
  offset: number,
  options?: GetWithdrawalHistoryOptions
) =>
  parseAction(async () => {
    return await getWithdrawalHistoryParser(
      paymentMethodId,
      limit,
      offset,
      options
    );
  });

// 환불객체 알림 보내기 (가상계좌, 컨트랙트)
export const retryWithdrawalAlertAction = (withdrawalId: number) =>
  parseAction(async () => {
    return await retryWithdrawalAlertParser(withdrawalId);
  });
