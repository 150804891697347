import { useState } from "react";
import { useParams } from "react-router-dom";
import { errorAlert } from "../../../../../utils/Utils";
import "./ManageNFT.scss";
import { getNFTDetailByTokenIdAction } from "action/nftAction";
import PageArticle from "view/components/_template/page/PageArticle";
import PageTitle from "view/components/_molecules/page/PageTitle";
import PageLoading from "view/components/_atom/loading/PageLoading";
import { isDisplayTransactionStatus, parseServiceStatus } from "../../../../../utils/status.utils";
import { NFT_STATUS } from "../constant/nftConstant";
import TransactionStatus from "../../../../components/_atom/transactionStatus/TransactionStatus";
import CopyLabelWithIcon from "../../../../components/_atom/label/CopyLabelWithIcon";
import useLoading from "hooks/useLoading";
import { ManageFieldContainer, ManageFieldLabel, RowManageField } from "view/components/_atom/section/ManageField";
import nftIcon from "../../../../assets/images/service/Icon_SNB_non_fungible_token.png";

const ManageNft = () => {
  const { id: nftProjectId, tokenId } = useParams();

  const [tokenDetail, setTokenDetail] = useState({});
  const [nftStatus, setNftStatus] = useState(null);

  const getNFTDetail_ = async () => {
    const { result, error } = await getNFTDetailByTokenIdAction(parseInt(nftProjectId), tokenId);
    if (result) {
      const { mainStatus, subStatus } = result;
      const targetStatus = parseServiceStatus(NFT_STATUS, mainStatus, subStatus);
      setNftStatus(targetStatus);
      setTokenDetail(result);
    }
    if (error) {
      errorAlert(error.data.message);
    }
  };

  const fileRenderer = (fileType) => {
    switch (fileType) {
      case "image":
        return <img src={tokenDetail.imageUrl} className={"nft-content"} alt={"file"} />;
      case "video":
        return (
          <video controls className={"nft-content"}>
            <source src={tokenDetail.animationUrl} />
          </video>
        );
      case "audio":
        return (
          <div className="nft-audio-wrapper">
            <img src={tokenDetail.imageUrl} className={"nft-audio-preview"} alt={"file"} />
            <audio controls className={"nft-audio"} src={tokenDetail.animationUrl} />
          </div>
        );
    }
  };

  const { loading } = useLoading({
    dependency: [nftProjectId, tokenId],
    synchronousFunction: async () => {
      await getNFTDetail_();
    },
  });

  if (loading) {
    return <PageLoading />;
  }

  return (
    <PageArticle>
      <PageTitle title={"NFT Detail"} size={810} />
      <div className="page-layout-810">
        {isDisplayTransactionStatus(NFT_STATUS[nftStatus]) && (
          <TransactionStatus
            {...(tokenDetail?.transactionHash && { transactionHash: tokenDetail.transactionHash })}
            statusDetail={NFT_STATUS[nftStatus].statusDetail}
            microChainId={tokenDetail?.microChainId}
          />
        )}
        <ManageFieldContainer icon={nftIcon} title="NFT Info">
          <RowManageField>
            <ManageFieldLabel label="Name" />
            <span>{`${tokenDetail?.title ?? "-"}`}</span>
          </RowManageField>
          <RowManageField>
            <ManageFieldLabel label="Description" />
            <span>{`${tokenDetail?.description ?? "-"}`}</span>
          </RowManageField>
          <RowManageField>
            <ManageFieldLabel label="File" />
            <div className="manage-nft-content-wrapper">{fileRenderer(tokenDetail?.type)}</div>
          </RowManageField>
          <RowManageField>
            <ManageFieldLabel label="NFT Project name" />
            <span>{`${tokenDetail?.projectName ?? "-"}`}</span>
          </RowManageField>
          <RowManageField>
            <ManageFieldLabel label="Contract address" />
            <CopyLabelWithIcon text={tokenDetail?.contractAddress} />
          </RowManageField>
        </ManageFieldContainer>
      </div>
    </PageArticle>
  );
};

export default ManageNft;
