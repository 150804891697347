import React, { useMemo, useState } from "react";
import "./EditProjectModal.scss";
import IconSelector, { IconTheme } from "../../../components/_molecules/select/IconSelector";
import ButtonContainer from "../../../components/_atom/container/ButtonContainer";
import BasicButton from "../../../components/_atom/button/BasicButton";
import { ButtonSize, ButtonTheme } from "../../../components/_atom/button/StyledButton";
import ModalTemplateB from "../../../components/_template/modal/ModalTemplateB";
import BasicTextarea from "../../../components/_atom/input/BasicTextarea";
import NetworkSelector from "../../../components/_atom/select/NetworkSelector";
import { getInitialDataForUpdateProjectAction, updateProjectAction } from "../../../../action/projectAction";
import { errorAlert } from "utils/Utils";
import PageLoading from "../../../components/_atom/loading/PageLoading";
import { useSelector } from "react-redux";
import { project_access } from "../../../../reducer/projectReducer";
import { toast } from "react-hot-toast";
import { requiredIndex } from "../../../../utils/textUtils";
import useGetSelectedProject from "../../../../hooks/useGetSelectedProject";
import _ from "lodash";
import CommentInput from "../../../components/_atom/input/CommentInput";
import useValidateCondition from "../../../../hooks/useValidateCondition";
import useIsAble from "../../../../hooks/useIsAble";
import useLoading from "hooks/useLoading";
import { VALIDATION__PROJECT_name } from "../validation/projectValidation";

const INIT_PROJECT_DETAIL = {
  createdAt: "",
  creatorId: -1,
  description: "",
  id: -1,
  image: "",
  name: "",
  pricingPlanId: -1,
  selectedMicroChainIds: [],
};

type EditProjectModalProps = {
  EditProjectModalOpen: boolean;
  setEditProjectModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setEndpointList: React.Dispatch<React.SetStateAction<any>>;
};

const EditProjectModal = (props: EditProjectModalProps) => {
  const { EditProjectModalOpen, setEditProjectModalOpen, setEndpointList } = props;

  const projectAccess = useSelector(project_access);
  const { selectedProject, selectedProjectID } = useGetSelectedProject();

  const [projectDetail, setProjectDetail] = useState(INIT_PROJECT_DETAIL);
  const [projectDefaultImageList, setProjectDefaultImageList] = useState<Array<string>>([]);
  const [networkList, setNetworkList] = useState<Array<any>>([]);
  const [previousSelectedMicroChainIds, setPreviousSelectedMicroChainIds] = useState([]);

  const isDifferentChainIds = useMemo(
    () => !_.isEqual(projectDetail.selectedMicroChainIds, previousSelectedMicroChainIds),
    [projectDetail.selectedMicroChainIds, previousSelectedMicroChainIds]
  );

  const setProjectName = (value: string) => {
    setProjectDetail((prevState: any) => ({
      ...prevState,
      name: value,
    }));
  };
  const setProjectImage = (value: File | string) => {
    setProjectDetail((prevState: any) => ({
      ...prevState,
      image: value,
    }));
  };
  const setProjectDescription = (value: string) => {
    setProjectDetail((prevState: any) => ({
      ...prevState,
      description: value,
    }));
  };

  const setSelectedMicroChainIds = (id: number) => {
    setProjectDetail((prevState: any) => {
      const { selectedMicroChainIds } = prevState;
      if (selectedMicroChainIds.includes(id)) {
        const result = selectedMicroChainIds.filter((selectedId: number) => selectedId !== id);
        return { ...prevState, selectedMicroChainIds: result };
      } else {
        const result = selectedMicroChainIds.concat(id);
        return { ...prevState, selectedMicroChainIds: result };
      }
    });
  };

  const getInitialData_ = async () => {
    const { result, error } = await getInitialDataForUpdateProjectAction(selectedProjectID);

    if (result) {
      const { defaultImage, networkList, projectDetail } = result;
      setProjectDefaultImageList(defaultImage);
      setNetworkList(networkList);
      setProjectDetail(projectDetail);
      setPreviousSelectedMicroChainIds(projectDetail.selectedMicroChainIds);
    }

    if (error) {
      errorAlert(error.data.message);
    }
  };

  const updateProject_ = async () => {
    const { result, error } = await updateProjectAction(
      projectDetail.name,
      projectDetail.description,
      projectDetail.image,
      projectDetail.selectedMicroChainIds
    );

    if (result) {
      toast.success("project information has been modified");
      setEndpointList(result);
      setEditProjectModalOpen(false);
    }

    if (error) {
      errorAlert(error.data.message);
    }
  };

  const projectNameValidation = useValidateCondition([projectDetail.name], VALIDATION__PROJECT_name(projectDetail.name));

  const isAble = useIsAble([
    projectNameValidation.isValid,
    projectDetail.name !== selectedProject.name ||
      projectDetail.image !== selectedProject.image ||
      projectDetail.description !== selectedProject.description ||
      isDifferentChainIds,
  ]);

  const { loading } = useLoading({
    dependency: [projectAccess],
    synchronousFunction: async () => {
      await getInitialData_();
    },
  });

  return (
    <ModalTemplateB title={"Project Info"} isOpen={EditProjectModalOpen} onRequestClose={() => setEditProjectModalOpen(false)}>
      <div id={"EditProjectModal"}>
        <div className="project-info-modal-content">
          {loading ? (
            <PageLoading />
          ) : (
            <>
              <div className="project-info-modal-content-row-wrapper">
                <div className="project-info-modal-content-row">
                  <div className="project-info-modal-content-row-label">
                    <div className="project-info-modal-content-row-label-square" />
                    <span className="project-info-modal-content-row-label-text">{requiredIndex("Name")}</span>
                  </div>
                  <div className="project-info-modal-content-row-value">
                    <CommentInput
                      {...projectNameValidation}
                      placeholder={"Enter the name of project"}
                      value={projectDetail.name}
                      onChange={(e) => setProjectName(e.target.value)}
                    />
                  </div>
                </div>

                <div className="project-info-modal-content-row">
                  <div className="project-info-modal-content-row-label">
                    <div className="project-info-modal-content-row-label-square" />
                    <span className="project-info-modal-content-row-label-text">{requiredIndex("Image")}</span>
                  </div>
                  <div className="project-info-modal-content-row-value">
                    <IconSelector
                      iconTheme={IconTheme.CIRCLE}
                      images={projectDefaultImageList}
                      fileState={projectDetail.image}
                      setFileState={setProjectImage}
                    />
                  </div>
                </div>

                <div className="project-info-modal-content-row">
                  <div className="project-info-modal-content-row-label">
                    <div className="project-info-modal-content-row-label-square" />
                    <span className="project-info-modal-content-row-label-text">Description</span>
                  </div>
                  <div className="project-info-modal-content-row-value">
                    <BasicTextarea
                      placeholder={"Enter the description of project"}
                      value={projectDetail.description}
                      onChange={(e) => setProjectDescription(e.target.value)}
                    />
                  </div>
                </div>

                <div className="project-info-modal-content-row">
                  <div className="project-info-modal-content-row-label">
                    <div className="project-info-modal-content-row-label-square" />
                    <span className="project-info-modal-content-row-label-text">Network</span>
                  </div>
                  <div className="project-info-modal-content-row-value">
                    <NetworkSelector networkList={networkList} selectedList={projectDetail.selectedMicroChainIds} setSelectedList={setSelectedMicroChainIds} />
                  </div>
                </div>
              </div>

              <div className="project-info-modal-button-wrapper">
                <ButtonContainer>
                  <BasicButton theme={ButtonTheme.SECONDARY} size={ButtonSize.LARGE} onClick={() => setEditProjectModalOpen(false)}>
                    Back
                  </BasicButton>
                  <BasicButton disabled={!isAble} size={ButtonSize.LARGE} onClick={() => updateProject_()}>
                    Save
                  </BasicButton>
                </ButtonContainer>
              </div>
            </>
          )}
        </div>
      </div>
    </ModalTemplateB>
  );
};
export default EditProjectModal;
