import AirdropOperatingWayPurposeBox from "../atom/AirdropOperatingWayPurposeBox";
import "./AirdropOperatingWayPurposeList.scss";

const AirdropOperatingWayPurposeList = (props) => {
  const { recommendationList, type, onClickTypeButton } = props || {};

  return (
    <div className="airdrop-operating-way-purpose-list">
      {recommendationList?.map((el, index) => {
        return (
          <AirdropOperatingWayPurposeBox key={`airdrop-operating-way-purpose-${index}`} type={type} data={el} onClickTypeButton={onClickTypeButton} />
        );
      })}
    </div>
  );
};

export default AirdropOperatingWayPurposeList;
