import React from "react";
import "./ModalTemplateA.scss";
import Modal from "react-modal";
import { customStyles } from "../../../view/styles/customStyles";

const ModalTemplateA = (props) => {
  const { isOpen, closeModal, title, children, shouldCloseOnOverlayClick = true } = props || {};
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={customStyles}
      overlayClassName="modal-overlay-style"
      ariaHideApp={false}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
    >
      <div className="modal-template-A">
        <div className="modal-template-A-header">{title}</div>
        <div className="modal-template-A-content">{children}</div>
      </div>
    </Modal>
  );
};

export default ModalTemplateA;
