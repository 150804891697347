import React, { useEffect, useRef, useState } from "react";
import ModalTemplateA from "../../../legacy-common/template/modal/ModalTemplateA";
import "./CurrencyImageUploadModal.scss";
import { Navigation } from "swiper";
import "swiper/scss";
import "swiper/scss/navigation";
import ImageUpload from "../_atom/file/ImageUpload";
import { Swiper, SwiperSlide } from "swiper/react";
import Icon_slider_left_prev_normal from "../../../view/assets/images/atom/Icon_slider_left_prev_normal.png";
import Icon_slider_right_prev_normal from "../../../view/assets/images/atom/Icon_slider_right_prev_normal.png";
import { getDefaultTokenImageAction } from "action/tokenAction";
import BasicButton from "../_atom/button/BasicButton";
import { ButtonSize, ButtonTheme } from "view/components/_atom/button/StyledButton";
import ButtonContainer from "../_atom/container/ButtonContainer";

const defaultCurrencyImageStyle = {
  width: 130,
  height: 130,
  borderRadius: "50%",
  objectFit: "contain",
};

const CurrencyImageUploadModal = (props) => {
  const { title, currencyImageModalOpen, setCurrencyImageModalOpen, currencyImage, setCurrencyImage } = props || {};

  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  const [preCurrencyImage, setPreCurrencyImage] = useState(currencyImage ?? null);
  const [defaultCurrencyImage, setDefaultCurrencyImage] = useState([]);

  const setDefaultCurrencyImageFromServer = async () => {
    const { result, error } = await getDefaultTokenImageAction();
    if (result) {
      const currencyImage = result.map((img) => {
        return {
          ...img,
          image: <img src={img.url} className={"default-image"} style={{ ...defaultCurrencyImageStyle }} alt={"default"} />,
        };
      });
      setDefaultCurrencyImage(currencyImage);
    }
    if (error) {
      console.error(error.data.message);
    }
  };

  const onClickSelect = () => {
    setCurrencyImage(preCurrencyImage);
    setCurrencyImageModalOpen(false);
  };

  useEffect(() => {
    setDefaultCurrencyImageFromServer();
  }, []);

  return (
    <ModalTemplateA title={title} isOpen={currencyImageModalOpen} closeModal={() => setCurrencyImageModalOpen(false)}>
      <section className="token-image-upload-modal-content">
        <p className="token-image-upload-modal-content-comment">{"Please upload the prepared image."}</p>
        <div className="flex justify-content-center" style={{ marginBottom: 40 }}>
          <ImageUpload image={preCurrencyImage} setImage={setPreCurrencyImage} previewStyle={{ borderRadius: "50%" }} buttonText={"Image select"} />
        </div>
        <p className="token-image-upload-modal-content-comment">{"We prepared the basic image."}</p>
        <div className="token-image-upload-modal-content-slider">
          <img src={Icon_slider_left_prev_normal} className="swiper-button-prev" ref={navigationPrevRef} alt={"navigation"} />
          <Swiper
            navigation={{
              nextEl: navigationNextRef.current,
              prevEl: navigationPrevRef.current,
            }}
            modules={[Navigation]}
            spaceBetween={40}
            slidesPerView={3}
            className={"swiper-wrapper"}
          >
            {defaultCurrencyImage.map((item, index) => (
              <SwiperSlide key={index}>
                <div onClick={() => setPreCurrencyImage(item.url)}>{item.image}</div>
              </SwiperSlide>
            ))}
          </Swiper>
          <img src={Icon_slider_right_prev_normal} ref={navigationNextRef} className="swiper-button-next" alt={"navigation"} />
        </div>
        <div className="token-image-upload-modal-button-container">
          <ButtonContainer>
            <BasicButton size={ButtonSize.LARGE} theme={ButtonTheme.SECONDARY} onClick={() => setCurrencyImageModalOpen(false)}>
              Cancel
            </BasicButton>
            <BasicButton size={ButtonSize.LARGE} onClick={() => onClickSelect()} disabled={!preCurrencyImage}>
              Select
            </BasicButton>
          </ButtonContainer>
        </div>
      </section>
    </ModalTemplateA>
  );
};

export default CurrencyImageUploadModal;
