import { useState, useEffect } from "react";
import "./AirdropConditionLimitSelector.scss";
import Select from "../../../../../components/_atom/select/Select";
import { AIRDROP_PARTICIPANT_COUNT_LIST, AIRDROP_PARTICIPANT_LIMIT_PERIOD_LIST } from "../../constants/airdropConstants";

const AirdropConditionLimitSelector = (props) => {
  const { participationLimitData, setParticipationLimitData } = props || {};

  const [selectedPeriod, setSelectedPeriod] = useState(null);
  const [selectedCount, setSelectedCount] = useState(null);

  useEffect(() => {
    setSelectedPeriod(participationLimitData.period);
    setSelectedCount(participationLimitData.count);
  }, []);

  useEffect(() => {
    setParticipationLimitData((prevState) => {
      return { ...prevState, period: selectedPeriod };
    });
  }, [selectedPeriod]);

  useEffect(() => {
    setParticipationLimitData((prevState) => {
      return { ...prevState, count: selectedCount };
    });
  }, [selectedCount]);

  return (
    <div className="airdrop-condition-limit-selector">
      <p style={{ marginBottom: "20px" }}>Project participants can participate up to</p>
      <div className="airdrop-condition-limit-selector-container">
        <Select
          labelKey={"label"}
          valueKey={"value"}
          options={AIRDROP_PARTICIPANT_COUNT_LIST}
          selectedValue={selectedCount}
          setSelectedValue={setSelectedCount}
          // style={{ width: 240, marginRight: "20px" }}
          placeholder={"set count"}
        />
        <Select
          labelKey={"label"}
          valueKey={"value"}
          options={AIRDROP_PARTICIPANT_LIMIT_PERIOD_LIST}
          selectedValue={selectedPeriod}
          setSelectedValue={setSelectedPeriod}
          // style={{ width: 240 }}
          placeholder={"set period"}
        />
      </div>
    </div>
  );
};

export default AirdropConditionLimitSelector;
