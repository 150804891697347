import { makeQuery } from "server/index/utils";
import { v2ServerByProjectAuth } from "../index/serverInstances";

const getParticipationPeriodTypes = async (): Promise<getParticipationPeriodTypesResponse> =>
  await v2ServerByProjectAuth.get({
    path: `airdrops/participation-period-types`,
  });

type getParticipationPeriodTypesResponse = any;
//---------------------------------------------------------------------------------------

const getDistributionTypes = async (): Promise<getDistributionTypesResponse> =>
  await v2ServerByProjectAuth.get({
    path: `airdrops/distribution-types`,
  });

type getDistributionTypesResponse = any;
//---------------------------------------------------------------------------------------

const getDistributionAmountTypes = async (): Promise<getDistributionAmountTypesResponse> =>
  await v2ServerByProjectAuth.get({
    path: `airdrops/distribution-amount-types`,
  });

type getDistributionAmountTypesResponse = any;
//---------------------------------------------------------------------------------------

const getParticipationConditionTypes = async (): Promise<getParticipationConditionTypesResponse> =>
  await v2ServerByProjectAuth.get({
    path: `airdrops/participation-condition-types`,
  });

type getParticipationConditionTypesResponse = any;
//---------------------------------------------------------------------------------------

const airdropUploadImage = async (request: airdropUploadImageRequest): Promise<airdropUploadImageResponse> =>
  await v2ServerByProjectAuth.post({
    path: `airdrops/upload-image`,
    query: makeQuery(request.query),
    data: request.data,
    config: {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
  });

type airdropUploadImageRequest = {
  query: {
    fileCount: number;
  };
  data: FormData;
};

type airdropUploadImageResponse = any;
//---------------------------------------------------------------------------------------

const createAirdrop = async (request: createAirdropRequest): Promise<createAirdropResponse> =>
  await v2ServerByProjectAuth.post({
    path: `airdrops`,
    data: request.data,
  });

type createAirdropRequest = {
  data: any;
};

type createAirdropResponse = any;
//---------------------------------------------------------------------------------------

const getAirdropList = async (request: getAirdropListRequest): Promise<getAirdropListResponse> =>
  await v2ServerByProjectAuth.get({
    path: `airdrops/me`,
    query: makeQuery(request.query),
  });

type getAirdropListRequest = {
  query: {
    projectId?: number;
    creatorId?: number;
    lastId?: number;
    limit?: number;
  };
};

type getAirdropListResponse = any;
//---------------------------------------------------------------------------------------

const getAsset = async (request: getAssetRequest): Promise<getAssetResponse> =>
  await v2ServerByProjectAuth.get({
    path: `airdrops/${request.params.airdropId}/asset`,
  });

type getAssetRequest = {
  params: {
    airdropId: number;
  };
};

type getAssetResponse = any;
//---------------------------------------------------------------------------------------

const getServerKeyAddress = async (request: getServerKeyAddressRequest): Promise<getServerKeyAddressResponse> =>
  await v2ServerByProjectAuth.get({
    path: `airdrops/${request.params.airdropId}/server-key`,
  });

type getServerKeyAddressRequest = {
  params: {
    airdropId: number;
  };
};

type getServerKeyAddressResponse = any;
//---------------------------------------------------------------------------------------

const getAirdropDetail = async (request: getAirdropDetailRequest): Promise<getAirdropDetailResponse> =>
  await v2ServerByProjectAuth.get({
    path: `airdrops/${request.params.airdropId}`,
  });

type getAirdropDetailRequest = {
  params: {
    airdropId: number;
  };
};

type getAirdropDetailResponse = any;
//---------------------------------------------------------------------------------------

const updateAirdrop = async (request: updateAirdropRequest): Promise<updateAirdropResponse> =>
  await v2ServerByProjectAuth.put({
    path: `airdrops/${request.params.airdropId}`,
    data: request.data,
  });

type updateAirdropRequest = {
  params: {
    airdropId: number;
  };
  data: {};
};

type updateAirdropResponse = any;
//---------------------------------------------------------------------------------------

const deleteAirdrop = async (request: deleteAirdropRequest): Promise<deleteAirdropResponse> =>
  await v2ServerByProjectAuth.delete({
    path: `airdrops/${request.params.airdropId}`,
  });

type deleteAirdropRequest = {
  params: {
    airdropId: number;
  };
};

type deleteAirdropResponse = any;
//---------------------------------------------------------------------------------------

const submitAirdrop = async (request: submitAirdropRequest): Promise<submitAirdropResponse> =>
  await v2ServerByProjectAuth.post({
    path: `airdrops/${request.params.airdropId}/request-review`,
  });

type submitAirdropRequest = {
  params: {
    airdropId: number;
  };
};

type submitAirdropResponse = any;
//---------------------------------------------------------------------------------------

const getMarketingChannels = async (request: getMarketingChannelsRequest): Promise<getMarketingChannelsResponse> =>
  await v2ServerByProjectAuth.get({
    path: `airdrops/${request.params.airdropId}/marketing-channels`,
  });

type getMarketingChannelsRequest = {
  params: {
    airdropId: number;
  };
};

type getMarketingChannelsResponse = any;
//---------------------------------------------------------------------------------------

const getParticipationPeriodTypesByAirdropId = async (
  request: getParticipationPeriodTypesByAirdropIdRequest
): Promise<getParticipationPeriodTypesByAirdropIdResponse> =>
  await v2ServerByProjectAuth.get({
    path: `airdrops/${request.params.airdropId}/participation-period-types`,
  });

type getParticipationPeriodTypesByAirdropIdRequest = {
  params: {
    airdropId: number;
  };
};

type getParticipationPeriodTypesByAirdropIdResponse = any;
//---------------------------------------------------------------------------------------

const getDistributionTypesByAirdropId = async (request: getDistributionTypesByAirdropIdRequest): Promise<getDistributionTypesByAirdropIdResponse> =>
  await v2ServerByProjectAuth.get({
    path: `airdrops/${request.params.airdropId}/distribution-types`,
  });

type getDistributionTypesByAirdropIdRequest = {
  params: {
    airdropId: number;
  };
};

type getDistributionTypesByAirdropIdResponse = any;
//---------------------------------------------------------------------------------------

const getDistributionAmountTypesByAirdropId = async (
  request: getDistributionAmountTypesByAirdropIdRequest
): Promise<getDistributionAmountTypesByAirdropIdResponse> =>
  await v2ServerByProjectAuth.get({
    path: `airdrops/${request.params.airdropId}/distribution-amount-types`,
  });

type getDistributionAmountTypesByAirdropIdRequest = {
  params: {
    airdropId: number;
  };
};

type getDistributionAmountTypesByAirdropIdResponse = any;
//---------------------------------------------------------------------------------------

const getDistributionAmountsByAirdropDistributionAmountTypeId = async (
  request: getDistributionAmountsByAirdropDistributionAmountTypeIdRequest
): Promise<getDistributionAmountsByAirdropDistributionAmountTypeIdResponse> =>
  await v2ServerByProjectAuth.get({
    path: `airdrops/${request.params.airdropId}/distribution-amounts/${request.params.airdropDistributionAmountTypeId}`,
  });

type getDistributionAmountsByAirdropDistributionAmountTypeIdRequest = {
  params: {
    airdropId: number;
    airdropDistributionAmountTypeId: number;
  };
};

type getDistributionAmountsByAirdropDistributionAmountTypeIdResponse = any;
//---------------------------------------------------------------------------------------

const getParticipationConditionTypesByAirdropId = async (
  request: getParticipationConditionTypesByAirdropIdRequest
): Promise<getParticipationConditionTypesByAirdropIdResponse> =>
  await v2ServerByProjectAuth.get({
    path: `airdrops/${request.params.airdropId}/participation-condition-types`,
  });

type getParticipationConditionTypesByAirdropIdRequest = {
  params: {
    airdropId: number;
  };
};

type getParticipationConditionTypesByAirdropIdResponse = any;
//---------------------------------------------------------------------------------------

const getParticipationLimit = async (request: getParticipationLimitRequest): Promise<getParticipationLimitResponse> =>
  await v2ServerByProjectAuth.get({
    path: `airdrops/${request.params.airdropId}/participation-limit/${request.params.airdropParticipationConditionTypeId}`,
  });

type getParticipationLimitRequest = {
  params: {
    airdropId: number;
    airdropParticipationConditionTypeId: number;
  };
};

type getParticipationLimitResponse = any;
//---------------------------------------------------------------------------------------

const getParticipationEvents = async (request: getParticipationEventsRequest): Promise<getParticipationEventsResponse> =>
  await v2ServerByProjectAuth.get({
    path: `airdrops/${request.params.airdropId}/participation-events/${request.params.airdropParticipationConditionTypeId}`,
  });

type getParticipationEventsRequest = {
  params: {
    airdropId: number;
    airdropParticipationConditionTypeId: number;
  };
};

type getParticipationEventsResponse = any;
//---------------------------------------------------------------------------------------

const checkDeposit = async (request: checkDepositRequest): Promise<checkDepositResponse> =>
  await v2ServerByProjectAuth.post({
    path: `airdrops/${request.params.airdropId}/check-deposit`,
  });

type checkDepositRequest = {
  params: {
    airdropId: number;
  };
};

type checkDepositResponse = any;
//---------------------------------------------------------------------------------------

const getDistributionFee = async (request: getDistributionFeeRequest): Promise<getDistributionFeeResponse> =>
  await v2ServerByProjectAuth.get({
    path: `airdrops/${request.params.airdropId}/distribution-fee`,
  });

type getDistributionFeeRequest = {
  params: {
    airdropId: number;
  };
};

type getDistributionFeeResponse = any;
//---------------------------------------------------------------------------------------

const checkDistributionFee = async (request: checkDistributionFeeRequest): Promise<checkDistributionFeeResponse> =>
  await v2ServerByProjectAuth.post({
    path: `airdrops/${request.params.airdropId}/check-distribution-fee`,
  });

type checkDistributionFeeRequest = {
  params: {
    airdropId: number;
  };
};

type checkDistributionFeeResponse = any;
//---------------------------------------------------------------------------------------

const airdropPublish = async (request: airdropPublishRequest): Promise<airdropPublishResponse> =>
  await v2ServerByProjectAuth.post({
    path: `airdrops/${request.params.airdropId}/release-review`,
  });

type airdropPublishRequest = {
  params: {
    airdropId: number;
  };
};

type airdropPublishResponse = any;
//---------------------------------------------------------------------------------------

const getAirdropParticipations = async (request: getAirdropParticipationsRequest): Promise<getAirdropParticipationsResponse> =>
  await v2ServerByProjectAuth.get({
    path: `airdrops/${request.params.airdropId}/participation`,
    query: makeQuery(request.query),
  });

type getAirdropParticipationsRequest = {
  params: {
    airdropId: number;
  };
  query: {
    limit: number;
    offset: number;
    participationStartDate: Date;
    participationEndDate: Date;
  };
};

type getAirdropParticipationsResponse = any;
//---------------------------------------------------------------------------------------

const getAirdropLimitParticipantDetailByAddress = async (
  request: getAirdropLimitParticipantDetailByAddressRequest
): Promise<getAirdropLimitParticipantDetailByAddressResponse> =>
  await v2ServerByProjectAuth.get({
    path: `airdrops/${request.params.airdropId}/participation/${request.params.address}/limit`,
  });

type getAirdropLimitParticipantDetailByAddressRequest = {
  params: {
    airdropId: number;
    address: String;
  };
};

type getAirdropLimitParticipantDetailByAddressResponse = any;
//---------------------------------------------------------------------------------------

const downloadAirdropBountyTemplate = async (request: downloadAirdropBountyTemplateRequest): Promise<downloadAirdropBountyTemplateResponse> =>
  await v2ServerByProjectAuth.post({
    path: `airdrops/${request.params.airdropId}/download-template`,
  });

type downloadAirdropBountyTemplateRequest = {
  params: {
    airdropId: number;
  };
};

type downloadAirdropBountyTemplateResponse = any;
//---------------------------------------------------------------------------------------

const downloadAirdropBountyResult = async (request: downloadAirdropBountyResultRequest): Promise<downloadAirdropBountyResultResponse> =>
  await v2ServerByProjectAuth.post({
    path: `airdrops/${request.params.airdropId}/download-result`,
  });

type downloadAirdropBountyResultRequest = {
  params: {
    airdropId: number;
  };
};

type downloadAirdropBountyResultResponse = any;
//---------------------------------------------------------------------------------------

const uploadAirdropBountyResult = async (request: uploadAirdropBountyResultRequest): Promise<uploadAirdropBountyResultResponse> =>
  await v2ServerByProjectAuth.post({
    path: `airdrops/${request.params.airdropId}/upload-result`,
    query: makeQuery(request.query),
    data: request.data,
    config: {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
  });

type uploadAirdropBountyResultRequest = {
  params: {
    airdropId: number;
  };
  query: {
    fileCount: number;
  };
  data: FormData;
};

type uploadAirdropBountyResultResponse = any;
//---------------------------------------------------------------------------------------

const reuploadAirdropBountyResult = async (request: reuploadAirdropBountyResultRequest): Promise<reuploadAirdropBountyResultResponse> =>
  await v2ServerByProjectAuth.post({
    path: `airdrops/${request.params.airdropId}/reupload-result`,
  });

type reuploadAirdropBountyResultRequest = {
  params: {
    airdropId: number;
  };
};

type reuploadAirdropBountyResultResponse = any;
//---------------------------------------------------------------------------------------

const airdropBountyDistribute = async (request: airdropBountyDistributeRequest): Promise<airdropBountyDistributeResponse> =>
  await v2ServerByProjectAuth.post({
    path: `airdrops/${request.params.airdropId}/distribute`,
  });

type airdropBountyDistributeRequest = {
  params: {
    airdropId: number;
  };
};

type airdropBountyDistributeResponse = any;
//---------------------------------------------------------------------------------------

const airdropBountyForceClose = async (request: airdropBountyForceCloseRequest): Promise<airdropBountyForceCloseResponse> =>
  await v2ServerByProjectAuth.post({
    path: `airdrops/${request.params.airdropId}/force-close`,
  });

type airdropBountyForceCloseRequest = {
  params: {
    airdropId: number;
  };
};

type airdropBountyForceCloseResponse = any;
//---------------------------------------------------------------------------------------

export {
  getParticipationPeriodTypes as getParticipationPeriodTypesFromServer,
  getDistributionTypes as getDistributionTypesFromServer,
  getDistributionAmountTypes as getDistributionAmountTypesFromServer,
  getParticipationConditionTypes as getParticipationConditionTypesFromServer,
  airdropUploadImage as airdropUploadImageFromServer,
  createAirdrop as createAirdropFromServer,
  getAirdropList as getAirdropListFromServer,
  getAsset as getAssetFromServer,
  getServerKeyAddress as getServerKeyAddressFromServer,
  getAirdropDetail as getAirdropDetailFromServer,
  updateAirdrop as updateAirdropFromServer,
  deleteAirdrop as deleteAirdropFromServer,
  submitAirdrop as submitAirdropFromServer,
  getMarketingChannels as getMarketingChannelsFromServer,
  getParticipationPeriodTypesByAirdropId as getParticipationPeriodTypesByAirdropIdFromServer,
  getDistributionTypesByAirdropId as getDistributionTypesByAirdropIdFromServer,
  getDistributionAmountTypesByAirdropId as getDistributionAmountTypesByAirdropIdFromServer,
  getDistributionAmountsByAirdropDistributionAmountTypeId as getDistributionAmountsByAirdropDistributionAmountTypeIdFromServer,
  getParticipationConditionTypesByAirdropId as getParticipationConditionTypesByAirdropIdFromServer,
  getParticipationLimit as getParticipationLimitFromServer,
  getParticipationEvents as getParticipationEventsFromServer,
  checkDeposit as checkDepositFromServer,
  getDistributionFee as getDistributionCountFromServer,
  checkDistributionFee as checkDistributionFeeFromServer,
  airdropPublish as airdropPublishFromServer,
  getAirdropParticipations as getAirdropParticipationsFromServer,
  getAirdropLimitParticipantDetailByAddress as getAirdropLimitParticipantDetailByAddressFromServer,
  downloadAirdropBountyResult as downloadAirdropBountyResultFromServer,
  downloadAirdropBountyTemplate as downloadAirdropBountyTemplateFromServer,
  uploadAirdropBountyResult as uploadAirdropBountyResultFromServer,
  reuploadAirdropBountyResult as reuploadAirdropBountyResultFromServer,
  airdropBountyDistribute as airdropBountyDistributeFromServer,
  airdropBountyForceClose as airdropBountyForceCloseFromServer,
};
