import {
  getContractAbiCodeFromServer,
  getContractDetailFromServer,
  getContractLibraryDetailFromServer,
} from "logic/services/Contract/server";
import { actionController } from "../utils";
import { getProjectsParser } from "parser/projectParser";
import { CONTRACT_TYPE } from "logic/services/Contract/server/index.type";

export const contractController = {
  getContractDetail: (contractId: number) => {
    return actionController(async () => {
      try {
        const contract = await getContractDetailFromServer({
          params: {
            contractId,
          },
          query: {},
        });
        if (!contract) {
          throw new Error("contract_detail_get_fail");
        }
        const contractLibrary = await getContractLibraryDetailFromServer({
          params: {
            contractId: contract.origin_contract_id,
            contractVersionId: contract.origin_contract_version_id,
          },
          query: {},
        });
        if (!contract) {
          throw new Error("contractLibrary_detail_get_fail");
        }
        const projectIds = [contractLibrary.contract.project_id];
        const projectInfo = await getProjectsParser({ projectIds });
        if (!projectInfo) {
          throw new Error("project_Info_get_fail");
        }

        const contract_type_labeling =
          CONTRACT_TYPE[contract.type] || "UNKNOWN";

        const projectName = projectInfo[0].name;
        const contractDetail = {
          ...contract,
          contractLibrary: {
            ...contractLibrary,
            project: projectName,
            contract_type_labeling,
          },
        };
        return contractDetail;
      } catch (e) {
        throw new Error("contract_Info_get_fail");
      }
    });
  },

  getContractConstructor: (contractId: number) => {
    return actionController(async () => {
      try {
        const abiCode = await getContractAbiCodeFromServer({
          params: {
            contractId,
          },
          query: {},
        });
        if (Array.isArray(abiCode)) {
          const constructorAbi = abiCode.filter(
            (item) => item.type === "constructor"
          );
          return constructorAbi[0].inputs;
        }
        if (abiCode.type === "constructor") {
          return [abiCode.inputs];
        }
        throw new Error("contract_constructor_not_exist");
      } catch (e) {
        throw new Error("contract_constructor_get_fail");
      }
    });
  },
  //token kit 배포 전 ContractLibrary애 있는 정보 조회
  getContractLibraryDetailWithOriginId: (
    origin_contract_id: number,
    origin_contract_version_id: number
  ) => {
    return actionController(async () => {
      try {
        const contractLibrary = await getContractLibraryDetailFromServer({
          params: {
            contractId: origin_contract_id,
            contractVersionId: origin_contract_version_id,
          },
          query: {},
        });
        return contractLibrary;
      } catch (e) {
        throw new Error("contract_Library_detail_get_fail");
      }
    });
  },

  //token kit 배포 후 Contract 정보 조회
  getContractDetailWithContractId: (contractId: number) => {
    return actionController(async () => {
      try {
        const contract = await getContractDetailFromServer({
          params: {
            contractId,
          },
          query: {},
        });
        return contract;
      } catch (e) {
        throw new Error("contract_detail_get_fail");
      }
    });
  },
};
