import {
  CONTINUOUS_NUMBER_REG_EXP,
  ENG_CAPITAL_LETTERS_REG_EXP,
  EQHUB_PASSWORD_REG_EXP,
  REPEAT_STR_NUM_REG_EXP,
  FLOAT_REG_EXP,
  NUMBER_REG_EXP,
  URL_REG_EXP,
  VERSION_REG_EXP,
} from "../../constants/commonConstants";
import { WEB3 } from "../web3/Web3";
import BigNumber from "bignumber.js";
import moment from "moment";
import isEmail from "validator/es/lib/isEmail";
import { checkConsecutiveByCondition } from "utils/userValidateUtils";

// 충족 = false
// 불충족 = true

// 숫자 0은 값이 있다라고 판단하겠음.
export const VC__is_empty_value = (value: any) => {
  const checkEmptyValue = value === null || value === undefined || value === "";
  if (checkEmptyValue) {
    return true;
  }
  if (typeof value === "string" && Boolean(value.trim().length <= 0)) {
    return true;
  }
  return false;
};

export const VC__is_array_empty_value = (value: []) => {
  if (Array.isArray(value) && value.length <= 0) {
    return true;
  }
  return false;
};

export const VC__is_text_exceed_limit = (value: string, limit: number) => {
  if (typeof value !== "string") {
    return;
  }
  return value.length > limit;
};

// 이상
export const VC__is_equal_or_more_than = (value: number, standard: number) => {
  // value >= standard
  const BN_value = new BigNumber(value);
  const BN_standardValue = new BigNumber(standard);
  return BN_value.gte(BN_standardValue);
};

// 초과
export const VC__is_more_than = (value: number, standard: number) => {
  // value > standard
  const BN_value = new BigNumber(value);
  const BN_standardValue = new BigNumber(standard);
  return BN_value.gt(BN_standardValue);
};

// 이하
export const VC__is_equal_or_less_than = (value: number, standard: number) => {
  // value <= standard
  const BN_value = new BigNumber(value);
  const BN_standardValue = new BigNumber(standard);
  return BN_value.lte(BN_standardValue);
};

// 미만
export const VC__is_less_than = (value: number, standard: number) => {
  // value < standard
  const BN_value = new BigNumber(value);
  const BN_standardValue = new BigNumber(standard);
  return BN_value.lt(BN_standardValue);
};

// 같지 않다면 - 숫자
export const VC__is_not_same_number = (value: number, standard: number) => {
  // value !== standard
  const BN_value = new BigNumber(value);
  const BN_standardValue = new BigNumber(standard);
  return !BN_value.isEqualTo(BN_standardValue);
};

// 같지 않다면 - 문자열
export const VC__is_not_same_string = (value: string, standard: string) => {
  // value !== standard
  return value !== standard;
};

export const VC__is_not_URL = (url: string) => {
  return !URL_REG_EXP.test(url);
};

export const VC_is_not_number = (value: string) => {
  return !NUMBER_REG_EXP.test(value);
};

export const VC_is_not_float = (value: string) => {
  return !FLOAT_REG_EXP.test(value);
};

export const VC__is_not_capital_letter = (text: string) => {
  return !ENG_CAPITAL_LETTERS_REG_EXP.test(text);
};

export const VC__is_not_email = (email: string) => {
  return !isEmail(email);
};

export const VC__is_not_string_less_than_greater_than = (value: string, less: number, greater: number) => {
  return value.length < less || value.length > greater;
};

export const VC__is_not_strong_password_condition = (password: string) => {
  return !EQHUB_PASSWORD_REG_EXP.test(password);
};

export const VC__is_not_repeat_str_num = (password: string) => {
  return REPEAT_STR_NUM_REG_EXP.test(password);
};

export const VC__is_not_consec_str_on_keyboard = (password: string) => {
  let qwerty: any = "qwertyuiopasdfghjklzxcvbnm";
  qwerty = qwerty + qwerty.toUpperCase();
  return checkConsecutiveByCondition(qwerty, password);
};

export const VC__is_not_consec_str_in_alphabet = (password: string) => {
  let alpha = "abcdefghijklmnopqrstuvwxyz";
  alpha = alpha + alpha.toUpperCase();
  return checkConsecutiveByCondition(alpha, password);
};

export const VC__is_not_consec_number = (password: string) => {
  return CONTINUOUS_NUMBER_REG_EXP.test(password);
};

export const VC__is_not_over_age = (birthday: Date, standardAge: number = 14) => {
  return moment().diff(moment(birthday).format("YYYY/MM/DD"), "years") < standardAge;
};

export const VC__is_not_address = (address: string) => {
  return Boolean(address.slice(0, 2) !== "0x") || address.length !== 42;
};

export const VC__is_not_WEB3_address = (address: string) => {
  return !WEB3.isAddress(address);
};

export const VC__is_not_version = (version: string) => {
  return !VERSION_REG_EXP.test(version);
};

export const distinguishValidation = (validationList: Array<{ isValid: boolean; message: string }>) => {
  return validationList.find((el) => !el.isValid) ?? { isValid: true, message: "" };
};
