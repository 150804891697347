import ListTypeBCard from "./ListTypeBCard";

const ListTypeB = ({ listElement, renderIndex, renderer, titleKey, imageKey, onClick, hasStatus, containerStyle }) => {
  return (
    <>
      {listElement.map((el, index) => {
        return (
          <ListTypeBCard
            key={index}
            imageKey={imageKey}
            title={el[titleKey]}
            renderIndex={renderIndex}
            renderer={renderer}
            dataObject={el}
            onClick={onClick}
            hasStatus={hasStatus}
            containerStyle={containerStyle}
          />
        );
      })}
    </>
  );
};

export default ListTypeB;
