import { useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import FileDragAndDrop from "../../../../../components/_atom/file/FileDragAndDrop";
import { errorAlert } from "../../../../../../utils/Utils";
import {
  airdropBountyDistributeAction,
  downloadAirdropBountyResultAction,
  downloadAirdropBountyTemplateAction,
  reuploadAirdropBountyResultAction,
  uploadAirdropBountyResultAction,
} from "action/airdropAction";
import { ManageFieldContainer, ManageFieldLabel, ManageFieldWrapper, RowManageField } from "view/components/_atom/section/ManageField";
import airdropIcon from "../../../../../assets/images/service/Icon_SNB_airdrop.png";
import BasicButton from "view/components/_atom/button/BasicButton";
import { ButtonSize } from "view/components/_atom/button/StyledButton";
import DivideLine from "view/components/_atom/line/DivideLine";

const ManageAirdropDistributionContainer = (props) => {
  const { airdropId, airdropDetail, getAirdropDetail_ } = props || {};

  const [excelFile, setExcelFile] = useState();

  const distributionStatus = useMemo(() => {
    return airdropDetail.subStatus;
  }, [airdropDetail.subStatus]);

  const downloadAirdropBountyResult_ = async () => {
    const { result, error } = await downloadAirdropBountyResultAction(airdropId);
    if (result) {
      const { participateListFile } = result || {};
      window.open(participateListFile);
    }
    if (error) {
      errorAlert(error.data.message);
    }
  };

  const onClickAirdropResult = async () => {
    await downloadAirdropBountyResult_();
    await getAirdropDetail_();
  };

  const downloadAirdropBountyTemplate_ = async () => {
    const { result, error } = await downloadAirdropBountyTemplateAction(airdropId);
    if (result) {
      const { templateFile } = result || {};
      window.open(templateFile);
    }
    if (error) {
      errorAlert(error.data.message);
    }
  };

  const onClickAirdropTemplate = async () => {
    await downloadAirdropBountyTemplate_();
    await getAirdropDetail_();
  };

  const uploadAirdropBountyResult_ = async () => {
    let formData = new FormData();
    formData.append("winnerListFile", excelFile);
    const { result, error } = await uploadAirdropBountyResultAction(airdropId, formData);
    if (result) {
      toast.success("processed successfully");
    }
    if (error) {
      errorAlert(error.data.message);
    }
  };

  const onClickUploadAirdropBountyResult = async () => {
    await uploadAirdropBountyResult_();
    await getAirdropDetail_();
  };

  const reuploadAirdropBountyResult_ = async () => {
    const { result, error } = await reuploadAirdropBountyResultAction(airdropId);
    if (result) {
      return;
    }
    if (error) {
      errorAlert(error.data.message);
    }
  };

  const reuploadHandler = async () => {
    await reuploadAirdropBountyResult_();
    await getAirdropDetail_();
  };

  useEffect(() => {
    if (excelFile) {
      reuploadHandler();
    }
  }, [excelFile]);

  const airdropBountyDistribute_ = async () => {
    const { result, error } = await airdropBountyDistributeAction(airdropId);
    if (result) {
      toast.success("processed successfully");
      return;
    }
    if (error) {
      errorAlert(error.data.message);
    }
  };

  const onClickAirdropBountyDistribute = async () => {
    await airdropBountyDistribute_();
    await getAirdropDetail_();
  };
  // const renderer = () => [
  //   {
  //     label: "Participants",
  //     renderers: [
  //       {
  //         index: "Participation result",
  //         value: <Button className="list-round" text="Download" onClick={() => onClickAirdropResult()} />,
  //       },
  //     ],
  //   },
  //   {
  //     label: "Winners",
  //     renderers: [
  //       {
  //         index: "Template",
  //         value: <Button className="list-round" text="Download template" onClick={() => onClickAirdropTemplate()} disabled={distributionStatus !== 1} />,
  //       },
  //       {
  //         index: "Winner document",
  //         value: (
  //           <div className="section-wrapper-content-data-row-value flex-column" style={{ width: "100 %" }}>
  //             <FileDragAndDrop file={excelFile} setFile={setExcelFile} allowExtension={["xlsx"]} disabled={distributionStatus < 1} />
  //             <div style={{ marginTop: "5px" }}>Please input the winner's information into the template and upload it.</div>
  //             <div style={{ marginBottom: "20px", color: "#CF7171" }}>Invalid winners file. Please review the file and upload it again.</div>
  //             <Button
  //               className="list-round"
  //               text="Check"
  //               onClick={() => onClickUploadAirdropBountyResult()}
  //               disabled={!Boolean(excelFile) || distributionStatus !== 1}
  //             />
  //           </div>
  //         ),
  //       },
  //     ],
  //   },
  //   {
  //     renderers: [
  //       {
  //         value: (
  //           <div style={{ display: "flex", justifyContent: "end" }}>
  //             <Button text={"Distribute"} className={"main"} onClick={() => onClickAirdropBountyDistribute()} disabled={distributionStatus !== 2} />
  //           </div>
  //         ),
  //       },
  //     ],
  //   },
  // ];

  return (
    <>
      <ManageFieldContainer icon={airdropIcon} title="Distribution">
        <ManageFieldWrapper title="Participants">
          <RowManageField>
            <ManageFieldLabel label="Participation result" />
            <BasicButton size={ButtonSize.DYNAMIC} onClick={() => onClickAirdropResult()}>
              Download
            </BasicButton>
          </RowManageField>
        </ManageFieldWrapper>
        <DivideLine style={{ margin: "20px 0" }} />
        <ManageFieldWrapper title="Winners">
          <RowManageField>
            <ManageFieldLabel label="Template" />
            <BasicButton size={ButtonSize.DYNAMIC} onClick={() => onClickAirdropTemplate()} disabled={distributionStatus !== 1}>
              Download template
            </BasicButton>
          </RowManageField>
          <RowManageField>
            <ManageFieldLabel label="Winner document" />
            <div className="section-wrapper-content-data-row-value" style={{ width: "100%" }}>
              <FileDragAndDrop file={excelFile} setFile={setExcelFile} allowExtension={["xlsx"]} disabled={distributionStatus < 1} />
              <div style={{ marginTop: "5px", fontSize: 14 }}>Please input the winner's information into the template and upload it.</div>
              <div style={{ marginBottom: "20px", fontSize: 14, color: "#CF7171" }}>Invalid winners file. Please review the file and upload it again.</div>

              <BasicButton
                size={ButtonSize.DYNAMIC}
                onClick={() => onClickUploadAirdropBountyResult()}
                disabled={!Boolean(excelFile) || distributionStatus !== 1}
              >
                Check
              </BasicButton>
            </div>
          </RowManageField>
        </ManageFieldWrapper>
        <DivideLine style={{ margin: "20px 0" }} />
        <div className="flex justify-content-flex-end">
          <BasicButton onClick={() => onClickAirdropBountyDistribute()} disabled={distributionStatus !== 2}>
            Distribute
          </BasicButton>
        </div>
      </ManageFieldContainer>
      {/* <Section title={"Distribution"} view={renderer} /> */}
    </>
  );
};

export default ManageAirdropDistributionContainer;
