import React from "react";
import "./DeleteMFAModal.scss";
import ModalTemplateB from "view/components/_template/modal/ModalTemplateB";
import ButtonContainer from "view/components/_atom/container/ButtonContainer";
import BasicButton from "view/components/_atom/button/BasicButton";
import { ButtonSize, ButtonTheme } from "view/components/_atom/button/StyledButton";
import { deleteTotpKeyAction } from "action/userAction";
import { useSelector } from "react-redux";
import { user_id } from "reducer/userReducer";

type DeleteMFAModalProps = {
  isOpen: boolean;
  closeModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const DeleteMFAModal = (props: DeleteMFAModalProps) => {
  const { isOpen, closeModal } = props;

  const userID = useSelector(user_id);

  const onRequestClose = () => {
    closeModal(false);
  };

  const deleteTotpKey_ = async () => {
    const { result, error } = await deleteTotpKeyAction(userID);

    if (result) {
      closeModal(false);
    }

    if (error) {
    }
  };

  return (
    <ModalTemplateB isOpen={isOpen} onRequestClose={onRequestClose} title={"Set up Multi-Factor Authentication"}>
      <div className="delete-mfa-modal">
        <div className="delete-mfa-modal-description">
          {`Do you want to delete the OTP information?\nIf you delete it, Multi-Factor Authentication will be disabled.`}
        </div>
        <ButtonContainer>
          <BasicButton size={ButtonSize.MIDDLE} theme={ButtonTheme.SECONDARY} onClick={() => onRequestClose()}>
            Cancel
          </BasicButton>
          <BasicButton size={ButtonSize.MIDDLE} onClick={() => deleteTotpKey_()}>
            Delete
          </BasicButton>
        </ButtonContainer>
      </div>
    </ModalTemplateB>
  );
};

export default DeleteMFAModal;
