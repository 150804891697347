import "./AirdropDepositModal.scss";
import { useSelector } from "react-redux";
import { SYSTEM_CONTRACT } from "../../../../../../constants/commonConstants";
import { WEB3 } from "../../../../../../modules/web3/Web3";
import ModalTemplateA from "../../../../../../legacy-common/template/modal/ModalTemplateA";
import { connectInfo } from "../../../../../../store/redux/EQconnect/EQConnectSlice";
import { addComma } from "../../../../../../utils/Utils";
import { makeTransactionAction } from "../../../../../../action/requestAction";
import bulletPoint from "../../../../../assets/images/bullet-point-airdrop-modal.png";
import BasicButton from "view/components/_atom/button/BasicButton";
import { ButtonSize, ButtonTheme } from "view/components/_atom/button/StyledButton";
import ButtonContainer from "view/components/_atom/container/ButtonContainer";
import useTransaction from "../../../../../../hooks/useTransaction";
import { project_access } from "../../../../../../reducer/projectReducer";

const AirdropDepositModal = (props) => {
  const { isOpen, closeModal, data, callback } = props || {};

  const { address: connectedAddress } = useSelector(connectInfo);
  const accessToken = useSelector(project_access);

  const callback_ = () => {
    callback();
    closeModal();
  };

  const depositTransaction_ = async () => {
    const isCoin = SYSTEM_CONTRACT === data.currency.contractAddress;

    let transactionData;
    if (isCoin) {
      transactionData = {
        address: connectedAddress,
        microChainId: data.microchainId,
        to: data.contractAddress,
        value: WEB3.toWei(data.depositAmount),
        functionName: "transfer",
        transactionPath: `${process.env.REACT_APP_HUB_SERVER_URL}${process.env.REACT_APP_API_VERSION_V2}airdrops/${data.airdropId}/deposit`,
        token: accessToken,
      };
    } else {
      transactionData = {
        address: connectedAddress,
        microChainId: data.microchainId,
        contractAddress: data.currency.contractAddress,
        parameters: [data.contractAddress, WEB3.toWei(data.depositAmount)],
        to: data.currency.contractAddress,
        value: "0x0",
        functionName: "transfer",
        transactionPath: `${process.env.REACT_APP_HUB_SERVER_URL}${process.env.REACT_APP_API_VERSION_V2}airdrops/${data.airdropId}/deposit`,
        token: accessToken,
      };
    }

    return await makeTransactionAction(transactionData);
  };

  const { requestTransaction } = useTransaction(depositTransaction_, callback_);

  // const checkDeposit_ = async () => {
  //   const { result, error } = await checkDepositAction(airdropId);
  //   if (result) {
  //     callback();
  //     closeModal();
  //   }
  //   if (error) {
  //     errorAlert(error.data.message);
  //   }
  // };

  return (
    <ModalTemplateA title="Deposit the budget" isOpen={isOpen} closeModal={closeModal}>
      <section className="airdrop-deposit-modal">
        <div className="airdrop-deposit-modal-description">{`Before operating the project,deposit the budget into the smart contract.`}</div>
        <div className="airdrop-deposit-modal-information">
          <div className="airdrop-deposit-modal-information-row">
            <div className="airdrop-deposit-modal-information-row-label">
              <img src={bulletPoint} alt="" className="airdrop-deposit-modal-information-row-label-bullet-point" />
              Smart contract address
            </div>
            <div className="airdrop-deposit-modal-information-row-value">{`${data.contractAddress}`}</div>
          </div>
          <div className="airdrop-deposit-modal-information-row">
            <div className="airdrop-deposit-modal-information-row-label">
              <img src={bulletPoint} alt="" className="airdrop-distribution-send-fee-modal-information-row-label-bullet-point" />
              Budget
            </div>
            <div className="airdrop-deposit-modal-information-row-value">{`${addComma(data.depositAmount)} ${data.currency.symbol}`}</div>
          </div>
        </div>
        <div className="flex justify-content-center">
          <ButtonContainer>
            <BasicButton theme={ButtonTheme.SECONDARY} size={ButtonSize.LARGE} onClick={() => closeModal()}>
              Close
            </BasicButton>
            <BasicButton size={ButtonSize.LARGE} disabled={!connectedAddress} onClick={() => requestTransaction()}>
              Deposit
            </BasicButton>
          </ButtonContainer>
        </div>
      </section>
    </ModalTemplateA>
  );
};

export default AirdropDepositModal;
