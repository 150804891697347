import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useCallback, useState } from "react";
import ListTypeB from "../../../../components/_atom/list/ListTypeB";
import EmptyListTemplate from "../../../../components/_template/emptyList/EmptyListTemplate";
import { errorAlert } from "../../../../../utils/Utils";
import { selected_project_id } from "reducer/projectReducer";
import { getNFTsAction } from "action/nftAction";
import PageArticle from "view/components/_template/page/PageArticle";
import PageTitle from "view/components/_molecules/page/PageTitle";
import PageLoading from "view/components/_atom/loading/PageLoading";
import BasicButton from "view/components/_atom/button/BasicButton";
import { ButtonSize, ButtonTheme } from "view/components/_atom/button/StyledButton";
import useLoading from "hooks/useLoading";

const NFTProjectList = () => {
  const navigate = useNavigate();
  const projectId = useSelector(selected_project_id);

  //========= Get NFT Project List =========//
  const [nftProjectList, setNFTProjectList] = useState([]);

  const getNFTList_ = async () => {
    const { result, error } = await getNFTsAction();
    if (result) {
      setNFTProjectList(result.slice().reverse());
    }
    if (error) {
      errorAlert(error.data.message);
    }
  };

  //========= UI =========//
  const nftProjectDetailIndex = [
    { label: "Network / Microchain", accessor: "chain" },
    { label: "Symbol", accessor: "symbol" },
    { label: "Contract address", accessor: "contractAddress" },
  ];
  const nftProjectDetailRenderer = useCallback(
    (data) => ({
      chain: `${data.networkLabel} / ${data.microChainLabel}`,
      symbol: data.symbol,
      contractAddress: data.contractAddress ?? "-",
    }),
    []
  );
  const onClickNFTProject = (el) => {
    navigate(`/application/nft/manage/${el.nftProjectId}`);
  };

  const { loading } = useLoading({
    dependency: [projectId],
    synchronousFunction: async () => {
      await getNFTList_();
    },
  });

  if (loading) {
    return <PageLoading />;
  }
  return (
    <PageArticle>
      <PageTitle title={"NFT Project list"} description={"Create and manage your NFT Projects"} size={1240} />
      <div className="page-layout-1240">
        <BasicButton theme={ButtonTheme.CREATE} size={ButtonSize.EXTRALARGE} onClick={() => navigate("/application/nft/create")}>
          Create a new NFT Project
        </BasicButton>
        <div style={{ height: 30 }} />
        {nftProjectList.length > 0 && (
          <ListTypeB
            titleKey={"projectName"}
            imageKey={"image"}
            listElement={nftProjectList}
            renderIndex={nftProjectDetailIndex}
            renderer={nftProjectDetailRenderer}
            onClick={onClickNFTProject}
            containerStyle={{ width: "126px", height: "126px" }}
          />
        )}
        {nftProjectList.length <= 0 && <EmptyListTemplate description={"You do not have an NFT project yet."} />}
      </div>
    </PageArticle>
  );
};

export default NFTProjectList;
