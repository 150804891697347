import React, { useEffect, useMemo, useState } from "react";
import { statusDetail } from "../../../../utils/status.utils";
import "./TransactionStatus.scss";
import { ReactComponent as IconLink } from "../../../assets/images/Icon_link.svg";
import IconCheckTransaction from "../../../assets/images/Icon_check_transaction.png";
import IconProcessingTransaction from "../../../assets/images/Icon_processing_transaction.png";
import IconFailTransaction from "../../../assets/images/Icon_fail_transaction.png";
import { getBlockExplorerDetailAction } from "../../../../action/blockExplorerAction";
import toast from "react-hot-toast";

const EQ_FIELD_URL = process.env.REACT_APP_EQ_FIELD_URL;

type TransactionStatusProps = {
  statusDetail: statusDetail;
  microChainId: number;
  transactionHash?: string;
};

const TRANSACTION_STATUS_ICON = {
  check: IconCheckTransaction,
  wait: IconProcessingTransaction,
  reject: IconFailTransaction,
};

const deployedBlockExplorerStatus = 5;

const TransactionStatus = (props: TransactionStatusProps) => {
  const { statusDetail, microChainId, transactionHash } = props;

  const [blockExplorerURL, setBlockExplorerURL] = useState<string>("");

  const getMicroChainExplorerURL = async () => {
    const { result, error } = await getBlockExplorerDetailAction(microChainId);
    const { mainStatus, isExternal, microChainId: chainId } = result;

    if (result) {
      if (isExternal && result.externalTransactionUrl) {
        setBlockExplorerURL(result.externalTransactionUrl);
        return;
      }
      if (!isExternal && mainStatus === deployedBlockExplorerStatus) {
        setBlockExplorerURL(`${EQ_FIELD_URL}/${chainId}/transaction/list`);
        return;
      }
    }

    if (error) {
      //TODO: error
    }
  };

  useEffect(() => {
    getMicroChainExplorerURL();
  }, []);

  const transactionStatusContent = useMemo(() => {
    if (statusDetail.showTransactionHash && transactionHash) {
      //showTransactionHash가 true이고 transactionHash값을 Props로 받았을 경우
      return <TransactionHashWithLink transactionHash={transactionHash} blockExplorerURL={blockExplorerURL} />;
    }
    if (!statusDetail.showTransactionHash && statusDetail.description) {
      //showTransactionHash가 false이고 description 데이터가 존재할 경우
      return (
        <span className={"transaction-status-content-description"} style={{ color: statusDetail.description.color ?? "#3F4248" }}>
          {statusDetail.description?.text}
        </span>
      );
    }
    return <></>;
  }, [blockExplorerURL, statusDetail, transactionHash]);

  return (
    <div className="transaction-status">
      <span className="transaction-status-label">Status</span>
      <div className="transaction-status-header">
        <img className="transaction-status-header-icon" src={TRANSACTION_STATUS_ICON[statusDetail.type]} alt="status" />
        <span className="transaction-status-header-title">{statusDetail.title}</span>
      </div>
      <div className={"transaction-status-content"}>{transactionStatusContent}</div>
    </div>
  );
};

export default TransactionStatus;

const TransactionHashWithLink = ({ transactionHash, blockExplorerURL }: { transactionHash: string; blockExplorerURL: string }) => {
  const [hoverButton, setHoverButton] = useState(false);

  const onClickLinkToBlockExplorer = async () => {
    if (blockExplorerURL !== "") {
      window.open(`${blockExplorerURL}/${transactionHash}`);
    } else {
      toast.error("Something wrong. Please try again");
    }
  };

  return (
    <div className={"transaction-hash-with-link"}>
      <span className="transaction-hash-with-link-hash" onClick={() => onClickLinkToBlockExplorer()}>
        {`TX Hash : ${transactionHash}`}
      </span>
      <div
        className="transaction-hash-with-link-button"
        onMouseOver={() => setHoverButton(true)}
        onMouseLeave={() => setHoverButton(false)}
        onClick={() => onClickLinkToBlockExplorer()}
      >
        <IconLink stroke={hoverButton ? "#ffffff" : "#5888FF"} />
      </div>
    </div>
  );
};
