import { useEffect, useState } from "react";
import "./ContractConstructorViewer.scss";
import { isArray } from "lodash";
import { ManageFieldContainer, ManageFieldLabel, RowManageField } from "view/components/_atom/section/ManageField";
import contractIcon from "../../../../assets/images/service/Icon_SNB_contract_library.png";
import BasicInput from "view/components/_atom/input/BasicInput";

const ContractConstructorViewer = (props) => {
  const {
    constructorState: { contractConstructor, setContractConstructor },
    disabled,
    valueKey = "description",
    announcement,
  } = props || {};

  const [constructorInputs, setConstructorInputs] = useState(contractConstructor.inputs);

  useEffect(() => {
    setContractConstructor((prev) => {
      return {
        ...prev,
        inputs: constructorInputs,
      };
    });
  }, [constructorInputs]);

  useEffect(() => {
    setConstructorInputs(contractConstructor.inputs);
  }, [contractConstructor]);

  const setInputData = (value, id) => {
    const currentInput = constructorInputs.map((input, index) => {
      if (id === index) {
        return {
          ...input,
          [valueKey]: value,
        };
      } else return input;
    });
    setConstructorInputs(currentInput);
  };

  return (
    <>
      <ManageFieldContainer icon={contractIcon} title="Constructor" announcement={announcement}>
        {constructorInputs.map((input, idx) => {
          return (
            <RowManageField key={`contract-constructor-${idx}`}>
              <ManageFieldLabel label={`${input.name} (${input.type})`} />
              {disabled ? (
                <div className="constructor-viewer-input-value">
                  {input[valueKey] ? (
                    isArray(input[valueKey]) ? (
                      input[valueKey].join(", ")
                    ) : (
                      input[valueKey]
                    )
                  ) : (
                    <span style={{ color: "#B1B1C1" }}>Enter the description of method</span>
                  )}
                </div>
              ) : (
                <BasicInput
                  name={valueKey}
                  value={input[valueKey] ?? ""}
                  onChange={(e) => setInputData(e.target.value, idx)}
                  placeholder={"Enter the description of input"}
                  style={{ fontSize: 16 }}
                />
              )}
            </RowManageField>
          );
        })}
      </ManageFieldContainer>
    </>
  );
};

export default ContractConstructorViewer;
