import React, { useState } from "react";
import "./NetworkUtilizationComparisonChart.scss";
import { NetworkUtilizationComparisonType } from "../constants/dashboard.type";
import { getNetworkUtilizationComparisonAction } from "action/dashboardAction";
import { FromTick } from "server/type/projectType";
import { ResponsiveContainer } from "recharts";
import { PieChart } from "recharts";
import { Pie } from "recharts";
import { Cell } from "recharts";
import { Legend } from "recharts";
import EmptyDataTemplate from "./EmptyDataTemplate";
import ChartLoading from "view/components/_atom/loading/ChartLoading";
import useLoading from "hooks/useLoading";
import SectionContainer from "view/components/_atom/section/SectionContainer";
import chartIcon from "../assets/Icon_dashboard_chart.png";
import { DASHBOARD_NETWORK_UTILIZATION_COMPARISON_COLOR, EMPTY_CHART_TEXT } from "../constants/dashboard.data";
import { numberConvertor } from "utils/convert.utils";
import { roundNumber } from "view/service/network/network/constants/network.utils";

type CustomChartProps = {
  payload?: any;
};

type CustomPieChartLabelProps = {
  cx?: number;
  cy?: number;
  midAngle?: number;
  innerRadius?: number;
  outerRadius?: number;
  fill?: string;
  percent?: number;
  count?: number;
  networkLabel?: string;
};

const NetworkUtilizationComparisonChart = () => {
  const [networkUtilizationComparison, setNetworkUtilizationComparison] = useState<NetworkUtilizationComparisonType[]>([]);

  const getNetworkUtilizationComparison_ = async () => {
    const { result, error } = await getNetworkUtilizationComparisonAction(FromTick.WEEK);
    if (result) {
      setNetworkUtilizationComparison(result);
    }
    if (error) {
    }
  };

  const { loading } = useLoading({
    dependency: [],
    synchronousFunction: async () => {
      await getNetworkUtilizationComparison_();
    },
  });

  return (
    <SectionContainer title="Network Utilization Comparison" icon={chartIcon}>
      <div className="network-utilization-comparison">
        {loading ? (
          <ChartLoading />
        ) : (
          <>
            {networkUtilizationComparison.length > 0 ? (
              <div className="network-utilization-comparison-chart-wrapper">
                <div className="network-utilization-comparison-chart">
                  <ResponsiveContainer width={"100%"} height={"100%"}>
                    <PieChart width={440} height={100}>
                      <Pie
                        data={networkUtilizationComparison}
                        cx={"50%"}
                        cy={"50%"}
                        outerRadius={100}
                        innerRadius={50}
                        fill="#fff"
                        dataKey="count"
                        startAngle={90}
                        endAngle={-270}
                        labelLine={false}
                        label={<CustomPieChartLabel />}
                      >
                        {networkUtilizationComparison.map((entry: any, index: number) => (
                          <Cell key={`network-utilization-comparison-chart-cell-${index}`} fill={DASHBOARD_NETWORK_UTILIZATION_COMPARISON_COLOR[index]} />
                        ))}
                      </Pie>
                      <Legend content={<CustomPieChartLegend />} wrapperStyle={{ bottom: -30 }} />
                    </PieChart>
                  </ResponsiveContainer>
                </div>
                <p className="network-utilization-comparison-chart-notice">* The data above will only show data for requests sent to the specified networks.</p>
              </div>
            ) : (
              <EmptyDataTemplate>{EMPTY_CHART_TEXT}</EmptyDataTemplate>
            )}
          </>
        )}
      </div>
    </SectionContainer>
  );
};

const CustomPieChartLabel = (props: CustomPieChartLabelProps) => {
  const { cx = 0, cy = 0, midAngle = 0, innerRadius = 0, outerRadius = 0, count, fill, percent = 0, networkLabel } = props;

  const RADIAN = Math.PI / 180;
  const radius = 25 + innerRadius + (outerRadius - innerRadius);
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  const utilizationPercent = roundNumber(percent * 100, 1);

  return (
    <text
      x={x}
      y={y}
      dominantBaseline="central"
      textAnchor={x > cx ? "start" : "end"}
      className="network-utilization-comparison-chart-label"
      style={{ fill: fill, opacity: 0.5 }}
    >
      <tspan x={x} y={y} className="network-utilization-comparison-chart-label-name">
        {networkLabel}
      </tspan>
      <tspan x={x} y={y + 16} className="network-utilization-comparison-chart-label-percent">
        {`${utilizationPercent}% (${numberConvertor(Number(count))})`}
      </tspan>
    </text>
  );
};

const CustomPieChartLegend = (props: CustomChartProps) => {
  const { payload } = props;

  return (
    <div className="network-utilization-comparison-chart-custom-legend">
      {payload.map((data: any, index: number) => {
        const { color, payload } = data;
        const { networkLabel } = payload;

        return (
          <div className="network-utilization-comparison-chart-custom-legend-item" key={`network-utilization-comparison-chart-legend-${index}`}>
            <div className="network-utilization-comparison-chart-custom-legend-item-icon" style={{ backgroundColor: color }} />
            <div className="network-utilization-comparison-chart-custom-legend-item-label">{networkLabel}</div>
          </div>
        );
      })}
    </div>
  );
};

export default NetworkUtilizationComparisonChart;
