import { useMemo } from "react";
import "./BlockchainEngineInfo.scss";

enum BlockchainEngineStatus {
  SELECTED = " selected",
  DISABLED = " disabled",
  UNABLE = " unable",
}

// TODO :: 변경해야함
function BlockchainEngineInfo(props) {
  const { engineInfo, networkData, setSelectedEngine, canSelect = false } = props;

  const isSelected = useMemo(() => {
    if (!Boolean(engineInfo) && !Boolean(networkData?.microChain)) {
      return false;
    }
    return engineInfo?.chainClientId === networkData?.microChain?.chainClientId;
  }, [networkData?.microChain?.chainClientId, engineInfo?.chainClientId]);

  const isDisabled = useMemo(() => {
    if (!Boolean(engineInfo)) {
      return false;
    }
    return engineInfo?.chainClient?.isPrivate;
  }, [engineInfo?.ChainClient?.isPrivate]);

  const onClickMoreButton = (e: React.BaseSyntheticEvent, link: string | null) => {
    if (isDisabled) return;
    e.stopPropagation();
    if (Boolean(link)) {
      window.open(link as string);
    }
  };

  const onClickEngine = () => {
    if (!canSelect) return;
    setSelectedEngine(engineInfo);
  };

  return (
    <div
      className={`blockchain-engine-info ${isSelected ? BlockchainEngineStatus.SELECTED : ""} ${isDisabled ? BlockchainEngineStatus.DISABLED : ""} ${
        !canSelect ? BlockchainEngineStatus.UNABLE : ""
      }`}
      onClick={() => onClickEngine()}
    >
      {isDisabled && (
        <div className="blockchain-engine-info-coming-soon">
          <div className="blockchain-engine-info-coming-soon-content">
            <div className="blockchain-engine-info-coming-soon-content-front">Coming soon</div>
            <div className="blockchain-engine-info-coming-soon-content-back" />
          </div>
        </div>
      )}
      {canSelect && <input type="radio" name="engine" className="engine-radio" checked={isSelected} disabled={isDisabled} readOnly />}
      <img src={engineInfo?.chainClient?.image || ""} alt="" className="engine-img" />
      <div className="engine-info">
        <div className="engine-name">{engineInfo?.chainClient?.name ?? "-"}</div>
        <div className="engine-description">{engineInfo?.chainClient?.description ?? "-"}</div>
      </div>
      <div className={`engine-button${isDisabled ? BlockchainEngineStatus.DISABLED : ""}`} onClick={(e) => onClickMoreButton(e, engineInfo?.chainClient?.link)}>
        More
        <div className="engine-button-right-arrow">{">"}</div>
      </div>
    </div>
  );
}

export default BlockchainEngineInfo;
