import "./Notifications.scss";
import { useEffect, useMemo, useRef, useState } from "react";
import Icon_Noti_Empty from "../../../../assets/images/atom/Icon_noti_empty.png";
import Icon_Noti_Fill from "../../../../assets/images/atom/Icon_noti_fill.png";
import Icon_Noti_Empty_Click from "../../../../assets/images/atom/Icon_noti_empty_click.png";
import Icon_Noti_Fill_Click from "../../../../assets/images/atom/Icon_noti_fill_click.png";
import NotificationPanel from "./NotificationPanel";
import { getNotificationAction } from "../../../../../action/notificationAction";
import { useSelector } from "react-redux";
import { selected_project_id } from "../../../../../reducer/projectReducer";

const NOTIFICATION_TYPE = {
  EMPTY: {
    defaultIcon: Icon_Noti_Empty,
    clickIcon: Icon_Noti_Empty_Click,
  },
  FILL: {
    defaultIcon: Icon_Noti_Fill,
    clickIcon: Icon_Noti_Fill_Click,
  },
};

const Notifications = () => {
  const selectedProjectId = useSelector(selected_project_id);
  const [notifications, setNotifications] = useState([]);
  const [notificationPanelOpen, setNotificationPanelOpen] = useState(false);
  const imgRef = useRef(null);
  const defaultImgRef = useRef(null);
  const hasUnreadNotification = useMemo(() => (notifications.reduce((acc, cur) => Boolean(!cur.isRead || acc), false) ? "FILL" : "EMPTY"), [notifications]);

  const notificationPanelRef = useRef(null);

  const notificationClickHandler = (e) => {
    e.stopPropagation();
    setNotificationPanelOpen((prev) => !prev);
  };

  const getNotifications_ = async () => {
    const { error, result } = await getNotificationAction();
    if (error) {
      console.log(error.data.message);
    } else {
      setNotifications(result);
    }
  };

  useEffect(() => {
    getNotifications_();
  }, [selectedProjectId]);

  useEffect(() => {
    defaultImgRef.current = NOTIFICATION_TYPE[hasUnreadNotification];
    imgRef.current.src = defaultImgRef.current.defaultIcon;
  }, [hasUnreadNotification]);

  useEffect(() => {
    imgRef.current.src = notificationPanelOpen ? defaultImgRef.current.clickIcon : defaultImgRef.current.defaultIcon;
  }, [notificationPanelOpen]);

  useEffect(() => {
    const notificationPanelHandleOutsideClose = (e) => {
      if (notificationPanelOpen && !notificationPanelRef.current.contains(e.target)) setNotificationPanelOpen(false);
    };

    document.addEventListener("click", notificationPanelHandleOutsideClose);

    return () => document.removeEventListener("click", notificationPanelHandleOutsideClose);
  }, [notificationPanelOpen]);

  return (
    <div className="notifications" onClick={(e) => notificationClickHandler(e)}>
      <div className="notifications-icon">
        <img ref={imgRef} className="notifications-icon-src" alt="notification" />
      </div>
      {!!notificationPanelOpen && (
        <NotificationPanel
          ref={notificationPanelRef}
          notifications={notifications}
          getNotifications_={getNotifications_}
          setNotificationPanelOpen={setNotificationPanelOpen}
        />
      )}
    </div>
  );
};

export default Notifications;
