import React, { ReactNode } from "react";
import "./ManageField.scss";
import QuestionMark, { QuestionMarkTheme } from "../tooltip/QuestionMark";
import DivideLine from "../line/DivideLine";

const ManageFieldContainer = (props: ManageFieldContainerProps) => {
  const { icon, title, description, announcement, options, children } = props;
  return (
    <div className="manage-field-container">
      <div className="manage-field-header">
        <div className="manage-field-header-info-wrapper">
          <div className="manage-field-header-info">
            {icon && (
              <div className="manage-field-header-info-icon">
                <img src={icon} className="manage-field-header-info-icon-src" alt="" />
              </div>
            )}
            <div className="manage-field-header-info-title">{title}</div>
            {announcement && (
              <div className="manage-field-header-info-announcement">
                <QuestionMark announcement={announcement} />
              </div>
            )}
          </div>
          {options && <div className="manage-field-header-option">{options}</div>}
        </div>
        {description && <p className="manage-field-header-description">{description}</p>}
        <DivideLine />
      </div>
      {children}
    </div>
  );
};

const ManageFieldWrapper = (props: ManageFieldWrapperProps) => {
  const { title, announcement, children } = props;

  return (
    <div className="manage-field-wrapper">
      <div className="manage-field-wrapper-header">
        <div className="manage-field-wrapper-header-title">{title}</div>
        {announcement && (
          <div className="manage-field-wrapper-header-announcement">
            <QuestionMark announcement={announcement} theme={QuestionMarkTheme.SECONDARY} />
          </div>
        )}
      </div>
      {children}
    </div>
  );
};

const RowManageField = (props: ManageFieldProps) => {
  const { children } = props;
  return <div className="manage-field row">{children}</div>;
};

const ColumnManageField = (props: ManageFieldProps) => {
  const { children } = props;
  return <div className="manage-field column">{children}</div>;
};

const ManageFieldLabel = (props: ManageFieldLabelProps) => {
  const { label, optional = false, announcement, description } = props;
  return (
    <div className="manage-field-label">
      <div className="manage-field-label-info">
        <span className="manage-field-label-info-text">{label}</span>
        {optional && <span className="manage-field-label-info-optional">{`(Option)`}</span>}
        {announcement && (
          <div className="manage-field-label-info-announcement">
            <QuestionMark announcement={announcement} theme={QuestionMarkTheme.SECONDARY} />
          </div>
        )}
      </div>
      <span className="manage-field-label-description">{description}</span>
    </div>
  );
};

export { RowManageField, ManageFieldWrapper, ColumnManageField, ManageFieldContainer, ManageFieldLabel };

type ManageFieldContainerProps = {
  icon?: string;
  title: string;
  description?: string;
  announcement?: any;
  options?: any;
  children: ReactNode;
};

type ManageFieldWrapperProps = {
  title: string;
  announcement?: any;
  children: ReactNode;
};

type ManageFieldProps = {
  children: ReactNode;
};

type ManageFieldLabelProps = {
  label: string;
  optional?: boolean;
  announcement?: any;
  description?: string;
};
