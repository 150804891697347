import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ALL_MAIN_STATUS } from "../../../../../constants/commonConstants";
import { errorAlert } from "../../../../../utils/Utils";
import { CURRENCY_MAIN_STATUS, SUB_STATUS_LIST } from "../constants/networkConstants";
import { getNetworkListAction } from "../../../../../action/networkAction";
import { selected_project_id } from "reducer/projectReducer";
import PageTitle from "view/components/_molecules/page/PageTitle";
import PageArticle from "view/components/_template/page/PageArticle";
import PageLoading from "view/components/_atom/loading/PageLoading";
import BasicButton from "view/components/_atom/button/BasicButton";
import { ButtonSize, ButtonTheme } from "view/components/_atom/button/StyledButton";
import useLoading from "hooks/useLoading";
import NetworkCard from "../component/atom/\bNetworkCard";
import EmptyDataTemplate from "view/service/dashboard/components/EmptyDataTemplate";

function NetworkList() {
  const navigate = useNavigate();

  const projectId = useSelector(selected_project_id);

  const [networks, setNetworks] = useState([]);
  const getNetworkList_ = async () => {
    const { result, error } = await getNetworkListAction({
      projectId,
      mainStatus: ALL_MAIN_STATUS,
    });

    if (result) {
      setNetworks(result);
    }
    if (error) {
      errorAlert(error.data.message);
    }
  };

  const goToCreateNetwork = () => {
    navigate("/network/mainnet/create");
  };

  const onNetworkClicked = (el) => {
    navigate(`${el.id}`);
  };

  const { loading } = useLoading({
    dependency: [projectId],
    synchronousFunction: async () => {
      await getNetworkList_(ALL_MAIN_STATUS, SUB_STATUS_LIST, CURRENCY_MAIN_STATUS);
    },
  });

  if (loading) {
    return <PageLoading />;
  }

  return (
    <PageArticle>
      <PageTitle size={1240} title={"Network List"} description={"Create and manage your own networks."} />
      <div className="page-layout-1240">
        <BasicButton theme={ButtonTheme.CREATE} size={ButtonSize.EXTRALARGE} onClick={() => goToCreateNetwork()}>
          Create a new Network
        </BasicButton>
        <div style={{ height: 30 }} />
        {networks.length === 0 ? (
          <div
            className="no-data"
            style={{
              width: "100%",
              height: "363px",
              background: "#fff",
              borderRadius: "16px",
            }}
          >
            <EmptyDataTemplate>
              <div className="no-networks-text" style={{ fontSize: "18px" }}>
                No networks created or running in your project.
              </div>
            </EmptyDataTemplate>
          </div>
        ) : (
          <div className="network-list" style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
            {networks.map((el) => (
              <NetworkCard
                key={el.id}
                networkInfo={el}
                equalizerCount={el.mainMicroChainEqualizerCount}
                lightNodeCount={el.mainMicroChainLightNodeCount}
                onClick={() => onNetworkClicked(el)}
              />
            ))}
          </div>
        )}
      </div>
    </PageArticle>
  );
}

export default NetworkList;
