import {
  getParticipationPeriodTypesFromServer,
  getDistributionTypesFromServer,
  getDistributionAmountTypesFromServer,
  getParticipationConditionTypesFromServer,
  airdropUploadImageFromServer,
  createAirdropFromServer,
  getAirdropListFromServer,
  getAssetFromServer,
  getServerKeyAddressFromServer,
  getAirdropDetailFromServer,
  updateAirdropFromServer,
  deleteAirdropFromServer,
  submitAirdropFromServer,
  getMarketingChannelsFromServer,
  getParticipationPeriodTypesByAirdropIdFromServer,
  getDistributionTypesByAirdropIdFromServer,
  getDistributionAmountTypesByAirdropIdFromServer,
  getDistributionAmountsByAirdropDistributionAmountTypeIdFromServer,
  getParticipationConditionTypesByAirdropIdFromServer,
  getParticipationLimitFromServer,
  getParticipationEventsFromServer,
  checkDepositFromServer,
  getDistributionCountFromServer,
  checkDistributionFeeFromServer,
  airdropPublishFromServer,
  getAirdropParticipationsFromServer,
  getAirdropLimitParticipantDetailByAddressFromServer,
  downloadAirdropBountyResultFromServer,
  downloadAirdropBountyTemplateFromServer,
  uploadAirdropBountyResultFromServer,
  reuploadAirdropBountyResultFromServer,
  airdropBountyDistributeFromServer,
  airdropBountyForceCloseFromServer,
} from "../server/API/airdropAPI";

export const getParticipationPeriodTypesParser = async (): Promise<any> => {
  const result = await getParticipationPeriodTypesFromServer();
  return result;
};

export const getDistributionTypesParser = async (): Promise<any> => {
  const result = await getDistributionTypesFromServer();
  return result;
};

export const getDistributionAmountTypesParser = async (): Promise<any> => {
  const result = await getDistributionAmountTypesFromServer();
  return result;
};

export const getParticipationConditionTypesParser = async (): Promise<any> => {
  const result = await getParticipationConditionTypesFromServer();
  return result;
};

export const airdropUploadImageParser = async (fileCount: number, formData: FormData): Promise<any> => {
  const result = await airdropUploadImageFromServer({ query: { fileCount }, data: formData });
  return result;
};

export const createAirdropParser = async (data: any): Promise<any> => {
  const result = await createAirdropFromServer({ data });
  return result;
};

export const getAirdropListParser = async (options: { projectId?: number; creatorId?: number; lastId?: number; limit?: number }): Promise<any> => {
  const result = await getAirdropListFromServer({
    query: {
      ...(options?.projectId && { projectId: options.projectId }),
      ...(options?.creatorId && { creatorId: options.creatorId }),
      ...(options?.lastId && { lastId: options.lastId }),
      ...(options?.limit && { limit: options.limit }),
    },
  });
  return result;
};

export const getAssetParser = async (airdropId: number): Promise<any> => {
  const result = await getAssetFromServer({
    params: {
      airdropId,
    },
  });
  return result;
};

export const getServerKeyAddressParser = async (airdropId: number): Promise<any> => {
  const result = await getServerKeyAddressFromServer({
    params: {
      airdropId,
    },
  });
  return result;
};

export const getAirdropDetailParser = async (airdropId: number): Promise<any> => {
  const result = await getAirdropDetailFromServer({
    params: {
      airdropId,
    },
  });
  return result;
};

export const updateAirdropParser = async (airdropId: number, data: any): Promise<any> => {
  const result = await updateAirdropFromServer({
    params: {
      airdropId,
    },
    data,
  });
  return result;
};

export const deleteAirdropParser = async (airdropId: number): Promise<any> => {
  const result = await deleteAirdropFromServer({
    params: {
      airdropId,
    },
  });
  return result;
};

export const submitAirdropParser = async (airdropId: number): Promise<any> => {
  const result = await submitAirdropFromServer({
    params: {
      airdropId,
    },
  });
  return result;
};

export const getMarketingChannelsParser = async (airdropId: number): Promise<any> => {
  const result = await getMarketingChannelsFromServer({
    params: {
      airdropId,
    },
  });
  return result;
};

export const getParticipationPeriodTypesByAirdropIdParser = async (airdropId: number): Promise<any> => {
  const result = await getParticipationPeriodTypesByAirdropIdFromServer({
    params: {
      airdropId,
    },
  });
  return result;
};

export const getDistributionTypesByAirdropIdParser = async (airdropId: number): Promise<any> => {
  const result = await getDistributionTypesByAirdropIdFromServer({
    params: {
      airdropId,
    },
  });
  return result;
};

export const getDistributionAmountTypesByAirdropIdParser = async (airdropId: number): Promise<any> => {
  const result = await getDistributionAmountTypesByAirdropIdFromServer({
    params: {
      airdropId,
    },
  });
  return result;
};

export const getDistributionAmountsByAirdropDistributionAmountTypeIdParser = async (
  airdropId: number,
  airdropDistributionAmountTypeId: number
): Promise<any> => {
  const result = await getDistributionAmountsByAirdropDistributionAmountTypeIdFromServer({
    params: {
      airdropId,
      airdropDistributionAmountTypeId,
    },
  });
  return result;
};

export const getParticipationConditionTypesByAirdropIdParser = async (airdropId: number): Promise<any> => {
  const result = await getParticipationConditionTypesByAirdropIdFromServer({
    params: {
      airdropId,
    },
  });
  return result;
};

export const getParticipationLimitParser = async (airdropId: number, airdropParticipationConditionTypeId: number): Promise<any> => {
  const result = await getParticipationLimitFromServer({
    params: {
      airdropId,
      airdropParticipationConditionTypeId,
    },
  });
  return result;
};

export const getParticipationEventsParser = async (airdropId: number, airdropParticipationConditionTypeId: number): Promise<any> => {
  const result = await getParticipationEventsFromServer({
    params: {
      airdropId,
      airdropParticipationConditionTypeId,
    },
  });
  return result;
};

export const checkDepositParser = async (airdropId: number): Promise<any> => {
  const result = await checkDepositFromServer({
    params: {
      airdropId,
    },
  });
  return result;
};

export const getDistributionCountParser = async (airdropId: number): Promise<any> => {
  const result = await getDistributionCountFromServer({
    params: {
      airdropId,
    },
  });
  return result;
};

export const checkDistributionFeeParser = async (airdropId: number): Promise<any> => {
  const result = await checkDistributionFeeFromServer({
    params: {
      airdropId,
    },
  });
  return result;
};

export const airdropPublishParser = async (airdropId: number): Promise<any> => {
  const result = await airdropPublishFromServer({
    params: {
      airdropId,
    },
  });
  return result;
};

export const getAirdropParticipationsParser = async (
  airdropId: number,
  limit: number,
  offset: number,
  participationStartDate: Date,
  participationEndDate: Date
): Promise<any> => {
  const result = await getAirdropParticipationsFromServer({
    params: {
      airdropId,
    },
    query: {
      limit,
      offset,
      participationStartDate,
      participationEndDate,
    },
  });
  return result;
};

export const getAirdropLimitParticipantDetailByAddressParser = async (airdropId: number, address: string): Promise<any> => {
  const result = await getAirdropLimitParticipantDetailByAddressFromServer({
    params: {
      airdropId,
      address,
    },
  });
  return result;
};

export const downloadAirdropBountyResultParser = async (airdropId: number): Promise<any> => {
  const result = await downloadAirdropBountyResultFromServer({
    params: {
      airdropId,
    },
  });
  return result;
};

export const downloadAirdropBountyTemplateParser = async (airdropId: number): Promise<any> => {
  const result = await downloadAirdropBountyTemplateFromServer({
    params: {
      airdropId,
    },
  });
  return result;
};

export const uploadAirdropBountyResultParser = async (airdropId: number, fileCount: number, formData: FormData): Promise<any> => {
  const result = await uploadAirdropBountyResultFromServer({
    params: {
      airdropId,
    },
    query: {
      fileCount,
    },
    data: formData,
  });
  return result;
};

export const reuploadAirdropBountyResultParser = async (airdropId: number): Promise<any> => {
  const result = await reuploadAirdropBountyResultFromServer({
    params: {
      airdropId,
    },
  });
  return result;
};

export const airdropBountyDistributeParser = async (airdropId: number): Promise<any> => {
  const result = await airdropBountyDistributeFromServer({
    params: {
      airdropId,
    },
  });
  return result;
};

export const airdropBountyForceCloseParser = async (airdropId: number): Promise<any> => {
  const result = await airdropBountyForceCloseFromServer({
    params: {
      airdropId,
    },
  });
  return result;
};
