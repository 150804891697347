import React from "react";
import "./NotFound.scss";
import notFoundImage from "../../../../view/assets/images/image-not-found.png";
import { useNavigate } from "react-router-dom";
import BasicButton from "view/components/_atom/button/BasicButton";
import { ButtonSize } from "view/components/_atom/button/StyledButton";

const NotFound = (props) => {
  const navigate = useNavigate();

  return (
    <section id="NotFound">
      <div className="not-found-image">
        <img src={notFoundImage} className="not-found-image-src" alt="not-found" />
      </div>
      <div className="message-wrapper">
        <p className="message-title">Page Not Found...</p>
        <p className="message-description">{`I think something is wrong.\nGood things will happen if you try again!`}</p>
      </div>
      <BasicButton size={ButtonSize.LARGE} onClick={() => navigate("/")}>
        Back to Home
      </BasicButton>
    </section>
  );
};

export default NotFound;
