import React, { useMemo } from "react";
import "./ButtonLoading.scss";
import { ReactComponent as ButtonRotate } from "../../../../view/assets/images/atom/icon-ButtonLoading.svg";
import { ReactComponent as ButtonRotateRed } from "../../../../view/assets/images/atom/icon-ButtonLoading-red.svg";

export default function ButtonLoading({ style, type = 0 }) {
  // 타입은 임시 (기본 0, 빨간색 1)
  const RotateButton = useMemo(() => {
    if (type === 0) {
      return <ButtonRotate />;
    }
    if (type !== 0) {
      return <ButtonRotateRed />;
    }
  }, [type]);

  return (
    <div className={"button-loading"} style={{ ...(style && style) }}>
      <div className={"rotate-button"}>{RotateButton}</div>
    </div>
  );
}
