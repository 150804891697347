import React from "react";
import "./PaymentGatewayTypeList.scss";
import { PAYMENT_GATEWAY_TYPE_LIST } from "../constants/paymentGateway.data";
import assetIcon from "../../../../assets/images/Icon_coin.png";
import BasicButton from "view/components/_atom/button/BasicButton";

const PaymentGatewayTypeList = (props) => {
  const { onClickTypeButton } = props;

  return (
    <div className="payment-gateway-type-list">
      {PAYMENT_GATEWAY_TYPE_LIST.map((el) => (
        <div className="payment-gateway-type-container" key={`payment-gateway-type-${el.label}`}>
          <div className="payment-gateway-type">
            <div className="payment-gateway-type-title">{el.label}</div>
            <div className="payment-gateway-type-description">
              <p className="payment-gateway-type-description-header">{`What is the ${el.label} method?`}</p>
              <div className="payment-gateway-type-description-content">{el.description}</div>
            </div>
            <div className="payment-gateway-type-available-asset">
              <div className="payment-gateway-type-available-asset-header">What is it For?</div>
              <div className="payment-gateway-type-available-asset-container">
                {el.availableAssetList.map((asset, index) => (
                  <div className="payment-gateway-type-available-asset-item" key={`payment-gateway-type-available-asset-${el}-${index}`}>
                    <div className="payment-gateway-type-available-asset-item-icon">
                      <img src={assetIcon} className="payment-gateway-type-available-asset-item-icon-src" alt="" />
                    </div>
                    <div className="payment-gateway-type-available-asset-item-label">{asset}</div>
                  </div>
                ))}
              </div>
            </div>
            <div className="payment-gateway-type-button">
              <BasicButton onClick={() => onClickTypeButton(el.value)}>Select</BasicButton>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default PaymentGatewayTypeList;
