import { v2ServerByProjectAuth } from "server/index/serverInstances";
import { makeQuery } from "server/index/utils";
import {
  getContractAbiCodeRequest,
  getContractAbiCodeResponse,
  getContractByteCodeRequest,
  getContractByteCodeResponse,
  getContractDetailRequest,
  getContractDetailResponse,
  getContractLibraryDetailRequest,
  getContractLibraryDetailResponse,
  getContractListRequest,
  getContractListResponse,
} from "./index.type";

const getContractDetail = async (
  request: getContractDetailRequest
): Promise<getContractDetailResponse> =>
  await v2ServerByProjectAuth.get({
    path: `/contracts/${request.params.contractId}`,
    query: makeQuery(request.query),
  });

const getContractList = async (
  request: getContractListRequest
): Promise<getContractListResponse> =>
  await v2ServerByProjectAuth.get({
    path: `/contracts`,
    query: makeQuery(request.query),
  });

const getContractByteCode = async (
  request: getContractByteCodeRequest
): Promise<getContractByteCodeResponse> =>
  await v2ServerByProjectAuth.get({
    path: `/contracts/${request.params.contractId}/byte-code`,
    query: makeQuery(request.query),
  });

const getContractAbiCode = async (
  request: getContractAbiCodeRequest
): Promise<getContractAbiCodeResponse> =>
  await v2ServerByProjectAuth.get({
    path: `/contracts/${request.params.contractId}/custom-abi-code`,
    query: makeQuery(request.query),
  });

const getContractLibraryDetail = async (
  request: getContractLibraryDetailRequest
): Promise<getContractLibraryDetailResponse> =>
  await v2ServerByProjectAuth.get({
    path: `/contract-library/${request.params.contractId}/version/${request.params.contractVersionId}`,
    query: makeQuery(request.query),
  });

export {
  getContractDetail as getContractDetailFromServer,
  getContractList as getContractListFromServer,
  getContractByteCode as getContractByteCodeFromserver,
  getContractAbiCode as getContractAbiCodeFromServer,
  getContractLibraryDetail as getContractLibraryDetailFromServer,
};
