import React, { useMemo, useState } from "react";
import "./NetworkDeleteModal.scss";
import ModalTemplateB from "view/components/_template/modal/ModalTemplateB";
import CommentInput from "view/components/_atom/input/CommentInput";
import ButtonContainer from "view/components/_atom/container/ButtonContainer";
import BasicButton from "view/components/_atom/button/BasicButton";
import { ButtonSize, ButtonTheme } from "view/components/_atom/button/StyledButton";
import { deleteNetworkAction } from "action/networkAction";
import { errorAlert } from "utils/Utils";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import useIsAble from "hooks/useIsAble";

type NetworkDeleteModalProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  networkId: number;
  networkLabel: string;
  mainStatus: number;
};

const NetworkDeleteModal = (props: NetworkDeleteModalProps) => {
  const { isOpen, setIsOpen, networkId, networkLabel, mainStatus } = props;

  const navigate = useNavigate();

  const [name, setName] = useState<string>("");

  const description = useMemo(() => {
    if (mainStatus === 5) {
      return `Are you sure you want to delete it?\nAll data generated so far will be lost forever,\nand there will be a huge negative impact on all nodes and related DApps\nparticipating in the network.`;
    }

    return `Are you sure you want to delete it?\nYou cannot recover deleted data.`;
  }, [mainStatus]);

  const deleteNetwork_ = async () => {
    const { result, error } = await deleteNetworkAction(networkId);

    if (result) {
      toast.success("processed successfully");
      navigate("/network/mainnet/manage");
    }
    if (error) {
      errorAlert(error.data.message);
      return;
    }
  };

  const isAble = useIsAble([name === networkLabel]);

  return (
    <ModalTemplateB isOpen={isOpen} onRequestClose={() => setIsOpen(false)} title="Delete Network" description={description}>
      <section className="network-delete-modal">
        <div className="network-delete-modal-description">Enter the network name to be deleted in the area below.</div>
        <div className="network-delete-modal-row">
          <div className="network-delete-modal-row-title">
            <div className="network-delete-modal-row-title-dot" />
            <div className="network-delete-modal-row-title-label">Name</div>
          </div>
          <CommentInput value={name} onChange={(e) => setName(e.target.value)} placeholder={networkLabel} />
        </div>
        <div className="network-delete-modal-button-wrapper">
          <ButtonContainer>
            <BasicButton size={ButtonSize.LARGE} theme={ButtonTheme.SECONDARY} onClick={() => setIsOpen(false)}>
              Cancel
            </BasicButton>
            <BasicButton size={ButtonSize.LARGE} onClick={() => deleteNetwork_()} disabled={!isAble}>
              Delete
            </BasicButton>
          </ButtonContainer>
        </div>
      </section>
    </ModalTemplateB>
  );
};

export default NetworkDeleteModal;
