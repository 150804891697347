import React, { useEffect, useState } from "react";
import "./KeystoreFileSettingModal.scss";
import FileDragAndDrop from "view/components/_atom/file/FileDragAndDrop";
import BasicInput from "view/components/_atom/input/BasicInput";
import ButtonContainer from "view/components/_atom/container/ButtonContainer";
import BasicButton from "view/components/_atom/button/BasicButton";
import { ButtonTheme } from "view/components/_atom/button/StyledButton";
import { WEB3 } from "modules/web3/Web3";
import { reduceAddress } from "utils/Utils";
import ModalTemplateB from "view/components/_template/modal/ModalTemplateB";
import CreateNewKeyModal from "view/components/_modal/CreateNewKeyModal";
import { encryptPrivateKeyAction } from "action/commonAction";
import toast from "react-hot-toast";

type Keystore = {
  address: string;
};

type PrivateKey = string;

const KeystoreFileSettingModal = (props) => {
  const { index, isOpen, closeModal, setNetworkData } = props;

  const [openCreateKeyModal, setOpenCreateKeyModal] = useState(false);

  const [file, setFile] = useState<any>();
  const [fileData, setFileData] = useState<Keystore>();
  const [password, setPassword] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const readFile = async (file) => {
    try {
      const blob = new Blob([file]);
      const fileResult = await blob.text();
      return JSON.parse(fileResult);
    } catch (e) {
      toast.error("Invalid file type. Please check the uploaded file");
      setFile(undefined);
      return;
    }
  };

  const readAndSetFileData = async (file) => {
    const fileResult = await readFile(file);
    setFileData(fileResult);
  };

  useEffect(() => {
    if (Boolean(file)) {
      readAndSetFileData(file);
    }
    if (!Boolean(file)) {
      setFile(undefined);
      setFileData(undefined);
      setErrorMessage("");
    }
  }, [file]);

  const setKeystoreFileData = (encryptedKey) => {
    setNetworkData((prevState) => {
      const nodeAlloc = prevState.nodeAlloc.map((item, i) => {
        if (i !== index) {
          return item;
        } else {
          return { ...item, keyStore: fileData, keyStoreFile: file, encryptedKey, address: `0x${fileData?.address}`, isChecked: true };
        }
      });

      return { ...prevState, nodeAlloc };
    });
  };

  const getPrivateKeyInKeystore = (): PrivateKey => {
    const decryptKeystoreFile = WEB3.decryptKeystore(JSON.stringify(fileData), password);
    return decryptKeystoreFile.privateKey;
  };

  const encryptPrivateKey = async (privateKey) => {
    const { result, error } = await encryptPrivateKeyAction(privateKey);
    if (result) {
      return result;
    }

    if (error) {
      console.error(error.data.message);
    }
  };

  const verifyKeystore = async () => {
    try {
      const privateKey = getPrivateKeyInKeystore();
      const encryptedKey = await encryptPrivateKey(privateKey);
      setKeystoreFileData(encryptedKey);
      toast.success(`Successfully verified`);
      closeModal();
    } catch (e) {
      setErrorMessage("Please check the password.");
    }
  };

  return (
    <ModalTemplateB
      isOpen={isOpen}
      onRequestClose={closeModal}
      title={"Upload Keystore File"}
      description={`Upload the keystore file and verify the uploaded keystore file.
Please enter the password of the keystore file to verify.`}
    >
      {openCreateKeyModal && <CreateNewKeyModal title={"Create a new key"} isOpen={openCreateKeyModal} closeModal={() => setOpenCreateKeyModal(false)} />}

      <section className="keystore-file-setting-modal">
        <div className="keystore-file-setting-modal-content">
          <div className="keystore-file-setting-modal-row">
            <div className="keystore-file-setting-modal-row-title">
              <div className="keystore-file-setting-modal-row-title-dot" />
              <div className="keystore-file-setting-modal-row-title-label">Keystore file</div>
              <div className="keystore-file-setting-modal-row-title-new-key" onClick={() => setOpenCreateKeyModal(true)}>
                Create a new key
              </div>
            </div>
            <FileDragAndDrop file={file} setFile={setFile} label={fileData?.address ? reduceAddress(`0x${fileData.address}`) : undefined} />
          </div>
          <div className="keystore-file-setting-modal-row">
            <div className="keystore-file-setting-modal-row-title">
              <div className="keystore-file-setting-modal-row-title-dot" />
              <div className="keystore-file-setting-modal-row-title-label">Password</div>
            </div>
            <BasicInput type={"password"} value={password} onChange={(e) => setPassword(e.target.value)} />
            <span className="keystore-file-setting-modal-row-error">{errorMessage}</span>
          </div>
          <div className="keystore-file-setting-modal-button-wrapper">
            <ButtonContainer>
              <BasicButton theme={ButtonTheme.SECONDARY} onClick={() => closeModal()}>
                Cancel
              </BasicButton>
              <BasicButton onClick={() => verifyKeystore()}>Verify</BasicButton>
            </ButtonContainer>
          </div>
        </div>
      </section>
    </ModalTemplateB>
  );
};

export default KeystoreFileSettingModal;
