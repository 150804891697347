import dashboardIcon from '../../assets/images/service/Icon_SNB_dashboard.png';
import memberIcon from '../../assets/images/service/Icon_SNB_member.png';
import billingIcon from '../../assets/images/service/Icon_SNB_billing.png';
import pricingIcon from '../../assets/images/service/Icon_SNB_pricing.png';
import networkIcon from '../../assets/images/service/Icon_SNB_network.png';
import microchainIcon from '../../assets/images/service/Icon_SNB_microchain.png';
import nodeIcon from '../../assets/images/service/Icon_SNB_node.png';
import foundationIcon from '../../assets/images/service/Icon_SNB_foundation.png';
import walletIcon from '../../assets/images/service/Icon_SNB_wallet.png';
import blockExplorerIcon from '../../assets/images/service/Icon_SNB_block_explorer.png';
import gatewayIcon from '../../assets/images/service/Icon_SNB_gateway.png';
import linkerIcon from '../../assets/images/service/Icon_SNB_linker.png';
import swapIcon from '../../assets/images/service/Icon_SNB_swap.png';
import dexIcon from '../../assets/images/service/Icon_SNB_decentralized.png';
import tokenIcon from '../../assets/images/service/Icon_SNB_token.png';
import airdropIcon from '../../assets/images/service/Icon_SNB_airdrop.png';
import launchpadIcon from '../../assets/images/service/Icon_SNB_launchpad.png';
import nftIcon from '../../assets/images/service/Icon_SNB_non_fungible_token.png';
import stoIcon from '../../assets/images/service/Icon_SNB_security_token.png';
import contractIcon from '../../assets/images/service/Icon_SNB_contract_library.png';
import apiDocsIcon from '../../assets/images/service/Icon_SNB_api_docs.png';
import dappIcon from '../../assets/images/service/Icon_SNB_dapp.png';
import pgIcon from '../../assets/images/service/Icon_SNB_payment_gateway.png';
import chainRequestIcon from '../../assets/images/service/Icon_SNB_chain_request.png';
import userGuideIcon from '../../assets/images/service/Icon_SNB_user_guide.png';
import developerGuideIcon from '../../assets/images/service/Icon_SNB_developer_guide.png';

const DOCS_GUIDE_URL = process.env.REACT_APP_EQ_HUB_DOCS_URL;
const DOCS_API_REFERENCE_URL = process.env.REACT_APP_EQ_HUB_DOCS_API_URL;

const TAB_PROJECT = {
  name: '',
  index: 'project',
  menu: [
    {
      name: 'Dashboard',
      index: 'dashboard',
      icon: dashboardIcon,
      route: '/',
      url: null,
      subMenu: null,
    },
    {
      name: 'Member',
      index: 'member',
      icon: memberIcon,
      route: '/project/member',
      url: null,
      subMenu: null,
    },
    {
      name: 'Billing',
      index: 'billing',
      icon: billingIcon,
      route: '/project/billing',
      url: null,
      subMenu: null,
    },
    {
      name: 'Pricing',
      index: 'pricing',
      icon: pricingIcon,
      route: '/project/pricing',
      url: null,
      subMenu: null,
    },
  ],
};

const TAB_NETWORK = {
  name: 'Blockchain Network',
  index: 'network',
  menu: [
    {
      name: 'Network',
      index: 'network',
      icon: networkIcon,
      route: '/network/mainnet/manage',
      url: null,
      subMenu: null,
    },
    // {
    //   name: "Microchain",
    //   index: "microchain",
    //   icon: microchainIcon,
    //   route: null,
    //   url: null,
    //   subMenu: null,
    // },
    {
      name: 'Node',
      index: 'node',
      icon: nodeIcon,
      route: '/network/node/manage',
      url: null,
      subMenu: null,
    },
    // {
    //   name: "Foundation",
    //   index: "foundation",
    //   icon: foundationIcon,
    //   route: "network/foundation/manage",
    //   url: null,
    //   subMenu: null,
    // },
  ],
};

const TAB_MICROCHAIN_TOOLS = {
  name: 'Microchain Tools',
  index: 'tools',
  menu: [
    {
      name: 'Wallet',
      index: 'wallet',
      icon: walletIcon,
      route: '/tool/wallet/manage',
      url: null,
      subMenu: null,
    },
    {
      name: 'Block Explorer',
      index: 'block_explorer',
      icon: blockExplorerIcon,
      route: '/tool/block-explorer/manage',
      url: null,
      subMenu: null,
    },
  ],
};

const TAB_NETWORK_SOLUTION = {
  name: 'Network Solution',
  index: 'solution',
  menu: [
    {
      name: 'Gateway (Bridge)',
      index: 'gateway',
      icon: gatewayIcon,
      route: null,
      url: null,
      subMenu: null,
    },
    {
      name: 'Linker',
      index: 'linker',
      icon: linkerIcon,
      route: null,
      url: null,
      subMenu: null,
    },
    {
      name: 'Swap',
      index: 'swap',
      icon: swapIcon,
      route: null,
      url: null,
      subMenu: null,
    },
    {
      name: 'Decentralized Exchange',
      index: 'dex',
      icon: dexIcon,
      route: null,
      url: null,
      subMenu: null,
    },
  ],
};

const TAB_APPLICATION = {
  name: 'Applications',
  index: 'applications',
  menu: [
    {
      name: 'Token',
      index: 'token',
      icon: tokenIcon,
      route: '/application/token-kit',
      url: null,
      subMenu: null,
    },
    {
      name: 'Airdrop',
      index: 'airdrop',
      icon: airdropIcon,
      route: '/application/airdrop/manage',
      url: null,
      subMenu: null,
    },
    // {
    //   name: "Launchpad",
    //   index: "launchpad",
    //   icon: launchpadIcon,
    //   route: null,
    //   url: null,
    //   subMenu: null,
    // },
    {
      name: 'Non-Fungible Token',
      index: 'nft',
      icon: nftIcon,
      route: '/application/nft/manage',
      url: null,
      subMenu: null,
    },
    // {
    //   name: "Security Token",
    //   index: "sto",
    //   icon: stoIcon,
    //   route: null,
    //   url: null,
    //   subMenu: null,
    // },
  ],
};

const TAB_DEVELOPER = {
  name: 'Developer',
  index: 'developer',
  menu: [
    {
      name: 'Contract Library',
      index: 'contract_library',
      icon: contractIcon,
      route: '/developer/contract',
      url: null,
      subMenu: [
        // {
        //   name: "Create Contract",
        //   route: "/developer/contract/create",
        //   // route: null,
        //   url: null,
        // },
        // {
        //   name: "Contract Storage",
        //   route: "/developer/contract/storage",
        //   // route: null,
        //   url: null,
        // },
        {
          name: 'Contract Library',
          route: '/developer/contract/library',
          url: null,
        },
        {
          name: 'Contract List',
          route: '/developer/contract/list',
          url: null,
        },
      ],
    },
    {
      name: 'API Docs',
      index: 'api_docs',
      icon: apiDocsIcon,
      route: null,
      url: DOCS_API_REFERENCE_URL,
      subMenu: null,
    },
    // {
    //   name: "dApp",
    //   index: "dapp",
    //   icon: dappIcon,
    //   route: null,
    //   url: null,
    //   subMenu: null,
    // },
    {
      name: 'Payment Gateway',
      index: 'payment-gateway',
      icon: pgIcon,
      route: '/developer/payment-gateway/manage',
      url: null,
      subMenu: null,
    },
    {
      name: 'Chain Request',
      index: 'chain_request',
      icon: chainRequestIcon,
      route: '/developer/chain-request/request',
      url: null,
      subMenu: null,
    },
  ],
};

const TAB_DOCUMENTATION = {
  name: 'Documentation',
  index: 'documentation',
  menu: [
    {
      name: 'User Guide',
      index: 'user_guide',
      icon: userGuideIcon,
      route: null,
      url: DOCS_GUIDE_URL,
      subMenu: null,
    },
    {
      name: 'Developer Guide',
      index: 'developer_guide',
      icon: developerGuideIcon,
      route: null,
      url: DOCS_API_REFERENCE_URL,
      subMenu: null,
    },
  ],
};

export const TABS_IN_USE = [TAB_PROJECT, TAB_NETWORK, TAB_MICROCHAIN_TOOLS, TAB_APPLICATION, TAB_DEVELOPER, TAB_DOCUMENTATION];
