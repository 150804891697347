import BasicInput, {BasicInputProps} from "./BasicInput";
import {useMemo} from "react";
import './CommentInput.scss'

type CommentInputProps = BasicInputProps & {
  isValid?: boolean;
  message?: string;
}

const CommentInput = (
  {
    isValid = true,
    message,
    ...basicInputProps
  }: CommentInputProps) => {

  const commentColor = useMemo(() => isValid ? '#3F4248' : '#CF7171',[isValid])

  return (
    <div className={'comment-input'}>
      <BasicInput {...basicInputProps}/>
      {message && <span className={'comment-input-text'} style={{color: commentColor}}>{message}</span>}
    </div>
  );
};

export default CommentInput;
