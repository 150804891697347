import { useSelector } from "react-redux";
import ModalTemplateA from "../../../../../../legacy-common/template/modal/ModalTemplateA";
import { connectInfo } from "../../../../../../store/redux/EQconnect/EQConnectSlice";
import "./AirdropContractDeployModal.scss";
import { calculateGasFeeAction, makeTransactionForUploadContractAction } from "../../../../../../action/requestAction";
import { project_access } from "reducer/projectReducer";
import BasicButton from "view/components/_atom/button/BasicButton";
import { ButtonTheme } from "view/components/_atom/button/StyledButton";
import { ButtonSize } from "view/components/_atom/button/StyledButton";
import ButtonContainer from "../../../../../components/_atom/container/ButtonContainer";
import useTransaction from "../../../../../../hooks/useTransaction";
import { useEffect, useState } from "react";
import { errorAlert } from "../../../../../../utils/Utils";

const AirdropContractDeployModal = (props) => {
  const { isOpen, closeModal, data, callback } = props;
  const { address: connectedAddress } = useSelector(connectInfo);
  const accessToken = useSelector(project_access);
  const [fee, setFee] = useState("");

  const callback_ = async () => {
    callback();
    closeModal();
  };

  const uploadContractTransaction = async () => {
    return await makeTransactionForUploadContractAction({
      address: connectedAddress,
      microChainId: data.microchainId,
      contractId: data.contractId,
      parameters: [data.serverKeyAddress, data.currency.contractAddress],
      to: null,
      value: null,
      transactionPath: `${process.env.REACT_APP_HUB_SERVER_URL}${process.env.REACT_APP_API_VERSION_V2}airdrops/${data.airdropId}/release-contract`,
      token: accessToken,
      additionalQuery: {
        body: {
          contractId: data.contractId,
        },
      },
    });
  };

  const calculateTransactionFee = async () => {
    const { error, result } = await calculateGasFeeAction({
      microChainId: data.microchainId,
      to: null,
      value: null,
      from: connectedAddress,
      parameters: [data.serverKeyAddress, data.currency.contractAddress],
      contractIdentifier: data.contractId,
      isContractDeployTx: true,
    });
    if (error) {
      errorAlert(error.data.message);
    } else {
      setFee(result);
    }
  };
  useEffect(() => {
    if (data) {
      calculateTransactionFee();
    }
  }, [data]);

  const { requestTransaction } = useTransaction(uploadContractTransaction, callback_);

  return (
    <ModalTemplateA title="Deploy Contract" isOpen={isOpen} closeModal={closeModal}>
      <section className="airdrop-contract-deploy-modal">
        <div className="airdrop-contract-deploy-modal-row">
          <div className="airdrop-contract-deploy-modal-row-label">Network:</div>
          <div className="airdrop-contract-deploy-modal-row-key">{data.networkLabel}</div>
        </div>
        <div className="airdrop-contract-deploy-modal-row">
          <div className="airdrop-contract-deploy-modal-row-label">Microchain:</div>
          <div className="airdrop-contract-deploy-modal-row-key">{data.microchainLabel}</div>
        </div>
        <div className="airdrop-contract-deploy-modal-row">
          <div className="airdrop-contract-deploy-modal-row-label">Transaction Fee:</div>
          <div className="airdrop-contract-deploy-modal-row-key">{fee}</div>
        </div>
        <div className="flex justify-content-center">
          <ButtonContainer>
            <BasicButton theme={ButtonTheme.SECONDARY} size={ButtonSize.LARGE} onClick={() => closeModal()}>
              Close
            </BasicButton>
            <BasicButton size={ButtonSize.LARGE} onClick={() => requestTransaction()}>
              Deploy
            </BasicButton>
            {/*<RequestTransactionButton text={"Deploy"} onRequest={() => uploadContractTransaction()} onSuccess={() => callback_()} />*/}
          </ButtonContainer>
        </div>
      </section>
    </ModalTemplateA>
  );
};

export default AirdropContractDeployModal;
