import { makeQuery } from "../index/utils";
import { v1ServerByProjectAuth } from "../index/serverInstances";
import { NotificationFromServer, PlatformCategoryFromServer, PlatformServiceFromServer } from "../type/notificationServerType";

//getPlatformService---------------------------------------------------------------------------------------
const getPlatformService = async (request: GetPlatformServiceRequest): Promise<GetPlatformServiceResponse> =>
  await v1ServerByProjectAuth.get({
    path: `hub-notifications/platform-services`,
    query: makeQuery(request.query),
  });

type GetPlatformServiceRequest = {
  query: {
    isActive?: boolean;
  };
};

type GetPlatformServiceResponse = Array<PlatformServiceFromServer>;

//getPlatformCategories---------------------------------------------------------------------------------------
const getPlatformCategories = async (request: GetPlatformCategoriesRequest): Promise<GetPlatformCategoriesResponse> =>
  await v1ServerByProjectAuth.get({
    path: `hub-notifications/platform-categories`,
    query: makeQuery(request.query),
  });
type GetPlatformCategoriesRequest = {
  query: {
    platformServiceId?: number;
    isActive?: boolean;
  };
};
type GetPlatformCategoriesResponse = Array<PlatformCategoryFromServer>;

//getNotifications---------------------------------------------------------------------------------------
const getNotifications = async (request: GetNotificationsRequest): Promise<GetNotificationsResponse> =>
  await v1ServerByProjectAuth.get({
    path: `hub-notifications/platform-notifications`,
    query: makeQuery(request.query),
  });

type GetNotificationsRequest = {
  query: {
    lastPlatformNotificationId?: number;
    microChainId?: number;
    platformServiceId?: number;
    platformCategoryId?: number;
    device: "WEB" | "MOBILE" | "APP";
    limit: number;
  };
};

type GetNotificationsResponse = Array<NotificationFromServer>;

//getNotificationCount---------------------------------------------------------------------------------------
const getNotificationCount = async (): Promise<GetNotificationCountResponse> =>
  await v1ServerByProjectAuth.get({
    path: `hub-notifications/platform-notifications/count`,
  });

type GetNotificationCountResponse = {
  total_count: number;
  unread_count: number;
};

//readAllNotification---------------------------------------------------------------------------------------
const readAllNotification = async (): Promise<ReadAllNotificationResponse> =>
  await v1ServerByProjectAuth.post({
    path: `hub-notifications/platform-notifications/unread-count/reset`,
  });

type ReadAllNotificationResponse = {
  success: boolean;
};

//deleteNotification---------------------------------------------------------------------------------------
const deleteNotification = async (request: DeleteNotificationRequest): Promise<DeleteNotificationResponse> =>
  await v1ServerByProjectAuth.delete({
    path: `hub-notifications/platform-notifications/${request.params.platformNotificationId}`,
  });

type DeleteNotificationRequest = {
  params: {
    platformNotificationId: number;
  };
};

type DeleteNotificationResponse = {
  success: boolean;
};

//readNotification---------------------------------------------------------------------------------------
const readNotification = async (request: ReadNotificationRequest): Promise<ReadNotificationResponse> =>
  await v1ServerByProjectAuth.post({
    path: `hub-notifications/platform-notifications/${request.params.platformNotificationId}/read`,
  });

type ReadNotificationRequest = {
  params: {
    platformNotificationId: number;
  };
};

type ReadNotificationResponse = {
  success: boolean;
};
//---------------------------------------------------------------------------------------
export {
  getNotifications as getNotificationsFromServer,
  readAllNotification as readAllNotificationFromServer,
  deleteNotification as deleteNotificationFromServer,
  readNotification as readNotificationFromServer,
};
