import {useEffect, useRef, useState} from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getContractLibraryDetailAction,
  getRecommendContractLibrariesByCategoryAction,
  getRecommendContractLibrariesByTagAction,
} from "../../../../../action/contractLibraryAction";
import PageLoading from "../../../../components/_atom/loading/PageLoading";
import PageTitle from "../../../../components/_molecules/page/PageTitle";
import ContractConstructorViewer from "../organism/ContractConstructorViewer";
import ContractDetailInfo from "../organism/ContractDetailInfo";
import ContractMethodViewer from "../organism/ContractMethodViewer";
import ContractVerticalCard from "../organism/ContractVerticalCard";
import { isProxyContract } from "../utils/contractUtils";
import "./ManageLibraryContract.scss";

const DETAILSTAB = ["Constructor", "Functions", "Events"];

const ManageLibraryContract = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { contractId, versionId } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [changeDataLoading, setChangeDataLoading] = useState(false);

  const [contractDetail, setContractDetail] = useState({});
  const [versionDetail, setVersionDetail] = useState({});
  const [selectedVersion, setSelectedVersion] = useState(parseInt(versionId));

  const [contractConstructor, setContractConstructor] = useState({});
  const [contractFunctions, setContractFunctions] = useState([]);
  const [contractEvents, setContractEvents] = useState([]);

  const [isAccessTokenRefresh, setIsAccessTokenRefresh] = useState(false);

  const setVersionDataFromServer = async (contractId, versionId) => {
    const { error, result } = await getContractLibraryDetailAction(Number(contractId), Number(versionId));
    if (error) {
      if (error.data.error.code === 401) {
        setIsAccessTokenRefresh(true);
      }
    } else {
      const { contractDetail, versionDetail } = result;
      const { is_private, contractConstructor, contractFunctions, contractEvents } = versionDetail;
      setContractDetail(contractDetail);
      setContractConstructor(contractConstructor);
      setVersionDetail(versionDetail);
      setContractFunctions(contractFunctions);
      setContractEvents(contractEvents);
      setIsAccessTokenRefresh(false);
      setSelectedVersion(versionDetail.contract_version_id);
    }
  };

  const navigateToGetContract = () => {
    isProxyContract(contractDetail)
      ? navigate(`/developer/contract/library/${contractId}/version/${versionId}/get-proxy`)
      : navigate(`/developer/contract/library/${contractId}/version/${versionId}/get-fixed`);
  };

  useEffect(() => {
    if(articleRef.current){
      articleRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if (selectedVersion !== parseInt(versionId)) {
      navigate(`/developer/contract/library/${contractId}/version/${selectedVersion}`);
      return;
    }
  }, [selectedVersion]);

  const [recommendedTabs, setRecommendedTabs] = useState([]);
  const [recommendCurrentTab, setRecommendCurrentTab] = useState(0);
  const [detailsCurrentTab, setDetailsCurrentTab] = useState(0);
  const [recommendContractBySolution, setRecommendContractBySolution] = useState([]);
  const [recommendContractByStandard, setRecommendContractByStandard] = useState([]);

  const getRecommended = async (contractId) => {
    const recommendedStandard = await getRecommendContractLibrariesByCategoryAction(contractId);
    const recommendedSolution = await getRecommendContractLibrariesByTagAction(contractId);

    if (recommendedStandard.result && recommendedSolution.result) {
      const standardResult = recommendedStandard.result;
      const solutionResult = recommendedSolution.result;
      setRecommendContractByStandard(standardResult.contract);
      setRecommendContractBySolution(solutionResult.contract);
      if (standardResult.contract.length === 0 && solutionResult.contract.length !== 0) {
        setRecommendedTabs(["Solution"]);
        setRecommendCurrentTab(0);
        return;
      }
      if (solutionResult.contract.length === 0 && standardResult.contract.length !== 0) {
        setRecommendedTabs(["Standard"]);
        setRecommendCurrentTab(0);
        return;
      }
      setRecommendedTabs(["Solution", "Standard"]);
      setRecommendCurrentTab(0);
    }
  };

  const articleRef = useRef();


  useEffect(() => {
    setChangeDataLoading(true)

    Promise.all([  setVersionDataFromServer(contractId, versionId),getRecommended(contractId)]).finally(() => {
      setDetailsCurrentTab(0)
      setIsLoading(false);
      setChangeDataLoading(false);
    });
  }, [location.pathname, isAccessTokenRefresh, ]);



  if (isLoading) {
    return <PageLoading />;
  }
  return (
    <div id={'ManageLibraryContract'}>
      {changeDataLoading && (
        <div
          style={{
            width: "100%",
            height: "100vh",
            backgroundColor: "#ffffff50",
            position: "fixed",
            zIndex: 9999,
          }}
        >
          <PageLoading style={{ width: "812px", height: "calc(100vh - 97px)" }} />
        </div>
      )}
      {/*<PageArticle scrollDependency={contractDetail} scrollRef={scrollRef}>*/}
      <div className='page-article' ref={articleRef}>
        <PageTitle title={"Contract Detail"} size={812} />
        <div className="page-layout-812 padding-top-60">
          <ContractDetailInfo
            navigateToGetContract={() => navigateToGetContract()}
            labelKey={"version"}
            valueKey={"contract_version_id"}
            options={contractDetail.contractVersions}
            contractDetail={contractDetail}
            versionDetail={versionDetail}
            selectedValue={selectedVersion}
            setSelectedValue={setSelectedVersion}
            select={true}
            button={true}
            placeholder={location.state}
          />
        </div>
        {recommendContractBySolution.length > 0 || recommendContractByStandard.length > 0 ? (
          <div className="page-layout-812 padding-top-60">
            <div className="page-sub-title">Recommended contracts</div>
            <div className="contract-detail-tabs-box">
              {recommendedTabs.map((tab, index) => (
                <div
                  key={`tab-${index}`}
                  className={recommendCurrentTab === index ? "contract-detail-tab selected" : "contract-detail-tab"}
                  onClick={() => setRecommendCurrentTab(index)}
                  version={versionDetail.version}
                >
                  {tab}
                </div>
              ))}
            </div>
            <div style={{ display: "flex", gap: "20px" }}>
              {recommendedTabs[recommendCurrentTab] === "Solution" &&
                recommendContractBySolution.map((contract, index) => (
                  <ContractVerticalCard
                    key={`solution-${index}`}
                    contractInfo={contract}
                    version={versionDetail.version}
                  />
                ))}
              {recommendedTabs[recommendCurrentTab] === "Standard" &&
                recommendContractByStandard.map((contract, index) => (
                  <ContractVerticalCard key={`standard-${index}`} contractInfo={contract} version={versionDetail.version}/>
                ))}
            </div>
          </div>
        ) : null}
        <div className="page-layout-812 detail padding-top-60">
          <div className="page-sub-title">Details</div>
          <div className="contract-detail-tabs-box">
            {DETAILSTAB.map((tab, index) => (
              <div
                key={`detail-tab-${index}`}
                className={detailsCurrentTab === index ? "contract-detail-tab selected" : "contract-detail-tab"}
                onClick={() => setDetailsCurrentTab(index)}
              >
                {tab}
              </div>
            ))}
          </div>
          {DETAILSTAB[detailsCurrentTab] === "Constructor" &&
            contractConstructor &&
            contractConstructor.inputs.length !== 0 && (
              <ContractConstructorViewer
                constructorState={{ contractConstructor, setContractConstructor }}
                disabled={true}
                announcement={<AnnouncementOfConstructor />}
              />
            )}
          {DETAILSTAB[detailsCurrentTab] === "Functions" && contractFunctions.length !== 0 && (
            <ContractMethodViewer
              label={"Functions"}
              methodState={{ methods: contractFunctions, setMethods: setContractFunctions }}
              disabled={true}
              announcement={<AnnouncementOfFunctions />}
            />
          )}
          {DETAILSTAB[detailsCurrentTab] === "Events" && contractEvents.length !== 0 && (
            <ContractMethodViewer
              label={"Events"}
              methodState={{ methods: contractEvents, setMethods: setContractEvents }}
              disabled={true}
              announcement={<AnnouncementOfEvents />}
            />
          )}
        </div>
      {/*</PageArticle>*/}
      </div>
    </div>
  );
};
export default ManageLibraryContract;

const AnnouncementOfConstructor = () => {
  return (
    <div className="announcement-box">
      <div>
        The constructor is functions hosted only once when deploying them to the blockchain. The creator sets the
        initial status by inputting the initial values of the contract.
      </div>
    </div>
  );
};

const AnnouncementOfFunctions = () => {
  return (
    <div className="announcement-box">
      <div>
        The function is a bunch of code block that executes certain actions on smart contracts. Functions are capable of
        reading, updating internal data from the smart contract, and call external data.
      </div>
    </div>
  );
};

const AnnouncementOfEvents = () => {
  return (
    <div className="announcement-box">
      <div>
        Events are used to deliver specific status changes in smart contracts outside the blockchain, which allows
        external applications to detect and respond to state changes in smart contracts.
      </div>
    </div>
  );
};
