import { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PageLoading from "../../../../components/_atom/loading/PageLoading";
import TableStatusLabel from "../../../../components/_atom/label/TableStatusLabel";
import Table from "../../../../../legacy-common/atom/table/Table";
import { errorAlert } from "../../../../../utils/Utils";
import Section from "legacy-common/atom/section/Section";
import { getBlockExplorerListAction } from "action/blockExplorerAction";
import { selected_project_id } from "reducer/projectReducer";
import PageTitle from "view/components/_molecules/page/PageTitle";
import PageArticle from "view/components/_template/page/PageArticle";
import BasicButton from "view/components/_atom/button/BasicButton";
import { ButtonSize, ButtonTheme } from "view/components/_atom/button/StyledButton";
import useLoading from "hooks/useLoading";

function BlockExplorerList(props) {
  const navigate = useNavigate();

  const projectId = useSelector(selected_project_id);

  const [blockExplorerList, setBlockExplorerList] = useState([]);

  const getActivatedExplorerMicroChains_ = async () => {
    const { result, error } = await getBlockExplorerListAction({ projectId });

    if (result) {
      setBlockExplorerList(result);
    }
    if (error) {
      errorAlert(error.data.message);
    }
  };

  const headers = useMemo(
    () => [
      { title: "No", accessor: "index" },
      { title: "Network", accessor: "network" },
      { title: "Microchain", accessor: "microChain" },
      { title: "Status", accessor: "status" },
    ],
    []
  );

  const colGroup = useMemo(() => [10, 35, 35, 20].map((el) => ({ style: { width: `${el}%` } })), []);

  const onBEClicked = (el) => {
    navigate(`${el.microChainId}`);
  };

  const onCreateBlockExplorer = () => {
    navigate("/tool/block-explorer/register");
  };

  const renderer = useCallback(
    (data) => ({
      index: data.microChainId,
      network: data.networkLabel,
      microChain: data.microChainLabel,
      status: <TableStatusLabel mainStatus={data.mainStatus} subStatus={data.subStatus} custom={[{ status: 5, style: 5, text: "Deployed" }]} />,
    }),
    []
  );

  const blockExplorerListView = () => [
    {
      renderers: [
        {
          value: <Table headers={headers} data={blockExplorerList} renderer={renderer} colGroup={colGroup} onClick={onBEClicked} />,
        },
      ],
    },
  ];

  const { loading } = useLoading({
    dependency: [projectId],
    synchronousFunction: async () => {
      await getActivatedExplorerMicroChains_();
    },
  });

  if (loading) {
    return <PageLoading />;
  }

  return (
    <PageArticle>
      <PageTitle size={1240} title={"Block Explorer List"} description={"Register and manage your block explorers."} />
      <div className="page-layout-1240">
        <BasicButton theme={ButtonTheme.CREATE} size={ButtonSize.EXTRALARGE} onClick={() => onCreateBlockExplorer()}>
          Register in Block Explorer
        </BasicButton>
        <div style={{ height: 30 }} />
        <Section title={"Block Explorer"} view={blockExplorerListView} />
      </div>
    </PageArticle>
  );
}

export default BlockExplorerList;
