import { networkLabelAndMicroChainLabel } from "../reducer/clientType/microChainClientType";
import { getGasPriceFromServer, getMicroChainDetailFromServer, getMicroChainListFromServer } from "../server/API/microChainAPI";

// ------------ COMMON ------------

export const getNetworkLabelAndMicroChainLabelParser = async (
  microChainId: number,
  options?: { paranoid: string }
): Promise<networkLabelAndMicroChainLabel> => {
  const { micro_chain_detail } = await getMicroChainDetailFromServer({
    params: {
      microChainId,
    },
    query: {
      ...(options?.paranoid && { paranoid: JSON.parse(options.paranoid) }),
    },
  });
  const { network, micro_chain } = micro_chain_detail || {};
  return {
    networkLabel: network.label,
    microChainLabel: micro_chain.label,
    microChainId: micro_chain.id,
  };
};

export const getExternalAndInnerMicroChainListParser = async (options?: {
  projectId?: number;
  mainStatus?: Array<number>;
  microChainIds?: Array<number>;
}): Promise<any> => {
  const innerChainList = await getMicroChainListFromServer({
    query: {
      isInternalUse: true,
      ...(options?.projectId && { projectId: options.projectId }),
      ...(options?.microChainIds && { microChainIds: options.microChainIds }),
      ...(options?.mainStatus && { mainStatus: options.mainStatus }),
      external: false,
    },
  });

  const externalChainList = await getMicroChainListFromServer({
    query: {
      isInternalUse: true,
      ...(options?.projectId && { projectId: options.projectId }),
      ...(options?.microChainIds && { microChainIds: options.microChainIds }),
      ...(options?.mainStatus && { mainStatus: options.mainStatus }),
      external: true,
    },
  });

  const allMicroChains = [...innerChainList, ...externalChainList];
  return allMicroChains
    .map((el: any) => ({
      createdAt: el.created_at,
      externalMicroChainId: el.external_micro_chain_id,
      fee: el.fee,
      gasPrice: el.gas_price,
      host: el.host,
      id: el.id,
      isMain: el.is_main,
      label: el.label,
      loadBalancer: el.load_balancer,
      mainStatus: el.main_status,
      network: {
        blockConfirmationCount: el.network.block_confirmation_count,
        blockConfirmationPercentage: el.network.block_confirmation_percentage,
        createdAt: el.network.created_at,
        id: el.network.id,
        isBlockConfirmationCountFixable: el.network.is_block_confirmation_count_fixable,
        isPrivate: el.network.is_private,
        label: el.network.label,
        mainStatus: el.network.main_status,
        subStatus: el.network.sub_status,
        updatedAt: el.network.updated_at,
      },
      networkId: el.network_id,
      subStatus: el.sub_status,
      updatedAt: el.updated_at,
      version: el.version,
    }))
    .slice()
    .reverse();
};

export const getMicroChainListParser = async (options?: {
  projectId?: number;
  mainStatus?: Array<number>;
  microChainIds?: Array<number>;
  isPublic?: boolean;
  external?: boolean;
}): Promise<any> => {
  const result = await getMicroChainListFromServer({
    query: {
      isInternalUse: true,
      ...(options?.projectId && { projectId: options.projectId }),
      ...(options?.microChainIds && { microChainIds: options.microChainIds }),
      ...(options?.mainStatus && { mainStatus: options.mainStatus }),
      ...(options?.isPublic && { isNodeParticipationAvailability: options.isPublic }),
      ...(options?.external && { external: options.external }),
    },
  });

  const response = result
    .map((el: any) => ({
      createdAt: el.created_at,
      externalMicroChainId: el.external_micro_chain_id,
      fee: el.fee,
      gasPrice: el.gas_price,
      host: el.host,
      id: el.id,
      isMain: el.is_main,
      label: el.label,
      loadBalancer: el.load_balancer,
      mainStatus: el.main_status,
      network: {
        blockConfirmationCount: el.network.block_confirmation_count,
        blockConfirmationPercentage: el.network.block_confirmation_percentage,
        createdAt: el.network.created_at,
        id: el.network.id,
        isBlockConfirmationCountFixable: el.network.is_block_confirmation_count_fixable,
        isPrivate: el.network.is_private,
        label: el.network.label,
        mainStatus: el.network.main_status,
        subStatus: el.network.sub_status,
        updatedAt: el.network.updated_at,
      },
      networkId: el.network_id,
      subStatus: el.sub_status,
      updatedAt: el.updated_at,
      version: el.version,
    }))
    .slice()
    .reverse();
  return response;
};

// ----------------------------------------------------------------------------------------------------------------------------------------

export const getMicroChainDetailParser = async (microChainId: number, options?: { paranoid: string }): Promise<any> => {
  const result = await getMicroChainDetailFromServer({
    params: { microChainId },
    query: {
      ...(options?.paranoid && { paranoid: JSON.parse(options.paranoid) }),
    },
  });

  const { micro_chain_detail: data } = result;
  const response = {
    createdAt: data.created_at,
    mainStatus: data.main_status,
    subStatus: data.sub_status,
    updatedAt: data.updated_at,
    microChain: {
      externalMicroChainId: data.micro_chain.external_micro_chain_id,
      host: data.micro_chain.host,
      id: data.micro_chain.id,
      isMain: data.micro_chain.is_main,
      label: data.micro_chain.label,
      loadBalancer: data.micro_chain.load_balancer,
      ownerId: data.micro_chain.owner_id,
    },
    microChainSetting: {
      disInflationRate: data.micro_chain_setting.dis_inflation_rate,
      equalizerCount: data.micro_chain_setting.equalizer_count,
      fee: data.micro_chain_setting.fee,
      fullNodeCount: data.micro_chain_setting.full_node_count,
      gasPrice: data.micro_chain_setting.gas_price,
      inflationRate: data.micro_chain_setting.inflation_rate,
      initialSupply: data.micro_chain_setting.initial_supply,
      lightNodeCount: data.micro_chain_setting.light_node_count,
      proposerIncentiveRate: data.micro_chain_setting.proposer_incentive_rate,
      specTypeId: data.micro_chain_setting.spec_type_id,
    },
    network: {
      externalNetworkId: data.network.external_network_id,
      id: data.network.id,
      label: data.network.label,
      ownerId: data.network.owner_id,
    },
  };

  return response;
};

export const getGasPriceParser = async (microChainId: number) => {
  const { gas_price } = await getGasPriceFromServer({
    params: { microChainId },
  });
  return gas_price;
};
