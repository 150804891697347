import React from 'react';
import {Routes} from "react-router-dom";

const SolutionRouter = () => {
  return (
    <Routes>
      {/*<Route path="dex/*" element={<DEXRouter/>}/>*/}
      {/*<Route path="gateway/*" element={<GatewayRouter/>}/>*/}
    </Routes>
  );
};

export default SolutionRouter;

const DEXRouter = () => {
  return (
    <Routes>
      {/*<Route path="register" element={<RegisterAsset/>}/>*/}
      {/*<Route path="manage/*">*/}
      {/*  <Route index element={<AssetList/>}/>*/}
      {/*  <Route path={":id"} element={<>manage dApp</>}/>*/}
      {/*</Route>*/}
    </Routes>
  )
}

const GatewayRouter = () => {
  return (
    <Routes>
      {/*<Route path="register" element={<RegisterGateway/>}/>*/}
      {/*<Route path="manage/*">*/}
      {/*  <Route index element={<GatewayList/>}/>*/}
      {/*  <Route path={":id"} element={<ManageGateway/>}/>*/}
      {/*</Route>*/}
    </Routes>
  )
}
