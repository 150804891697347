//인자로 전달받은 validateCondition의 condition들중 상단부터 확인합니다.
// 총 3개의 condition이 있다고 가정한다면, 확인해야 하는 순서대로 array에 작성해서
// 넘겨주시면 됩니다.

export const validationController = (inputValidationStatus = {}) => {
  return {
    result: inputValidationStatus ?
      Object.keys(inputValidationStatus).reduce((acc,cur) => {
        return !Boolean(inputValidationStatus[cur].comment) && acc
      }, true)
      :
      true,
    invalidData: {...inputValidationStatus},
    validateCondition: {},
    setValidateFormat: function(validateCondition){
      let validateFormat = {};
      Object.keys(validateCondition).map(key => {
        validateFormat = {
          ...validateFormat,
          [key]: {
            comment: ''
          }
        }
      })
      return validateFormat
    },
    checkValidation: function(userInputs, validateCondition){
      Object.keys(this.invalidData).forEach(input => {
        validateCondition[input].forEach(condition => {
          const valueResult = condition.condition(userInputs[input])
          if (!valueResult && !Boolean(this.invalidData[input].comment)) {
            this.result = false;
            this.invalidData[input].comment = condition.comment;
          }
        })
      })
      return this;
    },
    validationResult: function(){
      return {
        result: this.result,
        invalidData: (this.result ? null : this.invalidData)
      }
    }
  }
}