import { useEffect, useRef, useState } from "react";
import "./SNB.scss";
import { TABS_IN_USE } from "./tabs";
import TabSection from "./organisms/TabSection";
import userIcon from "../../assets/images/service/Icon_SNB_user.png";
import Notifications from "../header/organism/Notification/Notifications";
import { useSelector } from "react-redux";
import { user_info } from "reducer/userReducer";
import UserPanel from "../header/organism/User/UserPanel";

const SNB = () => {
  const TABS = TABS_IN_USE;

  const userInfo = useSelector(user_info);

  const [opendSubMenuList, setOpendSubMenuList] = useState([]);
  const [clickedMenu, setClickedMenu] = useState();
  const userPanelRef = useRef(null);
  const [isUserPanelOpen, setIsUserPanelOpen] = useState(false);

  useEffect(() => {
    const userPanelHandleOutsideClose = (e) => {
      if (isUserPanelOpen) setIsUserPanelOpen(false);
    };

    document.addEventListener("click", userPanelHandleOutsideClose);

    return () => document.removeEventListener("click", userPanelHandleOutsideClose);
  }, [isUserPanelOpen]);

  return (
    <div id={"SNB"}>
      <div className="user-menu">
        <div className="user-menu-info" onClick={() => setIsUserPanelOpen((prev) => !prev)}>
          {isUserPanelOpen && <UserPanel setIsUserPanelOpen={setIsUserPanelOpen} ref={userPanelRef} />}
          <div className="user-menu-info-icon">
            <img src={userIcon} className="user-menu-info-icon-src" alt="" />
          </div>
          <div className="user-menu-info-label-wrapper">
            <div className="user-menu-info-label">
              <span className="user-menu-info-label-hello">Hello, </span>
              <span className="user-menu-info-label-name">{`${userInfo.firstName} ${userInfo.lastName}`}</span>
            </div>
          </div>
        </div>
        <Notifications />
      </div>
      <div className="nav-container">
        {TABS.map((tab) => (
          <TabSection
            tab={tab}
            key={`tab-section-${tab.index}`}
            opendSubMenuList={opendSubMenuList}
            setOpendSubMenuList={setOpendSubMenuList}
            clickedMenu={clickedMenu}
            setClickedMenu={setClickedMenu}
          />
        ))}
      </div>
    </div>
  );
};

export default SNB;
