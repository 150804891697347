import React, { useRef } from "react";
import "./ImageUpload.scss";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import BasicButton from "../button/BasicButton";
import { ButtonShape, ButtonSize } from "../button/StyledButton";

const ImageUpload = (props) => {
  const {
    image,
    setImage,
    onClickUploader = null,
    disabled = false,
    style,
    previewStyle,
    buttonText = "Upload",
    previewLabel = "preview",
    comment = "",
  } = props || {};
  const imageRef = useRef(null);
  const [previewImage, setPreviewImage] = useState(null);
  const uploadImage = () => {
    if (imageRef.current.files[0]) {
      const file = imageRef.current.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        if (file.size > 5 * 1024 * 1024) {
          toast.error("image file must be less than or equal to 5 MB");
        } else {
          setImage(file);
        }
      };
    }
  };

  useEffect(() => {
    if (image) {
      if (typeof image === "string") {
        setPreviewImage(image);
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onloadend = () => {
          setPreviewImage(reader.result);
        };
      }
    } else {
      setPreviewImage(null);
    }
  }, [image]);

  return (
    <div className="image-uploader" style={style && style}>
      <input
        name={"image-uploader"}
        type="file"
        accept="image/png, image/jpeg, image/gif"
        ref={imageRef}
        onChange={(e) => {
          uploadImage(e);
        }}
      />
      <div
        className="preview"
        style={{ ...previewStyle }}
        onClick={() => (disabled ? undefined : onClickUploader ? onClickUploader() : imageRef.current.click())}
      >
        {image ? (
          <img src={previewImage} className={"preview-image"} style={{ cursor: disabled ? "default" : "pointer" }} alt={"image"} />
        ) : (
          <>{previewLabel}</>
        )}
      </div>
      {!disabled && <div className="upload-comment">{comment}</div>}
      {!disabled ? (
        <BasicButton size={ButtonSize.SMALL} shape={ButtonShape.ROUNDED} onClick={() => (onClickUploader ? onClickUploader() : imageRef.current.click())}>
          {buttonText}
        </BasicButton>
      ) : null}
    </div>
  );
};

export default ImageUpload;
