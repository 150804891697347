import {WEB3} from "../../../../../modules/web3/Web3";
import toast from "react-hot-toast";

export const isProxyContract = (contractDetail) => {
  return contractDetail.type === 1
}

export const isArrayParameter = (type) => {
  return type.includes('[]')
}

export const isCorrectWalletAddressRegex = (address) => {
  return WEB3.isAddress(address)
}

export const arrayHandler = (prev, index, value) => {
  let arr = [...prev];
  arr[index] = value;
  return arr;
}

export const removeEmptyElement = (array) => {
  return array.map(value => {
    if(typeof value !== 'string'){
      return value.filter(el => Boolean(el.trim())).map(el => el.trim())
    }else{
      return value.trim()
    }
  })
}

export const readFile = async(file) => {
  try {
    const blob = new Blob([file]);
    const fileResult = await blob.text();
    return JSON.parse(fileResult);
  } catch (e) {
    console.warn(e);
  }
}

// export const addDescriptionProperty = (object) => {
//   return {
//     ...object,
//     description: ""
//   }
// }

export const addDescriptionProperty = (object) => {
  return {
    ...object,
    description: ""
  }
}

export const addDescriptionRecursive = (abiItemArray, key = 'components') => {
  return abiItemArray.map(abi => {
    const parseAbi = addDescriptionProperty(abi);
    if(parseAbi[key] && parseAbi[key].length !== 0){
      return {
        ...parseAbi,
        [key]: addDescriptionRecursive(parseAbi[key])
      }
    }
    return {
      ...parseAbi
    }
  })
}
