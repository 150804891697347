import React, { useEffect, useMemo, useState } from "react";
import "./AirdropDistributionSendFeeModal.scss";
import { useSelector } from "react-redux";
import PageLoading from "../../../../../components/_atom/loading/PageLoading";
import ModalTemplateA from "../../../../../../legacy-common/template/modal/ModalTemplateA";
import { connectInfo } from "../../../../../../store/redux/EQconnect/EQConnectSlice";
import { WEB3 } from "../../../../../../modules/web3/Web3";
import { addComma, errorAlert, removeComma } from "../../../../../../utils/Utils";
import { calculateGasFeeAction, makeTransactionAction } from "../../../../../../action/requestAction";
import bulletPoint from "../../../../../assets/images/bullet-point-airdrop-modal.png";
import { getDistributionCountAction } from "action/airdropAction";
import BasicButton from "view/components/_atom/button/BasicButton";
import { ButtonSize, ButtonTheme } from "view/components/_atom/button/StyledButton";
import ButtonContainer from "../../../../../components/_atom/container/ButtonContainer";
import useLoading from "hooks/useLoading";
import useTransaction from "../../../../../../hooks/useTransaction";
import { project_access } from "../../../../../../reducer/projectReducer";
import BigNumber from "bignumber.js";

const AirdropDistributionSendFeeModal = (props) => {
  const { isOpen, closeModal, data, airdropId, callback } = props || {};

  const { address: connectedAddress } = useSelector(connectInfo);
  const accessToken = useSelector(project_access);

  const [distributionCountData, setDistributionCountData] = useState();
  const [fee, setFee] = useState("");

  const callback_ = () => {
    callback();
    closeModal();
  };

  const getDistributionFee_ = async () => {
    const { result, error } = await getDistributionCountAction(airdropId, data.microchainId);
    if (result) {
      setDistributionCountData(result);
    }
    if (error) {
      errorAlert(error.data.message);
    }
  };

  const calculateFee = async () => {
    const airdropAmount = WEB3.toBN(data.distributionAmount.distributionAmounts[0].amount).toString();
    const { error, result } = await calculateGasFeeAction({
      microChainId: data.microchainId,
      to: data.contractAddress,
      contractIdentifier: data.contractAddress,
      functionName: "airdrop",
      parameters: [[distributionCountData.virtualAddress], [WEB3.toWei(airdropAmount)]],
      from: distributionCountData.virtualAddress,
      isContractDeployTx: false,
    });
    if (error) {
      errorAlert(error.data.message);
    } else {
      setFee(result);
    }
  };

  const distributionSendFee_ = async () => {
    return await makeTransactionAction({
      address: connectedAddress,
      microChainId: data.microchainId,
      to: distributionCountData.virtualAddress,
      value: WEB3.toWei(removeComma(totalFee)),
      functionName: "transfer",
      transactionPath: `${process.env.REACT_APP_HUB_SERVER_URL}${process.env.REACT_APP_API_VERSION_V2}airdrops/${data.airdropId}/sendFee`,
      token: accessToken,
    });
  };

  const totalFee = useMemo(() => {
    if (fee && distributionCountData) {
      return BigNumber(fee).multipliedBy(BigNumber(distributionCountData.maximumDistributionCount)).toFormat();
    }
    return "";
  }, [fee, distributionCountData]);

  useEffect(() => {
    if (distributionCountData) {
      calculateFee();
    }
  }, [distributionCountData]);

  const { requestTransaction } = useTransaction(distributionSendFee_, callback_);

  const { loading } = useLoading({
    dependency: [airdropId],
    synchronousFunction: async () => {
      await getDistributionFee_();
    },
  });

  return (
    <ModalTemplateA title="Send fee" isOpen={isOpen} closeModal={closeModal}>
      {loading ? (
        <PageLoading />
      ) : (
        <section className="airdrop-distribution-send-fee-modal">
          <div className="airdrop-distribution-send-fee-modal-description">{`To make the process of rewarding the winners more efficient, the total tx fee is deposited in advance to request tx generation.\nAfter the payment process is over, the remaining tx fee will be refunded.`}</div>
          <div className="airdrop-distribution-send-fee-modal-information">
            <div className="airdrop-distribution-send-fee-modal-information-row">
              <div className="airdrop-distribution-send-fee-modal-information-row-label">
                <img src={bulletPoint} alt="" className="airdrop-distribution-send-fee-modal-information-row-label-bullet-point" />
                Address
              </div>
              <div className="airdrop-distribution-send-fee-modal-information-row-value">{`${distributionCountData.virtualAddress}`}</div>
            </div>
            <div className="airdrop-distribution-send-fee-modal-information-row">
              <div className="airdrop-distribution-send-fee-modal-information-row-label">
                <img src={bulletPoint} alt="" className="airdrop-distribution-send-fee-modal-information-row-label-bullet-point" />
                Estimated total tx fee
              </div>
              <div className="airdrop-distribution-send-fee-modal-information-row-value">{`${addComma(totalFee)} ${distributionCountData.coinSymbol}`}</div>
            </div>
          </div>
          <div className="airdrop-distribution-send-fee-modal-button-container">
            <ButtonContainer>
              <BasicButton theme={ButtonTheme.SECONDARY} size={ButtonSize.LARGE} onClick={() => closeModal()}>
                Close
              </BasicButton>
              <BasicButton size={ButtonSize.LARGE} disabled={!connectedAddress || !Boolean(totalFee)} onClick={() => requestTransaction()}>
                Send fee
              </BasicButton>
            </ButtonContainer>
          </div>
        </section>
      )}
    </ModalTemplateA>
  );
};

export default AirdropDistributionSendFeeModal;
