import { useNavigate } from "react-router-dom";
import { isProxyContract } from "../utils/contractUtils";
import iconDeleteRed from "../../../../assets/images/atom/icon_delete_red.png";
import BasicButton from "view/components/_atom/button/BasicButton";
import { ButtonSize } from "view/components/_atom/button/StyledButton";
import { ButtonTheme } from "view/components/_atom/button/StyledButton";

const ManageStoredContractButtonHandler = (props) => {
  const {
    status,
    isPrivate,
    contractId,
    versionId,
    contractDetail,
    isAbleToUpgradeVersion,
    isEditModeStatus: { isEditMode, setIsEditMode },
    onClickSave,
    onClickSubmit,
    onClickDelete,
    onClickConfirm,
    onClickPublish,
    onClickWithdraw,
  } = props || {};
  const navigate = useNavigate();

  const navigateToGetContract = () => {
    isProxyContract(contractDetail)
      ? navigate(`/developer/contract/library/${contractId}/version/${versionId}/get-proxy`)
      : navigate(`/developer/contract/library/${contractId}/version/${versionId}/get-fixed`);
  };

  return (
    <>
      {isEditMode && (
        <BasicButton size={ButtonSize.LARGE} onClick={() => onClickSave()}>
          Save
        </BasicButton>
      )}
      {!isEditMode && (
        <>
          {status <= 1 && (
            <div className="flex justify-content-flex-end">
              <BasicButton size={ButtonSize.ICON} theme={ButtonTheme.MINOR} onClick={() => onClickDelete()}>
                <img src={iconDeleteRed} style={{ width: 18, objectFit: "contain" }} alt={"delete"} />
              </BasicButton>
              <BasicButton
                size={ButtonSize.LARGE}
                theme={ButtonTheme.SECONDARY}
                onClick={() => {
                  setIsEditMode(true);
                }}
              >
                Edit
              </BasicButton>
              <BasicButton size={ButtonSize.LARGE} onClick={() => onClickSubmit()}>
                Submit
              </BasicButton>
            </div>
          )}
          {/* {status === 2 && (
            <>
              <Button className={"main"} text={"Confirm"} onClick={() => onClickConfirm()} />
              <Button className={"border-red"} text={"Reject"} onClick={() => onClickReject()} />
            </>
          )} */}
          {!isPrivate && (
            <>
              {status === 3 && (
                <BasicButton size={ButtonSize.LARGE} onClick={() => onClickPublish()}>
                  Publish
                </BasicButton>
              )}
              {status === 4 && (
                <BasicButton size={ButtonSize.LARGE} theme={ButtonTheme.MINOR} onClick={() => onClickWithdraw()}>
                  Withdraw
                </BasicButton>
              )}
            </>
          )}
          {status >= 3 && (
            <>
              <BasicButton size={ButtonSize.LARGE} onClick={() => navigateToGetContract()}>
                Add to List
              </BasicButton>
              <BasicButton size={ButtonSize.LARGE} theme={ButtonTheme.SECONDARY} onClick={() => setIsEditMode(true)}>
                Edit
              </BasicButton>
              {isAbleToUpgradeVersion && (
                <BasicButton size={ButtonSize.LARGE} theme={ButtonTheme.SECONDARY} onClick={() => navigate(`update`)}>
                  Update version
                </BasicButton>
              )}
              <BasicButton size={ButtonSize.ICON} theme={ButtonTheme.MINOR} onClick={() => onClickDelete()}>
                <img src={iconDeleteRed} style={{ width: 18, objectFit: "contain" }} alt={""} />
              </BasicButton>
            </>
          )}
        </>
      )}
    </>
  );
};

export default ManageStoredContractButtonHandler;
