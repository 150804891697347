import React from "react";
import "./NodeDeleteModal.scss";
import { deleteNodeAction } from "action/nodeAction";
import toast from "react-hot-toast";
import { errorAlert } from "utils/Utils";
import { useNavigate } from "react-router-dom";
import ModalTemplateB from "view/components/_template/modal/ModalTemplateB";
import BasicButton from "view/components/_atom/button/BasicButton";
import { ButtonSize, ButtonTheme } from "view/components/_atom/button/StyledButton";
import ButtonContainer from "view/components/_atom/container/ButtonContainer";

type NodeDeleteModalProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  nodeId: number;
  mainStatus: number;
};

const NodeDeleteModal = (props: NodeDeleteModalProps) => {
  const { isOpen, setIsOpen, nodeId, mainStatus } = props;

  const navigate = useNavigate();

  const deleteNode_ = async () => {
    const { result, error } = await deleteNodeAction(nodeId);

    if (result) {
      toast.success("Deleted successfully");
      navigate("/network/node/manage");
    }
    if (error) {
      errorAlert(error.data.message);
    }
  };

  return (
    <ModalTemplateB isOpen={isOpen} onRequestClose={() => setIsOpen(false)} title="Delete Node">
      <section className="node-delete-modal">
        <div className="node-delete-modal-description">
          {mainStatus === 5 &&
            `Are you sure you want to delete it?\nAll data generated so far will be lost forever,\nand there will be a huge negative impact on all nodes and related DApps\nparticipating in the network.`}
          {mainStatus !== 5 && `Are you sure you want to delete it? All data generated so far will be lost forever.`}
        </div>
        <ButtonContainer>
          <BasicButton size={ButtonSize.LARGE} theme={ButtonTheme.SECONDARY} onClick={() => setIsOpen(false)}>
            Cancel
          </BasicButton>
          <BasicButton size={ButtonSize.LARGE} onClick={() => deleteNode_()}>
            Delete
          </BasicButton>
        </ButtonContainer>
      </section>
    </ModalTemplateB>
  );
};

export default NodeDeleteModal;
