import { useEffect, useState } from "react";
import ImageUpload from "../../../../../components/_atom/file/ImageUpload";
import MultipleInput from "../../../../../components/_molecules/input/MultipleInput";
import {
  VALIDATION__AIRDROP_project_description,
  VALIDATION__AIRDROP_project_image,
  VALIDATION__AIRDROP_project_links,
  VALIDATION__AIRDROP_project_name,
} from "../../validation/airdropValidation";
import ButtonContainer from "view/components/_atom/container/ButtonContainer";
import BasicButton from "view/components/_atom/button/BasicButton";
import { ButtonSize, ButtonTheme } from "view/components/_atom/button/StyledButton";
import useValidateCondition from "hooks/useValidateCondition";
import useIsAble from "hooks/useIsAble";
import CommentInput from "view/components/_atom/input/CommentInput";
import { FieldContainer, FieldLabel, RowField } from "view/components/_atom/section/Field";
import airdropIcon from "../../../../../assets/images/service/Icon_SNB_airdrop.png";
import BasicTextarea from "view/components/_atom/input/BasicTextarea";

const AirdropBasicInfoSetting = (props) => {
  const { onClickBackButton, airdropData, setAirdropData, setCurrentStep, marketingChannelList, setMarketingChannelList } = props || {};

  const [projectImage, setProjectImage] = useState();

  useEffect(() => {
    setProjectImage(airdropData.projectImage);
  }, []);

  useEffect(() => {
    if (projectImage) {
      setAirdropData(projectImage, "projectImage");
    }
  }, [projectImage]);

  useEffect(() => {
    setAirdropData(marketingChannelList, "marketingChannels");
  }, [marketingChannelList]);

  const nameValidation = useValidateCondition([airdropData.projectName], VALIDATION__AIRDROP_project_name(airdropData.projectName));
  const descriptionValidation = useValidateCondition([airdropData.projectDescription], VALIDATION__AIRDROP_project_description(airdropData.projectDescription));
  const imageValidation = useValidateCondition([airdropData.projectImage], VALIDATION__AIRDROP_project_image(airdropData.projectImage));
  const linksValidation = useValidateCondition([airdropData.marketingChannels], VALIDATION__AIRDROP_project_links(airdropData.marketingChannels));

  const isAble = useIsAble([nameValidation.isValid, descriptionValidation.isValid, imageValidation.isValid, linksValidation.isValid]);

  const onClickNextButton = () => {
    setCurrentStep((prevState) => prevState + 1);
  };

  return (
    <>
      {/* Airdrop Info Description :: The network label will have the closest meaning to your project name. */}
      <FieldContainer icon={airdropIcon} title="Airdrop Info">
        <RowField>
          <FieldLabel label="Project name" />
          <CommentInput
            {...nameValidation}
            onChange={(e) => setAirdropData(e.target.value, "projectName")}
            value={airdropData.projectName ?? ""}
            placeholder={"Enter the name of project"}
            autoFocus
          />
        </RowField>
        <RowField>
          <FieldLabel label="Project description" />
          <BasicTextarea
            onChange={(e) => setAirdropData(e.target.value, "projectDescription")}
            value={airdropData.projectDescription ?? ""}
            placeholder={"Enter the description of project"}
          />
        </RowField>
        <RowField>
          <FieldLabel label="Project image" />
          <ImageUpload
            image={projectImage}
            setImage={(image) => setProjectImage(image)}
            buttonText={"Upload"}
            comment={"Max 5MB. PNG, JPG, GIF."}
            style={{ alignItems: "start" }}
          />
        </RowField>
        <RowField>
          <FieldLabel label="Project links" essential={false} />
          <MultipleInput inputArray={marketingChannelList} setInputArray={setMarketingChannelList} placeholder={"Ex) https://eqbr.com/"} />
        </RowField>
      </FieldContainer>

      <div className="flex justify-content-flex-end">
        <ButtonContainer>
          <BasicButton theme={ButtonTheme.SECONDARY} size={ButtonSize.LARGE} className={"border-blue"} onClick={() => onClickBackButton()}>
            Back
          </BasicButton>
          <BasicButton size={ButtonSize.LARGE} disabled={!isAble} onClick={() => onClickNextButton()}>
            Next
          </BasicButton>
        </ButtonContainer>
      </div>
    </>
  );
};

export default AirdropBasicInfoSetting;
