import { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PageLoading from "../../../../components/_atom/loading/PageLoading";
import Table from "../../../../../legacy-common/atom/table/Table";
import TableStatusLabel from "../../../../components/_atom/label/TableStatusLabel";
import { errorAlert } from "../../../../../utils/Utils";
import Section from "../../../../../legacy-common/atom/section/Section";
import { selected_project_id } from "reducer/projectReducer";
import { getListedContractsAction } from "../../../../../action/contractAction";
import PageArticle from "../../../../components/_template/page/PageArticle";
import PageTitle from "../../../../components/_molecules/page/PageTitle";
import useLoading from "hooks/useLoading";

const ContractList = () => {
  const navigate = useNavigate();
  const projectId = useSelector(selected_project_id);

  const [listedContracts, setListedContracts] = useState([]);

  const headers = useMemo(
    () => [
      { title: "ID", accessor: "contractId" },
      { title: "Name", accessor: "name" },
      { title: "Network", accessor: "network" },
      { title: "MicroChain", accessor: "microChain" },
      { title: "Version", accessor: "version" },
      { title: "Status", accessor: "status" },
    ],
    []
  );

  const renderer = useCallback(
    (data) => ({
      contractId: data.contractId,
      name: data.name,
      network: data.microChain?.network.label,
      microChain: data.microChain?.label,
      version: data.originVersion ?? "-",
      status: TableStatusLabel({
        mainStatus: data.mainStatus,
        custom: [
          { status: 5, text: "Deployed", style: 5 },
          { status: 3, text: "Created", style: 1 },
        ],
      }),
    }),
    []
  );

  const colGroup = useMemo(() => [10, 20, 20, 15, 15, 20].map((el) => ({ style: { width: `${el}%` } })), []);

  const onClickContract = (el) => {
    navigate(`/developer/contract/list/${el.contractId}`);
  };

  const { loading } = useLoading({
    dependency: [projectId],
    synchronousFunction: async () => {
      await setListedContractsFromServer();
    },
  });

  const setListedContractsFromServer = async () => {
    const { error, result } = await getListedContractsAction([0, 1, 2, 3, 4, 5]);
    if (error) errorAlert(error.data.message);
    else setListedContracts(result);
  };

  const CONTRACT_LIST_VIEW = () => [
    {
      renderers: [
        {
          value: (
            <Table
              headers={headers}
              data={listedContracts}
              colGroup={colGroup}
              renderer={renderer}
              onClick={onClickContract}
              noDataPlaceholder={"No Contracts"}
            />
          ),
        },
      ],
    },
  ];

  if (loading) {
    return <PageLoading />;
  }
  return (
    <PageArticle>
      <PageTitle title={"Contract List"} description={"Deploy the contracts added to the list to the chain."} size={1240} />
      <div className="page-layout-1240">
        <Section title={"Published Contracts"} view={CONTRACT_LIST_VIEW} />
      </div>
    </PageArticle>
  );
};

export default ContractList;
