import { useEffect, useRef } from 'react';

const PageArticle = (props) => {
  const { children, scrollDependency, id } = props;
  const ref = useRef();

  //==================================//
  const scrollToTop = () => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    scrollToTop();
  }, [scrollDependency]);
  //==================================//

  return (
    <div className="page-article" ref={ref} id={id}>
      {children}
    </div>
  );
};

export default PageArticle;
