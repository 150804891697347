import {
  changeUserPasswordParser,
  checkEmailParser,
  checkPasswordParser,
  checkPasswordRecommendedDateParser,
  createTotpKeyParser,
  createUserParser,
  deleteTotpKeyParser,
  findUserPasswordParser,
  getBelongProjectsParser,
  getMyUserParser,
  getUserParser,
  sendAuthCodeEmailParser,
  setTotpKeyParser,
  updateUserPasswordParser,
  verifyAuthCodeParser,
  verifyFindPasswordAuthCodeParser,
} from "../parser/userParser";
import { AuthCodeType } from "../server/type/userType";
import { parseAction } from "./util/parseAction";
import { storeDispatch } from "../reducer/index";
import { Project } from "../reducer/clientType/projectClientType";
import { setProjects } from "../reducer/projectReducer";
import { setUser } from "../reducer/userReducer";
import { changeToHash } from "server/index/utils";

export const signUpAction = (email: string, password: string, birthday: string, firstName: string, lastName: string) =>
  parseAction(async () => {
    const hashedPassword = changeToHash(password);
    const isValidEmail = await checkEmailParser(email);
    if (isValidEmail) {
      return await createUserParser(firstName, lastName, birthday, email, hashedPassword);
    }
  });

export const findUserPasswordAction = (email: string) =>
  parseAction(async () => {
    return await findUserPasswordParser(email);
  });

export const verifyFindPasswordAuthCodeAction = (authCode: string) =>
  parseAction(async () => {
    return await verifyFindPasswordAuthCodeParser(authCode);
  });

export const changeUserPasswordAction = (authCode: string, newPassword: string) =>
  parseAction(async () => {
    const password = changeToHash(newPassword);
    return await changeUserPasswordParser(authCode, password);
  });

export const updateUserPasswordAction = (password: string, newPassword: string) =>
  parseAction(async () => {
    return await updateUserPasswordParser(password, newPassword);
  });

export const getUserAction = (userId: number) =>
  parseAction(async () => {
    const result = await getUserParser(userId);
    return result;
  });

export const getMyUserAction = () =>
  parseAction(async () => {
    const result = await getMyUserParser();
    storeDispatch(setUser(result));
    return result;
  });

export const getProjectsByUserIdAction = () =>
  parseAction(async () => {
    const result = await getBelongProjectsParser();
    const projectList: Array<Project> = result.map((project) => project.project);
    storeDispatch(setProjects(projectList));
    return result;
  });

export const sendAuthCodeEmailAction = (userId: number, email: string) =>
  parseAction(async () => {
    return await sendAuthCodeEmailParser(userId, email, AuthCodeType.SIGN_UP);
  });

export const verifyAuthCodeAction = (authCode: string) =>
  parseAction(async () => {
    return await verifyAuthCodeParser(authCode);
  });

export const logoutAction = () =>
  parseAction(async () => {
    // const result = await logoutParser();
    // userJWT.removeToken();
    // projectJWT.removeToken();
    // storeDispatch(removeUser());
    // storeDispatch(removeAllProjects());
    // return result;
  });

export const checkPasswordAction = (newPassword: string) =>
  parseAction(async () => {
    return await checkPasswordParser(newPassword);
  });

export const checkPasswordRecommendedDateAction = () =>
  parseAction(async () => {
    return await checkPasswordRecommendedDateParser();
  });

export const createTotpKeyAction = (userId: number) =>
  parseAction(async () => {
    return await createTotpKeyParser(userId);
  });

export const saveTotpKeyAction = (userId: number, totpCode: string, totpKey: string) =>
  parseAction(async () => {
    const result = await setTotpKeyParser(userId, totpCode, totpKey);
    storeDispatch(setUser(result));
    return result;
  });

export const deleteTotpKeyAction = (userId: number) =>
  parseAction(async () => {
    const result = await deleteTotpKeyParser(userId);
    storeDispatch(setUser(result));
    return result;
  });
