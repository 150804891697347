import { useState } from "react";
import css from "./ManagePaymentGateway.scss";
import { useNavigate, useParams } from "react-router-dom";
import PaymentGatewayDetail from "../component/PaymentGatewayDetail";
import PaymentGatewayVirtualAccountDeposit from "../component/PaymentGatewayVirtualAccountDeposit";
import PaymentGatewayWithdrawal from "../component/PaymentGatewayWithdrawal";
import PageLoading from "../../../../components/_atom/loading/PageLoading";
import { errorAlert } from "../../../../../utils/Utils";
import { getPaymentMethodDetailAction } from "../../../../../action/paymentGatewayAction";
import PageArticle from "../../../../components/_template/page/PageArticle";
import PageTitle from "../../../../components/_molecules/page/PageTitle";
import useLoading from "hooks/useLoading";
import {
  MANAGE_PAYMENT_GATEWAY_TAB,
  PAYMENT_GATEWAY_STATUS,
  PAYMENT_GATEWAY_STATUS_DEPLOYED,
  PAYMENT_GATEWAY_TYPE_SMART_CONTRACT,
} from "../constants/paymentGateway.data";
import PaymentGatewaySmartContractDeposit from "../component/PaymentGatewaySmartContractDeposit";
import { bindStyle } from "view/styles/classNames";
import {
  isDisplayTransactionStatus,
  parseServiceStatus,
} from "../../../../../utils/status.utils";
import TransactionStatus from "../../../../components/_atom/transactionStatus/TransactionStatus";

function ManagePaymentGateway(props) {
  const navigate = useNavigate();
  const { id: paymentGatewayId } = useParams();

  const [selectedTab, setSelectedTab] = useState(MANAGE_PAYMENT_GATEWAY_TAB[0]);
  const [paymentGatewayStatus, setPaymentGatewayStatus] = useState(null);
  const [paymentMethodDetail, setPaymentMethodDetail] = useState(null);

  const getPaymentGatewayDetail_ = async () => {
    const { error, result } = await getPaymentMethodDetailAction(
      paymentGatewayId
    );
    if (result) {
      const { mainStatus } = result;
      //TODO: Payment gateway에서는 subStatus를 관리하지 않아서, 인터페이스를 맞추기위해 0을 인자값으로 전달합니다.
      const targetStatus = parseServiceStatus(
        PAYMENT_GATEWAY_STATUS,
        mainStatus,
        0
      );
      setPaymentGatewayStatus(targetStatus);
      setPaymentMethodDetail(result);
    }
    if (error) {
      errorAlert(error.data.message);
      if (error.data.status === "401") {
        navigate("/developer/payment-gateway/manage");
      }
    }
  };

  const { loading } = useLoading({
    dependency: [paymentGatewayId],
    synchronousFunction: async () => {
      await getPaymentGatewayDetail_();
    },
  });

  if (loading) {
    return <PageLoading />;
  }

  return (
    <PageArticle>
      <PageTitle size={810} title={"Payment Gateway Detail"} />
      <div className="page-layout-810">
        {isDisplayTransactionStatus(
          PAYMENT_GATEWAY_STATUS[paymentGatewayStatus]
        ) && (
          <TransactionStatus
            {...(paymentMethodDetail?.deployTransactionHash && {
              transactionHash: paymentMethodDetail.deployTransactionHash,
            })}
            statusDetail={
              PAYMENT_GATEWAY_STATUS[paymentGatewayStatus].statusDetail
            }
            microChainId={paymentMethodDetail?.microChainId}
          />
        )}
        {paymentMethodDetail.mainStatus === PAYMENT_GATEWAY_STATUS_DEPLOYED && (
          <>
            <BundleManager
              tabs={MANAGE_PAYMENT_GATEWAY_TAB}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
            />
            <div style={{ height: 20 }} />
          </>
        )}
        {selectedTab.id === MANAGE_PAYMENT_GATEWAY_TAB[0].id && (
          <PaymentGatewayDetail
            paymentMethodDetail={paymentMethodDetail}
            setPaymentMethodDetail={setPaymentMethodDetail}
            getPaymentGatewayDetail={getPaymentGatewayDetail_}
          />
        )}
        {selectedTab.id === MANAGE_PAYMENT_GATEWAY_TAB[1].id &&
          paymentMethodDetail.paymentTypeId !==
            PAYMENT_GATEWAY_TYPE_SMART_CONTRACT && (
            <PaymentGatewayVirtualAccountDeposit />
          )}
        {selectedTab.id === MANAGE_PAYMENT_GATEWAY_TAB[1].id &&
          paymentMethodDetail.paymentTypeId ===
            PAYMENT_GATEWAY_TYPE_SMART_CONTRACT && (
            <PaymentGatewaySmartContractDeposit
              paymentMethodDetail={paymentMethodDetail}
            />
          )}
        {selectedTab.id === MANAGE_PAYMENT_GATEWAY_TAB[2].id && (
          <PaymentGatewayWithdrawal paymentMethodDetail={paymentMethodDetail} />
        )}
      </div>
    </PageArticle>
  );
}

export default ManagePaymentGateway;

// ------------------------------ COMPONENTS -------------------------------------

const BundleManager = (props) => {
  const {
    tabs,
    selectedTab,
    setSelectedTab,
    label = "label",
    id = "id",
  } = props;

  const cn = bindStyle(css);

  return (
    <div className="bundle-manager">
      {tabs.map((tab, index) => (
        <div
          className={cn("bundle-manager-item", {
            selected: tab[id] === selectedTab[id],
          })}
          key={`bundle-manager-${tab[label]}`}
          onClick={() => setSelectedTab(tab)}
        >
          {tab[label]}
        </div>
      ))}
    </div>
  );
};
