import React, { useMemo } from "react";
import BasicButton from "view/components/_atom/button/BasicButton";
import { ButtonSize, ButtonTheme } from "view/components/_atom/button/StyledButton";
import ButtonContainer from "view/components/_atom/container/ButtonContainer";
import NetworkBesuPreAllocationField from "../molecule/NetworkBesuPreAllocationField";
import useValidateCondition from "hooks/useValidateCondition";
import {
  VALIDATION__NETWORK_allocation_address_duplicate,
  VALIDATION__NETWORK_foundation_address,
  VALIDATION__NETWORK_foundation_distribution,
  VALIDATION__NETWORK_foundation_name,
  VALIDATION__NETWORK_foundation_total_alloc_percent,
} from "../../validation/networkValidation";
import { distinguishValidation } from "modules/validation/validationCondition";
import useIsAble from "hooks/useIsAble";
import { plusCalculate } from "../../util/networkUtils";

type NetworkBesuPreAllocationSettingProps = {
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  networkData: any;
  setNetworkData: React.Dispatch<React.SetStateAction<any>>;
  createButtonHandler?: Function;
  updateButtonHandler?: Function;
};

const NetworkBesuPreAllocationSetting = (props: NetworkBesuPreAllocationSettingProps) => {
  const { setCurrentStep, networkData, setNetworkData, createButtonHandler, updateButtonHandler } = props;

  const backButtonHandler = () => {
    setCurrentStep((prevState) => prevState - 1);
  };

  const preAllocPercentList = networkData.preAlloc.map((el) => el.distributionPercent || "0").concat("0");
  const totalAllocPercent = preAllocPercentList.reduce((acc, cur) => plusCalculate(acc, cur));

  const nameValidation = useValidateCondition([networkData.preAlloc], VALIDATION__NETWORK_foundation_name(networkData.preAlloc));
  const addressValidation = useValidateCondition([networkData.preAlloc], VALIDATION__NETWORK_foundation_address(networkData.preAlloc));
  const distributionValidation = useValidateCondition([networkData.preAlloc], VALIDATION__NETWORK_foundation_distribution(networkData.preAlloc));
  const totalAllocValidation = useValidateCondition([totalAllocPercent], VALIDATION__NETWORK_foundation_total_alloc_percent(Number(totalAllocPercent)));
  const allocationAddressValidation = useValidateCondition(
    [networkData.nodeAlloc, networkData.preAlloc],
    VALIDATION__NETWORK_allocation_address_duplicate(networkData.nodeAlloc, networkData.preAlloc)
  );

  const nodeSettingValidationResult = useMemo(() => {
    return distinguishValidation([nameValidation, addressValidation, distributionValidation, allocationAddressValidation]);
  }, [nameValidation, addressValidation, distributionValidation, allocationAddressValidation]);

  const isAble: boolean = useIsAble([totalAllocValidation.isValid]);

  return (
    <div className="network-pre-allocation-setting">
      <NetworkBesuPreAllocationField networkData={networkData} setNetworkData={setNetworkData} initialSupply={networkData.microChainSetting.initialSupply} />
      <div className="network-button-container">
        <ButtonContainer>
          <BasicButton size={ButtonSize.LARGE} theme={ButtonTheme.SECONDARY} onClick={() => backButtonHandler()}>
            Back
          </BasicButton>
          {Boolean(createButtonHandler) && (
            <BasicButton
              size={ButtonSize.LARGE}
              disabled={!isAble}
              onClick={() => {
                if (createButtonHandler) {
                  createButtonHandler(nodeSettingValidationResult);
                }
              }}
            >
              Create
            </BasicButton>
          )}
          {Boolean(updateButtonHandler) && (
            <BasicButton
              size={ButtonSize.LARGE}
              disabled={!isAble}
              onClick={() => {
                if (updateButtonHandler) {
                  updateButtonHandler(nodeSettingValidationResult);
                }
              }}
            >
              Update
            </BasicButton>
          )}
        </ButtonContainer>
      </div>
    </div>
  );
};

export default NetworkBesuPreAllocationSetting;
