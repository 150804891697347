import "./ChartLoading.scss";

function ChartLoading() {
  return (
    <div className="chart-loading">
      {/* <div className="cude">
       <div className="side"></div>
       <div className="side"></div>
       <div className="side"></div>
       <div className="side"></div>
       <div className="side"></div>
       <div className="side"></div>
      </div> */}
      <div className="box-loading">
        <div className="box-loading-item"></div>
        <div className="box-loading-item"></div>
        <div className="box-loading-item"></div>
        <div className="box-loading-item"></div>
        <div className="box-loading-item"></div>
      </div>
    </div>
  );
}

export default ChartLoading;
