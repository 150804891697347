import {
  getContractByteCodeFromserver,
  getContractDetailFromServer,
  getContractListFromServer,
} from "logic/services/Contract/server";
import {
  createTokenKitFromServer,
  getDefaultImageForTokenKitFromServer,
  getMyPublicTokenKitsFromServer,
  getMyTokenKitsFromServer,
  getPublicTokenKitsFromServer,
  getSupportedChainListFromServer,
  getSupportedModuleContractListFromServer,
  getTokenAllowanceHistoryFromServer,
  getTokenApprovalsHistoryFromServer,
  getTokenBalanceOfFromServer,
  getTokenFunctionListFromServer,
  getTokenFunctionListWithVersionIdFromServer,
  getTokenKitDetailFromServer,
  getTokenKitTypeDetailFromServer,
  getTokenKitTypeListFromServer,
  getTokenKitTypeVersionListFromServer,
  getTokenKitVersionFromServer,
  getTokenTotalSupplyFromServer,
  getTokenTransferHistoryFromServer,
  postImageUploadForTokenKitFromServer,
  postKitIdDeployResultFromServer,
  postKitIdForMakeContractFromServer,
  postMyPublicTokenKitsFromServer,
} from "logic/services/Token-Kits/server";
import { WEB3 } from "modules/web3/Web3";
import { encodeABI, encodeABIWithByteCode } from "modules/web3/Web3Utils";
import {
  getAbiCodeByContractAddressParser,
  getAbiCodeByContractIdParser,
} from "parser/requestParser";
import { removeComma } from "utils/Utils";
import Web3 from "web3";
import {
  actionController,
  convertFromDecimal,
  convertToDecimal,
} from "../utils";
import { uploadTokenImageToS3Parser } from "parser/currencyParser";

export const tokenKitsController = {
  // const { tokenKits } = services;
  //유저가 생성한 토큰키트 조회
  getUserTokenKits: (option: {
    lastId?: number;
    offset?: number;
    limit?: number;
    order?: "ASC" | "DESC";
  }) => {
    return actionController(async () => {
      try {
        const tokenKits = await getMyTokenKitsFromServer({
          query: {
            ...(option.lastId && { lastId: option.lastId }),
            ...(option.offset && { offset: option.offset }),
            ...(option.limit && { limit: option.limit }),
            ...(option.order && { order: option.order }),
          },
        });
        const formattedTokenKits = {
          ...tokenKits,
          rows: tokenKits.rows.map((kit: any) => ({
            ...kit,
            is_public: kit.is_public === 1 ? true : false,
            version: {
              ...kit.version,
              is_public: kit.version.is_public === 1 ? true : false,
              is_latest: kit.version.is_latest === 1 ? true : false,
            },
          })),
        };
        return formattedTokenKits;
      } catch (e) {
        throw new Error("tokenKit_get_fail");
      }
    });
  },

  //추가된 Public Token Kit 목록을 조회
  getUserPublicTokenKits: (option: {
    lastId?: number;
    offset?: number;
    limit?: number;
    order?: "ASC" | "DESC";
  }) => {
    return actionController(async () => {
      try {
        const publicTokenKit = await getMyPublicTokenKitsFromServer({
          query: {
            ...(option.lastId && { lastId: option.lastId }),
            ...(option.offset && { offset: option.offset }),
            ...(option.limit && { limit: option.limit }),
            ...(option.order && { order: option.order }),
          },
        });
        const formattedTokenKits = {
          ...publicTokenKit,
          rows: publicTokenKit.rows.map((kit: any) => ({
            ...kit,
            is_public: kit.is_public === 1 ? true : false,
            version: {
              ...kit.version,
              is_public: kit.version.is_public === 1 ? true : false,
              is_latest: kit.version.is_latest === 1 ? true : false,
            },
          })),
        };
        return formattedTokenKits;
      } catch (e) {
        throw new Error("user_public_tokenKit_get_fail");
      }
    });
  },

  getPublicTokenKits: (option: {
    lastId?: number;
    offset?: number;
    limit?: number;
    order?: "ASC" | "DESC";
  }) => {
    return actionController(async () => {
      try {
        const publicTokenKit = await getPublicTokenKitsFromServer({
          query: {
            ...(option.lastId && { lastId: option.lastId }),
            ...(option.offset && { offset: option.offset }),
            ...(option.limit && { limit: option.limit }),
            ...(option.order && { order: option.order }),
          },
        });
        const formattedTokenKits = {
          ...publicTokenKit,
          rows: publicTokenKit.rows.map((kit: any) => ({
            ...kit,
            is_public: kit.is_public === 1 ? true : false,
            version: {
              ...kit.version,
              is_public: kit.version.is_public === 1 ? true : false,
              is_latest: kit.version.is_latest === 1 ? true : false,
            },
          })),
        };
        return formattedTokenKits;
      } catch (e) {
        throw new Error("public_tokenKit_get_fail");
      }
    });
  },

  //추가할 토큰 키트 목록
  postPublicTokenKits: (kitIds: number[]) => {
    return actionController(async () => {
      try {
        const publicTokenKit = await postMyPublicTokenKitsFromServer({
          data: {
            kitIds,
          },
          query: {},
        });
        return publicTokenKit;
      } catch (e) {
        throw new Error("public_tokenKit_post_fail");
      }
    });
  },

  getTokenKitDetail: (kitId: number) => {
    return actionController(async () => {
      try {
        const tokenKit = await getTokenKitDetailFromServer({
          params: {
            kitId: kitId,
          },
          query: {},
        });
        const formattedTokenKit = {
          ...tokenKit,
          is_public: tokenKit.is_public === 1 ? true : false,
          version: {
            ...tokenKit.version,
            is_public: tokenKit.version.is_public === 1 ? true : false,
            is_latest: tokenKit.version.is_latest === 1 ? true : false,
          },
        };
        return formattedTokenKit;
      } catch (e) {
        throw new Error("token_kit_detail_get_fail");
      }
    });
  },

  getTokenKitVersion: (
    kitTypeId: number,
    option: {
      lastId?: number;
      offset?: number;
      limit?: number;
      order?: "ASC" | "DESC";
    }
  ) => {
    return actionController(async () => {
      try {
        const tokenKitVersion = await getTokenKitVersionFromServer({
          query: {
            ...(option.lastId && { lastId: option.lastId }),
            ...(option.offset && { offset: option.offset }),
            ...(option.limit && { limit: option.limit }),
            ...(option.order && { order: option.order }),
            kitTypeId: kitTypeId,
          },
        });
        return tokenKitVersion;
      } catch (e) {
        throw new Error("token_kit_version_get_fail");
      }
    });
  },

  getTokenTotalSupply: (kitId: number) => {
    return actionController(async () => {
      try {
        const totalSupply = await getTokenTotalSupplyFromServer({
          params: { kitId },
          query: {},
        });
        const decimal = 18;
        const data = {
          totalSupply: convertToDecimal(totalSupply.total_supply, decimal),
        };
        return data;
      } catch (e) {
        throw new Error("token_totalSupply_get_fail");
      }
    });
  },

  getTokenFunctionList: (kitId: number) => {
    return actionController(async () => {
      try {
        const FunctionList = await getTokenFunctionListFromServer({
          params: { kitId },
          query: {},
        });
        return FunctionList;
      } catch (e) {
        throw new Error("token_Function_List_get_fail");
      }
    });
  },
  //수정
  getTokenFunctionListWithKitTypeId: (kitTypeId: number) => {
    return actionController(async () => {
      try {
        const tokenKitVersion = await getTokenKitVersionFromServer({
          query: { kitTypeId },
        });
        if (!tokenKitVersion) {
          throw new Error("tokenKit_version_get_fail");
        }
        const latestTokenKitVersion = tokenKitVersion.filter(
          (item) => item.is_latest === 1
        );
        const FunctionList = await getTokenFunctionListWithVersionIdFromServer({
          params: { versionId: latestTokenKitVersion[0].version_id },
          query: {},
        });
        return FunctionList;
      } catch (e) {
        throw new Error("token_Function_List_get_fail");
      }
    });
  },

  // getTokenFunctionMethodList 함수 (feature_id만 받아서 해당하는 functionName과 method 목록을 반환)
  getTokenFunctionMethodList: (feature_id: number) => {
    return actionController(async () => {
      const methods = {
        1: {
          feature_id: 1,
          function_name: "transfer",
          description: "function_name에 대한 description",
          methods: [101, 102, 103, 104, 105], // transfer 관련 method_id들
        },
        2: {
          feature_id: 2,
          function_name: "mint",
          description: "function_name에 대한 description",
          methods: [201, 202, 203, 204], // mint 관련 method_id들
        },
        3: {
          feature_id: 3,
          function_name: "burn",
          description: "function_name에 대한 description",
          methods: [301, 302, 303], // burn 관련 method_id들
        },
        4: {
          feature_id: 4,
          function_name: "allowance",
          description: "function_name에 대한 description",
          methods: [401, 402, 403], // allowance 관련 method_id들
        },
      };

      const methodDetails = {
        101: {
          method_id: 101,
          method: "balanceOf",
          method_description: "View the amount of tokens you own",
          input_type: "single",
          need_wallet: false,
          input: [{ name: "account", type: "address", data_type: "string" }],
        },
        102: {
          method_id: 102,
          method: "transfer",
          method_description: "Transfer tokens to another account",
          input_type: "single",
          need_wallet: true,
          input: [
            { name: "to", type: "address", data_type: "string" },
            { name: "amount", type: "amount", data_type: "number" },
          ],
        },
        103: {
          method_id: 103,
          method: "transferFrom",
          method_description:
            "Transfer tokens to another account instead of the token owner",
          input_type: "single",
          need_wallet: true,
          input: [
            { name: "from", type: "address", data_type: "string" },
            { name: "to", type: "address", data_type: "string" },
            { name: "amount", type: "amount", data_type: "number" },
          ],
        },
        104: {
          method_id: 104,
          method: "multiTransfer",
          method_description: "Transfer tokens to multiple accounts",
          input_type: "multiple",
          need_wallet: true,
          input: [
            { name: "to", type: "address", data_type: "string" },
            { name: "amount", type: "amount", data_type: "number" },
          ],
        },
        105: {
          method_id: 105,
          method: "transfer-history",
          method_description: "View the history of token transfer and receipt",
          input_type: "single",
          need_wallet: false,
          input: [{ name: "address", type: "address", data_type: "string" }],
        },
        201: {
          method_id: 201,
          method: "balanceOf",
          method_description: "View the amount of tokens you own",
          input_type: "single",
          need_wallet: false,
          input: [{ name: "account", type: "address", data_type: "string" }],
        },
        202: {
          method_id: 202,
          method: "totalSupply",
          method_description: "View number of current total issued tokens",
          input_type: "single",
          need_wallet: false,
          input: [],
        },
        203: {
          method_id: 203,
          method: "mint",
          method_description: "Issue tokens to another account",
          input_type: "single",
          need_wallet: true,
          input: [
            { name: "to", type: "address", data_type: "string" },
            { name: "amount", type: "amount", data_type: "number" },
          ],
        },
        204: {
          method_id: 204,
          method: "multiMint",
          method_description: "Issue tokens to multiple accounts",
          input_type: "multiple",
          need_wallet: true,
          input: [
            { name: "to", type: "address", data_type: "string" },
            { name: "amount", type: "amount", data_type: "number" },
          ],
        },
        301: {
          method_id: 301,
          method: "balanceOf",
          method_description: "View the amount of tokens you own",
          input_type: "single",
          need_wallet: false,
          input: [{ name: "account", type: "address", data_type: "string" }],
        },
        302: {
          method_id: 302,
          method: "burn",
          method_description: "Burn tokens you own",
          input_type: "single",
          need_wallet: true,
          input: [{ name: "amount", type: "amount", data_type: "number" }],
        },
        303: {
          method_id: 303,
          method: "burnFrom",
          method_description:
            "Burn tokens instead of the token owner(Number of tokens can be burnt depends on the allowance value)",
          input_type: "single",
          need_wallet: true,
          input: [
            { name: "from", type: "address", data_type: "string" },
            { name: "amount", type: "amount", data_type: "number" },
          ],
        },
        401: {
          method_id: 401,
          method: "approve",
          method_description:
            "Set the number of tokens that can be transferred instead of the token owner",
          input_type: "single",
          need_wallet: true,
          input: [
            { name: "spender", type: "address", data_type: "string" },
            { name: "amount", type: "amount", data_type: "number" },
          ],
        },
        402: {
          method_id: 402,
          method: "allowance",
          method_description:
            "View about the number of tokens that can be transferred instead of the token owner",
          input_type: "single",
          need_wallet: false,
          input: [
            { name: "owner", type: "address", data_type: "string" },
            { name: "spender", type: "address", data_type: "string" },
          ],
        },
        403: {
          method_id: 403,
          method: "approval-history",
          method_description:
            "View history of the permissions granted to other accounts by the account address and the permissions granted to the account address.",
          input_type: "single",
          need_wallet: false,
          input: [{ name: "address", type: "address", data_type: "string" }],
        },
      };

      try {
        // feature_id로 해당하는 기능 정보를 methods 객체에서 가져옵니다.
        const featureInfo = methods[feature_id];

        if (!featureInfo) {
          throw new Error("Invalid feature_id");
        }

        // feature_id에 해당하는 functionName과 method_id 목록을 가져옴
        const { function_name, description, methods: methodIds } = featureInfo;

        // method_id에 해당하는 method 세부 정보들 가져오기
        const methodDetailsList = methodIds.map((methodId: number) => {
          const methodDetail = methodDetails[methodId];

          // methodDetails에서 methodId에 해당하는 정보를 가져오지 못했을 경우 예외 처리
          if (!methodDetail) {
            throw new Error(`Method_details_for_method_id_not_found.`);
          }
          return {
            ...methodDetail,
          };
        });

        return {
          ...featureInfo,
          methods: methodDetailsList,
        };
      } catch (error) {
        throw new Error("Failed_to_get_token_function_method_list");
      }
    });
  },

  getTokenBalanceOf: (kitId: number, address: string) => {
    return actionController(async () => {
      try {
        const balance = await getTokenBalanceOfFromServer({
          params: { kitId },
          query: { address },
        });
        const decimal = 18;
        const data = {
          balance: convertToDecimal(balance.balance, decimal),
        };
        return data;
      } catch (e) {
        throw new Error("token_balance_get_fail");
      }
    });
  },

  getTokenTransferHistory: (
    kitId: number,
    address: string,
    option: {
      lastId?: number;
      offset?: number;
      limit?: number;
      order?: "ASC" | "DESC";
    }
  ) => {
    return actionController(async () => {
      try {
        const transferHistory = await getTokenTransferHistoryFromServer({
          params: { kitId },
          query: {
            ...(option.lastId && { lastId: option.lastId }),
            ...(option.offset && { offset: option.offset }),
            ...(option.limit && { limit: option.limit }),
            ...(option.order && { order: option.order }),
            address,
          },
        });
        const decimal = 18;
        const transferHistoryData = transferHistory.map((item) => ({
          ...item,
          amount: convertToDecimal(item.amount, decimal), // 각 항목의 amount 값을 변환
        }));
        return transferHistoryData;
      } catch (e) {
        throw new Error("token_transfer_history_fail");
      }
    });
  },

  getTokenApprovalsHistory: (
    kitId: number,
    address: string,
    option: {
      lastId?: number;
      offset?: number;
      limit?: number;
      order?: "ASC" | "DESC";
    }
  ) => {
    return actionController(async () => {
      try {
        const Approval = await getTokenApprovalsHistoryFromServer({
          params: { kitId: kitId },
          query: {
            ...(option.lastId && { lastId: option.lastId }),
            ...(option.offset && { offset: option.offset }),
            ...(option.limit && { limit: option.limit }),
            ...(option.order && { order: option.order }),
            address: address,
          },
        });
        const decimal = 18;
        const ApprovalHistoryData = Approval.map((item) => ({
          ...item,
          amount: convertToDecimal(item.allowance, decimal), // 각 항목의 allowance 값을 변환
        }));
        return ApprovalHistoryData;
      } catch (e) {
        throw new Error("token_approval_history_fail");
      }
    });
  },

  getTokenAllowanceHistory: (kitId: number, owner: string, spender: string) => {
    return actionController(async () => {
      try {
        const allowance = await getTokenAllowanceHistoryFromServer({
          params: { kitId: kitId },
          query: {
            owner,
            spender,
          },
        });
        const decimal = 18;
        const data = {
          allowance: convertToDecimal(allowance.allowance, decimal),
        };
        return data;
      } catch (e) {
        throw new Error("token_allowance_history_fail");
      }
    });
  },

  getSupportedChainList: (versionId: number) => {
    return actionController(async () => {
      try {
        const supportedChain = await getSupportedChainListFromServer({
          params: { versionId },
          query: {},
        });
        const supportedChainList = supportedChain.map((data) => ({
          versionId: data.version_id,
          chainId: data.chain_id,
        }));
        return supportedChainList;
      } catch (e) {
        throw new Error("supported_ChainList_get_fail");
      }
    });
  },

  getSupportedChainListWithKitTypeId: (kitTypeId: number) => {
    return actionController(async () => {
      try {
        const tokenKitVersion = await getTokenKitVersionFromServer({
          query: { kitTypeId },
        });
        if (!tokenKitVersion) {
          throw new Error("tokenKit_version_get_fail");
        }
        const latestTokenKitVersion = tokenKitVersion.filter(
          (item) => item.is_latest === 1
        );
        const supportedChain = await getSupportedChainListFromServer({
          params: { versionId: latestTokenKitVersion[0].version_id },
          query: {},
        });
        return supportedChain;
      } catch (e) {
        throw new Error("supported_ChainList_get_fail");
      }
    });
  },

  getSupportedModuleContractList: (kitId: number) => {
    return actionController(async () => {
      try {
        const supportedContract =
          await getSupportedModuleContractListFromServer({
            params: { kitId },
            query: {},
          });
        const supportedChainList = supportedContract.map((data) => ({
          contract_id: data.contract_id,
          contract_address: data.contract_address,
          has_constructor: data.has_constructor === 1 ? true : false,
        }));
        return supportedChainList;
      } catch (e) {
        throw new Error("moduleContract_get_fail");
      }
    });
  },

  /*******************************/
  /********* mint 기능 ************/
  /*******************************/

  tokenKitMintWithMetamask: async (
    amount: number,
    toAddress: string,
    originMicroChainId: number,
    contractAddress: string
  ) => {
    return await actionController(async () => {
      const web3 = new Web3(window.ethereum);
      try {
        // MetaMask에서 사용할 계정 가져오기
        const accounts = await web3.eth.getAccounts();
        const account = accounts[0];
        const gasPrice = await web3.eth.getGasPrice();

        const abiCode = await getAbiCodeByContractAddressParser(
          originMicroChainId,
          contractAddress
        );
        const decimal = 18;
        const dataByte = "0x0";
        const parameters = [
          toAddress,
          WEB3.toWei(removeComma(amount)),
          dataByte,
        ];
        const data = encodeABI(abiCode, "mint", parameters);
        console.log(data);

        // 트랜잭션 파라미터 설정
        if (data && parameters && abiCode) {
          const txParams = {
            from: account, // 현재 MetaMask 연결된 계정
            to: contractAddress, // 민트할 컨트랙트 주소
            data: data, // 인코딩된 mint 함수 데이터
            value: "0x0", // 트랜잭션 값 (없으면 0)
            // gasPrice: gasPrice, // gasPrice 설정
          };
          // 트랜잭션을 MetaMask를 통해 전송
          try {
            const txHash = await window.ethereum.request({
              method: "eth_sendTransaction",
              params: [txParams],
            });
            return txHash;
          } catch (e) {
            throw new Error("tx_send_fail");
          }
        } else {
          throw new Error("make_parameter_fail");
        }
      } catch (error) {
        throw new Error("tx_make_fail");
      }
    });
  },

  tokenKitMultiMintWithMetamask: async (
    mintInput: { amount: number; to: string }[],
    originMicroChainId: number,
    contractAddress: string
  ) => {
    console.log(mintInput);
    return await actionController(async () => {
      const web3 = new Web3(window.ethereum);
      try {
        // MetaMask에서 사용할 계정 가져오기
        const accounts = await web3.eth.getAccounts();
        const account = accounts[0];
        const gasPrice = await web3.eth.getGasPrice();

        const abiCode = await getAbiCodeByContractAddressParser(
          originMicroChainId,
          contractAddress
        );
        const parameters = mintInput.map((transfer) => {
          const dataByte = "0x0";
          const mintParameters = [
            transfer.to,
            WEB3.toWei(removeComma(transfer.amount)),
            dataByte,
          ];

          return encodeABI(abiCode, "mint", mintParameters);
        });

        const data = encodeABI(abiCode, "multicall", [parameters]);

        // 트랜잭션 파라미터 설정
        if (data && parameters && abiCode) {
          const txParams = {
            from: account, // 현재 MetaMask 연결된 계정
            to: contractAddress, // 민트할 컨트랙트 주소
            data: data, // 인코딩된 mint 함수 데이터
            value: "0x0", // 트랜잭션 값 (없으면 0)
            // gasPrice: gasPrice, // gasPrice 설정
          };
          // 트랜잭션을 MetaMask를 통해 전송
          try {
            const txHash = await window.ethereum.request({
              method: "eth_sendTransaction",
              params: [txParams],
            });
            return txHash;
          } catch (e) {
            throw new Error("tx_send_fail");
          }
        } else {
          throw new Error("make_parameter_fail");
        }
      } catch (error) {
        throw new Error("tx_make_fail");
      }
    });
  },

  /*******************************/
  /********* burn 기능 ************/
  /*******************************/

  tokenKitBurnWithMetamask: async (
    amount: number,
    originMicroChainId: number,
    contractAddress: string
  ) => {
    return await actionController(async () => {
      const web3 = new Web3(window.ethereum);
      try {
        // MetaMask에서 사용할 계정 가져오기
        const accounts = await web3.eth.getAccounts();
        const account = accounts[0];
        const gasPrice = await web3.eth.getGasPrice();

        const abiCode = await getAbiCodeByContractAddressParser(
          originMicroChainId,
          contractAddress
        );
        const decimal = 18;
        const dataByte = "0x0";
        const parameters = [convertFromDecimal(amount, decimal), dataByte];
        const data = encodeABI(abiCode, "burn", parameters);

        // 트랜잭션 파라미터 설정
        if (data && parameters && abiCode) {
          const txParams = {
            from: account, // 현재 MetaMask 연결된 계정
            to: contractAddress, // 민트할 컨트랙트 주소
            data: data, // 인코딩된 mint 함수 데이터
            value: "0x0", // 트랜잭션 값 (없으면 0)
            // gasPrice: gasPrice, // gasPrice 설정
          };
          // 트랜잭션을 MetaMask를 통해 전송
          try {
            const txHash = await window.ethereum.request({
              method: "eth_sendTransaction",
              params: [txParams],
            });
            return txHash;
          } catch (e) {
            throw new Error("tx_send_fail");
          }
        } else {
          throw new Error("make_parameter_fail");
        }
      } catch (error) {
        throw new Error("tx_make_fail");
      }
    });
  },

  tokenKitBurnFromWithMetamask: async (
    amount: number,
    fromAddress: string,
    originMicroChainId: number,
    contractAddress: string
  ) => {
    return await actionController(async () => {
      const web3 = new Web3(window.ethereum);
      try {
        // MetaMask에서 사용할 계정 가져오기
        const accounts = await web3.eth.getAccounts();
        const account = accounts[0];
        const gasPrice = await web3.eth.getGasPrice();

        const abiCode = await getAbiCodeByContractAddressParser(
          originMicroChainId,
          contractAddress
        );
        const decimal = 18;
        const dataByte = "0x0";
        const parameters = [
          fromAddress,
          convertFromDecimal(amount, decimal),
          dataByte,
        ];
        const data = encodeABI(abiCode, "burnFrom", parameters);

        // 트랜잭션 파라미터 설정
        if (data && parameters && abiCode) {
          const txParams = {
            from: account, // 현재 MetaMask 연결된 계정
            to: contractAddress, // 민트할 컨트랙트 주소
            data: data, // 인코딩된 mint 함수 데이터
            value: "0x0", // 트랜잭션 값 (없으면 0)
            // gasPrice: gasPrice, // gasPrice 설정
          };
          // 트랜잭션을 MetaMask를 통해 전송
          try {
            const txHash = await window.ethereum.request({
              method: "eth_sendTransaction",
              params: [txParams],
            });
            return txHash;
          } catch (e) {
            throw new Error("tx_send_fail");
          }
        } else {
          throw new Error("make_parameter_fail");
        }
      } catch (error) {
        throw new Error("tx_make_fail");
      }
    });
  },

  /***********************************/
  /********* transfer 기능 ************/
  /***********************************/

  tokenKitTransferWithMetamask: async (
    amount: number,
    originMicroChainId: number,
    contractAddress: string,
    toAddress: string
  ) => {
    return await actionController(async () => {
      const web3 = new Web3(window.ethereum);
      try {
        // MetaMask에서 사용할 계정 가져오기
        const accounts = await web3.eth.getAccounts();
        const account = accounts[0];
        const gasPrice = await web3.eth.getGasPrice();

        const abiCode = await getAbiCodeByContractAddressParser(
          originMicroChainId,
          contractAddress
        );
        const decimal = 18;
        const parameters = [toAddress, convertFromDecimal(amount, decimal)];
        const data = encodeABI(abiCode, "transfer", parameters);

        // 트랜잭션 파라미터 설정
        if (data && parameters && abiCode) {
          const txParams = {
            from: account, // 현재 MetaMask 연결된 계정
            to: contractAddress, // 민트할 컨트랙트 주소
            data: data, // 인코딩된 mint 함수 데이터
            value: "0x0", // 트랜잭션 값 (없으면 0)
            // gasPrice: gasPrice, // gasPrice 설정
          };
          // 트랜잭션을 MetaMask를 통해 전송
          try {
            const txHash = await window.ethereum.request({
              method: "eth_sendTransaction",
              params: [txParams],
            });
            return txHash;
          } catch (e) {
            throw new Error("tx_send_fail");
          }
        } else {
          throw new Error("make_parameter_fail");
        }
      } catch (error) {
        throw new Error("tx_make_fail");
      }
    });
  },

  tokenKitTransferFromWithMetamask: async (
    amount: number,
    originMicroChainId: number,
    contractAddress: string,
    toAddress: string,
    fromAddress: string //기존 소유주 계정
  ) => {
    return await actionController(async () => {
      const web3 = new Web3(window.ethereum);
      try {
        // MetaMask에서 사용할 계정 가져오기
        const accounts = await web3.eth.getAccounts();
        const account = accounts[0];
        const gasPrice = await web3.eth.getGasPrice();

        const abiCode = await getAbiCodeByContractAddressParser(
          originMicroChainId,
          contractAddress
        );
        const decimal = 18;
        const parameters = [
          fromAddress,
          toAddress,
          convertFromDecimal(amount, decimal),
        ];
        const data = encodeABI(abiCode, "transferFrom", parameters);

        // 트랜잭션 파라미터 설정
        if (data && parameters && abiCode) {
          const txParams = {
            from: account, // 현재 MetaMask 연결된 계정
            to: contractAddress, // 민트할 컨트랙트 주소
            data: data, // 인코딩된 mint 함수 데이터
            value: "0x0", // 트랜잭션 값 (없으면 0)
            // gasPrice: gasPrice, // gasPrice 설정
          };
          // 트랜잭션을 MetaMask를 통해 전송
          try {
            const txHash = await window.ethereum.request({
              method: "eth_sendTransaction",
              params: [txParams],
            });
            return txHash;
          } catch (e) {
            throw new Error("tx_send_fail");
          }
        } else {
          throw new Error("make_parameter_fail");
        }
      } catch (error) {
        throw new Error("tx_make_fail");
      }
    });
  },

  tokenKitMultiTransferWithMetamask: async (
    transferInput: { amount: number; to: string }[],
    originMicroChainId: number,
    contractAddress: string
  ) => {
    return await actionController(async () => {
      const web3 = new Web3(window.ethereum);
      try {
        // MetaMask에서 사용할 계정 가져오기
        const accounts = await web3.eth.getAccounts();
        const account = accounts[0];
        const gasPrice = await web3.eth.getGasPrice();

        const abiCode = await getAbiCodeByContractAddressParser(
          originMicroChainId,
          contractAddress
        );
        const decimal = 18;
        // transfers 배열을 순회하며 트랜잭션 파라미터 생성
        const parameters = transferInput.map((transfer) => {
          const mintAmount = WEB3.toWei(removeComma(transfer.amount));
          const mintParameters = [transfer.to, mintAmount];
          return encodeABI(abiCode, "transfer", mintParameters);
        });
        const data = encodeABI(abiCode, "multicall", [parameters]);

        // 트랜잭션 파라미터 설정
        if (data && parameters && abiCode) {
          const txParams = {
            from: account, // 현재 MetaMask 연결된 계정
            to: contractAddress, // 컨트랙트 주소
            data: data, // 인코딩된 함수 데이터
            value: "0x0", // 트랜잭션 값 (없으면 0)
            // gasPrice: gasPrice, // gasPrice 설정
          };
          // 트랜잭션을 MetaMask를 통해 전송
          try {
            const txHash = await window.ethereum.request({
              method: "eth_sendTransaction",
              params: [txParams],
            });
            return txHash;
          } catch (e) {
            throw new Error("tx_send_fail");
          }
        } else {
          throw new Error("make_parameter_fail");
        }
      } catch (error) {
        throw new Error("tx_make_fail");
      }
    });
  },

  /***********************************/
  /********* approve 기능 ************/
  /***********************************/

  tokenKitApproveTransactionWithMetamask: async (
    amount: number,
    originMicroChainId: number,
    contractAddress: string,
    spender: string
  ) => {
    return await actionController(async () => {
      const web3 = new Web3(window.ethereum);
      try {
        // MetaMask에서 사용할 계정 가져오기
        const accounts = await web3.eth.getAccounts();
        const account = accounts[0];
        const gasPrice = await web3.eth.getGasPrice();

        const abiCode = await getAbiCodeByContractAddressParser(
          originMicroChainId,
          contractAddress
        );
        const decimal = 18;
        const parameters = [spender, convertFromDecimal(amount, decimal)];
        const data = encodeABI(abiCode, "approve", parameters);

        // 트랜잭션 파라미터 설정
        if (data && parameters && abiCode) {
          const txParams = {
            from: account, // 현재 MetaMask 연결된 계정
            to: contractAddress, // 컨트랙트 주소
            data: data, // 인코딩된 함수 데이터
            value: "0x0", // 트랜잭션 값 (없으면 0)
            // gasPrice: gasPrice, // gasPrice 설정
          };
          // 트랜잭션을 MetaMask를 통해 전송
          try {
            const txHash = await window.ethereum.request({
              method: "eth_sendTransaction",
              params: [txParams],
            });
            return txHash;
          } catch (e) {
            throw new Error("tx_send_fail");
          }
        } else {
          throw new Error("make_parameter_fail");
        }
      } catch (error) {
        throw new Error("tx_make_fail");
      }
    });
  },

  /***********************************/
  /********* deploy 기능 ************/
  /***********************************/

  tokenKitDeployTransactionWithMetamask: async (kitId: number) => {
    return await actionController(async () => {
      const web3 = new Web3(window.ethereum);
      console.log(window.ethereum);

      try {
        // MetaMask에서 사용할 계정 가져오기
        const accounts = await web3.eth.getAccounts();
        const account = accounts[0];
        const gasPrice = await web3.eth.getGasPrice();
        const tokenKit = await getTokenKitDetailFromServer({
          params: {
            kitId: kitId,
          },
          query: {},
        });
        if (!tokenKit) {
          throw new Error("get_tokenKit_detail_fail");
        }

        //지원 모듈 컨트랙트
        const supportedContract =
          await getSupportedModuleContractListFromServer({
            params: { kitId: tokenKit.kit_id },
            query: {},
          });

        if (!supportedContract) {
          throw new Error("get_supportedContract_fail");
        }
        //모듈 컨트랙트 Address 목록
        const contractAddressList = supportedContract.map(
          (item) => item.contract_address
        );

        if (!contractAddressList) {
          throw new Error("make_addressList_fail");
        }
        const contractByteCode = await getContractByteCodeFromserver({
          params: {
            contractId: tokenKit.contract_id,
          },
          query: {},
        });
        const abiCode = await getAbiCodeByContractIdParser(
          tokenKit.contract_id
        );
        if (!abiCode) {
          throw new Error("make_abiCode_fail");
        }
        const constructorParams = [
          tokenKit.name, //token kit 생성할때 사용자가 입력하는 정보
          tokenKit.symbol, //token kit 생성할때 사용자가 입력하는 정보
          tokenKit.admin_address, //token kit의 주인 주소 token kit 상세
          contractAddressList,
          ["0x00", "0x00"], //현재는 0으로
          tokenKit.eqhub_address, // eq hub의 어드민 주소 -> token kit 상세
          kitId, //_kitId
          "token", //kit type
        ];
        console.log(constructorParams);
        //파라미터 인코딩
        const data = encodeABIWithByteCode(
          abiCode,
          contractByteCode.byte_code,
          constructorParams
        );

        if (!data) {
          throw new Error("make_parameter_fail"); // data가 없으면 에러를 던지기
        }

        // 트랜잭션 파라미터 설정
        const txParams = {
          from: account, // 현재 MetaMask 연결된 계정
          data: data, // 인코딩된 함수 데이터
          value: "0x0", // 트랜잭션 값 (없으면 0)
          // gasPrice: gasPrice,
        };
        // 트랜잭션을 MetaMask를 통해 전송
        try {
          const txHash = await window.ethereum.request({
            method: "eth_sendTransaction",
            params: [txParams],
          });
          return txHash;
        } catch (e) {
          throw new Error("tx_send_fail");
        }
      } catch (error) {
        throw new Error("tx_make_fail");
      }
    });
  },

  changeTokenKitStatusProcessing: async (kitId: number) => {
    return await actionController(async () => {
      try {
        const makeDeployStatus = await postKitIdDeployResultFromServer({
          params: { kitId },
          query: {},
        });
        const formattedTokenKits = {
          ...makeDeployStatus,
          is_public: makeDeployStatus.is_public === 1 ? true : false,
          version: {
            ...makeDeployStatus.version,
            is_public: makeDeployStatus.version.is_public === 1 ? true : false,
            is_latest: makeDeployStatus.version.is_latest === 1 ? true : false,
          },
        };
        return formattedTokenKits;
      } catch (error) {
        throw new Error("change_TokenKit_status_fail");
      }
    });
  },

  getTokenKitTypeList: async () => {
    return await actionController(async () => {
      try {
        const kitTypeList = await getTokenKitTypeListFromServer({
          params: {},
          query: {},
        });
        return kitTypeList;
      } catch (error) {
        throw new Error("kitTypeList_get_fail");
      }
    });
  },

  getTokenKitTypeDetail: async (kitTypeId: number) => {
    return await actionController(async () => {
      try {
        const kitTypeDetail = await getTokenKitTypeDetailFromServer({
          params: { kitTypeId },
          query: {},
        });
        return kitTypeDetail;
      } catch (error) {
        throw new Error("kitType_detail_get_fail");
      }
    });
  },

  getTokenKitTypeVersionList: async (kitTypeId: number) => {
    return await actionController(async () => {
      try {
        const kitTypeVersionDetail = await getTokenKitTypeVersionListFromServer(
          {
            params: { kitTypeId },
            query: {},
          }
        );
        const kitTypeVersionDetailData = kitTypeVersionDetail.map((item) => ({
          ...item,
          is_public: item.is_public === 1 ? true : false,
          is_latest: item.is_latest === 1 ? true : false,
        }));
        return kitTypeVersionDetailData;
      } catch (error) {
        throw new Error("kitType_version_detail_get_fail");
      }
    });
  },

  /*-----토큰키트 생성-----*/
  createTokenKit: async (
    versionId: number,
    isPublic: boolean,
    name: string,
    symbol: string,
    image: string | File,
    adminAddress: string
  ) => {
    return await actionController(async () => {
      try {
        let tokenKitImageUrl: string;

        // 이미지가 File일 경우 업로드 처리
        if (image instanceof File) {
          const formData = new FormData();
          formData.append("image", image);
          const fileCount = 1;
          const response = await uploadTokenImageToS3Parser(
            formData,
            fileCount
          );
          console.log(response);
          if (!response) {
            throw new Error("tokenKit_upload_image_fail");
          }
          tokenKitImageUrl = response;
        } else {
          // 이미지가 File이 아니면 그냥 URL을 사용
          tokenKitImageUrl = image;
        }
        // tokenKit 생성 요청
        const tokenKit = await createTokenKitFromServer({
          data: {
            versionId,
            isPublic,
            name,
            symbol,
            image: tokenKitImageUrl,
            adminAddress,
          },
          query: {},
        });
        const formattedTokenKits = {
          ...tokenKit,
          is_public: tokenKit.is_public === 1 ? true : false,
          version: {
            ...tokenKit.version,
            is_public: tokenKit.version.is_public === 1 ? true : false,
            is_latest: tokenKit.version.is_latest === 1 ? true : false,
          },
        };
        return formattedTokenKits;
      } catch (error) {
        throw new Error("tokenKit_create_fail");
      }
    });
  },

  // uploadTokenKitImageToS3: async (selectImage: File) => {
  //   return await actionController(async () => {
  //     try {
  //       const formData = new FormData();
  //       formData.append("image", selectImage);
  //       const tokenKitImageUrl = await postImageUploadForTokenKitFromServer({
  //         data: { selectImage: formData },
  //         query: {
  //           fileCount: 1,
  //         },
  //       });
  //       return tokenKitImageUrl;
  //     } catch (error) {
  //       throw new Error("tokenKit_image_upload_fail");
  //     }
  //   });
  // },

  getDefaultImageForTokenKit: async () => {
    return await actionController(async () => {
      try {
        const tokenKitDefaultImage = await getDefaultImageForTokenKitFromServer(
          {
            query: {},
          }
        );
        return tokenKitDefaultImage;
      } catch (error) {
        throw new Error("tokenKit_default_image_fail");
      }
    });
  },

  makeContractInfo: async (kitId: number, chainId: number) => {
    return await actionController(async () => {
      try {
        const tokenKit = await postKitIdForMakeContractFromServer({
          params: { kitId },
          data: { chainId },
          query: {},
        });
        const tokenKitData = {
          ...tokenKit,
          is_public: tokenKit.is_public === 1 ? true : false, // 1이면 true, 0이면 false
          version: {
            ...tokenKit.version,
            is_public: tokenKit.version.is_public === 1 ? true : false, // 1이면 true, 0이면 false
            is_latest: tokenKit.version.is_latest === 1 ? true : false, // 1이면 true, 0이면 false
          },
        };
        return tokenKitData;
      } catch (error) {
        throw new Error("make_contract_fail");
      }
    });
  },
};
