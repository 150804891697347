import {useEffect, useRef, useState} from "react";
import filter from "../../../../assets/images/icon_filter.png";
import "./ContractDropdownFilter.scss";
import { useSelector } from "react-redux";
import { selectAllSolution, selectAllStandard } from "reducer/presetReducer";

const ContractDropdownFilter = ({
  page,
  searchKeyword,
  checkedSolutions,
  checkedStandards,
  setCheckedSolutions,
  setCheckedStandards,
  getData,

}) => {
  const filterRef = useRef();
  const filterBtnRef = useRef();
  const [openFilter, setOpenFilter] = useState(false);
  const solutions = useSelector(selectAllSolution);
  const standards = useSelector(selectAllStandard);

  useEffect(() => {
    const onClickOutside = (e) => {
      if (openFilter && !filterRef.current.contains(e.target) && !filterBtnRef.current.contains(e.target)) {
        setOpenFilter(false);
      }
    };
    document.addEventListener("mousedown", onClickOutside);
    return () => {
      document.removeEventListener("mousedown", onClickOutside);
    };
  }, [openFilter, filterRef.current]);

  const clickSolutions = (tagId) => {
    if (checkedSolutions.includes(tagId)) {
      const updateCheckedSolutions = checkedSolutions.filter((sol) => sol !== tagId);
      setCheckedSolutions(updateCheckedSolutions);
      getData(page, searchKeyword, updateCheckedSolutions, checkedStandards);
    } else {
      const solutions = [...checkedSolutions, tagId];
      setCheckedSolutions(solutions);
      getData(page, searchKeyword, solutions, checkedStandards);
    }
  };

  const clickStandards = (categoryId) => {
    if (checkedStandards.includes(categoryId)) {
      const updateCheckedStandards = checkedStandards.filter((stan) => stan !== categoryId);
      setCheckedStandards(updateCheckedStandards);
      getData(page, searchKeyword, checkedSolutions, updateCheckedStandards);
    } else {
      const standards = [...checkedStandards, categoryId];
      setCheckedStandards(standards);
      getData(page, searchKeyword, checkedSolutions, standards);
    }
  };

  return (
    <div>
      <div
        className="contracts-filter"
        onClick={() => {
          setOpenFilter((prev) => !prev);
        }}
        ref={filterBtnRef}
      >
        <img className="contracts-filter-icon" src={filter} alt="" />
        Filter
      </div>
      {openFilter && (
        <div className="contracts-filter-dropdown" ref={filterRef}>
          <div className="contracts-filter-dropdown-box">
            <div className="contracts-filter-dropdown-title">Solution</div>
            <div className="contracts-filter-dropdown-type">
              {solutions &&
                solutions.map((sol, index) => (
                  <div key={`solution-${index}`} className="contracts-filter-dropdown-tag">
                    <input
                      type="checkbox"
                      value={sol.name}
                      checked={Boolean(checkedSolutions.find((solutionId) => solutionId === sol.tag_id))}
                      onChange={(e) => {}}
                    />
                    <label htmlFor={sol.name} onClick={() => clickSolutions(sol.tag_id)}>
                      {sol.name}
                    </label>
                  </div>
                ))}
            </div>
          </div>
          <div className="contracts-filter-dropdown-bar"></div>
          <div className="contracts-filter-dropdown-box">
            <div className="contracts-filter-dropdown-title">Standard</div>
            <div className="contracts-filter-dropdown-type">
              {standards &&
                standards.map((category, index) => (
                  <div
                    key={`standard-${index}`}
                    className="contracts-filter-dropdown-category"
                    style={{
                      color: category.font_color,
                      backgroundColor: category.background_color,
                    }}
                  >
                    <input
                      type="checkbox"
                      id={category.name}
                      checked={Boolean(checkedStandards.find((standardId) => standardId === category.category_id))}
                      onChange={(e) => {}}
                    />

                    <label htmlFor={category.name} onClick={() => clickStandards(category.category_id)}>
                      {category.name}
                    </label>
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContractDropdownFilter;
