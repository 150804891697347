import React from "react";
import { Route, Routes } from "react-router-dom";
import ProjectMember from "./page/ProjectMember";
import ProjectPricing from "./page/ProjectPricing";
import ProjectBilling from "./page/ProjectBilling";

const ProjectRouter = () => {
  return (
    <Routes>
      <Route path="member" element={<ProjectMember />} />
      <Route path="billing" element={<ProjectBilling />} />
      <Route path="pricing" element={<ProjectPricing />} />
    </Routes>
  );
};

export default ProjectRouter;
