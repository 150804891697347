import React from "react";
import "./Section.scss";
import DivideLine from "view/components/_atom/line/DivideLine";

const Section = ({ title, description, view, data = null, rowHorizontal = "center" }) => {
  const hasIndex = (obj) => obj.hasOwnProperty("index");

  return (
    <section className={"section"}>
      <div className={"section-header"}>
        <span className={"section-title"}>{title}</span>
        {description && <span className={"section-description"}>{description}</span>}
      </div>
      <DivideLine style={{ margin: "20px 0" }} />
      {view(data).map(
        (subSection, index) =>
          subSection && (
            <div className={"section-content"} key={index}>
              {subSection.label && <span className="section-content-label">{subSection.label}</span>}
              {subSection.renderers.map((renderer, idx) =>
                hasIndex(renderer) ? (
                  <div className="section-row-data" key={idx} style={{ alignItems: rowHorizontal }}>
                    <div className="section-row-index">{renderer.index}</div>
                    <div className="section-row-value">{renderer.value}</div>
                  </div>
                ) : (
                  <React.Fragment key={idx}>{renderer.value}</React.Fragment>
                )
              )}
            </div>
          )
      )}
    </section>
  );
};

export default Section;
