import {
  createPaymentMethodFromServer,
  deletePaymentMethodFromServer,
  getDepositHistoryFromServer,
  getDepositListForContractTypeFromServer,
  getPaymentMethodDetailFromServer,
  getPaymentMethodsFromServer,
  getPaymentTypePresetsFromServer,
  getSelectedPaymentGatewayTypeFromServer,
  getWithdrawalsFromServer,
  resendDepositFromServer,
  retryAlertFromServer,
  retryDepositAlertForContractTypeFromServer,
  retryWithdrawalAlertFromServer,
  submitPaymentMethodFromServer,
  updatePaymentMethodFromServer,
} from "../server/API/paymentGatewayAPI";
import {
  DepositHistory,
  DepositListForContractType,
  GetDepositHistoryOptions,
  GetDepositListOptionsForSmartContractType,
  GetWithdrawalHistoryOptions,
  PaymentMethod,
  PaymentType,
  SelectedPaymentGatewayType,
  WithdrawalHistory,
} from "../reducer/clientType/paymentMethodClientType";
import _ from "lodash";

export const getPaymentTypePresetsParser = async (): Promise<PaymentType[]> => {
  const result = await getPaymentTypePresetsFromServer();
  return result.map((type) => ({
    paymentTypeId: type.payment_type_id,
    name: type.name,
    methodType: type.method_type,
    description: type.description,
  }));
};

export const createPaymentMethodParser = async (
  paymentMethodName: string,
  microChainId: number,
  microChainCurrencyId: number,
  paymentTypeIds: Array<number>,
  walletAddress: string,
  depositCallbackURI: string,
  withdrawalCallbackURI: string,
  options?: { blockConfirmationCount?: number }
): Promise<PaymentMethod> => {
  const result = await createPaymentMethodFromServer({
    data: {
      paymentMethodName,
      microChainId,
      microChainCurrencyId,
      paymentTypeIds,
      walletAddress,
      depositCallbackURI,
      withdrawalCallbackURI,
      ...(options?.blockConfirmationCount && { blockConfirmationCount: options.blockConfirmationCount }),
    },
  });
  return {
    paymentMethodId: result.payment_method_id,
    name: result.name,
    adminAddress: result.admin_address,
    microChainId: result.micro_chain_id,
    microChainCurrencyId: result.micro_chain_currency_id,
    microChainCurrencyCA: result.micro_chain_currency_ca,
    callbackTimeout: result.callback_timeout,
    callbackRetryCount: result.callback_retry_count,
    withdrawalCallbackURI: result.withdrawal_callback_uri,
    mainStatus: result.main_status,
    blockConfirmationCount: result.block_confirmation_count,
    contractAddress: result.contract_address,
    contractId: result.contract_id,
    creatorId: result.creator_id,
    createdAt: result.created_at,
  };
};

export const getPaymentMethodsParser = async (): Promise<PaymentMethod[]> => {
  const result = await getPaymentMethodsFromServer();
  return result.map((el) => ({
    paymentMethodId: el.payment_method_id,
    name: el.name,
    adminAddress: el.admin_address,
    microChainId: el.micro_chain_id,
    microChainCurrencyId: el.micro_chain_currency_id,
    microChainCurrencyCA: el.micro_chain_currency_ca,
    callbackTimeout: el.callback_timeout,
    callbackRetryCount: el.callback_retry_count,
    withdrawalCallbackURI: el.withdrawal_callback_uri,
    mainStatus: el.main_status,
    blockConfirmationCount: el.block_confirmation_count,
    contractAddress: el.contract_address,
    contractId: el.contract_id,
    creatorId: el.creator_id,
    createdAt: el.created_at,
  }));
};

export const getPaymentMethodDetailParser = async (paymentMethodId: string): Promise<PaymentMethod> => {
  const result = await getPaymentMethodDetailFromServer({
    params: { paymentMethodId },
  });

  return {
    paymentMethodId: result.payment_method_id,
    name: result.name,
    adminAddress: result.admin_address,
    microChainId: result.micro_chain_id,
    microChainCurrencyId: result.micro_chain_currency_id,
    microChainCurrencyCA: result.micro_chain_currency_ca,
    callbackTimeout: result.callback_timeout,
    callbackRetryCount: result.callback_retry_count,
    withdrawalCallbackURI: result.withdrawal_callback_uri,
    mainStatus: result.main_status,
    blockConfirmationCount: result.block_confirmation_count,
    contractAddress: result.contract_address,
    contractId: result.contract_id,
    creatorId: result.creator_id,
    createdAt: result.created_at,
  };
};

export const getSelectedPaymentGatewayTypeParser = async (paymentMethodId: string): Promise<SelectedPaymentGatewayType[]> => {
  const result = await getSelectedPaymentGatewayTypeFromServer({
    query: { paymentMethodId },
  });
  return result.map((paymentType) => ({
    paymentMethodTypeId: paymentType.payment_method_type_id,
    paymentMethodId: paymentType.payment_method_id,
    paymentTypeId: paymentType.payment_type_id,
    setting: {
      callbackURI: paymentType.setting.callback_uri,
      isGenerateQrCode: paymentType.setting.is_generate_qr_code,
    },
  }));
};

export const updatePaymentMethodParser = async (
  paymentMethodId: string,
  walletAddress: string,
  depositCallbackURI: string,
  withdrawalCallbackURI: string
): Promise<PaymentMethod> => {
  const result = await updatePaymentMethodFromServer({
    params: {
      paymentMethodId,
    },
    data: {
      walletAddress,
      depositCallbackURI,
      withdrawalCallbackURI,
    },
  });
  return {
    paymentMethodId: result.payment_method_id,
    name: result.name,
    adminAddress: result.admin_address,
    microChainId: result.micro_chain_id,
    microChainCurrencyId: result.micro_chain_currency_id,
    microChainCurrencyCA: result.micro_chain_currency_ca,
    callbackTimeout: result.callback_timeout,
    callbackRetryCount: result.callback_retry_count,
    withdrawalCallbackURI: result.withdrawal_callback_uri,
    mainStatus: result.main_status,
    blockConfirmationCount: result.block_confirmation_count,
    contractAddress: result.contract_address,
    contractId: result.contract_id,
    creatorId: result.creator_id,
    createdAt: result.created_at,
  };
};

export const submitPaymentMethodParser = async (paymentMethodId: string): Promise<any> => {
  return await submitPaymentMethodFromServer({
    params: {
      paymentMethodId,
    },
  });
};

export const deletePaymentMethodParser = async (paymentMethodId: string): Promise<boolean> => {
  const { status } = await deletePaymentMethodFromServer({
    params: { paymentMethodId },
  });
  return status;
};

export const getDepositHistoryParser = async (
  paymentMethodId: string,
  limit: number,
  offset: number,
  options?: GetDepositHistoryOptions
): Promise<{
  count: number;
  rows: DepositHistory[];
}> => {
  const result = await getDepositHistoryFromServer({
    query: {
      paymentMethodId,
      limit,
      offset,
      ...(options?.address && { address: options.address }),
      ...(options?.createdStartDate && { createdStartDate: options.createdStartDate }),
      ...(options?.createdEndDate && { createdEndDate: options.createdEndDate }),
      ...(options?.depositStatus && { depositStatus: options.depositStatus }),
      ...(options?.transferStatus && { transferStatus: options.transferStatus }),
    },
  });

  return {
    count: result.count,
    rows: _.sortBy(
      result.rows.map((el) => ({
        virtualAddressTransactionId: el.virtual_address_transaction_id,
        createdAt: el.virtual_address.created_at,
        txHash: el.tx_hash,
        amount: el.amount,
        status: el.status,
        datetime: el.datetime,
        virtualAddress: {
          virtualAddressId: el.virtual_address.virtual_address_id,
          address: el.virtual_address.address,
          createdAt: el.virtual_address.created_at,
        },
        virtualAddressAlert: {
          status: el.virtual_address_alert.status,
          datetime: el.virtual_address_alert.datetime,
        },
        virtualAddressPayout: {
          status: el.virtual_address_payout.status,
          datetime: el.virtual_address_payout.datetime,
        },
      })),
      "createdAt"
    ).reverse(),
  };
};

export const retryDepositAlertParser = async (virtualAddressTransactionId: number, virtualAddressId: number): Promise<boolean> => {
  const { status } = await retryAlertFromServer({
    params: { virtualAddressId, virtualAddressTransactionId },
  });
  return status;
};

export const resendDepositParser = async (virtualAddressTransactionId: number, virtualAddressId: number): Promise<boolean> => {
  const { status } = await resendDepositFromServer({
    params: { virtualAddressId, virtualAddressTransactionId },
  });
  return status;
};

export const getWithdrawalHistoryParser = async (
  paymentMethodId: string,
  limit: number,
  offset: number,
  options?: GetWithdrawalHistoryOptions
): Promise<{
  count: number;
  rows: WithdrawalHistory[];
}> => {
  const result = await getWithdrawalsFromServer({
    query: {
      paymentMethodId,
      limit,
      offset,
      ...(options?.withdrawalStartDate && { withdrawalStartDate: options.withdrawalStartDate }),
      ...(options?.withdrawalEndDate && { withdrawalEndDate: options.withdrawalEndDate }),
      ...(options?.withdrawalStatus && { withdrawalStatus: options.withdrawalStatus }),
    },
  });
  return {
    count: result.count,
    rows: result.rows.map((el) => ({
      withdrawalId: el.withdrawal_id,
      address: el.address,
      amount: el.amount,
      createdAt: el.created_at,
      withdrawalTransaction: {
        status: el.withdrawal_transaction.status,
      },
      withdrawalAlert: {
        status: el.withdrawal_alert.status,
      },
    })),
  };
};

export const retryWithdrawalAlertParser = async (withdrawalId: number): Promise<boolean> => {
  const { status } = await retryWithdrawalAlertFromServer({
    params: { withdrawalId },
  });
  return status;
};

export const getDepositListForContractTypeParser = async (
  paymentMethodId: string,
  limit: number,
  offset: number,
  options?: GetDepositListOptionsForSmartContractType
): Promise<{ count: number; rows: Array<DepositListForContractType> }> => {
  const result = await getDepositListForContractTypeFromServer({
    query: {
      paymentMethodId,
      limit,
      offset,
      ...(options?.address && { address: options.address }),
      ...(options?.createdStartDate && { createdStartDate: options.createdStartDate }),
      ...(options?.createdEndDate && { createdEndDate: options.createdEndDate }),
      ...(options?.status && { status: options.status }),
    },
  });

  const response = {
    count: result.count,
    rows: result.rows.map((el) => ({
      contractTransactionId: el.contract_transaction_id,
      paymentRequestId: el.payment_request_id,
      txHash: el.tx_hash,
      result: el.result,
      amount: el.amount,
      from: el.from,
      status: el.status,
      datetime: el.datetime,
      blockNumber: el.block_number,
      blockConfirmationCount: el.block_confirmation_count,
      execBlockNumber: el.exec_block_number,
      createdAt: el.created_at,
      paymentRequest: {
        paymentRequestId: el.payment_request.payment_request_id,
        paymentMethodId: el.payment_request.payment_method_id,
        requestId: el.payment_request.request_id,
      },
      contractTransactionAlert: {
        status: el.contract_transaction_alert.status,
        datetime: el.contract_transaction_alert.datetime,
      },
    })),
  };

  return response;
};

export const retryDepositAlertForContractTypeParser = async (contractTransactionId: number): Promise<boolean> => {
  const { status } = await retryDepositAlertForContractTypeFromServer({
    params: {
      contractTransactionId,
    },
  });

  return status;
};
