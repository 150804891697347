import React, { TextareaHTMLAttributes } from "react";
import "./BasicTextarea.scss";

type BasicTextareaProps = TextareaHTMLAttributes<HTMLTextAreaElement>;

const BasicTextarea = (props: BasicTextareaProps) => {
  const { value, onChange, placeholder, rows = 5, readOnly, onFocus, ...rest } = props;

  return (
    <textarea
      className="basic-textarea"
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      rows={rows}
      readOnly={readOnly}
      onFocus={onFocus}
      {...rest}
    ></textarea>
  );
};

export default BasicTextarea;
