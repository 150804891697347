export const checkConsecutiveByCondition = (data, checkData) => {
  for (let i in data) {
    let s = "";
    let at = parseInt(i);
    let j = at;
    while (j < at + 3 && j < data.length) {
      s += data[j];
      j++;
    }
    var regex = new RegExp(s);
    if (regex.test(checkData)) {
      return true;
    }
  }
  return false;
};
