import {
  acceptContractVersionFromServer,
  createContractFromServer,
  createContractVersionFromServer,
  deleteContractVersionFromServer,
  getContractVersionFromServer,
  getContractVersionsFromServer,
  getMyContractsFromServer,
  getPublishedContractsFromServer,
  publishContractVersionFromServer,
  rejectContractVersionFromServer,
  submitContractVersionFromServer,
  updateContractVersionFromServer,
  withdrawContractVersionFromServer,
} from "../server/API/contractLibraryAPI";
import {AbiCodeObject, LibraryContract, LibraryContractVersionDetail} from "../reducer/clientType/contractClientType";
import axios from "axios";
import {parseAbiCode} from "../utils/abi.utils";
import {AbiItem} from "web3-utils";

export const getPublishedContractsParser = async (options?: {limit?: number; lastId?: number; order?: string}): Promise<LibraryContract[]> => {
  const result = await getPublishedContractsFromServer({
    query: {
      ...(options?.limit && {limit: options.limit}),
      ...(options?.lastId && {lastId: options.lastId}),
      ...(options?.order && {order: options.order}),
    },
  });
  return result.map((contract) => ({
    contractId             : contract.contract_id,
    creatorId              : contract.creator_id,
    accountId              : contract.account_id,
    latestContractVersionId: contract.latest_contract_version_id,
    title                  : contract.title,
    description            : contract.description,
    type                   : contract.type,
    ercType                : contract.erc_type,
    isOfficial             : contract.is_official,
    contractStandardId     : contract.contract_standard_id,
    projectId              : contract.project_id,
    contractVersions       : contract.contract_versions.map((el) => ({
      contractVersionId : el.contract_version_id,
      version           : el.version,
      versionDescription: el.version_description,
      status            : el.status,
      isPrivate         : el.is_private,
    })),
  }));
};

export const createContractParser = async (fileCount: number, formData: FormData): Promise<LibraryContract> => {
  const result = await createContractFromServer({
    query: {
      fileCount,
    },
    data : formData,
  });
  return {
    contractId             : result.contract_id,
    creatorId              : result.creator_id,
    accountId              : result.account_id,
    latestContractVersionId: result.latest_contract_version_id,
    title                  : result.title,
    description            : result.description,
    type                   : result.type,
    ercType                : result.erc_type,
    isOfficial             : result.is_official,
    contractStandardId     : result.contract_standard_id,
    projectId              : result.project_id,
    contractVersions       : result.contract_versions.map((el) => ({
      contractId        : el.contract_id,
      contractVersionId : el.contract_version_id,
      version           : el.version,
      versionDescription: el.version_description,
      status            : el.status,
      isPrivate         : el.is_private,
    })),
  };
};

export const getMyContractsParser = async (contractId?: number): Promise<LibraryContract[] | LibraryContract> => {
  const result = await getMyContractsFromServer({
    query: {
      ...(contractId && {contractId}),
    },
  });
  return Array.isArray(result)
    ? result.map((contract) => ({
      contractId             : contract.contract_id,
      creatorId              : contract.creator_id,
      accountId              : contract.account_id,
      latestContractVersionId: contract.latest_contract_version_id,
      title                  : contract.title,
      description            : contract.description,
      type                   : contract.type,
      ercType                : contract.erc_type,
      isOfficial             : contract.is_official,
      contractStandardId     : contract.contract_standard_id,
      projectId              : contract.project_id,
      contractVersions       : contract.contract_versions.map((el) => ({
        contractId        : el.contract_id,
        contractVersionId : el.contract_version_id,
        version           : el.version,
        versionDescription: el.version_description,
        status            : el.status,
        isPrivate         : el.is_private,
      })),
    }))
    : {
      contractId             : result.contract_id,
      creatorId              : result.creator_id,
      accountId              : result.account_id,
      latestContractVersionId: result.latest_contract_version_id,
      title                  : result.title,
      description            : result.description,
      type                   : result.type,
      ercType                : result.erc_type,
      isOfficial             : result.is_official,
      contractStandardId     : result.contract_standard_id,
      projectId              : result.project_id,
      contractVersions       : result.contract_versions.map((el) => ({
        contractId        : el.contract_id,
        contractVersionId : el.contract_version_id,
        version           : el.version,
        versionDescription: el.version_description,
        status            : el.status,
        isPrivate         : el.is_private,
      })),
    };
};

// TODO: contract에서 사용
export const getContractVersionsParser = async (contractId: number): Promise<LibraryContract> => {
  const result = await getContractVersionsFromServer({
    params: {
      contractId,
    },
  });
  return {
    contractId             : result.contract_id,
    creatorId              : result.creator_id,
    accountId              : result.account_id,
    latestContractVersionId: result.latest_contract_version_id,
    title                  : result.title,
    description            : result.description,
    type                   : result.type,
    ercType                : result.erc_type,
    isOfficial             : result.is_official,
    contractStandardId     : result.contract_standard_id,
    projectId              : result.project_id,
    contractVersions       : result.contract_versions.map((el) => ({
      contractVersionId : el.contract_version_id,
      version           : el.version,
      versionDescription: el.version_description,
      status            : el.status,
      isPrivate         : el.is_private,
    })),
  };
};

export const createContractVersionParser = async (contractId: number, fileCount: number, formData: FormData): Promise<LibraryContractVersionDetail> => {
  const result = await createContractVersionFromServer({
    params: {
      contractId: contractId,
    },
    query : {
      fileCount: fileCount,
    },
    data  : formData,
  });
  return {
    contractVersionId          : result.contract_version_id,
    contractId                 : result.contract_id,
    version                    : result.version,
    versionDescription         : result.version_description,
    status                     : result.status,
    isPrivate                  : result.is_private,
    contractVersionCode        : {
      contractVersionId: result.contract_version_code.contract_version_id,
      solidityCode     : result.contract_version_code.solidity_code,
      abiCode          : result.contract_version_code.abi_code,
      byteCode         : result.contract_version_code.byte_code,
      customAbiCode    : result.contract_version_code.custom_abi_code
    },
    contractVersionRejectDetail: result.contract_version_reject_detail
      ? {
        rejectReason: result.contract_version_reject_detail.reject_reason,
        createdAt   : result.contract_version_reject_detail.created_at,
      }
      : null,
  };
};


export const getContractVersionDetailParser = async (contractId: number, contractVersionId: number): Promise<LibraryContractVersionDetail> => {
  const result = await getContractVersionFromServer({
    params: {
      contractId,
      contractVersionId,
    },
  });
  return {
    contractVersionId  : result.contract_version_id,
    contractId         : result.contract_id,
    version            : result.version,
    versionDescription : result.version_description,
    status             : result.status,
    isPrivate          : result.is_private,
    contractVersionCode: {
      contractVersionId: result.contract_version_code.contract_version_id,
      solidityCode     : result.contract_version_code.solidity_code,
      abiCode          : result.contract_version_code.abi_code,
      byteCode         : result.contract_version_code.byte_code,
      customAbiCode    : result.contract_version_code.custom_abi_code
    },
    contractVersionRejectDetail: result.contract_version_reject_detail
      ? {
        rejectReason: result.contract_version_reject_detail.reject_reason,
        createdAt   : result.contract_version_reject_detail.created_at,
      }
      : null,
  };
};

export const updateContractVersionParser = async (
  contractId: number,
  contractVersionId: number,
  formData: FormData
): Promise<LibraryContractVersionDetail> => {
  const result = await updateContractVersionFromServer({
    query : {
      fileCount: 1,
    },
    params: {
      contractId,
      contractVersionId,
    },
    data  : formData
  });
  return {
    contractVersionId          : result.contract_version_id,
    contractId                 : result.contract_id,
    version                    : result.version,
    versionDescription         : result.version_description,
    status                     : result.status,
    isPrivate                  : result.is_private,
    contractVersionCode        : {
      contractVersionId: result.contract_version_code.contract_version_id,
      solidityCode     : result.contract_version_code.solidity_code,
      abiCode          : result.contract_version_code.abi_code,
      byteCode         : result.contract_version_code.byte_code,
      customAbiCode    : result.contract_version_code.custom_abi_code,
    },
    contractVersionRejectDetail: result.contract_version_reject_detail
      ? {
        rejectReason: result.contract_version_reject_detail.reject_reason,
        createdAt   : result.contract_version_reject_detail.created_at,
      }
      : null,
  };
};

export const submitContractVersionParser = async (contractId: number, contractVersionId: number): Promise<LibraryContractVersionDetail> => {
  const result = await submitContractVersionFromServer({
    params: {
      contractId,
      contractVersionId,
    },
  });
  return {
    contractVersionId          : result.contract_version_id,
    contractId                 : result.contract_id,
    version                    : result.version,
    versionDescription         : result.version_description,
    status                     : result.status,
    isPrivate                  : result.is_private,
    contractVersionCode        : {
      contractVersionId: result.contract_version_code.contract_version_id,
      solidityCode     : result.contract_version_code.solidity_code,
      abiCode          : result.contract_version_code.abi_code,
      byteCode         : result.contract_version_code.byte_code,
      customAbiCode    : result.contract_version_code.custom_abi_code

    },
    contractVersionRejectDetail: result.contract_version_reject_detail
      ? {
        rejectReason: result.contract_version_reject_detail.reject_reason,
        createdAt   : result.contract_version_reject_detail.created_at,
      }
      : null,
  };
};

export const deleteContractVersionParser = async (contractId: number, contractVersionId: number) => {
  const {status} = await deleteContractVersionFromServer({
    params: {
      contractId,
      contractVersionId,
    },
  });
  return status;
};

export const rejectContractVersionParser = async (
  contractId: number,
  contractVersionId: number,
  rejectReason: string
): Promise<LibraryContractVersionDetail> => {
  const result = await rejectContractVersionFromServer({
    params: {
      contractId,
      contractVersionId,
    },
    data  : {
      rejectReason,
    },
  });
  return {
    contractVersionId          : result.contract_version_id,
    contractId                 : result.contract_id,
    version                    : result.version,
    versionDescription         : result.version_description,
    status                     : result.status,
    isPrivate                  : result.is_private,
    contractVersionCode        : {
      contractVersionId: result.contract_version_code.contract_version_id,
      solidityCode     : result.contract_version_code.solidity_code,
      abiCode          : result.contract_version_code.abi_code,
      byteCode         : result.contract_version_code.byte_code,
      customAbiCode    : result.contract_version_code.custom_abi_code

    },
    contractVersionRejectDetail: result.contract_version_reject_detail
      ? {
        rejectReason: result.contract_version_reject_detail.reject_reason,
        createdAt   : result.contract_version_reject_detail.created_at,
      }
      : null,
  };
};

export const acceptContractVersionParser = async (contractId: number, contractVersionId: number): Promise<LibraryContractVersionDetail> => {
  const result = await acceptContractVersionFromServer({
    params: {
      contractId,
      contractVersionId,
    },
  });
  return {
    contractVersionId          : result.contract_version_id,
    contractId                 : result.contract_id,
    version                    : result.version,
    versionDescription         : result.version_description,
    status                     : result.status,
    isPrivate                  : result.is_private,
    contractVersionCode        : {
      contractVersionId: result.contract_version_code.contract_version_id,
      solidityCode     : result.contract_version_code.solidity_code,
      abiCode          : result.contract_version_code.abi_code,
      byteCode         : result.contract_version_code.byte_code,
      customAbiCode    : result.contract_version_code.custom_abi_code

    },
    contractVersionRejectDetail: result.contract_version_reject_detail
      ? {
        rejectReason: result.contract_version_reject_detail.reject_reason,
        createdAt   : result.contract_version_reject_detail.created_at,
      }
      : null,
  };
};

export const publishContractVersionParser = async (contractId: number, contractVersionId: number): Promise<LibraryContractVersionDetail> => {
  const result = await publishContractVersionFromServer({
    params: {
      contractId,
      contractVersionId,
    },
  });
  return {
    contractVersionId          : result.contract_version_id,
    contractId                 : result.contract_id,
    version                    : result.version,
    versionDescription         : result.version_description,
    status                     : result.status,
    isPrivate                  : result.is_private,
    contractVersionCode        : {
      contractVersionId: result.contract_version_code.contract_version_id,
      solidityCode     : result.contract_version_code.solidity_code,
      abiCode          : result.contract_version_code.abi_code,
      byteCode         : result.contract_version_code.byte_code,
      customAbiCode    : result.contract_version_code.custom_abi_code

    },
    contractVersionRejectDetail: result.contract_version_reject_detail
      ? {
        rejectReason: result.contract_version_reject_detail.reject_reason,
        createdAt   : result.contract_version_reject_detail.created_at,
      }
      : null,
  };
};

export const withdrawContractVersionParser = async (contractId: number, contractVersionId: number): Promise<LibraryContractVersionDetail> => {
  const result = await withdrawContractVersionFromServer({
    params: {
      contractId,
      contractVersionId,
    },
  });
  return {
    contractVersionId          : result.contract_version_id,
    contractId                 : result.contract_id,
    version                    : result.version,
    versionDescription         : result.version_description,
    status                     : result.status,
    isPrivate                  : result.is_private,
    contractVersionCode        : {
      contractVersionId: result.contract_version_code.contract_version_id,
      solidityCode     : result.contract_version_code.solidity_code,
      abiCode          : result.contract_version_code.abi_code,
      byteCode         : result.contract_version_code.byte_code,
      customAbiCode    : result.contract_version_code.custom_abi_code

    },
    contractVersionRejectDetail: result.contract_version_reject_detail
      ? {
        rejectReason: result.contract_version_reject_detail.reject_reason,
        createdAt   : result.contract_version_reject_detail.created_at,
      }
      : null,
  };
};

/**
 * S3 URL을 통해 데이터를 조회하기에 axios 인스턴스를 직접 사용하였습니다.
 */
export const getABICodeByFileURLParser = async (url: string): Promise<AbiCodeObject> => {
  const result = await axios.get(url).then(res => res.data) as AbiItem[];
  return parseAbiCode(result);
}
