import { useParams } from "react-router-dom";
import { useMemo, useState } from "react";
import StatusDetail, { DEFAULT_LABEL_STYLE } from "../../../../components/_atom/label/StatusDetail";
import ManageNFTProjectBeforeDeploy from "./ManageNFTProjectBeforeDeploy";
import ManageNFTProjectAfterDeploy from "./ManageNFTProjectAfterDeploy";
import { errorAlert } from "../../../../../utils/Utils";
import { MINTING, NFT_PROJECT_STATUS } from "../constant/nftConstant";
import { getNFTProjectDetailAction } from "action/nftAction";
import PageArticle from "view/components/_template/page/PageArticle";
import PageTitle from "view/components/_molecules/page/PageTitle";
import PageLoading from "view/components/_atom/loading/PageLoading";
import CopyLabelWithIcon from "view/components/_atom/label/CopyLabelWithIcon";
import useLoading from "hooks/useLoading";
import useGetSelectedProject from "hooks/useGetSelectedProject";
import { isDisplayTransactionStatus, parseServiceStatus } from "../../../../../utils/status.utils";
import TransactionStatus from "../../../../components/_atom/transactionStatus/TransactionStatus";
import nftIcon from "../../../../assets/images/service/Icon_SNB_non_fungible_token.png";
import { ManageFieldContainer, ManageFieldLabel, ManageFieldWrapper, RowManageField } from "view/components/_atom/section/ManageField";
import DivideLine from "view/components/_atom/line/DivideLine";

const ManageNFTProject = () => {
  const { selectedProjectID } = useGetSelectedProject();

  const { id: nftProjectId } = useParams();

  //========= Get NFT Project Detail =========//
  const [projectDetail, setProjectDetail] = useState({});
  const [nftProjectStatus, setNftProjectStatus] = useState(null);

  const getNFTProjectDetail_ = async () => {
    const { result, error } = await getNFTProjectDetailAction(parseInt(nftProjectId));
    if (result) {
      const { mainStatus, subStatus } = result;
      const targetStatus = parseServiceStatus(NFT_PROJECT_STATUS, mainStatus, subStatus);
      setNftProjectStatus(targetStatus);
      setProjectDetail(result);
    }
    if (error) {
      errorAlert(error.data.message);
    }
  };

  //========= Distinguish Status =========//
  const statusParser = useMemo(() => {
    switch (projectDetail?.mainStatus) {
      default:
        return projectDetail?.mainStatus;
    }
  }, [projectDetail]);

  const statusData = useMemo(() => {
    switch (projectDetail?.mainStatus) {
      default:
        return {
          text: DEFAULT_LABEL_STYLE.find((el) => el.status === statusParser)?.text,
          description: DEFAULT_LABEL_STYLE.find((el) => el.status === statusParser)?.description,
        };
    }
  }, [projectDetail, statusParser]);

  const { loading } = useLoading({
    dependency: [selectedProjectID],
    synchronousFunction: async () => {
      await getNFTProjectDetail_();
    },
  });

  if (loading) {
    return <PageLoading />;
  }

  return (
    <PageArticle>
      <PageTitle title={"NFT Project Detail"} size={810} />
      <div className="page-layout-810">
        <StatusDetail status={statusParser} data={statusData} />
        {isDisplayTransactionStatus(NFT_PROJECT_STATUS[nftProjectStatus]) && (
          <TransactionStatus
            {...(projectDetail.transactionHash && { transactionHash: projectDetail.transactionHash })}
            statusDetail={NFT_PROJECT_STATUS[nftProjectStatus].statusDetail}
            microChainId={projectDetail.microChainId}
          />
        )}
        {/* NFT Project Info Description :: You can view information about NFT projects and modify some information. */}
        <ManageFieldContainer icon={nftIcon} title="NFT Project Info">
          <ManageFieldWrapper title="Basic Info">
            <>
              <div className="section-wrapper-content-data-information">
                <div className="section-wrapper-content-data-information-image">
                  <img className="section-wrapper-content-data-information-image-logo" src={projectDetail?.image} alt="" />
                </div>
                <div className="section-wrapper-content-data-information-title">{projectDetail?.projectName ?? "-"}</div>
              </div>
              <div className="section-wrapper-content-data-description">{projectDetail?.projectDescription ?? "-"}</div>
            </>
          </ManageFieldWrapper>
          <DivideLine style={{ margin: "20px 0" }} />
          <ManageFieldWrapper title="Details">
            <RowManageField>
              <ManageFieldLabel label="ID" />
              <span>{`${projectDetail?.projectId ?? "-"}`}</span>
            </RowManageField>
            <RowManageField>
              <ManageFieldLabel label="Network / Microchain" />
              <span>{`${projectDetail?.networkLabel} / ${projectDetail?.microChainLabel}`}</span>
            </RowManageField>
            <RowManageField>
              <ManageFieldLabel label="Symbol" />
              <span>{`${projectDetail?.symbol || "-"}`}</span>
            </RowManageField>
            <RowManageField>
              <ManageFieldLabel label="Contract address" />
              {projectDetail?.contractAddress ? <CopyLabelWithIcon text={projectDetail?.contractAddress} /> : "-"}
            </RowManageField>
            <RowManageField>
              <ManageFieldLabel label="Project links" />
              <div className="manage-nft-project-link-container">
                {Array.isArray(projectDetail?.projectLink) && projectDetail?.projectLink.length === 0 && "-"}
                {projectDetail?.projectLink.map((el, index) => {
                  return (
                    <div className="link-button" key={`marketing-channel-${index}`} onClick={() => window.open(el)}>
                      {el}
                    </div>
                  );
                })}
              </div>
            </RowManageField>
            <RowManageField>
              <ManageFieldLabel label="The time of minting" />
              <span>{MINTING.find((el) => el.value === projectDetail?.mintTiming)?.label}</span>
            </RowManageField>
          </ManageFieldWrapper>
        </ManageFieldContainer>
        {(projectDetail.mainStatus === 1 || projectDetail.mainStatus === 3) && (
          <ManageNFTProjectBeforeDeploy projectDetail={projectDetail} getNFTProjectDetail={getNFTProjectDetail_} setProjectDetail={setProjectDetail} />
        )}
        {projectDetail.mainStatus === 5 && <ManageNFTProjectAfterDeploy projectDetail={projectDetail} />}
      </div>
    </PageArticle>
  );
};
export default ManageNFTProject;
