import React, { useEffect, useMemo, useState } from "react";
import css from "./ProjectRequestsChart.scss";
import { DashboardRequestVolumeType } from "../constants/dashboard.type";
import { FromTick } from "server/type/projectType";
import { getProjectRequestsAction } from "action/dashboardAction";
import { ProjectRequestsTab } from "server/type/projectType";
import { CHART_INTERVAL_OPTIONS, DASHBOARD_CHART_INTERVAL_COUNT, DASHBOARD_PROJECT_REQUESTS_TYPE_LIST } from "../constants/dashboard.data";
import { numberConvertor } from "utils/convert.utils";
import { Area, CartesianGrid, YAxis, XAxis, AreaChart, ResponsiveContainer, Tooltip } from "recharts";
import useLoading from "hooks/useLoading";
import ChartLoading from "view/components/_atom/loading/ChartLoading";
import SectionContainer from "view/components/_atom/section/SectionContainer";
import chartIcon from "../assets/Icon_dashboard_chart.png";
import { bindStyle } from "view/styles/classNames";
import arrowIcon from "../../..//assets/images/Icon_arrow_down.png";

type CustomChartProps = {
  payload?: any;
};

const ProjectRequestsChart = () => {
  const cn = bindStyle(css);

  const [projectRequests, setProjectRequests] = useState<DashboardRequestVolumeType[]>([]);
  const [intervalCount, setIntervalCount] = useState<number>(-1);

  const [selectedTabId, setSelectedTabId] = useState<ProjectRequestsTab>(ProjectRequestsTab.COUNT);
  const [selectedRequestsPeriod, setSelectedRequestsPeriod] = useState<FromTick>(FromTick.WEEK);

  const totalRequests: number = useMemo(() => {
    const result = projectRequests.reduce((acc, cur) => acc + cur.count, 0);

    switch (selectedTabId) {
      case ProjectRequestsTab.COUNT:
        return result;
      case ProjectRequestsTab.CREDIT:
        return Number(Math.ceil(result * 100) / 100);
      default:
        return result;
    }
  }, [projectRequests]);

  const getProjectReqeusts_ = async (type: ProjectRequestsTab, from: FromTick) => {
    const { result, error } = await getProjectRequestsAction(type, from);
    if (result) {
      setProjectRequests(result);
    }
    if (error) {
    }
  };

  useEffect(() => {
    let interval;
    if (intervalCount !== -1) {
      interval = setInterval(() => {
        setIntervalCount((prev) => prev + 1);
      }, 1000);
      if (intervalCount === DASHBOARD_CHART_INTERVAL_COUNT) {
        setIntervalCount(-1);
        getProjectReqeusts_(selectedTabId, selectedRequestsPeriod).then(() => setIntervalCount(0));
        clearInterval(interval);
      }
    }

    return () => clearInterval(interval);
  }, [intervalCount]);

  useEffect(() => {
    setIntervalCount(-1);
  }, [selectedTabId, selectedRequestsPeriod]);

  const { loading } = useLoading({
    dependency: [selectedTabId, selectedRequestsPeriod],
    synchronousFunction: async () => {
      getProjectReqeusts_(selectedTabId, selectedRequestsPeriod).then(() => setIntervalCount(0));
    },
  });

  return (
    <SectionContainer
      title="Project Requests"
      icon={chartIcon}
      element={<RequestsTypeSelector selectedTabId={selectedTabId} setSelectedTabId={setSelectedTabId} />}
    >
      <div className="project-requests">
        {loading ? (
          <ChartLoading />
        ) : (
          <>
            <div className="total-request">
              {numberConvertor(totalRequests)}
              {selectedTabId === ProjectRequestsTab.CREDIT && <span className="total-request-unit">EQ Credit</span>}
              <span className="total-request-unit">(Total)</span>
            </div>
            <div className="project-requests-chart">
              <ResponsiveContainer width={"100%"} height={"100%"}>
                <AreaChart data={projectRequests}>
                  <defs>
                    <linearGradient id="ProjectRequestsChartgradientColor" x1="0" y1="0" x2="0" y2="1">
                      <stop offset={"20%"} stopColor={"#006eff"} stopOpacity={0.35} />
                      <stop offset={"100%"} stopColor={"#006eff"} stopOpacity={0} />
                    </linearGradient>
                  </defs>
                  <XAxis dataKey={"ts"} axisLine={{ stroke: "#d8d9d9" }} tickLine={{ stroke: "#d8d9d9" }} />
                  <YAxis dataKey={"count"} axisLine={false} tickLine={false} width={44} />
                  <CartesianGrid strokeDasharray="3 3" stroke={"#d8d9d9"} vertical={false} />
                  <Tooltip wrapperStyle={{ outline: "none" }} content={<ProjectRequestsChartCustomTooltip />} cursor={false} />
                  <Area
                    type="monotone"
                    dataKey="count"
                    activeDot={{ stroke: "#5888ff", strokeWidth: 3, strokeOpacity: 0.4, fill: "#5888ff", r: 4 }}
                    strokeWidth={2}
                    stroke="#006eff"
                    fill="url(#ProjectRequestsChartgradientColor)"
                    fillOpacity={"0.5"}
                  />
                </AreaChart>
              </ResponsiveContainer>
            </div>
            <div className="project-requests-options">
              {CHART_INTERVAL_OPTIONS.map((option) => (
                <div
                  key={`project-requests-chart-option-${option.id}`}
                  className={cn("project-requests-options-item", { selected: selectedRequestsPeriod === option.value })}
                  onClick={() => setSelectedRequestsPeriod(option.value)}
                >
                  {option.label}
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </SectionContainer>
  );
};

const RequestsTypeSelector = (props) => {
  const { selectedTabId, setSelectedTabId } = props;

  const cn = bindStyle(css);
  const [isPanelOpen, setIsPanelOpen] = useState<boolean>(false);

  const typeClickHandler = (id) => {
    setSelectedTabId(id);
    setIsPanelOpen(false);
  };

  return (
    <div className="project-requests-type-selector" onMouseEnter={() => setIsPanelOpen(true)} onMouseLeave={() => setIsPanelOpen(false)}>
      <div className="project-requests-type-selector-area">
        <span className="project-requests-type-selector-area-label">{DASHBOARD_PROJECT_REQUESTS_TYPE_LIST.find((el) => el.id === selectedTabId)?.label}</span>
        <div className={cn("project-requests-type-selector-area-icon", { active: isPanelOpen })}>
          <img src={arrowIcon} className="project-requests-type-selector-area-icon-src" alt="" />
        </div>
      </div>

      {isPanelOpen && (
        <div className="project-requests-type-selector-panel">
          <ul className="project-requests-type-selector-panel-list">
            {DASHBOARD_PROJECT_REQUESTS_TYPE_LIST.filter((item) => {
              return item.id !== selectedTabId;
            }).map((el) => (
              <li key={`project-requests-tpye-${el.id}`} className="project-requests-type-selector-panel-list-item" onClick={() => typeClickHandler(el.id)}>
                {el.label}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

const ProjectRequestsChartCustomTooltip = (props: CustomChartProps) => {
  const { payload } = props || {};

  if (!payload || payload.length <= 0) {
    return <></>;
  }

  return (
    <div className="project-requests-chart-custom-tooltip">
      <p className="project-requests-chart-custom-tooltip-label">
        {payload[0]?.payload?.type === ProjectRequestsTab.COUNT ? numberConvertor(payload[0]?.value) : payload[0]?.value}
      </p>
    </div>
  );
};

export default ProjectRequestsChart;
