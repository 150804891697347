import BigNumber from "bignumber.js";
import { removeComma } from "utils/Utils";
import { commaRemovalConvertor } from "utils/convert.utils";

// 네트워크 생성 / 수정 과정 중 노드 개수를 선택하면 개수만큼 노드의 데이터를 채울 수 있는 빈 객체 배열 생성
export const setTempNodeList = (nodeCount: number): Array<any> => {
  let temp = new Array(nodeCount);
  temp.fill({ index: null, name: null });

  return temp.map((_, index) => ({
    address: null,
    name: `node #${index + 1}`,
    keyStore: null,
    encryptedKey: null,
    isValidator: true,
    mmcBalancePercent: "",
    mmcBalance: null,
    mmcStaking: null,
    // 클라이언트 데이터
    index,
    keyStoreFile: null,
    isChecked: false,
  }));
};

// 노드에 할당하려는 비율에 맞는 코인 balance를 계산해서 노드리스트 반환
export const calculateNodeAllocBalanceByDistributionPercent = (initialSupply: string, nodeAlloc: Array<any>): Array<any> | undefined => {
  if (!initialSupply) {
    return;
  }

  return nodeAlloc.map((el) => {
    const balance = convertPercentToValue(initialSupply, el.mmcBalancePercent);
    return {
      ...el,
      mmcBalance: balance,
      mmcStaking: balance,
    };
  });
};

// 노드에 할당하려는 비율에 맞는 코인 balance를 계산해서 노드리스트 반환
export const calculatePreAllocBalanceByDistributionPercent = (initialSupply: string, preAlloc: Array<any>): Array<any> | undefined => {
  if (!initialSupply) {
    return;
  }

  return preAlloc.map((el) => {
    const balance = convertPercentToValue(initialSupply, el.distributionPercent);
    return {
      ...el,
      mmcBalance: balance,
    };
  });
};

// 노드들에게 동등한 코인 자동 분배
export const nodeEqualDistribution = (nodeAllocatedAmount: string, allocListCount: string) => {
  // node 하나 당 동등한 비율로 나눴을 때의 퍼센트
  const allocationPerRow = divCalculate("100", allocListCount);
  // 하나의 node가 가질 퍼센트 (소수점 첫 번째)
  const allocationPerRowToFirstDecimal = customToFixed(Number(allocationPerRow));
  // 첫번째 node를 제외한 나머지 node 행들의 합
  const sumOfNodeAllocated = mulCalculate(allocationPerRowToFirstDecimal, (Number(allocListCount) - 1).toString());
  // 첫번째 node가 할당받을 퍼센트
  const remainingAllocationPercent = subCalculate("100", sumOfNodeAllocated);
  // 첫번째 node를 제외한 나머지 node 행들이 할당받을 balance
  const equalAllocatedNodeBalance = convertPercentToValue(nodeAllocatedAmount, allocationPerRowToFirstDecimal);
  // 첫번째 node들이 할당받은 balance 들의 합
  const totalNodeAllocatedBalance = mulCalculate(removeComma(equalAllocatedNodeBalance), (Number(allocListCount) - 1).toString());
  // 첫번째 node가 할당받을 balance
  const remainingBalance = subCalculate(nodeAllocatedAmount, removeComma(totalNodeAllocatedBalance));

  return {
    firstNode: { mmcBalancePercent: remainingAllocationPercent, balance: remainingBalance },
    restNode: { mmcBalancePercent: allocationPerRowToFirstDecimal, balance: equalAllocatedNodeBalance },
  };
};

export const allottedState = (percent: number): string => {
  let state = "";
  if (percent === 0) {
    state = "";
  }
  if (percent > 100) {
    state = "over";
  }
  if (percent === 100) {
    state = "enough";
  }
  if (percent > 0 && percent < 100) {
    state = "insufficient";
  }
  return state;
};

export const allottedStateLabel = (percent: number): string | undefined => {
  let label: string | undefined;
  if (percent === 0) {
    label = undefined;
  }
  if (percent > 100) {
    label = "Check the input value\nYou have exceeded the amount that can be allocated";
  }
  if (percent === 100) {
    label = "Allocation completed";
  }
  if (percent > 0 && percent < 100) {
    label = "Still have the coins to allocate";
  }
  return label;
};

// -------------------------------------------------------------------------------

// 초기 발행량에서 비율에 맞는 개수를 반환
const convertPercentToValue = (supply: string, percent: string): string => {
  if (!percent) {
    return "";
  }
  const BN_supply = BigNumber(supply);
  const BN_percent = BigNumber(percent);
  const result = BN_supply.multipliedBy(BN_percent).dividedBy(100).toFormat();
  return result;
};

// 소수점 자리수 제거
const customToFixed = (value: number, decimalPoint: number = 1) => {
  const decimalNumber = 10 ** decimalPoint;
  const BN_value = BigNumber(value);
  const BN_decimalNumber = BigNumber(decimalNumber);
  const mulDecimalPoint = Math.floor(commaRemovalConvertor(BN_value.multipliedBy(BN_decimalNumber).toFormat()) as number);
  return BigNumber(mulDecimalPoint).dividedBy(BN_decimalNumber).toFormat();
};

// BigNumber를 사용한 뺄셈
export const subCalculate = (firstValue: string, secondValue: string) => {
  const BN_firstValue = BigNumber(firstValue);
  const BN_secondValue = BigNumber(secondValue);
  return BN_firstValue.minus(BN_secondValue).toFormat();
};

// BigNumber를 사용한 곱셈
const mulCalculate = (firstValue: string, secondValue: string) => {
  const BN_firstValue = BigNumber(firstValue);
  const BN_secondValue = BigNumber(secondValue);
  return BN_firstValue.multipliedBy(BN_secondValue).toFormat();
};

// BigNumber를 사용한 나눗셈
const divCalculate = (firstValue: string, secondValue: string) => {
  const BN_firstValue = BigNumber(firstValue);
  const BN_secondValue = BigNumber(secondValue);
  return BN_firstValue.dividedBy(BN_secondValue).toFormat();
};

export const networkTypeConverter = (protocolId: number, chainClientVersionId: number, algorithmId: number): number => {
  const result = protocolId.toString() + chainClientVersionId.toString() + algorithmId.toString();
  // "${protocolId}${chainClientVersionId}${algorithmId}
  return Number(result);
};

export const roundNumber = (float: number, decimal: number): number => {
  const pow = Math.pow(10, decimal);
  return Math.round(float * pow) / pow;
};
