import React, { useEffect, useState } from "react";
import "./VerifyEmailModal.scss";
import emailImage from "../../../../assets/images/Image_send_email.png";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { sendAuthCodeEmailAction } from "../../../../../action/userAction";
import BasicButton from "view/components/_atom/button/BasicButton";
import { ButtonSize } from "view/components/_atom/button/StyledButton";
import ModalTemplateB from "view/components/_template/modal/ModalTemplateB";
import ButtonContainer from "view/components/_atom/container/ButtonContainer";

type VerifyEmailData = {
  userId: number;
  email: string;
};

type VerifyEmailModalProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  verifyEmailProps: VerifyEmailData;
};

const VerifyEmailModal = (props: VerifyEmailModalProps) => {
  const { isOpen, setIsOpen, verifyEmailProps } = props;
  const navigate = useNavigate();
  const [isSendDisabled, setIsSendDisabled] = useState(false);

  const continueToSignIn = () => {
    setIsOpen(false);
    navigate("/initialize");
  };

  const sendEmailAuthCode = async () => {
    const { result, error } = await sendAuthCodeEmailAction(verifyEmailProps.userId, verifyEmailProps.email);
    if (result) {
      toast.success("Verification mail has been sent again !");
      setIsSendDisabled(true);
    }
    if (error) {
      // toast.error(error.data.message);
    }
  };

  const sendAuthCodeByEmail_ = async () => {
    if (isSendDisabled) {
      toast.error("Please try again in a few seconds.");
      return;
    }

    await sendEmailAuthCode();
  };

  useEffect(() => {
    if (isSendDisabled) {
      setTimeout(() => {
        setIsSendDisabled(false);
      }, 5000);
    }
  }, [isSendDisabled]);

  return (
    <ModalTemplateB isOpen={isOpen} onRequestClose={() => setIsOpen(false)} title="Verify your email">
      <div className="verify-email-modal">
        <div className="verify-email-modal-image">
          <img className="verify-email-modal-image-src" src={emailImage} alt="" />
        </div>
        <div className="verify-email-modal-content">
          <p>
            We've sent an email to <span className="verify-email-modal-content-email">{verifyEmailProps?.email}</span>
          </p>
          <p>to verify your email address.</p>
          <p>The link in the email will expire in 24 hours.</p>
        </div>
        <div className="verify-email-modal-receive">
          <span className="verify-email-modal-receive-trigger" onClick={() => sendAuthCodeByEmail_()}>
            Click here
          </span>
          <span> if you did not receive an email</span>
        </div>
        <div className="verify-email-modal-button-container">
          <ButtonContainer>
            <BasicButton size={ButtonSize.EXTRALARGE} onClick={() => continueToSignIn()}>
              Done
            </BasicButton>
          </ButtonContainer>
        </div>
      </div>
    </ModalTemplateB>
  );
};

export default VerifyEmailModal;
