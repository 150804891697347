import { useEffect, useState } from "react";
import css from "./SortingTable.scss";
import arrowIcon from "../../../../view/assets/images/icon-sorting-table-arrow.png";
import { bindStyle } from "view/styles/classNames";
import ChartLoading from "../../../components/_atom/loading/ChartLoading";
import useLoading from "../../../../hooks/useLoading";
import PagingButtonBar from "./PagingButtonBar";

type SortingTableProps = {
  headers: { title: string; accessor: string; sortable: boolean }[];
  data: DataType;
  //TODO:: 함수 type 정의 필요
  renderer: any;
  colGroup: number[];
  //TODO:: 함수 type 정의 필요
  onClick?: any;
  noDataPlaceholder?: string;
};
type DataType = any[];

const LIMIT = 7;

function SortingTable(props: SortingTableProps) {
  const { headers, data, renderer, colGroup, onClick, noDataPlaceholder = "No Data" } = props || {};
  const cn = bindStyle(css);

  const [sortOption, setSortOption] = useState<{ accessor: string; type: string }>({ accessor: "count", type: "desc" });
  const onClickSort = (header: { title: string; accessor: string; sortable: boolean }, type: string): void => {
    setSortOption({ accessor: header.accessor, type: type });
  };

  const [sortedData, setSortedData] = useState<DataType>([]);

  const sortData = (): void => {
    if (sortOption.accessor) {
      //TODO:: 서버가 페이징 및 sorting 기능 완성되면 해당 부분 로직 수정 필요
      const sortedData = data.sort((a, b): number => {
        if (sortOption.type === "asc") {
          return a[sortOption.accessor] > b[sortOption.accessor] ? 1 : -1;
        }
        if (sortOption.type === "desc") {
          return a[sortOption.accessor] < b[sortOption.accessor] ? 1 : -1;
        }
        return 0;
      });
      setSortedData(sortedData);
    }
  };

  const [requestActivityData, setRequestActivityData] = useState<DataType>([]);

  useEffect(() => {
    getSortingTableData(1);
  }, [sortedData]);

  const [page, setPage] = useState<number>(1);
  const totalLength: number = sortedData.length;
  const maximumPage: number = Math.ceil(sortedData.length / LIMIT);
  const getSortingTableData = (page: number): void => {
    const start = (page - 1) * LIMIT;
    const end = page * LIMIT;
    setRequestActivityData(sortedData.slice(start, end));
  };

  const { loading: initialLoading } = useLoading({
    dependency: [data],
    synchronousFunction: async () => {
      await sortData();
    },
  });
  const { loading } = useLoading({
    dependency: [sortOption],
    synchronousFunction: async () => {
      await sortData();
    },
  });
  return initialLoading || loading ? (
    <ChartLoading />
  ) : (
    <div className="sorting-table-wrapper">
      <table className="sorting-table">
        <colgroup>
          {colGroup.map((col: any, index: number) => (
            <col key={`col-${index}`} style={{ width: `${col}px` }} />
          ))}
        </colgroup>
        <thead>
          <tr>
            {headers.map((header: any, index: number) => (
              <th key={`table-header-${index}`}>
                <div className="table-header-cell">
                  {header.title}
                  {header.sortable && (
                    <div className="table-sorting-arrows-wrapper">
                      <div className="table-sorting-arrow-wrapper" onClick={() => onClickSort(header, "asc")}>
                        <img
                          src={arrowIcon}
                          alt="-"
                          className={cn("table-sorting-arrow-icon asc", { selected: sortOption.accessor === header.accessor && sortOption.type === "asc" })}
                        />
                      </div>
                      <div className="table-sorting-arrow-wrapper" onClick={() => onClickSort(header, "desc")}>
                        <img
                          src={arrowIcon}
                          alt="-"
                          className={cn("table-sorting-arrow-icon desc", { selected: sortOption.accessor === header.accessor && sortOption.type === "desc" })}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {requestActivityData.map((el: any, index: number) => (
            <tr key={`table-row-${index}`} onClick={() => onClick && onClick(el, index)}>
              {headers.map((header: any) => (
                <td key={`table-row-data-${header.accessor}`}>{renderer(el)[header.accessor]}</td>
              ))}
            </tr>
          ))}
          {requestActivityData.length <= 0 && (
            <tr className="no-data-row">
              <td colSpan={headers.length}>{noDataPlaceholder}</td>
            </tr>
          )}
        </tbody>
      </table>
      <PagingButtonBar limit={LIMIT} page={page} setPage={setPage} totalLength={totalLength} maximumPage={maximumPage} getData={getSortingTableData} />
    </div>
  );
}

export default SortingTable;
