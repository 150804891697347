import { Route, Routes } from "react-router-dom";

import NetworkList from "./network/page/NetworkList";
import ManageNetwork from "./network/page/ManageNetwork";
import CreateNetwork from "./network/page/CreateNetwork";
import EditNetwork from "./network/page/EditNetwork";

import CreateNode from "./node/page/CreateNode";
import NodeList from "./node/page/NodeList";
import ManageNode from "./node/page/ManageNode";

import FoundationList from "./foundation/page/FoundationList";
import ManageFoundation from "./foundation/page/ManageFoundation";
import UpdateFoundation from "./foundation/page/UpdateFoundation";
import FoundationNoticeList from "./foundation/page/FoundationNoticeList";
import CreateFoundationNotice from "./foundation/page/CreateFoundationNotice";
import ManageFoundationNotice from "./foundation/page/ManageFoundationNotice";
import UpdateFoundationNotice from "./foundation/page/UpdateFoundationNotice";

export const NetworkRouter = () => {
  return (
    <Routes>
      <Route path={"mainnet/*"} element={<MainnetRouter />} />
      {/* <Route path={"micro-chain/*"} element={<MicroChainRouter />} /> */}
      <Route path={"node/*"} element={<NodeRouter />} />
      <Route path={"foundation/*"} element={<FoundationRouter />} />
    </Routes>
  );
};
export default NetworkRouter;

const MainnetRouter = () => {
  return (
    <Routes>
      <Route path="create" element={<CreateNetwork />} />
      <Route path="manage/*">
        <Route index element={<NetworkList />} />
        <Route path={":id/*"}>
          <Route index element={<ManageNetwork />} />
          <Route path={"edit"} element={<EditNetwork />} />
        </Route>
      </Route>
    </Routes>
  );
};

const MicroChainRouter = () => {
  return (
    <Routes>
      <Route path="create" element={<>Create Microchain</>} />
      <Route path="manage/*">
        <Route index element={<>Microchain List</>} />
        <Route path={":id"} element={<>Microchain manage</>} />
      </Route>
    </Routes>
  );
};

const NodeRouter = () => {
  return (
    <Routes>
      {/* <Route path="create" element={<CreateNode />} /> */}
      <Route path="manage/*">
        <Route index element={<NodeList />} />
        <Route path={":id"} element={<ManageNode />} />
      </Route>
    </Routes>
  );
};

const FoundationRouter = () => {
  return (
    <Routes>
      {/* <Route path="manage/*">
        <Route index element={<FoundationList />} />
        <Route path={":id/*"}>
          <Route index element={<ManageFoundation />} />
          <Route path={"update"} element={<UpdateFoundation />} />

          <Route path="notice/*">
            <Route path="create/*" element={<CreateFoundationNotice />} />
            <Route path="manage/*">
              <Route index element={<FoundationNoticeList />} />
              <Route path={":noticeId/*"}>
                <Route index element={<ManageFoundationNotice />} />
                <Route path={"update"} element={<UpdateFoundationNotice />} />
              </Route>
            </Route>
          </Route>
        </Route>
      </Route> */}
    </Routes>
  );
};
