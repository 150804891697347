import {isApiError, isExpiredError} from "../../server/index/APIError";
import {ModuleError} from "../../modules/ModuleError";
import {storeDispatch} from "reducer/index";
import {setIsExpired} from "reducer/userReducer";

/**
 * EQ Hub에서 다뤄질 수 있는 ErrorType입니다.
 * API: EQ Hub 내에서 사용하는 API에 대한 Error
 * Module: EQ Hub 내에서 사용하는 Module에 대한 Error (ex. web3)
 * External: 외부 API를 사용할 경우 해당 API에 대한 Error
 * unknown: 알수 없음
 */
export type ErrorType = "API" | "Module" | "External" | "unknown";

export type ActionErrorType = { type: ErrorType; data: any } | undefined;

type ActionType = {
	result: any | undefined;
	error: undefined | ActionErrorType;
};

export const parseAction = async (fn: () => Promise<any>): Promise<ActionType> => {
	try {
		const result = await fn();
		return {result, error: undefined};
	} catch (e: unknown) {
		let actionError: ActionErrorType;
		//api 에러 뿐만이 아나라 다른 에러가 일어날시에 대한 경우도 추가되어야함.
		//if else문 보다 다중 if문을 사용하는게 좋아보입니다. (가독성을 높이기 위함입니다.)
		if (isApiError(e)) {
			actionError = e;
			//API error일 경우
			return {
				result: undefined,
				error: actionError,
			};
		}

		if (isModuleError(e)) {
			actionError = ModuleErrorParser(e);
			return {
				result: undefined,
				error: actionError,
			};
		}

		if (isExpiredError(e)) {
			storeDispatch(setIsExpired(true));
			return {
				result: undefined,
				error: actionError,
			};
		}

		actionError = {
			type: "unknown",
			data: e,
		};
		return {
			result: undefined,
			error: actionError,
		};
	}
};

export const isModuleError = (e: any): e is ModuleError => {
	return e.type === "Module" && e instanceof ModuleError;
};

export const ModuleErrorParser = (e: any): ActionErrorType => {
	switch (e.name) {
		case "Web3":
			return {
				type: "Module",
				data: {
					code: e.data.code,
					argument: e.data.argument,
					reason: e.data.reason,
					value: e.data.value,
				},
			};
	}
};
