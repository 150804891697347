import React from "react";
import RowFieldContainer from "../../../../components/_atom/container/RowFieldContainer";
import { requiredIndex } from "../../../../../utils/textUtils";
import { ProjectDataType } from "../page/CreateProject";
import BasicTextarea from "../../../../components/_atom/input/BasicTextarea";
import ButtonContainer from "../../../../components/_atom/container/ButtonContainer";
import BasicButton from "../../../../components/_atom/button/BasicButton";
import { ButtonSize, ButtonTheme } from "../../../../components/_atom/button/StyledButton";
import { useNavigate } from "react-router-dom";
import IconSelector, { IconTheme } from "../../../../components/_molecules/select/IconSelector";
import NetworkSelector from "../../../../components/_atom/select/NetworkSelector";
import useIsAble from "../../../../../hooks/useIsAble";
import CommentInput from "../../../../components/_atom/input/CommentInput";
import useValidateCondition from "../../../../../hooks/useValidateCondition";
import { VC__is_empty_value } from "../../../../../modules/validation/validationCondition";
import { checkProjectNameAction } from "../../../../../action/projectAction";
import { errorAlert } from "../../../../../utils/Utils";

type CreateProjectStep1Props = {
  setStep: React.Dispatch<React.SetStateAction<number>>;
  projectData: ProjectDataType;
  setProjectData: React.Dispatch<React.SetStateAction<ProjectDataType>>;
  images: Array<string>;
  networkList: Array<any>;
  // TODO :: step3 생기면 삭제
  createProject: Function;
};

const CreateProjectStep1 = ({ setStep, projectData, setProjectData, images, networkList, createProject }: CreateProjectStep1Props) => {
  const navigate = useNavigate();

  const setProjectName = (value: string): void => {
    setProjectData((prevState) => {
      return { ...prevState, name: value };
    });
  };

  const setProjectDescription = (value: string): void => {
    setProjectData((prevState) => {
      return { ...prevState, description: value };
    });
  };

  const setProjectImage = (value: string | File): void => {
    setProjectData((prevState) => {
      return { ...prevState, image: value };
    });
  };

  const setProjectSelectedMicroChainIds = (id: number) => {
    setProjectData((prevState: any) => {
      const { selectedMicroChainIds } = prevState;
      if (selectedMicroChainIds.includes(id)) {
        const result = selectedMicroChainIds.filter((selectedId: number) => selectedId !== id);
        return { ...prevState, selectedMicroChainIds: result };
      } else {
        const result = selectedMicroChainIds.concat(id);
        return { ...prevState, selectedMicroChainIds: result };
      }
    });
  };

  const checkDuplicate = async () => {
    const { result, error } = await checkProjectNameAction(projectData.name);
    if (result) {
      setStep((prevStep) => prevStep + 1);
    }
    if (error) {
      errorAlert(error.data.message);
    }
  };

  // TODO :: Pricing Plan 없을 때 임시처리
  const createProject_ = async () => {
    const { result, error } = await checkProjectNameAction(projectData.name);

    if (result) {
      createProject();
    }

    if (error) {
      errorAlert(error.data.message);
    }
  };

  const projectNameValidation = useValidateCondition(
    [projectData.name],
    [
      {
        condition: !VC__is_empty_value(projectData.name),
        message: "",
      },
    ]
  );
  const projectImageValidation = useValidateCondition(
    [projectData.image],
    [
      {
        condition: !VC__is_empty_value(projectData.image),
        message: "",
      },
    ]
  );

  const isAble = useIsAble([projectNameValidation.isValid, projectImageValidation.isValid]);

  return (
    <>
      <RowFieldContainer label={requiredIndex("Project Name")}>
        <CommentInput value={projectData.name} onChange={(e) => setProjectName(e.target.value)} placeholder={"EX) EQ Hub"} />
      </RowFieldContainer>
      <RowFieldContainer label={"Project Description"}>
        <BasicTextarea
          value={projectData.description}
          onChange={(e) => setProjectDescription(e.target.value)}
          placeholder={"Enter the description of Project"}
        />
      </RowFieldContainer>
      <RowFieldContainer label={requiredIndex("Project image")}>
        <IconSelector iconTheme={IconTheme.CIRCLE} images={images} fileState={projectData.image} setFileState={setProjectImage} />
      </RowFieldContainer>
      <RowFieldContainer label={"Blockchain network"}>
        <NetworkSelector networkList={networkList} selectedList={projectData.selectedMicroChainIds} setSelectedList={setProjectSelectedMicroChainIds} />
      </RowFieldContainer>
      <div className="flex justify-content-flex-end">
        <ButtonContainer>
          <BasicButton theme={ButtonTheme.SECONDARY} size={ButtonSize.LARGE} onClick={() => navigate("/manage-project")}>{`Back`}</BasicButton>
          <BasicButton size={ButtonSize.LARGE} disabled={!isAble} onClick={() => createProject_()}>
            {`Create`}
          </BasicButton>
        </ButtonContainer>
      </div>
    </>
  );
};

export default CreateProjectStep1;
