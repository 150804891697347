import {STATUS, StatusDataType} from "./status.type";
import IconReady from "../assets/Icon_status_ready.png";
import IconCreated from '../assets/icon_status_created.png';
import IconProcessing from '../assets/icon_status_processing.png';
import IconComplete from '../assets/icon_status_complete.png';
import IconReject from '../assets/icon_status_reject.png';
import IconRunning from '../assets/Icon_status_running.png';

export const STATUS_DATA: StatusDataType[] = [
  {
    status: STATUS.READY,
    icon: IconReady,
    color: '#747474',
    defaultText: 'Ready'
  },
  {
    status: STATUS.CREATED,
    icon: IconCreated,
    color: '#80A3FC',
    defaultText: 'Created'
  },
  {
    status: STATUS.PROCESSING,
    icon: IconProcessing,
    color: '#F4C983',
    defaultText: 'Requested'
  },
  {
    status: STATUS.PREPARED,
    icon: IconComplete,
    color: '#6BC7E4',
    defaultText: 'Prepared'
  },
  {
    status: STATUS.COMPLETE,
    icon: IconComplete,
    color: '#6BC97E',
    defaultText: 'Deployed'
  },
  {
    status: STATUS.REJECTED,
    icon: IconReject,
    color: '#CF7171',
    defaultText: 'Rejected'
  },
  {
    status: STATUS.RUNNING,
    icon: IconRunning,
    color: '#5888FF',
    defaultText: 'Running'
  },
]
