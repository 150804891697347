import { useEffect } from "react";
import "./AirdropBudgetSetting.scss";
import {
  VALIDATION__AIRDROP_budget,
  VALIDATION__AIRDROP_currency,
  VALIDATION__AIRDROP_microchain,
  VALIDATION__AIRDROP_network,
} from "../../validation/airdropValidation";
import { getCurrenciesByMicroChainIdAction } from "action/airdropAction";
import Select from "../../../../../components/_atom/select/Select";
import BasicButton from "view/components/_atom/button/BasicButton";
import { ButtonSize, ButtonTheme } from "view/components/_atom/button/StyledButton";
import ButtonContainer from "view/components/_atom/container/ButtonContainer";
import useValidateCondition from "hooks/useValidateCondition";
import CommentInput from "view/components/_atom/input/CommentInput";
import useIsAble from "../../../../../../hooks/useIsAble";
import { FieldContainer, FieldLabel, RowField } from "view/components/_atom/section/Field";
import airdropIcon from "../../../../../assets/images/service/Icon_SNB_airdrop.png";

const AirdropBudgetSetting = (props) => {
  const {
    onClickBackButton,
    airdropData,
    setAirdropData,
    allNetworkList,
    allMicroChainList,
    setCurrentStep,
    selectedNetworkId,
    setSelectedNetworkId,
    selectedMicroChainId,
    setSelectedMicroChainId,
    selectedCurrencyId,
    setSelectedCurrencyId,
    availableMicroChainList,
    setAvailableMicroChainList,
    availableCurrencyList,
    setAvailableCurrencyList,
  } = props || {};

  useEffect(() => {
    if (selectedNetworkId) {
      const availableMicrochains = allMicroChainList.filter((el) => el.networkId === selectedNetworkId);
      if (availableMicrochains.length === 0) {
        setSelectedMicroChainId(null);
      } else {
        setSelectedMicroChainId(availableMicrochains.find((chain) => Boolean(chain.isMain)).id ?? null);
      }
      setAvailableMicroChainList(availableMicrochains);
    }
  }, [selectedNetworkId]);

  const getCurrenciesByMicroChainId_ = async () => {
    const { result, error } = await getCurrenciesByMicroChainIdAction(selectedMicroChainId);
    if (result) {
      setAvailableCurrencyList(result);
    }
    if (error) {
      // console.error(error.data.message);
    }
  };

  useEffect(() => {
    if (selectedMicroChainId) {
      setSelectedCurrencyId(null);
      setAirdropData(null, "microchainCurrencyId");
      getCurrenciesByMicroChainId_();
    }
  }, [selectedMicroChainId]);

  useEffect(() => {
    if (selectedNetworkId) {
      setSelectedCurrencyId(null);
      setAirdropData(null, "microchainCurrencyId");
    }
  }, [selectedNetworkId]);

  useEffect(() => {
    if (selectedNetworkId) {
      setAirdropData(selectedNetworkId, "networkId");
    }
  }, [selectedNetworkId]);

  useEffect(() => {
    if (selectedMicroChainId) {
      setAirdropData(selectedMicroChainId, "microchainId");
    }
  }, [selectedMicroChainId]);

  useEffect(() => {
    if (selectedCurrencyId) {
      setAirdropData(selectedCurrencyId, "microchainCurrencyId");
    }
  }, [selectedCurrencyId]);

  const networkValidation = useValidateCondition([airdropData.networkId], VALIDATION__AIRDROP_network(airdropData.networkId));
  const microchainValidation = useValidateCondition([airdropData.microchainId], VALIDATION__AIRDROP_microchain(airdropData.microchainId));
  const currencyValidation = useValidateCondition([airdropData.microchainCurrencyId], VALIDATION__AIRDROP_currency(airdropData.microchainCurrencyId));
  const budgetValidation = useValidateCondition([airdropData.depositAmount], VALIDATION__AIRDROP_budget(airdropData.depositAmount));

  const isAble = useIsAble([networkValidation.isValid, microchainValidation.isValid, currencyValidation.isValid, budgetValidation.isValid]);

  const onClickNextButton = () => {
    setCurrentStep((prevState) => prevState + 1);
  };

  return (
    <>
      {/* Budget Description :: The network label will have the closest meaning to your project name. */}
      <FieldContainer icon={airdropIcon} title="Budget">
        <RowField>
          <FieldLabel label="Network / Microchain" />
          <div className="airdrop-budget-setting-chain-selector-container">
            <Select
              labelKey={"label"}
              valueKey={"id"}
              options={allNetworkList}
              selectedValue={selectedNetworkId}
              setSelectedValue={setSelectedNetworkId}
              canSelect={allNetworkList.length !== 0}
              placeholder={"Select Network"}
            />
            <Select
              labelKey={"label"}
              valueKey={"id"}
              options={availableMicroChainList}
              selectedValue={selectedMicroChainId}
              setSelectedValue={setSelectedMicroChainId}
              canSelect={selectedNetworkId && availableMicroChainList.length !== 0}
              placeholder={"Select Microchain"}
            />
          </div>
        </RowField>
        <RowField>
          <FieldLabel label="Currency" />
          <Select
            labelKey={"symbol"}
            valueKey={"microChainCurrencyId"}
            options={availableCurrencyList}
            selectedValue={airdropData.microchainCurrencyId}
            setSelectedValue={setSelectedCurrencyId}
            canSelect={availableCurrencyList.length !== 0}
            placeholder={"Select Currency"}
          />
        </RowField>
        <RowField>
          <FieldLabel label="Distribution amount" />
          <CommentInput
            {...budgetValidation}
            onChange={(e) => setAirdropData(e.target.value, "depositAmount", "number")}
            value={airdropData.depositAmount ?? ""}
          />
        </RowField>
      </FieldContainer>

      <div className="flex justify-content-flex-end">
        <ButtonContainer>
          <BasicButton theme={ButtonTheme.SECONDARY} size={ButtonSize.LARGE} onClick={() => onClickBackButton()}>
            Back
          </BasicButton>
          <BasicButton size={ButtonSize.LARGE} disabled={!isAble} onClick={() => onClickNextButton()}>
            Next
          </BasicButton>
        </ButtonContainer>
      </div>
    </>
  );
};

export default AirdropBudgetSetting;
