import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Input from "../../../../../legacy-common/atom/input/Input";
import ImageUpload from "../../../../components/_atom/file/ImageUpload";
import TextArea from "../../../../../legacy-common/atom/textarea/TextArea";
import toast from "react-hot-toast";
import { errorAlert } from "../../../../../utils/Utils";
import { getFoundationNoticeDetailAction, updateFoundationNoticeAction } from "../../../../../action/foundationAction";
import Section from "legacy-common/atom/section/Section";
import { validationInFoundationNotice } from "../validation/foundationValidation";
import PageTitle from "view/components/_molecules/page/PageTitle";
import PageArticle from "view/components/_template/page/PageArticle";
import PageLoading from "view/components/_atom/loading/PageLoading";
import useLoading from "hooks/useLoading";
import ButtonContainer from "view/components/_atom/container/ButtonContainer";
import BasicButton from "view/components/_atom/button/BasicButton";
import { ButtonSize, ButtonTheme } from "view/components/_atom/button/StyledButton";

function UpdateFoundationNotice() {
  const navigate = useNavigate();
  const params = useParams();
  const { id: foundationId, noticeId } = params || {};

  const [foundationNoticeDetail, setFoundationNoticeDetail] = useState();
  const [noticeImage, setNoticeImage] = useState(null);

  const setNoticeData = (value, target) => {
    setFoundationNoticeDetail((prevState) => {
      return { ...prevState, [target]: value };
    });
  };

  useEffect(() => {
    if (noticeImage) {
      setNoticeData(noticeImage, "contentImg");
    }
  }, [noticeImage]);

  const getNoticeDetail_ = async () => {
    const { result, error } = await getFoundationNoticeDetailAction(noticeId);

    if (result) {
      setFoundationNoticeDetail(result);
      setNoticeImage(result.contentImg);
    }
    if (error) {
      errorAlert(error.data.message);
    }
  };

  const FoundationNoticeValidaiton = useMemo(() => {
    const nameValidation = validationInFoundationNotice.title(foundationNoticeDetail?.title);
    if (!nameValidation.success) {
      return nameValidation.message;
    }
    const symbolValidation = validationInFoundationNotice.image(noticeImage);
    if (!symbolValidation.success) {
      return symbolValidation.message;
    }
    const descriptionValidation = validationInFoundationNotice.content(foundationNoticeDetail?.content);
    if (!descriptionValidation.success) {
      return descriptionValidation.message;
    }
  }, [foundationNoticeDetail, noticeImage]);

  const updateFoundationNotice_ = async () => {
    if (FoundationNoticeValidaiton) {
      toast.error(FoundationNoticeValidaiton);
      return;
    }

    let formData = new FormData();
    formData.append("noticeImage", noticeImage);

    const { result, error } = await updateFoundationNoticeAction(
      formData,
      noticeId,
      foundationNoticeDetail.title,
      foundationNoticeDetail.content,
      foundationNoticeDetail.contentImg
    );

    if (result) {
      toast.success("processed successfully");
      navigate(`/network/foundation/manage/${foundationId}/notice/manage/${noticeId}`);
    }
    if (error) {
      errorAlert(error.data.message);
    }
  };

  const onClickBack = () => {
    navigate(-1);
  };

  const foundationNoticeInfoView = (data) => [
    {
      renderers: [
        {
          index: "Foundation",
          value: `${data.networkLabel} / ${data.microChainLabel}`,
        },
        {
          index: "Title",
          value: <Input value={foundationNoticeDetail?.title} onChange={(e) => setNoticeData(e.target.value, "title")} autoFocus />,
        },
        {
          index: "Image",
          value: (
            <ImageUpload
              name="notice-image"
              image={noticeImage}
              setImage={setNoticeImage}
              previewStyle={{ width: 498, height: 170 }}
              comment={"Please adjust the image size to 500X170 as much as possible."}
              style={{ alignItems: "start" }}
            />
          ),
        },
        {
          index: "Content",
          value: <TextArea value={foundationNoticeDetail?.content} onChange={(e) => setNoticeData(e.target.value, "content")} style={{ height: 134 }} />,
        },
      ],
    },
  ];

  const { loading } = useLoading({
    dependency: [noticeId, foundationId],
    synchronousFunction: async () => {
      await getNoticeDetail_();
    },
  });

  if (loading) {
    return <PageLoading />;
  }

  return (
    <PageArticle>
      <PageTitle size={810} title={"Update Notice"} />
      <div className="page-layout-810">
        <Section title={"Notice Info"} description={""} view={foundationNoticeInfoView} data={foundationNoticeDetail} />

        <div className="flex justify-content-flex-end">
          <ButtonContainer>
            <BasicButton size={ButtonSize.LARGE} theme={ButtonTheme.SECONDARY} onClick={() => onClickBack()}>
              Back
            </BasicButton>
            <BasicButton size={ButtonSize.LARGE} onClick={() => updateFoundationNotice_()}>
              Save
            </BasicButton>
          </ButtonContainer>
        </div>
      </div>
    </PageArticle>
  );
}

export default UpdateFoundationNotice;
