import React, { useState } from "react";
import "./ChangePasswordModal.scss";
import ModalTemplateB from "view/components/_template/modal/ModalTemplateB";
import ButtonContainer from "view/components/_atom/container/ButtonContainer";
import BasicButton from "view/components/_atom/button/BasicButton";
import { findUserPasswordAction } from "action/userAction";
import { errorAlert } from "utils/Utils";
import { useSelector } from "react-redux";
import { user_info } from "reducer/userReducer";
import { ButtonTheme } from "view/components/_atom/button/StyledButton";
import recommendedImage from "../../../assets/images/Image_recommended_data.png";
import sendEmailImage from "../../../assets/images/Image_send_email.png";

enum passwordModalStep {
  SEND_EMAIL = 1,
  COMPLETE = 2,
}

type ChangePasswordModalProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const ChangePasswordModal = (props: ChangePasswordModalProps) => {
  const { isOpen, setIsOpen } = props;
  const { email: userEmail } = useSelector(user_info);

  const [changePasswordModalstep, setChangePasswordModalStep] = useState(passwordModalStep.SEND_EMAIL);

  return (
    <ModalTemplateB isOpen={isOpen} onRequestClose={() => setIsOpen(false)} title={"Change password"}>
      <div className="change-password-modal">
        {changePasswordModalstep === passwordModalStep.SEND_EMAIL && (
          <SendEmailForChangePassword setIsOpen={setIsOpen} userEmail={userEmail} setChangePasswordModalStep={setChangePasswordModalStep} />
        )}
        {changePasswordModalstep === passwordModalStep.COMPLETE && <CompleteSendEmail setIsOpen={setIsOpen} />}
      </div>
    </ModalTemplateB>
  );
};

export default ChangePasswordModal;

type SendEmailForChangePasswordProps = {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  userEmail: string;
  setChangePasswordModalStep: React.Dispatch<React.SetStateAction<passwordModalStep>>;
};
const SendEmailForChangePassword = (props: SendEmailForChangePasswordProps) => {
  const { setIsOpen, userEmail, setChangePasswordModalStep } = props;

  const sendAuthCodeEmail_ = async () => {
    const { result, error } = await findUserPasswordAction(userEmail);
    if (result) {
      setChangePasswordModalStep(passwordModalStep.COMPLETE);
    }
    if (error) {
      errorAlert(error.data.message);
    }
  };

  return (
    <>
      <div className="change-password-modal-body">
        <img src={recommendedImage} className="change-password-modal-image send-email" alt="" />
        <div className="change-password-modal-description-container">
          <p className="change-password-modal-description strong">Email verification is required to change your password.</p>
          <p className="change-password-modal-description">{`A verification email will be sent\nto the email address of your user account.`}</p>
        </div>
      </div>
      <div className="change-password-modal-button-container">
        <ButtonContainer>
          <BasicButton theme={ButtonTheme.SECONDARY} onClick={() => setIsOpen(false)}>
            Close
          </BasicButton>
          <BasicButton onClick={() => sendAuthCodeEmail_()}>Send</BasicButton>
        </ButtonContainer>
      </div>
    </>
  );
};

type CompleteSendEmailProps = {
  setIsOpen: Function;
};

const CompleteSendEmail = (props: CompleteSendEmailProps) => {
  const { setIsOpen } = props;

  return (
    <>
      <div className="change-password-modal-body">
        <img src={sendEmailImage} className="change-password-modal-image send-email" alt="" />
        <div className="change-password-modal-description-container">
          <p className="change-password-modal-description">{`We've sent an email.\nPlease reset the password through the link in the email.\nThe link in the email will expire in 24 hours.`}</p>
        </div>
      </div>
      <div className="change-password-modal-button-container">
        <ButtonContainer>
          <BasicButton onClick={() => setIsOpen()}>Close</BasicButton>
        </ButtonContainer>
      </div>
    </>
  );
};
