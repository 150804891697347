import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./index";
import { MyInfo } from "./clientType/userClientType";
// Slice 영역 - 선언부

export const JWTInitialState = {
  access: {
    token: "",
    expires: "",
  },
  refresh: {
    expires: "",
  },
};

const initialState = {
  id: -1,
  firstName: "",
  lastName: "",
  email: "",
  emailVerification: false,
  region: null,
  createdAt: "",
  updatedAt: "",
  token: JWTInitialState,
  projectToken: JWTInitialState,
  lastPasswordChangedAt: "",
  changePasswordRecommendedDate: "",
  isMFASet: false,
  isExpired: false,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<MyInfo>) => {
      state.id = action.payload.id;
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.email = action.payload.email;
      state.emailVerification = action.payload.emailVerification;
      state.region = action.payload.region;
      state.createdAt = action.payload.createdAt;
      state.updatedAt = action.payload.updatedAt ? action.payload.updatedAt : "";
      state.lastPasswordChangedAt = action.payload.lastPasswordChangedAt;
      state.changePasswordRecommendedDate = action.payload.changePasswordRecommendedDate;
      state.isMFASet = action.payload.isMFASet;
    },
    setUserWithJWT: (state, action: PayloadAction<MyInfo>) => {
      state.id = action.payload.id;
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.email = action.payload.email;
      state.emailVerification = action.payload.emailVerification;
      state.region = action.payload.region;
      state.createdAt = action.payload.createdAt;
      state.updatedAt = action.payload.updatedAt ? action.payload.updatedAt : "";
      state.token = action.payload.token;
      state.projectToken = action.payload.projectToken ? action.payload.projectToken : JWTInitialState;
      state.lastPasswordChangedAt = action.payload.lastPasswordChangedAt;
      state.changePasswordRecommendedDate = action.payload.changePasswordRecommendedDate;
      state.isMFASet = action.payload.isMFASet;
    },
    removeUser: (state) => {
      state.id = -1;
      state.firstName = "";
      state.lastName = "";
      state.email = "";
      state.emailVerification = false;
      state.region = null;
      state.createdAt = "";
      state.updatedAt = "";
      state.token = JWTInitialState;
      state.projectToken = JWTInitialState;
      state.lastPasswordChangedAt = "";
      state.changePasswordRecommendedDate = "";
      state.isMFASet = false;
      state.isExpired = false;
    },
    setIsExpired: (state, action: PayloadAction<boolean>) => {
      state.isExpired = action.payload;
    },
  },
  extraReducers: {},
});
// Action 영역
export const { setUser, setUserWithJWT, removeUser, setIsExpired } = userSlice.actions;
// Store 영역
export const user_info = (state: RootState) => state.user;
export const user_id = (state: RootState) => state.user.id;
export const user_access = (state: RootState) => state.user.token.access.token;
export const user_is_expired = (state: RootState) => state.user.isExpired;

export default userSlice.reducer;
