import { makeQuery } from "../index/utils";
import { v2ServerByProjectAuth } from "../index/serverInstances";
import { LibraryContractFromServer, LibraryContractVersionDetailFromServer } from "../type/contract.server.type";

//----------getPublishedContracts
/**
 * published 상태의 모든 contract 및 version 정보를 가져오는 라우터
 */
const getPublishedContracts = async (request: getPublishedContractsRequest): Promise<getPublishedContractsResponse> =>
  await v2ServerByProjectAuth.get({
    path: "contract-library",
    query: makeQuery(request.query),
  });

type getPublishedContractsRequest = {
  query: {
    limit?: number;
    lastId?: number;
    order?: string;
  };
};

type getPublishedContractsResponse = LibraryContractFromServer[];

//----------createContract
const createContract = async (request: CreateContractRequest): Promise<CreateContractResponse> =>
  await v2ServerByProjectAuth.post({
    path: "contract-library",
    query: makeQuery(request.query),
    data: request.data,
    config: {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
  });

type CreateContractRequest = {
  query: {
    fileCount: number;
  };
  data: FormData;
};
type CreateContractResponse = LibraryContractFromServer;

//----------getMyContracts
//contractId가 있을 경우 단일 객체로 내려옴
const getMyContracts = async (request: getMyContractsRequest): Promise<getMyContractsResponse> =>
  await v2ServerByProjectAuth.get({
    path: `contract-library/me`,
    query: makeQuery(request.query),
  });

type getMyContractsRequest = {
  query: {
    contractId?: number;
  };
};
type getMyContractsResponse = LibraryContractFromServer | LibraryContractFromServer[];

//----------getContractVersions
const getContractVersions = async (request: GetContractVersionsRequest): Promise<GetContractVersionsResponse> =>
  await v2ServerByProjectAuth.get({
    path: `contract-library/${request.params.contractId}`,
  });

type GetContractVersionsRequest = {
  params: {
    contractId: number;
  };
};
type GetContractVersionsResponse = LibraryContractFromServer;

//----------createContractVersion
const createContractVersion = async (request: CreateContractVersionRequest): Promise<CreateContractVersionResponse> =>
  await v2ServerByProjectAuth.post({
    path: `contract-library/${request.params.contractId}`,
    query: makeQuery(request.query),
    data: request.data,
    config: {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
  });

type CreateContractVersionRequest = {
  params: {
    contractId: number;
  };
  query: {
    fileCount: number;
  };
  data: FormData;
};
type CreateContractVersionResponse = LibraryContractVersionDetailFromServer;

//----------getContractVersion
const getContractVersion = async (request: GetContractVersionRequest): Promise<GetContractVersionResponse> =>
  await v2ServerByProjectAuth.get({
    path: `contract-library/${request.params.contractId}/version/${request.params.contractVersionId}`,
  });

type GetContractVersionRequest = {
  params: {
    contractId: number;
    contractVersionId: number;
  };
};
type GetContractVersionResponse = LibraryContractVersionDetailFromServer;

//----------updateContractVersion
const updateContractVersion = async (request: UpdateContractVersionRequest): Promise<UpdateContractVersionResponse> =>
  await v2ServerByProjectAuth.put({
    path: `contract-library/${request.params.contractId}/version/${request.params.contractVersionId}`,
    query: makeQuery(request.query),
    data: request.data,
  });

type UpdateContractVersionRequest = {
  params: {
    contractId: number;
    contractVersionId: number;
  };
  query: {
    fileCount: number;
  }
  data: FormData
};
type UpdateContractVersionResponse = LibraryContractVersionDetailFromServer;

//----------submitContractVersion
const submitContractVersion = async (request: SubmitContractVersionRequest): Promise<SubmitContractVersionResponse> =>
  await v2ServerByProjectAuth.post({
    path: `contract-library/${request.params.contractId}/version/${request.params.contractVersionId}`,
  });

type SubmitContractVersionRequest = {
  params: {
    contractId: number;
    contractVersionId: number;
  };
};

type SubmitContractVersionResponse = LibraryContractVersionDetailFromServer;

//----------deleteContractVersion
const deleteContractVersion = async (request: DeleteContractVersionRequest): Promise<DeleteContractVersionResponse> =>
  await v2ServerByProjectAuth.delete({
    path: `contract-library/${request.params.contractId}/version/${request.params.contractVersionId}`,
  });

type DeleteContractVersionRequest = {
  params: {
    contractId: number;
    contractVersionId: number;
  };
};

type DeleteContractVersionResponse = {
  status: boolean;
};

//----------rejectContractVersion
const rejectContractVersion = async (request: RejectContractVersionRequest): Promise<RejectContractVersionResponse> =>
  await v2ServerByProjectAuth.post({
    path: `contract-library/${request.params.contractId}/version/${request.params.contractVersionId}/reject`,
    data: request.data,
  });

type RejectContractVersionRequest = {
  params: {
    contractId: number;
    contractVersionId: number;
  };
  data: {
    rejectReason: string;
  };
};

type RejectContractVersionResponse = LibraryContractVersionDetailFromServer;

//----------acceptContractVersion
const acceptContractVersion = async (request: AcceptContractVersionRequest): Promise<AcceptContractVersionResponse> =>
  await v2ServerByProjectAuth.post({
    path: `contract-library/${request.params.contractId}/version/${request.params.contractVersionId}/accept`,
  });

type AcceptContractVersionRequest = {
  params: {
    contractId: number;
    contractVersionId: number;
  };
};

type AcceptContractVersionResponse = LibraryContractVersionDetailFromServer;

//----------publishContractVersion
const publishContractVersion = async (request: PublishContractVersionRequest): Promise<PublishContractVersionResponse> =>
  await v2ServerByProjectAuth.post({
    path: `contract-library/${request.params.contractId}/version/${request.params.contractVersionId}/publish`,
  });

type PublishContractVersionRequest = {
  params: {
    contractId: number;
    contractVersionId: number;
  };
};

type PublishContractVersionResponse = LibraryContractVersionDetailFromServer;

//----------withdrawContractVersion
const withdrawContractVersion = async (request: WithdrawContractVersionRequest): Promise<WithdrawContractVersionResponse> =>
  await v2ServerByProjectAuth.post({
    path: `contract-library/${request.params.contractId}/version/${request.params.contractVersionId}/withdraw`,
  });

type WithdrawContractVersionRequest = {
  params: {
    contractId: number;
    contractVersionId: number;
  };
};

type WithdrawContractVersionResponse = LibraryContractVersionDetailFromServer;

export {
  getPublishedContracts as getPublishedContractsFromServer,
  createContract as createContractFromServer,
  getMyContracts as getMyContractsFromServer,
  getContractVersions as getContractVersionsFromServer,
  createContractVersion as createContractVersionFromServer,
  getContractVersion as getContractVersionFromServer,
  updateContractVersion as updateContractVersionFromServer,
  submitContractVersion as submitContractVersionFromServer,
  deleteContractVersion as deleteContractVersionFromServer,
  rejectContractVersion as rejectContractVersionFromServer,
  acceptContractVersion as acceptContractVersionFromServer,
  publishContractVersion as publishContractVersionFromServer,
  withdrawContractVersion as withdrawContractVersionFromServer,
};
