import { useEffect, useState } from "react";
import css from "./Dashboard.scss";
import { DASHBOARD_SECTION_API_ID, DASHBOARD_SECTION_NETWORK_ID, DASHBOARD_SECTION_SMART_CONTRACT_ID, DASHBOARD_SECTION_TAB } from "./constants/dashboard.data";
import useGetSelectedProject from "../../../hooks/useGetSelectedProject";
import { bindStyle } from "view/styles/classNames";
import DashboardAPI from "./DashboardAPI";
import { getPricingPlanAction, getProjectInfoAction } from "action/projectAction";
import { errorAlert } from "utils/Utils";
import DashboardNetwork from "./DashboardNetwork";
import ownerIcon from "./assets/Icon_dashboard_owner.png";
import memberIcon from "./assets/Icon_dashboard_member.png";
import pricingIcon from "./assets/Icon_dashboard_pricing.png";
import EditProjectModal from "./components/EditProjectModal";
import PageHeader from "view/components/_molecules/page/PageHeader";
import DashboardContract from "./DashboardContract";

const PROJECT_DEFAULT_BACKGROUND = "https://s3.ap-northeast-2.amazonaws.com/dev-user.dev.eqhubd.eqbr.com/project-default-image/Project_Background_01.png";

const Dashboard = () => {
  const cn = bindStyle(css);

  const [selectedDashboardSectionTabId, setSelectedDashboardSectionTabId] = useState<number>(DASHBOARD_SECTION_TAB[0].id);

  const [apiKey, setApiKey] = useState<string>("");
  const [endpointList, setEndpointList] = useState<Array<any>>([]);

  const { selectedProject, selectedProjectID, isOwner } = useGetSelectedProject();

  const { pricingPlanId } = selectedProject;

  const [isOpenEditProjectModal, setIsOpenEditProjectModal] = useState<boolean>(false);

  const [pricingPlanName, setPricingPlanName] = useState<string>("-");
  const getPricingPlanInfo_ = async () => {
    const { result, error } = await getPricingPlanAction(pricingPlanId);
    if (result) {
      setPricingPlanName(result.name);
    }
    if (error) {
    }
  };

  const [owner, setOwner] = useState<any>();
  const [memberCount, setMemberCount] = useState<number>();

  const getSettingProjectInfo_ = async () => {
    const { result, error } = await getProjectInfoAction();
    if (result) {
      const { owner, memberCount } = result;
      setOwner(owner);
      setMemberCount(memberCount);
    }
    if (error) {
      errorAlert(error.data.message);
    }
  };

  useEffect(() => {
    getSettingProjectInfo_();
    getPricingPlanInfo_();
    setSelectedDashboardSectionTabId(DASHBOARD_SECTION_TAB[0].id);
  }, [selectedProjectID]);

  return (
    <div id="Dashboard">
      {isOpenEditProjectModal && (
        <EditProjectModal EditProjectModalOpen={isOpenEditProjectModal} setEditProjectModalOpen={setIsOpenEditProjectModal} setEndpointList={setEndpointList} />
      )}
      <PageHeader title={"Dashboard"} />
      <div className="project-container">
        <div className="project-content-wrapper">
          {isOwner && (
            <button className="project-content-button" onClick={() => setIsOpenEditProjectModal(true)}>
              Edit
            </button>
          )}
          <div className="project-background-image">
            <div className="project-background-image-container">
              <img src={PROJECT_DEFAULT_BACKGROUND} className="project-container-image-src" alt="project" style={{ transform: "translate(0%, -50%)" }} />
            </div>
          </div>
          <div className="project-info-wrapper">
            <div className="project-info">
              <div className="project-info-name" data-project-name={selectedProject.name}>
                {selectedProject.name}
              </div>
              <div className="project-info-description">{selectedProject.description}</div>
            </div>
            <div className="project-detail-wrapper">
              <div className="project-detail-card">
                <div className="project-detail-card-title">Owner</div>
                <div className="project-detail-card-content">
                  <img src={ownerIcon} alt="" className="project-detail-card-content-icon" />
                  <div className="project-detail-card-content-text">{owner ? `${owner.firstName} ${owner.lastName}` : "-"}</div>
                </div>
              </div>
              <div className="vertical-line" />
              <div className="project-detail-card">
                <div className="project-detail-card-title">Total Member</div>
                <div className="project-detail-card-content">
                  <img src={memberIcon} alt="" className="project-detail-card-content-icon" />
                  <div className="project-detail-card-content-text">{memberCount}</div>
                </div>
              </div>
              <div className="vertical-line" />
              <div className="project-detail-card">
                <div className="project-detail-card-title">Pricing Plan</div>
                <div className="project-detail-card-content">
                  <img src={pricingIcon} alt="" className="project-detail-card-content-icon" />
                  <div className="project-detail-card-content-text">{pricingPlanName}</div>
                </div>
              </div>
            </div>
          </div>
          <img src={selectedProject.image} className="project-represent-image" alt="project" />
        </div>
      </div>
      <div className="dashboard">
        <div className="dashboard-tab">
          {DASHBOARD_SECTION_TAB.map((tab) => (
            <div
              key={`dashboard-tab-${tab.id}`}
              className={cn("dashboard-tab-item", {
                selected: selectedDashboardSectionTabId === tab.id,
              })}
              onClick={() => setSelectedDashboardSectionTabId(tab.id)}
            >
              {tab.label}
            </div>
          ))}
        </div>

        {selectedDashboardSectionTabId === DASHBOARD_SECTION_API_ID && (
          <DashboardAPI apiKey={apiKey} setApiKey={setApiKey} endpointList={endpointList} setEndpointList={setEndpointList} />
        )}
        {selectedDashboardSectionTabId === DASHBOARD_SECTION_NETWORK_ID && <DashboardNetwork />}
        {selectedDashboardSectionTabId === DASHBOARD_SECTION_SMART_CONTRACT_ID && <DashboardContract />}
      </div>
    </div>
  );
};

export default Dashboard;
