import {
  VC__is_empty_value,
  VC__is_equal_or_less_than,
  VC__is_not_address,
  VC__is_not_capital_letter,
  VC__is_text_exceed_limit,
} from "modules/validation/validationCondition";
import { commaRemovalConvertor } from "utils/convert.utils";

export function VALIDATION__TOKEN_name(name) {
  return [
    {
      condition: !VC__is_empty_value(name),
      message: "",
    },
    {
      condition: !VC__is_text_exceed_limit(name, 50),
      message: "Token name must be up to 50 characters long",
    },
  ];
}

export function VALIDATION__Token_symbol(symbol) {
  return [
    {
      condition: !VC__is_empty_value(symbol),
      message: "",
    },
    {
      condition: !VC__is_not_capital_letter(symbol),
      message: "Enter no more than 10 characters, including English capital letters and spaces",
    },
    {
      condition: !VC__is_text_exceed_limit(symbol, 10),
      message: "Enter no more than 10 characters, including English capital letters and spaces",
    },
  ];
}

export function VALIDATION__TOKEN_image(image) {
  return [
    {
      condition: !VC__is_empty_value(image),
      message: "Token image must be set",
    },
  ];
}

export function VALIDATION__TOKEN_initial_supply(supply) {
  return [
    {
      condition: !VC__is_empty_value(supply),
      message: "",
    },
    {
      condition: !VC__is_equal_or_less_than(commaRemovalConvertor(supply), 0),
      message: "Enter a value of 0 or more",
    },
  ];
}

export function VALIDATION__TOKEN_address(address) {
  return [
    {
      condition: !VC__is_empty_value(address),
      message: "",
    },
    {
      condition: !VC__is_not_address(address),
      message: "Address must start with ‘0x’ and be 42 characters long",
    },
  ];
}

export function VALIDATION__TOKEN_amount(amount) {
  return [
    {
      condition: !VC__is_empty_value(amount),
      message: "",
    },
    {
      condition: !VC__is_equal_or_less_than(commaRemovalConvertor(amount), 0),
      message: "Enter a value of 0 or more",
    },
  ];
}

//------------------------------------------------------------------------------------
