import React, { useEffect, useRef, useState } from "react";
import css from "./CoinImageSelector.scss";
import toast from "react-hot-toast";
import coinSelectorIcon from "view/assets/images/Icon_upload_image_preview.png";
import { bindStyle } from "view/styles/classNames";

type CoinImageSelectorProps = {
  image: string | File | null;
  setImage: React.Dispatch<React.SetStateAction<any>> | Function;
  onClick?: Function;
  disabled?: boolean;
};

const CoinImageSelector = (props: CoinImageSelectorProps) => {
  const { image, setImage, onClick, disabled = false } = props;

  const imageRef = useRef<any>(null);
  const cn = bindStyle(css);

  const [preview, setPreview] = useState<any>();

  const uploadImage = () => {
    if (imageRef && imageRef?.current?.files[0]) {
      const file = imageRef.current.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        if (file.size > 5 * 1024 * 1024) {
          toast.error("image file must be less than or equal to 5 MB");
        } else {
          setImage(file);
        }
      };
    }
  };

  useEffect(() => {
    if (image) {
      if (typeof image === "string") {
        setPreview(image);
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onloadend = () => {
          setPreview(reader.result);
        };
      }
    } else {
      setPreview("");
    }
  }, [image]);

  return (
    <div className="coin-image-selector" onClick={() => (disabled ? {} : onClick ? onClick() : imageRef.current.click())}>
      <input name={"coin-image-selector-input"} type="file" accept="image/png, image/jpeg, image/gif" ref={imageRef} onChange={() => uploadImage()} />
      {image && <img src={preview} className={cn(`coin-image-selector-preview`, { able: !disabled })} alt="preview" />}
      {!image && (
        <div className="coin-image-selector-default">
          <img src={coinSelectorIcon} className="coin-image-selector-default-icon" alt="" />
          <p className="coin-image-selector-default-label">{`Upload/Select`}</p>
        </div>
      )}
    </div>
  );
};

export default CoinImageSelector;
