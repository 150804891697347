import "./DashboardAPI.scss";
import NetworkTypeLabel from "./components/NetworkTypeLabel";
import { errorAlert } from "utils/Utils";
import { getDashboardAPIAction } from "../../../action/dashboardAction";
import useGetSelectedProject from "../../../hooks/useGetSelectedProject";
import PageLoading from "../../components/_atom/loading/PageLoading";
import useLoading from "hooks/useLoading";
import ProjectRequestsChart from "./components/ProjectRequestsChart";
import MostUsedRequestsChart from "./components/MostUsedRequestsChart";
import NetworkUtilizationComparisonChart from "./components/NetworkUtilizationComparisonChart";
import RequestsHistoryTable from "./components/RequestsHistoryTable";
import SectionContainer from "view/components/_atom/section/SectionContainer";
import DashboardAPICardList from "./components/DashboardAPICardList";
import apiIcon from "../../assets/images/service/Icon_SNB_api_docs.png";
import web3ProviderIcon from "./assets/Icon_dashboard_web3_provider.png";

const DashboardAPI = (props) => {
  const { apiKey, setApiKey, endpointList, setEndpointList } = props;
  const { selectedProjectID } = useGetSelectedProject();

  const getDashboardAPI_ = async () => {
    const { result, error } = await getDashboardAPIAction();

    if (result) {
      const { apiKey, endpointList } = result;
      setApiKey(apiKey);
      setEndpointList(endpointList);
    }

    if (error) {
      // errorAlert(error.data.message);
    }
  };

  const ApiData = [
    {
      label: "Endpoint (Host)",
      value: process.env.REACT_APP_HUB_SERVER_URL || "https://ag.eqhub.eqbr.com",
    },
    {
      label: "Key",
      value: apiKey,
    },
  ];

  const endPointData = endpointList?.map((endpoint) => {
    return {
      label: endpoint.networkLabel,
      InfoRenderer: (
        <div style={{ width: 68 }}>
          <NetworkTypeLabel isTestnet={endpoint.isTestnet} />
        </div>
      ),
      value: process.env.REACT_APP_WEB3_PROVIDER_ENDPOINT_HOST + "?socketKey=" + endpoint.endpointKey,
      icon: endpoint.coinImage,
    };
  });

  const { loading } = useLoading({
    dependency: [selectedProjectID],
    synchronousFunction: async () => {
      await getDashboardAPI_();
    },
  });

  if (loading) {
    return <PageLoading />;
  }

  return (
    <div id={"DashboardAPI"}>
      <div className="dashboard-api-area">
        <div className="dashboard-api-area-container">
          <SectionContainer title={"API"} icon={apiIcon} element={<GoToDocsButton />}>
            <DashboardAPICardList data={ApiData} />
          </SectionContainer>
        </div>
        <div className="dashboard-api-area-container">
          <SectionContainer title={"Web3 Provider"} icon={web3ProviderIcon}>
            {endPointData.length > 0 && <DashboardAPICardList data={endPointData} />}
            {endPointData.length <= 0 && (
              <div className="dashboard-api-empty-web3-endpoint">{`Choose the network you want to use in the EQ Hub.\nYou can set it in Editing Project.`}</div>
            )}
          </SectionContainer>
        </div>
      </div>
      <div className="dashboard-api-area">
        <div className="dashboard-api-area-container">
          <ProjectRequestsChart />
        </div>
        <div className="dashboard-api-area-container">
          <MostUsedRequestsChart />
        </div>
      </div>
      <div className="dashboard-api-area">
        <div className="dashboard-api-area-container">
          <NetworkUtilizationComparisonChart />
        </div>
        <div className="dashboard-api-area-container">
          <RequestsHistoryTable />
        </div>
      </div>
    </div>
  );
};

const GoToDocsButton = () => {
  return (
    <div className="dashboard-api-go-to-docs" onClick={() => window.open(process.env.REACT_APP_EQ_HUB_DOCS_API_URL)}>
      Go to API Docs
    </div>
  );
};

export default DashboardAPI;
